// CORE
import { DOM, Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/AddQuote/editPage/businessQuote.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@js/libs/customElement/Sites-FinalCustomer';

// SERVICE
import S_Customer from '@services/Customer/CustomerService';
import S_Sites from '@services/Site/SiteService';

type BusinessQuoteData = {
	infos: {
		customer: string,
		label: string,
		sites: string[],
		hasFinalCustomer: boolean,
		finalCustomer: string,
		siteCustom: string,
		description: string
	}
};

class BusinessQuote extends Modal {
	private previousCallback: null | (() => void) = null;
	private nextCallback: null | ((data: any) => void) = null;

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	constructor(data: BusinessQuoteData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.initEvents();

			this.postInitSites();
			this.postInit();

			const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			if (this.nextCallback) {
				N_save.innerHTML = 'Suivant';
			}

			if (this.previousCallback) {
				N_previous && N_previous.classList.remove('d-none');
			}

			N_previous.addEventListener('click', () => {
				if (this.previousCallback) {
					this.previousCallback();
				}

				DOM.nextTick(() => {
					this.reject(false);
				});
			});

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.sites = await this.getSites(data.infos.sites);

					if (this.nextCallback) {
						this.nextCallback(data);
					}

					this.resolve(data);
				}));
			});

			this.updateButtonSave();
		});
	}

	public setPreviousCallback(cb: () => void) {
		this.previousCallback = cb;
		return this;
	}

	public setNextCallback(cb: (data: any) => void) {
		this.nextCallback = cb;
		return this;
	}

	private initEvents() {
		const N_label = this.element.querySelector('[name="infos.label"]') as HTMLInputElement;
		N_label.addEventListener('input', () => {
			this.updateButtonSave();
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_sites = this.element.querySelector('[name="infos.sites"]') as CE_Select2;

		N_customer.create(this.element, { disabled: true });

		N_sites.setRef({ customer: N_customer.selectElement! });
		N_sites.create(this.element, { multiple: true });

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.sites'] = N_sites;
	}

	private async setData(data: BusinessQuoteData) {
		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				label: data.infos.label,
				description: data.infos.description,
				hasFinalCustomer: data.infos.hasFinalCustomer,
				finalCustomer: data.infos.finalCustomer,
				sites: await this.getSites(data.infos.sites),
				siteCustom: data.infos.siteCustom
			}
		};

		this.form?.setData(res);
	}

	private async getSites(sites: string[]): Promise<string[]> {
		const result: string[] = [];

		for (const site of sites) {
			const siteData = await S_Sites.getInstance().getDataToSelect2ByID(site);

			if (siteData) {
				result.push(siteData);
			}
		}

		return result;
	}

	private postInitSites() {
		const N_sites = this.element.querySelector('ap-sites-final-customer') as CE_SitesFinalCustomer;

		N_sites.setFinalCustomer();
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}

	private updateButtonSave() {
		const label = this.form?.getDataByName('infos.label') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !label;
	}
}

export default BusinessQuote;
