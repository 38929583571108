import History from '@libs/History';

import CE_Button from '@libs/customElement/Button';

import '../../../css/customElements/buttonsHistory.scss';

class ButtonsHistory extends HTMLElement {
	public static readonly tagName: string = 'ap-buttons-history';

	public connectedCallback() {
		this.innerHTML = `
			<ap-button class="btn-navbar" id="back" icon="arrow-left/line" tooltip="Précédent"></ap-button>
			<ap-button class="btn-navbar" id="forward" icon="arrow-right/line" tooltip="Suivant"></ap-button>
		`;

		const historyApps = History.getInstance();

		const N_back = this.querySelector<CE_Button>('#back')!;
		const N_forward = this.querySelector<CE_Button>('#forward')!;

		N_back.addEventListener('click', () => {
			historyApps.back();
		});

		N_forward.addEventListener('click', () => {
			historyApps.forward();
		});
	}

	public static register() {
		customElements.define(ButtonsHistory.tagName, ButtonsHistory);
	}
}

export default ButtonsHistory;
