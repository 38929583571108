// CORE
import { Alert, Controller, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
// LIBS
import History from '@libs/History';

import BrandCellRenderer from '@modules/Products/js/libs/BrandCellRenderer';
import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

import CE_Aggrid from '@libs/customElement/AgGrid';

// PRINTER
import P_InventoryExport from '../libs/printer/InventoryExport';
import P_InventoryGroup from '../libs/printer/InventoryGroup';

// SERVICE
import S_Inventory from '@services/StockInventoryService';
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';

class InventoryController extends Controller {
	private N_grid: CE_Aggrid;

	private id: string | null = null;
	private stock: string = '';
	private title: string = '';

	constructor(el: HTMLElement) {
		super(el);

		const params = utils.getQuery();

		this.stock = params.stock;
		this.id = params.id;

		this.N_grid = el.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product._id',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: ProductCellRenderer
				},
				{
					headerName: 'Désignation',
					field: 'product.label',
					filterParams: {
						textFormatter: (result: string) => {
							if (result == null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					width: 150,
					suppressSizeToFit: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						applyMiniFilterWhileTyping: true
					},
					cellRenderer: BrandCellRenderer
				},
				{
					headerName: 'Empl.',
					field: 'product.locationStock',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Ancienne Qté',
					field: 'oldQuantity',
					width: 80,
					suppressSizeToFit: true,
					valueGetter: (params) => {
						return params.data.oldQuantity;
					},
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: 'Nouvelle Qté',
					field: 'newQuantity',
					width: 80,
					editable: true,
					suppressSizeToFit: true,
					valueGetter: (params) => {
						return params.data.newQuantity;
					},
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				}
			],
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: { newRowsAction: 'keep' },
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			floatingFilter: true,
			onFilterChanged: (params: any) => {
				const filter = params.api.getFilterModel();
				const N_title = document.querySelector('#title') as HTMLElement;

				let N_icon = (N_title.parentNode as HTMLElement).querySelector('i#filter') as HTMLElement;

				if (!N_icon) {
					N_icon = document.createElement('i');
					N_icon.classList.add('icon', 'icon-filter', 'h4', 'mx-2');
					N_icon.id = 'filter';
					N_icon.title = 'Filtre actif';

					(N_title.parentNode as HTMLElement).insertBefore(N_icon, N_title);
				}

				if (_.isEmpty(filter)) {
					N_icon.classList.add('d-none');
				} else {
					N_icon.classList.remove('d-none');
				}
			}
		});

		this.getData();

		const N_print_groupByLocation = el.querySelector('#print_groupByLocation') as HTMLButtonElement;
		const N_print_groupByBrand = el.querySelector('#print_groupByBrand') as HTMLButtonElement;
		const N_print_export = el.querySelector('#print_export') as HTMLButtonElement;
		const N_save = el.querySelector('#save') as HTMLButtonElement;
		const N_finish = el.querySelector('#finish') as HTMLButtonElement;
		const N_name_stock = el.querySelector('#name_stock') as HTMLElement;

		S_Stock.getInstance().getDisplayRefByID(this.stock).then((data) => {
			this.title = data;
			N_name_stock.innerHTML = data;
		});

		N_print_groupByLocation.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.N_grid.api?.forEachNodeAfterFilter((node) => {
				data.push(node.data);
			});

			new P_InventoryGroup().print(data, 'product.locationStock', this.title);
		});

		N_print_groupByBrand.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.N_grid.api?.forEachNodeAfterFilter((node) => {
				data.push(node.data);
			});

			new P_InventoryGroup().print(data, 'product.brand', this.title);
		});

		N_print_export.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.N_grid.forEachNode((node) => {
				if (node.data.newQuantity !== '0') {
					data.push(node.data);
				}
			});

			new P_InventoryExport().print(data, this.title);
		});

		N_save.addEventListener('click', async () => {
			const newData: { [key: string]: any } = {
				_id: this.id,
				stock: this.stock,
				products: {}
			};

			this.N_grid.forEachNode((node) => {
				newData.products[node.data.product._id] = {
					oldQuantity: node.data.oldQuantity,
					newQuantity: node.data.newQuantity
				};
			});

			const { data } = await S_Inventory.getInstance().save(newData);

			this.id = data.data._id;

			this.updateURL();

			toaster.success('Sauvegarde réussie');
		});

		N_finish.addEventListener('click', () => {
			Alert.confirm('Avertissement', `
			Toutes les quantités des produits vont être remplacées par celles indiquées dans l'inventaire. <br>
			Si aucune valeur n'a été saisie pour un produit, la nouvelle valeur appliquée par défaut sera <span class="font-weight-bold">0</span>. <br>
			Etes-vous sûr de vouloir continuer ?
			`).then(async () => {
				const newData: { [key: string]: any } = {
					_id: this.id,
					stock: this.stock,
					finish: true,
					products: {}
				};

				this.N_grid.forEachNode((node) => {
					newData.products[node.data.product._id] = {
						oldQuantity: node.data.oldQuantity,
						newQuantity: node.data.newQuantity
					};
				});

				await S_Inventory.getInstance().save(newData);

				toaster.success('Sauvegarde réussie');

				Router.getInstance().navigate('/#module/stock');
			});
		});
	}

	private updateURL() {
		const href: string[] = [];

		href.push(`id=${this.id}`);
		href.push(`stock=${this.stock}`);

		History.getInstance().replaceState(`${window.location.hash.split('?')[0]}?${href.join('&')}`);
	}

	private async getData() {
		const { data } = await S_Inventory.getInstance().getDataToModal(this.id || '', { stock: this.stock });

		this.N_grid.value = data.data.products;
	}

	public destructor() {

	}
}

export default InventoryController;
