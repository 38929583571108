import Modal from '@libs/Modal';

import T_Modal from '../../tpl/modals/addEditBrands.html';

import S_Brand from '@services/Product/BrandService';
import Utils from '@js/libs/utils/Utils';

type brand = {
	_id: string,
	label: string
};

class AddEditBrands extends Modal {
	constructor(id?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.element.classList.add('loading');

			const N_title = this.element.querySelector<HTMLElement>('.modal-title')!;

			if (id) {
				N_title.innerHTML = 'Modification d\'une marque';
			} else {
				N_title.innerHTML = 'Ajouter une marque';
			}

			const { data } = await S_Brand.getInstance().getDataToModal(id);

			const N_label = this.element.querySelector<HTMLInputElement>('[name="label"]')!;
			N_label.value = data.data.label;

			const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

			N_save.addEventListener('click', async () => {
				const data: brand = {
					_id: id || Utils.generateId(),
					label: N_label.value
				};

				const tmp = await S_Brand.getInstance().save(data);

				this.resolve(tmp.data.data._id);
			});

			this.element.classList.remove('loading');
		});
	}
}

export default AddEditBrands;
