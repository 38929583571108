// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
import T_modal from '@tpl/modals/addReminder.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
import Utils from '@libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
// SERVICE

class AddReminder extends Modal {
	constructor(table: string, documentId: string, data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			if (!_.isEmpty(data)) {
				form.setData(data.value);
			}

			const N_description = this.element.querySelector('[name="description"]') as HTMLTextAreaElement;
			N_description.focus();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				let res: { [key: string]: any } = {};

				if (_.isEmpty(data)) {
					res = {
						value: {
							table,
							user: Utils.userID,
							state: '0', // state '0' => rappel actif / state '1' => rappel inactif
							date: moment().toDate().getTime(),
							description: form.getDataByName('description') as string,
							documentId
						}
					};
				} else {
					res = {
						...data
					};

					res.value.description = form.getDataByName('description') as string;
				}

				this.resolve(res);
			});
		});
	}
}

export default AddReminder;
