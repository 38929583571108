import GenericService from '../GenericService';

class BrochureQuoteService extends GenericService {
	private static instance: BrochureQuoteService | null = null;

	constructor() {
		super('brochures');
	}

	public static getInstance(): BrochureQuoteService {
		if (!BrochureQuoteService.instance) {
			BrochureQuoteService.instance = new BrochureQuoteService();
		}

		return BrochureQuoteService.instance;
	}
}

export default BrochureQuoteService;
