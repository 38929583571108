import DecimalApps from '@js/libs/utils/Decimal';

import { ContentLineBillPrice, TVARecap, TotalBillResult } from './index';

import CalculTaxe from '@modules/Comptabilité/js/libs/CalculTaxe';

import { billCustomerFormCalcul, contentLine } from '../../types/billCustomer';

type ContentLineBill = {
	label: string,
	quantity: number,
	unit: string, //id couchDB
	tva: string, //id coucDB
	price: number | string //Fix à faire dans la prochaine Màj pour convertir tout les montants en nombre
};

class CalculV2 {
	private bill: billCustomerFormCalcul;

	constructor(bill: billCustomerFormCalcul) {
		this.bill = bill;
	}

	private getAdvanceOrder() {
		return DecimalApps.setDisplayNumber(this.bill.order.advancePriceHT).toDecimalPlaces(2);
	}

	/**
	 * Retourne le total TVA de la facture à partir du recap TVA
	 */
	private calculateTotalTva(tvaRecap: TVARecap): DecimalApps {
		let totalTVA = new DecimalApps(0);
		tvaRecap.forEach((value) => {
			totalTVA = totalTVA.plus(value.tva.calculatePriceTVAByHT(value.priceHT));
		});

		return totalTVA.toDecimalPlaces(2);
	}

	private calculateTotalBill(contentLinePriceList: Array<ContentLineBillPrice>): TotalBillResult {
		//Initialisation Prix Total HT
		let totalHT = new DecimalApps(0);

		//On clear le recap TVA au cas ou il a déjà été calculé
		const tvaRecap: TVARecap = new Map();

		for (const linePrice of contentLinePriceList) {
			totalHT = totalHT.plus(linePrice.priceHT);

			const idTVA = linePrice.tva.getID();

			//Gérer la TVA par taux
			if (tvaRecap.has(idTVA)) {
				tvaRecap.set(idTVA, {
					...tvaRecap.get(idTVA)!,
					priceHT: tvaRecap.get(idTVA)!.priceHT.plus(linePrice.priceHT)
				});
			} else {
				tvaRecap.set(idTVA, {
					priceHT: linePrice.priceHT,
					tva: linePrice.tva
				});
			}
		}

		//On récupère les avancements de la commande associée
		const totalAdvanceHT = this.getAdvanceOrder();

		//Calcul du total TVA
		const totalTVA = this.calculateTotalTva(tvaRecap);

		//Attention ! On arrondit les totaux à 2 décimales
		return {
			totalTTC: totalHT.plus(totalTVA).toDecimalPlaces(2),
			totalHT: totalHT.toDecimalPlaces(2),
			totalTVA: totalTVA.toDecimalPlaces(2),
			advanceHT: totalAdvanceHT.toDecimalPlaces(2),
			tvaRecap
		};
	}

	/**
	 * Retourne les montants d'une ligne d'une facture
	 * @returns {priceHT: Decimal, priceTVA: Decimal, tvaRate: string}
	 */
	public async calculatePriceContentLine(line: ContentLineBill): Promise<ContentLineBillPrice> {
		//Récupération du prix HT par ligne
		//On fait un setDisplayNumber pour transformer le montant qui peut être stocké en chaine de caractère 
		//Ex : "22 393,25"
		const unitPrice = DecimalApps.setDisplayNumber(line.price);
		const quantity = DecimalApps.setDisplayNumber(line.quantity);
		//Calcul du prix total HT de la ligne => total HT = prix unitaire X quantité
		const priceHT = unitPrice.times(quantity);

		//Récupération de la TVA
		const tva = new CalculTaxe(line.tva);
		await tva.init();

		return {
			unitPrice,
			priceHT,
			tva
		};
	}

	public async calculatePrice(): Promise<TotalBillResult> {
		const contentLinePriceList: Array<ContentLineBillPrice> = [];
		//On parcourt chaque ligne du contenu de la facture
		for (const item of this.bill.content) {
			const contentLine = item as contentLine;

			//Si on a un prix et une TVA
			if (contentLine.price && contentLine.tva) {
				const priceContentLine = await this.calculatePriceContentLine(contentLine);
				contentLinePriceList.push(priceContentLine);
			} else if (contentLine.price && !contentLine.tva) {
				//??? On peut prendre la TVA par défaut ou throw une erreur
				throw new Error('TVA manquante dans la facture');
			}
		}

		return this.calculateTotalBill(contentLinePriceList);
	}
}

export default CalculV2;
