import tippy, { Instance, Props } from 'tippy.js';

import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import '../../css/productCellRenderer.scss';

import ProductService from '@services/Product/ProductService';

class ProductCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;
	private value: string;

	private instanceTooltip: Instance<Props> | null = null;

	private productService: ProductService;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('productCellRenderer');
		this.eGui.innerHTML = `
			<img class="image"/>
			<div class="fake-image">
				<ap-icon name="image/line"></ap-icon>
			</div>
			<div id="loading-spinner">
				<ap-icon class="icon-spin" name="loader-4/line"></ap-icon>
			</div>
			<div class="text"></div>
		`;

		this.productService = new ProductService();
		this.value = '';
	}

	public init(params: any) {
		this.value = params.value;

		const isOnlyImage = params.isOnlyImage;

		if (this.value) {
			const N_image = this.eGui.querySelector<HTMLImageElement>('.image')!;
			const N_text = this.eGui.querySelector('.text')!;

			this.displaySpinner();

			this.eGui.classList.remove('error');

			this.instanceTooltip?.destroy();

			N_image.src = `/function/products/image?id=${this.value}`;

			N_image.addEventListener('load', () => {
				this.displayImage();

				this.instanceTooltip = tippy(N_image, {
					trigger: 'mouseenter',
					placement: 'bottom-start',
					theme: 'light',
					content: `<ap-product-image-previewer src="/function/products/image?id=${this.value}"></ap-product-image-previewer>`,
					allowHTML: true
				});
			});

			N_image.addEventListener('error', () => {
				this.displayDefault();
			});

			if (!isOnlyImage) {
				this.productService.getById(this.value).then((data) => {
					if (data) {
						N_text.innerHTML = data?.[this.productService.referenceKey] || '';
					} else {
						this.eGui.classList.add('error');
						N_text.innerHTML = 'Erreur lors du chargement';
					}
				}).catch(() => {
					this.eGui.classList.add('error');
					N_text.innerHTML = 'Erreur lors du chargement';
				});
			} else {
				this.eGui.classList.add('no-text');
			}
		} else {
			this.displayDefault();
		}
	}

	private displayImage() {
		this.eGui.classList.add('displayImage');
		this.eGui.classList.remove('displayDefault');
		this.eGui.classList.remove('displaySpinner');
	}

	private displayDefault() {
		this.eGui.classList.remove('displayImage');
		this.eGui.classList.add('displayDefault');
		this.eGui.classList.remove('displaySpinner');
	}

	private displaySpinner() {
		this.eGui.classList.remove('displayImage');
		this.eGui.classList.remove('displayDefault');
		this.eGui.classList.add('displaySpinner');
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default ProductCellRenderer;
