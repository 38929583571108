import GenericService from './GenericService';

class ReceiptService extends GenericService {
	private static instance: ReceiptService | null = null;

	constructor() {
		super('receipts');
	}

	public static getInstance(): ReceiptService {
		if (!ReceiptService.instance) {
			ReceiptService.instance = new ReceiptService();
		}

		return ReceiptService.instance;
	}

	public async getByOrder(id_order: string) {
		return await this.get_function('getByOrder', { id_order });
	}
}

export default ReceiptService;
