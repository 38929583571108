class Version {
	public static convert(text: string) {
		if (!text.match(/-[0-9]{1,}/g)) {
			return text + '-0';
		}

		return text;
	}
}

export default Version;
