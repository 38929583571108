import { utils } from '@autoprog/core-client';

import { ColDef } from '@ag-grid-enterprise/all-modules';

import M_PrintPreview from '@libs/modals/PrintPreview';

import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

import CE_Aggrid from '@libs/customElement/AgGrid';

import LoadingCellRenderer from '@js/libs/agGrid/cellRenderer/LoadingCellRenderer';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

class InputStockTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-providers-input-stock-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private selectorTab: string = '';

	private idTab: string = '';

	private locations: { [key: string]: any }[] = [];

	private products: {
		[id: string]: { unit: string, brand: string, price: string, label: string, reference: string }
	} = {};

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || InputStockTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="database-2/line" icon-active="database-2/fill" text="Suivi entrée de stock"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid?.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Suivi entrée de stock

				<div class="page-tabs-title-right">
				
					<ap-button class="btn-icon d-none" type="print" id="print"></ap-button>

					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;
		this.N_grid?.setGridOptions({
			columnDefs: [{
				headerName: ''
			}]
		});

		this.initButton();
	}

	private initButton() {
		const N_print = this.N_el!.querySelector('#print') as HTMLButtonElement;

		N_print.addEventListener('click', () => {
			new M_PrintPreview('commands-provider', this.idOrder, 'pdfStock').open();
		});
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];
		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: 'text-center',
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: LoadingCellRenderer,
					cellRendererParams: {
						CellRenderer: NumericCellRenderer
					}
				});

				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id + '.quantity',
					width: 120,
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						params.data.stocks[item._id] = params.data.stocks[item._id] || { quantity: 0 };
						return params.data.stocks[item._id]?.quantity || 0;
					},
					cellRenderer: NumericCellRenderer
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer.quantity',
			width: 120,
			suppressSizeToFit: true,
			cellRenderer: NumericCellRenderer
		});

		this.N_grid!.setGridOptions({
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerName: '',
					field: 'productID',
					filter: false,
					width: 50,
					pinned: 'left',
					sortable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					resizable: false,
					suppressColumnsToolPanel: true,
					cellRenderer: ProductCellRenderer,
					cellRendererParams: {
						isOnlyImage: true
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.reference || 'Produit introuvable';
					}
				},
				{
					headerName: 'Libellé',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.label;
					}
				},
				{
					headerName: 'Marque',
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						return this.products[params.data.productID]?.brand;
					}
				},
				{
					headerName: 'Quantité Cmd',
					field: 'quantity',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer
				},
				{
					headerName: 'Unité',
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'text-center',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.unit;
					}
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Entrée stock',
					children: childrenLocation
				}
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			}
		});
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public async initData() {
		if (this.idOrder) {
			const { locations, content, stateReceipt } = await S_P_Order.getInstance().getStockById(this.idOrder);

			this.dispatchEvent(new CustomEvent('load', { detail: { stateReceipt } }));

			this.locations = locations;

			this.initGrid();

			await this.getAdditionalData();

			this.N_grid!.value = content;

			this.initStock();
		} else {
			this.N_grid!.value = [];
		}
	}

	private async getAdditionalData() {
		const products = await S_Product.getInstance().getDataToAgGrid();

		this.products = {};

		for (const item of products.rowData) {
			this.products[item._id.value] = {
				reference: item[S_Product.getInstance().referenceKey].formattedValue,
				unit: item.unit.formattedValue,
				brand: item.brand.formattedValue,
				label: item.label.formattedValue,
				price: item.price.formattedValue
			};
		}
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id.value] = stock[item.product._id.value] || {};
			stock[item.product._id.value][item.stock.value] = stock[item.product._id.value][item.stock.value] || { quantity: 0 };
			stock[item.product._id.value][item.stock.value].quantity = item.quantity.formattedValue;
		}

		this.N_grid!.forEachNode((node) => {
			for (const item of this.locations) {
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.productID]?.[item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	public static register() {
		customElements.define(InputStockTab.tagName, InputStockTab);
	}
}

export default InputStockTab;
