import { CompanyAddress } from '@modules/Settings/js/types/companyAddress';
import GenericService from './GenericService';

class CompaniesService extends GenericService {
	private static instance: CompaniesService | null = null;

	constructor() {
		super('companies');
	}

	public static getInstance(): CompaniesService {
		if (!CompaniesService.instance) {
			CompaniesService.instance = new CompaniesService();
		}

		return CompaniesService.instance;
	}

	public getFullAddressByCompanyAdress(address?: CompanyAddress) {
		return (address?.address || '') + '\n' + (address?.zipCode || '') + ' ' + (address?.city || '');
	}
}

export default CompaniesService;
