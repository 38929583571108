export default function (object: { [key: string]: any }) {
	const result = [];

	for (const key in object) {
		result.push({
			id: key,
			text: object[key]
		});
	}

	return result;
}
