import '../../../css/customElement/editCategoriesReadonly.scss';

import FormControllerPageID from '@libs/FormControllerPageID';

import S_ProductProvider from '@services/Product/ProductProviderService';

import CE_FilesReadonly from '@libs/customElement/FilesReadonly';

import '../../../css/customElement/editProductProviderReadonly.scss';

class EditProductProviderReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-edit-product-provider-readonly';

	private form: FormControllerPageID | null = null;

	public connectedCallback() {
		this.innerHTML = `
			<div class="provider-title"></div>
			<div class="provider-content">
				<div class="page-card">

					<div class="page-card-body">

						<div class="page-card-block-value">
							<div class="page-card-label">Référence :</div>
							<div class="page-card-value" name="provider_reference" defaultValue="-"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Designation :</div>
							<div class="page-card-value" name="provider_label" defaultValue="-"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Délai de livraison (en jours) :</div>
							<div class="page-card-value" name="deliveryTime" defaultValue="-"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Devis de référence :</div>
							<div class="page-card-value" name="quoteBase" defaultValue="-"></div>
						</div>

					</div>

				</div>

				<div class="page-card" id="price">

					<div class="page-card-body">

						<div class="price-grid-cell">

							<div class="page-card-block-value">
								<div class="page-card-label">Prix public :</div>
								<div class="page-card-value" type="price" name="publicPrice"></div>
							</div>

							<div class="page-card-block-value">
								<div class="page-card-label">Prix d'achat :</div>
								<div class="page-card-value" type="price" name="purchasePrice"></div>
							</div>

							<div class="page-card-block-value">
								<div class="page-card-label">Coût de revient :</div>
								<div class="page-card-value" type="price" name="costPrice"></div>
							</div>

						</div>

						<div class="price-grid-cell">

							<div class="page-card-block-value">
								<div class="page-card-label">Remise :</div>
								<div class="page-card-value" type="percent" name="discount"></div>
							</div>

							<div class="page-card-block-value">
								<div class="page-card-label">Prix transport :</div>
								<div class="page-card-value" type="price" name="shipPrice"></div>
							</div>

							<div class="page-card-block-value">
								<div class="page-card-label">Taux de marge :</div>
								<div class="page-card-value" type="percent"  name="marginRate"></div>
							</div>

						</div>

						

						<div class="price-grid-cell">
							<div class="page-card-block-value">
								<div class="page-card-label">Prix de vente conseillé :</div>
								<div class="page-card-value" type="price" name="recommendedSellPrice"></div>
							</div>
						</div>

					</div>

				</div>

				<div class="page-card">

					<div class="page-card-body">

						<div class="page-card-block-value">
							<div class="page-card-label">Commentaire :</div>
							<div class="page-card-value" type="textarea" name="comment" defaultValue="-"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Fichiers :</div>
							<ap-files-readonly defaultValue="-"></ap-files-readonly>
						</div>

						<div class="page-card-block-value" id="date">
							<div class="page-card-label">Dernière mise à jour :</div>
							<div class="page-card-value" type="date" format="DD/MM/YYYY HH:mm" name="lastUpdatePrice"></div>
						</div>

					</div>

				</div>
			
			</div>
		`;

		this.form = new FormControllerPageID(this.querySelector('.provider-content')!);

		const N_title = this.querySelector<HTMLElement>('.provider-title')!;

		N_title.addEventListener('click', () => {
			this.classList.toggle('active');
		});
	}

	public async init(id: string) {
		const { data } = await S_ProductProvider.getInstance().getDataToModal(id);

		this.form?.setData(data.data);

		const N_FilesReadonly = this.querySelector<CE_FilesReadonly>(CE_FilesReadonly.tagName)!;

		N_FilesReadonly.value = data.data.files;

		const N_title = this.querySelector<HTMLElement>('.provider-title')!;
		N_title.innerHTML = `${data.data.isDefault ? '<ap-icon name="star"></ap-icon>' : ''}${data.title} <ap-icon id="icon-active" name="arrow-down-s/line"></ap-icon>`;

		if (data.data.isDefault) {
			this.style.order = '-1';
			this.classList.add('active');
		}
	}

	public static register() {
		customElements.define(EditProductProviderReadonly.tagName, EditProductProviderReadonly);
	}
}

export default EditProductProviderReadonly;
