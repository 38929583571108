import _ from 'lodash';
import daterangepicker from 'daterangepicker';

export default function (options?: daterangepicker.Options): daterangepicker.Options | undefined {
	if (options) {
		return _.merge({
			showDropdowns: true,

			locale: {
				firstDay: 1,
				format: 'DD/MM/YYYY',
				separator: ' - ',
				applyLabel: 'Ok',
				cancelLabel: 'Retour',
				daysOfWeek: [
					'Di',
					'Lu',
					'Ma',
					'Me',
					'Je',
					'Ve',
					'Sa'
				],

				monthNames: [
					'Janvier',
					'Février',
					'Mars',
					'Avril',
					'Mai',
					'Juin',
					'Juillet',
					'Août',
					'Septembre',
					'Octobre',
					'Novembre',
					'Décembre'
				]

			},
			timePicker24Hour: true

		}, options);
	}

	return options;
}
