import { getCountries, getCountryCallingCode, parsePhoneNumberFromString } from 'libphonenumber-js';

class PhoneNumber extends HTMLElement {
	public static readonly tagName: string = 'ap-phone-number';

	private static countrySupported = getCountries();

	public async connectedCallback() {
		const name = this.getAttribute('name') as string;

		this.classList.add('w-100');

		const options: string[] = [];

		for (const item of PhoneNumber.countrySupported) {
			options.push(`<option class="text-monospace" value="${item}">${item} (+${getCountryCallingCode(item)})</value>`);
		}

		this.innerHTML = `
			<div class="d-flex" id="container">
				<select class="form-control" style="width: 120px; border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important;">
					${options.join('')}					
				</select>
				<input type="tel" class="form-control" id="input" style="border-bottom-left-radius: 0 !important; border-top-left-radius: 0 !important;">
			</div>
			<small id="msg-error" class="d-none text-red"> Format invalide </small>
			<input type="text" class="form-control d-none" name="${name}">
        `;

		const N_name = this.querySelector('[name]') as HTMLInputElement;
		const N_input = this.querySelector('#input') as HTMLInputElement;
		const N_select = this.querySelector('select') as HTMLSelectElement;

		N_select.value = 'FR';

		const instance = this;

		Object.defineProperty(N_name, 'value', {
			set: function (value) {
				if (value) {
					let data = parsePhoneNumberFromString(value)!;

					if (!data) {
						data = parsePhoneNumberFromString(value, 'FR')!;
					}

					instance.updateValid(data.isValid());

					N_select.value = data?.country || 'FR';
					N_input.value = data?.formatNational() || '';

					const valueInput = data?.formatInternational() || '';

					N_name.setAttribute('value', valueInput);
				} else {
					N_select.value = 'FR';
				}
			},
			get: function () {
				return N_name.getAttribute('value');
			}
		});

		N_input.addEventListener('input', () => {
			const data = parsePhoneNumberFromString(N_input.value, N_select.value as any)!;

			this.updateValid(data?.isValid());

			const valueInput = data?.formatInternational() || '';
			N_name.setAttribute('value', valueInput);
		});

		N_select.addEventListener('change', () => {
			const data = parsePhoneNumberFromString(N_input.value, N_select.value as any)!;

			this.updateValid(data?.isValid());

			const valueInput = data?.formatInternational() || '';
			N_name.setAttribute('value', valueInput);
		});

		this.removeAttribute('name');
	}

	private updateValid(isValid: boolean) {
		const N_list = this.querySelectorAll('.form-control') as NodeListOf<HTMLElement>;

		N_list.forEach((N_el) => {
			if (isValid) {
				N_el.classList.remove('is-invalid');
			} else {
				N_el.classList.add('is-invalid');
			}
		});
	}

	public static register() {
		customElements.define(PhoneNumber.tagName, PhoneNumber);
	}
}

export default PhoneNumber;
