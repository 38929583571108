import { Module, Modules, Router, global } from '@autoprog/core-client';

import C_Deliveries from './js/controllers/Deliveries';
import C_Delivery from './js/controllers/Delivery';

import T_delivery from './tpl/delivery.html';
import T_deliveryMobile from './tpl/mobile/delivery.html';
import T_tabs from '@tpl/tabs.html';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';
import Notifications from '@modules/Apps/js/libs/Notifications';

import CE_ContentDeliveriesTab from './js/libs/customElement/ContentDeliveriesTab';
import CE_ContentReceipt from './js/libs/customElement/ContentReceiptGrid';
import CE_DeliveryPrintOption from './js/libs/customElement/PrintOptionDeliveries';

import SettingsNotificationRegister from '@modules/Notifications/js/libs/SettingsNotificationRegister';

class DeliveriesReceiptsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_ContentReceipt.register();
		CE_ContentDeliveriesTab.register();
		CE_DeliveryPrintOption.register();
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});
		navigation.addLink({
			order: 3,
			category: 'Documents',
			name: 'BL / BR',
			type: 'module',
			module: 'apps/deliveries',
			permissions: ['DELIVERIES', 'RECEIPTS'],
			icon: 'truck/line'
		});

		NavigationMobile.getInstance().addLink({
			icon: 'icon-solid-truck',
			name: 'BL / BR',
			order: 4,
			dataset: {
				link: 'deliveries'
			}
		});

		const settingsNotificationRegister = SettingsNotificationRegister.getInstance();

		settingsNotificationRegister.addInput({
			text: 'Bon livraison',
			select: [
				{
					text: 'Prêt à être envoyé',
					name: 'delivery.ready'
				}
			]
		}, 1);

		Notifications.addCategory(['deliveries'], {
			textFilter: 'Bon livraison',
			isFilter: true,
			icon: 'icon-solid-truck',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_Delivery.open(item.data.id, { notification: item._id });
				}
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/apps/deliveries',
			controller: C_Deliveries as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/deliveries/:tabs',
			controller: C_Deliveries as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/delivery',
			controller: C_Delivery as any,
			tpl: global.IS_MOBILE ? T_deliveryMobile : T_delivery,
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Bl / BR', DeliveriesReceiptsModule);
