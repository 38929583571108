import { GridOptions } from '@ag-grid-enterprise/all-modules';

import '@css/customElements/button-edit-aggrid.scss';

import S_Products from '@services/Product/ProductService';

class UpdateGroup extends HTMLElement {
	public static readonly tagName: string = 'ap-button-aggrid-update-group';

	private _gridOptions: GridOptions = {};

	public connectedCallback() {
		this.classList.add('button-edit-aggrid');

		this.style.setProperty('--max-width', '130px');

		if (this.hasAttribute('disabled')) {
			this.innerHTML = `
			<ap-button class="disabled" tooltip="Actualiser le groupe" icon="loop-left/line"></ap-button>

			<div class="container-buttons">
				<ap-button class="disabled" id="price">Prix</ap-button>
				<ap-button class="disabled" id="label">Libellé</ap-button>
			</div>
		`;
		} else {
			this.innerHTML = `
			<ap-button tooltip="Actualiser le groupe" icon="loop-left/line"></ap-button>

			<div class="container-buttons">
				<ap-button id="price">Prix</ap-button>
				<ap-button id="label">Libellé</ap-button>
			</div>
		`;
		}

		const N_price = this.querySelector<HTMLElement>('#price')!;
		const N_label = this.querySelector<HTMLElement>('#label')!;

		N_price.addEventListener('click', async () => {
			const nodes: any[] = [];

			this._gridOptions.api?.forEachNode((node: any) => {
				nodes.push(node);
			});

			for (const node of nodes) {
				if (node.data.reference) {
					const { data } = await S_Products.getInstance().getDataToModal(node.data.reference);

					node.data.price = data.data.price;
					node.data.costPrice = data.data.defaultProvider?.costPrice || 0;

					node.setData(node.data);
				}
			}

			this.dispatchEvent(new CustomEvent('update'));
		});

		N_label.addEventListener('click', async () => {
			const nodes: any[] = [];

			this._gridOptions.api?.forEachNode((node: any) => {
				nodes.push(node);
			});

			await Promise.all(nodes.map(async (node: any) => {
				if (node.data.reference) {
					const data = await S_Products.getInstance().getById(node.data.reference);
					node.data.label = data.label;
					node.setData(node.data);
				}
			}));

			this.dispatchEvent(new CustomEvent('update'));
		});
	}

	public set gridOptions(gridOptions: GridOptions) {
		this._gridOptions = gridOptions;
	}

	public static register() {
		customElements.define(UpdateGroup.tagName, UpdateGroup);
	}
}

export default UpdateGroup;
