import { Controller } from '@autoprog/core-client';

import _cloneDeep from 'lodash/cloneDeep';

import Desktop from './Database.Desktop';
import Mobile from './Database.Mobile';

class ControllerDatabase extends Controller {
	private instance: Desktop | Mobile;

	constructor(el: HTMLElement, data: any) {
		super(el);

		const cloneData = _cloneDeep(data);

		if (!cloneData.instance) {
			throw new Error('instance not find');
		}

		const Instance = cloneData.instance;

		delete cloneData.instance;

		this.instance = new Instance(el, cloneData);
	}

	public destructor() {
		this.instance.destructor();
	}
}

export default ControllerDatabase;
