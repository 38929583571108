import _ from 'lodash';

import Modal from '@libs/Modal';

//TEMPLATE
import T_modal from '../../tpl/modals/scanTrackingNumber.html';

class ScanTrackingNumber extends Modal {
	private currentIndex = 0;

	private cuurentIndexProduct = 0;

	private groupBy: { [key: string]: any } = {};
	private productskey: string[] = [];

	constructor(data: any[]) {
		super({
			template: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			this.groupBy = _.groupBy(data, 'product.id');

			this.productskey = Object.keys(this.groupBy);

			this.displayProduct();

			const N_next = this.element.querySelector('#next') as HTMLButtonElement;

			N_next.addEventListener('click', () => {
				this.cuurentIndexProduct++;
				this.displayProduct();
			});
		});
	}

	private displayProduct() {
		let index = 0;
		this.currentIndex = 0;
		const data = this.groupBy[this.productskey[this.cuurentIndexProduct]];

		const N_product = this.element.querySelector('#product') as HTMLElement;

		N_product.innerHTML = data[0].product.name;

		for (const item of data) {
			this.getTemplate(item, index);
			index++;
		}
		this.changeFocus();
	}

	private async getTemplate(item: { [key: string]: any }, index: number) {
		const N_div = document.createElement('div');

		N_div.classList.add('row', 'no-gutters', 'mt-2');

		N_div.innerHTML = `	
			<div class="col">
				Scan
				<input type="text" class="form-control" id="trackingNumber" data-index="${index}" value="${item.trackingNumber || ''}">
			</div>
			<div class="col font-weight-bold">
				N° Traca
				<div id="internal_number">

				</div>
			</div>
		`;

		const N_trackingNumber = N_div.querySelector('#trackingNumber') as HTMLInputElement;
		const N_internal_number = N_div.querySelector('#internal_number') as HTMLInputElement;

		N_trackingNumber.addEventListener('change', () => {
			item.trackingNumber = N_trackingNumber.value;
			this.currentIndex++;
			//TODO: get new internal number
			N_internal_number.innerHTML = '00001';
			this.changeFocus();
		});

		const N_modalContainer = this.element.querySelector(`#col${index % 3}`) as HTMLElement;
		N_modalContainer.appendChild(N_div);
	}

	private changeFocus() {
		const N_input = this.element.querySelector(`[data-index="${this.currentIndex}"]`) as HTMLInputElement;
		N_input && N_input.focus();
	}
}
export default ScanTrackingNumber;
