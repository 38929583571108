import ConfigManager from '@libs/ConfigManager';

import _ from 'lodash';

import ModalManager from '@managers/ModalManager';
import ServiceManager from '@managers/ServiceManager';
import TemplateMobileManager from '@managers/TemplateMobileManager';

// SERVICE
import GenericService from '@services/GenericService';

// CUSTOM ELEMENTS
import CE_MobileFilter from '@libs/customElement/MobileFilter';
import CE_MobileSort from '@libs/customElement/MobileSort';

class DatabaseMobileCtrl {
	protected config: { [key: string]: any } = {};
	private tableName = '';
	private el: HTMLElement;

	private data: any[] = [];
	private allData: any[] = [];

	protected cancellers: EventListenerCanceller[] = [];

	private startIndex = 0;
	private endIndex = 100;

	protected tableService: GenericService;

	private abortController: AbortController;
	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	constructor(el: HTMLElement, data: any) {
		this.el = el;

		this.abortController = new AbortController();

		const N_title = this.el.querySelector('#title-mobile') as HTMLElement;
		N_title && (N_title.innerHTML = data.name);

		this.tableService = ServiceManager.get(data.database)!.getInstance();
		const configManager = ConfigManager.getInstance();

		this.tableName = data.database;

		this.config = configManager.getConfig(this.tableName);
		this.initSortFilter();

		const N_add = document.querySelector('#add') as HTMLButtonElement;

		if (!_.get(this.config, 'options.button.cancelEvent.add')) {
			N_add.addEventListener('click', async () => {
				await ModalManager.getInstance().open(this.tableName);

				this.refreshData();
			}, {
				signal: this.abortSignal
			});
		}

		if (_.get(this.config, 'options.button.hidden.add')) {
			N_add.classList.add('d-none');
		}

		this.init();

		let N_grid = this.el.querySelector('#grid') as HTMLElement;

		N_grid = N_grid.parentNode as HTMLElement;

		N_grid.addEventListener('scroll', () => {
			if (N_grid.scrollTop + N_grid.clientHeight >= N_grid.scrollHeight && this.data.length > this.endIndex) {
				this.loadChunk();
			}
		});

		N_grid.classList.add('scroll-y');
	}

	private initSortFilter() {
		const N_mobileSort = this.el.parentElement?.querySelector('ap-mobile-sort') as CE_MobileSort;
		const N_mobileFilter = this.el.parentElement?.querySelector('ap-mobile-filter') as CE_MobileFilter;

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		N_mobileSort.init(this.tableName);

		N_mobileSort.setOnUpdate(() => {
			// La combinaison entre le tri et les filtres se font dans l'ordre 'tri' -> 'filtre
			this.data = N_mobileSort.sortData();
			N_mobileFilter.data = this.data;
			this.data = N_mobileFilter.filterData();

			this.startIndex = 0;
			this.endIndex = 100;

			N_grid.innerHTML = '';

			this.loadChunk();
		});

		N_mobileFilter.init(this.tableName);

		N_mobileFilter.setOnUpdate(() => {
			// La combinaison entre le tri et les filtres se font dans l'ordre 'tri' -> 'filtre
			this.data = N_mobileSort.sortData();

			N_mobileFilter.data = this.data;
			this.data = N_mobileFilter.filterData();

			this.startIndex = 0;
			this.endIndex = 100;

			N_grid.innerHTML = '';

			this.loadChunk();
		});
	}

	private loadChunk() {
		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		const endIndex = this.endIndex > this.data.length ? this.data.length : this.endIndex;

		const data = this.data.slice(this.startIndex, endIndex);

		for (const item of data) {
			const N_item = document.createElement('div');

			N_item.innerHTML = TemplateMobileManager.get(this.tableName, item);

			N_item.addEventListener('click', (e) => {
				const target = e.target as HTMLElement;

				if (!(target && target.dataset.cancelModal === 'true')) {
					this.buttonEdit({
						value: item._id,
						data: item
					});
				}
			});

			this.customMobile(item, N_item);

			N_grid.appendChild(N_item);
		}

		this.startIndex = this.endIndex;
		this.endIndex += 100;
	}

	private async init() {
		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		N_grid.innerHTML = `
			<div class="align-items-center d-flex h-100 justify-content-center">
				<i class="icon icon-solid-spinner icon-spin text-muted" style="font-size:75px"></i>
			</div>
		`;

		const { rowData } = await this.tableService.getDataToAgGrid(this.abortController);

		this.allData = rowData;

		this.config.mobile.sort = this.config.mobile.sort || [];
		this.config.mobile.order = this.config.mobile.order || _.fill(new Array(this.config.mobile.sort.length), 'asc');

		const N_mobileSort = this.el.parentElement?.querySelector('ap-mobile-sort') as CE_MobileSort;
		const N_mobileFilter = this.el.parentElement?.querySelector('ap-mobile-filter') as CE_MobileFilter;

		// La combinaison entre le tri et les filtres se font dans l'ordre 'tri' -> 'filtre
		N_mobileSort.data = this.allData;
		this.data = N_mobileSort.sortData();

		N_mobileFilter.data = this.data;
		this.data = N_mobileFilter.filterData();

		N_grid.innerHTML = '';

		this.loadChunk();
	}

	protected refreshData() {
		this.startIndex = 0;
		this.endIndex = 100;
		this.init();
	}

	protected buttonEdit(params: any) {
		ModalManager.getInstance().open(this.tableName, params.data._id).then(() => {
			this.refreshData();
		}).catch(() => {
			this.refreshData();
		});
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected customMobile(data: { [key: string]: any }, N_el: HTMLElement | null = null) {

	}

	public destructor() {
		this.abortController.abort('destroyed');

		for (const canceller of this.cancellers) {
			canceller();
		}

		const N_add = document.querySelector('#add') as HTMLButtonElement;
		N_add && N_add.classList.remove('d-none');

		const N_datepicker = document.querySelectorAll('.daterangepicker');

		for (const N_el of N_datepicker) {
			N_el.remove();
		}
	}
}

export default DatabaseMobileCtrl;
