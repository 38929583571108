// CORE
import { Form, global, toaster } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../tpl/modals/OutputCustomer.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class OutputCustomer extends Modal {
	private form: null | Form = null;

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	constructor(idProduct: string, quantity: number) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			if (global.IS_MOBILE) {
				const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLSelectElement;
				N_modalDialog.classList.remove('modal-40');
			}

			await this.initStock();
			this.init();

			const N_form = this.element.querySelector('.modal-content') as HTMLFormElement;

			this.form = new Form(N_form);

			this.form.setData({ quantity });

			if (idProduct) {
				const tmpProduct = await S_Product.getInstance().getDataToSelect2ByID(idProduct);

				this.form?.setDataByName('product', tmpProduct);

				const N_oldQuantity = this.element.querySelector('#oldQuantity') as HTMLElement;

				N_oldQuantity.innerHTML = `Quantité reçu : ${quantity || 0}`;
			}

			this.form?.setDataByName('stock', 'deliveryToCustomer');

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				if (this.form) {
					const data = this.form.getData();

					if (data.product && data.quantity) {
						data.type = 'output';

						await S_StockEvent.getInstance().save(data);

						this.resolve();
					} else {
						toaster.error('Veuillez remplir tous les champs');
					}
				}
			});

			this.postInit();
		});
	}

	private async initStock() {
		const N_list = this.element.querySelector('[name="stock"]') as HTMLSelectElement;
		const data = await S_Stock.getInstance().getRealStock();

		for (const item of data) {
			const option = new Option(item.name, item._id);

			N_list.append(option);
		}
	}

	private init() {
		const N_T_site = this.element.querySelector('[name="traceability.site"]') as CE_Select2;
		const N_T_customer = this.element.querySelector('[name="traceability.customer"]') as CE_Select2;
		const N_T_C_command = this.element.querySelector('[name="traceability.command_customer"]') as CE_Select2;
		const N_product = this.element.querySelector('[name="product"]') as CE_Select2;

		N_product.create(this.element, { disabled: true });

		N_T_customer.create(this.element);

		N_T_site.setRef({ customer: N_T_customer.selectElement! });
		N_T_site.create(this.element);

		N_T_C_command.create(this.element);

		this.selectPostinit['traceability.site'] = N_T_site;
		this.selectPostinit['traceability.customer'] = N_T_customer;
		this.selectPostinit['traceability.command_customer'] = N_T_C_command;
		this.selectPostinit.product = N_product;
	}

	private postInit() {
		this.selectPostinit['traceability.command_customer'].on('change', async (value) => {
			if (value) {
				this.selectPostinit['traceability.customer'].disable();

				const data = await S_C_Order.getInstance().getById(value as string);

				const customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);

				this.form?.setDataByName('traceability.customer', customer);
			} else {
				this.selectPostinit['traceability.customer'].enable();
			}
		});

		this.selectPostinit['traceability.site'].postInit();
		this.selectPostinit['traceability.customer'].postInit();
		this.selectPostinit['traceability.command_customer'].postInit();
		this.selectPostinit.product.postInit();
	}
}

export default OutputCustomer;
