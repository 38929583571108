class InputTime extends HTMLElement {
	public static readonly tagName: string = 'ap-input-time';

	public async connectedCallback() {
		const name = this.getAttribute('name');
		const value = this.getAttribute('value');

		this.innerHTML = `
            <input class="form-control" name="${name}" type="time" value="${value}">
        `;

		const N_input = this.querySelector('input') as HTMLInputElement;

		Object.defineProperty(N_input, 'type', {
			set: function (type) {
				this.setAttribute('type', type);
			},
			get: function () {
				return 'text';
			}
		});

		this.removeAttribute('name');
		this.removeAttribute('value');
	}

	public static register() {
		customElements.define(InputTime.tagName, InputTime);
	}
}

export default InputTime;
