import tippy from 'tippy.js';

import '@css/customElements/stepModalNav.scss';

type item = {
	text: string
};

class StepModalNav extends HTMLElement {
	public static readonly tagName: string = 'ap-step-modal-nav';

	public connectedCallback() {
		this.innerHTML = '';
	}

	private renderItem(text: string, index: number) {
		const N_div = document.createElement('div');
		N_div.classList.add('step');

		this.append(N_div);

		tippy(N_div, {
			content: text,
			arrow: false,
			placement: 'top'
		});

		N_div.addEventListener('click', () => {
			this.dispatchEvent(new CustomEvent('step.change', { detail: { index } }));
		});
	}

	public set data(data: item[]) {
		let index = 0;
		for (const item of data) {
			this.renderItem(item.text, index);
			index++;
		}
	}

	public setActive(index: number) {
		const N_step = this.querySelectorAll<HTMLElement>('.step');

		for (let i = 0; i < N_step.length; i++) {
			N_step[i].classList.remove('active');

			if (i === index) {
				N_step[i].classList.add('active');
			}
		}
	}

	public setValidity(valid: boolean, index: number) {
		const N_step = this.querySelectorAll<HTMLElement>('.step');

		for (let i = 0; i < N_step.length; i++) {
			if (i === index) {
				N_step[i].classList.toggle('invalid', !valid);
			}
		}
	}

	public static register() {
		customElements.define(StepModalNav.tagName, StepModalNav);
	}
}

export default StepModalNav;
