import GenericService from '../GenericService';
class ProviderAddressService extends GenericService {
	private static instance: ProviderAddressService | null = null;

	constructor() {
		super('providers-address');
	}

	public static getInstance(): ProviderAddressService {
		if (!ProviderAddressService.instance) {
			ProviderAddressService.instance = new ProviderAddressService();
		}

		return ProviderAddressService.instance;
	}

	public async getFullAddress(id: string): Promise<string> {
		return this.get_function('getFullAddress', {
			id
		});
	}

	public async getByProviderToSelect2(id_provider: string) {
		return this.searchByPattern('', { id_provider });
	}

	public async getDeliveryAddressByProvider(id_provider: string) {
		return this.get_function('getDeliveryAddressByProvider', {
			id_provider
		});
	}
}

export default ProviderAddressService;
