module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-mobile flex-column">\n\n    <div class="w-100 text-sm">\n        <div class="w-100 d-flex txt-bold text-blue-grey-900">\n            ' +
((__t = (data.infos.customer)) == null ? '' : __t) +
' ' +
((__t = (data.infos.sites)) == null ? '' : __t) +
'\n\n        </div>\n        <div class="w-100 d-flex  my-1">\n            <div class="flex-grow-1">\n                N° ' +
((__t = (data.infos.number)) == null ? '' : __t) +
'\n            </div>\n\n            ';
 let state={ "0" : "En Cours" , "1" : "Accepté" , "2" : "Refusé" , "4" : "Attente Commande" , "5" : "Terminé" , "6" : "À Valider" , "7" : "Révisé" , "send" : "Envoyé" };
__p += '\n            <div class="mobile-quote-state" data-state="' +
((__t = (state[data.infos.state])) == null ? '' : __t) +
'">\n                <div class="mr-2">\n                    ' +
((__t = (state[data.infos.state])) == null ? '' : __t) +
'\n                </div>\n                <i class="icon icon-solid-circle"></i>\n\n            </div>\n        </div>\n\n\n        <div class="w-100 d-flex text-muted">\n            ' +
((__t = (data.infos.label)) == null ? '' : __t) +
'\n        </div>\n        <div class="w-100 ml-auto">\n\n        </div>\n        <div class="d-flex flex-row-reverse my-2 txt-bold text-blue font-weight-bold">\n            ' +
((__t = (data.globalPrice.formattedValue)) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n    <button class="btn btn-blue-grey-50 w-100 text-sm font-weight-bold" id="view-quote">\n        Voir plus\n    </button>\n</div>';

}
return __p
}