import { global } from '@autoprog/core-client';

import Decimal from 'decimal.js';

type Value = Decimal.Value | DecimalApps;

class DecimalApps {
	private value: Decimal;
	private rawValue: Value;

	constructor(n: Value) {
		this.rawValue = n;
		this.value = new Decimal(this.convertToDecimalJS(Number(n) || 0));
	}

	public static ROUND_UP = Decimal.ROUND_UP;

	public static setDisplayNumber(text: string | number = '') {
		text = text.toString();
		text = text.replace(',', '.');
		text = text.replace(/\s/gmi, '');
		text = text.replace(/(€.*)/gmi, '');

		const value = Number(text);

		return new DecimalApps(value);
	}

	/**
	 * Met en forme un nombre avec un suffixe et un nombre fixe de décimaux
	 * @param suffix le suffixe
	 * @param decimalPlaces le nombre de décimaux
	 */
	public setSuffixAndHumanizeNumber(suffix: string, decimalPlaces: number | string = 2) {
		return this.setSuffixStr(this.humanizeNumber(decimalPlaces), suffix);
	}

	/**
	 * Met en forme un nombre avec un suffixe, un nombre minimum de décimaux et une précision maximale
	 * @param suffix le suffixe
	 * @param minDecimalPlaces le nombre de décimaux minimum
	 */
	public setSuffixAndHumanizeNumberWithPrecision(suffix: string, minDecimalPlaces: number = 2) {
		let decimalPlaces = 0;
		this.getNumberDecimal() > minDecimalPlaces ? decimalPlaces = this.getNumberDecimal() : decimalPlaces = minDecimalPlaces;
		return this.setSuffixStr(this.humanizeNumber(decimalPlaces), suffix);
	}

	/**
	 * Met un forme un nombre avec un nombre minimum de décimaux et une précision maximale
	 * @param minDecimalPlaces le nombre de décimaux minimum
	 */
	public humanizeNumberWithPrecision(minDecimalPlaces: number = 2) {
		let decimalPlaces = 0;
		this.getNumberDecimal() > minDecimalPlaces ? decimalPlaces = this.getNumberDecimal() : decimalPlaces = minDecimalPlaces;
		return this.humanizeNumber(decimalPlaces);
	}

	/**
	 * Renvoie la valeur d'un nombre suivi du suffixe donné sous forme de chaine de caractères
	 * @param value la valeur
	 * @param suffix le suffixe
	 */
	private setSuffixStr(value: string | number, suffix: string) {
		if (value === undefined || value === null) {
			value = '';
		}

		value = value.toString();

		if (value) {
			return value + suffix;
		}

		return value;
	}

	/**
	 * Définit le suffixe à afficher
	 * @param suffix le suffixe
	 */
	public setSuffix(suffix: string) {
		const value = this.value.toString();

		if (value) {
			return value + suffix;
		}

		return value;
	}

	/**
	 * Met en forme un nombre avec un nombre fixe de décimaux
	 * @param decimalPlaces 
	 * @returns 
	 */
	public humanizeNumber(decimalPlaces: number | string = 2) {
		const numberStr = this.value.toDecimalPlaces(6).toString();
		decimalPlaces = Number(decimalPlaces);

		if (!isNaN(Number(this.rawValue))) {
			const chunkNumber = numberStr.split('.');

			chunkNumber[0] = (chunkNumber[0] || '0').replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');

			if (decimalPlaces === -1) {
				chunkNumber[1] = chunkNumber[1] || '';

				if (chunkNumber[1].length) {
					return chunkNumber[0] + global.DECIMAL_SEPARATOR + chunkNumber[1];
				}
			} else if (decimalPlaces) {
				chunkNumber[1] = (chunkNumber[1] || '').padEnd(decimalPlaces, '0').slice(0, decimalPlaces);

				return chunkNumber[0] + global.DECIMAL_SEPARATOR + chunkNumber[1];
			}

			return chunkNumber[0];
		} else {
			return '<span class="font-weight-bold text-danger">!ERR</span>';
		}
	}

	/**
	 * Met en forme un pourcentage
	 * @param ignoreDecimal à mettre à 'true' si on souhaite ignorer les décimaux
	 */
	public humanizePercent(ignoreDecimal = false) {
		const valueStr = Number(this.value.toNumber().toFixed(6)).toString();
		const chunk = valueStr.replace('NaN', '0').split('.');

		chunk[0] = (chunk[0] || '0').padStart(3, '#').slice(-3);
		chunk[1] = (chunk[1] || '').padStart(2, '0').slice(0, 2);

		let value = chunk[0] + global.DECIMAL_SEPARATOR + chunk[1];

		if (ignoreDecimal) {
			value = chunk[0];
		}

		return value.replace(/#/gmi, '&nbsp;');
	}

	/**
	 * Convertir un nombre en type Decimal (decimal.js)
	 * @param n le nombre à convertir
	 */
	private convertToDecimalJS(n: Value): Decimal {
		if (n instanceof DecimalApps) {
			return n.value;
		}

		return n as Decimal;
	}

	public plus(n: Value): DecimalApps {
		return new DecimalApps(this.value.plus(this.convertToDecimalJS(n)));
	}

	public minus(n: Value): DecimalApps {
		return new DecimalApps(this.value.minus(this.convertToDecimalJS(n)));
	}

	public times(n: Value) {
		return new DecimalApps(this.value.times(this.convertToDecimalJS(n)));
	}

	public dividedBy(n: Value) {
		return new DecimalApps(this.value.dividedBy(this.convertToDecimalJS(n)));
	}

	public toDecimalPlaces(n: number, type: Decimal.Rounding = Decimal.ROUND_HALF_UP) {
		return new DecimalApps(this.value.toDecimalPlaces(n, type));
	}

	public toString() {
		return this.value.toString();
	}

	public toNumber() {
		return this.value.toNumber();
	}

	public toExcel() {
		return this.value.toNumber().toString().replace(/\./gmi, global.DECIMAL_SEPARATOR);
	}

	public valueOf() {
		return this.value.valueOf();
	}

	public equals(n: Value) {
		return this.value.equals(this.convertToDecimalJS(n));
	}

	public greaterThan(n: Value) {
		return this.value.greaterThan(this.convertToDecimalJS(n));
	}

	public lessThan(n: Value) {
		return this.value.lessThan(this.convertToDecimalJS(n));
	}

	/**
	 * Revoie le nombre de décimal significative de la valeur
	*/
	public getNumberDecimal(): number {
		if (Math.floor(this.value.toNumber()) === this.value.toNumber()) {
			return 0;
		} else {
			return this.value.toString().split('.')[1].length || 0;
		}
	}
}

export default DecimalApps;
