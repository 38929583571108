//LIBS
import ConfigManager from '@libs/ConfigManager';

//MODALS
import M_MobileSort from '@libs/modals/MobileSort';

// STYLE
import '@css/modals/mobile-sort-filter.scss';
import _ from 'lodash';

class MobileSort extends HTMLElement {
	public static readonly tagName: string = 'ap-mobile-sort';

	private _data: any | null = null;
	private sortList: { key: string, type: string, name: string }[] | null = null;

	private _update: () => void = () => { };

	private static selectedSort: { selectedKey: string, selectedType: string, selectedSortOrder: string } = { selectedKey: '', selectedType: '', selectedSortOrder: '' };

	public async connectedCallback() {
		this.innerHTML = `
            <button id="mobile-sort-btn" type="button" class="w-100 p-2 btn btn-white">
				<i class="icon icon-solid-sort mr-2 text-grey-400"></i>
				<span>Trier par</span>
			</button>
        `;

		this.initEvent();
	}

	private initEvent() {
		const N_sortButton = this.querySelector('#mobile-sort-btn') as HTMLButtonElement;
		N_sortButton.addEventListener('click', () => {
			if (this._data && this.sortList) {
				new M_MobileSort(this.sortList, MobileSort.selectedSort).open().then((selectedSort) => {
					MobileSort.selectedSort = selectedSort;
					this._update();
				});
			}
		});
	}

	public sortData() {
		let sortedData: any[] = [];

		if (MobileSort.selectedSort.selectedSortOrder === '') {
			sortedData = this._data;
		} else if (MobileSort.selectedSort.selectedSortOrder === 'asc') {
			sortedData = this.sortAsc(MobileSort.selectedSort.selectedKey, MobileSort.selectedSort.selectedType);
		} else if (MobileSort.selectedSort.selectedSortOrder === 'desc') {
			sortedData = this.sortDesc(MobileSort.selectedSort.selectedKey, MobileSort.selectedSort.selectedType);
		}
		return sortedData;
	}

	private sortAsc(key: string, type: string) {
		const sortedData = [...this._data].sort((itemA: any, itemB: any) => {
			let res = 0;

			if (type === 'string') {
				if ((_.get(itemA, key) || '').trim().toLowerCase() < (_.get(itemB, key) || '').trim().toLowerCase()) {
					res = -1;
				} else {
					res = 1;
				}
			}

			if (type === 'number') {
				if (Number(_.get(itemA, key)) < Number(_.get(itemB, key))) {
					res = -1;
				} else {
					res = 1;
				}
			}

			return res;
		});

		return sortedData;
	}

	private sortDesc(key: string, type: string) {
		const sortedData = [...this._data].sort((itemA: any, itemB: any) => {
			let res = 0;

			if (type === 'string') {
				if ((_.get(itemA, key) || '').trim().toLowerCase() > (_.get(itemB, key) || '').trim().toLowerCase()) {
					res = -1;
				} else {
					res = 1;
				}
			}

			if (type === 'number') {
				if (Number(_.get(itemA, key)) > Number(_.get(itemB, key))) {
					res = -1;
				} else {
					res = 1;
				}
			}

			return res;
		});

		return sortedData;
	}

	public set data(data: any[]) {
		this._data = data;
	}

	public init(tableName: string) {
		MobileSort.selectedSort = { selectedKey: '', selectedType: '', selectedSortOrder: '' };
		this.sortList = this.getSortList(tableName);
	}

	private getSortList(table: string) {
		const configManager = ConfigManager.getInstance();
		const config = configManager.getConfig(table);

		const columnsToSort: string[] = config.mobile.sort || [];

		const columns: { key: string, type: string, name: string }[] = config.columns || [];
		const columnsData: { [key: string]: { type: string, name: string } } = {};

		for (const col of columns) {
			columnsData[col.key] = { type: col.type, name: col.name };
		}

		const sortList: { key: string, type: string, name: string }[] = [];

		for (const col of columnsToSort) {
			if (columnsData[col]) {
				sortList.push({ key: col, type: columnsData[col].type, name: columnsData[col].name });
			}
		}

		return sortList;
	}

	public setOnUpdate(cb: () => void) {
		this._update = cb;
	}

	public static register() {
		customElements.define(MobileSort.tagName, MobileSort);
	}
}

export default MobileSort;
