import { GridOptions } from '@ag-grid-enterprise/all-modules';

import '@css/customElements/searchBar.scss';

import AggridUtils from '../utils/Aggrid';

class SearchBar extends HTMLElement {
	public static readonly tagName: string = 'ap-search-bar';
	protected _gridOptions: GridOptions = {};

	private timeout: any = null;

	public async connectedCallback() {
		this.innerHTML = `
			<div id="search-bar">
				<div class="search-icon-container">
					<ap-icon name="search/line"></ap-icon>
				</div>
				<input id="search-input" type="search" placeholder="Rechercher ..."/>
			</div>
        `;
	}

	public setGridOptions(gridOptions: GridOptions) {
		this._gridOptions = gridOptions;

		this.initEvents();
	}

	private initEvents() {
		const N_search = this.querySelector('#search-input') as HTMLInputElement;

		let update = true;

		this._gridOptions.api?.addEventListener('filterChanged', () => {
			if (update) {
				N_search.value = AggridUtils.getQuickFilter(this._gridOptions);
			}
		});

		N_search.addEventListener('input', () => {
			update = false;

			this.timeout && clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this._gridOptions.api?.setQuickFilter(N_search.value);
			}, 500);
		});
	}

	public static register() {
		customElements.define(SearchBar.tagName, SearchBar);
	}
}

export default SearchBar;
