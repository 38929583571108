import '@css/customElements/navbar-separator.scss';

class NavbarSeparator extends HTMLElement {
	public static readonly tagName: string = 'ap-navbar-separator';

	public connectedCallback() {
		this.innerHTML = '';
	}

	public static register() {
		customElements.define(NavbarSeparator.tagName, NavbarSeparator);
	}
}

export default NavbarSeparator;
