import EditPageModal from '@libs/EditPageModal';

import { account } from '../types/account';

import T_modal from '../../tpl/modals/addAccount.html';

import CE_Select from '@libs/customElement/Select';

import Account from '../model/Account';

import AccountsService from '../services/AccountsService';

import '../../css/addAccount.scss';

class AddCompte extends EditPageModal<Partial<account>> {
	private accounting: Partial<account> | null;

	constructor(data?: Partial<account>) {
		super(data || {}, T_modal);
		this.accounting = data || null;
	}

	protected preInit() {
		const N_type = this.element.querySelector<CE_Select>('[name="type"]');

		N_type!.options = {
			data: [{
				id: 'purchase',
				text: 'Achat'
			}, {
				id: 'sale',
				text: 'Vente'
			}]
		};

		const title = this.element.querySelector<HTMLDivElement>('.modal-title');

		if (this.accounting) {
			title!.innerText = 'EDITION COMPTE COMPTABLE';
		} else {
			title!.innerText = 'NOUVEAU COMPTE COMPTABLE';
		}
	}

	protected async closeModal(data: { [key: string]: any }) {
		await new AccountsService().save(data);
		this.resolve(data);
	}

	protected async getDataForm(data: account) {
		return new Account(data).toSave();
	}

	protected checkValidityForm() {
		return this.form?.checkValidity() || false;
	}
}

export default AddCompte;
