import PrintOptionTab from '@libs/customElement/PrintOptionTab';

import T_FormPrintOptions from '../../../tpl/blocks/quoteFormPrintOption.html';

class QuotePrintOption extends PrintOptionTab {
	public static readonly tagName: string = 'ap-quotes-print-options-tab';

	protected readonly KEY_SETTINGS = 'QUOTE.PREF';

	protected eventChangeForm = 'pref.**.change';

	constructor() {
		super();
		this.table = 'quotes';
	}

	protected getFormTemplate() {
		return T_FormPrintOptions;
	}

	protected setDefaultValue(value: { [key: string]: any }) {
		const data = {
			groupDiscount: value.groupDiscount || {},
			details: value.details || {},
			group: value.group || {},
			products: value.products || {},
			subCategory: value.subCategory || {},
			category: value.category || {},
			columns: value.columns || {},
			sum: value.sum || {},
			generalCondition: value.generalCondition || {}
		};

		this.data = data;
	}

	public set data(data: { [key: string]: any }) {
		this.form!.setData({ pref: data });
	}

	public get data() {
		return (this.form!.getData() as { [key: string]: any }).pref;
	}

	public static register() {
		customElements.define(this.tagName, QuotePrintOption);
	}
}

export default QuotePrintOption;
