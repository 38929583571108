import EventEmitter from '@autoprog/eventemitter';
import { global } from '@autoprog/core-client';

import Permissions from './Permissions';

import GridOptionsManager from '@managers/GridOptionsManagers';
import TemplateMobileManager from '@managers/TemplateMobileManager';

import AgGridStateSaver from './agGrid/StateSaver';
import ConfigManager from './ConfigManager';

import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

type Data = {
	data: any[]
	settings: { [key: string]: any }
};

class GridAddedit extends EventEmitter {
	private table: string;
	private keyRef: string;
	private data: Data;
	private N_el: HTMLElement;
	private gridOptions: GridOptions = {};
	private configGrid: any = {};

	constructor(table: string, data: Data, keyRef: string, N_el: HTMLElement) {
		super();

		this.table = table;
		this.keyRef = keyRef;
		this.data = data || { data: [], settings: {} };
		this.N_el = N_el;

		this.configGrid = ConfigManager.getInstance().getConfig(this.table);

		if (global.IS_MOBILE) {
			this.initMobile();
		} else {
			this.initGrid();
			this.initButton();
		}
	}

	private initGrid() {
		this.gridOptions = GridOptionsManager.getInstance().get(this.table, {
			rowDragManaged: true,
			rowData: this.data.data || [],
			getContextMenuItems: (params) => {
				return [{
					name: 'Ajouter une ligne',
					icon: '<i class="icon icon-solid-plus"></i>',
					action: () => {
						this.addRowAgGridTable(params);
					}
				}, {
					name: 'Éditer',
					disabled: !params.node,
					icon: '<i class="icon icon-edit"></i>',
					action: () => {
						this.editRowAgGridTable({
							value: params.node.data._id,
							data: params.node.data,
							node: params.node
						});
					}
				}];
			},
			onGridReady: () => {
				const stateSaver = new AgGridStateSaver(this.gridOptions as any, this.table);
				stateSaver.setData(this.data.settings || {});
			}

		});

		if (this.gridOptions.columnDefs) {
			this.gridOptions.columnDefs.unshift({
				headerName: '',
				pinned: 'left',
				rowDrag: true,
				width: 50,
				suppressColumnsToolPanel: true,
				resizable: false,
				filter: false,
				suppressMovable: true,
				suppressSizeToFit: true
			});
		}

		if (this.gridOptions.columnDefs) {
			this.gridOptions.columnDefs.push({
				headerName: 'Action',
				field: '_id',
				headerClass: 'ag-theme-custom-text-center',
				width: 100,
				pinned: 'right',
				suppressSizeToFit: true,
				suppressMovable: true,
				sortable: false,
				suppressFiltersToolPanel: true,
				floatingFilter: false,
				suppressColumnsToolPanel: true,
				cellRenderer: (params) => {
					const N_div = document.createElement('div');

					const N_delete = document.createElement('button');

					N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
					N_delete.setAttribute('confirmation', '');
					N_delete.setAttribute('tooltip', 'Supprimer');
					N_delete.setAttribute('permission', `${Permissions[this.table]}.DELETE`);

					N_delete.type = 'button';

					N_delete.addEventListener('click', async () => {
						this.deleteRowAgGridTable(params);
					});

					const N_edit = document.createElement('button');
					N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
					N_edit.setAttribute('tooltip', 'Éditer');
					N_edit.setAttribute('permission', `${Permissions[this.table]}.OPEN`);
					N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

					N_edit.type = 'button';

					N_edit.addEventListener('click', async () => {
						this.editRowAgGridTable(params);
					});

					N_div.appendChild(N_edit);
					N_div.appendChild(N_delete);

					return N_div;
				}
			} as ColDef);
		}

		const grid = this.N_el.querySelector('.grid') as HTMLElement;

		new Grid(grid, this.gridOptions, { modules: AllModules });
	}

	private initButton() {
		const N_add = this.N_el.querySelector('#add') as HTMLButtonElement;
		N_add.setAttribute('permission', `${Permissions[this.table]}.ADD`);

		N_add.addEventListener('click', () => {
			this.addRowAgGridTable(this.gridOptions);
		});
	}

	private async initMobile() {
		this.N_el.classList.add('position-relative');

		this.N_el.innerHTML = '';

		const N_add = document.createElement('button');

		N_add.type = 'button';
		N_add.classList.add('add-mobile', 'btn', 'btn-success');
		N_add.innerHTML = '<i class="icon icon-solid-plus"></i>';

		N_add.addEventListener('click', () => {
			this.addRowAgGridTable({
				api: {
					applyTransaction: (data: { add: any[] }) => {
						for (const item of data.add) {
							const N_item = document.createElement('div');

							N_item.innerHTML = TemplateMobileManager.get(this.table, item);

							N_item.addEventListener('click', (e) => {
								const target = e.target as HTMLElement;

								if (!(target && target.dataset.cancelModal === 'true')) {
									this.editRowAgGridTable({
										value: item._id,
										data: item
									});
								}
							});

							this.N_el.appendChild(N_item);
						}
					}
				}
			});
		});

		this.N_el.appendChild(N_add);

		if (this.data.data.length) {
			for (const item of this.data.data) {
				const N_item = document.createElement('div');

				N_item.innerHTML = TemplateMobileManager.get(this.table, item);

				N_item.addEventListener('click', (e) => {
					const target = e.target as HTMLElement;

					if (!(target && target.dataset.cancelModal === 'true')) {
						this.editRowAgGridTable({
							value: item._id,
							data: item,
							node: {
								setData: (data: { [key: string]: any }) => {
									N_item.innerHTML = TemplateMobileManager.get(this.table, data);
								}
							}
						});
					}
				});

				this.N_el.appendChild(N_item);
			}
		} else {
			const N_noData = document.createElement('div');

			N_noData.classList.add('text-muted', 'd-flex', 'justify-content-center', 'h3');

			N_noData.innerHTML = 'Aucune données';

			this.N_el.appendChild(N_noData);
		}
	}

	public get api() {
		return this.gridOptions.api;
	}

	private addRowAgGridTable(params: any) {
		this.emit('add', params, this.configGrid, this.table, this.keyRef);
	}

	private editRowAgGridTable(params: any) {
		this.emit('edit', params, this.configGrid, this.table);
	}

	private deleteRowAgGridTable(params: any) {
		this.emit('delete', params, this.table);
	}
}

export default GridAddedit;
