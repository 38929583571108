// CORE
import { DOM, Form, LoggedUser } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/AddQuote/editPage/generalInformations.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
import Utils from '@js/libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Customer from '@services/Customer/CustomerService';
import S_Quote from '@services/QuoteService';
import S_User from '@services/User/UserService';

type GeneralInformationData = {
	infos: {
		number: string,
		customer: string,
		contact: string,
		email: string,
		manager: string
		date: Moment,
	}
};

class GeneralInformation extends Modal {
	private previousCallback: null | (() => void) = null;
	private nextCallback: null | ((data: any) => void) = null;

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	private date: Moment;

	constructor(data: GeneralInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;
		this.date = data.infos.date;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;

			if (this.nextCallback) {
				N_save.innerHTML = 'Suivant';
			}

			if (this.previousCallback) {
				N_previous.classList.remove('d-none');
			}

			N_previous.addEventListener('click', () => {
				if (this.previousCallback) {
					this.previousCallback();
				}
			});

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);
					data.infos.contact = await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact);
					data.infos.manager = await S_User.getInstance().getDataToSelect2ByID(data.infos.manager);

					if (this.nextCallback) {
						this.nextCallback(data);
					}

					DOM.nextTick(() => {
						this.resolve(data);
					});
				}));
			});

			this.updateButtonSave();
		});
	}

	public setPreviousCallback(cb: () => void) {
		this.previousCallback = cb;
		return this;
	}

	public setNextCallback(cb: (data: any) => void) {
		this.nextCallback = cb;
		return this;
	}

	private initEvents() {
		const N_updateNumber = this.element.querySelector('#updateNumber') as HTMLButtonElement;

		this.selectPostinit['infos.customer'].on('change', async (customerID) => {
			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customerID as string);

			if (contacts.length && customerID) {
				const firstContact = await S_C_Contact.getInstance().getById(contacts[0].id);

				this.form?.setDataByName('infos.contact', contacts[0]);
				this.form?.setDataByName('infos.email', firstContact.mail);
			} else {
				this.form?.setDataByName('infos.contact', { id: '', text: '' });
				this.form?.setDataByName('infos.email', '');
			}
		});

		this.selectPostinit['infos.contact'].on('change', async (contactID) => {
			const contact = await S_C_Contact.getInstance().getById(contactID as string);
			this.form?.setDataByName('infos.email', contact.mail);
		});

		N_updateNumber.addEventListener('click', async () => {
			const N_number = this.element.querySelector('[name="infos.number"]') as HTMLInputElement;
			N_number.value = await S_Quote.getInstance().getNewNumberByCompany(this.date, Utils.companyID);
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;
		const N_manager = this.element.querySelector('[name="infos.manager"]') as CE_Select2;

		const loggedUser = LoggedUser.getInstance();

		N_customer.create(this.element);

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		N_manager.create(this.element, { disabled: !loggedUser.hasPermission('QUOTES.CHANGE_MANAGER') });

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.contact'] = N_contact;
		this.selectPostinit['infos.manager'] = N_manager;
	}

	private async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				number: data.infos.number,
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact),
				email: data.infos.email,
				manager: await S_User.getInstance().getDataToSelect2ByID(data.infos.manager)
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}

		this.selectPostinit['infos.customer'].on('change', () => {
			this.updateButtonSave();
		});
	}

	private updateButtonSave() {
		const customer = this.form?.getDataByName('infos.customer') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !customer;
	}
}

export default GeneralInformation;
