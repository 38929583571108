import GenericService from '../GenericService';

class SiteService extends GenericService {
	private static instance: SiteService | null = null;

	constructor() {
		super('sites-contacts');
	}

	public static getInstance(): SiteService {
		if (!SiteService.instance) {
			SiteService.instance = new SiteService();
		}

		return SiteService.instance;
	}
}

export default SiteService;
