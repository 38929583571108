// CORE
import { toaster } from '@autoprog/core-client';

// NODE_MODULE
import h from 'hyperscript';

// LIBS
import AggridUtils from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import SelectEditor from '@libs/agGrid/new/SelectEditor';

import BrandCellRenderer from '@modules/Products/js/libs/BrandCellRenderer';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';
import QuoteIconCellRenderer from '@modules/Quotes/js/libs/cellRenderer/QuoteIconCellRenderer';
import TaxeCellRenderer from '@modules/Comptabilité/js/libs/TaxeCellRenderer';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';

import Settings from '@modules/Settings/js/libs/Settings';

// MODAL
import M_AddEditProduct from '@modules/Products/js/modals/AddEditProduct';
import M_SelectProduct from '@modules/Products/js/modals/SelectProduct';

// SERVICE
import S_ProductProvider from '@services/Product/ProductProviderService';
import S_Products from '@services/Product/ProductService';
import S_Provider from '@services/Provider/ProviderService';
import UnitCellRenderer from '@modules/Units/js/libs/UnitCellrenderer';

class ContentOrderProviderTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-provider-content-tab';

	private selectorTab: string = '';
	private idTab: string = '';
	private _isLock: boolean = false;
	private _form = (): any => { return {}; };
	private _deleteDeliveryprice = (): void => { };

	private N_grid: CE_AgGrid | null = null;

	private N_el: HTMLElement | null = null;

	public async connectedCallback() {
		this.idTab = ContentOrderProviderTab.tagName;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-edit/line" icon-active="file-edit/fill" text="Contenus"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid?.sizeColumnsToFit();
			this.getInfos();
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		this.N_el = document.createElement('div');

		this.N_el.classList.add('page-tabs-container', 'tab-pane');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Contenus
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>

			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
        `;

		this.N_grid = this.N_el.querySelector<CE_AgGrid>('#grid');

		N_container.append(this.N_el);

		this.initGrid();
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	private get provider() {
		return this._form().infos.provider;
	}

	private get deliveryPrice() {
		return this._form().infos.deliveryPrice;
	}

	private async getProductProvider(product: string) {
		if (this.provider && product) {
			try {
				const id = await S_ProductProvider.getInstance().getByProductAndProvider(product, this.provider);
				const { data } = await S_ProductProvider.getInstance().getDataToModal(id);

				return data.data;
			} catch (e) {
				toaster.error('Produit Fournisseur introuvable');
			}
		}

		return {};
	}

	private initGrid() {
		this.N_grid?.setGridOptions({
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: (params) => {
					return !params.node.rowPinned && !params.node.data.typeLine && !this._isLock;
				},
				resizable: true
			},
			columnDefs: [
				{
					headerName: '',
					width: 60,
					rowDrag: true,
					resizable: true,
					suppressMovable: true,
					suppressSizeToFit: true,
					pinned: 'left',
					field: 'quoteCustomer',
					editable: false,
					cellRenderer: QuoteIconCellRenderer
				},
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: 'reference',
					suppressSizeToFit: true,
					cellEditor: SelectEditor,
					cellEditorParams: {
						options: {
							table: 'products',
							refDataFunction: () => {
								return { provider: this.provider };
							}
						}
					},
					colSpan: (params) => {
						return params.node?.rowPinned ? 9 : 1;
					},
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return `<span class="font-weight-bold">${params.data.text}</span> : ${params.data.value}`;
						} else {
							const cellRenderer = new ProductCellRenderer();
							cellRenderer.init(params);
							return cellRenderer.getGui();
						}
					},
					cellClass: (params) => {
						return params.node.rowPinned ? ['ag-text-number'] : 'ag-select-editor';
					},
					editable: (params) => {
						return !params.data.quoteCustomer && !this._isLock && !params.data.typeLine;
					}
				},
				{
					headerName: 'Marque',
					field: 'brand',
					width: 120,
					cellEditor: SelectEditor,
					cellClass: 'ag-select-editor',
					cellEditorParams: {
						options: {
							table: 'brands'
						}
					},
					cellRenderer: BrandCellRenderer
				},
				{
					field: 'label',
					headerName: 'Libellé',
					cellRenderer: (params) => {
						if (params.data.typeLine === 'bill-address') {
							return '<span class="text-muted">Adresse Facturation</span>';
						} else if (params.data.typeLine === 'delivery-address') {
							return '<span class="text-muted">Adresse Livraison</span>';
						} else if (params.data.typeLine === 'delivery-price') {
							return '<span class="text-muted">Frais de port</span>';
						} else {
							return params.value;
						}
					}
				},
				{
					field: 'quoteProvider',
					headerName: 'Devis de référence',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (!params.node.rowPinned) {
							const N_refresh = h<CE_Button>('ap-button.btn-action-aggrid.ml-auto', { attrs: { confirmation: 'true', type: 'reload', tooltip: 'Actualiser le devis de référence' } });

							N_refresh.disabled = !params.data.reference;

							N_refresh.addEventListener('click', async () => {
								const data = await this.getProductProvider(params.data.reference);

								if (data.quoteBase) {
									params.node.setDataValue('quoteProvider', data.quoteBase);
									params.api?.refreshCells({ force: true, rowNodes: [params.node] });
								} else {
									toaster.error('Devis introuvable');
								}
							});

							const N_div = h<HTMLElement>('div.container-action-aggrid.w-100', params.value || '', N_refresh);

							return N_div;
						}

						return '';
					}
				},
				{
					field: 'unit',
					headerName: 'U',
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: UnitCellRenderer,
					cellEditor: SelectEditor,
					cellClass: 'ag-select-editor',
					cellClassRules: {
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					cellEditorParams: {
						options: {
							table: 'units'
						}
					},
					valueGetter: (params) => {
						return params.data.unit || '';
					}
				},
				{
					field: 'quantity',
					headerName: 'Qté',
					width: 80,
					suppressSizeToFit: true,
					cellClassRules: {
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					valueGetter: (params) => {
						if (params.data.typeLine === 'delivery-price') {
							return 1;
						}

						return params.data.quantity || 0;
					},
					cellRenderer: NumericCellRenderer
				},
				{
					field: 'price',
					headerName: 'P.U.',
					width: 200,
					suppressSizeToFit: true,
					cellClassRules: {
						'cell-invalid': (params: any) => {
							return !params.value && !params.node.rowPinned && !params.data.typeLine && params.data.reference;
						},
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					valueGetter: (params) => {
						if (params.data.typeLine === 'delivery-price') {
							return this.deliveryPrice;
						}

						return params.data.price || 0;
					},
					cellRenderer: (params) => {
						const N_refreshPrice = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'reload', tooltip: 'Appliquer prix fournisseur' } });

						N_refreshPrice.disabled = !params.data.reference;

						N_refreshPrice.addEventListener('click', () => {
							if (this.provider && params.data.reference) {
								this.getProductProvider(params.data.reference).then((data) => {
									if (data.purchasePrice) {
										params.node.setDataValue('price', data.purchasePrice);

										params.api?.refreshCells({ force: true, rowNodes: [params.node] });

										this.dispatchEvent(new CustomEvent('update'));
									} else {
										toaster.error('Aucun prix renseigné pour le fournisseur');
									}
								}).catch(() => {
									toaster.error('Aucun prix renseigné pour le fournisseur');
								});
							}
						});

						const N_value = h('div.h-100', Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber('€', 4));
						return h<HTMLElement>('div.numericCellRenderer', N_value, N_refreshPrice);
					}
				},
				{
					headerName: 'TVA',
					field: 'tva',
					width: 80,
					suppressSizeToFit: true,
					editable: true,
					cellClass: 'text-right text-monospace ag-select-editor',
					cellClassRules: {
						'cell-invalid': (params: any) => {
							return !params.value && params.data.price;
						}
					},
					cellEditor: SelectEditor,
					cellEditorParams: {
						options: {
							table: 'taxes',
							refData: {
								type: 'purchase'
							}
						}
					},
					cellRenderer: TaxeCellRenderer
				},
				{
					headerName: 'Montant HT',
					width: 200,
					cellClass: 'text-monospace text-right',
					editable: false,
					suppressSizeToFit: true,
					cellClassRules: {
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					valueGetter: (params) => {
						if (params.data.typeLine === 'delivery-price') {
							return this.deliveryPrice;
						}
						if (params.data.price) {
							let result = Decimal.setDisplayNumber(params.data.price);
							result = result.times(params.data.quantity || 0);

							if (params.data.type === 'discount') {
								result = result.times(-1);
							}

							return result.toDecimalPlaces(2).toNumber();
						}

						return '';
					},
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 2,
						suffix: '€'
					}
				},
				{
					field: '#',
					headerName: 'Action',
					width: 110,
					resizable: true,
					suppressMovable: true,
					suppressSizeToFit: true,
					pinned: 'right',
					editable: false,
					cellRenderer: (params) => {
						if (!params.node.rowPinned && !this._isLock) {
							const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete', tooltip: 'Supprimer' } });

							N_delete.addEventListener('click', () => {
								params.api.updateRowData({
									remove: [params.node.data]
								});

								if (params.data.typeLine === 'delivery-price') {
									this._deleteDeliveryprice();
								}

								this.dispatchEvent(new CustomEvent('update'));
							});

							const N_discount = h<CE_Button>('ap-button.btn-action-aggrid.btn-switch', { attrs: { confirmation: 'true', icon: 'price-tag-3/line', type: 'default' } });

							N_discount.disabled = !!params.data.typeLine;
							N_discount.tooltip = 'Remise';

							if (params.data.type === 'discount') {
								N_discount.setAttribute('tooltip', 'Enlever Remise');
								N_discount.icon = 'price-tag-3/fill';
							} else {
								N_discount.setAttribute('tooltip', 'Appliquer Remise');
								N_discount.icon = 'price-tag-3/line';
							}

							N_discount.addEventListener('click', () => {
								params.data.type = params.data.type === 'discount' ? '' : 'discount';
								params.node.setData(params.data);
								params.api?.refreshCells({ force: true, rowNodes: [params.node] });

								this.dispatchEvent(new CustomEvent('update'));
							});

							const N_div = h<HTMLElement>('div.container-action-aggrid.w-100', N_discount, N_delete);

							return N_div;
						} else {
							return '';
						}
					}
				}
			],
			getContextMenuItems: (params) => {
				const result: any[] = [
					...AggridUtils.contextMenuAddLines(params, async () => {
						return {
							tva: await this.getProviderTva() || this.tva
						};
					}, this._isLock),
					{
						name: 'Produits',
						disabled: this._isLock,
						icon: '<i class="icon icon-solid-box-open"></i>',
						subMenu: [{
							name: 'Sélectionner',
							action: () => {
								new M_SelectProduct().open().then(async ({ products, groups }) => {
									const data = [];

									for (const id in products) {
										const product = products[id].product;
										const quantity = products[id].quantity;

										const productProvider = await this.getProductProvider(product._id);

										data.push({
											reference: product._id,
											quantity,
											unit: product.unit || 'U',
											brand: product.brand,
											price: productProvider.purchasePrice || '',
											label: productProvider.provider_label || product.label || '',
											quoteProvider: productProvider.quoteBase || '',
											tva: await this.getProviderTva() || this.tva
										});
									}

									for (const key in groups) {
										data.push({
											label: groups[key][0].name
										});

										for (const item of groups[key]) {
											const product = item.product;
											const quantity = item.quantity;

											if (product._id) {
												const productProvider = await this.getProductProvider(product._id);

												data.push({
													reference: product._id,
													quantity,
													unit: product.unit || 'U',
													brand: product.brand,
													price: productProvider.purchasePrice || '',
													label: productProvider.provider_label || product.label || '',
													quoteProvider: productProvider.quoteBase || '',
													tva: await this.getProviderTva() || this.tva
												});
											}
										}
									}

									params.api?.applyTransaction({
										add: data
									});

									this.dispatchEvent(new CustomEvent('update'));
								});
							}
						}, {
							name: 'Éditer',
							disabled: !params.node?.data.reference,
							action: () => {
								new M_AddEditProduct(params.node.data.reference).open().then(() => { }).catch(() => { });
							}
						}]
					}
				];

				result.push({
					name: 'Ajout Frais de port',
					disabled: this._isLock,
					action: async () => {
						await this.updateDeliveryPrice();
					}
				});

				result.push({
					name: 'Ajout adresse',
					disabled: this._isLock,
					subMenu: [{
						name: 'Facturation',
						action: () => {
							params.api?.applyTransaction({
								add: [{
									typeLine: 'bill-address'
								}]
							});

							this.dispatchEvent(new CustomEvent('update'));
						}
					}, {
						name: 'Livraison',
						action: () => {
							params.api?.applyTransaction({
								add: [{
									typeLine: 'delivery-address'
								}]
							});

							this.dispatchEvent(new CustomEvent('update'));
						}
					}]
				});

				return result;
			},
			onCellEditingStopped: async (params) => {
				if (['label'].includes(params.column.getColId())) {
					params.node.setDataValue(params.column.getColId(), params.newValue?.trim());
				}

				// Au changement d'un produit
				if (params.column.getColId() === 'reference') {
					if (!params.data.quantity) {
						params.node.setDataValue('quantity', 1);
					}

					const product = await S_Products.getInstance().getById(params.data.reference) || {};

					params.node.setDataValue('unit', product.unit || 'U');
					params.node.setDataValue('brand', product.brand || '');

					const data = await this.getProductProvider(params.data.reference);

					params.node.setDataValue('price', data.purchasePrice || '');
					params.node.setDataValue('tva', await this.getProviderTva() || this.tva);
					params.node.setDataValue('label', data.provider_label || product.label || '');
					params.node.setDataValue('quoteProvider', data.quoteBase || '');
				}

				if (params.column.getColId() === 'quantity') {
					if (params.data.quoteCustomer && Number(params.data.quantity) > Number(params.data.maxQuantity)) {
						params.node.setDataValue('quantity', params.data.maxQuantity);
					}
				}

				if (['price', 'quantity', 'reference'].includes(params.column.getColId())) {
					params.api?.refreshCells({ force: true, rowNodes: [params.node] });
				}

				this.dispatchEvent(new CustomEvent('update'));
			},
			rowData: []
		});
	}

	private async getProviderTva() {
		if (this.provider) {
			const provider = await new S_Provider().getById(this.provider);
			return provider.tva || '';
		}

		return '';
	}

	private get tva() {
		return Settings.getInstance().get('accountings')?.defaultTaxe?.purchase || '';
	}

	public set data(content: any) {
		this.N_grid!.value = content;
		this.N_grid!.pinnedBottomValue = [];
		this.dispatchEvent(new CustomEvent('load'));
	}

	public get data(): any {
		const data = this.N_grid!.value;

		for (const item of data) {
			item.tva = item.tva || this.tva;
		}

		return data;
	}

	public getInfos() {
		let priceHT = new Decimal(0);

		this.N_grid!.forEachNode((node: any) => {
			if (node.data.typeLine === 'delivery-price') {
				const tmp = Decimal.setDisplayNumber(this.deliveryPrice);
				priceHT = priceHT.plus(tmp);
			} else if (node.data.price && node.data.quantity) {
				const tmp = Decimal.setDisplayNumber(node.data.price).toDecimalPlaces(4).times(node.data.quantity || 0).toDecimalPlaces(2);

				if (node.data.type === 'discount') {
					priceHT = priceHT.minus(tmp);
				} else {
					priceHT = priceHT.plus(tmp);
				}
			}
		});

		priceHT = priceHT.toDecimalPlaces(2);

		this.N_grid!.pinnedBottomValue = [{ value: priceHT.setSuffixAndHumanizeNumber('€'), text: 'Total HT' }];

		return { priceHT };
	}

	public async updateDeliveryPrice() {
		let findDeliveryPriceLine = false;

		this.N_grid!.api?.forEachNode((node: any) => {
			if (node.data.typeLine === 'delivery-price') {
				findDeliveryPriceLine = true;
			}
		});

		if (!findDeliveryPriceLine) {
			this.N_grid!.api?.applyTransaction({
				add: [{
					typeLine: 'delivery-price',
					tva: await this.getProviderTva() || this.tva,
					unit: 'default_unit'
				}]
			});
		}

		this.N_grid!.api?.refreshCells({ force: true });

		this.dispatchEvent(new CustomEvent('update'));
	}

	public updateMaterials() {
		if (this.provider) {
			this.N_grid!.api?.forEachNode(async (node) => {
				if (node.data.reference) {
					const data = await this.getProductProvider(node.data.reference);

					node.setDataValue('price', data.purchasePrice || '');
					node.setDataValue('label', data.provider_label || node.data.label || '');

					node.setDataValue('quoteProvider', data.quoteBase || '');
				}
			});

			this.dispatchEvent(new CustomEvent('update'));
		}
	}

	public updateCommandsCustomer(newMaterials: any[]) {
		this.N_grid!.api?.applyTransaction({
			add: newMaterials
		});

		this.updateMaterials();
	}

	public setGetForm(cb: () => string) {
		this._form = cb;
	}

	public setDeleteDeliveryPrice(cb: () => void) {
		this._deleteDeliveryprice = cb;
	}

	public static register() {
		customElements.define(ContentOrderProviderTab.tagName, ContentOrderProviderTab);
	}
}

export default ContentOrderProviderTab;
