import h from 'hyperscript';

import SettingsController from '@modules/Settings/js/libs/SettingsController';

import CE_AgGrid from '@libs/customElement/AgGrid';

import AccountsService from '../services/AccountsService';

import Account from '../model/Account';

import M_AddAccount from '../modals/AddAccount';

class ListAccountTab extends SettingsController {
	private accountsService: AccountsService;

	constructor(el: HTMLElement) {
		super(el);

		this.accountsService = new AccountsService();
	}

	protected async init() {
		this.initAccount();
		this.initButtons();

		this.getData();
	}

	private initAccount() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_grid.setGridOptions({
			suppressContextMenu: true,
			defaultColDef: {
				resizable: true,
				suppressMovable: true
			},
			columnDefs: [{
				headerName: 'Numero de compte',
				field: 'number',
				width: 200,
				suppressSizeToFit: true
			}, {
				headerName: 'Libellé',
				field: 'label'
			}, {
				headerName: 'Type',
				field: 'type',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'Produit',
				field: 'forProduct',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'TVA',
				field: 'forTaxe',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'Taux horaire',
				field: 'forHourlyRate',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: '',
				width: 80,
				pinned: 'right',
				resizable: false,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit' } });

					N_edit.addEventListener('click', () => {
						new M_AddAccount(params.node.data._rowData_).open().then((data) => {
							params.node.setData(new Account(data).toDashboard());
						});
					});

					const N_delete = h('ap-button.btn-action-aggrid', { attrs: { type: 'delete', confirmation: 'true' } });

					N_delete.addEventListener('click', async () => {
						await this.accountsService.delete(params.node.data._id.value);
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit, N_delete);

					return N_div;
				}
			}]
		});
	}

	private initButtons() {
		const N_add_compte = this.el.querySelector<HTMLButtonElement>('#add-account')!;
		const N_grid_account = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_add_compte.addEventListener('click', () => {
			new M_AddAccount().open().then((data) => {
				N_grid_account!.addValues([new Account(data).toDashboard()]);
			});
		});
	}

	private async getData() {
		const accounts = await this.accountsService.getDataToAgGrid();

		const N_grid_account = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_grid_account.value = accounts?.rowData || [];
	}
}

export default ListAccountTab;
