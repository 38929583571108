// CORE
import { toaster } from '@autoprog/core-client';

// NODE_MODULE
import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
// LIBS
import C_Database from '@js/controllers/Database';
import C_Quote from '@modules/Quotes/js/controllers/AddQuotes';

// PRINTER
// UTILS
// MODAL
import M_info from '../modals/Interventions';

// CUSTOM_ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// SERVICE
import S_Interventions from '@services/Interventions';

class Interventions extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Interventions',
			database: 'interventions'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener('click', () => {
			new M_info().open().then(() => {
				this.refreshData();
			});
		}, {
			signal: this.abortSignal
		});
	}

	protected buttonEdit(params: any) {
		new M_info(params.data._id).open().then(() => {
			this.refreshData();
		});
	}

	protected async buttonDuplicate(params: any) {
		try {
			new M_info(params.value).setMode('duplicate').open().then(() => {
				this.refreshData();
			}).catch(() => {
				this.refreshData();
			});
		} catch (error) {
			console.error(error);
			toaster.error('Impossible de dupliquer la donnée.', 'Erreur');
		}
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.data && params.data.infos.state === '1') {
			return {
				'background-color': 'var(--ap-red-50)',
				color: 'var(--ap-red-900)'
			};
		}
		return {
			'background-color': 'white'
		};
	}

	protected getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		const col = _.find(this.config.columns, { key: 'infos.state' }) as any;

		const subMenu = [];

		for (const key in col.object) {
			subMenu.push({
				name: col.object[key],
				icon: params.node?.data.infos.state === key ? '<i class="icon icon-solid-check"></i>' : '',
				action: async () => {
					await S_Interventions.getInstance().save({ _id: params.node.data._id, infos: { state: key } }, { type: 'state' });

					toaster.success('Sauvegarde réussie');

					this.refreshData();
				}
			});
		}

		return [
			...super.getContextMenu(params),
			'separator',
			{
				name: 'État',
				disabled: !params.node,
				subMenu
			}
		];
	}

	protected addOnButtonGrid(params: any) {
		const N_quote = document.createElement('button');

		N_quote.classList.add('h-100', 'py-0', 'btn-transparent');

		N_quote.innerHTML = '<i class="text-dark h5 icon icon-file"></i>';

		if (params.data.contextMenu.quoteId) {
			N_quote.setAttribute('permission', 'QUOTES.EDIT');

			N_quote.setAttribute('tooltip', 'Ouvrir le devis n°' + params.data.contextMenu.quoteNumber);
			N_quote.innerHTML = '<i class="text-dark h5 icon icon-solid-file"></i>';

			N_quote.addEventListener('click', () => {
				C_Quote.open(params.data.contextMenu.quoteId);
			});
		} else {
			N_quote.setAttribute('permission', 'QUOTES.ADD');
			N_quote.setAttribute('tooltip', 'Créer un devis');

			N_quote.addEventListener('click', () => {
				C_Quote.open(null, { intervention: params.data._id });
			});
		}

		return [N_quote];
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: 'affected',
					type: 'switch',
					filters: [{
						value: 'true',
						label: 'Intervention personnelle',
						hideIcon: true
					}]
				}, {
					type: 'separator'
				}, {
					column: 'infos.state',
					type: 'checkbox',
					checkboxListTitle: 'État',
					filters: [{
						value: '0',
						label: 'Garantie'
					}, {
						value: '1',
						label: 'À facturer',
						backgroundColor: 'var(--ap-red-50)'
					}, {
						value: '2',
						label: 'Facturé'
					}, {
						value: '3',
						label: 'Commande'
					}, {
						value: '4',
						label: 'Autre'
					}]
				}
			]
		];
	}
}

export default Interventions;
