type LinkItem = {
	order: number
	icon: string
	name: string
	dataset: { link: string }
};

class NavigationMobileApps {
	private static instance: NavigationMobileApps;

	private linkMap: LinkItem[] = [];

	constructor() {
		this.linkMap = [];
	}

	public static getInstance() {
		if (!NavigationMobileApps.instance) {
			NavigationMobileApps.instance = new NavigationMobileApps();
		}

		return NavigationMobileApps.instance;
	}

	public addLink(item: LinkItem) {
		this.linkMap.push(item);
	}

	public render(N_container: HTMLElement) {
		N_container.innerHTML = '';

		this.linkMap = this.linkMap.sort((a, b) => {
			return a.order - b.order;
		});

		for (const item of this.linkMap) {
			const N_div = document.createElement('div');

			N_div.dataset.link = item.dataset.link;

			N_div.innerHTML = `<i class="icon ${item.icon}"></i>${item.name}`;

			N_container.appendChild(N_div);
		}
	}
}

export default NavigationMobileApps;
