// CORE
// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_template from '../../../tpl/blocks/template_inventory.ejs';

// LIBS
// PRINTER
// UTILS
import Utils from '@libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';

class Stock {
	public async print(data: { [key: string]: any }[], groupKey: string, title: string) {
		const group: { [brand: string]: any } = _.groupBy(data, groupKey + '.formattedValue');

		let result: { [key: string]: any }[] = [];

		const productKey = S_Product.getInstance().referenceKey;

		for (const key in group) {
			group[key] = _.sortBy(group[key], 'product.' + productKey + '.formattedValue');

			for (const item of group[key]) {
				const groupKeyData = _.get(item, groupKey);

				const label = item.product?.label.formattedValue || '';
				const reference = item.product[productKey].formattedValue;

				result.push({
					type: 'product',
					reference: reference.length < 15 ? reference : reference.slice(0, 15 - 3) + '...',
					label: label.length < 70 ? label : label.slice(0, 70 - 3) + '...',
					groupKey: (groupKeyData.formattedValue || 'NON RENSEIGNEE').toUpperCase(),
					locationStock: item.product.locationStock.formattedValue,
					brand: item.product.brand.formattedValue,
					oldQuantity: item.oldQuantity,
					newQuantity: item.newQuantity
				});
			}
		}

		result = _.sortBy(result, ['groupKey', 'reference']) as any[];

		let tmpGroupKey = '';
		for (let i = 0; i < result.length; i++) {
			if (tmpGroupKey !== result[i].groupKey) {
				tmpGroupKey = result[i].groupKey;
				result.splice(i, 0, {
					type: 'groupKey',
					label: tmpGroupKey
				});
				i++;
			}

			if (i % 54 === 0) {
				result.splice(i, 0, {
					type: 'groupKey',
					label: tmpGroupKey
				});

				i++;
			}
		}

		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		const chunkMaterials = _.chunk(result, 54);

		let page = 1;
		for (const chunk of chunkMaterials) {
			N_container.innerHTML += T_template({
				title,
				page: (page++),
				totalPage: chunkMaterials.length,
				data: chunk,
				groupKey
			});
		}

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);

			Utils.removeTooltip();
		}, 1000);
	}
}

export default Stock;
