import StepContent from '@libs/StepModal/StepContent';

import T_Step from '../../../../tpl/modals/orderFromQuote/steps/quote.html';

import '../../../../css/modals/orderFromQuote/steps/quote.scss';

import { Form } from '@autoprog/core-client';
import FormControllerPageID from '@libs/FormControllerPageID';

import CE_AddressDelivery from '@libs/customElement/AddressDelivery';
import CE_AddressDeliveryReadOnly from '@libs/customElement/AddressDeliveryReadonly';
import CE_Button from '@libs/customElement/Button';
import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@libs/customElement/Sites-FinalCustomer';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';

import CE_PageTabsMenu from '@libs/customElement/PageControllerID/PageTabsMenu';

import CE_GroupQuotesTab from '../../../customElements/GroupQuotesTab';
import CE_UsersTab from '../../../customElements/UsersTab';

import S_C_Address from '@services/Customer/CustomerAddressService';
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Customer from '@services/Customer/CustomerService';
import S_Site from '@services/Site/SiteService';

import moment from 'moment';

type QuoteStepData = {
	customer?: string
	quoteID?: string
	contact?: string
	label?: string
	description?: string
	deliveryAddress?: {
		type?: string
		text?: string
		site?: string
		address?: string
		GPSCoordinates?: string
	}
	entryDate?: Date
	date?: Date
	deliveryDate?: Date
	finalCustomer?: string
	sites?: {
		sites: string[]
		siteCustom: string
		hasFinalCustomer: boolean
	}
	constributors?: string[]
	informedPeople?: string[]
	purchaseManager?: string
	planningManager?: string
	price?: number
	selectedGroups?: {
		[groupID: string]: number
	}
};

class QuoteStep extends StepContent {
	private formReadonly: { [key: string]: FormControllerPageID } = {};
	private form: { [key: string]: Form } = {};
	protected selectPostinit: { [key: string]: CE_Select2 | CE_AddressDelivery } = {};

	private data: QuoteStepData = {};

	private currentEdit: { [key: string]: boolean } = {};

	public async init() {
	}

	public getTitle() {
		return 'Devis';
	}

	public getContent() {
		return T_Step;
	}

	public getData() {
		const N_GroupQuotesTab = this.el.querySelector<CE_GroupQuotesTab>(CE_GroupQuotesTab.tagName)!;
		const N_UsersTab = this.el.querySelector<CE_UsersTab>(CE_UsersTab.tagName)!;

		const data = {
			quoteID: this.data.quoteID,
			...this.formReadonly.infos.getData(),
			...this.formReadonly.dates.getData(),
			...this.formReadonly.address.getData(),
			selectedGroups: N_GroupQuotesTab.selectedGroups,
			price: N_GroupQuotesTab.price,
			...N_UsersTab.data
		};

		if (data.entryDate) {
			data.entryDate = data.entryDate.valueOf();
		} else {
			data.entryDate = '';
		}

		if (data.date) {
			data.date = data.date.valueOf();
		} else {
			data.date = '';
		}

		if (data.deliveryDate) {
			data.deliveryDate = data.deliveryDate.valueOf();
		} else {
			data.deliveryDate = '';
		}

		return data;
	}

	public async setData(data: QuoteStepData) {
		this.data = data;

		this.preInit();

		const customerData = await S_Customer.getInstance().getDataToSelect2ByID(data.customer!);

		const N_customer = this.el.querySelector<HTMLElement>('#customer')!;
		N_customer.innerHTML = customerData.text;

		this.initEdit('dates');
		this.initEdit('infos');
		this.initEdit('address');

		const dataInfos = await this.convertDataInfos(data);

		this.formReadonly.infos.setData(dataInfos);
		this.form.infos.setData(dataInfos);

		const N_finalCustomerReadonly = this.el.querySelector<CE_SitesFinalCustomerReadonly>(CE_SitesFinalCustomerReadonly.tagName)!;
		N_finalCustomerReadonly.update(data.sites!.hasFinalCustomer);

		const N_finalCustomer = this.el.querySelector<CE_SitesFinalCustomer>(CE_SitesFinalCustomer.tagName)!;
		N_finalCustomer.setFinalCustomer();

		const dataDates = {
			entryDate: moment(data.entryDate, 'x'),
			date: moment(data.date, 'x'),
			deliveryDate: moment(data.deliveryDate, 'x')
		};

		this.formReadonly.dates.setData(dataDates);
		this.form.dates.setData(dataDates);

		const dataAddress = await this.convertDataAdress(data);

		this.formReadonly.address.setData(dataAddress);
		this.form.address.setData(dataAddress);

		const N_AddressDelivery = this.el.querySelector<CE_AddressDelivery>(CE_AddressDelivery.tagName)!;
		N_AddressDelivery.update(data.deliveryAddress!.type!);

		const N_AddressDeliveryReadOnly = this.el.querySelector<CE_AddressDeliveryReadOnly>(CE_AddressDeliveryReadOnly.tagName)!;
		N_AddressDeliveryReadOnly.update(data.deliveryAddress!.type!);

		const N_GroupQuotesTab = this.el.querySelector<CE_GroupQuotesTab>(CE_GroupQuotesTab.tagName)!;
		N_GroupQuotesTab.setParentElement(this.el);

		const N_UsersTab = this.el.querySelector<CE_UsersTab>(CE_UsersTab.tagName)!;
		N_UsersTab.setParentElement(this.el);

		const N_PageTabsMenu = this.el.querySelector<CE_PageTabsMenu>(CE_PageTabsMenu.tagName)!;
		N_PageTabsMenu.setActive(`#${CE_GroupQuotesTab.tagName}`);

		N_UsersTab.initData({
			constributors: this.data.constributors,
			informedPeople: this.data.informedPeople,
			purchaseManager: this.data.purchaseManager,
			planningManager: this.data.planningManager
		});

		N_GroupQuotesTab.initData(this.data!.quoteID!, this.data!.selectedGroups!);

		this.postInit();
	}

	public checkValidity(): boolean {
		const label = this.formReadonly.infos.getDataByName('label');
		const date = this.formReadonly.dates.getDataByName('date');

		if (!label?.trim() || !date) {
			return false;
		}

		return true;
	}

	private preInit() {
		const N_contact = this.el.querySelector<CE_Select2>('ap-select2-button[name="contact"]')!;
		const N_addressDelivery = this.el.querySelector<CE_AddressDelivery>(CE_AddressDelivery.tagName)!;

		N_contact.setRef({ id_customer: this.data.customer! });
		N_contact.create(this.el);

		N_addressDelivery.create(this.data.customer!, this.el);

		this.selectPostinit.contact = N_contact;
		this.selectPostinit.deliveryAddress = N_addressDelivery;
	}

	private initEdit(id: string) {
		const N_btnEdit = this.el.querySelector<CE_Button>(`[data-btn-edit="${id}"]`)!;
		const N_saveEdit = this.el.querySelector<CE_Button>(`[data-save-edit="${id}"]`)!;
		const N_cancelEdit = this.el.querySelector<CE_Button>(`[data-cancel-edit="${id}"]`)!;

		const N_readonly = this.el.querySelector<HTMLElement>(`[data-readonly-edit="${id}"]`)!;
		const N_container = this.el.querySelector<HTMLElement>(`[data-container-edit="${id}"]`)!;

		this.formReadonly[id] = new FormControllerPageID(N_readonly);
		this.form[id] = new Form(N_container as HTMLFormElement);

		const toggleEdit = () => {
			N_readonly.classList.toggle('d-none');
			N_container.classList.toggle('d-none');
			N_btnEdit.classList.toggle('d-none');
			N_saveEdit.classList.toggle('d-none');
			N_cancelEdit.classList.toggle('d-none');

			this.currentEdit[id] = !this.currentEdit[id];
		};

		N_btnEdit.addEventListener('click', () => {
			toggleEdit();
		});

		N_saveEdit.addEventListener('click', async () => {
			let data = this.form[id].getData() as { [key: string]: any };

			if (this.form[id].checkValidity()) {
				if (id === 'infos') {
					data = await this.convertDataInfos(data);
				}

				if (id === 'address') {
					data = await this.convertDataAdress(data);
				}

				this.formReadonly[id].setData(data);

				if (id === 'address') {
					const N_AddressDelivery = this.el.querySelector<CE_AddressDelivery>(CE_AddressDelivery.tagName)!;
					N_AddressDelivery.update(data.deliveryAddress.type);
				}

				toggleEdit();
			}
		});

		N_cancelEdit.addEventListener('click', () => {
			toggleEdit();
		});

		N_container.classList.toggle('d-none');
		N_saveEdit.classList.toggle('d-none');
		N_cancelEdit.classList.toggle('d-none');
	}

	private async convertDataInfos(data: { [key: string]: any }) {
		const sites: { id: string, text: string }[] = [];

		for (const site of data.sites.sites) {
			sites.push(await S_Site.getInstance().getDataToSelect2ByID(site));
		}

		return {
			contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.contact),
			label: data.label,
			description: data.description,
			finalCustomer: data.finalCustomer,
			sites: {
				sites,
				siteCustom: data.sites.siteCustom,
				hasFinalCustomer: data.sites.hasFinalCustomer
			}
		};
	}

	private async convertDataAdress(data: { [key: string]: any }) {
		return {
			deliveryAddress: {
				type: data.deliveryAddress.type,
				text: data.deliveryAddress.text,
				site: await S_Site.getInstance().getDataToSelect2ByID(data.deliveryAddress.site),
				address: await S_C_Address.getInstance().getDataToSelect2ByID(data.deliveryAddress.address),
				GPSCoordinates: data.deliveryAddress.GPSCoordinates
			}
		};
	}

	protected postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default QuoteStep;
