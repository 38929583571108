import T_modal from '../../tpl/modals/listBillingRequest.html';

import Modal from '@libs/Modal';

import CE_BillingRequestTab from '../customElements/BillingRequestTab';
import CE_PageTabsMenu from '@libs/customElement/PageControllerID/PageTabsMenu';

import S_C_Order from '@services/Customer/CustomerOrderService';

class ListBillingRequest extends Modal {
	private N_BillingRequestTab: CE_BillingRequestTab | null = null;

	constructor(idOrder: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const order = await S_C_Order.getInstance().getById(idOrder);
			const title = await S_C_Order.getInstance().getTitleToModal(order);

			const N_addonTitle = this.element.querySelector<HTMLElement>('.modal-title-addon')!;

			N_addonTitle.innerHTML = title;

			this.N_BillingRequestTab = this.element.querySelector<CE_BillingRequestTab>(CE_BillingRequestTab.tagName)!;

			this.N_BillingRequestTab.setParentElement(this.element);

			this.N_BillingRequestTab.addEventListener('close', () => {
				this.ignoreChangeRoute = true;
				this.resolve();
			});

			this.N_BillingRequestTab.setForceID(idOrder).setMode('order-only-create').initData();

			const N_PageTabsMenu = this.element.querySelector<CE_PageTabsMenu>(CE_PageTabsMenu.tagName)!;
			N_PageTabsMenu.setActive(`#${CE_BillingRequestTab.tagName}`);
		});
	}

	public getElement(): HTMLElement | null {
		return this.element;
	}
}

export default ListBillingRequest;
