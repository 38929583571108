import GenericService from './GenericService';

class InterventionsService extends GenericService {
	private static instance: InterventionsService | null = null;

	constructor() {
		super('interventions');
	}

	public static getInstance(): InterventionsService {
		if (!InterventionsService.instance) {
			InterventionsService.instance = new InterventionsService();
		}

		return InterventionsService.instance;
	}
}

export default InterventionsService;
