// NODE_MODULE
import { Moment } from 'moment';
import h from 'hyperscript';

// LIBS
import AggridUtils from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import SelectEditor from '@libs/agGrid/new/SelectEditor';
import TextareaEditor from '@libs/agGrid/TextareaEditor';

import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';
import QuoteIconCellRenderer from '@modules/Quotes/js/libs/cellRenderer/QuoteIconCellRenderer';
import TaxeCellRenderer from '@modules/Comptabilité/js/libs/TaxeCellRenderer';
import TextareaCellRenderer from '@js/libs/agGrid/TextareaCellRenderer';

// CUSTOM_ELEMENT
import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@js/libs/customElement/Button';
import CE_TVA from '@libs/customElement/TVA';

// SERVICE
import S_Products from '@services/Product/ProductService';
import UnitCellRenderer from '@modules/Units/js/libs/UnitCellrenderer';

import EditBillingRequest from '@modules/OrdersCustomer/js/modals/EditBillingRequest';

import CalculBill from '../libs/CalculBill';

class ContentBillCustomerTab extends HTMLElement {
	public static readonly tagName: string = 'ap-bill-customer-content-tab';

	private selectorTab: string = '';
	private idTab: string = '';
	private _isLock: boolean = false;

	private _orderID: string = '';
	private _billID: string = '';
	private _billType: 'advance' | 'normal' | 'last' = 'normal';
	private _rectifiedContent: (options: any) => Promise<void> = async () => { };

	private _getFormData: () => any = () => { };

	private N_el: HTMLElement | null = null;
	private N_grid: CE_AgGrid | null = null;

	constructor() {
		super();
		this.idTab = ContentBillCustomerTab.tagName;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';
	}

	public async connectedCallback() {
		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-edit/line" icon-active="file-edit/fill" text="Contenus"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
			this.getInfos();
		});
	}

	public set isLock(value: boolean) {
		this._isLock = value;
		this.updateRectifyButton();
	}

	private updateRectifyButton() {
		// Si le document est verrouillé (déjà payé, ou déjà envoyé) : on ne doit pas voir le bouton pour rectifier
		const N_rectify = this.N_el?.querySelector<CE_Button>('#rectify');
		N_rectify?.classList.toggle('d-none', this._isLock);
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		this.N_el = document.createElement('div');

		this.N_el.classList.add('page-tabs-container', 'tab-pane');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Contenus
				<div class="page-tabs-title-right">
					<ap-button class="btn-icon d-none" id="rectify" type="edit" tooltip="Rectifier le contenu"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>

			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
        `;

		this.N_grid = this.N_el.querySelector<CE_AgGrid>('#grid');

		const N_rectify = this.N_el.querySelector<CE_Button>('#rectify');

		N_rectify?.addEventListener('click', async () => {
			// Si le document est verrouillé (déjà payé, ou déjà envoyé) : on ne doit pas pouvoir rectifier
			if (!this._isLock) {
				new EditBillingRequest(this._orderID, this._billID).setMode('rectify-bill-content').setBillType(this._billType).open().then((options: any) => {
					this._rectifiedContent(options);
				});
			}
		});

		if (!this._isLock) {
			N_rectify?.classList.remove('d-none');
		}

		N_container.append(this.N_el);

		this.initGrid();
	}

	private initGrid() {
		this.N_grid!.setGridOptions({
			rowDragManaged: true,
			defaultColDef: {
				editable: false
			},
			getRowHeight: (params: any) => {
				if (params.node.rowPinned) {
					return 31 * params.data.value?.length || 1;
				}

				return 12 + 15 + 17 * (params.data.label || '').split('\n').length;
			},
			columnDefs: [{
				headerName: '',
				rowDrag: true,
				width: 60,
				suppressSizeToFit: true,
				suppressMovable: true,
				resizable: false,
				pinned: 'left',
				field: 'quoteID',
				cellRenderer: QuoteIconCellRenderer
			}, {
				headerName: S_Products.getInstance().columnNameReference,
				field: 'reference',
				width: 170,
				editable: (params) => {
					return !this._isLock && !params.node.rowPinned;
				},
				suppressSizeToFit: true,
				autoHeight: true,
				wrapText: true,
				colSpan: (params) => {
					return params.node?.rowPinned ? 8 : 1;
				},
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						table: 'products'
					}
				},
				cellClass: (params) => {
					return params.node.rowPinned ? ['ag-text-number'] : 'ag-select-editor';
				},
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						const N_div = document.createElement('div');
						N_div.classList.add('d-flex');
						N_div.style.lineHeight = '31px';

						const N_title = document.createElement('div');
						N_title.classList.add('font-weight-bold', 'ml-auto', 'mr-2');

						const N_price = document.createElement('div');
						N_price.classList.add('text-right');

						const title: string[] = [];
						const price: string[] = [];

						for (const item of (params.data.value || [])) {
							title.push(item.title + ' :');
							price.push(item.text);
						}

						N_title.innerHTML = title.join('<br>');
						N_price.innerHTML = price.join('<br>');

						N_div.appendChild(N_title);
						N_div.appendChild(N_price);

						return N_div;
					} else {
						const renderer = new ProductCellRenderer();
						renderer.init(params);
						return renderer.getGui();
					}
				}
			}, {
				field: 'label',
				headerName: 'Libéllé',
				autoHeight: true,
				wrapText: true,
				editable: () => {
					return !this._isLock;
				},
				cellEditor: TextareaEditor,
				cellRenderer: TextareaCellRenderer
			}, {
				field: 'quantity',
				headerName: 'Qté',
				width: 80,
				suppressSizeToFit: true,
				cellEditor: NumericCellEditor,
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: 2,
					ignoreValueZero: true
				}
			}, {
				field: 'unit',
				headerName: 'U',
				width: 80,
				suppressSizeToFit: true,
				cellRenderer: UnitCellRenderer,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						table: 'units'
					}
				}
			}, {
				field: 'price',
				headerName: 'PU',
				width: 100,
				suppressSizeToFit: true,
				cellEditor: NumericCellEditor,
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: 2,
					suffix: '€',
					ignoreValueZero: true
				}
			}, {
				headerName: 'TVA',
				field: 'tva',
				width: 80,
				suppressSizeToFit: true,
				editable: true,
				cellClass: 'text-right text-monospace ag-select-editor',
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: {
						table: 'taxes',
						refData: {
							type: 'sale'
						}
					}
				},
				cellRenderer: TaxeCellRenderer
			}, {
				headerName: 'Total HT',
				width: 100,
				editable: false,
				suppressSizeToFit: true,
				valueGetter: (params) => {
					if (params.data.price && params.data.quantity) {
						return Decimal.setDisplayNumber(params.data.price).times(params.data.quantity).toDecimalPlaces(2).toNumber();
					}
					return 0;
				},
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: 2,
					suffix: '€',
					ignoreValueZero: true
				}
			}, {
				field: '#',
				headerName: '',
				width: 50,
				editable: false,
				suppressSizeToFit: true,
				suppressMovable: true,
				resizable: false,
				pinned: 'right',
				cellRenderer: (params) => {
					if (params.node.rowPinned || this._isLock) {
						return '';
					} else {
						const N_delete = h('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete' } });

						N_delete.addEventListener('click', () => {
							params.api.updateRowData({
								remove: [params.node.data]
							});

							this.dispatchEvent(new CustomEvent('update'));
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_delete);

						return N_div;
					}
				}
			}],
			getContextMenuItems: (params) => {
				return [
					...AggridUtils.contextMenuAddLines(params, () => {
						return {
							tva: CE_TVA.getDefaultID()
						};
					}, this._isLock)
				];
			},

			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			onCellEditingStarted: (params) => {
				if (params.column.getColId() === 'label') {
					params.node.setRowHeight((params.node.rowHeight || 0) + 20);
					params.api.onRowHeightChanged();
				}
			},
			onCellEditingStopped: (params) => {
				if (['label'].includes(params.column.getColId())) {
					params.node.setDataValue(params.column.getColId(), params.newValue.trim());
				}

				if (params.column.getColId() === 'reference') {
					S_Products.getInstance().getById(params.data.reference).then((data) => {
						params.node.setDataValue('quantity', 1);
						params.node.setDataValue('label', data.label);
						params.node.setDataValue('unit', data.unit || 'U');
						params.node.setDataValue('price', data.price);

						params.api.resetRowHeights();

						params.api.refreshCells({
							force: true,
							rowNodes: [params.node]
						});

						this.dispatchEvent(new CustomEvent('update'));
					});
				}

				if (['price', 'quantity', 'label'].indexOf(params.column.getColId()) !== -1) {
					params.api?.resetRowHeights();
					params.api?.refreshCells({
						force: true,
						rowNodes: [params.node]
					});
				}

				this.dispatchEvent(new CustomEvent('update'));
			},
			rowData: []
		});
	}

	public set orderID(orderID: string) {
		this._orderID = orderID;
	}

	public set billID(billID: string) {
		this._billID = billID;
	}

	public set billType(billType: 'advance' | 'normal' | 'last') {
		this._billType = billType;
	}

	public set rectifiedContent(rectifiedContent: (options: any) => Promise<void>) {
		this._rectifiedContent = rectifiedContent;
	}

	public set data(content: any) {
		this.N_grid!.value = content;
		this.N_grid!.pinnedBottomValue = [{}];

		this.getInfos();
	}

	public get data(): any {
		return this.N_grid!.value;
	}

	public async getInfos() {
		const data = this.data;

		const formData = {
			...this._getFormData(),
			content: data
		};

		const calcul = new CalculBill(formData);
		const dataPrice = await calcul.calculatePrice();

		const pinnedRows: any[] = [];

		pinnedRows.push({
			title: 'Total HT',
			text: dataPrice.totalHT.setSuffixAndHumanizeNumber('€')
		});

		pinnedRows.push({
			title: 'TVA',
			text: dataPrice.totalTVA.setSuffixAndHumanizeNumber('€')
		});

		pinnedRows.push({
			title: 'Total TTC',
			text: dataPrice.totalTTC.setSuffixAndHumanizeNumber('€')
		});

		this.N_grid!.pinnedBottomValue = [{ value: pinnedRows }];

		return {
			price: dataPrice.totalHT,
			priceTVA: dataPrice.totalTVA,
			priceTTC: dataPrice.totalTTC
		};
	}

	public setGetFormData(getFormData: () => any) {
		this._getFormData = getFormData;
	}

	public updateDateAvancement(oldDate: Moment, newDate: Moment) {
		this.N_grid!.forEachNode((node) => {
			const label = (node.data.label || '').replace(new RegExp(oldDate.format('DD/MM/YY'), 'gmi'), newDate.format('DD/MM/YY'));
			node.setDataValue('label', label);
		});
	}

	public static register() {
		customElements.define(ContentBillCustomerTab.tagName, ContentBillCustomerTab);
	}
}

export default ContentBillCustomerTab;
