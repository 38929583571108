// CORE
// NODE_MODULE
// TEMPLATE
import T_modal from '@tpl/modals/mobileFilter.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
import M_MobileSelectMultipleFilter from '@libs/modals/MobileSelectMultipleFilter';

// CUSTOM_ELEMENT
// SERVICE

// STYLE
import '@css/modals/mobile-sort-filter.scss';

class MobileFilter extends Modal {
	private filterList: { [key: string]: any } = {}; // la liste des filtres possibles (récupérée depuis le .json correspondant)

	private selectedFilters: { [key: string]: { type: string, value: any } } = {};

	private data: any | undefined = undefined;

	private config: any = {};

	constructor(filterList: { [key: string]: any }, selectedFilters: { [key: string]: { type: string, value: any } }, data: any, config: any) {
		super({
			tpl: T_modal
		});

		this.on('opened', () => {
			this.filterList = filterList;
			this.selectedFilters = selectedFilters;
			this.data = data;
			this.config = config;

			this.renderFilterOptions();

			const N_reset = this.element.querySelector('#reset') as HTMLButtonElement;

			N_reset.addEventListener('click', () => {
				this.selectedFilters = {};
				this.renderFilterOptions();
			});

			const N_apply = this.element.querySelector('#apply') as HTMLButtonElement;

			N_apply.addEventListener('click', () => {
				this.resolve(this.selectedFilters);
			});
		});
	}

	private renderFilterOptions() {
		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;
		N_filterOptions.innerHTML = '';

		for (const key in this.filterList) {
			if (this.filterList[key].type === 'string') {
				this.renderStringFilter(key);
			}

			if (this.filterList[key].type === 'date') {
				this.renderDateFilter(key);
			}

			if (this.filterList[key].type === 'boolean') {
				this.renderBooleanFilter(key);
			}

			if (this.filterList[key].type === 'array') {
				this.renderSelectFilter(key, 'array');
			}

			if (this.filterList[key].type === 'object') {
				if (this.filterList[key].filter === 'multiple') {
					this.renderSelectMultipleFilter(key, 'object');
				} else {
					this.renderSelectFilter(key, 'object');
				}
			}

			if (this.filterList[key].type === 'Decimal' || this.filterList[key].type === 'number') {
				this.renderDecimalNumberFilter(key);
			}

			if (this.filterList[key].type === 'table') {
				if (this.filterList[key].listFilter) {
					this.renderSelectMultipleFilter(key, 'table', this.data);
				} else {
					this.renderStringFilter(key);
				}
			}
		}
	}

	private renderDecimalNumberFilter(key: string) {
		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;

		const N_filterNumberContainer = document.createElement('div') as HTMLDivElement;
		N_filterNumberContainer.classList.add('filter-container');

		const N_filterNumberLabel = document.createElement('div') as HTMLDivElement;
		N_filterNumberLabel.classList.add('filter-label', 'mb-2');
		N_filterNumberLabel.innerHTML = this.filterList[key].name;

		const N_filterNumberInput = document.createElement('input') as HTMLInputElement;
		N_filterNumberInput.classList.add('filter-input-text');
		N_filterNumberInput.type = 'number';
		N_filterNumberInput.placeholder = this.filterList[key].name;

		if (this.selectedFilters[key]) {
			N_filterNumberInput.value = this.selectedFilters[key].value;
		}

		N_filterNumberInput.addEventListener('input', () => {
			if (isNaN(N_filterNumberInput.valueAsNumber)) {
				delete this.selectedFilters[key];
			} else {
				this.selectedFilters[key] = {
					type: 'number',
					value: N_filterNumberInput.valueAsNumber
				};
			}
		});

		N_filterNumberContainer.append(N_filterNumberLabel);
		N_filterNumberContainer.append(N_filterNumberInput);

		N_filterOptions.append(N_filterNumberContainer);
	}

	private renderStringFilter(key: string) {
		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;

		const N_filterStringContainer = document.createElement('div') as HTMLDivElement;
		N_filterStringContainer.classList.add('filter-container');

		const N_filterStringLabel = document.createElement('div') as HTMLDivElement;
		N_filterStringLabel.classList.add('filter-label', 'mb-2');
		N_filterStringLabel.innerHTML = this.filterList[key].name;

		const N_filterStringInput = document.createElement('input') as HTMLInputElement;
		N_filterStringInput.classList.add('filter-input-text');
		N_filterStringInput.type = 'text';
		N_filterStringInput.id = 'filter-' + key;
		N_filterStringInput.placeholder = this.filterList[key].name;

		if (this.selectedFilters[key]) {
			N_filterStringInput.value = this.selectedFilters[key].value;
		}

		N_filterStringInput.addEventListener('input', () => {
			if (!N_filterStringInput.value) {
				delete this.selectedFilters[key];
			} else {
				this.selectedFilters[key] = {
					type: 'string',
					value: N_filterStringInput.value
				};
			}
		});

		N_filterStringContainer.append(N_filterStringLabel);
		N_filterStringContainer.append(N_filterStringInput);

		N_filterOptions.append(N_filterStringContainer);
	}

	private renderDateFilter(key: string) {
		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;

		const N_filterDateContainer = document.createElement('div') as HTMLDivElement;
		N_filterDateContainer.classList.add('filter-container');

		const N_filterDateLabel = document.createElement('div') as HTMLDivElement;
		N_filterDateLabel.classList.add('filter-label', 'mb-2');
		N_filterDateLabel.innerHTML = this.filterList[key].name;

		const N_filterDateInputsContainer = document.createElement('div') as HTMLDivElement;
		N_filterDateInputsContainer.classList.add('d-flex', 'align-items-center');

		const N_filterDateStartPrefix = document.createElement('div') as HTMLDivElement;
		N_filterDateStartPrefix.classList.add('filter-date-prefix', 'mr-2');
		N_filterDateStartPrefix.innerHTML = 'De';

		const N_filterDateEndPrefix = document.createElement('div') as HTMLDivElement;
		N_filterDateEndPrefix.classList.add('filter-date-prefix', 'mr-2');
		N_filterDateEndPrefix.innerHTML = 'à';

		const N_filterDateStartInput = document.createElement('input') as HTMLInputElement;
		N_filterDateStartInput.classList.add('filter-input-date', 'mr-2');
		N_filterDateStartInput.type = 'date';

		const N_filterDateEndInput = document.createElement('input') as HTMLInputElement;
		N_filterDateEndInput.classList.add('filter-input-date');
		N_filterDateEndInput.type = 'date';

		N_filterDateInputsContainer.append(N_filterDateStartPrefix);
		N_filterDateInputsContainer.append(N_filterDateStartInput);

		N_filterDateInputsContainer.append(N_filterDateEndPrefix);
		N_filterDateInputsContainer.append(N_filterDateEndInput);

		if (this.selectedFilters[key]) {
			N_filterDateStartInput.value = this.selectedFilters[key].value.startDate;
			N_filterDateEndInput.value = this.selectedFilters[key].value.endDate;
		}

		N_filterDateStartInput.addEventListener('input', () => {
			this.validateDateFilters(key, N_filterDateStartInput, N_filterDateEndInput);
		});

		N_filterDateEndInput.addEventListener('input', () => {
			this.validateDateFilters(key, N_filterDateStartInput, N_filterDateEndInput);
		});

		N_filterDateContainer.append(N_filterDateLabel);
		N_filterDateContainer.append(N_filterDateInputsContainer);

		N_filterOptions.append(N_filterDateContainer);
	}

	private validateDateFilters(key: string, N_startDateInput: HTMLInputElement, N_endDateInput: HTMLInputElement) {
		const startDate = N_startDateInput.value;
		const endDate = N_endDateInput.value;

		if (!startDate || !endDate) {
			if (!startDate && !startDate) { // Si les deux dates sont vides, on l'autorise, on considère que le filtre n'est juste pas appliqué
				N_startDateInput.classList.remove('border-red');
				N_endDateInput.classList.remove('border-red');
			}

			if (!startDate && endDate) { // Si une des deux dates est vide, on marque la date vide en erreur
				N_startDateInput.classList.add('border-red');
				N_endDateInput.classList.remove('border-red');
			}

			if (startDate && !endDate) { // Si une des deux dates est vide, on marque la date vide en erreur
				N_startDateInput.classList.remove('border-red');
				N_endDateInput.classList.add('border-red');
			}

			delete this.selectedFilters[key];
		} else if (this.datesOrderIsInvalid(startDate, endDate)) { // Si les dates ne sont pas dans l'ordre on marque les deux en erreur (bordure rouge)
			N_startDateInput.classList.add('border-red');
			N_endDateInput.classList.add('border-red');

			delete this.selectedFilters[key];
		} else { // Sinon, quand les dates sont valides, on ajoute le filtre dans la sélection
			N_startDateInput.classList.remove('border-red');
			N_endDateInput.classList.remove('border-red');

			this.selectedFilters[key] = {
				type: 'date',
				value: {
					startDate,
					endDate
				}
			};
		}
	}

	private datesOrderIsInvalid(startDate: string, endDate: string): boolean {
		return new Date(startDate) > new Date(endDate);
	}

	private renderBooleanFilter(key: string) {
		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;

		const N_filterBooleanContainer = document.createElement('div') as HTMLDivElement;
		N_filterBooleanContainer.classList.add('filter-container');

		const N_filterBooleanLabel = document.createElement('div') as HTMLDivElement;
		N_filterBooleanLabel.classList.add('filter-label', 'mb-2');
		N_filterBooleanLabel.innerHTML = this.filterList[key].name;

		// Création du radio button pour le choix "PAR DÉFAUT"

		const N_filterBooleanRadioDefault = document.createElement('input') as HTMLInputElement;
		N_filterBooleanRadioDefault.classList.add('mr-2');
		N_filterBooleanRadioDefault.name = 'filter-' + key;
		N_filterBooleanRadioDefault.type = 'radio';
		N_filterBooleanRadioDefault.id = 'filter-' + key + 'radio-default';

		N_filterBooleanRadioDefault.addEventListener('change', () => {
			delete this.selectedFilters[key];
		});

		const N_filterBooleanRadioDefaultLabel = document.createElement('label') as HTMLLabelElement;
		N_filterBooleanRadioDefaultLabel.classList.add('mr-5', 'filter-radio-label');
		N_filterBooleanRadioDefaultLabel.setAttribute('for', 'filter-' + key + 'radio-default');
		N_filterBooleanRadioDefaultLabel.innerHTML = 'Aucun filtre';

		// Création du radio button pour le choix "TRUE"

		const N_filterBooleanRadioTrue = document.createElement('input') as HTMLInputElement;
		N_filterBooleanRadioTrue.classList.add('mr-2');
		N_filterBooleanRadioTrue.name = 'filter-' + key;
		N_filterBooleanRadioTrue.type = 'radio';
		N_filterBooleanRadioTrue.id = 'filter-' + key + 'radio-true';

		N_filterBooleanRadioTrue.addEventListener('change', () => {
			this.selectedFilters[key] = {
				type: 'boolean',
				value: true
			};
		});

		const N_filterBooleanRadioTrueLabel = document.createElement('label') as HTMLLabelElement;
		N_filterBooleanRadioTrueLabel.classList.add('mr-5', 'filter-radio-label');
		N_filterBooleanRadioTrueLabel.setAttribute('for', 'filter-' + key + 'radio-true');
		N_filterBooleanRadioTrueLabel.innerHTML = '<i class="icon icon-solid-check"></i>';

		// Création du radio button pour le choix "FALSE"

		const N_filterBooleanRadioFalse = document.createElement('input') as HTMLInputElement;
		N_filterBooleanRadioFalse.classList.add('mr-2');
		N_filterBooleanRadioFalse.name = 'filter-' + key;
		N_filterBooleanRadioFalse.type = 'radio';
		N_filterBooleanRadioFalse.id = 'filter-' + key + 'radio-false';

		N_filterBooleanRadioFalse.addEventListener('change', () => {
			this.selectedFilters[key] = {
				type: 'boolean',
				value: false
			};
		});

		const N_filterBooleanRadioFalseLabel = document.createElement('label') as HTMLLabelElement;
		N_filterBooleanRadioFalseLabel.classList.add('mr-5', 'filter-radio-label');
		N_filterBooleanRadioFalseLabel.setAttribute('for', 'filter-' + key + 'radio-false');
		N_filterBooleanRadioFalseLabel.innerHTML = '<i class="icon icon-solid-times"></i>';

		// Initialisation en fonction de la selection
		N_filterBooleanRadioDefault.checked = true;
		if (this.selectedFilters[key]) {
			N_filterBooleanRadioDefault.checked = false;
			N_filterBooleanRadioTrue.checked = this.selectedFilters[key].value;
			N_filterBooleanRadioFalse.checked = !this.selectedFilters[key].value;
		}

		N_filterBooleanContainer.append(N_filterBooleanLabel);
		N_filterBooleanContainer.append(N_filterBooleanRadioDefault);
		N_filterBooleanContainer.append(N_filterBooleanRadioDefaultLabel);
		N_filterBooleanContainer.append(N_filterBooleanRadioTrue);
		N_filterBooleanContainer.append(N_filterBooleanRadioTrueLabel);
		N_filterBooleanContainer.append(N_filterBooleanRadioFalse);
		N_filterBooleanContainer.append(N_filterBooleanRadioFalseLabel);

		N_filterOptions.append(N_filterBooleanContainer);
	}

	private renderSelectFilter(key: string, type: string) {
		const possibleValues: { [key: string]: string } = {};

		const columns = this.config.columns as any[];
		const column = columns.find((item) => item.key === key);

		if (type === 'array') {
			for (const item of column.array) {
				const value = item as string;
				possibleValues[value] = value;
			}
		}

		if (type === 'object') {
			for (const columnKey in column.object) {
				possibleValues[columnKey] = column.object[columnKey];
			}
		}

		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;

		const N_filterSelectContainer = document.createElement('div') as HTMLDivElement;
		N_filterSelectContainer.classList.add('filter-container');

		const N_filterSelectLabel = document.createElement('div') as HTMLDivElement;
		N_filterSelectLabel.classList.add('filter-label', 'mb-2');
		N_filterSelectLabel.innerHTML = this.filterList[key].name;

		const N_filterSelectSelection = document.createElement('div') as HTMLDivElement;
		N_filterSelectSelection.classList.add('d-flex', 'align-items-center', 'scroll-x');

		const N_filterSelectSelect = document.createElement('select') as HTMLSelectElement;
		N_filterSelectSelect.classList.add('filter-select', 'mb-2');

		const N_filterSelectOptionDefault = document.createElement('option') as HTMLOptionElement;
		N_filterSelectOptionDefault.value = '';
		N_filterSelectOptionDefault.selected = true;

		N_filterSelectSelect.append(N_filterSelectOptionDefault);

		for (const possibleValueKey in possibleValues) {
			const N_filterSelectOption = document.createElement('option') as HTMLOptionElement;
			N_filterSelectOption.value = possibleValueKey;
			N_filterSelectOption.innerHTML = possibleValues[possibleValueKey];

			N_filterSelectSelect.append(N_filterSelectOption);
		}

		if (this.selectedFilters[key]) {
			N_filterSelectSelect.value = this.selectedFilters[key].value;
		}

		N_filterSelectSelect.addEventListener('change', () => {
			if (!N_filterSelectSelect.value) {
				delete this.selectedFilters[key];
				N_filterSelectSelection.innerHTML = '';
			} else {
				this.selectedFilters[key] = {
					type,
					value: N_filterSelectSelect.value
				};

				N_filterSelectSelection.innerHTML = '';
				const N_selectedItem = document.createElement('div') as HTMLDivElement;
				N_selectedItem.classList.add('filter-selected-item', 'mr-2', 'text-nowrap');
				N_selectedItem.innerHTML = possibleValues[N_filterSelectSelect.value];
				N_filterSelectSelection.append(N_selectedItem);
			}
		});

		N_filterSelectContainer.append(N_filterSelectLabel);
		N_filterSelectContainer.append(N_filterSelectSelect);
		N_filterSelectContainer.append(N_filterSelectSelection);

		N_filterOptions.append(N_filterSelectContainer);
	}

	private renderLabel(N_label: HTMLElement, N_selection: HTMLElement, selectedKeys: string[], possibleValues: { [key: string]: string }, label: string) {
		N_label.innerHTML = label + '(' + selectedKeys.length + ')';

		N_selection.innerHTML = '';
		for (const selectedKey of selectedKeys) {
			const N_selectedItem = document.createElement('div') as HTMLDivElement;
			N_selectedItem.classList.add('filter-selected-item', 'mr-2');

			N_selectedItem.innerHTML = possibleValues[selectedKey];
			N_selection.append(N_selectedItem);
		}
	}

	private renderSelectMultipleFilter(key: string, type: string, data?: any) {
		const possibleValues: { [key: string]: string } = {};

		if (type === 'table' && data) {
			for (const item of data) {
				if (item[key].trim()) {
					possibleValues[item[key]] = item[key];
				}
			}
		}

		if (type === 'object') {
			const columns = this.config.columns as any[];
			const column = columns.find((item) => item.key === key);

			for (const objectKey in column.object) {
				possibleValues[objectKey] = column.object[objectKey];
			}
		}

		const N_filterOptions = this.element.querySelector('#filter-options') as HTMLDivElement;

		const N_filterSelectMultipleContainer = document.createElement('div') as HTMLDivElement;
		N_filterSelectMultipleContainer.classList.add('filter-container');

		const N_filterSelectMultipleHeaderContainer = document.createElement('div') as HTMLDivElement;
		N_filterSelectMultipleHeaderContainer.classList.add('d-flex', 'align-items-center', 'mb-2');

		const N_filterSelectMultipleLabel = document.createElement('div') as HTMLDivElement;
		N_filterSelectMultipleLabel.classList.add('filter-label');

		const N_filterSelectMultipleSelection = document.createElement('div') as HTMLDivElement;
		N_filterSelectMultipleSelection.classList.add('filter-selection', 'd-flex', 'align-items-center', 'scroll-x');

		this.renderLabel(N_filterSelectMultipleLabel, N_filterSelectMultipleSelection, this.selectedFilters[key]?.value || [], possibleValues, this.filterList[key].name);

		const N_filterSelectMultipleButton = document.createElement('button') as HTMLButtonElement;
		N_filterSelectMultipleButton.classList.add('filter-select-multiple-button', 'ml-auto', 'btn', 'btn-transparent');
		N_filterSelectMultipleButton.type = 'button';
		N_filterSelectMultipleButton.innerHTML = 'Voir tout <i class="icon icon-solid-chevron-right"></i>';

		N_filterSelectMultipleButton.addEventListener('click', () => {
			new M_MobileSelectMultipleFilter(this.selectedFilters[key]?.value || [], possibleValues).open().then((selectedKeys) => {
				if (selectedKeys.length === 0) {
					delete this.selectedFilters[key];
				} else {
					this.selectedFilters[key] = {
						type,
						value: selectedKeys
					};
				}

				this.renderLabel(N_filterSelectMultipleLabel, N_filterSelectMultipleSelection, selectedKeys, possibleValues, this.filterList[key].name);
			});
		});

		N_filterSelectMultipleHeaderContainer.append(N_filterSelectMultipleLabel);
		N_filterSelectMultipleHeaderContainer.append(N_filterSelectMultipleButton);

		N_filterSelectMultipleContainer.append(N_filterSelectMultipleHeaderContainer);
		N_filterSelectMultipleContainer.append(N_filterSelectMultipleSelection);

		N_filterOptions.append(N_filterSelectMultipleContainer);
	}
}

export default MobileFilter;
