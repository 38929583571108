import '@css/customElements/quantity.scss';

class Quantity extends HTMLElement {
	public static readonly tagName: string = 'ap-quantity';

	private disabled: boolean = false;

	private _min: number = 0;
	private _max: number | null = null;

	public async connectedCallback() {
		const name = this.getAttribute('name') as string;

		const max = this.getAttribute('max');
		const min = this.getAttribute('min');

		this._max = max !== null ? Number(max) : null;
		this._min = min !== null ? Number(min) : 0;

		this.innerHTML = `
			<ap-button class="btn-icon subtract" type="default" icon="subtract/line" id="subtract_quantity"></ap-button>
			<input type="number" name="${name}" value="0"  min="${this._min}" max="${this._max === null ? '' : this._max}">
			<ap-button class="btn-icon add" type="default" icon="add/line" id="add_quantity"></ap-button>
        `;

		const N_add = this.querySelector('#add_quantity') as HTMLButtonElement;
		const N_subtract = this.querySelector('#subtract_quantity') as HTMLButtonElement;

		const N_input = this.querySelector('input') as HTMLInputElement;

		N_add.addEventListener('click', () => {
			let quantity = (N_input.valueAsNumber as number || 0);

			quantity++;

			N_input.valueAsNumber = quantity;

			this.dispatchEvent(new Event('change', { bubbles: true }));
			N_input.dispatchEvent(new Event('change', { bubbles: true }));
			this.updateButton();
		});

		N_subtract.addEventListener('click', () => {
			let quantity = (N_input.valueAsNumber as number || 0);

			quantity--;

			N_input.valueAsNumber = quantity;

			this.dispatchEvent(new Event('change', { bubbles: true }));
			N_input.dispatchEvent(new Event('change', { bubbles: true }));
			this.updateButton();
		});

		N_input.addEventListener('input', () => {
			this.dispatchEvent(new Event('change', { bubbles: true }));
			this.updateButton();
		});

		N_input.addEventListener('change', () => {
			this.dispatchEvent(new Event('change', { bubbles: true }));
			this.updateButton();
		});

		this.addEventListener('click', (e) => {
			e.stopImmediatePropagation();
		});

		const instance = this;

		Object.defineProperty(N_input, 'disabled', {
			set: function (disabled) {
				N_add.disabled = disabled;
				N_subtract.disabled = disabled;
				if (disabled) {
					N_input.setAttribute('disabled', disabled);
				} else {
					N_input.removeAttribute('disabled');
				}

				instance.disabled = disabled;
			},
			get: function () {
				return instance.disabled;
			}
		});

		this.updateButton();

		this.removeAttribute('name');
	}

	private updateButton() {
		const N_add = this.querySelector('#add_quantity') as HTMLButtonElement;
		const N_subtract = this.querySelector('#subtract_quantity') as HTMLButtonElement;

		const N_input = this.querySelector('input') as HTMLInputElement;

		N_add.disabled = N_input.valueAsNumber === this._max;
		N_subtract.disabled = N_input.valueAsNumber === this._min;
	}

	public set value(value: number) {
		const N_input = this.querySelector('input') as HTMLInputElement;
		N_input.valueAsNumber = value;
		this.updateButton();
	}

	public get value() {
		const N_input = this.querySelector('input') as HTMLInputElement;
		return N_input.valueAsNumber;
	}

	public set min(value: number) {
		const N_input = this.querySelector('input') as HTMLInputElement;
		N_input.min = value.toString();
		this._min = value;
		this.updateButton();
	}

	public set max(value: number) {
		const N_input = this.querySelector('input') as HTMLInputElement;
		N_input.max = value.toString();
		this._max = value;
		this.updateButton();
	}

	public static register() {
		customElements.define(Quantity.tagName, Quantity);
	}
}

export default Quantity;
