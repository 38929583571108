// CORE
import { Alert, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import Loader from '@libs/Loader';
import OpenDocuments from '@libs/customElement/OpenDocuments';

import Notifications from '@modules/Apps/js/libs/Notifications';

// UTILS
import Utils from '@js/libs/utils/Utils';

import DecimalApps from '@js/libs/utils/Decimal';

// MODAL
import M_AdditionalInformation from '../modals/editPage/AdditionalInformation';
import M_DetailsCommand from '../modals/editPage/DetailsCommand';
import M_GeneralInformation from '../modals/editPage/GeneralInformation';

import M_receipt from '@modules/Deliveries_Receipts/js/modals/receipt/Infos';

// CUSTOM_ELEMENT
import BillTab, { updateFormEvent as updateBillData } from '@modules/BillsProvider/js/customElements/BillsProviderTab';

import CE_ContentTab from '../customElements/ContentTab';
import CE_InputStockTab from '../customElements/InputStockTab';
import CE_OrderPrintOption from '../customElements/OrderPrintOption';

// SERVICE
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Receipt from '@services/ReceiptService';

// TYPES
import { Company } from '@modules/Settings/js/types/company';
import PriceWithPercentModel from '@js/libs/model/_app/PriceWithPercent';

import '../../css/pageControllerID.scss';

class CommandProviderCtrl extends ControllerPageID {
	private N_PrintOptionTab: CE_OrderPrintOption | null = null;
	private N_BillTab: BillTab | null = null;
	private N_InputStockTab: CE_InputStockTab | null = null;
	private N_ContentTab: CE_ContentTab | null = null;

	private N_ButtonReceipt: HTMLButtonElement | undefined;

	private company: Company | undefined;

	private price: { priceHT: DecimalApps, bill: DecimalApps, credit: DecimalApps };

	private infosCancelButton: { numberBill: number };

	private idReceipt: string;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = CommandProviderCtrl.options || {};

		CommandProviderCtrl.options = {};

		this.price = {
			priceHT: new DecimalApps(0),
			bill: new DecimalApps(0),
			credit: new DecimalApps(0)
		};

		this.infosCancelButton = {
			numberBill: 0
		};

		this.idReceipt = '';

		this.reloadAfterSave = true;

		this.routeReturn = 'module/orders/providers';

		this.init('commands-provider', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		CommandProviderCtrl.options = options || {};

		if (id && !ControllerPageID.mode) {
			await OpenDocuments.checkOpen(id, 'commands-provider');
			Router.getInstance().navigate(`module/ordersPage/provider?id=${id}`);
		} else {
			if (ControllerPageID.mode === 'duplicate') {
				ControllerPageID.idDuplicate = id!;
			}

			Router.getInstance().navigate('module/ordersPage/provider');
		}
	}

	protected async init(table: string, id: string) {
		this.initButtonReceipt();

		await super.init(table, id);

		this.initTabs();

		const data = await this.getData();

		this.company = data.company;

		this.setData(data);

		this.updateType();

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		this.updateEditButtons();

		if (!this.id) {
			this.openGeneralInformation(true);
		}

		if (this.options.products && this.id) {
			this.enableSaveButton();
		}

		this.options = {};
	}

	private openGeneralInformation(isFirstOpen = false) {
		const res = {
			infos: {
				provider: this.form?.getDataByName('infos.provider') as string,
				contact: this.form?.getDataByName('infos.contact') as string
			}
		};

		const modal = new M_GeneralInformation(res);

		if (isFirstOpen) {
			modal.setNextCallback(() => {
				this.openDetailsCommand(isFirstOpen);
			});
		}

		modal.open().then(async (data) => {
			let updatePrice = false;

			if (data.infos.provider.id && res.infos.provider.length === 0) {
				updatePrice = true;
			}

			this.setDataForm(data);

			if (updatePrice) {
				this.N_ContentTab?.updateMaterials();
			}

			if (isFirstOpen || res.infos.provider !== data.infos.provider.id) {
				await this.updateProviderAddress();
			}

			if (!isFirstOpen) {
				this.enableSaveButton();
			}

			this.updateTitle();
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openDetailsCommand(isFirstOpen: boolean = false) {
		const res = {
			infos: {
				label: this.form?.getDataByName('infos.label') as string || this.form?.getDataByName('infos.orderCustomerIDs') as string[],
				orderCustomerIDs: this.form?.getDataByName('infos.orderCustomerIDs') as string[],
				sites: this.form?.getDataByName('infos.sites') as string[]
			}
		};

		const provider = this.form?.getDataByName('infos.provider') as string;

		const modal = new M_DetailsCommand(res, provider);

		if (isFirstOpen) {
			modal.setPreviousCallback(() => {
				this.openGeneralInformation(isFirstOpen);
			});
		}

		modal.open().then((data) => {
			this.setDataForm(data);
			this.N_ContentTab?.updateCommandsCustomer(data.newMaterials);
			this.enableSaveButton();
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsCommand = this.el.querySelector('[data-edit="detailsCommand"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsCommand.addEventListener('click', () => {
			this.openDetailsCommand();
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					provider: this.form?.getDataByName('infos.provider') as string,
					deliveryPrice: this.form?.getDataByName('infos.deliveryPrice') as string,
					date: this.form?.getDataByName('infos.date') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment,
					deliveryDate: this.form?.getDataByName('infos.deliveryDate') as Moment,
					user: this.form?.getDataByName('infos.user') as string,
					email: this.form?.getDataByName('infos.email') as string,
					comments: this.form?.getDataByName('infos.comments') as string
				},
				address: {
					id: this.form?.getDataByName('address.id') as string,
					text: this.form?.getDataByName('address.text') as string
				},
				addressDelivery: {
					type: this.form?.getDataByName('addressDelivery.type') as string,
					customer: {
						id: this.form?.getDataByName('addressDelivery.customer.id') as string,
						address: {
							id: this.form?.getDataByName('addressDelivery.customer.address.id') as string,
							text: this.form?.getDataByName('addressDelivery.customer.address.text') as string
						}
					},
					internal: {
						id: this.form?.getDataByName('addressDelivery.internal.id') as string,
						text: this.form?.getDataByName('addressDelivery.internal.text') as string
					}
				},
				company: this.company as Company
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateType();
				this.N_ContentTab?.updateDeliveryPrice();
				this.updateNumber();
				this.updateEditButtons();
				this.enableSaveButton();
			});
		});
	}

	protected initButton() {
		super.initButton();

		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;

		//Gestion de l'évènement pour annuler la commande
		const title = '<i class="icon icon-warning text-danger"></i> Annulation de la commande <i class="icon icon-warning text-danger"></i>';
		const content = '<strong>Attention</strong>, cette opération va supprimer le bon de réception et le stock sera réinitialisé.<br><br>Voulez-vous continuez ?';

		N_cancel.addEventListener('click', () => {
			Alert.confirm(title, content, {
				yesColor: 'validate-modal',
				yesText: 'Valider',
				noColor: 'close-modal',
				noText: 'Fermer'
			}).then(async () => {
				Loader.getInstance().open();
				await this.save();
				await S_P_Order.getInstance().cancelOrder(this.id);
				toaster.success(`Commande N° ${this.form?.getDataByName('infos.number')} annulée`);
				Loader.getInstance().close();
				this.return();
			});
		});

		const N_closeNotification = this.el.querySelector('#close_notification') as HTMLButtonElement;

		if (this.options.notification) {
			(N_closeNotification.parentNode as HTMLElement).classList.remove('d-none');
		}

		N_closeNotification.addEventListener('click', async () => {
			await Notifications.closeNotification(this.options.notification);
			this.return();
		});
	}

	private initTabs() {
		this.initBill();
		this.initContent();
		this.initPreviewPrint();
		this.initReceipt();
	}

	private async initBill() {
		this.N_BillTab = this.el.querySelector(BillTab.tagName) as BillTab;
		this.N_BillTab.setParentElement(this.el);

		this.N_BillTab.addEventListener('update.form', ((e: CustomEvent<updateBillData>) => {
			this.price.bill = e.detail.priceHT;
			this.price.credit = e.detail.priceCredit;

			this.form?.setDataByName('paymentPrice', e.detail.paymentPrice);
			this.form?.setDataByName('notPaymentPrice', e.detail.notPaymentPrice);

			this.updatePrice();
		}) as EventListener);

		this.N_BillTab.addEventListener('load', ((e: CustomEvent<{ number: number }>) => {
			this.infosCancelButton.numberBill = e.detail.number;
			this.updateCancelButton();
		}) as EventListener);
	}

	private async initContent() {
		this.N_ContentTab = this.el.querySelector(CE_ContentTab.tagName) as CE_ContentTab;

		this.N_ContentTab.setParentElement(this.el);

		this.N_ContentTab.addEventListener('update', () => {
			this.price.priceHT = this.N_ContentTab!.getInfos().priceHT;
			this.updatePrice();
			this.enableSaveButton();
		});

		this.N_ContentTab.addEventListener('load', () => {
			this.price.priceHT = this.N_ContentTab!.getInfos().priceHT;
			this.updatePrice();
		});

		this.N_ContentTab!.setDeleteDeliveryPrice(() => {
			this.form?.setDataByName('infos.deliveryPrice', '');
		});

		this.N_ContentTab!.setGetForm(() => {
			return this.form!.getData();
		});
	}

	private initPreviewPrint() {
		this.N_PrintOptionTab = this.el.querySelector(CE_OrderPrintOption.tagName);

		this.N_PrintOptionTab!.setParentElement(this.el);

		this.N_PrintOptionTab!.table = 'commands-provider';

		this.N_PrintOptionTab!.setID(() => {
			return this.id;
		});

		this.N_PrintOptionTab!.setGetData(() => {
			return this.saveData;
		});

		this.N_PrintOptionTab!.setOnUpdate(() => {
			this.enableSaveButton();
		});
	}

	private async initReceipt() {
		this.N_InputStockTab = this.el.querySelector(CE_InputStockTab.tagName) as CE_InputStockTab;

		this.N_InputStockTab.setParentElement(this.el);

		this.N_InputStockTab.addEventListener('load', ((e: CustomEvent<{ stateReceipt: string }>) => {
			this.setDataForm({ receipt: { state: e.detail.stateReceipt } });
			this.updateCancelButton();
		}) as EventListener);
	}

	private get isLock() {
		const sendDate = this.form?.getDataByName('infos.sendDate');

		if (LoggedUser.getInstance().hasPermission('ORDERS._PROVIDERS.EDIT_SENDED')) {
			return false;
		}

		return sendDate || !LoggedUser.getInstance().hasPermission('ORDERS._PROVIDERS.EDIT');
	}

	protected convertData(data: { [key: string]: any; }): { [key: string]: any; } {
		if (data.addressDelivery?.type) {
			const type: { [key: string]: string } = {
				internal: 'Interne',
				customer: 'Direct Client'
			};

			data.addressDelivery.type = {
				id: data.addressDelivery.type,
				text: type[data.addressDelivery.type]
			};
		}

		if (data.receipt?.state) {
			const stateReceipt: { [key: string]: string } = {
				inProgress: 'En cours',
				waiting: 'En attente de réception',
				partial: 'Reçue partiellement',
				total: 'Reçue en totalité'
			};

			data.receipt.state = {
				id: data.receipt.state,
				text: stateReceipt[data.receipt.state]
			};
		}

		return data;
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_ContentTab!.data = data.data.content;

		this.N_PrintOptionTab!.data = data.data.options || {};

		this.updatePrice();

		this.N_InputStockTab!.initData();
		this.N_BillTab!.initData();

		this.updateCancelButton();
		this.loadReceipt();
	}

	protected postInit(): void {
		super.postInit();

		this.N_PrintOptionTab!.postInit();
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		if (newData.infos.date) {
			newData.infos.date = newData.infos.date.format('x');
		}

		if (newData.infos.sendDate) {
			newData.infos.sendDate = newData.infos.sendDate.format('x');
		}

		if (newData.infos.deliveryDate) {
			newData.infos.deliveryDate = newData.infos.deliveryDate.format('x');
		}

		newData.content = this.N_ContentTab!.data;
		newData.options = this.N_PrintOptionTab?.data;

		return newData;
	}

	private async initButtonReceipt() {
		this.N_ButtonReceipt = this.el.querySelector('#create-open-receipt') as HTMLButtonElement;

		this.N_ButtonReceipt.addEventListener('click', () => {
			Utils.removeTooltip();

			if (this.idReceipt) {
				new M_receipt({
					id: this.idReceipt
				}).open().then(() => {
					this.reload();
				}).catch(() => {
					this.reload();
				});
			} else {
				new M_receipt({
					order: this.id
				}).open().then(() => {
					this.reload();
				}).catch(() => {
					this.reload();
				});
			}
		}, {
			signal: this.abortSignal
		});
	}

	private async loadReceipt() {
		this.N_ButtonReceipt = this.el.querySelector('#create-open-receipt') as HTMLButtonElement;

		this.N_ButtonReceipt.innerHTML = '<i class="icon icon-spin icon-solid-spinner"></i>';
		this.N_ButtonReceipt.disabled = true;

		const receipts = await S_Receipt.getInstance().getByOrder(this.id);

		this.idReceipt = receipts[0]?._id;

		if (receipts.length) {
			this.N_ButtonReceipt.setAttribute('tooltip', 'Ouvrir le bon de réception');
			this.N_ButtonReceipt.setAttribute('permission', 'RECEIPTS.OPEN');
		} else {
			this.N_ButtonReceipt.setAttribute('tooltip', 'Créer un bon de réception');
			this.N_ButtonReceipt.setAttribute('permission', 'RECEIPTS.ADD');
		}

		this.N_ButtonReceipt.innerHTML = '<i class="icon icon-solid-truck"></i>';
		this.N_ButtonReceipt.disabled = false;
	}

	private async updateProviderAddress() {
		const provider = this.form?.getDataByName('infos.provider');
		const addresses = await S_P_Address.getInstance().getByProviderToSelect2(provider as string);

		if (addresses.length && provider) {
			const fullAddress = await S_P_Address.getInstance().getFullAddress(addresses[0]?.id);
			this.form?.setDataByName('address.id', addresses[0]);
			this.form?.setDataByName('address.text', fullAddress);
		} else {
			this.form?.setDataByName('address.id', { id: '', text: '' });
			this.form?.setDataByName('address.text', '');
		}
	}

	private updateEditButtons() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsCommand = this.el.querySelector('[data-edit="detailsCommand"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_DetailsCommand.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;

		this.N_ContentTab!.isLock = this.isLock;
	}

	private updatePrice() {
		const notBill = this.price.priceHT.plus(this.price.credit).minus(this.price.bill);

		const notBillTxt = PriceWithPercentModel.calculAndConvertToModel(notBill, this.price.priceHT).getText();
		const billTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.bill, this.price.priceHT).getText();
		const creditTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.credit, this.price.priceHT).getText();

		this.form?.setDataByName('priceHT', this.price.priceHT);
		this.form?.setDataByName('bill.notBill', notBillTxt);
		this.form?.setDataByName('bill.priceHT', billTxt);
		this.form?.setDataByName('bill.priceCredit', creditTxt);
	}

	private updateNumber() {
		const number = this.form?.getDataByName('infos.number');
		const date = this.form?.getDataByName('infos.date');

		//On met à jour le numéro de commande lors du changement de date dans le cas où un numéro existe déjà
		if (number && date) {
			const value = S_P_Order.getInstance().updateNumberByDate(number || '', date);
			this.form?.setDataByName('infos.number', value);
		}
	}

	private updateCancelButton() {
		const sendDate = this.form?.getDataByName('infos.sendDate') as string;
		const stateReceipt = this.form?.getDataOnAllDataByName('receipt.state') as string;

		//S'il n'y a pas de factures et qu'il y a un bon de réception, on peut annuler la commande
		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;
		if (this.infosCancelButton.numberBill !== 0 || stateReceipt === 'total' || stateReceipt === 'partial' || sendDate) {
			N_cancel.classList.add('disabled');
		} else {
			N_cancel.classList.remove('disabled');
		}
	}

	private updateType() {
		const type = this.form?.getDataByName('addressDelivery.type');

		const N_address_customer_elements: NodeListOf<Element> = this.el.querySelectorAll('[address-type="customer"]');
		const N_address_internal_elements: NodeListOf<Element> = this.el.querySelectorAll('[address-type="internal"]');

		if (type === 'customer') {
			for (const N of N_address_internal_elements) {
				!N.classList.contains('d-none') && N.classList.add('d-none');
			}
			for (const N of N_address_customer_elements) {
				N.classList.contains('d-none') && N.classList.remove('d-none');
			}
		}

		if (type === 'internal') {
			for (const N of N_address_customer_elements) {
				!N.classList.contains('d-none') && N.classList.add('d-none');
			}
			for (const N of N_address_internal_elements) {
				N.classList.contains('d-none') && N.classList.remove('d-none');
			}
		}
	}

	/**
	 * Surcharge l'activation du bouton "Sauvegarde" pour permettre de désactiver le bouton "Création/Ouverture de bon de réception" en même temps
	 */
	protected enableSaveButton() {
		super.enableSaveButton();

		this.disableReceiptButton();
	}

	/**
	 * Surcharge la désactivation du bouton "Sauvegarde" pour permettre d'activer le bouton "Création/Ouverture de bon de réception" en même temps
	 */
	protected disableSaveButton() {
		super.disableSaveButton();

		this.enableReceiptButton();
	}

	/**
	 * Surcharge la sauvegarde pour permettre de mettre à jour le bouton d'annulation de commande
	 */
	protected async save() {
		await super.save();

		this.updateCancelButton();
	}

	/**
	 * Désactive le bouton "Création/Ouverture de bon de réception"
	 */
	private disableReceiptButton() {
		if (this.N_ButtonReceipt) {
			this.N_ButtonReceipt.disabled = true;
		}
	}

	/**
	 * Active le bouton "Création/Ouverture de bon de réception"
	 */
	private enableReceiptButton() {
		if (this.N_ButtonReceipt) {
			this.N_ButtonReceipt.disabled = false;
		}
	}
}

export default CommandProviderCtrl;
