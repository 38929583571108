import GenericService from './GenericService';
import Utils from '@libs/utils/Utils';

class HistoryService extends GenericService {
	private static instance: HistoryService | null = null;

	constructor() {
		super('history');
	}

	public static getInstance(): HistoryService {
		if (!HistoryService.instance) {
			HistoryService.instance = new HistoryService();
		}

		return HistoryService.instance;
	}

	public getLastOpen() {
		return this.get_function('getLastOpen', { user: Utils.userID, companyID: Utils.companyID });
	}

	public getHistory(table: string, id: string) {
		return this.get_function('getHistory', { id, table });
	}

	public getLastEvent(table: string, id: string) {
		return this.get_function('getLastEvent', { id, table });
	}
}

export default HistoryService;
