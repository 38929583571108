import h from 'hyperscript';
import { services } from '@autoprog/core-client';

import AddEditGroup from '../modals/groups/AddEditGroup';

import AgGridStateSaver from '@libs/agGrid/StateSaver';
import Permissions from '@libs/Permissions';

import GridOptionsManager from '@js/managers/GridOptionsManagers';

import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';
import CE_GridSidebar from '@libs/customElement/GridSidebar';

import S_Group from '@services/User/GroupService';

class Groups {
	private el: HTMLElement;
	private permissionKey: string;
	private N_grid: CE_Aggrid;
	private abortController: AbortController;

	constructor(el: HTMLElement) {
		this.el = el;
		this.permissionKey = Permissions.groups;

		this.abortController = new AbortController();

		this.N_grid = this.el.querySelector<CE_Aggrid>('#grid')!;

		const N_add = document.querySelector<CE_Button>('#add')!;

		N_add.setAttribute('permission', `${this.permissionKey}.CREATE`);

		N_add.addEventListener('click', () => {
			new AddEditGroup().open().then(() => {
				this.getData();
			});
		}, {
			signal: this.abortController.signal
		});

		this.initGrid();

		this.getData();
	}

	private initGrid() {
		const gridOptionsManager = GridOptionsManager.getInstance();

		const gridOptions = gridOptionsManager.get('groups', {
			sideBar: {
				hiddenByDefault: true
			},
			suppressContextMenu: true
		});

		gridOptions.columnDefs?.push({
			headerName: '',
			filter: false,
			width: 80,
			pinned: 'right',
			suppressSizeToFit: true,
			suppressColumnsToolPanel: true,
			cellRenderer: this.cellRendererActionCol.bind(this)
		});

		this.N_grid.setGridOptions(gridOptions);

		const N_GridSidebar = this.el.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.N_grid!.gridOptions);
	}

	private cellRendererActionCol(params: any) {
		const N_div = h<HTMLElement>('div.container-action-aggrid');

		const N_edit = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.EDIT`, type: 'edit' } });

		N_edit.addEventListener('click', () => {
			this.buttonEdit(params.data._id);
		});

		N_div.appendChild(N_edit);

		const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.DELETE`, confirmation: 'true', type: 'delete' } });

		N_delete.disabled = !!params.data.used;

		N_delete.addEventListener('click', () => {
			this.buttonDelete(params.data._id);
		});

		N_div.appendChild(N_delete);

		return N_div;
	}

	private async getData() {
		this.N_grid.resetValue();

		const { rowData, settings } = await S_Group.getInstance().getDataToAgGrid(this.abortController);
		const usedGroups = await services.GroupsService.getInstance().getUsed();

		for (const group of rowData) {
			if (usedGroups.includes(group._id)) {
				group.used = true;
			}
		}

		this.N_grid.value = rowData;

		const stateSaver = new AgGridStateSaver(this.N_grid.gridOptions, 'groups');
		stateSaver.setData(settings);
	}

	protected buttonEdit(id: string) {
		new AddEditGroup(id).open().then(() => {
			this.getData();
		});
	}

	protected async buttonDelete(id: string) {
		await S_Group.getInstance().delete(id);
		this.getData();
	}

	public destructor() {
		this.abortController.abort('destroy');
	}
}

export default Groups;
