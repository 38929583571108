import ApIcon from '@autoprog/icons';

import S_History from '@services/HistoryService';

import '@css/customElements/traceability.scss';

class Traceability extends HTMLElement {
	public static readonly tagName: string = 'ap-traceability';

	public async connectedCallback() {
		this.innerHTML = `
            <div class="item active" id="create"  tooltip="Création">
				<ap-icon class="icon-spin" name="loader-4/line"></ap-icon>
                <span class="text"> </span>
            </div>

			<div class="separator"></div>

            <div class="item" id="lastSave" tooltip="Dernier enregistrement" >
				<ap-icon class="icon-spin" name="loader-4/line"></ap-icon>
                <span class="text"> </span>
            </div>

			<div class="separator"></div>

            <div class="item" id="lastOpen" tooltip="Dernière ouverture">
				<ap-icon class="icon-spin" name="loader-4/line"></ap-icon>
                <span class="text"></span>
            </div>
			
			<div class="separator"></div>

            <div class="item" id="lastPrint" tooltip="Dernière impression">
				<ap-icon class="icon-spin" name="loader-4/line"></ap-icon>
                <span class="text"></span>
            </div>
        `;
	}

	private async updateElement(el: HTMLElement, icon: string, value: string) {
		const N_span = el.querySelector<HTMLElement>('.text')!;
		const N_icon = el.querySelector<ApIcon>('ap-icon')!;

		N_span.innerHTML = value;

		N_icon.classList.remove('icon-spin');
		N_icon.name = icon;
	}

	public async update(table: string, id: string) {
		const data = await S_History.getInstance().getLastEvent(table, id);

		await this.updateElements(data);
	}

	private async updateElements(lastData: { [key: string]: string }) {
		const N_create = this.querySelector('#create') as HTMLElement;
		const N_lastSave = this.querySelector('#lastSave') as HTMLElement;
		const N_lastOpen = this.querySelector('#lastOpen') as HTMLElement;
		const N_lastPrint = this.querySelector('#lastPrint') as HTMLElement;

		await this.updateElement(N_create, 'add/line', lastData.create);
		await this.updateElement(N_lastSave, 'save-2/line', lastData.lastSave);
		await this.updateElement(N_lastOpen, 'folder-open/line', lastData.lastOpen);
		await this.updateElement(N_lastPrint, 'printer/line', lastData.lastPrint);
	}

	public static register() {
		customElements.define(Traceability.tagName, Traceability);
	}
}

export default Traceability;
