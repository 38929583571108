import GenericService from '@services/GenericService';

type GenericServiceClass = {
	new(): GenericService
	getInstance(): GenericService
};

abstract class ServiceManager {
	private static cache: Map<string, GenericServiceClass> = new Map<string, GenericServiceClass>();

	private constructor() {

	}

	public static register(table: string, ObjClassType: GenericServiceClass) {
		ServiceManager.cache.set(table, ObjClassType);
	}

	public static get(table: string): GenericServiceClass | undefined {
		return this.cache.get(table);
	}
}

export default ServiceManager;
