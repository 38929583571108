import EventEmitter from '@autoprog/eventemitter';

class SaveButton extends HTMLElement {
	public static readonly tagName: string = 'ap-save-button';

	private eventEmitter = new EventEmitter();

	private _click: () => Promise<any> = async () => { };

	public async connectedCallback() {
		this.innerHTML = `
            <button type="button" class="btn btn-save-modal">
				<i class="icon icon-solid-save mr-2"></i>Enregistrer
			</button>
        `;

		const N_button = this.querySelector('button') as HTMLButtonElement;

		N_button.addEventListener('click', async () => {
			this.eventEmitter.emit('save.start');
			this.eventEmitter.emit('save.change', 'start');

			try {
				await N_button.loading(this._click());
				this.eventEmitter.emit('save.end');
				this.eventEmitter.emit('save.change', 'end');
			} catch (e) {
				this.eventEmitter.emit('save.error');
				this.eventEmitter.emit('save.change', 'error');
			}
		});
	}

	public onClick(cb: () => Promise<any>) {
		this._click = cb;
	}

	public on(key: 'save.change' | 'save.start' | 'save.end' | 'save.error', cb: (value: string) => void) {
		this.eventEmitter.on(key, cb);
	}

	public static register() {
		customElements.define(SaveButton.tagName, SaveButton);
	}
}

export default SaveButton;
