import GenericService from '../GenericService';

class ProviderService extends GenericService {
	private static instance: ProviderService | null = null;

	constructor() {
		super('providers');
	}

	public static getInstance(): ProviderService {
		if (!ProviderService.instance) {
			ProviderService.instance = new ProviderService();
		}

		return ProviderService.instance;
	}

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return 'number';
	}
}

export default ProviderService;
