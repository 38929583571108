// CORE
// NODE_MODULE
// TEMPLATE
import T_Modal from '@tpl/modals/VerifSave.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class VerifSave extends Modal {
	constructor() {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_elements = this.element.querySelectorAll('[data-res]') as NodeListOf<HTMLElement>;

			N_elements.forEach((N_el) => {
				N_el.addEventListener('click', () => {
					this.resolve(N_el.dataset.res);
				});
			});
		});
	}
}

export default VerifSave;
