import { CAttribute, DOM } from '@autoprog/core-client';

class EllipsisTooltip extends CAttribute {
	public static attrName: string = 'ellipsis-tooltip';

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		this.addTitle();
	}

	public attributeChanged(name: string): void {
		if (name === EllipsisTooltip.attrName) {
			const el = this.el as HTMLElement;
			el!.setAttribute('title', el!.innerText);
		}
	}

	private addTitle() {
		const el = this.el as HTMLElement;
		el!.setAttribute('title', el!.innerText);
	}

	public destructor() {
		this.el!.removeAttribute(EllipsisTooltip.attrName);
	}

	public static registry() {
		DOM.registerAttribute(EllipsisTooltip as any);
	}
}

export default EllipsisTooltip;
