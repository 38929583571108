import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';

import DashboardController, { DashboardConfigColumns, DashboardOptions } from '@js/controllers/DashboardController';

import ProductCellRenderer from '../libs/ProductCellRenderer';

import CE_SelectCategorie from '../libs/customElement/SelectCategorie';
import CE_SelectProvider from '../libs/customElement/SelectProvider';
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import M_AddEditProduct from '../modals/AddEditProduct';
import M_ViewProduct from '../modals/ViewProduct';

import CreateOrderContextMenu from '@modules/OrdersProvider/js/libs/ProductContextMenu';
import tippy from 'tippy.js';

class ProductsTab extends DashboardController {
	constructor(el: HTMLElement) {
		super(el, 'Produits', 'PRODUCTS', 'products');
	}

	protected buttonAdd() {
		new M_AddEditProduct().open().then(() => {
			this.getData();
		});
	}

	protected buttonEdit(id: string) {
		new M_AddEditProduct(id, 0).open().then(() => {
			this.getData();
		});
	}

	protected buttonView(id: string) {
		new M_ViewProduct(id).open().then((update) => {
			if (update) {
				this.getData();
			}
		});
	}

	protected getTextConfirmDelete(data: { [key: string]: any }) {
		return `Êtes-vous sûr de vouloir supprimer le produit <b>${data.internalCode.formattedValue}</b> ?`;
	}

	protected initHeader(): void {
		super.initHeader();

		const N_SelectCategorie = this.el.querySelector('ap-select-categories') as CE_SelectCategorie;
		N_SelectCategorie.setGridOptions(this.N_grid!.gridOptions);

		const N_SelectProvider = this.el.querySelector('ap-select-providers') as CE_SelectProvider;
		N_SelectProvider.setGridOptions(this.N_grid!.gridOptions);
	}

	protected cellRendererActionIcons(params: any) {
		const N_div = document.createElement('div');
		N_div.classList.add('container-icons-aggrid');

		const cellRenderer = new ProductCellRenderer();
		cellRenderer.init({ params, value: params.data._id.value, isOnlyImage: true });
		N_div.append(cellRenderer.getGui());

		const N_purchasePrice = document.createElement('div');
		N_purchasePrice.classList.add('icon-state');
		N_purchasePrice.innerHTML = '<ap-icon name="alert/line"></ap-icon>';

		const errorTooltip: string[] = [];

		if (params.data.error.price.value || params.data.error.purchasePrice.value || params.data.error.hasNotDefaultProvider.value) {
			N_purchasePrice.style.setProperty('--color', 'var(--ap-red-500)');
			N_purchasePrice.style.setProperty('--bg-color', 'var(--ap-red-100)');
		} else {
			N_purchasePrice.classList.add('disabled');
		}

		if (params.data.error.price.value) {
			errorTooltip.push('&bull; Coût de revient > Prix de vente');
		}

		if (params.data.error.purchasePrice.value) {
			errorTooltip.push('&bull; Aucun prix d\'achat');
		}

		if (params.data.error.hasNotDefaultProvider.value) {
			errorTooltip.push('&bull; Aucun fournisseur par défaut');
		}

		if (errorTooltip.length) {
			tippy(N_purchasePrice, {
				content: errorTooltip.join('<br>'),
				arrow: false,
				allowHTML: true,
				placement: 'right-start'
			});
		}

		const N_deprecated = document.createElement('div');
		N_deprecated.classList.add('icon-state');
		N_deprecated.innerHTML = '<ap-icon name="archive/line"></ap-icon>';

		let deprecatedTooltip: string = '';

		if (params.data.deprecated.value) {
			deprecatedTooltip = 'Obsolète';
			N_deprecated.style.setProperty('--color', 'var(--ap-orange-500)');
			N_deprecated.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		} else {
			N_deprecated.classList.add('disabled');
		}

		if (deprecatedTooltip) {
			tippy(N_deprecated, {
				content: deprecatedTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		N_div.append(N_purchasePrice, N_deprecated);

		return N_div;
	}

	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return data.internalCode.formattedValue;
	}

	protected _getContextMenu(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
		let products = params.api?.getSelectedRows() || [];

		if (products.length === 0) {
			products.push(params.node.data);
		}

		products = products.map((item) => {
			return {
				productID: item._id.value,
				quantity: 1
			};
		});

		return [
			...super._getContextMenu(params),
			'separator',
			CreateOrderContextMenu.orderProduct(products, !params.node)
		];
	}

	protected getWidthColActionIcon(): number {
		return 170;
	}

	protected enabledCheckboxSelection(params: any) {
		return !params.data.deprecated.value;
	}

	protected get optionsColumnsGrid(): DashboardOptions {
		return {
			columns: {
				displayed: {
					icons: true,
					selection: true
				}
			},
			actionButtons: {
				displayed: {
					add: true,
					edit: true,
					view: true,
					duplicate: false,
					delete: true,
					print: false
				}
			}
		};
	}

	protected get configColumns(): DashboardConfigColumns {
		return [
			{
				key: '_id',
				type: 'primaryKey',
				name: 'ID'
			},
			{
				key: 'internalCode',
				type: 'string',
				name: 'Code Interne'
			},
			{
				key: 'reference',
				type: 'string',
				name: 'Référence'
			},
			{
				key: 'label',
				type: 'string',
				name: 'Désignation'
			},
			{
				key: 'price',
				type: 'number',
				name: 'Prix de vente'
			},
			{
				key: 'isDangerous',
				type: 'boolean',
				name: 'Dangereux'
			},
			{
				key: 'compatibility',
				type: 'string',
				name: 'Compatibilité'
			},
			{
				key: 'model',
				type: 'string',
				name: 'Modèle'
			},
			{
				key: 'variation',
				type: 'string',
				name: 'Déclinaison'
			},
			{
				key: 'subVariation',
				type: 'string',
				name: 'Sous-déclinaison'
			},
			{
				key: 'position',
				type: 'string',
				name: 'Position/Repère'
			},
			{
				key: 'forSaleOnline',
				type: 'boolean',
				name: 'Vente en ligne'
			},
			{
				key: 'deprecated',
				type: 'boolean',
				hide: true,
				name: 'Obsolète'
			},
			{
				key: 'error.price',
				type: 'boolean',
				hide: true,
				name: 'Erreur prix de vente'
			},
			{
				key: 'error.purchasePrice',
				type: 'boolean',
				hide: true,
				name: 'Erreur prix d\'achat'
			},
			{
				key: 'error.hasNotDefaultProvider',
				type: 'boolean',
				hide: true,
				name: 'Fournisseur par défaut introuvable'
			},
			{
				key: 'unit',
				type: 'string',
				name: 'Unité'
			},
			{
				key: 'brand',
				type: 'string',
				name: 'Marque'
			},
			{
				key: 'categories',
				type: 'table',
				filter: 'list',
				name: 'Catégories'
			},
			{
				key: 'providers',
				type: 'string',
				name: 'Fournisseur'
			},
			{
				key: 'tags',
				type: 'string',
				name: 'Mots clés'
			},
			{
				key: 'categoryIDs',
				type: 'string',
				hide: true,
				name: 'Catégories IDs'
			},
			{
				key: 'providersIDs',
				type: 'string',
				hide: true,
				name: 'Fournisseur IDs'
			},
			{
				key: 'validate.active',
				type: 'boolean',
				hide: true,
				name: 'Validé'
			},
			{
				key: 'comptaVente',
				type: 'string',
				name: 'Compte comptable de vente'
			},
			{
				key: 'tvaRate',
				type: 'number',
				name: 'TVA'
			},
			{
				key: 'transporter',
				type: 'string',
				name: 'Transporteur'
			},
			{
				key: 'lastUpdatePrice',
				type: 'string',
				name: 'MÀJ P.V.'
			},
			{
				key: 'defaultProvider.lastUpdatePrice',
				type: 'string',
				name: 'MÀJ P.A. par défaut'
			},
			{
				key: 'dimension',
				type: 'number',
				name: 'Dimension'
			},
			{
				key: 'weight',
				type: 'number',
				name: 'Poids'
			},
			{
				key: 'margin',
				type: 'number',
				name: 'Marge'
			},
			{
				key: 'defaultProvider.id',
				type: 'string',
				filter: 'list',
				name: 'Fournisseur par défaut'
			},
			{
				key: 'defaultProvider.reference',
				type: 'string',
				name: 'Référence fournisseur par défaut'
			},
			{
				key: 'defaultProvider.purchasePrice',
				type: 'number',
				name: 'Prix d\'achat par défaut'
			},
			{
				key: 'defaultProvider.costPrice',
				type: 'number',
				name: 'Coût de revient par défaut'
			},
			{
				key: 'defaultProvider.recommendedSellPrice',
				type: 'number',
				name: 'Prix de vente conseillé'
			},
			{
				key: 'stock.hidden',
				type: 'boolean',
				name: 'Stock non compté'
			}
		];
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: 'error.price',
					checkboxListTitle: 'Erreurs :',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'Coût de revient > Prix de vente',
							iconColor: 'red-100'
						}
					]
				}, {
					column: 'error.purchasePrice',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'Aucun prix d\'achat',
							iconColor: 'red-100'
						}
					]
				}, {
					column: 'error.hasNotDefaultProvider',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'Aucun fournisseur par défaut',
							iconColor: 'red-100'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'validate.active',
					type: 'switch',
					filters: [
						{
							value: 'false',
							label: 'En attente de validation',
							hideIcon: true
						}
					]
				}, {
					column: 'deprecated',
					type: 'switch-3',
					filters: [
						{
							values: ['false', 'true'],
							label: 'Obsolète',
							hideIcon: true
						}
					]
				}
			]
		];
	}
}

export default ProductsTab;
