// CORE
import CSV from '@autoprog/csv';
import { Controller } from '@autoprog/core-client';

// NODE_MODULE
import { ColDef } from '@ag-grid-enterprise/all-modules';
import h from 'hyperscript';
import moment from 'moment';

// LIBS
import ExportUtils from '@libs/utils/ExportUtils';

import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';

import CE_Aggrid from '@libs/customElement/AgGrid';

// CONTROLLERS
import C_C_Order from './Orders.Customer';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Stock from '@services/StockService';

class MaterialCustomerController extends Controller {
	private element: HTMLElement;

	private N_Products: CE_Aggrid;
	private N_Orders: CE_Aggrid;

	private dataByProduct: { [key: string]: any[] } = {};
	private dataProducts: { [key: string]: any }[] = [];

	private currentProductRow: { [key: string]: any } = {};

	private locations: { [key: string]: any }[] = [];

	constructor(el: HTMLElement) {
		super(el);

		this.element = el;

		this.N_Products = this.element.querySelector<CE_Aggrid>('#products .grid')!;
		this.N_Orders = this.element.querySelector<CE_Aggrid>('#orders .grid')!;

		this.getLocations().then(() => {
			this.init();
			this.initEvents();
			this.getData();
		});
	}

	private async getLocations() {
		this.locations = await S_Stock.getInstance().getStockToCommandCustomer();
	}

	private init() {
		this.N_Products.setGridOptions({
			localeText: { noRowsToShow: 'Pas de produit' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			rowSelection: 'single',
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Référence',
					field: 'reference',
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					}
				},
				{
					headerName: 'Marque',
					field: 'brand',
					filter: 'agSetColumnFilter'
				},
				{
					headerName: 'Quantité globale',
					field: 'globalQuantity',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				}
			],
			onSelectionChanged: (params) => {
				const selectedRows = params.api.getSelectedRows();

				this.currentProductRow = selectedRows[0];

				const N_exportByProduct = this.element.querySelector('#exportByProduct') as HTMLButtonElement;
				N_exportByProduct.removeAttribute('disabled');

				const N_productSelected = this.element.querySelector('#product-selected') as HTMLElement;
				N_productSelected.innerHTML = `Produit selectionné : ${this.currentProductRow.reference.value}`;

				this.N_Orders.value = this.dataByProduct[this.currentProductRow.id.value];
			}
		});

		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id,
					width: 120,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						return params.data.stocks[item._id] || 0;
					},
					cellRenderer: (params) => {
						return params.value || 0;
					}
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer',
			width: 120,
			cellClass: ['text-right', 'text-monospace'],
			suppressSizeToFit: true,
			cellRenderer: (params) => {
				return params.value || 0;
			}
		});

		this.N_Orders.setGridOptions({
			localeText: { noRowsToShow: 'Aucune commande ne contient ce produit' },
			rowData: [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true
			},
			columnDefs: [
				{
					headerName: 'N° Commande',
					field: 'orderNumber',
					suppressSizeToFit: true
				},
				{
					headerName: 'Client',
					field: 'customer',
					suppressSizeToFit: true
				},
				{
					headerName: 'Site(s)',
					field: 'sites',
					suppressSizeToFit: true
				},
				{
					headerName: 'Objet de la commande',
					field: 'label'
				},
				{
					headerName: 'Facturé',
					field: 'price',
					width: 200,
					suppressSizeToFit: true
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					width: 80,
					suppressSizeToFit: true
				},
				{
					headerName: 'Sortie stock',
					children: childrenLocation
				},
				{
					headerName: '',
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', tooltip: 'Ouvrir la commande n°' + params.data.orderNumber.value } });

						N_edit.addEventListener('click', () => {
							C_C_Order.open(params.data.orderID.value);
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit);

						return N_div;
					}
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		});

		const N_export = this.element.querySelector('#export') as HTMLButtonElement;
		const N_exportByProduct = this.element.querySelector('#exportByProduct') as HTMLButtonElement;

		N_export.addEventListener('click', () => {
			const data = this.getDataExportProductList();

			const title = `Export-Materiel-${moment().format('DD-MM-YYYY')}`;

			this.exportDataList(data, title);
		});

		N_exportByProduct.addEventListener('click', () => {
			const data = this.getDataExportByProduct(this.currentProductRow.id.value);
			const title = `Export-Materiel-Produit-${this.currentProductRow.reference.formattedValue}-${moment().format('DD-MM-YYYY')}`;

			this.exportData(data, title);
		});
	}

	private initEvents() {
		const N_select = this.element.querySelector('#stateBill') as HTMLSelectElement;

		N_select.addEventListener('change', () => {
			this.getData();
		});
	}

	private getDataExportProductList() {
		const tmp: { [key: string]: any }[] = [];

		const orders: { [orderID: string]: string } = {};

		for (const id in this.dataByProduct) {
			if (Array.isArray(this.dataByProduct[id])) {
				for (const order of this.dataByProduct[id]) {
					if (!orders[order.orderID.value]) {
						orders[order.orderID.value] = '';
					}
				}
			}
		}

		for (const id in this.dataByProduct) {
			const dataProduct = this.dataProducts.find(current => current.id.value === id) || {};

			let res: any = {
				reference: dataProduct.reference.export,
				brand: dataProduct.brand.export,
				globalQuantity: dataProduct.globalQuantity.export
			};

			res = { ...res, ...orders };

			if (this.dataByProduct[id]) {
				for (const order of this.dataByProduct[id]) {
					res[order.orderID.value] = order.quantity.export;
				}
			}

			tmp.push(res);
		}

		return tmp;
	}

	private getDataExportByProduct(id: string) {
		const tmp: { [key: string]: any }[] = [];

		const dataProduct = this.dataProducts.find(current => current.id.value === id) || {};

		if (this.dataByProduct[id]) {
			for (const order of this.dataByProduct[id]) {
				tmp.push({
					reference: dataProduct.reference.export,
					brand: dataProduct.brand.export,
					globalQuantity: dataProduct.globalQuantity.export,
					orderNumber: order.orderNumber.export,
					customer: order.customer.export,
					sites: order.sites.export,
					label: order.label.export,
					price: order.price.export,
					quantity: order.quantity.export
				});
			}
		}

		return tmp;
	}

	private exportDataList(data: { [key: string]: any }[] = [], title: string) {
		const orderHeader: { [key: string]: string } = {};

		for (const id in this.dataByProduct) {
			if (Array.isArray(this.dataByProduct[id])) {
				for (const order of this.dataByProduct[id]) {
					if (!orderHeader[order.orderID.value]) {
						orderHeader[order.orderID.value] = order.orderNumber.export;
					}
				}
			}
		}

		const header: any = {
			reference: 'Référence',
			brand: 'Marque',
			globalQuantity: 'Quantité globale',
			...orderHeader
		};

		const csv = CSV.stringify(data, header, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private exportData(data: { [key: string]: any }[] = [], title: string) {
		const csv = CSV.stringify(data, {
			reference: 'Référence',
			brand: 'Marque',
			globalQuantity: 'Quantité globale',
			orderNumber: 'N° Commande',
			customer: 'Client',
			sites: 'Site(s)',
			label: 'Objet de la commande',
			price: 'Facture',
			quantity: 'Quantité'
		}, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private async getData() {
		const N_select = this.element.querySelector('#stateBill') as HTMLSelectElement;
		const stateBill = N_select.options[N_select.selectedIndex].value;

		const { data, products } = await S_C_Order.getInstance().getDataToMaterial(stateBill);

		this.dataByProduct = data;
		this.dataProducts = products;

		this.N_Products.value = this.dataProducts;

		if (this.currentProductRow?.id) {
			this.N_Orders.value = this.dataByProduct[this.currentProductRow.id.value];
		} else {
			this.N_Orders.value = [];
		}
	}

	public destructor() {
	}
}

export default MaterialCustomerController;
