import { unit } from '../types/unit';

import BooleanModel from '@js/libs/model/_app/Boolean';
import PrimaryKeyModel from '@libs/model/_app/PrimaryKey';
import StringModel from '@libs/model/_app/String';

class UnitModel {
	private data: unit;

	private static cache: { [key: string]: any } = {};

	constructor(data: unit) {
		this.data = data;
	}

	public static setCache(table: string, values: { [key: string]: any }) {
		UnitModel.cache[table] = values;
	}

	private convertRowData() {
		return {
			_id: new PrimaryKeyModel(this.data._id),
			unit: new StringModel(this.data.unit),
			label: new StringModel(this.data.label),
			forLength: new BooleanModel(this.data.forLength),
			forWeight: new BooleanModel(this.data.forWeight),
			_rowData_: this.data
		};
	}

	public toDashboard() {
		const data = this.convertRowData();

		return {
			_id: data._id.toDashboard(),
			unit: data.unit.toDashboard(),
			label: data.label.toDashboard(),
			forLength: data.forLength.toDashboard(),
			forWeight: data.forWeight.toDashboard(),
			_rowData_: this.data
		};
	}

	public toDocument() {
		const data = this.convertRowData();

		return {
			_id: data._id.toDocument(),
			unit: data.unit.toDocument(),
			label: data.label.toDocument(),
			forLength: data.forLength.toDocument(),
			forWeight: data.forWeight.toDocument()
		};
	}

	public toSave(): unit {
		const data = this.convertRowData();

		return {
			_id: data._id.toSave(),
			unit: data.unit.toSave(),
			label: data.label.toSave(),
			forLength: data.forLength.toSave(),
			forWeight: data.forWeight.toSave()
		};
	}

	public toSelect() {
		const data = this.convertRowData();

		return {
			id: data._id.getValue(),
			text: this.displayRef()
		};
	}

	public displayRef() {
		const data = this.convertRowData();
		return `${data.unit.toDocument()}•${data.label.toDocument()}`;
	}

	public displayAggrid() {
		const data = this.convertRowData();
		return data.unit.toDocument();
	}
}

export default UnitModel;
