import CE_Select from '@libs/customElement/Select';

class Select2Editor {
	private value: string = '';
	private params: any;
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
	}

	public getGui() {
		return this.eGui;
	}

	public getValue() {
		return this.value;
	}

	public afterGuiAttached() {
		const N_select = this.eGui.querySelector<CE_Select>('ap-select')!;

		N_select.open();
	}

	public isPopup() {
		return false;
	}

	public init(params: any) {
		this.params = params;
		this.value = params.value;

		this.eGui.innerHTML = '<ap-select></ap-select>';

		const N_select = this.eGui.querySelector<CE_Select>('ap-select')!;

		if (this.params.options.table) {
			N_select.options = {
				ajax: {
					url: `/api/${this.params.options.table}/by-pattern`,
					getParams: (search: string) => {
						if (this.params.options.refDataFunction) {
							this.params.options.refData = this.params.options.refDataFunction(search);
						}

						return {
							search,
							refData: JSON.stringify(this.params.options.refData || {})
						};
					}
				}
			};
		}

		if (this.params.options.data) {
			N_select.options = {
				data: this.params.options.data
			};
		}

		if (this.params.options.dataFunction) {
			N_select.options = {
				data: this.params.options.dataFunction()
			};
		}

		N_select.addEventListener('change', () => {
			this.value = N_select.value as string;
			this.params.stopEditing();
		});

		N_select.addEventListener('close', () => {
			this.value = params.value;
			this.params.stopEditing();
		});
	}

	public destroy() {

	}
}

export default Select2Editor;
