import { Module, Modules } from '@autoprog/core-client';

import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

import C_SettingsAccounting from './js/controllers/Settings.Accounting';
import T_SettingsAccounting from './tpl/Settings.Accounting.html';

import C_SettingsListAccount from './js/controllers/Settings.ListAccount';
import T_SettingsListAccount from './tpl/Settings.ListAccount.html';

import C_SettingsListTaxe from './js/controllers/Settings.ListTaxe';
import T_SettingsListTaxe from './tpl/Settings.ListTaxe.html';

import CE_ComptaTab from './js/customElements/ComptaTab';

import './css/compta.scss';

class AccountingModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_ComptaTab.register();
	}

	public async preInit() {
		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addLink({
			id: 'company-accounting',
			Controller: C_SettingsAccounting,
			tpl: T_SettingsAccounting,
			order: 100,
			category: 'company',
			name: 'Comptabilité'
		});

		settingsRegister.addLink({
			Controller: C_SettingsListAccount,
			tpl: T_SettingsListAccount,
			order: 101,
			category: 'general',
			icon: 'bill/line',
			name: 'Compte comptables'
		});

		settingsRegister.addLink({
			Controller: C_SettingsListTaxe,
			tpl: T_SettingsListTaxe,
			order: 102,
			category: 'general',
			icon: 'bank/line',
			name: 'TVA'
		});
	}

	public async init() {

	}

	public async postInit() {
	}
}

Modules.registerModule('Comptabilité', AccountingModule);
