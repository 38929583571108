import DecimalApps from '@js/libs/utils/Decimal';

import CalculV1 from './CalculV1';
import CalculV2 from './CalculV2';

import CalculTaxe from '@modules/Comptabilité/js/libs/CalculTaxe';

import { billCustomerFormCalcul, contentLine } from '../../types/billCustomer';

export type TVARecap = Map<string, { priceHT: DecimalApps, tva: CalculTaxe }>;

export type TotalBillResult = {
	totalTTC: DecimalApps,
	totalHT: DecimalApps,
	totalTVA: DecimalApps,
	advanceHT: DecimalApps
	tvaRecap: TVARecap
};

export type ContentLineBillPrice = {
	unitPrice: DecimalApps,
	priceHT: DecimalApps,
	tva: CalculTaxe
};

export type ICalcul = {
	calculatePrice(): Promise<TotalBillResult>
	calculatePriceContentLine(line: contentLine): Promise<ContentLineBillPrice>
};

class CalculBill {
	private calcul: ICalcul;

	public constructor(bill: billCustomerFormCalcul) {
		switch (bill.version) {
			case '1':
				this.calcul = new CalculV1(bill);
				break;
			default:
				this.calcul = new CalculV2(bill);
		}
	}

	public calculatePrice() {
		return this.calcul.calculatePrice();
	}

	public calculatePriceContentLine(line: contentLine) {
		return this.calcul.calculatePriceContentLine(line);
	}
}

export default CalculBill;
