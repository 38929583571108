import axios from 'axios';

class ConfigService {
	private static instance: ConfigService | null = null;

	protected baseURI: string = '/api/config-manager';

	public static getInstance(): ConfigService {
		if (!ConfigService.instance) {
			ConfigService.instance = new ConfigService();
		}

		return ConfigService.instance;
	}

	public async getAll() {
		const result = await axios.get(this.baseURI);
		return result.data;
	}

	public async updateConfigFile(name: string, data: { [key: string]: any }) {
		const result = await axios.put(`${this.baseURI}/update-file`, { name, config: data });
		return result;
	}

	public async getEditableTable() {
		const result = await axios.get(`${this.baseURI}/editable-table`);
		return result.data.data;
	}
} export default ConfigService;
