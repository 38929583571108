import { SocketIO } from '@autoprog/core-client';

import Utils from '@libs/utils/Utils';

class OpenDocuments {
	private static instance: OpenDocuments;

	public static getInstance() {
		if (!OpenDocuments.instance) {
			OpenDocuments.instance = new OpenDocuments();
		}

		return OpenDocuments.instance;
	}

	private registerData: { [key: string]: any } = {};

	private socketIo: SocketIO;

	constructor() {
		this.socketIo = SocketIO.getInstance();

		this.init();
	}

	private init() {
		setInterval(() => {
			const user = Utils.userID;
			for (const item in this.registerData) {
				this.socketIo.emit('openDocument.update', { ...this.registerData[item], user });
			}
		}, 1000);
	}

	public register(id: string, table: string, isSave: boolean) {
		id = id || '';
		table = table || '';

		if (id.length && table.length) {
			this.registerData[`${id}_${table}`] = {
				id,
				table,
				date: Date.now(),
				isSave
			};
		}
	}

	public unregister(id: string, table: string) {
		delete this.registerData[`${id}_${table}`];
	}
}

export default OpenDocuments;
