// CORE
import { Form, global } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/deliveries/editPage/generalInformation.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';
import _ from 'lodash';

//SERVICE
import S_Delivery from '@services/DeliveryService';

type GeneralInformationData = {
	infos: {
		deliveryDate: Moment,
		businessName: string,
		number: string
	}
};

class GeneralInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	private number: string;

	constructor(data: GeneralInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;
		this.number = '';

		this.on('opened', async () => {
			this.init();
			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			this.number = data.infos.number;
			await this.setData(data);
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const formData = this.form?.getData() as { [key: string]: any };

					this.number = S_Delivery.getInstance().updateNumberByDate(this.number || '', formData.infos.deliveryDate);

					const data = _.merge(formData, { infos: { number: this.number } });

					this.resolve(data);
				}));
			});
		});
	}

	private init() {
		if (global.IS_MOBILE) {
			this.initMobileView();
		}
	}

	private initMobileView() {
		const N_modal_document = this.element.querySelector('[role="document"]');
		const N_modal_content = this.element.querySelector('.modal-content');
		N_modal_document?.classList.add('modal-dialog-scrollable');
		N_modal_document?.classList.remove('modal-40');
		N_modal_content?.classList.add('h-100');
	}

	private async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				deliveryDate: data.infos.deliveryDate,
				businessName: data.infos.businessName
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default GeneralInformation;
