// CORE
import { Form } from '@autoprog/core-client';

class SettingsBillTab extends HTMLElement {
	public static readonly tagName: string = 'ap-bill-customer-settings-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private form: Form | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="settings-3/line" icon-active="settings-3/fill" text="Paramètres"></ap-page-tabs-menu-item>`;

		this.id = '';
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('page-tabs-container', 'tab-pane');
		N_div.id = this.idTab;

		N_div.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Paramètres 
			</div>
			<div class="page-tabs-content" id="form">
				<div class="card shadow-none p-3">
					<div class="font-weight-bold mt-2">
						Facture d'acompte / avancement
					</div>
			
					<div class="ml-3">
			
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" id="displayRow_notbill" name="displayRow.notBill">
							<label class="custom-control-label" for="displayRow_notbill">
								Afficher la ligne "Montant restant à facturer" en fin de devis.
							</label>
						</div>
			
					</div>

					<div class="font-weight-bold mt-2">
						Facture définitive
					</div>
			
					<div class="ml-3">
			
						<div class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" id="display_modal_quote" name="display.modalQuotes">
							<label class="custom-control-label" for="display_modal_quote">
								Afficher la fenêtre modal de sélection des devis à la création de la facture.
							</label>
						</div>
			
					</div>
				</div>
            </div>
		`;

		const N_form = N_div.querySelector('#form') as HTMLFormElement;
		this.form = new Form(N_form);

		N_container.append(N_div);
	}

	public set data(data: any) {
		this.form?.setData(data);
	}

	public get data(): any {
		return this.form?.getData();
	}

	public static register() {
		customElements.define(SettingsBillTab.tagName, SettingsBillTab);
	}
}

export default SettingsBillTab;
