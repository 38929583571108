import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_modal from '../../../tpl/modals/deliveries/ContentEdit.html';

import S_Product from '@services/Product/ProductService';

import CE_Select2 from '@libs/customElement/Select2';

class ContentEdit extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null = null;

	constructor(item: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			this.form.setData({
				...item,
				reference: await S_Product.getInstance().getDataToSelect2ByID(item.reference)
			});

			this.postInit();

			const N_save = this.element.querySelector('#save');

			N_save?.addEventListener('click', () => {
				this.resolve(this.form!.getData());
			});
		});
	}

	private init() {
		const N_product = this.element.querySelector('[name="reference"]') as CE_Select2;

		N_product.create(this.element, { disabled: true });

		this.selectPostinit.product = N_product;
	}

	private postInit() {
		this.selectPostinit.product.postInit();

		this.selectPostinit.product.on('change', async (value) => {
			const data = await S_Product.getInstance().getById(value as string);

			this.form?.setDataByName('label', data.name);
		});

		const N_quantity = this.element.querySelector('[name="quantity"]') as HTMLInputElement;
		const N_deliveredQuantity = this.element.querySelector('[name="deliveredQuantity"]') as HTMLInputElement;

		N_quantity.addEventListener('input', () => {
			const data = this.form!.getData() as { [key: string]: number };

			let deliveredQuantity = data.deliveredQuantity;

			if (deliveredQuantity > data.quantity) {
				deliveredQuantity = data.quantity;

				this.form!.setDataByName('deliveredQuantity', deliveredQuantity);
			}

			this.form!.setDataByName('remainingQuantity', data.quantity - deliveredQuantity);
		});

		N_deliveredQuantity.addEventListener('input', () => {
			const data = this.form!.getData() as { [key: string]: number };

			let deliveredQuantity = data.deliveredQuantity;

			if (deliveredQuantity > data.quantity) {
				deliveredQuantity = data.quantity;

				this.form!.setDataByName('deliveredQuantity', deliveredQuantity);
			}

			this.form!.setDataByName('remainingQuantity', data.quantity - deliveredQuantity);
		});
	}
}

export default ContentEdit;
