import axios from 'axios';

class Select2Mobile extends HTMLElement {
	public static readonly tagName: string = 'ap-select2-mobile';

	private options: any = {};

	private value = '';

	private N_select: JQuery<HTMLElement> | null = null;

	public connectedCallback() {
		this.classList.add('w-100');

		this.innerHTML = `
            <div class="d-none" id="container-list">
				<input class="form-control"/>
				<div id="list">
				</div>
            </div>
        `;

		const N_input = this.querySelector('input') as HTMLInputElement;
		const N_list = this.querySelector('#list') as HTMLElement;
		const N_containerList = this.querySelector('#container-list') as HTMLElement;

		N_input.addEventListener('input', () => {
			axios({
				...this.options.ajax,
				params: this.options.ajax.data({ term: N_input.value })
			}).then(({ data }) => {
				N_list.innerHTML = '';

				for (const item of data.data) {
					const N_div = document.createElement('div');

					N_div.classList.add('item', item.id === this.value ? 'active' : 'not-active');

					N_div.innerHTML = item.text;

					N_div.addEventListener('click', () => {
						N_containerList?.classList.toggle('d-none');

						// create the option and append to Select2
						const option = new Option(item.text, item.id, true, true);
						this.N_select!.append(option).trigger('change');
						///@ts-ignore
						this.N_select!.trigger({
							type: 'select2:select',
							params: {
								data: item
							}
						});
					});

					N_list.appendChild(N_div);
				}

				if (!data.data.length) {
					N_list.innerHTML = 'Aucune données';
				}
			});
		});
	}

	public setOption(options: any) {
		this.options = options;
	}

	public setSelect(N_select: JQuery<HTMLElement>) {
		const N_input = this.querySelector('input') as HTMLInputElement;

		this.N_select = N_select;

		this.N_select.on('select2:select', (args) => {
			this.value = args.params.data?.id || '';
		});

		this.N_select.on('select2:unselect', () => {
			this.value = '';
		});

		const N_select2 = this.parentNode?.querySelector('.select2.select2-container');

		const N_containerList = this.querySelector('#container-list');

		N_select2?.addEventListener('click', () => {
			N_containerList?.classList.toggle('d-none');
			N_input.value = '';

			N_input.dispatchEvent(new Event('input', { bubbles: true }));

			N_input.focus();
		});
	}

	public static register() {
		customElements.define(Select2Mobile.tagName, Select2Mobile);
	}
}

export default Select2Mobile;
