import { SettingQuoteOption } from './GridOptions';
import objectToArray from './utils/objectToArray';

class QuoteOption {
	private static instance: QuoteOption;

	private _pref: { [key: string]: any };
	private _infos: { [key: string]: any };
	private _quoteOptions: SettingQuoteOption;
	private _version: string;

	private constructor() {
		this._pref = {};
		this._infos = {};
		this._quoteOptions = { hourlyPriceType: {}, marginType: {} };
		this._version = '';
	}

	public static newInstance() {
		QuoteOption.instance = new QuoteOption();
		return QuoteOption.instance;
	}

	public static getInstance() {
		if (!QuoteOption.instance) {
			QuoteOption.instance = new QuoteOption();
		}

		return QuoteOption.instance;
	}

	public set pref(value: { [key: string]: any }) {
		value.decimalNumber = Number(value.decimalNumber);
		this._pref = value;
	}

	public set infos(value: { [key: string]: any }) {
		value.state = value.state.toString();
		this._infos = value;
	}

	public set quoteOptions(value: SettingQuoteOption) {
		this._quoteOptions = value;
	}

	public set version(value: string) {
		this._version = value;
	}

	public get pref() {
		return this._pref;
	}

	public get infos() {
		return this._infos;
	}

	public get quoteOptions(): SettingQuoteOption {
		return this._quoteOptions;
	}

	public get version() {
		return this._version;
	}

	public getMarginType() {
		return objectToArray(this._quoteOptions.marginType || {});
	}

	public getHourlyPriceType() {
		return objectToArray(this._quoteOptions.hourlyPriceType || {});
	}
}

export default QuoteOption;
