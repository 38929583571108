import { utils } from '@autoprog/core-client';

import h from 'hyperscript';

import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';

import M_EditBillingRequest from '../modals/EditBillingRequest';

import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';

import S_C_BillingRequest from '@services/Customer/CustomerBillingRequestService';

import C_BillsCustomer from '@modules/BillsCustomer/js/controllers/Bills.Customer';
import DecimalApps from '@js/libs/utils/Decimal';

class BillingRequestTab extends HTMLElement {
	public static readonly tagName: string = 'ap-billing-request-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	private mode: string = '';

	private forceId: string | null = null;

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.idDatabase || BillingRequestTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="bill/line" icon-active="bill/fill" text="Demande de facturation" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid?.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Demande de facturation
				<div class="page-tabs-title-right">
					<ap-button class="d-none" type="add" id="create-bill" permission="BILLS._CUSTOMERS.ADD">Créer Facture</ap-button>
					<ap-button type="add" id="add" permission="ORDERS._CUSTOMERS._BILLING_REQUESTS.ADD" disabled></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButton();
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucune Demande' },
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			suppressContextMenu: true,
			columnDefs: [
				{
					headerName: '',
					field: '',
					pinned: 'left',
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						let disable = false;

						if (params.data.state.value === 'bill') {
							disable = true;
						}

						if (params.data.version.value === '1') {
							disable = true;
						}

						if (params.data.billingStartDate.value && params.data.billingStartDate.value > Date.now()) {
							disable = true;
						}

						if (disable) {
							const N_div = h<HTMLElement>('div.container-action-aggrid');
							const N_forbidden = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { icon: 'forbid-2/line' } });

							N_div.append(N_forbidden);

							return N_div;
						}

						const N_div = h<HTMLElement>('div.container-action-aggrid');
						const N_checkbox = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { icon: 'checkbox-blank/line' } });

						const updateSelected = () => {
							if (params.node.isSelected()) {
								N_checkbox.icon = 'checkbox/line';
							} else {
								N_checkbox.icon = 'checkbox-blank/line';
							}
						};

						N_div.addEventListener('click', () => {
							params.node.setSelected(!params.node.isSelected());
							updateSelected();
						});

						updateSelected();

						N_div.append(N_checkbox);

						return N_div;
					}
				},
				{
					headerName: 'Date création',
					field: 'date'
				}, {
					headerName: 'Personne',
					field: 'user'
				}, {
					headerName: 'Montant',
					field: 'price',
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						suffix: '€'
					}
				}, {
					headerName: 'Type',
					field: 'type'
				}, {
					headerName: 'A traiter à partir du',
					field: 'billingStartDate'
				}, {
					headerName: 'Commentaire',
					field: 'comment'
				}, {
					headerName: 'Objet',
					field: 'designation'
				}, {
					headerName: 'Etat',
					field: 'state'
				}, {
					headerName: '',
					width: 80,
					editable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					pinned: 'right',
					cellRenderer: (params) => {
						const N_edit = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { type: 'edit' } });

						N_edit.addEventListener('click', () => {
							new M_EditBillingRequest(this.idDatabase, params.data._id.value).open().then(() => {
								this.N_grid!.resetValue();
								this.initData();
							});
						});

						N_edit.disabled = this.mode === 'bill' || (params.data.version.value === '1' || params.data.state.value === 'bill');

						const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', tooltip: 'Supprimer', type: 'delete' } });

						N_delete.disabled = (this.mode === 'bill' || params.data.state.value === 'bill');

						N_delete.addEventListener('click', async () => {
							await S_C_BillingRequest.getInstance().delete(params.data._id.value);
							params.api.applyTransaction({
								remove: [params.data]
							});
							this.initData();
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit, N_delete);

						return N_div;
					}
				}
			],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			onSelectionChanged: () => {
				this.updateButtonCreateBill();
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});
	}

	private updateButtonCreateBill() {
		const hasSelectedRow = this.N_grid!.api?.getSelectedNodes().length !== 0;

		const N_createBillButton = this.N_el!.querySelector<HTMLButtonElement>('#create-bill');

		if (hasSelectedRow) {
			N_createBillButton?.classList.remove('d-none');
		} else {
			N_createBillButton?.classList.add('d-none');
		}
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector<HTMLElement>('#number')!;

		if (this.N_grid!.isLoad) {
			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		} else {
			N_number.classList.add('d-none');
		}
	}

	private initButton() {
		const N_btn = this.N_el!.querySelector<HTMLButtonElement>('#add')!;

		N_btn.addEventListener('click', async () => {
			new M_EditBillingRequest(this.idDatabase).open().then(() => {
				this.N_grid!.resetValue();
				this.initData();
			});
		});

		const N_createBillButton = this.N_el!.querySelector<HTMLButtonElement>('#create-bill');

		N_createBillButton?.addEventListener('click', async () => {
			this.dispatchEvent(new CustomEvent('close'));
			C_BillsCustomer.open(null, {
				idOrder: this.idDatabase,
				idsBillingRequest: this.N_grid!.api?.getSelectedNodes().map(node => node.data._id.value)
			});
		});
	}

	private get idDatabase() {
		return this.forceId || utils.getQuery().id;
	}

	public setForceID(value: string) {
		this.forceId = value;
		return this;
	}

	private dispatchLoad(data: any[]) {
		let res = new DecimalApps(0);

		for (const item of data) {
			if (item.state.value === 'waiting') {
				res = res.plus(item.price.value);
			}
		}

		res = res.toDecimalPlaces(2);

		this.dispatchEvent(new CustomEvent('load', { detail: { sum: res } }));
	}

	public setMode(mode: 'bill' | 'order' | 'order-only-create') {
		this.mode = mode;

		if (this.mode === 'bill') {
			const N_createBillButton = this.N_el!.querySelector<HTMLButtonElement>('#create-bill');
			const N_btn = this.N_el!.querySelector<HTMLButtonElement>('#add')!;

			N_createBillButton?.classList.add('d-none');
			N_btn?.classList.add('d-none');
		}

		if (this.mode === 'order-only-create') {
			const N_btn = this.N_el!.querySelector<HTMLButtonElement>('#add')!;
			N_btn?.classList.add('d-none');
		}

		return this;
	}

	public async initData() {
		if (this.idDatabase) {
			let data: any[] = [];

			if (this.mode === 'order' || this.mode === 'order-only-create') {
				data = await S_C_BillingRequest.getInstance().getByOrder(this.idDatabase);
			}

			if (this.mode === 'bill') {
				data = await S_C_BillingRequest.getInstance().getByBill(this.idDatabase);
			}

			this.N_grid!.value = data;
			this.dispatchLoad(data);
		} else {
			this.N_grid!.value = [];
		}
	}

	public disabledCreateButton(value: boolean) {
		const N_create = this.N_el!.querySelector('#add') as HTMLButtonElement;
		N_create.disabled = value;
	}

	public get data() {
		return this.N_grid!.value;
	}

	public static register() {
		customElements.define(BillingRequestTab.tagName, BillingRequestTab);
	}
}

export default BillingRequestTab;
