// TEMPLATE
import T_modal from '../../tpl/modals/selectQuote.html';

// LIBS
import Modal from '@libs/Modal';

//SERVICE
import S_Quote from '@services/QuoteService';

type SelectQuoteData = {
	quotes: any[]
};

class SelectQuote extends Modal {
	private checkboxes: HTMLInputElement[] = [];

	constructor(data: SelectQuoteData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.checkboxes = [];

			await this.initList(data);
			this.initValidator();

			const N_print = this.element.querySelector('#print') as HTMLButtonElement;

			N_print.addEventListener('click', async () => {
				N_print.loading(new Promise(async () => {
					const quotes: any[] = [];

					for (const checkbox of this.checkboxes) {
						if (checkbox.checked) {
							quotes.push(checkbox.value);
						}
					}

					this.resolve(quotes);
				}));
			});
		});
	}

	private async initList(data: SelectQuoteData) {
		const N_quotesList = this.element.querySelector('#quotesList');

		for (const item of data.quotes) {
			const N_li = document.createElement('li') as HTMLLIElement;
			N_li.classList.add('list-group-item');

			const N_div = document.createElement('div') as HTMLDivElement;
			N_div.classList.add('custom-control', 'custom-checkbox');

			const N_input = document.createElement('input') as HTMLInputElement;
			N_input.classList.add('custom-control-input');
			N_input.setAttribute('value', item.quoteID);
			N_input.setAttribute('type', 'checkbox');
			N_input.setAttribute('id', 'check_' + item.quoteID);

			if (item === '0') {
				N_input.setAttribute('checked', 'checked');
			}

			this.checkboxes.push(N_input);

			const N_label = document.createElement('label') as HTMLLabelElement;
			N_label.classList.add('custom-control-label');
			N_label.setAttribute('for', 'check_' + item.quoteID);

			const quote = await S_Quote.getInstance().getById(item.quoteID);
			N_label.innerHTML = quote.infos.number;

			N_li.appendChild(N_div);
			N_div.appendChild(N_input);
			N_div.appendChild(N_label);

			N_quotesList?.appendChild(N_li);
		}
	}

	private initValidator() {
		for (const checkbox of this.checkboxes) {
			checkbox.addEventListener('change', () => {
				this.validate();
			});
		}
	}

	private validate() {
		let atLeastOneIsChecked = false;
		for (const checkbox of this.checkboxes) {
			atLeastOneIsChecked = atLeastOneIsChecked || checkbox.checked;
		}

		const N_print = this.element.querySelector('#print') as HTMLButtonElement;
		const N_invalidText = this.element.querySelector('#invalidText') as HTMLSpanElement;

		if (atLeastOneIsChecked) {
			N_print?.removeAttribute('disabled');
			N_invalidText.classList.add('d-none');
		} else {
			N_print?.setAttribute('disabled', 'disabled');
			N_invalidText.classList.remove('d-none');
		}
	}
}

export default SelectQuote;
