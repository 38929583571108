import StepContent from '@libs/StepModal/StepContent';

import T_Step from '../../../../tpl/modals/addEditProduct/steps/stock.html';

import '../../../../css/modals/steps/stock.scss';

import CE_Select from '@libs/customElement/Select';
import CE_Switch from '@libs/customElement/Switch';

import S_Pdt_Stock from '@services/Product/ProductStockService';
import S_Stock from '@services/StockService';

import Utils from '@js/libs/utils/Utils';

class StockStep extends StepContent {
	private data: { [id: string]: any } = {};

	private selectedStockId: string = '';

	private initialStocksIds: string[] = [];

	/**
	 * Fonction d'initialisation principale
	 */
	public async init() {
		const N_stockID = this.el.querySelector<CE_Select>('[name="stockID"]')!;
		const N_stockLocation = this.el.querySelector<HTMLInputElement>('[name="stockLocation"]')!;
		const N_alarmStock = this.el.querySelector<HTMLInputElement>('[name="alarmStock"]')!;
		const N_enableCustomAlarm = this.el.querySelector<CE_Switch>('[name="enableCustomAlarm"]')!;

		const N_stockEnableAlarm = this.el.querySelector<CE_Switch>('[name="stock.enableAlarm"]')!;
		const N_addStock = this.el.querySelector<HTMLButtonElement>('#add-stock')!;
		const N_deleteStock = this.el.querySelector<HTMLButtonElement>('#delete-stock')!;

		N_stockID.options = {
			ajax: {
				url: S_Stock.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ realStock: true })
					};
				}
			}
		};

		N_stockEnableAlarm.addEventListener('change', () => {
			this.updateFormDisabling();
		});

		/**
		 * Évènements sur les champs du formulaire du stock sélectionné
		 */

		N_stockID.addEventListener('change', async () => {
			this.updateFormDisabling();
			this.saveSelectedStock();
			this.updateStocksMenu(N_stockID);
			this.updateAddButtonDisabling();
			this.refreshStockIdResult();
		});

		N_stockLocation.addEventListener('input', () => {
			this.saveSelectedStock();
		});

		N_alarmStock.addEventListener('input', () => {
			this.saveSelectedStock();
		});

		N_enableCustomAlarm.addEventListener('change', () => {
			this.saveSelectedStock();
			this.updateFormDisabling();
		});

		/**
		 * Boutons d'ajout et de suppression de stock
		 */

		N_addStock.addEventListener('click', () => {
			const N_item = this.renderStocksMenu({});
			this.activeStock(N_item.dataset.id!);
			this.updateAddButtonDisabling();
		});

		N_deleteStock.addEventListener('click', () => {
			if (this.initialStocksIds.includes(this.selectedStockId)) {
				this.data[this.selectedStockId].deleted = true;
			} else {
				delete this.data[this.selectedStockId];
			}

			const N_menu = this.el.querySelector<HTMLElement>(`[data-id="${this.selectedStockId}"]`);
			N_menu?.remove();

			const ids: string[] = [];
			for (const id in this.data) {
				if (!this.data[id].deleted) {
					ids.push(id);
				}
			}

			this.selectedStockId = '';

			if (ids.length) {
				this.activeStock(ids[0]);
			} else {
				const N_item = this.renderStocksMenu({});
				this.activeStock(N_item.dataset.id!);
			}

			this.updateAddButtonDisabling();
			this.refreshStockIdResult();
		});
	}

	/**
	 * Rafraichit le résultat du select de stockID pour exclure les emplacements de stock déjà sélectionnés
	 */
	private refreshStockIdResult() {
		const currentSelectedStockIds = Object.entries(this.data)
			.filter(([, value]) => (!value.deleted && value.stockID)) // On ne garde que les données qui ne sont pas "deleted"
			.map(([, value]) => value.stockID); // On transforme en tableau de stockIDs

		const N_stockID = this.el.querySelector<CE_Select>('[name="stockID"]')!;
		N_stockID.excludeList = currentSelectedStockIds;
	}

	/**
	 * Renvoie le titre de l'étape
	 */
	public getTitle() {
		return 'Stocks';
	}

	/**
	 * Renvoie le contenu (template) de l'étape
	 */
	public getContent() {
		return T_Step;
	}

	/**
	 * Renvoie les données de l'étape
	 */
	public getData() {
		const N_defaultAlarm = this.el.querySelector<HTMLInputElement>('[name="stock.defaultAlarm"]')!;
		const N_enableAlarm = this.el.querySelector<HTMLInputElement>('[name="stock.enableAlarm"]')!;
		const N_hidden = this.el.querySelector<HTMLInputElement>('[name="stock.hidden"]')!;
		const N_calculatePrice = this.el.querySelector<HTMLInputElement>('[name="stock.calculatePrice"]')!;

		const stocks: { [key: string]: any }[] = [];

		for (const id in this.data) {
			if (this.data[id].stockID) {
				stocks.push(this.data[id]);
			}
		}

		return {
			stocks,
			stock: {
				defaultAlarm: isNaN(N_defaultAlarm.valueAsNumber) ? null : N_defaultAlarm.valueAsNumber,
				hidden: N_hidden.checked,
				calculatePrice: N_calculatePrice.checked,
				enableAlarm: N_enableAlarm.checked
			}
		};
	}

	/**
	 * Définit les données pour l'étape
	 * @param data les données de l'étape
	 */
	public async setData(data: { [key: string]: any }) {
		if (data.stocks.length) {
			for (const id of data.stocks) {
				this.initialStocksIds.push(id);

				const stock = await S_Pdt_Stock.getInstance().getDataToModal(id);

				let stockText = '';

				if (stock.data.data.stockID?.id) {
					stockText = stock.data.data.stockID?.text;
					stock.data.data.stockID = stock.data.data.stockID.id;
				}

				this.renderStocksMenu(stock.data.data, stockText);
			}
			this.activeStock(data.stocks[0]);
		} else {
			const N_item = this.renderStocksMenu({});
			this.activeStock(N_item.dataset.id!);
		}

		const N_defaultAlarm = this.el.querySelector<HTMLInputElement>('[name="stock.defaultAlarm"]')!;
		const N_hidden = this.el.querySelector<HTMLInputElement>('[name="stock.hidden"]')!;
		const N_calculatePrice = this.el.querySelector<HTMLInputElement>('[name="stock.calculatePrice"]')!;
		const N_stockEnableAlarm = this.el.querySelector<HTMLInputElement>('[name="stock.enableAlarm"]')!;

		N_defaultAlarm.value = data.stock?.defaultAlarm;
		N_hidden.checked = data.stock?.hidden || false;
		N_calculatePrice.checked = data.stock?.calculatePrice || false;
		N_stockEnableAlarm.checked = data.stock?.enableAlarm || false;

		this.updateFormDisabling();
		this.updateAddButtonDisabling();
		this.refreshStockIdResult();
	}

	/**
	 * Met à jour la désactivation du bouton d'ajout de stock
	 */
	private updateAddButtonDisabling() {
		let hasAnyInvalidStock = false;

		for (const stockId in this.data) {
			if (!this.data[stockId].stockID) {
				hasAnyInvalidStock = true;
			}
		}

		const N_addStock = this.el.querySelector<HTMLButtonElement>('#add-stock')!;
		N_addStock.disabled = hasAnyInvalidStock;
	}

	/**
	 * Sauvegarde les données du formulaire dans le stock sélectionné
	 */
	private saveSelectedStock() {
		if (this.selectedStockId) {
			this.data[this.selectedStockId] = { ...this.getDataForm(), _id: this.data[this.selectedStockId]?._id };
		}
	}

	/**
	 * Définit le stock sélectionné dans le menu
	 * @param stockId l'id du stock
	 */
	private async activeStock(stockId: string) {
		this.saveSelectedStock();

		const N_oldActiveStock = this.el.querySelector(`.list [data-id="${this.selectedStockId}"]`)!;
		N_oldActiveStock?.classList.remove('active');

		this.selectedStockId = stockId;

		const N_item = this.el.querySelector(`.list [data-id="${this.selectedStockId}"]`)!;
		N_item.classList.add('active');

		await this.setDataForm(this.data[this.selectedStockId]);

		this.updateFormDisabling();
	}

	/**
	 * Construit le menu des stocks
	 * @param data 
	 * @param stockText 
	 */
	private renderStocksMenu(data: { [key: string]: any }, stockText: string = '') {
		data._id = data._id || Utils.generateId();

		this.data[data._id] = data;

		const N_list = this.el.querySelector('.list-content');

		const N_item = document.createElement('div');
		N_item.classList.add('list-item');
		N_item.dataset.id = data._id;

		N_item.innerHTML = stockText || 'Nouveau Stock';

		N_item?.addEventListener('click', () => {
			this.activeStock(data._id);
		});

		N_list?.append(N_item);

		return N_item;
	}

	/**
	 * Remplit le formulaire avec les données données
	 * @param data les données à remplir dans le formulaire
	 */
	private async setDataForm(data: { [key: string]: any }) {
		const N_stockID = this.el.querySelector<CE_Select>('[name="stockID"]')!;
		const N_stockLocation = this.el.querySelector<HTMLInputElement>('[name="stockLocation"]')!;
		const N_alarmStock = this.el.querySelector<HTMLInputElement>('[name="alarmStock"]')!;
		const N_enableCustomAlarm = this.el.querySelector<CE_Switch>('[name="enableCustomAlarm"]')!;

		N_stockID.value = await S_Stock.getInstance().getDataToSelect2ByID(data.stockID as string);
		N_stockLocation.value = data.stockLocation || '';
		N_alarmStock.value = data.alarmStock;
		N_enableCustomAlarm.checked = data.enableCustomAlarm || '';
	}

	/**
	 * Renvoie les données du formulaire
	 */
	private getDataForm() {
		const N_stockID = this.el.querySelector<CE_Select>('[name="stockID"]')!;
		const N_stockLocation = this.el.querySelector<HTMLInputElement>('[name="stockLocation"]')!;
		const N_alarmStock = this.el.querySelector<HTMLInputElement>('[name="alarmStock"]')!;
		const N_enableCustomAlarm = this.el.querySelector<CE_Switch>('[name="enableCustomAlarm"]')!;

		return {
			stockID: N_stockID.value,
			stockLocation: N_stockLocation.value,
			alarmStock: isNaN(N_alarmStock.valueAsNumber) ? null : N_alarmStock.valueAsNumber,
			enableCustomAlarm: N_enableCustomAlarm.checked
		};
	}

	/**
	 * Met à jour le menu des stocks selon le stock sélectionné
	 * @param N_stockID le select d'ID de stock
	 */
	private async updateStocksMenu(N_stockID: CE_Select) {
		const stock = await S_Stock.getInstance().getDisplayRefByID(N_stockID.value as string);

		const N_list = this.el.querySelector(`.list [data-id="${this.selectedStockId}"]`)!;
		N_list.innerHTML = stock;
	}

	/**
	 * Met à jour la désactivation de certains champs du formulaire
	 */
	private updateFormDisabling() {
		const N_stockEnableAlarm = this.el.querySelector<CE_Switch>('[name="stock.enableAlarm"]')!;
		const N_defaultAlarmStock = this.el.querySelector<HTMLInputElement>('[name="stock.defaultAlarm"]')!;
		const N_stockID = this.el.querySelector<CE_Select>('[name="stockID"]')!;
		const N_alarmStock = this.el.querySelector<HTMLInputElement>('[name="alarmStock"]')!;
		const N_enableCustomAlarm = this.el.querySelector<HTMLInputElement>('[name="enableCustomAlarm"]')!;

		N_defaultAlarmStock.disabled = !N_stockEnableAlarm.checked;
		N_alarmStock.disabled = !N_stockID.value || !N_stockEnableAlarm.checked || !N_enableCustomAlarm.checked;
	}
}

export default StockStep;
