import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';

import '@css/cellRenderer/numberCellRenderer.scss';

export default function (params : ICellRendererParams) {
	if (typeof params.value === 'number') {
		return `<div class="numberCellRenderer">${params.value.toFixed(params.value % 1 === 0 ? 0 : 2)}</div>`;
	}
	return params.value;
}
