import StepContent from '@libs/StepModal/StepContent';

import T_Step from '../../../../tpl/modals/addEditProduct/steps/caract.html';

import '../../../../css/modals/steps/caract.scss';

import CE_FilesEdit from '@libs/customElement/FilesEdit';
import CE_MultiInput from '../../../libs/customElement/MultiInput';
import CE_Select from '@libs/customElement/Select';

import S_Units from '@modules/Units/js/services/UnitsService';

class CaractStep extends StepContent {
	public async init() {
		const N_unit = this.el.querySelector<CE_Select>('[name="unit"]')!;
		const N_unitLength = this.el.querySelector<CE_Select>('[name="unitLength"]')!;
		const N_unitWeight = this.el.querySelector<CE_Select>('[name="unitWeight"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;

		N_unit.options = {
			ajax: {
				url: new S_Units().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search
					};
				}
			}
		};

		N_unitLength.options = {
			ajax: {
				url: new S_Units().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ forLength: true })
					};
				}
			}
		};

		N_unitWeight.options = {
			ajax: {
				url: new S_Units().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ forWeight: true })
					};
				}
			}
		};

		N_unitWeight.disabled = true;

		await N_files.init();
	}

	public getTitle() {
		return 'Caractéristiques techniques';
	}

	public getContent() {
		return T_Step;
	}

	public getData() {
		const N_compatibility = this.el.querySelector<HTMLInputElement>('[name="compatibility"]')!;
		const N_model = this.el.querySelector<HTMLInputElement>('[name="model"]')!;
		const N_variation = this.el.querySelector<HTMLInputElement>('[name="variation"]')!;
		const N_subVariation = this.el.querySelector<HTMLInputElement>('[name="subVariation"]')!;
		const N_position = this.el.querySelector<HTMLInputElement>('[name="position"]')!;

		const N_unit = this.el.querySelector<CE_Select>('[name="unit"]')!;
		const N_unitLength = this.el.querySelector<CE_Select>('[name="unitLength"]')!;
		const N_weight = this.el.querySelector<HTMLInputElement>('[name="weight"]')!;
		const N_unitWeight = this.el.querySelector<CE_Select>('[name="unitWeight"]')!;
		const N_isDangerous = this.el.querySelector<HTMLInputElement>('[name="isDangerous"]')!;
		const N_comment = this.el.querySelector<HTMLInputElement>('[name="comment"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;
		const N_dimensions = this.el.querySelector<CE_MultiInput>(CE_MultiInput.tagName)!;

		return {
			compatibility: N_compatibility.value,
			model: N_model.value,
			variation: N_variation.value,
			subVariation: N_subVariation.value,
			position: N_position.value,
			unit: N_unit.value,
			unitLength: N_unitLength.value,
			weight: N_weight.value,
			unitWeight: N_unitWeight.value,
			isDangerous: N_isDangerous.checked,
			comment: N_comment.value,
			files: N_files.value,
			...N_dimensions.value
		};
	}

	public async setData(data: { [key: string]: any }) {
		const N_compatibility = this.el.querySelector<HTMLInputElement>('[name="compatibility"]')!;
		const N_model = this.el.querySelector<HTMLInputElement>('[name="model"]')!;
		const N_variation = this.el.querySelector<HTMLInputElement>('[name="variation"]')!;
		const N_subVariation = this.el.querySelector<HTMLInputElement>('[name="subVariation"]')!;
		const N_position = this.el.querySelector<HTMLInputElement>('[name="position"]')!;

		const N_unit = this.el.querySelector<CE_Select>('[name="unit"]')!;
		const N_unitLength = this.el.querySelector<CE_Select>('[name="unitLength"]')!;
		const N_weight = this.el.querySelector<HTMLInputElement>('[name="weight"]')!;
		const N_unitWeight = this.el.querySelector<CE_Select>('[name="unitWeight"]')!;
		const N_isDangerous = this.el.querySelector<HTMLInputElement>('[name="isDangerous"]')!;
		const N_comment = this.el.querySelector<HTMLInputElement>('[name="comment"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;
		const N_dimensions = this.el.querySelector<CE_MultiInput>(CE_MultiInput.tagName)!;

		N_files.value = data.files || [];

		N_compatibility.value = data.compatibility || '';
		N_model.value = data.model || '';
		N_variation.value = data.variation || '';
		N_subVariation.value = data.subVariation || '';
		N_position.value = data.position || '';
		N_unit.value = data.unit;
		N_unitLength.value = data.unitLength;
		N_weight.value = data.weight || 0;
		N_unitWeight.value = data.unitWeight;
		N_isDangerous.checked = data.isDangerous || false;
		N_comment.value = data.comment || '';

		N_dimensions.value = {
			length: data.length,
			width: data.width,
			height: data.height
		};
	}

	public checkValidity(): boolean {
		const N_unit = this.el.querySelector<CE_Select>('[name="unit"]')!;
		N_unit.classList.toggle('invalid', !N_unit.value);
		return !!N_unit.value;
	}
}

export default CaractStep;
