module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pagePrint" style="width:calc(100% - 1px)">\n\n    <table>\n\n        <tbody>\n\n            <tr>\n                <td colspan="4">\n\n                    <div class="w-100 d-flex">\n                        CC N° ' +
((__t = (internalNumber)) == null ? '' : __t) +
' / ' +
((__t = ( infosCustomer.customer.number)) == null ? '' : __t) +
' ' +
((__t = (infosCustomer.customer.name)) == null ? '' : __t) +
'\n                    </div>\n                </td>\n            </tr>\n\n            <tr>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">' +
((__t = (column.reference)) == null ? '' : __t) +
'</td>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">Libellé</td>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">Quantité</td>\n                <td class="bg-grey-500 border border-dark text-center" rowspan="2">Unité</td>\n            </tr>\n            <tr></tr>\n\n            ';
for(let item of data){;
__p += '\n\n                ';
if(item.type == 'brand'){;
__p += '\n                    <tr>\n                        <td class="font-weight-bold border border-dark" colspan="4">\n                            ' +
((__t = (item.label)) == null ? '' : __t) +
'\n                        </td>\n                    </tr>\n                ';
}else if(item.type == 'quote'){;
__p += '\n                    <tr>\n                        <td class="font-weight-bold border border-dark" colspan="4">\n                            ' +
((__t = (item.label)) == null ? '' : __t) +
'\n                        </td>\n                    </tr>\n                ';
}else{;
__p += '\n                    <tr>\n                        <td class="border border-dark text-center">' +
((__t = (item.product.reference)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark text-center">' +
((__t = (item.product.label)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark text-center">' +
((__t = (item.quantity)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark text-center">' +
((__t = (item.product.unit)) == null ? '' : __t) +
'</td>\n                    </tr>\n                ';
};
__p += '\n                \n            ';
};
__p += '\n\n        </tbody>\n\n    </table>\n\n</div>';

}
return __p
}