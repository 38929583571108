import GenericService from './GenericService';

class StockService extends GenericService {
	private static instance: StockService | null = null;

	constructor() {
		super('stock');
	}

	public static getInstance(): StockService {
		if (!StockService.instance) {
			StockService.instance = new StockService();
		}

		return StockService.instance;
	}

	public async getDefaultStock() {
		return this.get_function('getDefaultStock');
	}

	public async getStockOutput() {
		return this.get_function('getStockOutput');
	}

	public async getRealStock() {
		return this.get_function('getRealStock');
	}

	public async getStockToCommandCustomer() {
		return this.get_function('getStockToCommandCustomer');
	}

	public async getStockToReceipt() {
		return this.get_function('getStockToReceipt');
	}

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return 'name';
	}
}

export default StockService;
