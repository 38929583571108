import { Form, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import CGVTab, { CGV } from '@libs/customElement/CGVTab';
import CE_QuoteOption from '../libs/customElement/QuoteOption';
import CE_QuotePrintOption from '../libs/customElement/QuotePrintOption';

import SettingsController from '@modules/Settings/js/libs/SettingsController';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};

type OptionQuoteData = {
	CGV: CGV | null
	pref: {
		[key: string]: any
	} | null
	quoteOptions: {
		[key: string]: any
	} | null
	infos: {
		[key: string]: any
	} | null
};

class SettingsTabs extends SettingsController {
	private data: OptionQuoteData;

	private N_CGVTab: CGVTab | null;
	private N_QuotePrintOption: CE_QuotePrintOption | null;
	private N_QuoteOption: CE_QuoteOption | null;

	private formInfos: Form | null;

	private settings: SettingsApps;

	constructor(el: HTMLElement) {
		super(el);

		this.settings = SettingsApps.getInstance();
		this.data = this.getData();
		this.N_CGVTab = null;
		this.N_QuotePrintOption = null;
		this.N_QuoteOption = null;
		this.formInfos = null;
	}

	protected init() {
		super.init();

		// On récupère les données des options des factures
		this.initTab();

		const N_form = this.el.querySelector('#info') as HTMLFormElement;
		this.formInfos = new Form(N_form);

		this.setData();
		this.initSave();
	}

	private initSave() {
		const N_saveBtn = this.el.querySelector('#save') as HTMLButtonElement;
		N_saveBtn.addEventListener('click', () => {
			N_saveBtn.loading(
				new Promise(async (resolve, reject) => {
					try {
						this.save();
						resolve('');
					} catch (e) {
						reject(e);
					}
				}).then(() => {
					toaster.success('Sauvegarde terminée');
				}).catch((e) => {
					toaster.error('Erreur lors de la sauvegarde');
					console.error(e);
				})
			);
		});
	}

	private getSaveData() {
		const result = this.formInfos?.getData() as { [key: string]: any };

		result.CGV = this.N_CGVTab!.data;
		result.quoteOptions = this.N_QuoteOption!.quoteOptions;
		result.pref = {
			...this.N_QuotePrintOption!.data,
			...this.N_QuoteOption!.data
		};

		return result;
	}

	private save() {
		if (this.formInfos?.checkValidity()) {
			const newData = this.getSaveData();

			this.settings.setGeneral('QUOTE.CGV', newData.CGV);
			this.settings.setGeneral('QUOTE.PREF', newData.pref);
			this.settings.setGeneral('QUOTE.INFOS', newData.infos);

			this.settings.setGeneral('QUOTE.OPTIONS', newData.quoteOptions);

			return newData;
		} else {
			throw new Error('Formulaire invalide');
		}
	}

	/**
	 * Initialise les onglets
	 */
	private initTab() {
		// on initialise l'onglet des options de CGV
		this.N_CGVTab = this.el.querySelector(CGVTab.tagName) as CGVTab;
		this.N_CGVTab.setParentElement(this.el);

		// on initialise l'onglet des options de parametre
		this.N_QuoteOption = this.el.querySelector<CE_QuoteOption>(CE_QuoteOption.tagName)!;
		this.N_QuoteOption.setParentElement(this.el);

		// on initialise l'onglet des options d'impression
		this.N_QuotePrintOption = this.el.querySelector<CE_QuotePrintOption>(CE_QuotePrintOption.tagName)!;
		this.N_QuotePrintOption.setParentElement(this.el);
		this.N_QuotePrintOption.setID(() => {
			return 'fakeQuote';
		});
		this.N_QuotePrintOption.setGetData(() => {
			const data = this.getSaveData();
			return data;
		});
	}

	/**
	 * Initialisation de l'onglet CGV
	 */
	private setData() {
		if (this.data.quoteOptions) {
			this.N_QuoteOption!.quoteOptions = (this.data.quoteOptions as SettingQuoteOption);
			this.N_QuoteOption!.data = (this.data.pref as SettingQuoteOption);
		} else {
			console.error('no quoteOptions found');
		}

		if (this.data.pref) {
			this.N_QuotePrintOption!.data = this.data.pref;
		} else {
			console.error('no printOptions found');
		}

		if (this.data.CGV) {
			this.N_CGVTab!.data = this.data.CGV;
		} else {
			console.error('no CGV found');
		}

		this.formInfos!.setData(this.data as { [key: string]: any });
	}

	/**
	 * Récupère les données des options des devis
	 */
	private getData(): OptionQuoteData {
		const data = {
			CGV: this.settings.get('QUOTE.CGV') || [],
			pref: this.settings.get('QUOTE.PREF') || {},
			quoteOptions: this.settings.get('QUOTE.OPTIONS') || {},
			infos: this.settings.get('QUOTE.INFOS') || {}
		};
		return data;
	}

	public destructor() {

	}
}

export default SettingsTabs;
