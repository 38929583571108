import { global } from '@autoprog/core-client';

import Desktop from './Database.Desktop';
import Mobile from './Database.Mobile';

let instance: any = Desktop;

const userAgent = navigator.userAgent.toLowerCase();
global.IS_TABLET = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

if (global.IS_MOBILE && !global.IS_TABLET) {
	instance = Mobile;
}

export default instance;
