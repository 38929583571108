import DOMPurify from 'dompurify';
import { Modal } from '@autoprog/core-client';
import h from 'hyperscript';

import '@css/modals/confirm.scss';

export type ConfirmOptions = {
	yesText?: string,
	yesType?: string,
	yesIcon?: string,
	noText?: string,
	noType?: string,
	noIcon?: string,
};

class Confirm extends Modal {
	public constructor(title: string, text?: string, options: ConfirmOptions = {}) {
		super({
			tpl: Confirm.getConfirmEl(options, title, text),
			keyboard: false,
			backdrop: 'static'
		});
	}

	private static getConfirmEl(options: ConfirmOptions, title?: string, text?: string) {
		const modalBody = h('div');
		modalBody.innerHTML = DOMPurify.sanitize(text || '');

		return h('div.modal.modal-confirm',
			h('div.modal-dialog.modal-dialog-centered',
				h('div.modal-content',
					h('div.modal-header'),
					h('div.modal-body',
						modalBody
					),
					h('div.modal-footer',
						h('ap-button', { attrs: { type: options.noType || 'close', 'data-reject': '', icon: options.noIcon || '' } }, options.noText || 'Annuler'),
						h('ap-button', { attrs: { type: options.yesType || 'default', 'data-resolve': '', icon: options.yesIcon || '' } }, options.yesText || '')
					)
				)
			)
		).outerHTML;
	}
}

export default Confirm;
