import '../../../css/customElement/editTagsReadonly.scss';

import S_Tags from '@services/Product/ProductTagsService';

class BadgeEditTagsReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-badge-edit-tags-readonly';

	public connectedCallback() {
		this.innerHTML = '';

		const id = this.dataset.id || '';
		const text = this.dataset.text || '';

		if (id) {
			S_Tags.getInstance().getDisplayRefByID(id).then((text) => {
				this.innerHTML = text;
			});
		}

		if (text) {
			this.innerHTML = text;
		}
	}
}

class EditTagsReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-edit-tags-readonly';

	private defaultValue: string = '';

	public connectedCallback() {
		this.defaultValue = this.getAttribute('defaultvalue') || '';
		this.innerHTML = this.defaultValue;
	}

	public set data(ids: string[]) {
		if (ids.length) {
			this.innerHTML = `
				<div class="content">
					&nbsp;
				</div>
			`;

			for (const id of ids) {
				this.renderItem(id);
			}
		} else {
			this.innerHTML = this.defaultValue;
		}
	}

	private renderItem(id: string) {
		const N_content = this.querySelector<HTMLElement>('.content');

		const N_div = document.createElement(BadgeEditTagsReadonly.tagName);

		N_div.dataset.id = id;

		N_content?.prepend(N_div);
	}

	public static register() {
		customElements.define(EditTagsReadonly.tagName, EditTagsReadonly);
		customElements.define(BadgeEditTagsReadonly.tagName, BadgeEditTagsReadonly);
	}
}

export default EditTagsReadonly;
