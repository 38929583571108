import EventEmitter from '@autoprog/eventemitter';
import StepContent from '@libs/StepModal/StepContent';

import T_Step from '../../../../tpl/modals/addEditProduct/steps/provider.html';

import '../../../../css/modals/steps/provider.scss';

import CE_FilesEdit from '@libs/customElement/FilesEdit';
import CE_InputWithAddon from '@libs/customElement/InputWithAddon';
import CE_Select from '@libs/customElement/Select';

import S_Pdt_Provider from '@services/Product/ProductProviderService';
import S_Provider from '@services/Provider/ProviderService';

import Alert from '@libs/Alert';
import DecimalApps from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';
import { toaster } from '@autoprog/core-client';

class ProviderStep extends StepContent {
	private providersData: { [id: string]: any } = {};

	private currentId: string = '';

	private oldIds: string[] = [];

	private isInit = false;
	private eventEmitter: EventEmitter = new EventEmitter();

	public async init() {
		const N_addProvider = this.el.querySelector<HTMLButtonElement>('#add-provider')!;
		const N_deleteProvider = this.el.querySelector<HTMLButtonElement>('#delete-provider')!;

		const N_providerID = this.el.querySelector<CE_Select>('[name="providerID"]')!;
		const N_providerLabel = this.el.querySelector<HTMLInputElement>('[name="provider_label"]')!;
		const N_isDefault = this.el.querySelector<HTMLInputElement>('[name="isDefault"]')!;
		const N_discount = this.el.querySelector<HTMLInputElement>('[name="discount"]')!;
		const N_shipPrice = this.el.querySelector<HTMLInputElement>('[name="shipPrice"]')!;
		const N_marginRate = this.el.querySelector<HTMLInputElement>('[name="marginRate"]')!;
		const N_publicPrice = this.el.querySelector<HTMLInputElement>('[name="publicPrice"]')!;
		const N_purchasePrice = this.el.querySelector<HTMLInputElement>('[name="purchasePrice"]')!;
		const N_price = this.el.querySelector<HTMLInputElement>('[name="price"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;

		this.eventEmitter.once('init', () => {
			if (this.currentId && this.isEdit) {
				N_addProvider.disabled = !this.checkProvidersValidity(true);
			}
		});

		N_addProvider.addEventListener('click', async () => {
			const N_item = this.renderProviderListCard({});

			await this.setActiveProvider(N_item.dataset.id!);
			this.updateRecommendedSellPrices();

			N_addProvider.disabled = !this.checkProvidersValidity(true);

			this.displayNoActiveProvider(false);
		});

		N_deleteProvider.addEventListener('click', async () => {
			if (!this.providersData[this.currentId].isDefault || Object.values(this.providersData).filter(provider => !provider.deleted).length === 1) {
				if (this.oldIds.includes(this.currentId)) {
					this.providersData[this.currentId].deleted = true;
				} else {
					delete this.providersData[this.currentId];
				}

				const N_menu = this.el.querySelector<HTMLElement>(`[data-id="${this.currentId}"]`);
				N_menu?.remove();

				const ids: string[] = [];
				for (const id in this.providersData) {
					if (!this.providersData[id].deleted) {
						ids.push(id);
					}
				}

				if (ids.length) {
					await this.setActiveProvider(ids[0], true);
				} else {
					await this.setActiveProvider(null);
					this.displayNoActiveProvider(true);
					N_addProvider.disabled = false;
				}
			} else {
				toaster.warning('Vous devez choisir un autre fournisseur par défaut avant de supprimer celui-ci.');
			}
		});

		N_providerID.options = {
			ajax: {
				url: S_Provider.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search
					};
				}
			}
		};

		N_providerID.addEventListener('change', async () => {
			this.updateDisabledProviderForm();

			const providerText = await S_Provider.getInstance().getDisplayRefByID(N_providerID.value as string);

			const N_ProviderName = this.el.querySelector(`#providers-list [data-id="${this.currentId}"] .provider-name`)!;
			N_ProviderName.innerHTML = providerText;

			const provider = await S_Provider.getInstance().getById(N_providerID.value as string);

			N_discount.value = provider.discount || '0';
			N_marginRate.value = provider.marginRate || '0';

			this.calcDataProviderForm();

			if (Object.keys(this.providersData).length === 1 || !this.hasDefaultProvider()) {
				N_isDefault.checked = true;
				this.providersData[this.currentId].isDefault = true;
				const N_list = this.el.querySelector(`#providers-list [data-id="${this.currentId}"]`)!;
				N_list.classList.toggle('isDefault', N_isDefault.checked);
			}

			this.checkValidity();
		});

		N_providerLabel.addEventListener('input', Utils.debounce(() => {
			this.providersData[this.currentId].provider_label = N_providerLabel.value;

			this.checkValidity();
		}, 500));

		N_isDefault.addEventListener('click', async () => {
			const N_item = this.el.querySelector(`#providers-list [data-id="${this.currentId}"]`)!;

			if (N_isDefault.checked) {
				let findID = '';
				for (const id in this.providersData) {
					if (this.providersData[id].isDefault && id !== this.currentId) {
						findID = id;
						break;
					}
				}

				if (findID) {
					await Alert.confirm('', `
						Attention, il y a déjà un fournisseur par défaut. Il sera remplacé.
						<br>
						<br>
						Voulez-vous continuer ?
					`, { yesType: 'validate' }).then(() => {
						this.providersData[this.currentId].isDefault = true;
						N_item.classList.add('isDefault');

						if (this.providersData[findID]) {
							const N_listOldDefault = this.el.querySelector(`#providers-list [data-id="${findID}"]`)!;
							N_listOldDefault.classList.remove('isDefault');
							this.providersData[findID].isDefault = false;
						}
					}).catch(() => {
						N_isDefault.checked = false;
					});
				} else {
					this.providersData[this.currentId].isDefault = true;
					N_item.classList.add('isDefault');
				}
			} else {
				N_isDefault.checked = true;
				toaster.warning('Il doit y avoir au moins un fournisseur par défaut');
			}
		});

		N_discount.addEventListener('input', () => {
			this.calcDataProviderForm();
		});

		N_shipPrice.addEventListener('input', () => {
			this.calcDataProviderForm();
		});

		N_marginRate.addEventListener('input', () => {
			this.calcDataProviderForm();
		});

		N_publicPrice.addEventListener('input', () => {
			this.calcDataProviderForm();
		});

		N_purchasePrice.addEventListener('input', () => {
			this.calcDataProviderForm(true);
		});

		N_price.addEventListener('input', () => {
			this.checkValidity();
		});

		await N_files.init();
	}

	public getTitle() {
		return 'Fournisseurs et prix de vente';
	}

	public getContent() {
		return T_Step;
	}

	private hasDefaultProvider() {
		return Object.entries(this.providersData).reduce((hasDefaultProvider, [, providerData]) => {
			return hasDefaultProvider || providerData.isDefault;
		}, false);
	}

	public getData() {
		if (this.currentId) {
			// Récupération des données des fournisseurs
			this.providersData[this.currentId] = this.getDataProviderForm();
		}

		const providers: { [key: string]: any }[] = [];

		for (const id in this.providersData) {
			if (this.providersData[id].providerID) {
				delete this.providersData[id].purchasePrice;
				delete this.providersData[id].costPrice;
				delete this.providersData[id].recommendedSellPrice;

				providers.push(this.providersData[id]);
			}
		}

		// Récupération des données du prix de vente
		const priceFormData = this.getDataPriceForm();

		return {
			providers,
			price: priceFormData.price
		};
	}

	public async setData(data: { [key: string]: any }) {
		// Initialisation des données des fournisseurs
		if (data.providers.length) {
			this.displayNoActiveProvider(false);

			for (const id of data.providers) {
				this.oldIds.push(id);

				const provider = await S_Pdt_Provider.getInstance().getDataToModal(id);

				let providerText = '';

				if (provider.data.data.providerID?.id) {
					providerText = provider.data.data.providerID?.text;
					provider.data.data.providerID = provider.data.data.providerID.id;
				}

				this.renderProviderListCard(provider.data.data, providerText);
			}
			await this.setActiveProvider(data.providers[0]);
		} else {
			this.displayNoActiveProvider(true);
		}

		// Initialisation des données pour le prix de vente
		this.setDataPriceForm(data);
		this.updateRecommendedSellPrices();

		if (this.isEdit) {
			this.checkValidity();
		}

		this.isInit = true;
		this.eventEmitter.emit('init');
	}

	private displayNoActiveProvider(display: boolean) {
		const N_NoProvider = this.el.querySelector('#no-provider');
		const N_ProviderDetail = this.el.querySelector('#provider-detail');
		const N_ProviderPrice = this.el.querySelector('#provider-price');
		const recommendedPrices = this.el.querySelector('#recommended-prices');

		N_NoProvider?.classList.toggle('d-none', !display);
		N_ProviderDetail?.classList.toggle('d-none', display);
		N_ProviderPrice?.classList.toggle('d-none', display);
		recommendedPrices?.classList.toggle('d-none', display);
	}

	public checkValidity(): boolean {
		// Vérification de la validité des fournisseurs

		let areProvidersValid = true;

		if (this.currentId) {
			this.calcDataProviderForm();
			areProvidersValid = this.checkProvidersValidity();
		}

		const N_addProvider = this.el.querySelector<HTMLButtonElement>('#add-provider')!;
		N_addProvider.disabled = !areProvidersValid;

		// Vérification de la validité du prix de vente

		const N_priceFormTitle = this.el.querySelector<HTMLDivElement>('#price-form-title')!;
		const N_price = this.el.querySelector<CE_InputWithAddon>('#price')!;

		const isPriceValid = N_price.checkValidity();

		N_priceFormTitle.classList.toggle('invalid', !isPriceValid);

		if (!isPriceValid) {
			toaster.warning('Prix de vente non renseigné');
		}

		return areProvidersValid && isPriceValid;
	}

	public checkProvidersValidity(ignoreInvalidStyle: boolean = false) {
		let areProvidersValid = true;
		let defaultProviderCount = 0;

		for (const [providerID, providerData] of Object.entries(this.providersData)) {
			const providerValidity = this.checkProviderValidity(providerID, providerData, ignoreInvalidStyle);

			if (!ignoreInvalidStyle) {
				const N_listCardProvider = this.el.querySelector<HTMLDivElement>(`.list-card[data-id="${providerID}"]`);
				N_listCardProvider?.classList.toggle('invalid-provider', !providerValidity);
			}

			if (providerData.isDefault) {
				defaultProviderCount++;
			}

			areProvidersValid &&= providerValidity;
		}

		areProvidersValid &&= defaultProviderCount === 1;

		return areProvidersValid;
	}

	public checkProviderValidity(providerID: string, providerData: any, ignoreInvalidStyle: boolean = false): boolean {
		if (!ignoreInvalidStyle) {
			if (providerID === this.currentId) {
				const N_providerIDFormItem = this.el.querySelector<HTMLDivElement>('#providerID-form-item')!;
				N_providerIDFormItem?.classList.toggle('invalid', !providerData.providerID);

				const N_providerID = this.el.querySelector<CE_Select>('[name="providerID"]')!;
				N_providerID.checkValidity();

				const N_providerLabelFormItem = this.el.querySelector<HTMLDivElement>('#providerLabel-form-item')!;
				N_providerLabelFormItem?.classList.toggle('invalid', !providerData.provider_label);
			}
		}

		return !!(providerData.providerID && providerData.provider_label);
	}

	/* -------------------------------------------------------------------------- */
	/*                                Fournisseurs                                */
	/* -------------------------------------------------------------------------- */

	private renderProviderListCard(data: { [key: string]: any }, providerText: string = '') {
		data._id = data._id || Utils.generateId();

		this.providersData[data._id] = data;

		const N_list = this.el.querySelector('.list-cards')!;

		const N_item = document.createElement('div');
		N_item.classList.add('list-card');
		N_item.dataset.id = data._id;

		N_item.innerHTML = `
			<div class="list-card-left">
				<ap-icon name="star/fill"></ap-icon>
			</div>
			
			<div class="list-card-center">
				<span class="provider-name">${providerText || 'Nouveau fournisseur'}</span>
			</div>
		
			<div class="list-card-right">
				<div class="list-card-arrow">
					<ap-icon name="arrow-right-s/line"></ap-icon>
				</div>
			</div>
		`;

		N_item?.addEventListener('click', async () => {
			await this.setActiveProvider(data._id);
		});

		N_item.classList.toggle('isDefault', !!data.isDefault);

		N_list.append(N_item);

		return N_item;
	}

	private async setActiveProvider(id: string | null, isDelete = false) {
		if (id) {
			if (this.currentId && !isDelete) {
				this.providersData[this.currentId] = this.getDataProviderForm();

				const N_itemOldActive = this.el.querySelector(`#providers-list [data-id="${this.currentId}"]`)!;
				N_itemOldActive?.classList.remove('active');
			}

			this.currentId = id;

			const N_item = this.el.querySelector(`#providers-list [data-id="${this.currentId}"]`)!;
			N_item.classList.add('active');

			const selectedItems = document.querySelectorAll('.list-card-selected');
			selectedItems.forEach(itemSelected => {
				itemSelected.classList.remove('list-card-selected');
			});
			N_item.classList.add('list-card-selected');

			await this.setDataProviderForm(this.providersData[this.currentId]);

			this.updateDisabledProviderForm();
		} else {
			this.currentId = '';
			this.displayNoActiveProvider(true);
		}

		this.updateRecommendedSellPrices();
	}

	private getDataProviderForm() {
		const N_providerID = this.el.querySelector<CE_Select>('[name="providerID"]')!;
		const N_provider_reference = this.el.querySelector<HTMLInputElement>('[name="provider_reference"]')!;
		const N_provider_label = this.el.querySelector<HTMLInputElement>('[name="provider_label"]')!;
		const N_deliveryTime = this.el.querySelector<HTMLInputElement>('[name="deliveryTime"]')!;
		const N_quoteBase = this.el.querySelector<HTMLInputElement>('[name="quoteBase"]')!;
		const N_publicPrice = this.el.querySelector<HTMLInputElement>('[name="publicPrice"]')!;
		const N_purchasePrice = this.el.querySelector<HTMLInputElement>('[name="purchasePrice"]')!;
		const N_costPrice = this.el.querySelector<HTMLInputElement>('[name="costPrice"]')!;
		const N_discount = this.el.querySelector<HTMLInputElement>('[name="discount"]')!;
		const N_shipPrice = this.el.querySelector<HTMLInputElement>('[name="shipPrice"]')!;
		const N_marginRate = this.el.querySelector<HTMLInputElement>('[name="marginRate"]')!;
		const N_recommendedSellPrice = this.el.querySelector<HTMLInputElement>('[name="recommendedSellPrice"]')!;
		const N_isDefault = this.el.querySelector<HTMLInputElement>('[name="isDefault"]')!;
		const N_comment = this.el.querySelector<HTMLInputElement>('[name="comment"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;

		return {
			_id: this.currentId,
			providerID: N_providerID.value,
			provider_reference: N_provider_reference.value,
			provider_label: N_provider_label.value,
			deliveryTime: N_deliveryTime.value,
			quoteBase: N_quoteBase.value,
			publicPrice: N_publicPrice.value,
			purchasePrice: N_purchasePrice.value,
			costPrice: N_costPrice.value,
			discount: N_discount.value,
			shipPrice: N_shipPrice.value,
			marginRate: N_marginRate.value,
			recommendedSellPrice: N_recommendedSellPrice.value,
			isDefault: N_isDefault.checked,
			comment: N_comment.value,
			files: N_files.value
		};
	}

	private async setDataProviderForm(data: { [key: string]: any }) {
		const N_providerID = this.el.querySelector<CE_Select>('[name="providerID"]')!;
		const N_provider_reference = this.el.querySelector<HTMLInputElement>('[name="provider_reference"]')!;
		const N_provider_label = this.el.querySelector<HTMLInputElement>('[name="provider_label"]')!;
		const N_deliveryTime = this.el.querySelector<HTMLInputElement>('[name="deliveryTime"]')!;
		const N_quoteBase = this.el.querySelector<HTMLInputElement>('[name="quoteBase"]')!;
		const N_publicPrice = this.el.querySelector<HTMLInputElement>('[name="publicPrice"]')!;
		const N_purchasePrice = this.el.querySelector<HTMLInputElement>('[name="purchasePrice"]')!;
		const N_costPrice = this.el.querySelector<HTMLInputElement>('[name="costPrice"]')!;
		const N_discount = this.el.querySelector<HTMLInputElement>('[name="discount"]')!;
		const N_shipPrice = this.el.querySelector<HTMLInputElement>('[name="shipPrice"]')!;
		const N_marginRate = this.el.querySelector<HTMLInputElement>('[name="marginRate"]')!;
		const N_recommendedSellPrice = this.el.querySelector<HTMLInputElement>('[name="recommendedSellPrice"]')!;
		const N_isDefault = this.el.querySelector<HTMLInputElement>('[name="isDefault"]')!;
		const N_comment = this.el.querySelector<HTMLInputElement>('[name="comment"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;

		if (data.providerID) {
			N_providerID.value = await S_Provider.getInstance().getDataToSelect2ByID(data.providerID as string);
		} else {
			N_providerID.value = null;
		}

		N_provider_reference.value = data.provider_reference || '';
		N_provider_label.value = data.provider_label || '';
		N_deliveryTime.value = data.deliveryTime || 0;
		N_quoteBase.value = data.quoteBase || '';
		N_publicPrice.value = data.publicPrice || 0;
		N_purchasePrice.value = data.purchasePrice || 0;
		N_costPrice.value = data.costPrice || '';
		N_discount.value = data.discount || 0;
		N_shipPrice.value = data.shipPrice || 0;
		N_marginRate.value = data.marginRate || 0;
		N_recommendedSellPrice.value = data.recommendedSellPrice || 0;
		N_isDefault.checked = data.isDefault || false;
		N_comment.value = data.comment || '';

		N_files.value = data.files || [];
	}

	private updateDisabledProviderForm() {
		const N_providerID = this.el.querySelector<CE_Select>('[name="providerID"]')!;

		const N_provider_reference = this.el.querySelector<HTMLInputElement>('[name="provider_reference"]')!;
		const N_provider_label = this.el.querySelector<HTMLInputElement>('[name="provider_label"]')!;
		const N_deliveryTime = this.el.querySelector<HTMLInputElement>('[name="deliveryTime"]')!;
		const N_quoteBase = this.el.querySelector<HTMLInputElement>('[name="quoteBase"]')!;
		const N_publicPrice = this.el.querySelector<HTMLInputElement>('[name="publicPrice"]')!;
		const N_discount = this.el.querySelector<HTMLInputElement>('[name="discount"]')!;
		const N_shipPrice = this.el.querySelector<HTMLInputElement>('[name="shipPrice"]')!;
		const N_marginRate = this.el.querySelector<HTMLInputElement>('[name="marginRate"]')!;
		const N_isDefault = this.el.querySelector<HTMLInputElement>('[name="isDefault"]')!;
		const N_comment = this.el.querySelector<HTMLInputElement>('[name="comment"]')!;

		const N_files = this.el.querySelector<CE_FilesEdit>(CE_FilesEdit.tagName)!;

		N_provider_reference.disabled = !N_providerID.value;
		N_provider_label.disabled = !N_providerID.value;
		N_deliveryTime.disabled = !N_providerID.value;
		N_quoteBase.disabled = !N_providerID.value;
		N_publicPrice.disabled = !N_providerID.value;
		N_discount.disabled = !N_providerID.value;
		N_shipPrice.disabled = !N_providerID.value;
		N_marginRate.disabled = !N_providerID.value;
		N_isDefault.disabled = !N_providerID.value;
		N_comment.disabled = !N_providerID.value;

		N_files.disabled = !N_providerID.value;
	}

	private calcDataProviderForm(fromPurchasePrice: boolean = false) {
		const data = this.getDataProviderForm();

		const N_publicPrice = this.el.querySelector<HTMLInputElement>('[name="publicPrice"]')!;
		const N_purchasePrice = this.el.querySelector<HTMLInputElement>('[name="purchasePrice"]')!;
		const N_costPrice = this.el.querySelector<HTMLInputElement>('[name="costPrice"]')!;
		const N_recommendedSellPrice = this.el.querySelector<HTMLInputElement>('[name="recommendedSellPrice"]')!;

		if (fromPurchasePrice && data.discount !== '' && data.purchasePrice !== '') {
			data.publicPrice = this.calcProviderPublicPrice(data.discount, data.purchasePrice);
			N_publicPrice.value = data.publicPrice;
		}

		if (!fromPurchasePrice && data.discount !== '' && data.publicPrice !== '') {
			data.purchasePrice = this.calcProviderPurchasePrice(data.discount, data.publicPrice);
			N_purchasePrice.value = data.purchasePrice;
		}

		if (data.shipPrice !== '' && data.purchasePrice !== '') {
			data.costPrice = this.calcProviderCostPrice(data.shipPrice, data.purchasePrice);
			N_costPrice.value = data.costPrice;
		}

		if (data.costPrice !== '' && data.marginRate !== '') {
			data.recommendedSellPrice = this.calcProviderRecommendedSellPrice(data.costPrice, data.marginRate);
			N_recommendedSellPrice.value = data.recommendedSellPrice;
		}

		this.updateRecommendedSellPrices();
	}

	private calcProviderPurchasePrice(discount: string, publicPrice: string) {
		const discountDecimal = DecimalApps.setDisplayNumber(discount);
		const publicPriceDecimal = DecimalApps.setDisplayNumber(publicPrice);

		// Prix achat = prix public x  (1 - (Pourc. Remise / 100))
		const percentDiscount = new DecimalApps(1).minus(discountDecimal.dividedBy(100));
		const purchasePrice = publicPriceDecimal.times(percentDiscount).toDecimalPlaces(4);

		return purchasePrice.toString();
	}

	public calcProviderCostPrice(shipPrice: string, purchasePrice: string) {
		const shipPriceDecimal = DecimalApps.setDisplayNumber(shipPrice);
		const purchasePriceDecimal = DecimalApps.setDisplayNumber(purchasePrice);

		// Coût de revient = Prix achat + Prix transport
		const costPrice = purchasePriceDecimal.plus(shipPriceDecimal).toDecimalPlaces(2);

		return costPrice.toString();
	}

	private calcProviderRecommendedSellPrice(costPrice: string, marginRate: string) {
		const costPriceDecimal = DecimalApps.setDisplayNumber(costPrice);
		const marginRateDecimal = DecimalApps.setDisplayNumber(marginRate);

		// prix de vente = coût de revient / (1 - tx marge/100)
		const recommendedSellPrice = costPriceDecimal.dividedBy(new DecimalApps(1).minus(marginRateDecimal.dividedBy(100))).toDecimalPlaces(2);

		return recommendedSellPrice.toString();
	}

	private calcProviderPublicPrice(discount: string, purchasePrice: string) {
		const discountDecimal = DecimalApps.setDisplayNumber(discount);
		const purchasePriceDecimal = DecimalApps.setDisplayNumber(purchasePrice);

		// Prix public = Prix achat / (1 - (Pourc. Remise / 100))
		const percentDiscount = new DecimalApps(1).minus(discountDecimal.dividedBy(100));
		const publicPrice = purchasePriceDecimal.dividedBy(percentDiscount).toDecimalPlaces(4);

		return publicPrice.toString();
	}

	/* -------------------------------------------------------------------------- */
	/*                                Prix de vente                               */
	/* -------------------------------------------------------------------------- */

	private getDataPriceForm() {
		const N_price = this.el.querySelector<HTMLInputElement>('[name="price"]')!;

		return {
			price: N_price.value
		};
	}

	private setDataPriceForm(data: { [key: string]: any }) {
		const N_price = this.el.querySelector<HTMLInputElement>('[name="price"]')!;
		N_price.value = data.price;
	}

	public getAllRecommendedSellPrice() {
		type ProviderWithRecommendedSellPrice = { providerID: string, providerText: string, recommendedSellPrice: string, isDefault: boolean, deleted: boolean };

		return new Promise<ProviderWithRecommendedSellPrice[]>((resolve) => {
			const getData = async () => {
				if (!this.currentId) {
					resolve([]);
				} else {
					this.providersData[this.currentId] = this.getDataProviderForm();

					const providers: ProviderWithRecommendedSellPrice[] = [];

					for (const [id, provider] of Object.entries(this.providersData)) {
						if (!provider.deleted) {
							let providerText = '';

							if (this.providersData[id].providerID) {
								providerText = await S_Provider.getInstance().getDisplayRefByID(this.providersData[id].providerID as string);
							}

							providers.push({
								providerID: this.providersData[id].providerID,
								providerText,
								recommendedSellPrice: this.providersData[id].recommendedSellPrice,
								isDefault: this.providersData[id].isDefault,
								deleted: this.providersData[id].deleted
							});
						}
					}

					resolve(providers);
				}
			};

			if (this.isInit) {
				getData();
			} else {
				this.eventEmitter.once('init', () => {
					getData();
				});
			}
		});
	}

	private updateRecommendedSellPrices() {
		const N_RecommendedSellPrices = this.el.querySelector<HTMLDivElement>('.recommended-sell-prices')!;

		this.getAllRecommendedSellPrice().then((providers) => {
			N_RecommendedSellPrices.innerHTML = '';

			for (const provider of providers) {
				const N_RecommendedSellPrice = document.createElement('div');
				N_RecommendedSellPrice.classList.add('recommended-sell-price');
				N_RecommendedSellPrice.innerHTML = `
					<div class="recommended-sell-price-provider-name">
						${provider.providerText || 'Nouveau fournisseur'}
					</div>
					<div class="recommended-sell-price-value">
						${provider.recommendedSellPrice ? provider.recommendedSellPrice + ' €' : '0 €'}
					</div>
				`;

				N_RecommendedSellPrices.append(N_RecommendedSellPrice);
			}
		});
	}
}

export default ProviderStep;
