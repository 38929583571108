// TEMPLATE
import T_modal from '../../../../tpl/modals/users/editPage/companies.html';

// MODEL
import { Company } from '@modules/Settings/js/types/company';
import Modal from '@libs/EditPageModal';

// SERVICE
import S_Companies from '@services/CompaniesService';

type CompaniesData = {
	companies: string[]
};

class Companies extends Modal<CompaniesData> {
	private companies: any[] = [];

	private selectedCompanies: string[] = [];

	constructor(data: CompaniesData, companies: Company[]) {
		super(data, T_modal);

		this.on('opening', async () => {
			this.companies = companies;
			this.selectedCompanies = data.companies;
		});
	}

	protected checkValidityForm() {
		const isValid = this.selectedCompanies.length > 0;

		const N_InvalidText = this.element.querySelector('#invalid-text') as HTMLDivElement;
		N_InvalidText.classList.toggle('d-none', isValid);

		return isValid;
	}

	protected async getDataForm() {
		const data = { companies: await this.getCompanies(this.selectedCompanies) };
		return data;
	}

	private async getCompanies(companies: string[]) {
		const res: { [key: string]: any }[] = [];
		for (const item of companies) {
			res.push(await S_Companies.getInstance().getDataToSelect2ByID(item));
		}
		return res;
	}

	protected preInit() {
		this.initForm();

		if (this.companies.length > 1) {
			this.initSwitchAll();
		}
	}

	private initSwitchAll() {
		const companiesSwitchesInput = this.element.querySelectorAll('.companies-switches .company-switch input') as NodeListOf<HTMLInputElement>;
		const N_CompaniesSwitchAll = this.element.querySelector('#companies-switch-all') as HTMLDivElement;
		const N_CompaniesSwitchAllInput = this.element.querySelector('#companies-switch-all input') as HTMLInputElement;

		this.updateSwitchAll(N_CompaniesSwitchAllInput);

		N_CompaniesSwitchAll.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation();

			N_CompaniesSwitchAllInput.checked = !N_CompaniesSwitchAllInput.checked;

			this.selectedCompanies = [];

			for (const N_Input of companiesSwitchesInput) {
				N_Input.checked = N_CompaniesSwitchAllInput.checked;
				this.companySelection(N_Input.id, N_Input.checked);
			}
		});
	}

	private updateSwitchAll(N_CompaniesSwitchAllInput: HTMLInputElement) {
		if (this.companies.length === this.selectedCompanies.length) {
			N_CompaniesSwitchAllInput.checked = true;
		} else {
			N_CompaniesSwitchAllInput.checked = false;
		}
	}

	private initForm() {
		const N_NoCompany = this.element.querySelector('#no-company') as HTMLDivElement;
		const N_CompaniesSwitchAll = this.element.querySelector('#companies-switch-all') as HTMLDivElement;
		const N_CompaniesSwitches = this.element.querySelector('#companies-switches') as HTMLDivElement;

		N_NoCompany.classList.toggle('d-none', this.companies.length > 0);
		N_CompaniesSwitchAll.classList.toggle('d-none', !(this.companies.length > 1));
		N_CompaniesSwitches.classList.toggle('d-none', !(this.companies.length > 0));

		for (const company of this.companies) {
			const N_CompanySwitch = document.createElement('div');

			N_CompanySwitch.classList.add('company-switch');

			N_CompanySwitch.innerHTML = `
                <div class="company-switch-label">${company.name}</div>
                <div class="custom-control custom-switch custom-switch-md">
                    <input type="checkbox" class="custom-control-input" id="${company._id}" name="company-${company._id}" value="${company._id}" ${this.selectedCompanies.includes(company._id) ? 'checked' : ''}>
                    <label class="custom-control-label" for="${company._id}"></label>
                </div>
            `;

			const N_CompanySwitchInput = N_CompanySwitch.querySelector('input') as HTMLInputElement;
			const N_CompaniesSwitchAllInput = this.element.querySelector('#companies-switch-all input') as HTMLInputElement;

			N_CompanySwitch.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				N_CompanySwitchInput.checked = !N_CompanySwitchInput.checked;
				this.companySelection(company._id, N_CompanySwitchInput.checked);

				this.updateSwitchAll(N_CompaniesSwitchAllInput);
			});

			N_CompaniesSwitches.append(N_CompanySwitch);
		}
	}

	private companySelection(companyID: string, isSelect: boolean) {
		if (isSelect && !this.selectedCompanies.includes(companyID)) {
			this.selectedCompanies.push(companyID);
		} else {
			this.selectedCompanies = this.selectedCompanies.filter((id) => id !== companyID);
		}
	}
}

export default Companies;
