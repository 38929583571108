import S_Settings_Accounting from '@modules/Comptabilité/js/services/Settings.AccountingService';

class Settings {
	private static instance: Settings;

	private data: { [key: string]: any } = {};

	private constructor() {

	}

	public static getInstance() {
		if (!Settings.instance) {
			Settings.instance = new Settings();
		}

		return Settings.instance;
	}

	public async loadByCompany(companyID: string) {
		this.data = {
			accountings: await new S_Settings_Accounting().getByCompany(companyID)
		};
	}

	public get(key: 'accountings') {
		return this.data[key] || {};
	}
}

export default Settings;
