import { CAttribute, DOM } from '@autoprog/core-client';

import moment from 'moment';

class Readonly extends CAttribute {
	public static attrName: string = 'ap-readonly';

	private originalInnerHTML = '';

	constructor(el: HTMLElement | SVGElement) {
		super(el);

		this.create();
	}

	public attributeChanged(name: string): void {
		if (name === Readonly.attrName) {
			this.create();
		}
	}

	private formatValue(value: string) {
		return this.originalInnerHTML + value + (this.el.getAttribute('suffix') || '');
	}

	private hiddenInputs() {
		const N_inputs = this.el.querySelectorAll('input, textarea, select,ap-tva') as NodeListOf<HTMLElement>;
		for (const item of N_inputs) {
			item.classList.add('d-none');
		}
	}

	private create() {
		this.hiddenInputs();

		const N_inputText = this.el.querySelector('input:not([type="date"],[type="checkbox"])');

		let value = '';

		const instance = this;

		this.originalInnerHTML = instance.el.innerHTML;

		if (N_inputText) {
			Object.defineProperty(N_inputText, 'value', {
				set: function (v) {
					instance.el.innerHTML = instance.formatValue(v);
					instance.hiddenInputs();
					value = v;
				},
				get: function () {
					return value;
				}
			});
		}

		const N_inputDate = this.el.querySelector('input[type="date"]');
		if (N_inputDate) {
			Object.defineProperty(N_inputDate, 'valueAsDate', {
				set: function (v) {
					instance.el.innerHTML = instance.formatValue(moment(v).format('DD/MM/YYYY'));
					instance.hiddenInputs();
					value = v;
				},
				get: function () {
					return value;
				}
			});
		}

		const N_inputCheckbox = this.el.querySelector('input[type="checkbox"]');
		if (N_inputCheckbox) {
			Object.defineProperty(N_inputCheckbox, 'checked', {
				set: function (v) {
					instance.el.innerHTML = v ? '<i class="icon icon-solid-check text-success"></i>' : '<i class="icon icon-solid-times text-danger"></i>';
					instance.hiddenInputs();
					value = v;
				},
				get: function () {
					return value;
				}
			});
		}

		const N_textarea = this.el.querySelector('textarea');

		if (N_textarea) {
			this.el.style.whiteSpace = 'pre';

			Object.defineProperty(N_textarea, 'value', {
				set: function (v) {
					instance.el.innerHTML = instance.formatValue(v);
					instance.hiddenInputs();
					value = v;
				},
				get: function () {
					return value;
				}
			});
		}

		const N_select = this.el.querySelector('select');

		if (N_select) {
			Object.defineProperty(N_select, 'value', {
				set: function (v) {
					if (v instanceof Array) {
						const res: string[] = [];
						for (const item of v) {
							const N_option = N_select.querySelector(`[value="${item}"]`) as HTMLOptionElement;
							res.push(instance.formatValue(N_option.innerHTML));
						}
						instance.el.innerHTML = res.join(', ');
					} else {
						const N_option = N_select.querySelector(`[value="${v}"]`) as HTMLOptionElement;
						instance.el.innerHTML = instance.formatValue(N_option.innerHTML);
					}
					instance.hiddenInputs();
					value = v;
				},
				get: function () {
					return value;
				}
			});
		}
	}

	public destructor() {
		this.el.removeAttribute(Readonly.attrName);
	}

	public static registry() {
		DOM.registerAttribute(Readonly as any);
	}
}

export default Readonly;
