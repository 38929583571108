import { LoggedUser, SocketIO } from '@autoprog/core-client';

import '@css/customElements/messages.scss';

class Messages extends HTMLElement {
	public static readonly tagName: string = 'ap-messages';

	public connectedCallback() {
		this.innerHTML = '<div class="content"></div>';

		const N_content = this.querySelector('.content')!;

		const loggedUser = LoggedUser.getInstance();

		const io = SocketIO.getInstance();

		io.whenConnected().then(() => {
			io.on('messages.value', (value: string) => {
				N_content.innerHTML = value;
			});

			if (loggedUser.isLogged) {
				io.emit('messages.init', true);
			}

			loggedUser.on('login', () => {
				io.emit('messages.init', true);
			});
		});
	}

	public static register() {
		customElements.define(Messages.tagName, Messages);
	}
}

export default Messages;
