import { Tab, toaster } from '@autoprog/core-client';

import _ from 'lodash';

import Decimal from '@libs/utils/Decimal';
import StateSaver from '@libs/agGrid/StateSaver';

import M_modal from '../modals/groupsProducts/GroupsProducts';

import { AllModules, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';

import agUtils from '@libs/agGrid/french';

import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_HeaderDashboard from '@libs/customElement/HeaderDashboard';

import S_Pdt_Groups from '@services/Product/GroupsService';
import S_Products from '@services/Product/ProductService';

class GroupsTab extends Tab {
	private element: HTMLElement;
	private cancellers: EventListenerCanceller[] = [];
	private productService: S_Products;

	private gridOptions: GridOptions = {};

	private abortController: AbortController;
	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	constructor(el: HTMLElement) {
		super(el);

		this.abortController = new AbortController();

		this.element = el;
		this.productService = S_Products.getInstance();

		const N_Infos = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		N_Infos.reset();
		N_Infos.setTitle('Groupe produits');

		const N_add = document.querySelector('#add') as HTMLElement;

		N_add.addEventListener('click', () => {
			new M_modal().open().then(() => {
				this.getData();
			});
		}, {
			signal: this.abortSignal
		});

		N_add.setAttribute('permission', 'PRODUCTS._GROUPS.ADD');

		this.init().then(() => {
			N_Infos.setGridOptions(this.gridOptions);
			this.initGridSidebar();
		});
	}

	private async init() {
		this.gridOptions = agUtils.french<GridOptions>({
			rowData: [],
			animateRows: true,
			floatingFilter: true,
			suppressDragLeaveHidesColumns: true,
			suppressContextMenu: false,
			defaultColDef: {
				suppressMenu: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				resizable: true,
				filterParams: {
					newRowsAction: 'keep'
				}
			},
			columnDefs: [{
				field: 'name',
				headerName: 'Nom',
				rowGroup: true,
				hide: true
			}, {
				headerName: this.productService.columnNameReference,
				headerTooltip: this.productService.columnNameReference,
				field: `product.${this.productService.referenceKey}`,
				filterParams: {
					textFormatter: (result: string) => {
						if (result == null) return null;
						return _.deburr(result.toLowerCase());
					},
					debounceMS: 200
				}
			}, {
				headerName: 'Désignation',
				headerTooltip: 'Désignation',
				field: 'product.name',
				filterParams: {
					textFormatter: (result: string) => {
						if (result == null) return null;
						return _.deburr(result.toLowerCase());
					},
					debounceMS: 200
				}
			}, {
				headerName: 'Marque',
				headerTooltip: 'Marque',
				field: 'product.brand',
				filter: 'agSetColumnFilter',
				floatingFilterComponentParams: {
					suppressFilterButton: false
				},
				filterParams: {
					applyMiniFilterWhileTyping: true
				}
			}, {
				headerName: 'Quantité',
				headerTooltip: 'Quantité',
				field: 'quantity',
				cellClass: ['text-monospace', 'text-right'],
				comparator: (valueA, valueB) => {
					valueA = Decimal.setDisplayNumber(valueA).toNumber();
					valueB = Decimal.setDisplayNumber(valueB).toNumber();
					return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
				},
				//On filtre sur le début du nombre
				filterParams: {
					textCustomComparator: (_: string, value: string, filterNumber: string) => {
						filterNumber = Decimal.setDisplayNumber(filterNumber).toString();
						return Decimal.setDisplayNumber(value).toString().startsWith(filterNumber);
					}
				}
			}, {
				headerName: 'Prix',
				headerTooltip: 'Prix',
				field: 'product.price',
				filter: 'agNumberColumnFilter',
				cellClass: ['text-monospace', 'text-right'],
				comparator: (valueA, valueB) => {
					valueA = valueA?.value || '';
					valueB = valueB?.value || '';
					return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
				},
				floatingFilterComponentParams: {
					suppressFilterButton: false
				},
				valueGetter: (params) => {
					return (_.get(params.data, params.column.getColDef()?.field || '') || {}).value;
				},
				cellRenderer: (params) => {
					if (params.node.group) {
						let price = new Decimal(0);

						for (const node of params.node.childrenAfterGroup) {
							const tmpPrice = Decimal.setDisplayNumber(node.data.product.price.value).times(node.data.quantity || 0);
							price = price.plus(tmpPrice);
						}

						return `<span class="font-weight-bold">${price.setSuffixAndHumanizeNumber('€')}</span>`;
					} else {
						return (_.get(params.data, params.column.colDef.field) || {}).formattedValue;
					}
				}
			}, {
				headerName: 'Action',
				headerClass: 'ag-theme-custom-text-center',
				suppressSizeToFit: true,
				filter: false,
				pinned: 'right',
				sortable: false,
				suppressColumnsToolPanel: true,
				width: 100,
				cellRenderer: (params) => {
					const N_div = document.createElement('div');

					if (!params.data) {
						const N_edit = document.createElement('button');

						N_edit.setAttribute('permission', 'PRODUCTS._GROUPS.OPEN');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

						N_edit.setAttribute('tooltip', 'Éditer');

						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

						N_edit.addEventListener('click', () => {
							new M_modal(params.node.key).open().then(() => {
								this.getData();
							});
						});

						const N_delete = document.createElement('button');

						N_delete.setAttribute('permission', 'PRODUCTS._GROUPS.DELETE');

						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.setAttribute('tooltip', 'Supprimer');
						N_delete.setAttribute('confirmation', '');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

						N_delete.addEventListener('click', () => {
							S_Pdt_Groups.getInstance().deleteByName(params.node.key)
								.then(() => {
									this.getData();
									toaster.success(`Suppression du groupe produit "${params.node.key}".`);
								})
								.catch((e) => {
									console.error(e);
									toaster.error('Impossible de supprimer le groupe produit.', 'Erreur serveur');
								});
						});

						N_div.appendChild(N_edit);
						N_div.appendChild(N_delete);
					}

					return N_div;
				}
			}],
			autoGroupColumnDef: {
				headerName: 'Nom',
				filter: 'agTextColumnFilter',
				filterValueGetter: params => params.data.name
			},
			getContextMenuItems: (params) => {
				const menu: Array<MenuItemDef> = [];

				if (params.node.key) {
					menu.push({
						name: `Groupe ${params.node.key}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					});
					menu.push({
						name: 'Éditer',
						icon: '<i class="icon icon-edit"></i>',
						action: () => {
							new M_modal(params.node.key!).open().then(() => {
								this.getData();
							});
						}
					});
				}

				return menu;
			}
		});

		const N_grid = this.element.querySelector('#grid');

		if (N_grid) {
			new Grid(N_grid as HTMLElement, this.gridOptions, { modules: AllModules });
		}

		await this.getData();
	}

	private initGridSidebar() {
		const N_GridSidebar = this.element.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.gridOptions);
	}

	private async getData() {
		const data = await S_Pdt_Groups.getInstance().getDataToAgGrid(this.abortController);
		this.gridOptions.api?.setRowData(data.rowData);

		const stateSaver = new StateSaver(this.gridOptions as any, 'products-groups-grid');
		stateSaver.setData(data.settings);
	}

	public destructor() {
		this.abortController.abort('destroyed');

		for (const canceller of this.cancellers) {
			canceller();
		}
	}
}

export default GroupsTab;
