import GenericService from '../GenericService';

class CustomerAddressService extends GenericService {
	private static instance: CustomerAddressService | null = null;

	constructor() {
		super('customers-address');
	}

	public static getInstance(): CustomerAddressService {
		if (!CustomerAddressService.instance) {
			CustomerAddressService.instance = new CustomerAddressService();
		}

		return CustomerAddressService.instance;
	}

	public async getFullAddress(id: string): Promise<string> {
		return this.get_function('getFullAddress', {
			id
		});
	}

	public async getAddressInfos(id: string): Promise<{ [key: string]: any }> {
		return this.get_function('getAddressInfos', {
			id
		});
	}

	public getDataToSelect2ByObject2(item: { [key: string]: any }) {
		return {
			id: item._id,
			text: item.name
		};
	}

	public async getByCustomerToSelect2(id_customer: string) {
		return this.searchByPattern('', { id_customer });
	}

	public async getBillAddressByCustomer(id_customer: string) {
		return this.get_function('getBillAddressByCustomer', {
			id_customer
		});
	}

	public async getDeliveryAddressByCustomer(id_customer: string) {
		return this.get_function('getDeliveryAddressByCustomer', {
			id_customer
		});
	}

	public async getAddressByCustomer(id_customer: string) {
		return this.get_function('getAddressByCustomer', {
			id_customer
		});
	}
}

export default CustomerAddressService;
