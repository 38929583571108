import { GridOptions } from '@ag-grid-community/core';
import _merge from 'lodash/merge';

const french = <T = GridOptions>(gridOptions: T): T => {
	return _merge({

		localeText: {

			// for filter panel
			page: 'Page',
			more: 'Plus',
			to: 'à',
			of: 'De',
			next: 'Suivant',
			last: 'Précédent',
			first: 'Premier',
			previous: 'Précédent',
			loadingOoo: 'Chargement ...',

			// for set filter
			selectAll: 'Sélectionner tous',
			searchOoo: 'Rechercher...',
			blanks: 'Vide',
			clearFilter: 'Supprimer les filtres',

			// for number filter and text filter
			filterOoo: 'Filtre ...',
			applyFilter: 'Appliquer les filtres ...',
			equals: 'Egals',
			notEquals: 'Pas égals',

			// for number filter
			notEqual: 'Pas égal',
			lessThan: 'Plus petit que',
			greaterThan: 'Plus grand que',
			lessThanOrEqual: 'Plus petit ou égal à',
			greaterThanOrEqual: 'Plus grand ou égal à',
			inRange: 'Entre',

			// for text filter
			contains: 'Contient',
			notContains: 'Ne contient pas',
			startsWith: 'Commence par',
			endsWith: 'Se termine par',

			// filter conditions
			andCondition: 'ET',
			orCondition: 'OU',

			// the header of the default group column
			group: 'Groupe',

			// tool panel
			columns: 'Colonnes',
			rowGroupColumns: 'Pivot colonnes',
			rowGroupColumnsEmptyMessage: ' faites glisser les colonnes vers le groupe',
			valueColumns: 'Valeur des colonnes',
			pivotMode: 'Mode pivot',
			groups: 'Groupes',
			values: 'Valeurs',
			pivots: 'Pivots',
			valueColumnsEmptyMessage: ' faites glisser les colonnes pour agréger',
			pivotColumnsEmptyMessage: ' faites glisser ici pour pivoter',
			toolPanelButton: 'outils',

			// other
			noRowsToShow: 'Pas de ligne',

			// enterprise menu
			pinColumn: 'Épinglé colonne',
			valueAggregation: 'Agrégation de valeur',
			autosizeThiscolumn: 'Ajuster cette colonne',
			autosizeAllColumns: 'Ajuster toutes les colonnes',
			groupBy: 'Regrouper par',
			ungroupBy: 'Dégrouper par',
			resetColumns: 'Réinitialiser les colonnes',
			expandAll: 'Développer tout',
			collapseAll: 'Tout réduire',
			toolPanel: 'Panneau d\'outils',
			export: 'Exporter',
			csvExport: ' Exporter en CSV',
			excelExport: 'Exporter en Excel',
			excelXmlExport: 'Exporter en XML',

			// enterprise menu pinning
			pinLeft: 'Épingler à gauche',
			pinRight: 'Épingler à droite',
			noPin: 'Ne pas épingler',

			// enterprise menu aggregation and status panel
			sum: 'Somme',
			min: 'Min',
			max: 'Max',
			none: 'Aucun',
			count: 'Compter',
			average: 'Moyenne',
			filteredRows: 'Filter',
			selectedRows: 'Sélectionner',
			totalRows: 'Total Lignes',
			totalAndFilteredRows: 'Total et Filtrer',

			// standard menu
			copy: 'Copier',
			copyWithHeaders: 'Copier avec en-tête',
			ctrlC: 'CTRL + C',
			paste: 'Coller',
			ctrlV: 'CTRL + V'
		}

	}, gridOptions) as T;
};

export default { french };
