import '@css/customElements/alert.scss';

import '@css/customElements/files-readonly.scss';

import S_Files from '@services/FileService';

class FilesReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-files-readonly';

	private defaultValue: string = '';

	public connectedCallback() {
		this.defaultValue = this.getAttribute('defaultvalue') || '';
		this.innerHTML = this.defaultValue;
	}

	public set value(data: { [key: string]: any }[]) {
		this.innerHTML = '';

		if (data.length) {
			for (const item of data) {
				const N_div = document.createElement('div');
				N_div.classList.add('item');
				N_div.innerHTML = item.name;

				N_div.addEventListener('click', () => {
					S_Files.getInstance().open(item._id, item.name);
				});

				this.append(N_div);
			}
		} else {
			this.innerHTML = this.defaultValue;
		}
	}

	public static register() {
		customElements.define(FilesReadonly.tagName, FilesReadonly);
	}
}

export default FilesReadonly;
