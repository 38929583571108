import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import Decimal from '@js/libs/utils/Decimal';

import '@css/cellRenderer/numericCellRenderer.scss';

class NumericCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('numericCellRenderer');
		this.eGui.innerHTML = '';
	}

	public init(params: any) {
		const decimalNumber = params.decimalNumber;

		const suffix = params.suffix || '';

		if (params.ignoreValueZero && !params.value) {
			this.eGui.innerHTML = '';
		} else {
			if (decimalNumber >= 0) {
				this.eGui.innerHTML = Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber(suffix, decimalNumber);
			} else {
				this.eGui.innerHTML = Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumberWithPrecision(suffix, 0);
			}
		}
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default NumericCellRenderer;
