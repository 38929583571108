import GenericService from '../GenericService';

class BrandService extends GenericService {
	private static instance: BrandService | null = null;

	constructor() {
		super('brands');
	}

	public static getInstance(): BrandService {
		if (!BrandService.instance) {
			BrandService.instance = new BrandService();
		}

		return BrandService.instance;
	}
}

export default BrandService;
