import Confirm, { ConfirmOptions } from './Confirm';

class Alert {
	private constructor() {

	}

	public static confirm(title: string, text?: string, options: ConfirmOptions = {}): Promise<void> {
		const modal = new Confirm(title, text, options);
		return modal.open();
	}
}

export default Alert;
