import Decimal from '@js/libs/utils/Decimal';

import CalculV1 from './CalculV1';
import CalculV2 from './CalculV2';
import CalculV3 from './CalculV3';

import QuoteData from '../QuoteData';
import QuoteOption from '../QuoteOptions';

type resCalculatePrice = {
	sumMat: number,
	sumMO: number,
	sum: number,
	price: number
};

type resCalculateGlobalPrice = {
	all: number,
	option: number,
	notOption: number
};

export type ICalcul = {
	calculatePrice(item: { [key: string]: any }, pref: { [key: string]: any }): resCalculatePrice
	calculateGlobalPrice(grid: any[], pref: { [key: string]: any }): resCalculateGlobalPrice
	humanizeNumber(number: string | number, pref: { [key: string]: any }, ignoreRound: boolean): string
	calculatePUGroup(data: any): number
	calculateSumGroup(data: any): number
};

class Calcul {
	private calcul: ICalcul;

	private quoteOption: QuoteOption;

	constructor() {
		this.quoteOption = QuoteOption.getInstance();

		switch (this.quoteOption.version) {
			case '1':
				this.calcul = CalculV1;
				break;
			case '2':
				this.calcul = CalculV2;
				break;
			default:
				this.calcul = CalculV3;
		}
	}

	public calculatePrice(item: { [key: string]: any }): resCalculatePrice {
		return this.calcul.calculatePrice(item, this.quoteOption.pref);
	}

	public calculateGlobalPrice(grid: any[]): resCalculateGlobalPrice {
		return this.calcul.calculateGlobalPrice(grid, this.quoteOption.pref);
	}

	public humanizeNumber(number: string | number, ignoreRound = false) {
		return this.calcul.humanizeNumber(number, this.quoteOption.pref, ignoreRound);
	}

	public calculatePUGroup(params: any): number {
		return this.calcul.calculatePUGroup(params);
	}

	public calculateSumGroup(params: any): number {
		return this.calcul.calculateSumGroup(params);
	}

	public calculateFooterDetails(params: any) {
		const rowPinned = {
			time: new Decimal(0),
			km: new Decimal(0),
			sumMaterials: new Decimal(0),
			price: new Decimal(0),
			marges: new Decimal(0)
		};

		const rows: { [key: string]: any }[] = [];

		params.api.forEachNode((node: any) => {
			rows.push(node.data);
		});

		for (const item of rows) {
			const quantity = Decimal.setDisplayNumber(item.quantity);

			const time = Decimal.setDisplayNumber(item.time).times(quantity);
			const price = Decimal.setDisplayNumber(item.price).times(quantity);
			const costPrice = Decimal.setDisplayNumber(item.costPrice).times(quantity);

			const data = this.calculatePrice(item);

			const sumMat = Decimal.setDisplayNumber(data.sumMat);

			let marge = new Decimal(0);

			if (item.reference) {
				marge = sumMat.minus(costPrice);
			} else {
				marge = sumMat.minus(price);
			}

			if (item.type === QuoteData.KILOMETRAGE_COST) {
				rowPinned.km = rowPinned.km.plus(time);
			} else {
				rowPinned.time = rowPinned.time.plus(time);
			}

			if (item.typeMat === QuoteData.LICENCE) {
				rowPinned.marges = rowPinned.marges.plus(price);
			} else if (item.typeMat === QuoteData.DISCOUNT) {
				rowPinned.marges = rowPinned.marges.minus(price);
			} else {
				rowPinned.price = rowPinned.price.plus(price);
				rowPinned.marges = rowPinned.marges.plus(marge);
				rowPinned.sumMaterials = rowPinned.sumMaterials.plus(sumMat);
			}
		}

		return rowPinned;
	}
}

export default Calcul;
