import CSV from '@autoprog/csv';

import Decimal from '@libs/utils/Decimal';
import ExportUtils from '@libs/utils/ExportUtils';

import CE_TVA from '@libs/customElement/TVA';

class UtilsBills {
	public static exportCompta(data: { [key: string]: any }[] = [], title: string) {
		const res: { [key: string]: any }[] = [];

		for (const item of data) {
			res.push({
				log: item.log,
				date: item.date,
				compta: item.compta,
				customer: item.customer,
				'bill.number': item.bill.number,
				'bill.object': item.bill.object,
				debit: ExportUtils.convertTo({ value: Decimal.setDisplayNumber(item.debit).toNumber() }, { type: 'Decimal' }),
				credit: ExportUtils.convertTo({ value: Decimal.setDisplayNumber(item.credit).toNumber() }, { type: 'Decimal' }),
				tva: CE_TVA.convertValueToCalc(item.tva).toNumber()
			});
		}

		const csvString = CSV.stringify(res, {
			log: 'Journal',
			date: 'Date de pièce',
			compta: 'Compte',
			customer: 'Libellé compte',
			'bill.number': 'N° de pièces (Facture)',
			'bill.object': 'Libellé Mouvement',
			debit: 'Débit',
			credit: 'Crédit',
			tva: 'CODE TVA'
		}, undefined, ';');

		const a = ExportUtils.createFileLink(csvString);
		if (title) {
			a.download = `Export-Comptable-${title}.csv`;
		} else {
			a.download = 'Export-Comptable.csv';
		}
		a.click();
		a.remove();
	}
}

export default UtilsBills;
