import { Controller } from '@autoprog/core-client';
import _upperFirst from 'lodash/upperFirst';
import h from 'hyperscript';

import SettingsRegister, { LinkItem } from '../libs/SettingsRegister';

import CE_CompanySelect from '@libs/customElement/CompanySelect';
import CE_SettingsCompanyListItem from '../customElements/SettingsCompanyListItem';

import '../../css/settings.scss';

import { Company } from '../types/company';

import M_AddEditCompanies from '../modals/AddEditCompanies';

import S_Companies from '@services/CompaniesService';
import SettingsController from '../libs/SettingsController';

class Settings extends Controller {
	private el: HTMLElement;

	private instanceTabs: SettingsController | null;

	private companiesData: Company[] = [];

	private convertCategoryToText: { [key: string]: string };

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;
		this.instanceTabs = null;
		this.convertCategoryToText = {};

		this.init();
	}

	private async loadCompaniesData() {
		this.companiesData = await S_Companies.getInstance().getAll() as Company[];
	}

	private async init() {
		await this.loadCompaniesData();
		this.initButtons();
		this.renderList();
	}

	private initButtons() {
		const N_add_company = this.el.querySelector<HTMLElement>('#add-company')!;

		N_add_company.addEventListener('click', () => {
			new M_AddEditCompanies().open().then((data) => {
				const settings = SettingsRegister.getInstance();
				const item = settings.getLinksByID('company-infos');
				if (item) {
					this.openCompany(item, data);
				}
			});
		});
	}

	private renderListItemLinks(category: string) {
		const settings = SettingsRegister.getInstance();
		const links = settings.linkMap.get(category);

		const N_container_links = this.el.querySelector<HTMLElement>(`#${category}-links`)!;

		if (links) {
			let pages = Array.from(links.values());

			pages = pages.sort((a, b) => a.order - b.order);

			for (const page of pages) {
				const icon = page.icon ? `<ap-icon name="${page.icon}"></ap-icon>` : '';

				const N_item = h('div.link', { attrs: { permission: page.permission || '' } }, { innerHTML: `${icon}${page.name}` });

				N_item.addEventListener('click', () => {
					this.resetActive();
					N_item.classList.add('active');
					this.renderLink(page);
				});

				N_container_links.append(N_item);
			}
		}
	}

	private async openCompany(item: LinkItem, company: Company) {
		await this.renderLink(item, company);
		await this.loadCompaniesData();
		this.renderCompanyLinks('company');
		this.reloadCompanySelect();
	}

	private renderCompanyLinks(category: string) {
		const settings = SettingsRegister.getInstance();
		const links = settings.linkMap.get(category);

		const N_container_links = this.el.querySelector<HTMLElement>(`#${category}-links`)!;
		N_container_links.innerHTML = '';

		for (const company of this.companiesData) {
			const N_item = h(CE_SettingsCompanyListItem.tagName) as CE_SettingsCompanyListItem;

			N_item.addEventListener('resetActive', (() => {
				this.resetActive();
			}) as EventListener);

			N_item.addEventListener('selectTabs', ((e: CustomEvent<LinkItem>) => {
				this.openCompany(e.detail, company);
			}) as EventListener);

			N_item.setTitle(company.name);

			N_item.addEventListener('click-title', () => {
				this.closeCompanies(N_item.id);
				this.resetActive();
				N_item.toggle();
			});

			if (links) {
				let pages = Array.from(links.values());
				pages = pages.sort((a, b) => a.order - b.order);
				N_item.setContent(pages);
			}

			N_container_links.append(N_item);
		}
	}

	private renderList() {
		const settings = SettingsRegister.getInstance();
		const N_list = this.el.querySelector<HTMLElement>('.list')!;

		this.convertCategoryToText.general = 'Général';
		this.convertCategoryToText.company = 'Sociétés';

		this.renderListItemLinks('general');
		this.renderCompanyLinks('company');

		const mapCatogeries = new Map([...settings.categoriesMap.entries()].sort((a, b) => a[1].order - b[1].order));

		for (const [key, value] of mapCatogeries.entries()) {
			if (!SettingsRegister.defaultCategories.includes(key)) {
				this.convertCategoryToText[value.id] = value.name || value.id;

				const N_title = h('div.title', value.name || value.id);
				const N_container_links = h(`div.links#${key}-links`);

				N_list.append(N_title, N_container_links);

				this.renderListItemLinks(key);
			}
		}
	}

	private renderLink(item: LinkItem, data?: object) {
		const N_container = this.el.querySelector<HTMLElement>('.content')!;
		const N_title = this.el.querySelector<HTMLElement>('.title-content')!;

		N_container.innerHTML = item.tpl;

		if (this.instanceTabs) {
			this.instanceTabs.destructor();
		}

		const category = this.convertCategoryToText[item.category!] || '';

		if (data) {
			N_title.innerHTML = `${_upperFirst(category)} > ${_upperFirst((data as any).name)} > ${_upperFirst(item.name)}`;
		} else {
			N_title.innerHTML = `${_upperFirst(category)} > ${_upperFirst(item.name)}`;
		}

		this.instanceTabs = new item.Controller(N_container, data);

		return this.instanceTabs.open();
	}

	private resetActive() {
		const N_active = this.el.querySelectorAll('.list .active');

		for (const N_el of N_active) {
			N_el.classList.remove('active');
		}
	}

	private closeCompanies(id?: string) {
		const N_container_links = this.el.querySelector<HTMLElement>('#company-links')!;
		const N_items = N_container_links.querySelectorAll<CE_SettingsCompanyListItem>(CE_SettingsCompanyListItem.tagName);

		for (const N_el of N_items) {
			if (id) {
				if (N_el.id !== id) {
					N_el.close();
				}
			} else {
				N_el.close();
			}
		}
	}

	private reloadCompanySelect() {
		const N_CompanySelect = document.querySelector<CE_CompanySelect>('#navbar ap-company-select')!;
		N_CompanySelect.reload();
	}

	public destructor() {
	}
}

export default Settings;
