//LIBS
import ConfigManager from '@libs/ConfigManager';

//MODALS
import M_MobileFilter from '@libs/modals/MobileFilter';

// STYLE
import '@css/modals/mobile-sort-filter.scss';
import _ from 'lodash';

class MobileFilter extends HTMLElement {
	public static readonly tagName: string = 'ap-mobile-filter';

	private static selectedFilters: { [key: string]: any } = {};

	private _data: any | null = null;

	private _update: () => void = () => { };

	private filterList: { [key: string]: any } | null = null;

	private config: any;

	public async connectedCallback() {
		this.innerHTML = `
            <button id="mobile-filter-btn" type="button" class="w-100 p-2 btn btn-white">
				<i class="icon icon-filter-line mr-2 text-grey-400"></i>
				<span>Filtrer</span>
			</button>
        `;

		this.initEvent();
	}

	private initEvent() {
		const N_filterButton = this.querySelector('#mobile-filter-btn') as HTMLButtonElement;
		N_filterButton.addEventListener('click', () => {
			if (this._data && this.filterList) {
				new M_MobileFilter(this.filterList, structuredClone(MobileFilter.selectedFilters), this._data, this.config).open().then((selectedFilters) => {
					MobileFilter.selectedFilters = selectedFilters;
					this._update();
				});
			}
		});
	}

	public init(tableName: string) {
		MobileFilter.selectedFilters = {};
		this.filterList = this.getFilterList(tableName);
	}

	private getFilterList(tableName: string) {
		const configManager = ConfigManager.getInstance();
		this.config = configManager.getConfig(tableName);

		const columnsToFilter: string[] = this.config.mobile.search || [];

		const columns: { key: string, type: string, name: string }[] = this.config.columns || [];
		const columnsData: { [key: string]: { type: string, name: string } } = {};

		for (const col of columns) {
			columnsData[col.key] = col;
		}

		const filterList: { [key: string]: any } = {};

		for (const col of columnsToFilter) {
			filterList[col] = columnsData[col];
		}

		return filterList;
	}

	public filterData() {
		let filteredData: any[] = [];

		if (Object.keys(MobileFilter.selectedFilters).length !== 0 && this.filterList) {
			for (const item of this._data) {
				for (const key in MobileFilter.selectedFilters) {
					const filterValue = MobileFilter.selectedFilters[key].value;

					const type = this.filterList[key].type;

					if (type === 'string') {
						const tmp = this.filterString(item, filterValue, key);
						if (!tmp) {
							break;
						}
					}

					if (type === 'date') {
						const tmp = this.filterDate(item, filterValue, key);
						if (!tmp) {
							break;
						}
					}

					if (type === 'boolean') {
						const tmp = this.filterBoolean(item, filterValue, key);
						if (!tmp) {
							break;
						}
					}

					if (type === 'array') {
						const tmp = this.filterSelect(item, filterValue, key);
						if (!tmp) {
							break;
						}
					}

					if (type === 'object') {
						if (this.filterList[key].filter === 'multiple') {
							const tmp = this.filterSelectMultiple(item, filterValue, key);
							if (!tmp) {
								break;
							}
						} else {
							const tmp = this.filterSelect(item, filterValue, key);
							if (!tmp) {
								break;
							}
						}
					}

					if (type === 'Decimal' || type === 'number') {
						const tmp = this.filterDecimalNumber(item, filterValue, type, key);
						if (!tmp) {
							break;
						}
					}

					if (type === 'table') {
						if (this.filterList[key].listFilter) {
							const tmp = this.filterSelectMultiple(item, filterValue, key);
							if (!tmp) {
								break;
							}
						} else {
							const tmp = this.filterString(item, filterValue, key);
							if (!tmp) {
								break;
							}
						}
					}

					filteredData.push(item);
				}
			}
		} else {
			filteredData = this._data;
		}

		return filteredData;
	}

	private filterString(item: { [key: string]: any }, filterValue: any, key: string): boolean {
		const filter = filterValue.toLowerCase() as string;
		const value = (_.get(item, key) || '').toLowerCase().trim() as string;
		return value.includes(filter);
	}

	private filterDate(item: { [key: string]: any }, filterValue: any, key: string): boolean {
		const filterStart = (new Date(filterValue.startDate as string)).getTime(); // exemple du format de startDate et endDate : "2022-12-31"
		const filterEnd = (new Date(filterValue.endDate as string)).getTime();
		const value = _.get(item, key) as number;
		return value > filterStart && value < filterEnd;
	}

	private filterBoolean(item: { [key: string]: any }, filterValue: any, key: string): boolean {
		const filter = filterValue as boolean;
		const value = _.get(item, key) as boolean;
		return value === filter;
	}

	private filterDecimalNumber(item: { [key: string]: any }, filterValue: any, type: any, key: string): boolean {
		const filter = filterValue as number;
		let value = 0;

		if (type === 'number') {
			value = _.get(item, key) as number;
		}

		if (type === 'decimal') {
			value = _.get(item, key).value as number;
		}

		return Number(value) === filter;
	}

	private filterSelectMultiple(item: { [key: string]: any }, filterValue: any, key: string): boolean {
		const filter = filterValue as string[];
		const value = _.get(item, key) as string;
		return filter.includes(value);
	}

	private filterSelect(item: { [key: string]: any }, filterValue: any, key: string): boolean {
		const filter = filterValue as string;
		const value = _.get(item, key) as string;
		return value === filter;
	}

	public setOnUpdate(cb: () => void) {
		this._update = cb;
	}

	public set data(data: any) {
		this._data = data;
	}

	public static register() {
		customElements.define(MobileFilter.tagName, MobileFilter);
	}
}

export default MobileFilter;
