import M_edit from '../modals/DeadlinePayment';

class DeadlinePayment extends HTMLElement {
	public static readonly tagName: string = 'ap-deadline-payment';

	private deadlinePayment = '';
	private typeDeadlinePayment = '';
	private fixedDateDeadline = 0;

	public async connectedCallback() {
		const nameDeadlinePayment = this.getAttribute('name-deadlinePayment');
		const nameTypeDeadlinePayment = this.getAttribute('name-typeDeadlinePayment');
		const nameFixedDateDeadline = this.getAttribute('name-fixedDateDeadline');
		const classText = this.getAttribute('class-text');

		this.innerHTML = `
			<div class="w-100 d-flex align-items-center">
				<div class="input-group flex-nowrap flex-column" style="max-width: calc(100% - 40px);">
					<div id="value" class="${classText}"></div>	
				</div>

				<div id="custom-buttons-container">
					<button class="btn btn-white" type="button" id="edit">
						<ap-icon name="pencil/fill"></ap-icon>
					</button>
				</div>
			</div>

            <div class="d-none">
                <input type="text" name="${nameDeadlinePayment}" class="form-control" id="deadlinePayment">
                <select name="${nameTypeDeadlinePayment}" class="form-control" id="typeDeadlinePayment">
                    <option value="1">Fin de mois</option>
                    <option value="2">Date facture</option>
                    <option value="3">À réception de facture</option>
                </select>
				<input type="number" name="${nameFixedDateDeadline}" class="form-control" id="fixedDateDeadline">
            </div>
        `;

		this.classList.add('w-100');

		const N_deadlinePayment = this.querySelector('#deadlinePayment') as HTMLInputElement;
		const N_typeDeadlinePayment = this.querySelector('#typeDeadlinePayment') as HTMLSelectElement;
		const N_fixedDateDeadline = this.querySelector('#fixedDateDeadline') as HTMLInputElement;

		const instance = this;

		Object.defineProperty(N_deadlinePayment, 'value', {
			set: function (value) {
				instance.deadlinePayment = value;
				this.setAttribute('value', value);
				instance.updateValue();
			},
			get: function () {
				return this.getAttribute('value');
			}
		});

		N_deadlinePayment.addEventListener('change', () => {
			this.deadlinePayment = N_deadlinePayment.value;
			this.updateValue();
		});

		Object.defineProperty(N_typeDeadlinePayment, 'value', {
			set: function (value) {
				instance.typeDeadlinePayment = value;

				for (const option of this.options) {
					option.selected = (value === option.value);
				}
				this.setAttribute('value', value);

				instance.updateValue();
			},
			get: function () {
				return this.getAttribute('value');
			}
		});

		N_typeDeadlinePayment.addEventListener('change', () => {
			this.typeDeadlinePayment = N_typeDeadlinePayment.value;
			this.updateValue();
		});

		Object.defineProperty(N_fixedDateDeadline, 'valueAsNumber', {
			set: function (value) {
				instance.fixedDateDeadline = value;
				this.setAttribute('value', value);
				instance.updateValue();
			},
			get: function () {
				return Number(this.getAttribute('value'));
			}
		});

		N_fixedDateDeadline.addEventListener('change', () => {
			this.fixedDateDeadline = Number(N_fixedDateDeadline.value);
			this.updateValue();
		});

		const N_edit = this.querySelector('#edit') as HTMLElement;

		if (this.getAttribute('readonly') !== null) {
			N_edit.classList.add('d-none');
		}

		N_edit.addEventListener('click', async () => {
			const data = await new M_edit({
				typeDeadlinePayment: this.typeDeadlinePayment,
				deadlinePayment: this.deadlinePayment,
				fixedDateDeadline: this.fixedDateDeadline
			}).open();

			N_typeDeadlinePayment.value = data.typeDeadlinePayment;
			N_deadlinePayment.value = data.deadlinePayment;
			N_fixedDateDeadline.valueAsNumber = data.fixedDateDeadline;

			this.dispatchEvent(new Event('change', { bubbles: true }));
		});
	}

	private updateValue() {
		const N_value = this.querySelector('#value') as HTMLElement;

		const type: { [key: string]: string } = {
			1: 'fin de mois',
			2: 'date facture',
			3: 'à réception de facture'
		};

		const suffix = (this.typeDeadlinePayment === '1' && this.fixedDateDeadline !== 0) ? `le ${this.fixedDateDeadline}` : '';

		if (type[this.typeDeadlinePayment]) {
			N_value.innerHTML = `${this.deadlinePayment} jour${Number(this.deadlinePayment) > 1 ? 's' : ''} ${type[this.typeDeadlinePayment]} ${suffix}`;
		} else {
			N_value.innerHTML = 'Aucun délai de paiement';
		}
	}

	public static register() {
		customElements.define(DeadlinePayment.tagName, DeadlinePayment);
	}
}

export default DeadlinePayment;
