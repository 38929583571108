import { taxe, taxeType } from '../types/taxe';

import DecimalModel from '@libs/model/_app/Decimal';
import DisplayRefModel from '@libs/model/_app/DisplayRef';
import ObjectModel from '@libs/model/_app/Object';
import PrimaryKeyModel from '@libs/model/_app/PrimaryKey';
import StringModel from '@libs/model/_app/String';

class TaxeModel {
	private data: taxe;

	private static cache: { [key: string]: any } = {};

	private static type = {
		purchase: 'Achat',
		sale: 'Vente'
	};

	constructor(data: taxe) {
		this.data = data;
	}

	public static setCache(table: string, values: { [key: string]: any }) {
		TaxeModel.cache[table] = values;
	}

	private getCache(table: string) {
		return TaxeModel.cache[table] || {};
	}

	private convertRowData() {
		return {
			_id: new PrimaryKeyModel(this.data._id),
			code: new StringModel(this.data.code),
			label: new StringModel(this.data.label),
			rate: new DecimalModel(this.data.rate, 2),
			type: new ObjectModel(this.data.type, TaxeModel.type),
			accountingAccount: new DisplayRefModel(this.data.accountingAccount, this.getCache('accountingAccount')),
			specialMention: new StringModel(this.data.specialMention),
			_rowData_: this.data
		};
	}

	public toDashboard() {
		const data = this.convertRowData();

		return {
			_id: data._id.toDashboard(),
			code: data.code.toDashboard(),
			label: data.label.toDashboard(),
			rate: data.rate.toDashboard(),
			type: data.type.toDashboard(),
			accountingAccount: data.accountingAccount.toDashboard(),
			specialMention: data.specialMention.toDashboard(),
			_rowData_: this.data
		};
	}

	public toDocument() {
		const data = this.convertRowData();

		return {
			_id: data._id.toDocument(),
			code: data.code.toDocument(),
			label: data.label.toDocument(),
			rate: data.rate.toDocument(),
			type: data.type.toDocument(),
			accountingAccount: data.accountingAccount.toDocument(),
			specialMention: data.specialMention.toDocument()
		};
	}

	public toSave(): taxe {
		const data = this.convertRowData();

		return {
			_id: data._id.toSave(),
			code: data.code.toSave(),
			label: data.label.toSave(),
			rate: data.rate.toSave(),
			type: data.type.toSave() as taxeType,
			accountingAccount: data.accountingAccount.toSave(),
			specialMention: data.specialMention.toSave()
		};
	}

	public toSelect() {
		const data = this.convertRowData();

		return {
			id: data._id.getValue(),
			text: this.displayRef()
		};
	}

	public displayRef() {
		const data = this.convertRowData();
		return `${data.rate.toDocument()}% ${data.label.toDocument()}`;
	}

	public displayAggrid() {
		const data = this.convertRowData();
		return data.rate.toDocument() + '%';
	}
}

export default TaxeModel;
