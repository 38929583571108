// CORE
import agUtils from '@libs/agGrid/french';
import { global } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// LIBS
import Decimal from '@libs/utils/Decimal';
import Select2Utils from '@libs/utils/select2Utils';
import SelectEditor from '@libs/agGrid/SelectEditor';

import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

// MODAL
import M_mobile from '../../modals/receipt/ContentEdit';

// SERVICE
import S_Products from '@services/Product/ProductService';

class ContentReceiptTab extends HTMLElement {
	public static readonly tagName: string = 'ap-receipt-content-tab';

	private _gridOptions: GridOptions = {};

	private selectorTab: string = '';
	private idTab: string = '';
	private N_el: HTMLElement | null = null;

	private readonly: boolean = false;
	private onlyGrid: boolean = false;

	private allDataToMobile: any[] = [];

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || ContentReceiptTab.tagName;

		this.readonly = this.getAttribute('readonly') !== null;

		this.onlyGrid = this.getAttribute('type') === 'grid';

		if (!this.onlyGrid) {
			this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="database-2/line" icon-active="database-2/fill" text="Suivi entrée de stock"></ap-page-tabs-menu-item>`;

			const N_item = this.querySelector('ap-page-tabs-menu-item');

			N_item?.addEventListener('open', () => {
				this._gridOptions.api?.sizeColumnsToFit();
			});
		}

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	public active() {
		const N_tabs = this.querySelector('.page-tabs-container');
		N_tabs?.classList.add('active');

		this._gridOptions.api?.sizeColumnsToFit();
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		this.N_el = document.createElement('div');

		this.N_el.classList.add('page-tabs-container', 'tab-pane');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Suivi entrée de stock
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>

			<div class="page-tabs-content">
				<div id="grid" class="ag-theme-alpine h-100 w-100"></div>
			</div>
        `;

		if (!this.onlyGrid) {
			N_container.append(this.N_el);
		} else {
			this.append(this.N_el);
		}
	}

	private async initGrid(data: any) {
		this._gridOptions = agUtils.french<GridOptions>({
			rowData: data.products,
			columnDefs: [{
				headerName: S_Products.getInstance().columnNameReference,
				field: 'reference',
				width: 200,
				suppressSizeToFit: true,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: Select2Utils.getOptions('products', this)
				},
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						return '';
					} else {
						const cellRenderer = new ProductCellRenderer();
						cellRenderer.init(params);
						return cellRenderer.getGui();
					}
				}
			}, {
				headerName: 'Libellé',
				field: 'label',
				editable: false
			}, {
				headerName: 'Qté Cmdé',
				field: 'quantity',
				cellClass: 'text-right',
				width: 100,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params: any) => {
					if (params.node.rowPinned) {
						let quantity = 0;

						params.api.forEachNode((node: any) => {
							quantity += Number(_.get(node.data, 'quantity') || 0);
						});

						return quantity.toString();
					} else {
						return params.value;
					}
				}
			}, {
				headerName: 'Stock',
				children: await this.getStockLocation(data.locations)
			}, {
				headerName: 'Total',
				field: 'total',
				cellClass: 'text-right',
				width: 100,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						let quantity = 0;

						params.api.forEachNode((node: any) => {
							for (const id in (node.data.stocks || {})) {
								quantity += Number(node.data.stocks[id].quantity || 0);
							}
							quantity += Number(node.data.receiptToCustomer?.quantity || 0);
						});

						return quantity.toString();
					} else {
						let quantity = 0;

						for (const id in (params.data.stocks || {})) {
							quantity += Number(params.data.stocks[id].quantity || 0);
						}

						quantity += Number(params.data.receiptToCustomer?.quantity || 0);

						return quantity.toString();
					}
				}
			}],
			singleClickEdit: global.IS_ELECTRON,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				editable: (params) => {
					return !params.node.rowPinned && !this.readonly;
				}
			},
			onCellEditingStopped: (params) => {
				if (params.column.getColId() === 'reference') {
					S_Products.getInstance().getById(params.data.reference).then((data) => {
						params.node.setDataValue('label', data.label);
					});
					params.api.refreshCells({ force: true, rowNodes: [params.node] });
				} else {
					const field = params.colDef.field || '';
					const quantity = new Decimal(params.data.quantity);

					_.set(params.data, field, params.newValue);

					if (field.includes('stocks.') || field.includes('receiptToCustomer')) {
						let newQuantity = new Decimal(0);

						for (const id in params.data.stocks) {
							newQuantity = newQuantity.plus(Decimal.setDisplayNumber(params.data.stocks[id].quantity));
						}

						newQuantity = newQuantity.plus(Decimal.setDisplayNumber(params.data.receiptToCustomer?.quantity));

						if (newQuantity.greaterThan(quantity)) {
							params.newValue = quantity.minus(newQuantity.minus(Decimal.setDisplayNumber(params.newValue))).toNumber();
						}
					}

					_.set(params.data, field, params.newValue);

					params.node.setData(params.data);

					params.api.setPinnedBottomRowData([{}]);
					params.api.refreshCells({ force: true, rowNodes: [params.node], columns: ['total'] });
				}
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
				params.api.setPinnedBottomRowData([{}]);
			}
		});

		const N_grid = this.N_el!.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	private async getStockLocation(locations: any[]) {
		const result: any[] = [];

		for (const item of locations) {
			result.push({
				headerName: item.name,
				field: `stocks.${item._id}.quantity`,
				cellClass: 'text-right',
				width: 100,
				suppressSizeToFit: true,
				cellRenderer: (params: any) => {
					if (params.node.rowPinned) {
						let quantity = 0;

						params.api.forEachNode((node: any) => {
							quantity += Number(_.get(node.data, `stocks.${item._id}.quantity`) || 0);
						});

						return quantity.toString();
					} else {
						return params.value + (!this.readonly ? '<i class="icon icon-edit ml-2 text-light-blue-600"></i>' : '');
					}
				}
			});
		}

		result.push({
			headerName: 'Réception client',
			field: 'receiptToCustomer.quantity',
			width: 120,
			cellClass: 'text-right',
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				if (params.node.rowPinned) {
					let quantity = 0;

					params.api.forEachNode((node: any) => {
						quantity += Number(node.data.receiptToCustomer?.quantity || 0);
					});

					return quantity.toString();
				} else {
					return params.value + (!this.readonly ? '<i class="icon icon-edit ml-2 text-light-blue-600"></i>' : '');
				}
			}
		});

		return result;
	}

	private async initMobile(data: any) {
		const container = this.N_el!.querySelector('.grid') as HTMLDivElement;

		container.innerHTML = '';

		let index = 0;
		for (const item of data.products) {
			container.appendChild(await this.templateInitMobile(item, data.locations, index++));
		}
	}

	private async templateInitMobile(item: { [key: string]: any }, locations: any, index: number) {
		const N_div = document.createElement('div');
		N_div.classList.add('card-mobile', 'flex-column');

		const product = await S_Products.getInstance().getById(item.reference);

		item.label = product.label;
		this.allDataToMobile[index] = item;

		let total = 0;

		item.stocks = item.stocks || {};

		let htmlStock = '';
		for (const location of locations) {
			const stock = item.stocks[location._id] || {};
			htmlStock += `<div class="pl-2"> - ${location.name} : ${stock.quantity || 0}</div>`;
			total += Number(stock.quantity || 0);
		}

		N_div.innerHTML = `
			<div class="font-weight-bold flex-grow-1 w-100 text-truncate">
				${product[S_Products.getInstance().referenceKey]} ${item.label}
			</div>
			<div>
				Qté cmdé : ${item.quantity || '0'}
			</div>
			<div>
				Quantité Stock : (${total})
			</div>
			${htmlStock}
		`;

		N_div.addEventListener('click', () => {
			new M_mobile(item, locations).open().then(async (newData) => {
				N_div.parentNode?.insertBefore(await this.templateInitMobile(newData, locations, index), N_div);

				N_div.remove();
			});
		});

		return N_div;
	}

	public set data(data: any) {
		if (global.IS_MOBILE) {
			this.initMobile(data);
		} else {
			if (this._gridOptions.api) {
				this._gridOptions.api?.setRowData(data.products || []);
				this._gridOptions.api?.setPinnedBottomRowData([{}]);
			} else {
				this.initGrid(data);
			}
		}
	}

	public get data(): any {
		if (global.IS_MOBILE) {
			return this.allDataToMobile;
		} else {
			const results: any[] = [];

			this._gridOptions.api?.stopEditing();

			this._gridOptions.api?.forEachNode((node) => {
				results.push(node.data);
			});

			return results;
		}
	}

	public get gridOptions() {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(ContentReceiptTab.tagName, ContentReceiptTab);
	}
}

export default ContentReceiptTab;
