import { Form, toaster } from '@autoprog/core-client';

import CE_Select from '@libs/customElement/Select2';
import Modal from '@libs/Modal';

import S_Quote from '@services/QuoteService';

import T_Modal from '../../../tpl/modals/AddQuote/NotifNotFinish.html';

class NotifNotFinish extends Modal {
	constructor(id: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const data = await S_Quote.getInstance().getById(id);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_close = this.element.querySelector('#close') as HTMLButtonElement;
			const N_disableDisplay = this.element.querySelector('#disableDisplay') as HTMLInputElement;

			const N_users = this.element.querySelector('[name="users"]') as CE_Select;

			N_users.create(this.element, { multiple: true });

			const form = new Form(this.element.querySelector('form') as HTMLFormElement);

			N_users.postInit();

			N_save.addEventListener('click', async () => {
				const formData = form.getData() as any;

				await S_Quote.getInstance().sendNotificationNotFinish(data._id, formData.users);

				toaster.success('Notification envoyée');

				this.resolve({ reopen: N_disableDisplay.checked });
			});

			N_close.addEventListener('click', async () => {
				this.resolve({ reopen: N_disableDisplay.checked });
			});
		});
	}
}

export default NotifNotFinish;
