import { Module, Modules, Router, SocketIO } from '@autoprog/core-client';

import C_Settings from './js/controllers/Settings';
import T_Settings from './tpl/settings.html';

import C_SettingsGeneral from './js/controllers/Settings.General';
import T_SettingsGeneral from './tpl/settings.General.html';

import C_SettingsCompanies from './js/controllers/Settings.Companies';
import T_SettingsCompanies from './tpl/settings.Companies.html';

import SettingsRegister from './js/libs/SettingsRegister';

import CE_SettingsCompanyListItem from './js/customElements/SettingsCompanyListItem';

import Navigation from '@libs/Navigation';
import Settings from './js/libs/Settings';
import Utils from '@js/libs/utils/Utils';

class SettingsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_SettingsCompanyListItem.register();
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Paramétrage',
			order: 1000
		});
		navigation.addLink({
			order: 0,
			category: 'Paramétrage',
			name: 'Paramètres',
			type: 'module',
			module: 'settings',
			permissions: ['MAIN_SETTINGS'],
			icon: 'settings-4/line'
		});

		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addCategory({
			id: 'general',
			order: 0
		});

		settingsRegister.addCategory({
			id: 'company',
			order: 100
		});

		settingsRegister.addLink({
			id: 'apps',
			Controller: C_SettingsGeneral,
			tpl: T_SettingsGeneral,
			icon: 'draft/line',
			order: 0,
			category: 'general',
			name: 'Application'
		});

		settingsRegister.addLink({
			id: 'company-infos',
			Controller: C_SettingsCompanies,
			tpl: T_SettingsCompanies,
			order: 0,
			category: 'company',
			name: 'Infos'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/settings',
			controller: C_Settings as any,
			tpl: T_Settings,
			requireValidUser: true
		});

		Settings.getInstance().loadByCompany(Utils.companyID);

		SocketIO.getInstance().on('settings.company.*.save', (companyID: string) => {
			if (Utils.companyID === companyID) {
				Settings.getInstance().loadByCompany(companyID);
			}
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Paramètres', SettingsModule);
