import { ElementForm } from '..';

export default (N_input: ElementForm) => {
	if (N_input instanceof HTMLTextAreaElement) {
		return N_input.value;
	}

	if (N_input instanceof HTMLInputElement) {
		switch (N_input.type) {
			case 'checkbox':
				return N_input.checked;

			case 'number':
			case 'range':
				return N_input.valueAsNumber;

			case 'date':
			case 'month':
			case 'time':
			case 'week':
			case 'datetime-local':
				return N_input.valueAsDate;

			default:
				return N_input.value;
		}
	}

	if (N_input instanceof HTMLSelectElement) {
		return N_input.multiple ? Array.from(N_input.selectedOptions).map(o => o.value) : N_input.value;
	}

	if ('value' in N_input) {
		return (N_input as any).value;
	}
};
