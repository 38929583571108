import { Form, global } from '@autoprog/core-client';

import moment from 'moment';

import T_Modal from '../../../tpl/modals/QuotesToDo/Informations.html';

import Loader from '@libs/Loader';
import Modal from '@libs/Modal';
import Select2Utils from '@libs/utils/select2Utils';
import Utils from '@libs/utils/Utils';

import S_C_Contact from '@services/Customer/CustomerContactService';
import S_ToDoQuote from '@services/Quotes/ToDoQuoteService';

import FilesTab from '@libs/customElement/FilesTab';
import NotesTab from '@libs/customElement/NotesTab';

import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@js/libs/customElement/Sites-FinalCustomer';
import CE_Traceability from '@libs/customElement/Traceability';

class Information extends Modal {
	private idData: string = '';
	private toDoQuoteService: S_ToDoQuote;

	private N_NotesTab: NotesTab | null = null;
	private N_FilesTab: FilesTab | null = null;

	private form: Form | null = null;

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	constructor(id: string = '', type = 'normal') {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.idData = id;
		this.toDoQuoteService = S_ToDoQuote.getInstance();

		this.on('opened', async () => {
			Loader.getInstance().open();

			this.initInfos();
			this.initFiles();
			this.initNotes();

			const N_form = this.element.querySelector('.modal-body') as HTMLFormElement;

			const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLElement;
			N_modalDialog.style.maxWidth = global.IS_MOBILE ? '' : '80%';

			this.form = new Form(N_form);

			const data = await this.getData();

			this.form.setData(data);

			this.N_NotesTab!.data = data.notes || [];
			this.N_FilesTab!.data = data.attachments || {};

			this.N_NotesTab!.postInit();
			this.N_FilesTab!.postInit();

			const N_traceability = this.element.querySelector('ap-traceability') as CE_Traceability;
			N_traceability.update('quotes-to-do', this.idData);

			this.postInitInfos();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				await N_save.loading(this.save());
				this.resolve();
			});

			const N_closeNotification = this.element.querySelector('#close_notification') as HTMLButtonElement;

			if (type === 'notification') {
				N_closeNotification.classList.remove('d-none');
			}

			N_closeNotification.addEventListener('click', () => {
				this.resolve('notification');
			});

			Loader.getInstance().close();
		});
	}

	private async getData() {
		const { data } = await this.toDoQuoteService.getDataToModal(this.idData);

		return data.data;
	}

	private initInfos() {
		const N_sites = this.element.querySelector('[name="sites"]') as CE_Select2;

		const N_customer = this.element.querySelector('[name="customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="contact"]') as CE_Select2;

		const N_concernedUsers = this.element.querySelector('[name="concernedUsers"]') as HTMLSelectElement;

		N_customer.create(this.element);

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		N_sites.setRef({ customer: N_customer.selectElement! });
		N_sites.create(this.element, { multiple: true });

		const usersSelect2 = new Select2Utils('users', N_concernedUsers);
		usersSelect2.create(this.element, {
			multiple: true
		});

		this.selectPostinit.sites = N_sites;
		this.selectPostinit.customer = N_customer;
		this.selectPostinit.contact = N_contact;
	}

	private postInitInfos() {
		this.selectPostinit.customer.on('change', async (customer) => {
			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				this.form?.setDataByName('contact', contacts[0]);
			} else {
				this.form?.setDataByName('contact', { id: '', text: '' });
			}
		});

		const N_sites = this.element.querySelector('ap-sites-final-customer') as CE_SitesFinalCustomer;

		N_sites.setFinalCustomer();

		this.selectPostinit.sites.postInit();
		this.selectPostinit.customer.postInit();
		this.selectPostinit.contact.postInit();
	}

	private async initNotes() {
		this.N_NotesTab = this.element.querySelector(NotesTab.tagName) as NotesTab;

		this.N_NotesTab.setParentElement(this.element);

		this.N_NotesTab.setCallback(() => {
			this.save();
		});
	}

	private initFiles() {
		this.N_FilesTab = this.element.querySelector(FilesTab.tagName) as FilesTab;

		this.N_FilesTab.setParentElement(this.element);

		this.N_FilesTab.setCallback(() => {
			this.save();
		});
	}

	private async save() {
		if (this.form) {
			const formData = this.form.getData() as { [key: string]: any };

			const createDate = moment(formData.createDate);
			formData.createDate = createDate.isValid() ? createDate.format('YYYY-MM-DD') : '';

			const limitDate = moment(formData.limitDate);
			formData.limitDate = limitDate.isValid() ? limitDate.format('YYYY-MM-DD') : '';

			const newData: { [key: string]: any } = {
				_id: this.idData,
				...formData,
				notes: []
			};

			newData.attachments = this.N_FilesTab!.data;
			newData.notes = this.N_NotesTab!.data;

			newData.companyID = Utils.companyID;

			const res = await this.toDoQuoteService.save(newData);
			this.idData = res.data.data._id;
		}
	}
}

export default Information;
