import { global } from '@autoprog/core-client';

import _ from 'lodash';

class Title {
	private static _instance: Title;

	private _override = '';
	private _notif = 0;
	private _orign = '';
	private _suffix = '';

	private constructor() {
		this._orign = global.WINDOW_TITLE || 'Apps';

		if (window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost')) {
			this._orign += ' - PC';
		}

		if (window.location.href.includes('192.168.5.15')) {
			this._orign += ' - VM Biogaz';
		}

		if (window.location.href.includes('192.168.5.19')) {
			this._orign += ' - VM Autoprog';
		}

		this.updateTitle();
	}

	public static getInstance() {
		if (!Title._instance) {
			Title._instance = new Title();
		}

		return Title._instance;
	}

	public updateNotif(number: number) {
		this._notif = number;
		this.updateTitle();
	}

	public updateOverride(text: string) {
		this._override = text;

		this.updateTitle();
	}

	public updateSuffix(text: string) {
		this._suffix = text;

		this.updateTitle();
	}

	private updateTitle() {
		if (this._override) {
			global.WINDOW_TITLE = this._override;
		} else if (this._notif) {
			global.WINDOW_TITLE = `(${this._notif}) ${_.compact([this._orign, this._suffix]).join(' - ')}`;
		} else {
			global.WINDOW_TITLE = _.compact([this._orign, this._suffix]).join(' - ');
		}
	}
}

export default Title;
