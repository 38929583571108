import { Controller, utils } from '@autoprog/core-client';

import Calcul from '../libs/Calcul';
import QuoteOptions from '../libs/QuoteOptions';

import Decimal from '@libs/utils/Decimal';

import S_Products from '@services/Product/ProductService';
import S_Quotes from '@services/QuoteService';

import '../../css/mobile.quotes.scss';
import { SettingQuoteOption } from './Settings.Quote';

class ViewQuote extends Controller {
	private id = '';
	private el: HTMLElement;

	private calcul: Calcul;
	private quoteOptions: QuoteOptions;

	private data: { [key: string]: any } = {};

	constructor(el: HTMLElement) {
		super(el);

		const params = utils.getQuery();

		this.id = params.id;
		this.el = el;

		this.quoteOptions = QuoteOptions.newInstance();
		this.calcul = new Calcul();

		this.init();
	}

	private async getData() {
		const { data, title } = (await S_Quotes.getInstance().getDataToModal(this.id)).data;

		this.data = data;

		this.quoteOptions.infos = { state: '0' };
		this.quoteOptions.pref = data.pref || {};
		this.quoteOptions.quoteOptions = data.options as SettingQuoteOption;

		this.initTitle(title);
	}

	private initTitle(title: string) {
		const N_title = this.el.querySelector('#title-addon') as HTMLElement;

		N_title.innerHTML = title;
	}

	private async init() {
		await this.getData();

		const N_mobileQuotesContainer = this.el.querySelector('#mobile-quotes-container') as HTMLDivElement;

		for (const item of this.data.data) {
			const N_quoteGroup = document.createElement('div') as HTMLDivElement;
			N_quoteGroup.classList.add('mobile-quote-group');

			const N_quoteGroupHeader = document.createElement('div') as HTMLDivElement;
			N_quoteGroupHeader.classList.add('mobile-quote-group-header');

			const N_quoteGroupContent = document.createElement('div') as HTMLDivElement;
			N_quoteGroupContent.classList.add('mobile-quote-group-content');

			N_quoteGroupHeader.addEventListener('click', () => {
				this.toggleAccordion(N_quoteGroup);
			});

			N_quoteGroup.append(N_quoteGroupHeader);
			N_quoteGroup.append(N_quoteGroupContent);

			let totalPrice = 0;

			for (const detail of item.details) {
				const N_quoteGroupDetail = document.createElement('div') as HTMLDivElement;
				N_quoteGroupDetail.classList.add('mobile-quote-group-detail');

				const price = this.calcul.calculatePrice(detail).price;
				const formattedPrice = Decimal.setDisplayNumber(price).setSuffixAndHumanizeNumber('€', -1);

				totalPrice += price;

				const productData = await S_Products.getInstance().getById(detail.reference);

				const productCode = productData ? productData.internalCode : '';

				N_quoteGroupDetail.innerHTML = `
					<div class="mobile-quote-group-code">${productCode}</div>

					<div class="w-100 d-flex align-items-center mb-1">
						<div class="mobile-quote-group-price mr-auto">${formattedPrice}</div>
	
						<div class="d-flex">
							<div class="mobile-quote-group-quantity-label mr-2">Quantité :</div>
							<div class="mobile-quote-group-quantity-value">${detail.quantity}</div>
						</div>
					</div>

					<div class="mobile-quote-group-label">${detail.label}</div>
				`;

				N_quoteGroupContent.append(N_quoteGroupDetail);
			}

			const formattedTotalPrice = Decimal.setDisplayNumber(totalPrice).setSuffixAndHumanizeNumber('€', -1);

			N_quoteGroupHeader.innerHTML = `
				<div class="w-100 d-flex mb-2 align-items-end no-scroll">
					<div class="mobile-quote-group-category mr-auto">${item.category}</div>
					<div class="mobile-quote-group-subcategory">${item.subCategory}</div>
				</div>

				<div class="w-100 d-flex align-items-center mb-2">
					<div class="mobile-quote-group-price mr-auto">${formattedTotalPrice}</div>

					<div class="d-flex">
						<div class="mobile-quote-group-quantity-label mr-2">Quantité :</div>
						<div class="mobile-quote-group-quantity-value">${item.quantity}</div>
					</div>
				</div>

				<div class="w-100 mb-2">
					<div class="mobile-quote-group-name">${item.name}</div>
				</div>
				
				<div class="mobile-quote-accordion-icon-container"><i class="icon icon-solid-angle-down"></i></div>
			`;

			N_mobileQuotesContainer.append(N_quoteGroup);
		}
	}

	private toggleAccordion(N_quoteGroup: HTMLDivElement) {
		N_quoteGroup.classList.toggle('opened');

		const N_accordionIconContainer = N_quoteGroup.querySelector('.mobile-quote-accordion-icon-container') as HTMLDivElement;

		if (N_quoteGroup.classList.contains('opened')) {
			N_accordionIconContainer.innerHTML = '<i class="icon icon-solid-angle-up"></i>';
		} else {
			N_accordionIconContainer.innerHTML = '<i class="icon icon-solid-angle-down"></i>';
		}
	}

	public destructor() {

	}
}

export default ViewQuote;
