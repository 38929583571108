import GenericService from './GenericService';

import { global } from '@autoprog/core-client';

class FileService extends GenericService {
	private static instance: FileService | null = null;

	constructor() {
		super('files');
	}

	public static getInstance(): FileService {
		if (!FileService.instance) {
			FileService.instance = new FileService();
		}

		return FileService.instance;
	}

	public open(id: string, name: string) {
		window.open(`${global.FILEDB_URL}/${global.COUCHDB_PREFIX}files/${id}/${name}`, '_blank');
	}
}

export default FileService;
