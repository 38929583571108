module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div class="d-flex">\n            <div class="font-weight-bold text-blue-grey-900">\n                ' +
((__t = (data.infos.number || '&nbsp;')) == null ? '' : __t) +
'\n            </div>\n            <div class="ml-auto">\n                ';
let state = {"0": "Garantie","1": "À facturer","2": "Facturé","3": "Commande","4": "Autre"};
__p += '\n                ' +
((__t = (state[data.infos.state] || '&nbsp;')) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="d-flex mt-2">\n            <div>\n                ' +
((__t = (data.infos.site || '')) == null ? '' : __t) +
' ' +
((__t = (data.infos.customer || '&nbsp;')) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="mt-2">\n            ' +
((__t = (data.users || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n</div>';

}
return __p
}