import EventEmitter from '@autoprog/eventemitter';

export type IStepContent = {
	new(el: HTMLElement): StepContent
};

abstract class StepContent extends EventEmitter {
	protected el: HTMLElement;

	protected stepsInstances: StepContent[] = [];

	private active: boolean = false;
	private _isEdit: boolean = false;

	constructor(el: HTMLElement) {
		super();
		this.el = el;
	}

	public async init() {

	}

	public getTitle() {
		return '';
	}

	public getContent() {
		return '';
	}

	public getData() {
		return {};
	}

	public setActive() {
		for (const stepInstance of this.stepsInstances) {
			stepInstance.setInactive();
		}

		this.active = true;
	}

	public setInactive() {
		this.active = false;
	}

	public async openStep() {
	}

	public setInstanceOtherStep(stepsInstance: StepContent[]) {
		this.stepsInstances = stepsInstance;
	}

	abstract setData (data: { [key: string]: any }): void;

	public isActive(): boolean {
		return this.active;
	}

	public checkValidity(): boolean {
		return true;
	}

	public get isEdit() {
		return this._isEdit;
	}

	public set isEdit(value: boolean) {
		this._isEdit = value;
	}
}

export default StepContent;
