// CORE
import { Router } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

//STYLE
import '../../../../css/quickAccess.scss';

class Quotes extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `

			<div class="d-flex align-items-center text-nowrap quickaccess-card">
				<div class="p-3 w-100">
					<div class="d-flex w-100">
						<div class="d-flex align-items-center">
							<i class="quickaccess-icon quotes-icon d-flex align-items-center justify-content-center icon icon-solid-file h2 px-3 py-2"
							style="height: 56px; width: 56px"></i>
						</div>
						<div class="pl-3 w-100">
							<div class="d-flex font-weight-bold text-grey-700 h6">Devis</div>
							<div style="font-size: 12px">
								<div class="d-flex w-100">
									<div>En cours</div>
									<div class="ml-auto font-weight-bold" data-key="quotes.inProgress"></div>
								</div>
								<div class="d-flex w-100">
									<div>Total</div>
									<div class="ml-auto font-weight-bold" data-key="quotes.number"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="quickaccess-link-icon">
					<i class="icon icon-solid-external-link-alt"></i>
				<div>
			</div>
        `;
	}

	private linkData() {
		const N_quotes_toLate = this.querySelector('[data-key="quotes.inProgress"]');
		const N_quotes_waiting = this.querySelector('[data-key="quotes.number"]');

		N_quotes_toLate!.innerHTML = this._data.quotes.inProgress;
		N_quotes_waiting!.innerHTML = this._data.quotes.number;
	}

	private initButton() {
		const router = Router.getInstance();

		const N_quotes = this.querySelector('.quickaccess-card') as HTMLElement;

		N_quotes?.addEventListener('click', () => {
			router.navigate('#module/quotes/quotes');
		});
	}

	public set data(data: any) {
		this._data = data;
		this.linkData();
		this.initButton();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(Quotes.tagName, Quotes);
	}
}

export default Quotes;
