import { Controller, Tabs } from '@autoprog/core-client';

import C_ExportData from '../tabs/ExportData';
import C_ImportData from '../tabs/ImportData';

import T_ExportData from '../../tpl/exportData.html';
import T_ImportData from '../../tpl/importData.html';

import History from '@libs/History';

class ImportExportCtrl extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'import-data';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		this.tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		this.tabs.add({
			name: 'Import',
			id: 'import-data',
			default: defaultTab === 'import-data',
			controller: C_ImportData as any,
			permission: 'IMPORT.SHOW',
			template: T_ImportData
		});

		this.tabs.add({
			name: 'Export',
			id: 'export-data',
			default: defaultTab === 'export-data',
			controller: C_ExportData as any,
			permission: 'EXPORT.SHOW',
			template: T_ExportData
		});

		let firstLoad = true;
		this.tabs.on('change', (data: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/import_export/${data.id}`);
			}
		});
	}

	public destructor() {
		this.tabs.destructor();
	}
}

export default ImportExportCtrl;
