import GenericService from './GenericService';

import moment, { Moment } from 'moment';
import Utils from '@libs/utils/Utils';

class QuoteService extends GenericService {
	private static instance: QuoteService | null = null;

	private prefix = 'DE';

	constructor() {
		super('quotes');
	}

	public static getInstance(): QuoteService {
		if (!QuoteService.instance) {
			QuoteService.instance = new QuoteService();
		}

		return QuoteService.instance;
	}

	public changeState(id: string, state: string) {
		return this.put_function('changeState', { id, state, user: Utils.userID });
	}

	public toValidate(id: string, user: string, createBy: string) {
		return this.put_function('toValidate', { id, user, createBy });
	}

	public validateNotification(id: string, notification: string) {
		return this.put_function('validateNotification', { id, notification, user: Utils.userID });
	}

	public refuseNotification(id: string, notification: string) {
		return this.put_function('refuseNotification', { id, notification, user: Utils.userID });
	}

	public getNewNumberByCompany(date = moment(), companyID: string) {
		return this.get_function('getNewNumberByCompany', { date: date.valueOf().toString(), companyID });
	}

	public getNextVersionNumber(id: string) {
		return this.get_function('getNextVersionNumber', { id });
	}

	public convertToOrder(id: string) {
		return this.get_function('convertToOrder', { id });
	}

	public getRecap(data: { [key: string]: any }) {
		return this.post_function('getRecap', { data });
	}

	public getMaterials(data: { [key: string]: any }) {
		return this.post_function('getMaterials', { data });
	}

	public sendNotificationNotFinish(id: string, users: string[]) {
		return this.put_function('sendNotificationNotFinish', { id, users, user: Utils.userID });
	}

	public updateNumberByDate(number: string, date: Moment) {
		const chunkNumber = number.replace(`${this.prefix}-`, '').split('-');

		return `${this.prefix}-${date.format('YYYY-MM')}-${chunkNumber.slice(2).join('-')}`;
	}
}

export default QuoteService;
