import { LoggedUser, Tab } from '@autoprog/core-client';

import { AllModules, GetContextMenuItemsParams, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';

import SettingsApps from '@libs/Settings';
import StateSaver from '@libs/agGrid/StateSaver';
import agUtils from '@libs/agGrid/french';

import M_AddEdit from '../modals/Favorite/AddEdit';

import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_HeaderDashboard from '@libs/customElement/HeaderDashboard';

import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import Options, { SettingQuoteOption } from '../libs/GridOptions';
import QuoteOptions from '../libs/QuoteOptions';

import CE_FilterButton from '@libs/customElement/FilterButton';

class Favorite extends Tab {
	private element: HTMLElement;

	private gridOptions: GridOptions = {};
	private optionsGrid: Options;
	private quoteOptions: QuoteOptions;

	private abortController: AbortController;
	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	constructor(el: HTMLElement) {
		super(el);

		this.abortController = new AbortController();

		this.quoteOptions = QuoteOptions.newInstance();
		this.optionsGrid = new Options();
		this.optionsGrid.init();
		this.optionsGrid.isLock = true;

		const N_add = document.querySelector('#add') as HTMLElement;

		N_add.classList.remove('d-none');

		N_add.addEventListener('click', () => {
			new M_AddEdit().open().then(() => {
				this.refreshData();
			});
		}, {
			signal: this.abortSignal
		});

		N_add.setAttribute('permission', 'QUOTES._FAVORITE.ADD');

		const N_Infos = document.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		N_Infos.reset();
		N_Infos.setTitle('Favoris');

		this.element = el;

		this.init().then(() => {
			N_Infos.setGridOptions(this.gridOptions);
			this.initGridSidebar();
			this.initFilter();
		});
	}

	private async refreshData() {
		const { rowData } = await S_Q_Favorite.getInstance().getDataToAgGrid(this.abortController);
		this.gridOptions.api?.setRowData(rowData);
	}

	private async init() {
		const { rowData, settings } = await S_Q_Favorite.getInstance().getDataToAgGrid(this.abortController);

		const pref = SettingsApps.getInstance().get('QUOTE.PREF') as unknown as { [key: string]: any };
		const options = SettingsApps.getInstance().get('QUOTE.OPTIONS') || {};

		this.quoteOptions.infos = { state: '0' };
		this.quoteOptions.pref = pref || {};
		this.quoteOptions.quoteOptions = options as SettingQuoteOption;

		const gridMaster = this.optionsGrid.gridMaster('favorite');

		gridMaster.columnDefs.pop();

		gridMaster.columnDefs.splice(1, 0, {
			field: 'title',
			headerName: 'Titre',
			suppressSizeToFit: true,
			width: 300
		});

		gridMaster.columnDefs.push({
			field: 'user',
			headerName: 'Utilisateur',
			width: 150,
			suppressSizeToFit: true
		});

		gridMaster.columnDefs.push({
			headerName: 'Action',
			pinned: 'right',
			headerClass: 'ag-theme-custom-text-center',
			field: '_id',
			sortable: false,
			width: 100,
			resizable: false,
			filter: false,
			suppressSizeToFit: true,
			cellRenderer: (params) => {
				const N_div = document.createElement('div');

				const N_delete = document.createElement('button');

				N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

				N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
				N_delete.setAttribute('confirmation', '');
				N_delete.setAttribute('tooltip', 'Supprimer');
				N_delete.setAttribute('permission', 'QUOTES._FAVORITE.DELETE');

				N_delete.addEventListener('click', async () => {
					await S_Q_Favorite.getInstance().delete(params.value);

					this.refreshData();
				});

				const N_edit = document.createElement('button');

				N_edit.classList.add('h-100', 'py-0', 'btn-transparent');
				N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
				N_edit.setAttribute('tooltip', 'Éditer');
				N_edit.setAttribute('permission', 'QUOTES._FAVORITE.OPEN');

				N_edit.addEventListener('click', () => {
					new M_AddEdit(params.value).open().then(() => {
						this.refreshData();
					});
				});

				N_div.appendChild(N_edit);
				N_div.appendChild(N_delete);

				return N_div;
			}
		});

		for (const item of gridMaster.columnDefs) {
			if (['quantity', 'price', 'sum', '_idGrid', 'unit'].includes(item.field || '')) {
				item.filter = false;
			}
		}

		const gridDetails = this.optionsGrid.gridDetails('favorite', () => { });

		gridDetails.columnDefs.pop();

		this.gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucun Favoris' },
			rowData: [],
			...gridMaster,
			floatingFilter: true,
			getContextMenuItems: (params) => this.getContextMenu(params),
			defaultColDef: {
				suppressMenu: true,
				editable: false,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				resizable: true,
				filterParams: {
					newRowsAction: 'keep'
				}
			},
			masterDetail: true,
			detailRowHeight: 400,
			detailCellRendererParams: {
				detailGridOptions: <GridOptions>{
					...gridDetails,
					suppressDragLeaveHidesColumns: true,
					defaultColDef: {
						suppressMenu: true
					},
					onGridReady: (params) => {
						params.api.sizeColumnsToFit();
					},
					getContextMenuItems: (params) => this.getContextMenu(params)
				},
				template: () => {
					return '<div class="h-100"><div ref="eDetailGrid" class="h-100" style="padding: 3px;background: black;"></div></div>';
				},
				getDetailRowData: (params: any) => {
					params.successCallback(params.data.details);
				}
			},
			onGridReady: (params) => {
				params.api?.setRowData(rowData);

				const stateSaver = new StateSaver(this.gridOptions as any, 'quotes-favorite');
				stateSaver.setData(settings);
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api?.resetRowHeights();
				}
			}
		});

		const N_grid = this.element.querySelector('#grid') as HTMLElement;
		new Grid(N_grid, this.gridOptions, { modules: [...AllModules, MasterDetailModule] });
	}

	private initFilter() {
		const N_header = this.element.querySelector('#header-grid') as HTMLElement;
		const N_filter = document.createElement('ap-filter-button') as CE_FilterButton;
		N_header.insertBefore(N_filter, N_header.firstChild);
		N_filter.setConfig([], this.gridOptions);
	}

	private initGridSidebar() {
		const N_GridSidebar = this.element.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.gridOptions);
	}

	private getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		const result: any[] = [];

		if (params.node) {
			result.push({
				name: 'Éditer le favori',
				icon: '<i class="icon icon-edit"></i>',
				disabled: !LoggedUser.getInstance().hasPermission('QUOTES._FAVORITE.OPEN'),
				action: () => {
					new M_AddEdit(params.node.data._id || params.node.data._idFavorite).open().then(() => {
						this.refreshData();
					});
				}
			});

			return result;
		} else {
			return [];
		}
	}

	public destructor() {
		this.abortController.abort('destroyed');
	}
}

export default Favorite;
