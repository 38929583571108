module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div class="d-flex">\n            N° ' +
((__t = (data.infos.number || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n</div>';

}
return __p
}