module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div class="d-flex">\n            <span class="font-weight-bold text-blue-grey-900">' +
((__t = (data.customer || '&nbsp;')) == null ? '' : __t) +
'</span>\n\n            <div class="ml-auto">\n                Traité : ' +
((__t = (data.finish ? '<i class="text-success icon icon-solid-check"></i>' : '<i class="text-danger icon icon-solid-times"></i>')) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="mt-2">\n            ' +
((__t = (data.contact || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n        <div class="mt-2">\n            ' +
((__t = (data.label || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n</div>';

}
return __p
}