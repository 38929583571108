// CORE
import { Form, global } from '@autoprog/core-client';

// TEMPLATE
import T_modal from '../../../tpl/modals/receipts/infos.html';

// LIBS
import ModalDocument from '@libs/modals/ModalDocument';

// UTILS
import Utils from '@libs/utils/Utils';

// CUSTOM_ELEMENT
import CE_ContentReceipt from '../../libs/customElement/ContentReceiptGrid';

// SERVICE
import S_Receipt from '@services/ReceiptService';

class Infos extends ModalDocument {
	private form: Form | null = null;

	private _options: { [key: string]: any };

	private N_ContentReceipt: CE_ContentReceipt | null = null;

	private events: EventListenerCanceller[] = [];

	private companyRefObject: { id: string, text: string } = { id: '', text: '' };

	private commandRefObject: { id: string, text: string } = { id: '', text: '' };

	private providerRefObject: { id: string, text: string } = { id: '', text: '' };

	constructor(options: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this._options = options;

		this.on('opened', async () => {
			const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLElement;
			N_modalDialog.style.maxWidth = global.IS_MOBILE ? '' : '80%';

			this.init();

			this.form = new Form(this.N_form!);

			this.initProduct();

			await this.getData();

			this.postInit();

			Utils.cancelEditingAggrid(this.N_ContentReceipt!.gridOptions, this.abortSignal);
		});

		this.on('closed', () => {
			for (const event of this.events) {
				event();
			}
		});
	}

	private async getData() {
		const { data } = await S_Receipt.getInstance().getDataToModal(this._options.id, this._options);

		if (data.data.companyID) {
			this.companyRefObject = data.data.companyID;
			data.data.companyID = this.companyRefObject.text;
		}

		if (data.data.infos.command) {
			this.commandRefObject = data.data.infos.command;
			data.data.infos.command = this.commandRefObject.text;
		}

		if (data.data.infos.provider) {
			this.providerRefObject = data.data.infos.provider;
			data.data.infos.provider = this.providerRefObject.text;
		}

		this.form?.setData(data.data);

		this.N_FilesTab!.data = data.data.attachments || {};
		this.N_NotesTab!.data = data.data.notes || [];
		this.N_ContentReceipt!.data = {
			locations: data.locations,
			products: data.data.products
		};

		return data;
	}

	protected async save() {
		const formData = this.form?.getData() as any;

		const newData: { [key: string]: any } = {
			_id: this._options.id,
			...formData,
			products: this.N_ContentReceipt!.data
		};

		newData.attachments = this.N_FilesTab!.data;
		newData.notes = this.N_NotesTab!.data;

		newData.companyID = this.companyRefObject.id;
		newData.infos.command = this.commandRefObject.id;
		newData.infos.provider = this.providerRefObject.id;

		await S_Receipt.getInstance().save(newData);

		return newData;
	}

	protected init() {
		super.init();
	}

	protected postInit() {
		this.N_FilesTab!.postInit();
		this.N_ContentReceipt?.active();

		super.postInit();
	}

	private async initProduct() {
		this.N_ContentReceipt = this.element.querySelector(CE_ContentReceipt.tagName) as CE_ContentReceipt;

		this.N_ContentReceipt.setParentElement(this.element);
	}
}

export default Infos;
