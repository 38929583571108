import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import _ from 'lodash';

import P_MaterialsByBrand from '../printer/MaterialsByBrand';
import P_MaterialsByCategory from '../printer/MaterialsByCategory';

import S_Products from '@services/Product/ProductService';
import S_Quote from '@services/QuoteService';
import S_StockEvent from '@services/StockEventService';

import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

class RecapTab extends HTMLElement {
	public static readonly tagName: string = 'ap-products-recap-quote';

	private idTab: string;
	private selectorTab: string;
	private N_content: HTMLElement | null = null;

	private _gridOptions: GridOptions = {};
	private _dataId = (): string => { return ''; };
	private _data = (): any => { return {}; };

	private locations: { [key: string]: any }[] = [];

	public static register() {
		customElements.define(this.tagName, RecapTab);
	}

	constructor() {
		super();
		this.idTab = this.id;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="product/line" icon-active="product/fill" text="Produits"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this._gridOptions.api?.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public async connectedCallback() {
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_content = document.createElement('div');
		this.N_content.classList.add('tab-pane', 'page-tabs-container');
		this.N_content.id = this.idTab;
		this.N_content.innerHTML = `
			<div class="page-tabs-title">
				Produits
				<div class="d-flex align-items-center text-sm ml-2">
					<strong class="text-grey mr-3 pt-1">Légende :</strong>

					<small class="d-flex align-items-center text-orange-900 mr-3">
						<div class="badge-legend bg-orange-100"></div>
						Stock insuffisant
					</small>

					<small class="d-flex align-items-center text-green-900">
						<div class="badge-legend bg-green-100"> </div>
						Stock OK
					</small>

				</div>
				<div class="page-tabs-title-right">
					<div class="d-flex align-items-center mr-2">

						<div class="text-md mr-1">Imprimer le stock :</div>

						<ap-switch id="displayStock"></ap-switch>

					</div>
									
					<div class="dropdown d-inline-flex h-100">
						<ap-button type="print" id="print-by-type" data-toggle="dropdown"></ap-button>
						<div class="dropdown-menu mt-2" style="border-radius:5px">
							<div class="dropdown-item" id="print-by-brand">
								Par marque 
								<ap-icon class="ml-2" name="information/line" tooltip="Prend en compte les filtres"></ap-icon>
							</div>
							<div class="dropdown-item" id="print-by-category">Par catégorie</div>
						</div>
					</div>

					<ap-button class="btn-icon" type="reload" id="reload" tooltip="Actualiser le tableau"></ap-button>

					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<div class="h-100 w-100 ag-theme-alpine grid"></div>
			</div>
        `;

		const N_printByBrand = this.N_content.querySelector('#print-by-brand') as HTMLButtonElement;
		const N_printByCategory = this.N_content.querySelector('#print-by-category') as HTMLButtonElement;
		const N_reload = this.N_content.querySelector('#reload') as HTMLButtonElement;

		N_printByBrand.addEventListener('click', () => {
			this.printByBrand();
		});

		N_printByCategory.addEventListener('click', () => {
			this.printByCategory();
		});

		N_reload.addEventListener('click', async () => {
			const data = await S_Quote.getInstance().getMaterials(this._data());
			this._gridOptions.api?.setRowData(data);
			this.initStock();
		});

		N_container.append(this.N_content);
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});
			})(item);
		}

		this._gridOptions = agUtils.french<GridOptions>({
			rowData: this._gridOptions.rowData || [],
			columnDefs: [
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: 'product._id',
					width: 200,
					suppressSizeToFit: true,
					floatingFilter: true,
					cellRenderer: ProductCellRenderer
				},
				{
					headerName: 'Libellé',
					field: 'product.label',
					floatingFilter: true
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					floatingFilter: true,
					sort: 'asc',
					width: 200,
					suppressSizeToFit: true
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: ['text-right', 'text-monospace'],
					width: 100,
					suppressSizeToFit: true
				},
				{
					headerName: 'Unité',
					field: 'product.unit',
					width: 100,
					suppressSizeToFit: true
				}
			],
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			getRowStyle: (params: any) => {
				const stockProduct = params.data.currentStock || {};

				if (_.isUndefined(stockProduct)) {
					return {
						background: '#fff'
					};
				}

				let totalStock = 0;

				for (const key in stockProduct) {
					totalStock += stockProduct[key].quantity || 0;
				}

				if (totalStock < params.data.quantity) {
					return {
						'background-color': 'var(--ap-orange-50)',
						color: 'var(--ap-orange-900)'
					};
				}

				if (totalStock >= params.data.quantity) {
					return {
						'background-color': 'var(--ap-green-50)',
						color: 'var(--ap-green-900)'
					};
				}
			},
			getContextMenuItems: () => {
				return [{
					name: 'Imprimer',
					subMenu: [{
						name: 'Par marque',
						action: () => {
							this.printByBrand();
						}
					}, {
						name: 'Par categorie',
						action: () => {
							this.printByCategory();
						}
					}]
				}];
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private printByBrand() {
		const N_displayStock = this.N_content!.querySelector('#displayStock') as HTMLInputElement;

		const rowData: { [key: string]: any }[] = [];

		this._gridOptions.api?.forEachNodeAfterFilter((node) => {
			rowData.push(node.data);
		});

		new P_MaterialsByBrand(this._dataId(), rowData, N_displayStock.checked);
	}

	private printByCategory() {
		const N_displayStock = this.N_content!.querySelector('#displayStock') as HTMLInputElement;

		const rowData: { [key: string]: any }[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			rowData.push(node.data);
		});

		new P_MaterialsByCategory(this._dataId(), rowData, N_displayStock.checked);
	}

	public setData(data: { [key: string]: any }) {
		this.locations = data.locations;

		this._gridOptions.rowData = data.products;
		this._gridOptions.api?.setRowData(data.products);

		if (!this._gridOptions.api) {
			this.initGrid();
			this.initStock();
		}
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id.value] = stock[item.product._id.value] || {};
			stock[item.product._id.value][item.stock.value] = stock[item.product._id.value][item.stock.value] || { quantity: 0 };
			stock[item.product._id.value][item.stock.value].quantity = item.quantity.formattedValue;
		}

		this._gridOptions.api?.forEachNode((node) => {
			for (const item of this.locations) {
				stock[node.data.reference] = stock[node.data.reference] || {};
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.reference][item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	public setID(cb: () => string) {
		this._dataId = cb;
	}

	public setGetData(cb: () => any) {
		this._data = cb;
	}
}
export default RecapTab;
