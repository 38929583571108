import h from 'hyperscript';

import SettingsController from '@modules/Settings/js/libs/SettingsController';

import CE_AgGrid from '@libs/customElement/AgGrid';
import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';

import TaxesService from '../services/TaxesService';

import M_AddTaxe from '../modals/AddTaxe';

class ListTaxeTab extends SettingsController {
	private taxesService: TaxesService;

	constructor(el: HTMLElement) {
		super(el);

		this.taxesService = new TaxesService();
	}

	protected async init() {
		this.initTaxe();
		this.initButtons();

		this.getData();
	}

	private initTaxe() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_grid.setGridOptions({
			columnDefs: [{
				headerName: 'Code',
				field: 'code',
				width: 100
			}, {
				headerName: 'Libellé',
				field: 'label'
			}, {
				headerName: 'Taux',
				field: 'rate',
				width: 80,
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					suffix: '%'
				}
			}, {
				headerName: 'Type',
				field: 'type',
				width: 100
			}, {
				headerName: 'Compte comptable',
				field: 'accountingAccount'
			}, {
				headerName: 'Mention particulière',
				field: 'specialMention'
			}, {
				headerName: '',
				width: 80,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit' } });

					N_edit.addEventListener('click', () => {
						new M_AddTaxe(params.node.data._rowData_).open().then(() => {
							this.getData();
						});
					});

					const N_delete = h('ap-button.btn-action-aggrid', { attrs: { type: 'delete', confirmation: 'true' } });

					N_delete.addEventListener('click', async () => {
						await this.taxesService.delete(params.node.data._id.value);
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit, N_delete);

					return N_div;
				}
			}],
			suppressContextMenu: true,
			defaultColDef: {
				resizable: true,
				suppressMovable: true,
				suppressMenu: true
			}
		});
	}

	private initButtons() {
		const N_add_compte = this.el.querySelector<HTMLButtonElement>('#add-account')!;

		N_add_compte.addEventListener('click', () => {
			new M_AddTaxe().open().then(() => {
				this.getData();
			});
		});
	}

	private async getData() {
		const taxes = await this.taxesService.getDataToAgGrid();

		const N_grid_taxes = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_grid_taxes.resetValue();

		N_grid_taxes.value = taxes?.rowData || [];
	}
}

export default ListTaxeTab;
