import '@css/customElements/inputWithAddon.scss';

class InputWithAddon extends HTMLElement {
	public static readonly tagName: string = 'ap-input-addon';

	private isValid: boolean = false;

	private validateImmediately: boolean = false;

	public connectedCallback() {
		const id = this.getAttribute('id-input') as string;
		const name = this.getAttribute('name') as string;
		const type = this.getAttribute('type') as string;
		const suffix = this.getAttribute('suffix') as string;
		const required = this.hasAttribute('required');

		const attrs = `${required ? 'required' : ''}`;

		if (type === 'number') {
			const step = this.getAttribute('step') as string;
			const min = this.getAttribute('min') as string;
			const max = this.getAttribute('max') as string;

			const textMin = this.hasAttribute('min') ? `min="${min}" ` : '';
			const textMax = this.hasAttribute('max') ? `max="${max}"` : '';
			const textStep = this.hasAttribute('step') ? `step="${step}"` : 'step="0.01"';

			this.innerHTML = `
				<input type="${type || 'text'}" id="${id}" name="${name}" ${textMin} ${textMax} ${textStep} ${required ? 'required' : ''}>
				<div class="suffix">${suffix}</div>
        	`;
		} else {
			this.innerHTML = `
				<input type="${type || 'text'}" id="${id}" name="${name}" ${attrs} >
				<div class="suffix">${suffix}</div>
			`;
		}

		this.disabled = this.hasAttribute('disabled');
		this.validateImmediately = this.hasAttribute('validate-immediately');

		this.removeAttribute('id-input');
		this.removeAttribute('name');

		this.initEvent();
	}

	/**
	 * Initialise les événements sur l'input
	 */
	private initEvent() {
		const input = this.querySelector<HTMLInputElement>('input');

		if (this.validateImmediately) {
			this.isValid = input!.checkValidity();
			this.toggleClass();
		}

		input?.addEventListener('input', () => {
			this.isValid = input!.checkValidity();
			this.toggleClass();
		});
	}

	public checkValidity(): boolean {
		const input = this.querySelector<HTMLInputElement>('input');
		this.isValid = input!.checkValidity();

		this.toggleClass();
		return this.isValid;
	}

	/**
	 * Change les classes sur le composant 
	 */
	private toggleClass() {
		if (this.isValid) {
			this.classList.add('valid');
			this.classList.remove('invalid');
		} else {
			this.classList.add('invalid');
			this.classList.remove('valid');
		}
	}

	public set disabled(value: boolean) {
		if (value) {
			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}

		const input = this.querySelector<HTMLInputElement>('input');
		if (input) {
			input.disabled = value;
		}
	}

	public static register() {
		customElements.define(InputWithAddon.tagName, InputWithAddon);
	}
}

export default InputWithAddon;
