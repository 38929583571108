import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

class LoadingCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('loadingCellRenderer');
		this.eGui.innerHTML = '';
	}

	public init(params: any) {
		if (params.value === undefined) {
			this.eGui.innerHTML = '<ap-icon class="icon-spin icon-1x" name="loader-4"></ap-icon>';
		} else {
			if (params.CellRenderer) {
				const instance = new params.CellRenderer();
				instance.init(params);
				this.eGui = instance.getGui();
			} else {
				this.eGui.innerHTML = params.value;
			}
		}
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default LoadingCellRenderer;
