// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import moment from 'moment';

// TEMPLATE
import T_modal from '@tpl/modals/deadlinePayment.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
import DeadlinePaymentUtils from '@libs/utils/DeadlinePaymentUtils';

// MODAL
// CUSTOM_ELEMENT
// SERVICE

class deadlinePayment extends Modal {
	private form: Form | null = null;

	constructor(data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			this.form = new Form(this.element.querySelector('form')!);

			data.fixedDealinePaypmentDate = data.fixedDealinePaypmentDate || 15;

			this.form.setData({
				simulation: {
					date: moment()
				},
				...data
			} as { [key: string]: any });

			const N_dateSimulation = this.form.getElementByName('simulation.date') as HTMLInputElement;
			const N_nbDays = this.form.getElementByName('deadlinePayment') as HTMLInputElement;
			const N_type = this.form.getElementByName('typeDeadlinePayment') as HTMLSelectElement;
			const N_fixedDate = this.form.getElementByName('fixedDateDeadline') as HTMLInputElement;

			N_dateSimulation.addEventListener('change', () => this.simulation());

			N_nbDays.addEventListener('input', () => this.simulation());

			N_type.addEventListener('change', () => this.simulation());

			N_fixedDate.addEventListener('input', () => this.simulation());

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const formData = this.form!.getData();
				formData.fixedDateDeadline = formData.fixedDateDeadline || 0;
				this.resolve(formData);
			});

			this.simulation();
		});
	}

	private simulation() {
		const N_text = this.element.querySelector('.simulation-text') as HTMLDivElement;
		const N_fixedDate = this.form!.getElementByName('fixedDateDeadline') as HTMLInputElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		const data = this.form!.getData() as { [key: string]: any };

		if (data.typeDeadlinePayment === '1') {
			(N_fixedDate.parentNode as HTMLElement).classList.remove('d-none');
		} else {
			(N_fixedDate.parentNode as HTMLElement).classList.add('d-none');
			this.form?.setDataByName('fixedDateDeadline', 15);
		}

		const estimatedDate = DeadlinePaymentUtils.calculateDate(data.simulation.date, data.deadlinePayment, data.fixedDateDeadline, data.typeDeadlinePayment);

		N_text.innerHTML = estimatedDate.format('DD/MM/YYYY');

		N_save.disabled = !this.form!.checkValidity();
	}
}

export default deadlinePayment;
