import Modal from '@libs/Modal';

import T_Modal from '@tpl/modals/PrintPreview.html';

import Utils from '@libs/utils/Utils';

import Loader from '@libs/Loader';

import axios from 'axios';

import CE_Alert, { AlertType } from '@libs/customElement/Alert';

export type PrintAlertOptions = {
	type: AlertType,
	htmlContent: string,
};

class PrintPreview extends Modal {
	private token: string;
	private table: string;

	constructor(table: string, id: string, url: string = 'pdf', alert?: PrintAlertOptions) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.table = table;
		this.token = '';

		this.on('opened', async () => {
			Loader.getInstance().open();

			if (alert) {
				const CE_Alert = document.createElement('ap-alert') as CE_Alert;
				CE_Alert.setType(alert.type);
				CE_Alert.toggleVisibility(true);
				CE_Alert.innerHTML = alert.htmlContent;

				const N_ModalHeader = this.element.querySelector('.modal-header') as HTMLDivElement;
				N_ModalHeader?.append(CE_Alert);
			}

			const N_fakePrint = this.element.querySelector('#fakePrint') as HTMLIFrameElement;

			N_fakePrint.addEventListener('load', () => {
				Loader.getInstance().close();
			});

			if (this.token.length) {
				N_fakePrint.src = `/api/${table}/${url}?id=${id}&user=${Utils.userID}&token=${this.token}&companyID=${Utils.companyID}`;
			} else {
				N_fakePrint.src = `/api/${table}/${url}?id=${id}&user=${Utils.userID}&companyID=${Utils.companyID}`;
			}
		});
	}

	public async getToken(data: any) {
		const token = (await axios.post(`/api/${this.table}/previewToken`, { defaultvalue: data })).data;
		this.token = token;
		return this;
	}
}

export default PrintPreview;
