module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n\n<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div class="font-weight-bold text-blue-grey-900">\n            ' +
((__t = (data.number || '&nbsp;')) == null ? '' : __t) +
' - ' +
((__t = (data.name || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n        <div class="mt-2">\n            ' +
((__t = (data.customer || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n        <div class="d-flex mt-2">\n            \n            ';
if(data.coords && data.coords.latitude && data.coords.longitude){;
__p += '\n\n                <a href="https://www.google.fr/maps/@' +
((__t = (data.coords.latitude)) == null ? '' : __t) +
',' +
((__t = (data.coords.longitude)) == null ? '' : __t) +
',14z"\n                    target="_blank" data-cancel-modal="true">\n                    <i class="icon icon-solid-map-marker-alt mr-2"></i>' +
((__t = (data.city || '&nbsp;')) == null ? '' : __t) +
'\n\n                </a>\n            ';
}else if(data.address){;
__p += '\n\n                <a href="http://maps.google.com/?q=' +
((__t = (data.address)) == null ? '' : __t) +
'" target="_blank" data-cancel-modal="true">\n                    <i class="icon icon-solid-map-marker-alt mr-2"></i>' +
((__t = (data.city || '&nbsp;')) == null ? '' : __t) +
'\n\n                </a>\n            ';
}else{;
__p += '\n                <i class="icon icon-solid-map-marker-alt mr-2"></i>' +
((__t = (data.city || '&nbsp;')) == null ? '' : __t) +
'\n            ';
};
__p += '\n\n        </div>\n\n        <div class="d-flex mt-2">\n\n            ';
 if(data.phone){;
__p += '\n                <a href="tel:' +
((__t = (data.phone)) == null ? '' : __t) +
';" data-cancel-modal="true">\n                    <i class="icon icon-phone mr-1"></i> ' +
((__t = (data.phone || '&nbsp;')) == null ? '' : __t) +
'\n                </a>\n            ';
}else{;
__p += '\n                <span class="text-muted">\n                    <i class="icon icon-phone mr-1"></i> Aucun Téléphone\n                </span>\n            ';
};
__p += '\n\n        </div>\n\n        <div class="d-flex mt-2">\n            ';
 if(data.mail){;
__p += '\n\n                <a href="mailto:' +
((__t = (data.mail)) == null ? '' : __t) +
';" data-cancel-modal="true">\n                    <i class="icon icon-envelope mr-2"></i>\n                    ' +
((__t = (data.mail || '&nbsp;')) == null ? '' : __t) +
'\n                </a>\n            ';
}else{;
__p += '\n                <span class="text-muted">\n                    <i class="icon icon-envelope mr-2"></i>Aucun Email\n                </span>\n            ';
};
__p += '\n        </div>\n\n    </div>\n\n</div>';

}
return __p
}