import _get from 'lodash/get';
import _set from 'lodash/set';

import getElementValue from './utils/getElementValue';
import setElementValue from './utils/setElementValue';

import CE_Select from '@libs/customElement/Select';
import isRadioElement from './utils/isRadioElement';

export type ElementForm = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | CE_Select;

class Form {
	private N_form: HTMLFormElement;

	private N_inputsByName: Map<string, ElementForm[]>;

	constructor(N_form: HTMLFormElement) {
		this.N_form = N_form;
		this.N_inputsByName = new Map();

		this.updateInputs();
	}

	private isValidInput(N_input: HTMLElement) {
		if (N_input instanceof HTMLInputElement) {
			return true;
		}

		if (N_input instanceof HTMLTextAreaElement) {
			return true;
		}

		if (N_input instanceof HTMLSelectElement) {
			return true;
		}

		if (N_input instanceof CE_Select) {
			return true;
		}

		return false;
	}

	public updateInputs() {
		const N_inputs = this.N_form.querySelectorAll<ElementForm>('[name]');

		for (const N_input of N_inputs) {
			if (this.isValidInput(N_input)) {
				const tmp = this.N_inputsByName.get(N_input.name) || [];
				tmp.push(N_input);

				this.N_inputsByName.set(N_input.name, tmp);
			}
		}
	}

	public setData(data: { [key: string]: any }) {
		for (const [name, N_inputs] of this.N_inputsByName) {
			const value = _get(data, name);

			if (isRadioElement(N_inputs[0])) {
				for (const element of N_inputs) {
					if (element instanceof HTMLInputElement) {
						element.checked = true;
						break;
					}
				}
			} else {
				for (const element of N_inputs) {
					_set(data, name, setElementValue(element, value));
				}
			}
		}
	}

	public getData() {
		const data: { [key: string]: any } = {};

		for (const [name, N_inputs] of this.N_inputsByName) {
			if (isRadioElement(N_inputs[0])) {
				for (const element of N_inputs) {
					if (element instanceof HTMLInputElement && element.checked) {
						_set(data, name, element.value);
						break;
					}
				}
			} else {
				for (const element of N_inputs) {
					_set(data, name, getElementValue(element));
				}
			}
		}

		return data;
	}

	public getDataByName(name: string) {
		const N_el = this.N_inputsByName.get(name);

		if (N_el) {
			if (isRadioElement(N_el[0])) {
				for (const element of N_el) {
					if (element instanceof HTMLInputElement && element.checked) {
						return element.value;
					}
				}
			} else {
				return getElementValue(N_el[0]);
			}
		}

		return null;
	}
}

export default Form;
