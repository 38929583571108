import moment from 'moment';

import _ from 'lodash';

class FormControllerUtils {
	private static isObject(obj: any) {
		return obj instanceof Object && Object.keys(obj).length !== 0 && !Array.isArray(obj) && !moment.isMoment(obj);
	}

	private static clone(obj: any) {
		if (moment.isMoment(obj)) {
			return obj.clone();
		}

		return _.cloneDeep(obj);
	}

	public static merge(oldData: { [key: string]: any }, newData: { [key: string]: any }) {
		for (const key in newData) {
			if (this.isObject(newData[key])) {
				oldData[key] = this.merge(oldData[key] || {}, newData[key]);
			} else {
				oldData[key] = this.clone(newData[key]);
			}
		}

		return this.clone(oldData);
	}
}

export default FormControllerUtils;
