// CORE
import { Form, global, toaster } from '@autoprog/core-client';

// NODE_MODULE
import { GridApi } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
import T_desktop from '../../tpl/modals/AddEdit.desktop.html';

// LIBS
import Modal from '@libs/Modal';

import ServiceManager from '@managers/ServiceManager';
import TemplateModalManager from '@managers/TemplateModalManager';

import GridAddedit from './AddEdit.Grid';

// PRINTER
// UTILS
import Select2Utils from './utils/select2Utils';

// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';
import FilesTab from '@libs/customElement/FilesTab';

// SERVICE

class AddEditModal2 extends Modal {
	protected config: { [key: string]: any } = {};

	protected _id: string | undefined;
	protected database: string;

	private mode: string | undefined;

	protected defaultvalue: { [key: string]: any } = {};
	protected oldFormData: { [key: string]: any } = {};

	protected select2utils: { [key: string]: Select2Utils } = {};

	protected gridOptionsTable: { [key: string]: GridAddedit } = {};
	private N_FilesTab: FilesTab | null = null;

	protected selectPostinit: { [key: string]: CE_Select2 } = {};

	protected form: Form | null = null;

	constructor(config: { [key: string]: any }, database: string, id?: string, defaultvalue = {}) {
		super({
			tpl: T_desktop,
			keyboard: false,
			backdrop: 'static'
		});

		this.config = config;
		this.database = database;
		this.defaultvalue = defaultvalue;
		this._id = id;

		this.on('opened', async () => {
			await this.init();
		});
	}

	public setMode(mode: 'duplicate' | string) {
		this.mode = mode;
		return this;
	}

	/**
	 * Initialise le contenu de la modal
	 */
	protected async init() {
		const { data, grid } = await this.getData();

		const N_modalHeaderTitle = this.element.querySelector('.modal-header #title') as HTMLElement;

		N_modalHeaderTitle.innerHTML = TemplateModalManager.getTitle(data, this.database);

		const N_modalBody = this.element.querySelector('.modal-body') as HTMLElement;
		N_modalBody.innerHTML = TemplateModalManager.get(this.database, this.mode);

		const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLElement;
		N_modalDialog.style.maxWidth = global.IS_MOBILE ? '' : this.config.modal.size;

		this.initFile();
		this.initSelect2(N_modalBody);
		this.initAgGridTable(N_modalBody, grid || {});

		if (this.N_FilesTab) {
			this.N_FilesTab.data = data.attachments || {};
			this.N_FilesTab.postInit();
		}

		const N_form = N_modalBody.querySelector('form') as HTMLFormElement;
		N_form.addEventListener('submit', (e) => {
			e.preventDefault();
		});

		this.form = new Form(N_form);

		if (data) {
			this.form.setData(data);
		}

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;
		const N_cancel = this.element.querySelector('#cancel') as HTMLButtonElement;

		N_save.addEventListener('click', async () => {
			const id = await this.save();
			this.resolve(id);
		});

		N_cancel.addEventListener('click', async () => {
			this.reject();
		});

		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}

	private async getData() {
		let data: { [key: string]: any } = {};

		if (this.mode === 'duplicate') {
			const tmp = await ServiceManager.get(this.database)?.getInstance().duplicate(this._id!, this.defaultvalue) || {};
			data = tmp.data;
			this._id = data.data._id;
		} else {
			const tmp = await ServiceManager.get(this.database)?.getInstance().getDataToModal(this._id, this.defaultvalue) || {};
			data = tmp.data;
		}

		for (const col of this.config.columns) {
			let value = _.get(data.data, col.key);

			if (col.type === 'date') {
				value = moment(value, col.formatFrom || 'x');
			}

			_.set(data.data, col.key, value);
		}

		this.oldFormData = data.data;

		return data;
	}

	private async initFile() {
		this.N_FilesTab = this.element.querySelector('ap-files-tab') as FilesTab;

		if (this.N_FilesTab) {
			this.N_FilesTab.setParentElement(this.element);

			this.N_FilesTab.setCallback(() => {
				this.save();
			});
		}
	}

	protected createSelect2Utils(key: string, N_select: HTMLSelectElement) {
		const table = _.find(this.config.columns, { key })?.table;

		this.select2utils[key] = new Select2Utils(table, N_select);

		this.select2utils[key].create(this.element);
	}

	protected createApSelect2Button(key: string, N_select: CE_Select2) {
		N_select.create(this.element);

		this.selectPostinit[key] = N_select;
	}

	private initSelect2(el: HTMLElement) {
		const N_elements = el.querySelectorAll('.select2') as NodeListOf<HTMLSelectElement>;

		N_elements.forEach((N_select) => {
			const key = N_select.getAttribute('name') || '';

			this.createSelect2Utils(key, N_select);
		});

		const N_apSelect2 = el.querySelectorAll('ap-select2-button') as NodeListOf<CE_Select2>;

		N_apSelect2.forEach((N_select) => {
			const key = N_select.getAttribute('name') || '';

			this.createApSelect2Button(key, N_select);
		});
	}

	/**
	 * Permet d'ajouter une ligne sur un tableau AgGrid de la modal
	 * @param params les paramètres renvoyés par AgGrid
	 * @param configGrid la configuration du tableau AgGrid
	 * @param table le nom du tablau
	 * @param keyRef le clés de référence par rapport à l'entité actuelle
	 */
	protected async addRowAgGridTable(params: any, configGrid: any, table: string, keyRef: string) {
		const id = await this.save();

		this._id = id;

		new AddEditModal2(configGrid, table, '', {
			[keyRef]: this._id
		}).open().then(async (id) => {
			const data = await ServiceManager.get(table)?.getInstance().getDataToAgGridByID(id);

			params.api?.applyTransaction({
				add: [data?.rowData]
			});
		});
	}

	/**
	* Permet d'éditer une ligne d'un tableau AgGrid de la modal
	* @param params les paramètres renvoyés par AgGrid
	* @param configGrid la configuration du tableau AgGrid
	* @param table le nom du tablau
	*/
	protected async editRowAgGridTable(params: any, configGrid: any, table: string) {
		const id = await this.save();

		this._id = id;

		new AddEditModal2(configGrid, table, params.value).open().then(async (id) => {
			const data = await ServiceManager.get(table)?.getInstance().getDataToAgGridByID(id);

			params.node.setData(data?.rowData);
		});
	}

	/**
	 * Permet de supprimer une ligne d'un AgGrid
	 * @param params les paramamètres AgGrid
	 * @param table le nom de la table
	 */
	protected async deleteRowAgGridTable(params: any, table: string) {
		try {
			await ServiceManager.get(table)?.getInstance().delete(params.value);

			params.api?.updateRowData({
				remove: [params.node.data]
			});

			toaster.success('Suppression réussi');
		} catch (e) {

		}
	}

	private initAgGridTable(el: HTMLElement, data: { [key: string]: any }) {
		const N_elements = el.querySelectorAll('div[data-table]') as NodeListOf<HTMLElement>;

		N_elements.forEach((N_el) => {
			const table = N_el.dataset.table || '';
			const keyRef = N_el.dataset.keyRef || '';

			this.gridOptionsTable[table] = new GridAddedit(table, data[table], keyRef, N_el);

			this.gridOptionsTable[table].on('add', (params: any, configGrid: any, table: string, keyRef: string) => {
				this.addRowAgGridTable(params, configGrid, table, keyRef);
			});

			this.gridOptionsTable[table].on('edit', (params: any, configGrid: any, table: string) => {
				this.editRowAgGridTable(params, configGrid, table);
			});

			this.gridOptionsTable[table].on('delete', (params: any, table: string) => {
				this.deleteRowAgGridTable(params, table);
			});
		});
	}

	protected async getFormData(data: { [key: string]: any }) {
		const idConfig = _.find(this.config.columns, { type: 'primaryKey' }) as any;

		if (idConfig.idKey) {
			this._id = this._id || data._id || data[idConfig.idKey] || Date.now().toString(36);
		} else {
			this._id = this._id || data._id || Date.now().toString(36);
		}

		data._id = this._id;

		for (const col of this.config.columns) {
			if (col.type !== 'isAgGrid') {
				let value = _.get(data, col.key);

				if (_.isUndefined(value)) {
					value = '';
				}

				if (col.type === 'date') {
					value = (value && value.isValid()) ? value.format(col.formatFrom) : '';
				}

				if (_.isNaN(value)) {
					value = '';
				}

				if (_.isNumber(value)) {
					value = value.toString();
				}

				if (!!col.replaceFrom && !!col.replaceTo) {
					value = value.toString().replace(new RegExp(col.replaceFrom, 'gmi'), col.replaceTo);
				}

				if (col.toUpperCase) {
					value = value.toString().toUpperCase();
				}

				if (col.toLowerCase) {
					value = value.toString().toLowerCase();
				}

				_.set(data, col.key, value);
			}
		}

		data.attachments = this.N_FilesTab?.data || {};

		delete data._attachments;

		return data;
	}

	protected saveGridtable() {
		const result: { [key: string]: any } = {};

		for (const table in this.gridOptionsTable) {
			if (this.gridOptionsTable[table].api) {
				result[table] = result[table] || [];

				(this.gridOptionsTable[table].api as GridApi).forEachNode(async (node) => {
					result[table].push(node.data.rowData);
				});
			}
		}

		return result;
	}

	protected async save() {
		if (this.form && this.form.checkValidity()) {
			let data = this.form.getData() as { [key: string]: any };

			try {
				data = await this.getFormData(data);

				const otherData = {
					orders: this.saveGridtable()
				};

				const res = await ServiceManager.get(this.database)?.getInstance().save(data, otherData);

				if (res && res.err) {
					throw new Error(res && res.err);
				} else {
					toaster.success('Sauvegarde réussie');
					return data._id;
				}
			} catch (e) {
				if ((e as any).response) {
					toaster.error((e as any).response.data, 'Erreur lors de la sauvegarde');
					throw new Error((e as any).response.data);
				} else {
					toaster.error((e as any).message, 'Erreur lors de la sauvegarde');
					throw new Error((e as any).message);
				}
			}
		} else {
			toaster.error('Formulaire non-valide');
			throw Error('Formulaire non-valide');
		}
	}
}

export default AddEditModal2;
