import { toaster } from '@autoprog/core-client';

import _ from 'lodash';

import Modal from '@libs/Modal';

import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import T_Modal from '../../../tpl/modals/AddQuote/AddFavorite.html';

class AddFavorite extends Modal {
	constructor(newData: { [key: string]: any }) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		newData = _.cloneDeep(newData);

		this.on('opened', () => {
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			const N_title = this.element.querySelector('#title') as HTMLInputElement;

			N_title.value = (newData.category || '') + (newData.subCategory ? ' - ' + newData.subCategory : '') || newData.name;

			N_save.addEventListener('click', async () => {
				newData.title = N_title.value;

				const data = await S_Q_Favorite.getInstance().save(newData);

				if (!data.err) {
					this.resolve(data.data.data._id);
					toaster.success('Sauvegarde réussi');
				}
			});
		});
	}
}

export default AddFavorite;
