import Select2Utils from '@libs/utils/select2Utils';

class Select2Editor {
	private value: string = '';
	private params: any;
	private eGui: HTMLElement;

	private select2Instance: Select2Utils | null;
	private N_select: HTMLSelectElement | undefined;

	constructor() {
		this.select2Instance = null;
		this.eGui = document.createElement('div');
		this.eGui.classList.add('w-100', 'ag-input-text-wrapper');
	}

	public getGui() {
		return this.eGui;
	}

	public getValue() {
		return this.value;
	}

	public afterGuiAttached() {
		const select = this.eGui.querySelector('.select2');

		if (select) {
			$(select).select2('open');
			(document.querySelector('.select2-search__field') as HTMLInputElement).focus();

			if (this.params.charPress) {
				$(select).data('select2').dropdown.$search.val(this.params.charPress).trigger('input');
			}
		}
	}

	public isPopup() {
		return false;
	}

	public init(params: any) {
		this.params = params;
		this.value = params.value;
		this.eGui.innerHTML = '<select class="form-control select2"></select>';

		this.N_select = this.eGui.querySelector('select') as HTMLSelectElement;

		params.options = params.options || {};

		let select2 = $(this.N_select) as JQuery<HTMLElement>;

		if (params.options.table) {
			this.select2Instance = new Select2Utils(params.options.table, this.N_select);

			select2 = this.select2Instance.create(params.options.rootElement, params.options.overrideOption || {})!;

			this.getSelectedValue(select2);
		} else if (params.options.select2Utils) {
			this.select2Instance = params.options.select2Utils as Select2Utils;

			this.select2Instance.setSelect(this.N_select);

			select2 = this.select2Instance.create(params.options.rootElement, params.options.overrideOption || {})!;

			this.getSelectedValue(select2);
		} else {
			select2.select2(params.options);

			select2.val(this.value);

			select2.on('select2:select', () => {
				this.value = this.N_select!.value;

				params.stopEditing();
			});

			select2.on('select2:unselect', () => {
				this.value = '';
			});
		}
	}

	private getSelectedValue(select2: JQuery<HTMLElement>) {
		if (this.params.options.isAsync) {
			this.select2Instance!.getDisplayRefValue(this.value).then((data: any) => {
				this.bindSelectEvent(select2, data);
			});
		} else {
			this.bindSelectEvent(select2, { id: this.value, text: this.value });
		}
	}

	public bindSelectEvent(select2: JQuery<HTMLElement>, data: { id: string, text: string }) {
		if (this.value) {
			// create the option and append to Select2
			const option = new Option(data.text, data.id, true, true);
			select2.append(option).trigger('change');
			///@ts-ignore
			select2.trigger({
				type: 'select2:select',
				params: {
					data
				}
			});
		}

		select2.on('select2:select', () => {
			this.value = this.N_select!.value;
			this.params.stopEditing();
		});
		select2.on('select2:unselect', () => {
			this.value = '';
		});
	}

	public destroy() {
		const select = this.eGui.querySelector('.select2');
		select && $(select).select2('close');
	}
}

export default Select2Editor;
