import DashboardController, { DashboardConfigColumns, DashboardOptions } from '@js/controllers/DashboardController';

class TagssTab extends DashboardController {
	constructor(el: HTMLElement) {
		super(el, 'Mots clés', '', 'products-tags');
	}

	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return data.label.formattedValue;
	}

	protected getDisabledActionButtons(data: { [key: string]: any }): {
		edit?: boolean, view?: boolean, duplicate?: boolean, delete?: boolean, print?: boolean
	} {
		return {
			edit: false,
			view: false,
			duplicate: false,
			delete: data.numberUse.value !== 0,
			print: false
		};
	}

	protected get optionsColumnsGrid(): DashboardOptions {
		return {
			columns: {
				displayed: {
					icons: false,
					selection: false
				}
			},
			actionButtons: {
				displayed: {
					add: false,
					edit: false,
					view: false,
					duplicate: false,
					delete: true,
					print: false
				}
			}
		};
	}

	protected get configColumns(): DashboardConfigColumns {
		return [
			{
				key: '_id',
				type: 'primaryKey',
				name: 'ID'
			},
			{
				key: 'label',
				type: 'string',
				name: 'Nom'
			},
			{
				key: 'numberUse',
				type: 'number',
				name: 'Nombre de produits'
			}
		];
	}
}

export default TagssTab;
