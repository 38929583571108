import PrintOptionTab from '@libs/customElement/PrintOptionTab';

import T_FormPrintOptions from '../../tpl/blocks/orderFormPrintOption.html';

class OrderProviderPrintOption extends PrintOptionTab {
	public static readonly tagName: string = 'ap-order-provider-print-options-tab';

	protected readonly KEY_SETTINGS = 'ORDER_PROVIDER.OPTIONS';

	protected eventChangeForm = 'options.print.**.change';

	constructor() {
		super();
		this.table = 'commands-provider';
	}

	protected getFormTemplate() {
		return T_FormPrintOptions;
	}

	protected setDefaultValue(value: { [key: string]: any }) {
		this.data = value;
	}

	public set data(data: { [key: string]: any }) {
		this.form!.setData({ options: data });
	}

	public get data() {
		return (this.form!.getData() as { [key: string]: any }).options;
	}

	public static register() {
		customElements.define(this.tagName, OrderProviderPrintOption);
	}
}

export default OrderProviderPrintOption;
