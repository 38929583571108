import '@css/customElements/lost-connexion.scss';

class ApLostConnexion extends HTMLElement {
	public static readonly tagName = 'ap-lost-connexion';

	public connectedCallback() {
		this.innerHTML = `
			<ap-icon name="signal-wifi-off/line"></ap-icon>
		`;

		this.setAttribute('tooltip', 'Connexion perdue');
	}

	public set serverConnected(serverConnected: boolean) {
		this.style.setProperty('display', serverConnected ? 'none' : null);
	}

	public static register() {
		customElements.define(ApLostConnexion.tagName, ApLostConnexion);
	}
}

export default ApLostConnexion;
