import tippy, { Instance, Props } from 'tippy.js';

import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

import CE_Quantity from '@libs/customElement/Quantity';

import '../../../css/customElements/productLine.scss';
import DecimalApps from '@js/libs/utils/Decimal';

class ProductLine extends HTMLElement {
	public static readonly tagName: string = 'ap-create-order-product-line';

	private _productID: string = '';
	private _quoteCustomer: string = '';

	private data: { price: number, quantity: number, total: number } = { price: 0, quantity: 0, total: 0 };

	private tooltipErrorInstance: Instance<Props> | null = null;

	public connectedCallback() {
		this.innerHTML = `
			<div class="col-right">
				<div class="image cell-loading"></div>
				<div class="reference cell-loading"></div>
				<div class="provider cell-loading"></div>
				<div class="label cell-loading"></div>
				<div class="price cell-loading"><span class="value">price<span></div>
			</div>
			<div class="col-left">
				<div class="quantity cell-loading">
					<span class="text">Quantité</span> <ap-quantity></ap-quantity>
				</div>
				<div class="total cell-loading">
					Total : <span class="price"></span>
				</div>
				<div class="close cell-loading">	
					<ap-button class="btn-icon btn-default" icon="close/line" confirmation></ap-button>
				</div>
			</div>
		`;

		const N_quantity = this.querySelector<CE_Quantity>('.quantity ap-quantity')!;
		const N_close = this.querySelector<CE_Quantity>('.close ap-button')!;

		N_quantity.addEventListener('change', () => {
			this.data.quantity = N_quantity.value;
			this.updateTotal();
		});

		N_close.addEventListener('click', () => {
			this.remove();
			this.dispatchEvent(new CustomEvent('delete'));
		});

		this.addEventListener('click', () => {
			this.dispatchEvent(new CustomEvent('select'));
		});
	}

	public setData(data: { id: string, reference: string, provider: string, label: string, quantity: number, maxQuantity?: number, quoteCustomer?: string }) {
		this._productID = data.id;
		this._quoteCustomer = data.quoteCustomer || '';

		const N_image = this.querySelector<HTMLElement>('.image')!;
		const N_reference = this.querySelector<HTMLElement>('.reference')!;
		const N_provider = this.querySelector<HTMLElement>('.provider')!;
		const N_label = this.querySelector<HTMLElement>('.label')!;

		const N_quantity = this.querySelector<CE_Quantity>('.quantity ap-quantity')!;
		const N_quantitytText = this.querySelector<HTMLElement>('.quantity .text')!;

		if (data.maxQuantity !== undefined) {
			N_quantitytText.innerHTML += ` (max : ${data.maxQuantity})`;
			N_quantity.max = data.maxQuantity;
		}

		this.data.quantity = N_quantity.value = data.quantity;

		const N_img = new ProductCellRenderer();
		N_img.init({ value: data.id, isOnlyImage: true });

		N_image.append(N_img.getGui());
		N_reference.innerHTML = data.reference;
		N_provider.innerHTML = data.provider;
		N_label.innerHTML = data.label;

		this.updateTotal();
	}

	public set error(value: boolean) {
		this.classList.toggle('error', value);
	}

	public setPrice(price: number) {
		const N_price = this.querySelector<HTMLElement>('.price .value')!;

		this.data.price = price;

		N_price!.innerHTML = DecimalApps.setDisplayNumber(price).setSuffixAndHumanizeNumber('€');

		this.tooltipErrorInstance?.destroy();

		if (isNaN(price)) {
			this.tooltipErrorInstance = tippy(N_price, {
				content: 'Aucun prix trouvé pour le fournisseur sélectionné'
			});
		}

		this.updateTotal();
	}

	private updateTotal() {
		const N_total = this.querySelector<HTMLElement>('.total .price')!;

		const quantity = DecimalApps.setDisplayNumber(this.data.quantity);
		const price = DecimalApps.setDisplayNumber(this.data.price);

		this.data.total = price.times(quantity).toNumber();

		N_total!.innerHTML = price.times(quantity).setSuffixAndHumanizeNumber('€');

		this.dispatchEvent(new CustomEvent('update.price'));
	}

	public get productID() {
		return this._productID;
	}

	public get quantity() {
		return this.data.quantity;
	}

	public get quoteCustomer() {
		return this._quoteCustomer;
	}

	public get totalPrice() {
		return this.data.total;
	}

	public static register() {
		customElements.define(ProductLine.tagName, ProductLine);
	}
}

export default ProductLine;
