class String {
	private value: string;

	constructor(value: string) {
		this.value = value;
	}

	public getValue() {
		return this.value;
	}

	public toDashboard() {
		return {
			formattedValue: this.value,
			value: this.value,
			quickFilter: this.value
		};
	}

	public toDocument() {
		return this.value;
	}

	public toSave() {
		return this.value;
	}

	public toPrint() {
		return this.value || '';
	}
}

export default String;
