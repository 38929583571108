// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/intervention/productEditInterventions.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_Product from '@services/Product/ProductService';

class ContentEdit extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null = null;

	constructor(item: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		item = _.cloneDeep(item);

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			if (item.reference) {
				item.reference = await S_Product.getInstance().getDataToSelect2ByID(item.reference);
			}

			this.form.setData(item);

			this.postInit();

			const N_save = this.element.querySelector('#save');

			N_save?.addEventListener('click', () => {
				this.resolve(this.form!.getData());
			});
		});
	}

	private init() {
		const N_product = this.element.querySelector('[name="reference"]') as CE_Select2;

		N_product.create(this.element);

		this.selectPostinit.product = N_product;
	}

	private postInit() {
		this.selectPostinit.product.postInit();

		this.selectPostinit.product.on('change', async (value) => {
			const data = await S_Product.getInstance().getById(value as string);

			this.form?.setDataByName('label', data.name);
			this.form?.setDataByName('time', data.time);
		});
	}
}

export default ContentEdit;
