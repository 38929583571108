import { LoggedUser, Module, Modules, Router, SocketIO, global } from '@autoprog/core-client';

import h from 'hyperscript';
import semver from 'semver';

import C_Changelog from './js/controllers/Changelog';
import M_modal from './js/modals/Changelog';
import T_Changelog from './tpl/changelog.html';

import S_ChangelogService from '@services/ChangelogService';
import S_UserAddon from '@services/User/UserAddonService';
import Utils from '@libs/utils/Utils';

import UtilsVersion from './js/utils/ConvertVersion';

import './css/changelog.scss';

class ChangelogModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {

	}

	public async init() {
		global.CHANGELOG = await S_ChangelogService.getInstance().getAll();

		//Récupération du numéro de la dernière version de l'application
		const versions = global.CHANGELOG.match(/([0-9]{1,}.[0-9]{1,}.[0-9]{1,}(-[0-9]{0,1})?)/gmi);
		global.VERSION = versions[0];

		const router = Router.getInstance();

		router.addRoute({
			url: '/module/changelog/home',
			controller: C_Changelog as any,
			tpl: T_Changelog,
			requireValidUser: true
		});
	}

	public async postInit() {
		const io = SocketIO.getInstance();

		io.on('changelog.version', (version: string) => {
			const N_button = document.querySelector('#button-update') as HTMLElement;

			if (version !== global.VERSION) {
				N_button?.classList.remove('d-none');
			}
		});

		if (!global.IS_MOBILE) {
			const N_navbar = document.querySelector('#navbar-right-content') as HTMLElement;

			const N_changelog = document.createElement('div');

			N_changelog.innerHTML = `
				<ap-button class="btn-navbar" icon="code-s-slash/line" tooltip="Changelog"></ap-button>
            `;

			N_changelog.addEventListener('click', () => {
				window.open(window.location.origin + '/#module/changelog/home');
			});

			const N_changeLogAlert = h('ap-button.btn-navbar.animate__animated.animate__flash.animate__infinite.text-danger.d-none#button-update', { attrs: { icon: 'alert/line', confirmation: 'true', tooltip: 'Mise à jour disponible' } });

			N_changeLogAlert.addEventListener('click', () => {
				location.reload();
			});

			N_navbar.appendChild(N_changeLogAlert);
			N_navbar.appendChild(N_changelog);

			const loggedUser = LoggedUser.getInstance();

			if (Utils.userID) {
				this.openChangeLog(Utils.userID);
			}

			loggedUser.on('login', () => {
				this.openChangeLog(Utils.userID);
			});
		}
	}

	private async openChangeLog(idUser: string) {
		let oldData: { [key: string]: string } = {
			_id: idUser
		};

		try {
			const data = await S_UserAddon.getInstance().getById(idUser);

			data.lastVersion = UtilsVersion.convert(data.lastVersion);
			const oldVersion = UtilsVersion.convert(global.VERSION);

			if (semver.lt(data.lastVersion, oldVersion)) {
				new M_modal(data.lastVersion).open();

				oldData = {
					...oldData,
					...data
				};
			}
		} catch (e) {
			new M_modal().open().then(() => {

			});
		}

		//On actualise la dernière version connue dans tous les cas
		oldData.lastVersion = global.VERSION;

		await S_UserAddon.getInstance().save(oldData);
	}
}

Modules.registerModule('Changelog', ChangelogModule);
