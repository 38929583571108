import { Module, Modules } from '@autoprog/core-client';

import CE_DeliveriesTab from './js/customElements/DeliveriesTab';

class DeliveriesModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_DeliveriesTab.register();
	}

	public async preInit() { }

	public async init() { }

	public async postInit() { }
}

Modules.registerModule('Bon-Livraison', DeliveriesModule);
