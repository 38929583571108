import { Module, Modules, Router } from '@autoprog/core-client';

import C_Materials from './js/controllers/TrackingProducts';

import T_database from '@tpl/database.html';

import CE_TrackingProductEventCard from './js/customElement/TrackingProductEventCard';
import CE_TrackingProductTab from './js/customElement/TrackingProductTab';

class TrackingProductModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_TrackingProductEventCard.register();
		CE_TrackingProductTab.register();
	}

	//TODO: à réintégrer dans le menu lorsque le module sera opérationnel
	public async preInit() {
		// const navigation = Navigation.getInstance();
		// navigation.addCategory({
		// 	name: 'Documents',
		// 	order: 100
		// });
		// navigation.addLink({
		// 	order: 5,
		// 	category: 'Documents',
		// 	name: 'Suivi de matériels',
		// 	type: 'module',
		// 	module: 'apps/tracking_products',
		// 	icon: 'arrow-right/line'
		// });
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/apps/tracking_products',
			controller: C_Materials as any,
			tpl: T_database,
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Suivi Materiel', TrackingProductModule);
