import SettingsController from '@modules/Settings/js/libs/SettingsController';

import Form from '@libs/Form';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Alert from '@libs/customElement/Alert';
import CE_Select from '@libs/customElement/Select';
import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';

import { Company } from '@modules/Settings/js/types/company';

import AccountsService from '../services/AccountsService';
import SettingAccountingService from '../services/Settings.AccountingService';
import TaxesService from '../services/TaxesService';

import Account from '../model/Account';
import TaxeModel from '../model/Taxe';

class AccountingTab extends SettingsController {
	private taxesService: TaxesService;
	private accountsService: AccountsService;
	private settingAccountingService: SettingAccountingService;

	private companyID: string;

	private form: Form;

	private _id: string;

	constructor(el: HTMLElement, data: Company) {
		super(el);

		this._id = '';

		this.companyID = data._id;

		this.taxesService = new TaxesService();
		this.accountsService = new AccountsService();
		this.settingAccountingService = new SettingAccountingService();

		this.form = new Form(this.el.querySelector<HTMLFormElement>('form')!);
	}

	protected async init() {
		this.initAccount();
		this.initTaxe();
		this.initSaveButton();
		this.initEvent();

		this.initDefaultAccount();

		this.getData();
	}

	private initAccount() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid-account')!;

		N_grid.setGridOptions({
			suppressContextMenu: true,
			defaultColDef: {
				resizable: true,
				suppressMovable: true
			},
			columnDefs: [{
				headerName: 'Numero de compte',
				field: 'number',
				width: 200,
				suppressSizeToFit: true
			}, {
				headerName: 'Libellé',
				field: 'label'
			}, {
				headerName: 'Type',
				field: 'type',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'Produit',
				field: 'forProduct',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'Taux horaire',
				field: 'forHourlyRate',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'TVA',
				field: 'forTaxe',
				width: 160,
				suppressSizeToFit: true
			}]
		});
	}

	private initTaxe() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid-taxe')!;

		N_grid.setGridOptions({
			columnDefs: [{
				headerName: 'Code',
				field: 'code',
				width: 100
			}, {
				headerName: 'Libellé',
				field: 'label'
			}, {
				headerName: 'Taux',
				field: 'rate',
				width: 80,
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					suffix: '%'
				}
			}, {
				headerName: 'Type',
				field: 'type',
				width: 100
			}, {
				headerName: 'Compte comptable',
				field: 'accountingAccount'
			}, {
				headerName: 'Mention particulière',
				field: 'specialMention'
			}],
			suppressContextMenu: true,
			defaultColDef: {
				resizable: true,
				suppressMovable: true,
				suppressMenu: true
			}
		});
	}

	private initSaveButton() {
		const N_grid_taxe = this.el.querySelector<CE_AgGrid>('#grid-taxe')!;

		const N_save = this.el.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', async () => {
			const data = this.form.getData();

			data.companyID = this.companyID;
			data._id = this._id;

			const dataTaxe = N_grid_taxe.value;

			for (const item of dataTaxe) {
				item.companyID = this.companyID;
				await this.taxesService.save(item);
			}

			this.settingAccountingService.save(data);

			this.resetSaveButton();
		});
	}

	private initEvent() {
		const N_enableAccounting = this.el.querySelector('[name="enabled"]') as HTMLInputElement;

		const N_defaultProduct = this.el.querySelector<CE_Select>('[name="defaultAccount.sale.product"]');
		const N_defaultHourlyRate = this.el.querySelector<CE_Select>('[name="defaultAccount.sale.hourlyRate"]');
		const N_defaultSale = this.el.querySelector<CE_Select>('[name="defaultTaxe.sale"]');
		const N_defaultPurchase = this.el.querySelector<CE_Select>('[name="defaultTaxe.purchase"]');

		N_enableAccounting.addEventListener('change', () => {
			this.updateButtonSave();
			this.updateAlertEnabled();
		});

		N_defaultProduct?.addEventListener('change', () => {
			this.updateButtonSave();
		});

		N_defaultHourlyRate?.addEventListener('change', () => {
			this.updateButtonSave();
		});

		N_defaultSale?.addEventListener('change', () => {
			this.updateButtonSave();
		});

		N_defaultPurchase?.addEventListener('change', () => {
			this.updateButtonSave();
		});
	}

	private async getData() {
		const accounts = await this.accountsService.getDataToAgGrid();
		const taxes = await this.taxesService.getDataToAgGridByCompanyID(this.companyID);

		const settings = (await this.settingAccountingService.getDataByCompany(this.companyID)) || {};

		const N_grid_account = this.el.querySelector<CE_AgGrid>('#grid-account')!;
		const N_grid_taxe = this.el.querySelector<CE_AgGrid>('#grid-taxe')!;

		N_grid_account.value = accounts?.rowData || [];

		this.updateCacheTaxe();

		N_grid_taxe.value = taxes?.rowData || [];

		this._id = settings._id;

		this.form.setData(settings);

		this.updateAlertEnabled();
	}

	private initDefaultAccount() {
		const N_defaultProduct = this.el.querySelector<CE_Select>('[name="defaultAccount.sale.product"]');
		const N_defaultHourlyRate = this.el.querySelector<CE_Select>('[name="defaultAccount.sale.hourlyRate"]');

		const N_defaultSale = this.el.querySelector<CE_Select>('[name="defaultTaxe.sale"]');
		const N_defaultPurchase = this.el.querySelector<CE_Select>('[name="defaultTaxe.purchase"]');

		N_defaultProduct!.options = {
			ajax: {
				url: this.accountsService.createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ forProduct: true })
					};
				}
			}
		};

		N_defaultHourlyRate!.options = {
			ajax: {
				url: this.accountsService.createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ forHourlyRate: true })
					};
				}
			}
		};

		N_defaultSale!.options = {
			ajax: {
				url: this.taxesService.createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ type: 'sale' })
					};
				}
			}
		};

		N_defaultPurchase!.options = {
			ajax: {
				url: this.taxesService.createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ type: 'purchase' })
					};
				}
			}
		};
	}

	private updateButtonSave() {
		const N_save = this.el.querySelector<HTMLButtonElement>('#save')!;
		N_save.disabled = false;
	}

	private updateAlertEnabled() {
		const N_alert = this.el.querySelector<CE_Alert>('#alert-disabled-accounting')!;
		N_alert.toggleVisibility(this.form!.getDataByName('enabled') === false);
	}

	private updateCacheTaxe() {
		const N_grid_account = this.el.querySelector<CE_AgGrid>('#grid-account')!;
		const cache: { [key: string]: string } = {};
		const data = N_grid_account.value;

		for (const item of data) {
			const tmp = new Account(item).toSelect();
			cache[tmp.id] = tmp.text;
		}

		TaxeModel.setCache('accountingAccount', cache);
	}

	private resetSaveButton() {
		const N_save = this.el.querySelector<HTMLButtonElement>('#save')!;
		N_save.disabled = true;
	}
}

export default AccountingTab;
