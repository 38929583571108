import { GridOptions } from '@ag-grid-enterprise/all-modules';
import removeAccents from 'remove-accents';

import '@css/customElements/alert.scss';

import CE_Button from '@libs/customElement/Button';

import '../../../css/customElement/select-categorie.scss';

import S_Category from '@services/Product/ProductCategoryService';

class SelectCategorie extends HTMLElement {
	public static readonly tagName: string = 'ap-select-categories';

	private N_modal: HTMLElement | null = null;
	private _children: { [key: string]: any } = {};
	private _parent: { [key: string]: any } = {};
	private gridOptions: GridOptions = {};

	private search: string = '';

	private openValue: string[] = [];

	public connectedCallback() {
		this.innerHTML = '<ap-button type="default">Catégorie</ap-button>';

		const N_button = this.querySelector<CE_Button>('ap-button')!;

		N_button.addEventListener('click', async () => {
			this.open();

			const N_content = this.N_modal?.querySelector<HTMLElement>('.content')!;
			N_content.classList.remove('active');

			this.openValue = this.getCurrentValue();
			this.updateSelectedValue();
		});
	}

	public disconnectedCallback() {
		this.N_modal?.remove();
	}

	private async getData() {
		const tmp = await S_Category.getInstance().getAll();

		this._children = {};
		this._children = {};

		for (const item of tmp) {
			this._children[item.parent] = this._children[item.parent] || [];
			this._children[item.parent].push(item);

			this._parent[item._id] = item.parent;
		}
	}

	private open() {
		this.N_modal?.classList.add('open');
	}

	private close() {
		this.N_modal?.classList.remove('open');

		const N_search = this.N_modal!.querySelector<HTMLInputElement>('#search')!;
		N_search.value = '';
		this.search = '';

		this.displaySearchResult('.left', N_search.value);
	}

	private init() {
		this.N_modal = document.createElement('div');

		this.N_modal.classList.add('select-categorie-modal');
		this.N_modal.innerHTML = `
			<div class="title">
				Catégories
				<ap-button class="btn-icon btn-default" id="close" icon="close/line"></ap-button>
			</div>
			<input class="ap-input" id="search" placeHolder="Recherche"/>
			<div class="content">
				<div class="left"></div>
				<div class="right"></div>
			</div>
		`;

		const N_close = this.N_modal.querySelector<CE_Button>('#close')!;
		const N_search = this.N_modal.querySelector<HTMLInputElement>('#search')!;
		const N_content = this.N_modal?.querySelector<HTMLElement>('.content')!;

		N_close.addEventListener('click', () => {
			this.close();
		});

		N_search.addEventListener('input', () => {
			if (N_content.classList.contains('active')) {
				this.displaySearchResult('.right', N_search.value);
			} else {
				this.search = N_search.value;
				this.displaySearchResult('.left', N_search.value);
			}
		});

		document.body.append(this.N_modal);

		this.renderCategory();
	}

	public async setGridOptions(gridOptions: GridOptions) {
		this.gridOptions = gridOptions;

		this.gridOptions.api?.addEventListener('filterChanged', () => {
			this.updateButton();
		});

		await this.getData();

		this.init();
	}

	private updateButton() {
		const N_button = this.querySelector<CE_Button>('ap-button')!;
		this.openValue = this.getCurrentValue();
		N_button.classList.toggle('active', this.openValue.length !== 0);
	}

	private displaySearchResult(idContainer: string, value: string) {
		if (value) {
			const search = removeAccents(value).toUpperCase();

			const N_itemList = this.N_modal!.querySelectorAll<HTMLElement>(`${idContainer} [data-id][data-label]`);

			for (const N_item of N_itemList) {
				const label = removeAccents(N_item.dataset.label || '').toUpperCase();

				N_item.classList.toggle('d-none', !label.includes(search));
			}
		} else {
			const N_itemList = this.N_modal!.querySelectorAll<HTMLElement>(`${idContainer} [data-id]`);

			for (const N_item of N_itemList) {
				N_item.classList.remove('d-none');
			}
		}
	}

	private renderCategory() {
		const N_left = this.N_modal?.querySelector<HTMLElement>('.left')!;
		N_left.innerHTML = '';

		let data: { [key: string]: string }[] = this._children[''] || [];
		data = data.sort((itemA, itemB) => {
			if (itemA.label > itemB.label) {
				return 1;
			}
			if (itemA.label < itemB.label) {
				return -1;
			}
			return 0;
		});

		if (data.length) {
			const N_all = document.createElement('div');
			N_all.classList.add('item', 'item-title');

			if (this.openValue.length === 0) {
				N_all.classList.add('selected');
			}

			N_all.dataset.id = '';
			N_all.dataset.search = '';
			N_all.innerHTML = 'Voir tout';

			N_all.addEventListener('click', () => {
				this.updateGrid('');
			});

			N_left.append(N_all);
		}

		for (const item of data) {
			const hasChildren = (this._children[item._id] || []).length !== 0;

			const N_item = document.createElement('div');
			N_item.classList.add('item');
			N_item.innerHTML = item.label;
			N_item.dataset.id = item._id;
			N_item.dataset.label = item.label;

			if (this.openValue.includes(item._id)) {
				N_item.classList.add('selected');
			}

			if (hasChildren) {
				N_item.innerHTML += '<ap-icon name="arrow-right-s/line"></ap-icon>';
			}

			N_item.addEventListener('click', () => {
				if (hasChildren) {
					this.renderSubCategory(item);
				} else {
					this.updateGrid(item._id);
				}
			});

			N_left.append(N_item);
		}
	}

	private renderSubCategory(item: { [key: string]: string }) {
		const N_search = this.N_modal?.querySelector<HTMLInputElement>('#search')!;
		this.search = N_search.value;
		N_search.value = '';

		const N_content = this.N_modal?.querySelector<HTMLElement>('.content')!;
		N_content.classList.add('active');

		const N_right = this.N_modal?.querySelector<HTMLElement>('.right')!;
		N_right.innerHTML = '';

		const N_back = document.createElement('div');
		N_back.classList.add('item-title', 'back');
		N_back.innerHTML = `<ap-icon name="arrow-left/line"></ap-icon>${item.label}`;
		N_back.addEventListener('click', () => {
			N_content.classList.remove('active');
			N_search.value = this.search;

			this.displaySearchResult('.left', N_search.value);
		});
		N_right.append(N_back);

		this.renderContent(item._id, item.label);
	}

	private renderContent(idParent: string, label: string) {
		const N_right = this.N_modal?.querySelector<HTMLElement>('.right')!;

		let data: { [key: string]: string }[] = this._children[idParent] || [];
		data = data.sort((itemA, itemB) => {
			if (itemA.label > itemB.label) {
				return 1;
			}
			if (itemA.label < itemB.label) {
				return -1;
			}
			return 0;
		});

		if (data.length) {
			const N_right = this.N_modal?.querySelector<HTMLElement>('.right')!;
			const N_all = document.createElement('div');
			N_all.classList.add('item');

			if (this.openValue[this.openValue.length - 1] === idParent) {
				N_all.classList.add('selected');
			}

			N_all.dataset.id = idParent;
			N_all.dataset.search = label;
			N_all.innerHTML = 'Voir tout';

			N_all.addEventListener('click', () => {
				this.updateGrid(idParent);
			});

			N_right.append(N_all);
		}

		for (const item of data) {
			const N_item = document.createElement('div');

			if ((this._children[item._id] || []).length) {
				N_item.classList.add('item-title');
			} else {
				N_item.classList.add('item');
				N_item.dataset.id = item._id;
				N_item.dataset.label = item.label;

				if (this.openValue.includes(item._id)) {
					N_item.classList.add('selected');
				}
			}

			N_item.innerHTML = item.label;

			N_item.addEventListener('click', () => {
				this.updateGrid(item._id);
			});

			N_right.append(N_item);

			this.renderContent(item._id, item.label);
		}
	}

	private getParent(id: string, res: string[] = []) {
		if (this._parent[id]) {
			this.getParent(this._parent[id], res);
		}

		res.push(id);
		return res;
	}

	private updateGrid(id: string) {
		const filterInstance = this.gridOptions.api?.getFilterInstance('categoryIDs');

		filterInstance?.setModel({
			type: 'contains',
			filter: this.getParent(id).join('•')
		});

		this.gridOptions.api?.onFilterChanged();

		this.close();
	}

	private getCurrentValue() {
		const filterInstance = this.gridOptions.api?.getFilterInstance('categoryIDs');

		const tmp = filterInstance?.getModel();

		if (tmp?.filter) {
			return tmp.filter.split('•');
		}

		return [];
	}

	private updateSelectedValue() {
		const N_selectedList = this.N_modal!.querySelectorAll<HTMLElement>('.selected');

		for (const N_el of N_selectedList) {
			N_el?.classList.remove('selected');
		}

		if (this.openValue.length) {
			const N_selected = this.N_modal!.querySelector<HTMLElement>(`[data-id="${this.openValue[0]}"]`);
			N_selected?.classList.add('selected');
		}
	}

	public static register() {
		customElements.define(SelectCategorie.tagName, SelectCategorie);
	}
}

export default SelectCategorie;
