import { Module, Modules, Router } from '@autoprog/core-client';

import Navigation from '@libs/Navigation';

import C_ImportExport from './js/controllers/ImportExportController';
import T_tabs from './tpl/tabs.html';

import C_Maintenance from '@js/controllers/MaintenanceController';
import T_Maintenance from '@tpl/maintenance.html';

import './css/import-export.scss';

class UpdaterModule extends Module {
	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Données',
			order: 0
		});
		navigation.addLink({
			order: 10,
			category: 'Données',
			name: 'Import/Export de données',
			type: 'module',
			module: 'import_export',
			permissions: ['IMPORT', 'EXPORT'],
			icon: 'arrow-left-right/line'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/import_export',
			controller: C_Maintenance,
			data: {
				title: 'Page indisponible',
				text: '<p>Nous nous excusons pour la gêne occasionnée. </br >Nous travaillons pour réintégrer la page au plus vite !</p>'
			},
			tpl: T_Maintenance,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/import_export',
			controller: C_ImportExport as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/import_export/:tabs',
			controller: C_ImportExport as any,
			tpl: T_tabs,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Export', UpdaterModule);
