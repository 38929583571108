import { Alert, LoggedUser, toaster, utils } from '@autoprog/core-client';

import h from 'hyperscript';

import C_Quote from '@modules/Quotes/js/controllers/AddQuotes';

import M_SelectQuotes from '@modules/Quotes/js/modals/SelectQuotes';

import M_EditQuote from '../modals/EditQuote';

import CE_Aggrid from '@libs/customElement/AgGrid';

import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';

import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';

import Decimal from '@libs/utils/Decimal';
import DecimalModel from '@libs/model/_app/Decimal';

export type updateFormEvent = {
	price: Decimal
};

class QuotesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private _idCustomer: () => string = () => { return ''; };
	private _checkDelete: (data: { [key: string]: any }) => Promise<void> = async () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || QuotesTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-edit/line" icon-active="file-edit/fill" text="Devis" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Devis
				<div class="page-tabs-title-right">
					<ap-button type="add" id="add" permission="ORDERS._CUSTOMERS.ADD"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButton();
	}

	public setCheckDelete(cb: (data: { [key: string]: any }) => Promise<void>) {
		this._checkDelete = cb;
	}

	public setCustomer(cb: () => string) {
		this._idCustomer = cb;
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucun Devis' },
			suppressRowClickSelection: true,
			columnDefs: [
				{
					headerName: 'Numéro Devis',
					field: 'quoteID'
				}, {
					headerName: 'Objet',
					field: 'label'
				}, {
					headerName: 'Sites',
					field: 'sites'
				}, {
					headerName: 'Date commande',
					field: 'date'
				}, {
					headerName: 'Date saisie',
					field: 'entryDate'
				}, {
					headerName: 'Date livraison',
					field: 'deliveryDate'
				}, {
					headerName: 'Montant',
					field: 'price',
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 2,
						suffix: '€'
					}
				}, {
					headerName: 'Personnes informées',
					field: 'constributors'
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 180,
					cellRenderer: (params: any) => {
						if (!params.node.rowPinned) {
							const N_edit = h('ap-button.btn-action-aggrid.btn-default', { attrs: { tooltip: 'Modifier l\'enregistrement', icon: 'file-text/line', permission: 'ORDERS._CUSTOMERS.EDIT' } });

							N_edit.addEventListener('click', async () => {
								new M_EditQuote(this._idCustomer()).setEditData(params.data._rowData_).open().then(async (data) => {
									await S_C_Order.getInstance().save(data, { idOrder: this.idOrder, type: 'updateQuote' });
									this.dispatchEvent(new CustomEvent('silent-refresh'));
								});
							});

							const N_view = h('ap-button.btn-action-aggrid', { attrs: { tooltip: 'Ouvrir', type: 'open', permission: 'QUOTES.OPEN' } });

							N_view.addEventListener('click', async () => {
								C_Quote.open(params.data._rowData_.quoteID);
							});

							const N_editQuote = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', permission: 'QUOTES.FORCE_EDIT' } });

							N_editQuote.addEventListener('click', async () => {
								C_Quote.open(params.data._rowData_.quoteID, { forceEdit: true });
							});

							const N_delete = h('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete', permission: 'ORDERS._CUSTOMERS.EDIT' } });

							N_delete.addEventListener('click', async () => {
								try {
									await this._checkDelete(params.data._rowData_);

									await S_C_Order.getInstance().deleteQuoteToOrder(this.idOrder, params.data._rowData_.quoteID);

									params.api.updateRowData({
										remove: [params.data]
									});

									toaster.success('Sauvegarde réussie');
								} catch (e) {
									Alert.warning('Impossible de supprimer le devis', '<br><br>' + (e as Error).message);
								}
							});

							const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit, N_view, N_editQuote, N_delete);

							return N_div;
						}

						return '';
					}
				}
			],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			getContextMenuItems: (params: any) => {
				const result = [];

				if (params.node) {
					result.push({
						name: `N° ${params.node.data.quoteID.formattedValue}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					});

					if (LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.EDIT')) {
						result.push({
							name: 'Modifier l\'enregistrement',
							icon: '<i class="icon icon-ri-file-text-line"></i>',
							action: () => {
								new M_EditQuote(this._idCustomer()).setEditData(params.data._rowData_).open().then(async (data) => {
									await S_C_Order.getInstance().save(data, { idOrder: this.idOrder, type: 'updateQuote' });
									this.dispatchEvent(new CustomEvent('silent-refresh'));
								});
							}
						});
					}

					if (LoggedUser.getInstance().hasPermission('QUOTES.OPEN')) {
						result.push({
							name: 'Voir',
							icon: '<i class="icon icon-eye"></i>',
							action: () => {
								C_Quote.open(params.node.data._rowData_.quoteID);
							}
						});
					}

					if (LoggedUser.getInstance().hasPermission('QUOTES.FORCE_EDIT')) {
						result.push({
							name: 'Éditer',
							icon: '<i class="icon icon-edit"></i>',
							action: () => {
								C_Quote.open(params.node.data._rowData_.quoteID, { forceEdit: true });
							}
						});
					}

					result.push('separator');
				}

				if (LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.ADD')) {
					result.push({
						name: 'Ajouter',
						icon: '<i class="icon icon-ri-add-line"></i>',
						action: async () => {
							const customer = await S_Customer.getInstance().getDisplayRefByID(this._idCustomer());
							new M_SelectQuotes(customer).open().then((idQuote) => {
								new M_EditQuote(this._idCustomer()).setAddData(idQuote).open().then(async (data) => {
									await S_C_Order.getInstance().save(data, { idOrder: this.idOrder, type: 'updateQuote' });
									this.dispatchEvent(new CustomEvent('silent-refresh'));
								});
							});
						}
					});
				}

				return result;
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector<HTMLElement>('#number')!;

		if (this.N_grid!.isLoad) {
			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		} else {
			N_number.classList.add('d-none');
		}
	}

	private initButton() {
		const N_btn = document.querySelector(`#${this.idTab} #add`) as HTMLButtonElement;

		N_btn.addEventListener('click', async () => {
			const customer = await S_Customer.getInstance().getDisplayRefByID(this._idCustomer());

			new M_SelectQuotes(customer).open().then((idQuote) => {
				new M_EditQuote(this._idCustomer()).setAddData(idQuote).open().then(async (data) => {
					await S_C_Order.getInstance().save(data, { idOrder: this.idOrder, type: 'updateQuote' });
					this.dispatchEvent(new CustomEvent('silent-refresh'));
				});
			});
		});
	}

	private initPinnedBottom() {
		const sum = this.sum;

		this.N_grid!.pinnedBottomValue = [{ price: new DecimalModel(sum).toDashboard() }];

		this.dispatchEvent(new CustomEvent('update.form', { detail: { price: sum } }));
	}

	public get sum() {
		let sum = new Decimal(0);

		this.N_grid?.forEachNode((node) => {
			sum = sum.plus(Decimal.setDisplayNumber(node.data.price.value));
		});

		return sum.toDecimalPlaces(2);
	}

	public set data(data: any) {
		this.N_grid!.value = data;
		this.initPinnedBottom();
	}

	public get data(): any {
		return this.N_grid!.value;
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public static register() {
		customElements.define(QuotesTab.tagName, QuotesTab);
	}
}

export default QuotesTab;
