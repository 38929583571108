module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card-mobile flex-column">\n\n    <div>\n        ' +
((__t = (data.lastname || '&nbsp;')) == null ? '' : __t) +
' ' +
((__t = (data.firstname || '&nbsp;')) == null ? '' : __t) +
'\n    </div>\n\n    <div>\n        ' +
((__t = (data.tel1 || '&nbsp;')) == null ? '' : __t) +
' / ' +
((__t = (data.tel2 || '&nbsp;')) == null ? '' : __t) +
'\n    </div>\n\n    <div>\n        ' +
((__t = (data.mail || '&nbsp;')) == null ? '' : __t) +
'\n    </div>\n\n</div>';

}
return __p
}