import h from 'hyperscript';

import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/createOrderByProduct.html';

import '../../css/createOrderByProduct.scss';

import S_C_Order from '@services/Customer/CustomerOrderService';
import S_P_Orders from '@services/Provider/ProviderOrderService';
import S_Pdt_Brand from '@services/Product/BrandService';
import S_Pdt_Provider from '@services/Product/ProductProviderService';
import S_Products from '@services/Product/ProductService';
import S_Provider from '@services/Provider/ProviderService';
import S_Quote from '@services/QuoteService';

import C_OrderProvider from '../controllers/Orders.Provider';
import DecimalApps from '@libs/utils/Decimal';

import CE_Select from '@libs/customElement/Select';

import CE_BrandLine from '../customElements/createOrderByProduct/BrandLine';
import CE_ProductLine from '../customElements/createOrderByProduct/ProductLine';

import CE_DetailProduct from '@modules/Products/js/libs/customElement/viewProduct/DetailProduct';
import CE_ProviderProduct from '@modules/Products/js/libs/customElement/viewProduct/ProviderProduct';

type data = {
	productID: string
	quantity: number
	maxQuantity?: number
	quoteCustomer?: string
};

class CreateOrderByProduct extends Modal {
	private defaultProvider: string;
	private idOrderCustomer: string | undefined;

	private tmpListProductLoading: (CE_BrandLine | CE_ProductLine)[];

	constructor(data: data[], idOrderCustomer?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.defaultProvider = '';
		this.idOrderCustomer = idOrderCustomer;
		this.tmpListProductLoading = [];

		this.on('opened', async () => {
			this.element.classList.add('loading');
			this.loadingListProduct();
			await this.renderListProduct(data);
			await this.initSelect();
			await this.updatePrice();
			this.updateListOrder();

			if (this.idOrderCustomer) {
				const N_addon_title = this.element.querySelector<HTMLElement>('.modal-title-addon');
				const orderText = await S_C_Order.getInstance().getDisplayRefByID(this.idOrderCustomer);
				N_addon_title!.innerHTML = '&bull; ' + orderText;
			}

			const N_provider = this.element.querySelector<CE_Select>('#provider');
			const N_order = this.element.querySelector<CE_Select>('#order');
			const N_label = this.element.querySelector<HTMLInputElement>('#label');
			const N_comment = this.element.querySelector<HTMLTextAreaElement>('#comment');

			const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

			N_save.addEventListener('click', () => {
				const products: {
					productID: string,
					quantity: number,
					quoteCustomer: string
				}[] = [];

				const N_lines = this.element.querySelectorAll<CE_ProductLine>('ap-create-order-product-line');

				for (const N_el of N_lines) {
					if (N_el.quantity) {
						products.push({
							productID: N_el.productID,
							quantity: N_el.quantity,
							quoteCustomer: N_el.quoteCustomer
						});
					}
				}

				const res = {
					orderCustomer: this.idOrderCustomer,
					provider: N_provider?.value,
					label: N_label?.value,
					comment: N_comment?.value,
					products
				};

				this.ignoreChangeRoute = true;

				const idOrder = N_order?.value === '-1' ? null : (N_order!.value as string);

				C_OrderProvider.open(idOrder, res);
				this.resolve();
			});

			this.element.classList.remove('loading');
		});
	}

	private async initSelect() {
		const N_provider = this.element.querySelector<CE_Select>('#provider')!;

		N_provider.options = {
			ajax: {
				url: S_Provider.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search
					};
				}
			}
		};

		N_provider.addEventListener('change', () => {
			this.updatePrice();
			this.updateListOrder();
		});

		N_provider.value = await S_Provider.getInstance().getDataToSelect2ByID(this.defaultProvider);

		const N_order = this.element.querySelector<CE_Select>('#order')!;

		N_order.addEventListener('change', async () => {
			this.updateLabel();
		});
	}

	private loadingListProduct() {
		const N_containerProduct = this.element.querySelector<HTMLElement>('.container-product')!;

		const N_brand = h<CE_BrandLine>('ap-create-order-brand-line');
		N_containerProduct.append(N_brand);

		this.tmpListProductLoading.push(N_brand);

		for (let i = 0; i < 10; i++) {
			const N_product = h<CE_ProductLine>('ap-create-order-product-line');
			N_containerProduct.append(N_product);

			this.tmpListProductLoading.push(N_product);
		}
	}

	private async renderListProduct(data: data[]) {
		const N_containerProduct = this.element.querySelector<HTMLElement>('.container-product')!;

		const groupByBrand: { [quote: string]: { [brand: string]: any[] } } = {};
		const itemByProduct: { [quote: string]: { [productID: string]: data } } = {};

		for (const item of data) {
			const quoteCustomer = item.quoteCustomer || '';

			itemByProduct[quoteCustomer] = itemByProduct[quoteCustomer] || {};
			itemByProduct[quoteCustomer][item.productID] = item;

			const product = await S_Products.getInstance().getById(item.productID);

			groupByBrand[quoteCustomer] = groupByBrand[quoteCustomer] || {};
			groupByBrand[quoteCustomer][product.brand] = groupByBrand[quoteCustomer][product.brand] || [];
			groupByBrand[quoteCustomer][product.brand].push(product);
		}

		const quotesList = Object.keys(groupByBrand).sort();

		for (const quote of quotesList) {
			if (quote) {
				const quoteDisplay = (await S_Quote.getInstance().getDisplayRefByID(quote)) || 'Aucun Devis';
				const N_quote = h('div.title-quote', quoteDisplay);
				N_containerProduct.append(N_quote);
			}

			const brandList = Object.keys(groupByBrand[quote]).sort();
			for (const brand of brandList) {
				const productList = groupByBrand[quote][brand].sort((itemA, itemB) => {
					if (itemA.reference < itemB.reference) return -1;
					if (itemA.reference > itemB.reference) return 1;
					return 0;
				});

				const N_brand = h<CE_BrandLine>('ap-create-order-brand-line');
				N_containerProduct.append(N_brand);

				const brandTxt = await S_Pdt_Brand.getInstance().getDisplayRefByID(brand);
				N_brand.setData({ brand: brandTxt || 'Aucune marque', number: groupByBrand[quote][brand].length });

				for (const product of productList) {
					const N_product = h<CE_ProductLine>('ap-create-order-product-line');
					N_containerProduct.append(N_product);

					let pdtProvider: { [key: string]: any } = {};

					try {
						const pdtProviderId = await S_Pdt_Provider.getInstance().getDefaultByProduct(product._id);
						pdtProvider = (await S_Pdt_Provider.getInstance().getDataToModal(pdtProviderId)).data?.data;

						if (!this.defaultProvider) {
							this.defaultProvider = pdtProvider?.providerID.id;
						}
					} catch (e) {

					}

					N_product.setData({
						id: product._id,
						reference: product.reference || '',
						label: product.label || '',
						provider: pdtProvider?.providerID?.text || '',
						quantity: itemByProduct[quote][product._id].quantity,
						maxQuantity: this.idOrderCustomer ? itemByProduct[quote][product._id].maxQuantity : undefined,
						quoteCustomer: quote
					});

					N_product.addEventListener('delete', () => {
						N_brand.setData({ number: N_brand.number - 1 });
						this.updateTotalPrice();
					});

					N_product.addEventListener('select', () => {
						if (!N_product.classList.contains('selected')) {
							const N_productList = N_containerProduct.querySelectorAll<HTMLElement>('ap-create-order-product-line');

							for (const N_el of N_productList) {
								N_el.classList.remove('selected');
							}

							N_product.classList.add('selected');

							this.renderProduct(N_product.productID, pdtProvider);
						}
					});

					N_product.addEventListener('update.price', () => {
						this.updateTotalPrice();
					});
				}
			}
		}

		for (const N_el of this.tmpListProductLoading) {
			N_el.remove();
		}

		this.updateTotalPrice();
	}

	private async renderProduct(productID: string, pdtProviderDefault: { [key: string]: any }) {
		const N_displayProducts = this.element.querySelector<HTMLElement>('.display-products')!;
		N_displayProducts?.classList.remove('no-value');
		N_displayProducts.classList.add('loading');

		const N_productProviders = this.element.querySelector<HTMLElement>('.productProviders')!;
		const N_detail = this.element.querySelector<CE_DetailProduct>('ap-view-product-detail')!;

		N_productProviders.innerHTML = '';

		const tmpViewProduct: CE_ProviderProduct[] = [];

		for (let i = 0; i < 2; i++) {
			const N_el = h<CE_ProviderProduct>('ap-view-product-provider');
			tmpViewProduct.push(N_el);
			N_productProviders.appendChild(N_el);
		}

		const { data } = await S_Products.getInstance().getDataToModal(productID);

		const product = data.data;

		N_detail.data = {
			_id: product._id,
			label: product.label,
			reference: product.reference,
			internalCode: product.internalCode,
			brand: product.brand.text,
			price: product.price,
			defaultProvider: {
				purchasePrice: pdtProviderDefault.purchasePrice,
				costPrice: pdtProviderDefault.costPrice,
				recommendedSellPrice: pdtProviderDefault.recommendedSellPrice
			}
		};

		const productProviderIds = await S_Pdt_Provider.getInstance().getByProduct(productID);

		let index = 0;
		for (const id of productProviderIds) {
			const { data } = await S_Pdt_Provider.getInstance().getDataToModal(id);

			const productProvider = data.data;
			const N_el = tmpViewProduct[index] || h<CE_ProviderProduct>('ap-view-product-provider');

			N_productProviders.appendChild(N_el);

			N_el.data = {
				isDefault: productProvider.isDefault,
				provider: productProvider.providerID.text,
				reference: productProvider.provider_reference,
				label: productProvider.provider_label,
				public_price: productProvider.publicPrice,
				discount: productProvider.discount,
				purchasePrice: productProvider.purchasePrice,
				shipPrice: productProvider.shipPrice,
				costPrice: productProvider.costPrice,
				marginRate: productProvider.marginRate,
				recommendedSellPrice: productProvider.recommendedSellPrice
			};

			index++;
		}

		for (let i = index; i < tmpViewProduct.length; i++) {
			tmpViewProduct[i].remove();
		}

		N_displayProducts.classList.remove('loading');
	}

	private async updatePrice() {
		const N_provider = this.element.querySelector<CE_Select>('#provider')!;

		const N_lines = this.element.querySelectorAll<CE_ProductLine>('ap-create-order-product-line');

		for (const N_el of N_lines) {
			try {
				if (N_provider.value && N_el.productID) {
					const productProviderID = await S_Pdt_Provider.getInstance().getByProductAndProvider(N_el.productID, N_provider.value as string);
					const { data } = await S_Pdt_Provider.getInstance().getDataToModal(productProviderID);

					const productProvider = data.data;

					N_el.error = false;
					N_el.setPrice(productProvider.purchasePrice);
				} else {
					throw Error('Fournisseur et/ou produit non renseigné');
				}
			} catch (e) {
				N_el.error = true;
				N_el.setPrice(NaN);
			}
		}
	}

	private updateTotalPrice() {
		const N_lines = this.element.querySelectorAll<CE_ProductLine>('ap-create-order-product-line');

		let sum = new DecimalApps(0);

		for (const N_el of N_lines) {
			sum = sum.plus(DecimalApps.setDisplayNumber(N_el.totalPrice));
		}

		const N_save = this.element.querySelector<HTMLElement>('#save')!;

		N_save.innerHTML = `Valider (${sum.setSuffixAndHumanizeNumber('€')})`;
	}

	private async updateListOrder() {
		const N_provider = this.element.querySelector<CE_Select>('#provider')!;

		const N_order = this.element.querySelector<CE_Select>('#order')!;

		const data = await S_P_Orders.getInstance().getLastOrderProvider(N_provider.value as string);

		N_order.options = {
			data,
			dataFixBottom: [{ id: '-1', text: 'Nouvelle Commande' }]
		};

		N_order.value = { id: '-1', text: 'Nouvelle Commande' };

		this.updateLabel();
	}

	private async updateLabel() {
		const N_order = this.element.querySelector<CE_Select>('#order')!;
		const N_label = this.element.querySelector<CE_Select>('#label')!;

		const order = await S_P_Orders.getInstance().getById(N_order.value as string);

		N_label.value = order?.infos?.label || '';
	}
}

export default CreateOrderByProduct;
