import T_modal from '../../../tpl/modals/editPage/detailsOrder.html';

import Modal from '@libs/EditPageModal';

type DetailsOrderData = {
	label: string
	description: string
};

class DetailsOrder extends Modal<DetailsOrderData> {
	constructor(data: DetailsOrderData) {
		super(data, T_modal);
	}

	protected postInit() {
		super.postInit();

		const N_label = this.element.querySelector<HTMLInputElement>('[name="label"]')!;

		N_label.addEventListener('input', () => {
			this.updateButtonSave();
		});

		this.updateButtonSave();
	}

	private updateButtonSave() {
		const label = this.form?.getDataByName('label') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !label;
	}
}

export default DetailsOrder;
