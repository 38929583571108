import GenericService from './GenericService';
class StockInventoryService extends GenericService {
	private static instance: StockInventoryService | null = null;

	constructor() {
		super('stock_inventories');
	}

	public static getInstance(): StockInventoryService {
		if (!StockInventoryService.instance) {
			StockInventoryService.instance = new StockInventoryService();
		}

		return StockInventoryService.instance;
	}

	public async getLastInventory(idStock: string) {
		return this.get_function('getLastInventory', { idStock });
	}
}

export default StockInventoryService;
