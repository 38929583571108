import Alert from '@libs/Alert';
import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/viewProduct.html';

import S_Product from '@services/Product/ProductService';

import FormControllerPageID from '@libs/FormControllerPageID';

import M_AddEditProduct from '../modals/AddEditProduct';

import CE_Alert from '@libs/customElement/Alert';
import CE_Button from '@libs/customElement/Button';
import CE_EditCategoriesReadonly from '../libs/customElement/EditCategoriesReadonly';
import CE_EditProductProviderReadonly from '../libs/customElement/EditProductProviderReadonly';
import CE_EditProductStockReadonly from '../libs/customElement/EditProductStockReadonly';
import CE_EditTagsReadonly from '../libs/customElement/EditTagsReadonly';
import CE_FilesReadonly from '@libs/customElement/FilesReadonly';
import CE_ProductImagePreviewer from '../libs/customElement/ProductImagePreviewer';
import CE_Traceability from '@libs/customElement/Traceability';

import '../../css/viewproduct.scss';
import DecimalApps from '@js/libs/utils/Decimal';
import moment from 'moment';

class ViewProduct extends Modal {
	private id: string;
	private update: boolean;

	private internalCode: string;

	constructor(id: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id = id;
		this.update = false;
		this.internalCode = '';

		this.on('opened', async () => {
			await this.getData();

			const N_close = this.element.querySelector<HTMLButtonElement>('.close')!;
			const N_edit = this.element.querySelector<HTMLButtonElement>('#edit')!;
			const N_validate = this.element.querySelector<HTMLButtonElement>('#validate')!;
			const N_deprecated = this.element.querySelector<HTMLButtonElement>('#deprecated')!;
			const N_notDeprecated = this.element.querySelector<HTMLButtonElement>('#not-deprecated')!;
			const N_delete = this.element.querySelector<HTMLButtonElement>('#delete')!;

			N_edit.addEventListener('click', () => {
				this.hide();

				new M_AddEditProduct(id).open().then(() => {
					this.element.classList.add('loading');
					this.update = true;
					this.show();
					this.getData();
					this.element.classList.remove('loading');
				}).catch(() => {
					this.show();
				});
			});

			N_validate.addEventListener('click', async () => {
				Alert.confirm('', `Etes-vous sur de vouloir valider le produit ${this.internalCode}?`, { yesType: 'save', yesText: 'Valider' }).then(async () => {
					this.update = true;
					await S_Product.getInstance().save({ _id: id }, { validate: true });
					await this.getData();
				});
			});

			N_deprecated.addEventListener('click', () => {
				Alert.confirm('', `
					En rendant obsolète le produit ${this.internalCode}, celui-ci ne sera plus proposé à la vente.
					<br>
					<br>
					Voulez-vous continuer ?
				`, { yesType: 'default', yesText: 'Rendre obsolète', yesIcon: 'archive/line' }).then(async () => {
					await S_Product.getInstance().save({ _id: id }, { deprecated: true });
					await this.getData();
				});
			});

			N_notDeprecated.addEventListener('click', () => {
				Alert.confirm('', `
					Si vous restaurez le produit ${this.internalCode}, celui-ci sera de nouveau proposé à la vente.
					<br>
					<br>
					Voulez-vous continuer ?
				`, { yesType: 'default', yesText: 'Restaurer', yesIcon: 'archive/line' }).then(async () => {
					await S_Product.getInstance().save({ _id: id }, { notDeprecated: true });
					await this.getData();
				});
			});

			N_delete.addEventListener('click', () => {
				Alert.confirm('', `Etes-vous sur de vouloir supprimer le produit ${this.internalCode}?`, { yesType: 'delete' }).then(async () => {
					await S_Product.getInstance().delete(id);
					this.update = true;
					this.resolve(this.update);
				});
			});

			N_close.addEventListener('click', () => {
				this.resolve(this.update);
			});
		});
	}

	private async getData() {
		this.element.classList.add('loading');

		const N_EditCategoriesReadonly = this.element.querySelector<CE_EditCategoriesReadonly>(CE_EditCategoriesReadonly.tagName)!;
		const N_FilesReadonly = this.element.querySelector<CE_FilesReadonly>(CE_FilesReadonly.tagName)!;
		const N_EditTagsReadonly = this.element.querySelector<CE_EditTagsReadonly>(CE_EditTagsReadonly.tagName)!;
		const N_image = this.element.querySelector<CE_ProductImagePreviewer>('#image')!;
		const N_provider = this.element.querySelector<HTMLElement>('#container-provider')!;
		const N_stock = this.element.querySelector<HTMLElement>('#container-stock')!;
		const N_title = this.element.querySelector<HTMLElement>('#title')!;
		const N_stocksCount = this.element.querySelector<HTMLElement>('#stocks-count')!;

		const N_label = this.element.querySelector<HTMLElement>('#label')!;
		const N_internalCode = this.element.querySelector<HTMLElement>('#internalCode')!;
		const N_price = this.element.querySelector<HTMLElement>('#price')!;
		const N_lastUpdatePrice = this.element.querySelector<HTMLSpanElement>('#lastUpdatePrice')!;
		const N_defaultProviderName = this.element.querySelector<HTMLElement>('#defaultProviderName')!;
		const N_defaultProviderRecommendedSellingPrice = this.element.querySelector<HTMLElement>('#defaultProviderRecommendedSellingPrice')!;

		const N_validate = this.element.querySelector<CE_Button>('#validate')!;
		const N_deprecated = this.element.querySelector<CE_Button>('#deprecated')!;
		const N_notDeprecated = this.element.querySelector<CE_Button>('#not-deprecated')!;

		const N_validateBadge = this.element.querySelector<CE_Alert>('#validate-badge')!;
		const N_deprecatedBadge = this.element.querySelector<HTMLElement>('#deprecated-badge')!;
		const N_Traceability = this.element.querySelector<CE_Traceability>(CE_Traceability.tagName)!;

		const form = new FormControllerPageID(this.element);

		const { data } = await S_Product.getInstance().getDataToModal(this.id);

		N_provider.innerHTML = '';

		if (data.data.providers.length) {
			for (const id of data.data.providers) {
				const N_item = document.createElement(CE_EditProductProviderReadonly.tagName) as CE_EditProductProviderReadonly;

				N_provider.append(N_item);

				await N_item.init(id);
			}
		} else {
			N_provider.innerHTML = 'Aucun fournisseur défini';
		}

		const stocksCount = data.data.stocks.length;

		N_stocksCount.innerHTML = '&nbsp;(' + stocksCount + ')';

		N_stock.innerHTML = '';

		if (stocksCount) {
			for (const id of data.data.stocks) {
				const N_item = document.createElement(CE_EditProductStockReadonly.tagName) as CE_EditProductStockReadonly;

				N_stock.append(N_item);

				await N_item.init(id);
			}
		}

		N_image.setURL(`/function/products/image?id=${this.id}&displayDefault=true&&timestamp=${Date.now()}`);
		N_EditCategoriesReadonly.data = data.data.categories;
		N_title.innerHTML = data.title;

		form.setData(data.data);

		const recommendedSellPrice = data.data.defaultProvider?.recommendedSellPrice;

		N_label.innerHTML = data.data.label;
		N_internalCode.innerHTML = data.data.internalCode;
		N_price.innerHTML = DecimalApps.setDisplayNumber(data.data.price).toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');
		if (data.data.lastUpdatePrice) {
			N_lastUpdatePrice.innerHTML = moment(data.data.lastUpdatePrice).format('DD/MM/YYYY à HH:mm');
		}
		N_defaultProviderName.innerHTML = data.data.defaultProvider?.id?.text || '-';
		N_defaultProviderRecommendedSellingPrice.innerHTML = recommendedSellPrice ? DecimalApps.setDisplayNumber(data.data.defaultProvider?.recommendedSellPrice).toDecimalPlaces(2).setSuffixAndHumanizeNumber('€') : '-';

		N_FilesReadonly.value = data.data.files;
		N_EditTagsReadonly.data = data.data.tags;

		N_validate.classList.toggle('d-none', data.data.validate.active);
		N_deprecated.classList.toggle('d-none', data.data.deprecated);
		N_notDeprecated.classList.toggle('d-none', !data.data.deprecated);

		N_validateBadge.classList.toggle('d-none', data.data.validate.active);
		N_deprecatedBadge.classList.toggle('d-none', !data.data.deprecated);

		N_Traceability.update('products', this.id);

		this.internalCode = data.data.internalCode;

		this.element.classList.remove('loading');
	}
}

export default ViewProduct;
