import { Router, toaster } from '@autoprog/core-client';
import tippy from 'tippy.js';

import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';

import C_OrdersProvider from '../controllers/Orders.Provider';
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

import M_CreateBillProvider from '@modules/BillsProvider/js/modals/CreateBillProvider';
import M_PrintPreview from '@js/libs/modals/PrintPreview';
import M_SetSendDate from '@libs/modals/SetSendDate';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import DashboardController, { DashboardConfigColumns, DashboardOptions } from '@js/controllers/DashboardController';
import { AxiosError } from 'axios';

class ListCommand extends DashboardController {
	constructor(el: HTMLElement) {
		super(el, 'Commandes - fournisseur', 'ORDERS._PROVIDERS', 'commands-provider');
	}

	protected initHeader() {
		super.initHeader();

		const router = Router.getInstance();
		const N_recap = this.N_header.querySelector<HTMLButtonElement>('#recap')!;
		N_recap.addEventListener('click', () => {
			router.navigate('module/commands_export/provider');
		});
	}

	protected buttonAdd() {
		C_OrdersProvider.open(null);
	}

	protected buttonView(id: string) {
		C_OrdersProvider.open(id);
	}

	protected buttonDuplicate(id: string) {
		C_OrdersProvider.setMode('duplicate').open(id);
	}

	protected buttonPrint(id: string) {
		new M_PrintPreview('commands-provider', id).open();
	}

	protected async buttonDelete(id: string) {
		try {
			await this.tableService.delete(id);
			this.getData();
		} catch (err) {
			if ((err as AxiosError).response?.statusText === 'Impossible de supprimer cette commande') {
				toaster.error('Impossible de supprimer cette commande');
			}
		}
	}

	protected cellRendererActionIcons(params: any) {
		const N_div = document.createElement('div');
		N_div.classList.add('container-icons-aggrid');

		const N_billstate = document.createElement('div');
		N_billstate.classList.add('icon-state');
		N_billstate.innerHTML = '<ap-icon name="bill-euro/line"></ap-icon>';

		let billStateTooltip = '';
		let stateTooltip = '';

		//facturer a 100%
		if (params.data.bill.state.value === '2') {
			billStateTooltip = 'Facturée à 100%';
			N_billstate.style.setProperty('--color', 'var(--ap-green-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		//non valorisé
		if (params.data.bill.state.value === '3') {
			billStateTooltip = 'Commande non valorisé';
			N_billstate.style.setProperty('--color', 'var(--ap-teal-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-teal-100)');
		}

		//pas facturer
		if (params.data.bill.state.value === '0') {
			billStateTooltip = 'Facturation pas commencée';
			N_billstate.style.setProperty('--color', 'var(--ap-red-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-red-100)');
		}

		//en cours de facturation
		if (params.data.bill.state.value === '1') {
			billStateTooltip = 'Facturation en cours';
			N_billstate.style.setProperty('--color', 'var(--ap-orange-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		const N_state = document.createElement('div');
		N_state.classList.add('icon-state');

		if (params.data.receipt.state.value === 'inProgress') {
			stateTooltip = 'En cours de création';
			N_state.innerHTML = '<ap-icon name="file-edit/line"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-black)');
			N_state.style.setProperty('--bg-color', 'var(--ap-grey-100)');
		}

		if (params.data.receipt.state.value === 'waiting') {
			stateTooltip = 'En attente de réception';
			N_state.innerHTML = '<ap-icon name="product/line"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-blue-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-blue-100)');
		}

		if (params.data.receipt.state.value === 'partial') {
			stateTooltip = 'Reçue partiellement';
			N_state.innerHTML = '<ap-icon name="product/fill"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-orange-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		if (params.data.receipt.state.value === 'total') {
			stateTooltip = 'Reçue en totalité';
			N_state.innerHTML = '<ap-icon name="product/fill"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-green-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		tippy(N_billstate, {
			content: billStateTooltip,
			arrow: false,
			placement: 'right'
		});

		tippy(N_state, {
			content: stateTooltip,
			arrow: false,
			placement: 'right'
		});

		const N_sendState = document.createElement('div');
		N_sendState.classList.add('icon-state');
		N_sendState.innerHTML = '<ap-icon name="mail-send/line"></ap-icon>';

		const N_isLateState = document.createElement('div');
		N_isLateState.classList.add('icon-state');
		N_isLateState.innerHTML = '<ap-icon name="time/line"></ap-icon>';

		let isSendTooltip = '';
		let isToLateTooltip = '';

		//envoyé
		if (params.data.send.isSend.value === '1') {
			isSendTooltip = 'Envoyé le ' + params.data.infos.sendDate.formattedValue;
			N_sendState.style.setProperty('--color', 'var(--ap-purple-500)');
			N_sendState.style.setProperty('--bg-color', 'var(--ap-purple-50)');
		} else {
			N_sendState.classList.add('disabled');
		}

		if (params.data.receipt.isToLate.value) {
			isToLateTooltip = 'En retard';
			N_isLateState.style.setProperty('--color', 'var(--ap-indigo-500)');
			N_isLateState.style.setProperty('--bg-color', 'var(--ap-indigo-50)');
		} else {
			N_isLateState.classList.add('disabled');
		}

		if (isSendTooltip) {
			tippy(N_sendState, {
				content: isSendTooltip,
				arrow: false,
				allowHTML: true,
				placement: 'right'
			});
		}

		if (isToLateTooltip) {
			tippy(N_isLateState, {
				content: isToLateTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		N_div.append(N_billstate, N_state, N_sendState, N_isLateState);

		return N_div;
	}

	protected _getContextMenu(params: GetContextMenuItemsParams) {
		if (params.node) {
			const _id = params.node.data._id.value;
			const objBill = {
				name: 'Créer une facture',
				icon: '<ap-icon name="bill-euro/line"></ap-icon>',
				disabled: params.node.data.bill.state.value === '2', // || params.node.data.compta.error,
				action: () => {
					new M_CreateBillProvider(_id).open();
				}
			};

			const sendDate = {
				name: 'Définir date d\'envoi',
				icon: '<i class="icon icon-envelope"></i>',
				disabled: params.node.data.send.isSend.value === '1',
				action: () => {
					const modal = new M_SetSendDate('commands-provider', _id);

					modal.open().then(() => {
						this.getData();
					});
				}
			};

			return [
				...super._getContextMenu(params),
				'separator',
				objBill,
				'separator',
				sendDate
			];
		} else {
			return [];
		}
	}

	protected async _onDataUpdate(params: any) {
		let priceTotal = new Decimal(0);
		let priceBill = new Decimal(0);
		let priceCredit = new Decimal(0);
		let priceRequest = new Decimal(0);

		params.api.forEachNodeAfterFilter(async (node: any) => {
			priceTotal = priceTotal.plus(node.data.priceHT.value);
			priceBill = priceBill.plus(node.data.bill.priceHT.value);
			priceCredit = priceCredit.plus(node.data.bill.priceCredit.value);
		});

		const notBill = priceTotal.minus(priceBill).toDecimalPlaces(2);

		priceTotal = priceTotal.toDecimalPlaces(2);
		priceBill = priceBill.toDecimalPlaces(2);
		priceCredit = priceCredit.toDecimalPlaces(2);
		priceRequest = priceRequest.toDecimalPlaces(2);

		this.setDataTitle([{
			label: 'Global HT',
			value: priceTotal.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Facturé HT',
			value: priceBill.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Restant à facturer HT',
			value: notBill.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'À facturer HT',
			value: priceRequest.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Avoir HT',
			value: priceCredit.setSuffixAndHumanizeNumber('€')
		}]);
	}

	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return `${data.infos.number.formattedValue} / ${data.infos.provider.formattedValue}`;
	}

	protected _getRowStyle(params: any): { [key: string]: string } {
		if (params.data.finish.value) {
			return {
				'background-color': 'var(--ap-green-50)',
				color: 'var(--ap-green-900)'
			};
		}

		return {
			'background-color': 'white'
		};
	}

	protected getWidthColActionIcon(): number {
		return 170;
	}

	protected get configColumns(): DashboardConfigColumns {
		return [
			{
				key: '_id',
				type: 'primaryKey',
				name: 'ID'
			},
			{
				key: 'infos.number',
				type: 'string',
				name: 'Numéro'
			},
			{
				key: 'infos.label',
				type: 'string',
				name: 'Objet'
			},
			{
				key: 'infos.provider',
				type: 'string',
				name: 'Fournisseur'
			},
			{
				key: 'infos.contact',
				type: 'string',
				name: 'Contact'
			},
			{
				key: 'priceHT',
				type: 'number',
				name: 'Montant'
			},
			{
				key: 'infos.date',
				type: 'date',
				name: 'Date commande'
			},
			{
				key: 'infos.user',
				type: 'string',
				name: 'Suivi par'
			},
			{
				key: 'infos.orderCustomerIDs',
				type: 'string',
				name: 'N° Affaire'
			},
			{
				key: 'receipt.isToLate',
				type: 'boolean',
				name: 'En retard',
				hide: true
			},
			{
				key: 'receipt.state',
				type: 'object',
				filter: 'multiple',
				hide: true,
				object: {
					inProgress: 'En cours',
					waiting: 'En attente de réception',
					partial: 'Reçue partiellement',
					total: 'Reçue en totalité'
				},
				name: 'État réception'
			},
			{
				key: 'infos.deliveryDate',
				type: 'date',
				name: 'Date livraison prévisionnelle'
			},
			{
				key: 'infos.deliveryPrice',
				type: 'number',
				name: 'Montant frais de port'
			},
			{
				key: 'bill.priceCredit',
				type: 'number',
				name: 'Montant avoir commercial'
			},
			{
				key: 'bill.priceHT',
				type: 'number',
				name: 'Montant facturé'
			},
			{
				key: 'bill.notBilled',
				type: 'number',
				name: 'Montant restant à facturer'
			},
			{
				key: 'bill.percent',
				type: 'string',
				name: 'Facturé'
			},
			{
				key: 'bill.state',
				type: 'object',
				filter: 'multiple',
				hide: true,
				object: {
					0: 'Pas commencée',
					1: 'En cours',
					2: 'Facturée à 100%'
				},
				name: 'État facturation'
			}

		];
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							label: 'Mes commandes'
						}
					]
				},
				{
					type: 'separator'
				}, {
					checkboxListTitle: 'État facturation',
					column: 'bill.state',
					type: 'checkbox',
					filters: [
						{
							value: '0',
							label: 'Aucune facture',
							iconName: 'icon-outline-file-invoice-dollar',
							iconColor: 'red-400',
							leftIndent: true
						}, {
							value: '1',
							label: 'Facture partielle',
							iconName: 'icon-outline-file-invoice-dollar',
							iconColor: 'orange-400',
							leftIndent: true
						}, {
							value: '2',
							label: 'Facture de solde',
							iconName: 'icon-outline-file-invoice-dollar',
							iconColor: 'green-400',
							leftIndent: true
						}
					]
				}
			],
			[
				{
					checkboxListTitle: 'État commande',
					column: 'receipt.state',
					type: 'checkbox',
					filters: [{
						value: 'inProgress',
						label: 'En cours de création'
					}, {
						value: 'waiting',
						label: 'En attente de réception'
					}, {
						value: 'partial',
						label: 'Reçue partiellement'
					}, {
						value: 'total',
						label: 'Reçue en totalité'
					}]
				},
				{ type: 'separator' },
				{
					column: 'receipt.isLate',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'En retard'
						}
					]
				}
			]
		];
	}

	protected get optionsColumnsGrid(): DashboardOptions {
		return {
			columns: {
				displayed: {
					icons: true,
					selection: false
				}
			},
			actionButtons: {
				displayed: {
					add: true,
					edit: false,
					view: true,
					duplicate: true,
					delete: true,
					print: true
				}
			}
		};
	}

	protected getDisabledActionButtons(data: { [key: string]: any }) {
		return {
			delete: data.finish.value || (data.bill.state.value !== '3' && data.bill.state.value !== '4' && data.bill.state.value !== '0') || data.send.isSend.value !== '0' || data.receipt.state.value !== 'inProgress'
		};
	}
}

export default ListCommand;
