import SettingsApps from '@libs/Settings';

import Utils from '@libs/utils/Utils';

import OrderPrintOptionTab from '../customElements/OrderPrintOption';
import SettingsController from '@modules/Settings/js/libs/SettingsController';

type OptionBillData = {
	options: {
		print: {
			[key: string]: any
		}
	} | null
};

class SettingsOrderProviderTabs extends SettingsController {
	private readonly OPTIONS = 'ORDER_PROVIDER.OPTIONS';

	private data: OptionBillData;
	private N_PrintOptionTab: OrderPrintOptionTab | null;

	private settings: SettingsApps;

	constructor(el: HTMLElement) {
		super(el);

		this.settings = SettingsApps.getInstance();
		this.data = this.getData();
		this.N_PrintOptionTab = null;
	}

	protected init() {
		super.init();

		// On récupère les données des options des factures
		this.initTab();
		this.setData();
		this.initSave();
	}

	private initSave() {
		const N_saveBtn = this.el.querySelector('#save') as HTMLButtonElement;
		N_saveBtn.addEventListener('click', () => {
			N_saveBtn.loading(
				new Promise(async (resolve, reject) => {
					try {
						await this.save();
						this.resolve();
					} catch (e) {
						reject(e);
					}
				})
			);
		});
	}

	private save() {
		const printOptions = this.N_PrintOptionTab!.data;
		this.settings.setGeneral(this.OPTIONS, printOptions);
	}

	/**
	 * Initialise les onglets
	 */
	private initTab() {
		// on initialise l'onglet des options d'impression
		this.N_PrintOptionTab = this.el.querySelector(OrderPrintOptionTab.tagName) as OrderPrintOptionTab;
		this.N_PrintOptionTab.setParentElement(this.el);

		this.N_PrintOptionTab.setGetData(() => {
			return {
				companyID: Utils.companyID,
				options: this.N_PrintOptionTab!.data
			};
		});

		this.N_PrintOptionTab.setID(() => {
			return 'fakeProviderOrder';
		});
	}

	/**
	 * Initialisation de l'onglet CGV
	 */
	private setData() {
		if (this.data.options) {
			this.N_PrintOptionTab!.data = this.data.options;
		} else {
			console.error('no printOptions found');
		}
	}

	/**
	 * Récupère les données des options des factures
	 */
	private getData(): OptionBillData {
		const data = {
			options: this.settings.get(this.OPTIONS) as { print: { [key: string]: any } }
		};
		return data;
	}

	public destructor() {

	}
}

export default SettingsOrderProviderTabs;
