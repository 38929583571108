import '@css/customElements/checkbox.scss';

class Checkbox extends HTMLElement {
	public static readonly tagName: string = 'ap-checkbox';

	public connectedCallback() {
		const name = this.getAttribute('name') as string;
		const label = this.getAttribute('label') as string;
		const disabled = this.hasAttribute('disabled');
		const checked = this.hasAttribute('checked');

		this.innerHTML = `
			<input type="checkbox" ${disabled ? 'disabled' : ''}  ${checked ? 'checked' : ''} name="${name}" />
			<label>${label}</label>
        `;

		const N_input = this.querySelector<HTMLInputElement>('input')!;

		N_input.addEventListener('change', () => {
			this.dispatchEvent(new Event('change'));
		});

		this.addEventListener('click', () => {
			if (!N_input.disabled) {
				N_input.checked = !N_input.checked;
				this.dispatchEvent(new Event('change'));
			}
		});

		this.removeAttribute('name');
	}

	public set checked(value: boolean) {
		const N_input = this.querySelector<HTMLInputElement>('input')!;
		if (N_input) {
			N_input.checked = value;
		} else {
			if (value) {
				this.setAttribute('checked', '');
			} else {
				this.removeAttribute('checked');
			}
		}
	}

	public get checked() {
		const N_input = this.querySelector<HTMLInputElement>('input')!;
		return N_input.checked;
	}

	public static register() {
		customElements.define(Checkbox.tagName, Checkbox);
	}
}

export default Checkbox;
