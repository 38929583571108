// CORE

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/users/editPage/additionalInformations.html';

// LIBS
import Modal from '@libs/EditPageModal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

type AdditionalInformationsData = {
	hiddenProductPhoto: boolean
	logoutAutoEnabled: boolean
	logoutDelay: number
	quotesManager: boolean
	manager: boolean
	deleted: boolean
	isSuperAdmin: boolean
	hiddenInSearch: boolean
};

class AdditionalInformations extends Modal<AdditionalInformationsData> {
	constructor(data: AdditionalInformationsData) {
		super(data, T_modal);
	}

	protected checkValidityForm() {
		return !!(this.form?.checkValidity() && this.logoutDelayIsValid());
	}

	protected async getDataForm(data: { [key: string]: any }) {
		//Si l'auto-déconnexion n'est pas activée, on set le temps à 0
		if (!data.logoutAutoEnabled) data.logoutDelay = 0;

		return data;
	}

	private logoutDelayIsValid(): boolean {
		if (this.form?.getDataByName('logoutAutoEnabled')) {
			return !isNaN(this.form?.getDataByName('logoutDelay') as number);
		} else {
			return true;
		}
	}

	protected async setData(data: AdditionalInformationsData) {
		const res: { [key: string]: any } = {
			hiddenProductPhoto: data.hiddenProductPhoto,
			logoutAutoEnabled: data.logoutAutoEnabled,
			logoutDelay: data.logoutAutoEnabled ? data.logoutDelay : null,
			quotesManager: data.quotesManager,
			manager: data.manager,
			deleted: data.deleted,
			isSuperAdmin: data.isSuperAdmin,
			hiddenInSearch: data.hiddenInSearch
		};

		this.form?.setData(res);
	}

	private initLogoutForm() {
		const N_LogoutAutoEnabled = this.element.querySelector('#logoutAutoEnabled') as HTMLInputElement;

		N_LogoutAutoEnabled.addEventListener('click', () => {
			this.updateLogoutDelayForm(N_LogoutAutoEnabled.checked);
		});

		this.updateLogoutDelayForm(N_LogoutAutoEnabled.checked);
	}

	private updateLogoutDelayForm(isLogoutAutoEnabled: boolean) {
		const N_LogoutDelayInputContainer = this.element.querySelector('#logoutDelayInputContainer') as HTMLDivElement;
		const N_LogoutDelay = this.element.querySelector('[name="logoutDelay"]') as HTMLInputElement;

		N_LogoutDelayInputContainer.classList.toggle('disabled', !isLogoutAutoEnabled);
		N_LogoutDelay.disabled = !isLogoutAutoEnabled;
	}

	protected postInit() {
		super.postInit();
		this.initLogoutForm();
	}
}

export default AdditionalInformations;
