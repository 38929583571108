// CORE
// NODE_MODULE
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
import T_template from '../../../tpl/blocks/template_inventory_export.ejs';

// LIBS
import Title from '@libs/Title';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';

class Stock {
	public async print(data: { [key: string]: any }[], title: string) {
		const group: { [brand: string]: any } = _.groupBy(data, 'product.brand.formattedValue');
		let result: { [key: string]: any }[] = [];

		const productKey = S_Product.getInstance().referenceKey;

		const priceByBrand: { [brand: string]: Decimal } = {};

		for (const brand in group) {
			group[brand] = _.sortBy(group[brand], 'product.reference.formattedValue');

			for (const item of group[brand]) {
				const value = item.product.defaultProvider?.costPrice.value;
				const PU = Decimal.setDisplayNumber(value);

				const brand = (item.product.brand.formattedValue || 'NON RENSEIGNEE').toUpperCase();

				const priceTotal = PU.times(item.newQuantity);

				const label = item.product.label.formattedValue;
				const reference = item.product[productKey].formattedValue;

				result.push({
					type: 'product',
					reference: reference.length < 15 ? reference : reference.slice(0, 15 - 3) + '...',
					label: label.length < 60 ? label : label.slice(0, 60 - 3) + '...',
					brand,
					price: priceTotal.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€'),
					locationStock: item.product.locationStock.formattedValue,
					quantity: item.newQuantity
				});

				priceByBrand[brand] = priceByBrand[brand] || new Decimal(0);

				priceByBrand[brand] = priceByBrand[brand].plus(priceTotal);
			}
		}

		result = _.sortBy(result, ['brand', productKey]) as any[];

		let brand = '';
		let index = 1;
		for (let i = 0; i < result.length; i++) {
			if (brand !== result[i].brand) {
				brand = result[i].brand;

				result.splice(i, 0, {
					type: 'brand',
					label: brand,
					price: priceByBrand[result[i].brand].toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')
				});

				i++;
			}

			if (i % 54 === 0) {
				result.splice(i, 0, {
					type: 'brand',
					label: brand,
					price: priceByBrand[result[i].brand].toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')
				});

				i++;
			}

			if (result[i].type !== 'brand') {
				result[i].index = index;
				index++;
			}
		}

		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		const chunkMaterials = _.chunk(result, 54);

		let page = 1;
		for (const chunk of chunkMaterials) {
			N_container.innerHTML += T_template({
				title,
				date: moment().format('DD/MM/YYYY'),
				page: (page++),
				totalPage: chunkMaterials.length,
				data: chunk
			});
		}

		Title.getInstance().updateOverride(`Inventaire_${moment().format('DD/MM/YYYY')}`);

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);

			Title.getInstance().updateOverride('');

			Utils.removeTooltip();
		}, 1000);
	}
}

export default Stock;
