// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_Modal from '../../tpl/modals/selectOrder.html';

// LIBS
import Modal from '@libs/Modal';

// MODAL
import M_ListSelectOrder from './ListSelectOrder';

// CUSTOM_ELEMENT
import MaterialsTab from '../customElements/MaterialsTab';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';

class SelectOrder extends Modal {
	private N_MaterialsTab: MaterialsTab | null = null;

	constructor() {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_selectOrders = this.element.querySelector('#selectOrders') as HTMLButtonElement;

			let idOrder: string = '';

			N_save.addEventListener('click', () => {
				const rowSelected = this.N_MaterialsTab!.getSelectedNodes();

				const products = _.map(rowSelected, (item) => {
					return {
						quoteCustomer: item.data.quote,
						productID: item.data.productID,
						maxQuantity: item.data.quantity,
						quantity: item.data.quantity
					};
				});

				this.resolve({
					products,
					idOrder
				});
			});

			N_selectOrders.addEventListener('click', () => {
				new M_ListSelectOrder().open().then((id) => {
					idOrder = id;
					N_selectOrders.loading(new Promise<void>(async (resolve) => {
						const { data } = await S_C_Order.getInstance().getDataToModal(id);

						this.N_MaterialsTab!.data = data.data.content || [];

						resolve();
					}));
				});
			});

			this.N_MaterialsTab = this.element.querySelector(MaterialsTab.tagName) as MaterialsTab;

			this.N_MaterialsTab.setSuppressContextMenu(() => {
				return true;
			});

			this.N_MaterialsTab.setParentElement(this.element);

			this.N_MaterialsTab.disableButton();

			this.N_MaterialsTab!.data = [];

			this.N_MaterialsTab.active();
		});
	}
}

export default SelectOrder;
