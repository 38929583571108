// CORE
import { Controller, LoggedUser, Router, global } from '@autoprog/core-client';

// NODE_MODULE
import axios from 'axios';

// TEMPLATE
import T_Desktop from '../../tpl/blocks/home.desktop.html';

// LIBS
import NavigationMobile from '@libs/NavigationMobile';

// PRINTER
// UTILS
import Utils from '@js/libs/utils/Utils';

// MODAL
import M_Alerte from '../../../Stock/js/modals/Alerte';

// CUSTOM_ELEMENT
import CE_Commands from '../libs/customElement/Commands';
import CE_GlobalInformations from '../libs/customElement/GlobalInformations';
import CE_Interventions from '../libs/customElement/quickAccess/Interventions';
import CE_Quotes from '../libs/customElement/quickAccess/Quotes';
import CE_QuotesToDo from '../libs/customElement/quickAccess/QuotesToDo';
import CE_Stock from '../libs/customElement/quickAccess/Stock';

// SERVICE

class HomeCtrl extends Controller {
	private el: HTMLElement;

	private intervalHours: any = null;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		const I_user = LoggedUser.getInstance();

		if (I_user.isLogged) {
			if (global.IS_MOBILE && !global.IS_TABLET) {
				this.initMobile();
			} else {
				this.initDesktop();
			}
		}
	}

	private link() {
		const router = Router.getInstance();

		const N_quotes_to_do = this.el.querySelector('[data-link="quotes_to_do"]') as HTMLElement;
		const N_quotes = this.el.querySelector('[data-link="quotes"]') as HTMLElement;
		const N_interventions = this.el.querySelector('[data-link="interventions"]') as HTMLElement;
		const N_deliveries = this.el.querySelector('[data-link="deliveries"]') as HTMLElement;
		const N_products = this.el.querySelector('[data-link="products"]') as HTMLElement;
		const N_sites = this.el.querySelector('[data-link="sites_projects"]') as HTMLElement;
		const N_customers = this.el.querySelector('[data-link="customers_providers"]') as HTMLElement;
		const N_hours = this.el.querySelector('[data-link="hours"]') as HTMLElement;
		const N_profil = this.el.querySelector('[data-link="profil"]') as HTMLElement;
		const N_alerteStock = this.el.querySelectorAll('[data-link="alerte_stock"]') as NodeListOf<HTMLElement>;

		const N_stocks = this.el.querySelector('[data-link="stocks"]') as HTMLElement;

		N_quotes_to_do && N_quotes_to_do.classList.add('cursor-pointer');
		N_quotes && N_quotes.classList.add('cursor-pointer');
		N_interventions && N_interventions.classList.add('cursor-pointer');

		N_quotes_to_do && N_quotes_to_do.addEventListener('click', () => {
			router.navigate('#module/quotes/quotes_to_do');
		});

		N_quotes && N_quotes.addEventListener('click', () => {
			router.navigate('#module/quotes/quotes');
		});

		N_interventions && N_interventions.addEventListener('click', () => {
			router.navigate('#module/apps/interventions');
		});

		N_deliveries && N_deliveries.addEventListener('click', () => {
			router.navigate('#module/apps/deliveries');
		});

		N_products && N_products.addEventListener('click', () => {
			router.navigate('#module/apps/products');
		});

		N_sites && N_sites.addEventListener('click', () => {
			router.navigate('#database/sites');
		});

		N_hours && N_hours.addEventListener('click', () => {
			router.navigate('#module/hours/hours');
		});

		N_profil && N_profil.addEventListener('click', () => {
			router.navigate('#module/profile/home');
		});

		N_customers && N_customers.addEventListener('click', () => {
			router.navigate('#tabs/customers_providers');
		});

		N_stocks && N_stocks.addEventListener('click', () => {
			router.navigate('#module/stock');
		});

		N_alerteStock.forEach((el) => {
			el.classList.add('cursor-pointer');
			el.addEventListener('click', () => {
				new M_Alerte().open();
			});
		});
	}

	private async initDesktop() {
		const N_container = this.el.querySelector('#container') as HTMLElement;
		N_container.innerHTML = T_Desktop;

		const N_version = this.el.querySelector('#version') as HTMLElement;
		N_version.innerHTML = global.VERSION;

		this.initCustomElements();
	}

	private async initCustomElements() {
		/* Récupération des custom elements */
		const N_CE_GlobalInformations = this.el.querySelector('ap-global-informations') as CE_GlobalInformations;
		const N_CE_Commands = this.el.querySelector('ap-commands') as CE_Commands;
		const N_CE_Interventions = this.el.querySelector('ap-interventions') as CE_Interventions;
		const N_CE_QuotesToDo = this.el.querySelector('ap-quotes-to-do') as CE_QuotesToDo;
		const N_CE_Quotes = this.el.querySelector('ap-quotes') as CE_Quotes;
		const N_CE_Stock = this.el.querySelector('ap-stock') as CE_Stock;

		/* Récupération des données à envoyer dans les custom elements */
		const { data } = await axios.get('/home/data', { params: { companyID: Utils.companyID } });

		const globalInformationsData = {
			sites: {
				number: data.data.sites.number
			},
			products: {
				number: data.data.products.number
			},
			stock: {
				price: data.data.stock.price
			},
			providers: {
				number: data.data.providers.number
			},
			customers: {
				number: data.data.customers.number
			}
		};

		const commandsData = {
			commands_provider: {
				partial: data.data.commands_provider.partial,
				waiting: data.data.commands_provider.waiting,
				inProgress: data.data.commands_provider.inProgress
			},
			commands_customer: {
				waiting: data.data.commands_customer.waiting,
				notBill: data.data.commands_customer.notBill,
				inBilling: data.data.commands_customer.inBilling
			},
			bill: {
				toLate: data.data.bill.toLate
			}
		};

		const interventionsData = {
			interventions: {
				invoiced: data.data.interventions.invoiced,
				future: data.data.interventions.future
			}

		};

		const quotesToDoData = {
			quotesToDo: {
				toLate: data.data.quotesToDo.toLate,
				waiting: data.data.quotesToDo.waiting
			}

		};

		const quotesData = {
			quotes: {
				inProgress: data.data.quotes.inProgress,
				number: data.data.quotes.number
			}
		};

		const stockData = {
			stock: {
				negative: data.data.stock.negative,
				alert: data.data.stock.alert,
				warning: data.data.stock.warning
			}
		};

		/* Envoi des données récupérées pour chaque custom element respectif */

		N_CE_GlobalInformations.data = globalInformationsData;
		N_CE_Commands.data = commandsData;
		N_CE_Interventions.data = interventionsData;
		N_CE_QuotesToDo.data = quotesToDoData;
		N_CE_Quotes.data = quotesData;
		N_CE_Stock.data = stockData;
	}

	private initMobile() {
		const N_version = this.el.querySelector('#version') as HTMLElement;
		const N_login = this.el.querySelector('#login') as HTMLElement;

		N_version.innerHTML = 'v' + global.VERSION;

		const user = LoggedUser.getInstance();
		N_login.innerHTML = user.get('firstname') + '  ' + user.get('lastname');

		const N_buttons = this.el.querySelector('.buttons') as HTMLElement;

		NavigationMobile.getInstance().render(N_buttons);

		this.link();
	}

	public destructor() {
		clearInterval(this.intervalHours);
	}
}

export default HomeCtrl;
