import { Router } from '@autoprog/core-client';

import HistoryApps from '@libs/History';

import T_NavbarMobile from '@tpl/customElement/mobile/navbar-mobile.html';

class NavbarMobile extends HTMLElement {
	public static readonly tagName: string = 'ap-navbar-mobile';

	public async connectedCallback() {
		this.classList.add('navbar-bottom');

		this.innerHTML = T_NavbarMobile;

		this.init();
	}

	private init() {
		const N_home = this.querySelector('#home') as HTMLElement;
		const back = this.querySelector('#back') as HTMLElement;
		const forward = this.querySelector('#forward') as HTMLElement;

		const historyApps = HistoryApps.getInstance();

		N_home.addEventListener('click', () => {
			Router.getInstance().navigate('module/apps/home');
		});

		back.addEventListener('click', () => {
			historyApps.back();
		});

		forward.addEventListener('click', () => {
			historyApps.forward();
		});
	}

	public static register() {
		customElements.define(NavbarMobile.tagName, NavbarMobile);
	}
}

export default NavbarMobile;
