import { GridOptions } from '@ag-grid-enterprise/all-modules';
import removeAccents from 'remove-accents';

import '@css/customElements/alert.scss';

import CE_Button from '@libs/customElement/Button';

import '../../../css/customElement/select-provider.scss';

import S_Provider from '@services/Provider/ProviderService';

class SelectProvider extends HTMLElement {
	public static readonly tagName: string = 'ap-select-providers';

	private N_modal: HTMLElement | null = null;
	private _data: { [key: string]: any }[] = [];
	private gridOptions: GridOptions = {};

	private openValue: string = '';

	public connectedCallback() {
		this.innerHTML = '<ap-button type="default">Fournisseur</ap-button>';

		const N_button = this.querySelector<CE_Button>('ap-button')!;

		N_button.addEventListener('click', async () => {
			this.open();

			this.openValue = this.getCurrentValue();
			this.updateSelectedValue();
		});
	}

	public disconnectedCallback() {
		this.N_modal?.remove();
	}

	private async getData() {
		const tmp = await S_Provider.getInstance().getAll();

		this._data = [];

		for (const item of tmp) {
			this._data.push(item);
		}
	}

	private open() {
		this.N_modal?.classList.add('open');
	}

	private close() {
		this.N_modal?.classList.remove('open');

		const N_search = this.N_modal!.querySelector<HTMLInputElement>('#search')!;
		N_search.value = '';

		this.displaySearchResult(N_search.value);
	}

	private updateButton() {
		const N_button = this.querySelector<CE_Button>('ap-button')!;
		this.openValue = this.getCurrentValue();
		N_button.classList.toggle('active', !!this.openValue);
	}

	private init() {
		this.N_modal = document.createElement('div');

		this.N_modal.classList.add('select-providers-modal');
		this.N_modal.innerHTML = `
			<div class="title">
				Fournisseurs
				<ap-button class="btn-icon btn-default" id="close" icon="close/line"></ap-button>
			</div>
			<input class="ap-input" id="search" placeHolder="Recherche"/>
			<div class="content"></div>
		`;

		const N_close = this.N_modal.querySelector<CE_Button>('#close')!;
		const N_search = this.N_modal.querySelector<HTMLInputElement>('#search')!;

		N_close.addEventListener('click', () => {
			this.close();
		});

		N_search.addEventListener('input', () => {
			this.displaySearchResult(N_search.value);
		});

		document.body.append(this.N_modal);

		this.renderContent();
	}

	public async setGridOptions(gridOptions: GridOptions) {
		this.gridOptions = gridOptions;

		this.gridOptions.api?.addEventListener('filterChanged', () => {
			this.updateButton();
		});

		await this.getData();

		this.init();
	}

	private displaySearchResult(value: string) {
		if (value) {
			const search = removeAccents(value).toUpperCase();

			const N_itemList = this.N_modal!.querySelectorAll<HTMLElement>('.content [data-id][data-label]');

			for (const N_item of N_itemList) {
				const label = removeAccents(N_item.dataset.label || '').toUpperCase();

				N_item.classList.toggle('d-none', !label.includes(search));
			}
		} else {
			const N_itemList = this.N_modal!.querySelectorAll<HTMLElement>('.content [data-id]');

			for (const N_item of N_itemList) {
				N_item.classList.remove('d-none');
			}
		}
	}

	private renderContent() {
		const N_content = this.N_modal?.querySelector<HTMLElement>('.content')!;
		N_content.innerHTML = '';

		let data: { [key: string]: string }[] = this._data || [];
		data = data.sort((itemA, itemB) => {
			const valueA = itemA.number + itemA.name;
			const valueB = itemB.number + itemB.name;

			if (valueA > valueB) {
				return 1;
			}
			if (valueA < valueB) {
				return -1;
			}
			return 0;
		});

		if (data.length) {
			const N_all = document.createElement('div');
			N_all.classList.add('item', 'item-title');

			if (this.openValue === '') {
				N_all.classList.add('selected');
			}

			N_all.dataset.id = '';
			N_all.dataset.search = '';
			N_all.innerHTML = 'Voir tout';

			N_all.addEventListener('click', () => {
				this.updateGrid('');
			});

			N_content.append(N_all);
		}

		for (const item of data) {
			const N_item = document.createElement('div');
			N_item.classList.add('item');
			N_item.innerHTML = item.number + ' ' + item.name;
			N_item.dataset.id = item._id;
			N_item.dataset.label = item.number + ' ' + item.name;

			if (this.openValue === item._id) {
				N_item.classList.add('selected');
			}

			N_item.addEventListener('click', () => {
				this.updateGrid(item._id);
			});

			N_content.append(N_item);
		}
	}

	private updateGrid(id: string) {
		const filterInstance = this.gridOptions.api?.getFilterInstance('providersIDs');

		filterInstance?.setModel({
			type: 'contains',
			filter: id
		});

		this.gridOptions.api?.onFilterChanged();

		this.close();
	}

	private getCurrentValue() {
		const filterInstance = this.gridOptions.api?.getFilterInstance('providersIDs');

		const tmp = filterInstance?.getModel();

		if (tmp?.filter) {
			return tmp.filter;
		}

		return '';
	}

	private updateSelectedValue() {
		const N_selectedList = this.N_modal!.querySelectorAll<HTMLElement>('.selected');

		for (const N_el of N_selectedList) {
			N_el?.classList.remove('selected');
		}

		if (this.openValue) {
			const N_selected = this.N_modal!.querySelector<HTMLElement>(`[data-id="${this.openValue}"]`);
			N_selected?.classList.add('selected');
		}
	}

	public static register() {
		customElements.define(SelectProvider.tagName, SelectProvider);
	}
}

export default SelectProvider;
