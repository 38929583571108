import GenericService from './GenericService';

class TrackingProductService extends GenericService {
	private static instance: TrackingProductService | null = null;

	constructor() {
		super('tracking-products');
	}

	public static getInstance(): TrackingProductService {
		if (!TrackingProductService.instance) {
			TrackingProductService.instance = new TrackingProductService();
		}

		return TrackingProductService.instance;
	}
}

export default TrackingProductService;
