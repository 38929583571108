module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card-mobile flex-column">\n\n    <div>\n        ' +
((__t = (data.id_provider || '&nbsp;')) == null ? '' : __t) +
'\n    </div>\n\n    <div>\n        ' +
((__t = (data.provider_reference || '&nbsp;')) == null ? '' : __t) +
' ' +
((__t = (data.provider_label || '&nbsp;')) == null ? '' : __t) +
'\n    </div>\n\n</div>';

}
return __p
}