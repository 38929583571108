import StepContent from '@libs/StepModal/StepContent';

import T_Step from '../../../../tpl/modals/orderFromQuote/steps/order.html';

import '../../../../css/modals/orderFromQuote/steps/order.scss';

import S_C_Contact from '@services/Customer/CustomerContactService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';
import S_User from '@services/User/UserService';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Select2Button from '@libs/customElement/Select2';

type OrderStepData = {
	quoteID?: string,
	quoteNumber?: string,
	infosCustomer?: {
		orderNumber?: string,
		addonNumber?: string,
		customer?: string,
		contact?: string
	},
	manager?: string,
	orderLabel?: string,
	orderDescription?: string,
	selectedOrderId?: string
};

class OrderStep extends StepContent {
	protected selects: { [key: string]: CE_Select2Button } = {};

	private data?: OrderStepData;

	private validationChecked: boolean = false;

	public async init() {
		this.preInit();
		this.postInit();
	}

	public getTitle() {
		return 'Commande';
	}

	public getContent() {
		return T_Step;
	}

	public getData(): OrderStepData {
		if (this.data?.selectedOrderId) {
			return {
				selectedOrderId: this.data?.selectedOrderId
			};
		}

		const N_customer = this.el.querySelector<CE_Select2Button>('#customer')!;
		const N_contact = this.el.querySelector<CE_Select2Button>('#contact')!;
		const N_manager = this.el.querySelector<CE_Select2Button>('#manager')!;
		const N_orderNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;
		const N_addonNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.addonNumber"]')!;
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;
		const N_description = this.el.querySelector<HTMLInputElement>('[name="description"]')!;

		return {
			infosCustomer: {
				orderNumber: N_orderNumber.value,
				addonNumber: N_addonNumber.value,
				customer: N_customer.value as string,
				contact: N_contact.value as string
			},
			manager: N_manager.value as string,
			orderLabel: N_label.value,
			orderDescription: N_description.value
		};
	}

	private initValidationEvents() {
		const N_customer = this.el.querySelector<CE_Select2Button>('#customer')!;
		const N_manager = this.el.querySelector<CE_Select2Button>('#manager')!;
		const N_orderNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;

		N_customer.on('change', () => {
			if (this.validationChecked) {
				this.checkCustomerValidity();
			}
		});

		N_manager.on('change', () => {
			if (this.validationChecked) {
				this.checkManagerValidity();
			}
		});

		N_orderNumber.addEventListener('input', () => {
			if (this.validationChecked) {
				this.checkOrderNumberValidity();
			}
		});

		N_label.addEventListener('input', () => {
			if (this.validationChecked) {
				this.checkLabelValidity();
			}
		});
	}

	public checkValidity(): boolean {
		this.validationChecked = true;

		let validity = true;

		validity = this.checkCustomerValidity() ? validity : false;
		validity = this.checkManagerValidity() ? validity : false;
		validity = this.checkOrderNumberValidity() ? validity : false;
		validity = this.checkLabelValidity() ? validity : false;

		return validity;
	}

	private checkCustomerValidity(): boolean {
		const N_customer = this.el.querySelector<CE_Select2Button>('#customer')!;
		const validity = !!this.data?.selectedOrderId || !!N_customer.value;
		N_customer.classList.toggle('invalid', !validity);

		return validity;
	}

	private checkManagerValidity(): boolean {
		const N_manager = this.el.querySelector<CE_Select2Button>('#manager')!;
		const validity = !!this.data?.selectedOrderId || !!N_manager.value;
		N_manager.classList.toggle('invalid', !validity);

		return validity;
	}

	private checkOrderNumberValidity(): boolean {
		const N_orderNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;
		const validity = !!this.data?.selectedOrderId || !!N_orderNumber.value;
		N_orderNumber.classList.toggle('invalid', !validity);

		return validity;
	}

	private checkLabelValidity(): boolean {
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;
		const validity = !!this.data?.selectedOrderId || !!N_label.value;
		N_label.classList.toggle('invalid', !validity);

		return validity;
	}

	public async setData(data: OrderStepData) {
		this.data = data;

		const N_customer = this.el.querySelector<CE_Select2Button>('#customer')!;
		const N_contact = this.el.querySelector<CE_Select2Button>('#contact')!;
		const N_manager = this.el.querySelector<CE_Select2Button>('#manager')!;
		const N_orderNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;
		const N_addonNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.addonNumber"]')!;
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;
		const N_description = this.el.querySelector<HTMLInputElement>('[name="description"]')!;

		N_customer.setValues(await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer?.customer ?? ''));
		N_contact.setValues(await S_C_Contact.getInstance().getDataToSelect2ByID(data.infosCustomer?.contact ?? ''));
		N_manager.setValues(await S_User.getInstance().getDataToSelect2ByID(data.manager ?? ''));

		N_orderNumber.value = data.infosCustomer?.orderNumber ?? '';
		N_addonNumber.value = data.infosCustomer?.addonNumber ?? '';

		N_label.value = data.orderLabel ?? '';
		N_description.value = data.orderDescription ?? '';

		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;
		const { rowData } = await S_C_Order.getInstance().getDataToAgGrid();
		N_grid.value = rowData;

		this.changeFilter(data.infosCustomer?.customer ?? '');
		this.updateMode();
	}

	protected preInit() {
		const N_grid = this.el.querySelector<CE_AgGrid >('#grid')!;

		const stateBill: { [key: number]: string } = {
			0: 'Pas commencée',
			1: 'En cours',
			2: 'Facturée à 100%',
			3: 'Non valorisé'
		};

		N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucune commande trouvée' },
			suppressContextMenu: true,
			columnDefs: [{
				headerName: '',
				checkboxSelection: true,
				pinned: 'left',
				width: 50,
				suppressSizeToFit: true
			}, {
				headerName: 'N° Affaire',
				field: 'internalNumber'
			}, {
				headerName: 'Client',
				field: 'infosCustomer.customer',
				hide: true
			}, {
				headerName: 'N° commande client',
				field: 'infosCustomer.orderNumber'
			}, {
				headerName: 'Complément',
				field: 'infosCustomer.addonNumber'
			}, {
				headerName: 'Etat facture',
				field: 'bill.state',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: Object.keys(stateBill),
					valueFormatter: (params: any) => {
						return stateBill[params.value] || params.value;
					}
				},
				hide: true
			}, {
				headerName: 'Objet',
				field: 'label'
			}, {
				headerName: 'Sites',
				field: 'sites'
			}],
			defaultColDef: {
				suppressMovable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			onFilterChanged: () => {
				if (N_grid.api?.getDisplayedRowCount() === 0) {
					N_grid.api?.showNoRowsOverlay();
				} else {
					N_grid.api?.hideOverlay();
				}
				this.updateMode();
			},
			onRowClicked: (event) => {
				if (event.node.isSelected()) {
					event.api.deselectNode(event.node);
				} else {
					event.api.selectNode(event.node);
				}
			},
			onRowSelected: () => {
				this.updateMode();
			}
		});

		const N_customer = this.el.querySelector<CE_Select2Button>('#customer')!;
		const N_contact = this.el.querySelector<CE_Select2Button>('#contact')!;
		const N_manager = this.el.querySelector<CE_Select2Button>('#manager')!;

		N_manager.create(this.el);
		N_customer.create(this.el);
		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.el);

		this.selects['infosCustomer.customer'] = N_customer;
		this.selects['infosCustomer.contact'] = N_contact;
		this.selects.manager = N_manager;
	}

	protected postInit() {
		for (const key in this.selects) {
			this.selects[key].postInit();
		}

		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		const N_orderNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;
		const N_addonNumber = this.el.querySelector<HTMLInputElement>('[name="infosCustomer.addonNumber"]')!;
		const N_contact = this.el.querySelector<CE_Select2Button>('#contact')!;
		const N_copyQuoteNumberBtn = this.el.querySelector<HTMLButtonElement>('#copyQuoteNumber')!;
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;

		N_copyQuoteNumberBtn?.addEventListener('click', () => {
			N_orderNumber.value = this.data!.quoteNumber ?? '';
			N_orderNumber.dispatchEvent(new Event('input'));

			const filterInstanceOrderNumber = N_grid.api?.getFilterInstance('infosCustomer.orderNumber');

			filterInstanceOrderNumber?.setModel({
				type: 'contains',
				filter: N_orderNumber.value
			});

			N_grid.api?.onFilterChanged();
		});

		N_orderNumber.addEventListener('input', () => {
			const filterInstanceOrderNumber = N_grid.api?.getFilterInstance('infosCustomer.orderNumber');

			filterInstanceOrderNumber?.setModel({
				type: 'contains',
				filter: N_orderNumber.value
			});

			N_grid.api?.onFilterChanged();
		});

		N_addonNumber.addEventListener('input', () => {
			const filterInstanceAddonNumber = N_grid.api?.getFilterInstance('infosCustomer.addonNumber');

			filterInstanceAddonNumber?.setModel({
				type: 'contains',
				filter: N_addonNumber.value
			});

			N_grid.api?.onFilterChanged();
		});

		N_label.addEventListener('input', () => {
			this.updateMode();
		});

		this.selects['infosCustomer.customer'].on('change', async (customer) => {
			await this.updateFilterCustomer(customer as string);

			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				N_contact.setValues([contacts[0]]);
			} else {
				N_contact.setValues([{ id: '', text: '' }]);
			}

			this.updateMode();
		});

		this.selects.manager.on('change', () => {
			this.updateMode();
		});

		this.initValidationEvents();
	}

	private updateMode() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;
		const N_newOrder = this.el.querySelector<HTMLDivElement>('#new-order')!;

		let selectedOrderId: string | undefined;

		N_grid.api?.forEachNodeAfterFilter((node) => {
			if (node.isSelected()) {
				selectedOrderId = node.data?._id?.value;
			}
		});

		this.data!.selectedOrderId = selectedOrderId;

		if (selectedOrderId) {
			N_newOrder.classList.add('step-order-column-disabled');
		} else {
			N_newOrder.classList.remove('step-order-column-disabled');
		}
	}

	private async updateFilterCustomer(customer: string) {
		const value = await S_Customer.getInstance().getDisplayRefByID(customer);

		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		const filterInstanceCustomer = N_grid.api?.getFilterInstance('infosCustomer.customer');

		filterInstanceCustomer?.setModel({
			type: 'contains',
			filter: value
		});

		N_grid.api?.onFilterChanged();
	}

	private async changeFilter(customer: string) {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		const filterInstanceState = N_grid.api?.getFilterInstance('bill.state');

		await this.updateFilterCustomer(customer);

		filterInstanceState?.setModel({
			values: ['0', '1', '3']
		});

		N_grid.api?.onFilterChanged();
	}
}

export default OrderStep;
