import Modal from '@libs/Modal';

import T_Modal from '../../tpl/modals/addEditCategories.html';

import '../../css/addEditCategory.scss';

import S_Category from '@services/Product/ProductCategoryService';
import Utils from '@js/libs/utils/Utils';

type category = {
	_id: string
	label: string
	children: category[]
};

class AddEditCategories extends Modal {
	private deletedIds: string[];

	private isUse: { [key: string]: boolean };

	constructor(id?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.deletedIds = [];
		this.isUse = {};

		this.on('opened', async () => {
			this.element.classList.add('loading');

			const N_title = this.element.querySelector<HTMLElement>('.modal-title')!;

			if (id) {
				N_title.innerHTML = 'Modification d\'une catégorie';
			} else {
				N_title.innerHTML = 'Ajouter une catégorie';
			}

			const { data } = await S_Category.getInstance().getDataToModal(id);

			this.setIsUse(data.data);

			const N_label = this.element.querySelector<HTMLInputElement>('[name="label"]')!;
			N_label.value = data.data.label;

			this.renderListChildren(data.data.children);

			const N_containerChildren = this.element.querySelector<HTMLElement>('#children');
			const N_containerSuBChildren = this.element.querySelector<HTMLElement>('#sub-children')!;

			const N_add_children = this.element.querySelector<HTMLButtonElement>('#add-children')!;
			const N_add_sub_children = this.element.querySelector<HTMLButtonElement>('#add-sub-children')!;

			const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

			N_add_children.addEventListener('click', () => {
				const id = Utils.generateId();
				N_containerChildren?.append(this.renderItem({ _id: id, label: '', children: [] }, ''));
				this.selectChildren(id);
			});

			N_add_sub_children.addEventListener('click', () => {
				const N_selected = this.element.querySelector<HTMLElement>('#children .selected')!;

				const idParent = N_selected.dataset.id!;

				N_containerSuBChildren?.append(this.renderItem({ _id: Utils.generateId(), label: '', children: [] }, idParent));

				this.selectChildren(idParent);
			});

			N_save.addEventListener('click', async () => {
				const data: category = {
					_id: id || Utils.generateId(),
					label: N_label.value,
					children: this.getChildren('', 0)
				};

				await S_Category.getInstance().save(data, { deletedIds: this.deletedIds });

				this.resolve();
			});

			N_add_children.disabled = false;

			this.element.classList.remove('loading');
		});
	}

	private getChildren(idParent: string, level: number = 0) {
		const result: category[] = [];

		const N_listChildren = this.element.querySelectorAll<HTMLElement>(`[data-level="${level}"] [${idParent ? 'data-parent=' + idParent : 'data-parent'}]`);

		for (const N_el of N_listChildren) {
			const N_input = N_el.querySelector<HTMLInputElement>('input');

			result.push({
				_id: N_el.dataset.id!,
				label: N_input!.value,
				children: this.getChildren(N_el.dataset.id!, level + 1)
			});
		}

		return result;
	}

	private renderListChildren(children: category[]) {
		const N_container = this.element.querySelector<HTMLElement>('[data-level="0"]')!;

		for (const item of children) {
			N_container.append(this.renderItem(item, ''));

			this.renderListSubChildren(item);
		}
	}

	private renderListSubChildren(item: category) {
		const N_container = this.element.querySelector<HTMLElement>('[data-level="1"]')!;

		for (const children of item.children) {
			N_container?.append(this.renderItem(children, item._id));
		}
	}

	private renderItem(item: category, idParent: string) {
		const N_div = document.createElement('div');
		N_div.classList.add('item');

		if (idParent) {
			N_div.classList.add('d-none');
		}

		N_div.dataset.id = item._id;
		N_div.dataset.parent = idParent;

		N_div.innerHTML = `
			<input type="text" id="input" class="ap-input" value="${item.label}" placeholder="Nouvelle sous catégorie"/>
			<ap-button class="btn-icon btn-default" icon="close/line" confirmation id="delete"></ap-button>
			<ap-button class="btn-icon ${idParent ? 'd-none' : ''}" type="action" icon="arrow-right-s/line" id="select"></ap-button>
		`;

		const N_delete = N_div.querySelector<HTMLButtonElement>('#delete')!;
		const N_select = N_div.querySelector<HTMLButtonElement>('#select')!;
		const N_input = N_div.querySelector<HTMLInputElement>('#input')!;

		if (item.label === '') {
			N_input.focus();
		}

		N_delete.disabled = !!this.isUse[item._id];

		N_delete.addEventListener('click', () => {
			this.deletedIds.push(item._id);
			N_div.remove();
			this.updateAddSubChildren();
			this.selectChildren(idParent);
		});

		N_select.addEventListener('click', () => {
			const N_selected = this.element.querySelector<HTMLElement>('[data-level="0"] .selected');
			N_selected?.classList.remove('selected');

			N_div.classList.add('selected');

			this.selectChildren(item._id);

			this.updateAddSubChildren();
		});

		return N_div;
	}

	private selectChildren(id: string) {
		const N_subChildren = this.element.querySelectorAll<HTMLElement>('[data-level="1"] [data-id]')!;

		for (const N_el of N_subChildren) {
			if (N_el.dataset.parent === id) {
				N_el.classList.remove('d-none');
			} else {
				N_el.classList.add('d-none');
			}
		}
	}

	private updateAddSubChildren() {
		const N_add_sub_children = this.element.querySelector<HTMLButtonElement>('#add-sub-children')!;
		const N_selected = this.element.querySelector<HTMLElement>('[data-level="0"] .selected');

		N_add_sub_children.disabled = !N_selected;
	}

	private setIsUse(data: { [key: string]: any }) {
		data.children = data.children || [];

		for (const item of data.children) {
			this.isUse[item._id] = item.numberUse !== 0;

			this.setIsUse(item);
		}
	}
}

export default AddEditCategories;
