import { Alert, toaster } from '@autoprog/core-client';

import DashboardController, { DashboardConfigColumns, DashboardOptions } from '@js/controllers/DashboardController';

//NODE_MODULES
import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import moment from 'moment';
import tippy from 'tippy.js';

//LIBS
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

//CONTROLLER
import C_BillsProvider from '../controllers/Bills.Provider';

//MODALS
import M_AddPayment from '../modals/AddLinePayment';
import M_CreateBillProvider from '../modals/CreateBillProvider';

import M_SetRelaunchDate from '@libs/modals/SetRelunchDate';

//CUSTOM-ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// EXPORTS CSV
import Export from '@libs/export/Export';
import ExportUtils from '@libs/utils/ExportUtils';

// SERVICE
import S_P_Bill from '@services/Provider/ProviderBillService';

class Customer extends DashboardController {
	constructor(el: HTMLElement) {
		super(el, 'Facture - fournisseur', 'BILLS._PROVIDERS', 'bills-providers');
	}

	protected initHeader() {
		super.initHeader();

		const N_exportElement = this.N_header.querySelector('#export_excel') as HTMLButtonElement;

		N_exportElement.addEventListener('click', () => {
			const csvString = new Export()
				.included(['infos.provider', 'infos.label', 'infos.number', 'order.internalNumber', 'infos.date', 'infos.receiveDate', 'infos.datePayment', 'relaunch.date', 'relaunch.number', 'infos.type', 'priceHT', 'priceTVA', 'priceTTC', 'paymentPrice', 'notPaymentPrice', 'paid.state', 'receive.isReceive'])
				.fromTableName('bills-providers')
				.fromGridOptionsNewDashboard(this.N_grid!.gridOptions, this.configColumns)
				.createCSV();

			const a = ExportUtils.createFileLink(csvString);
			a.download = `Export-Factures-Fournisseur-${moment().format('DD-MM-YYYY')}`;
			a.click();
			a.remove();
		});
	}

	protected buttonView(id: string) {
		C_BillsProvider.open(id);
	}

	protected getDisabledActionButtons(data: { [key: string]: any }) {
		return {
			delete: data._rowData_.payments.length !== 0
		};
	}

	protected getWidthColActionIcon(): number {
		return 160;
	}

	protected cellRendererActionIcons(params: any) {
		const N_div = document.createElement('div');
		N_div.classList.add('container-icons-aggrid');

		const N_paymentState = document.createElement('div');
		N_paymentState.classList.add('icon-state');
		N_paymentState.innerHTML = '<ap-icon name="bank/line"></i>"></ap-icon>';

		let paymentStateTooltip = '';

		//Payée en totalité
		if (params.data.paid.state.value === '2') {
			paymentStateTooltip = 'Paiement total';
			N_paymentState.style.setProperty('--color', 'var(--ap-green-500)');
			N_paymentState.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		//Payée partiellement
		if (params.data.paid.state.value === '1') {
			paymentStateTooltip = 'Paiement partiel';
			N_paymentState.style.setProperty('--color', 'var(--ap-orange-500)');
			N_paymentState.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		//Aucun paiement
		if (params.data.paid.state.value === '0') {
			paymentStateTooltip = 'Aucun paiement';
			N_paymentState.style.setProperty('--color', 'var(--ap-red-500)');
			N_paymentState.style.setProperty('--bg-color', 'var(--ap-red-100)');
		}

		const N_receiveState = document.createElement('div');
		N_receiveState.classList.add('icon-state');
		N_receiveState.innerHTML = '<ap-icon name="mail-check/line"></ap-icon>';
		N_receiveState.style.transform = 'scale(-1,1)';

		const N_isLateState = document.createElement('div');
		N_isLateState.classList.add('icon-state');
		N_isLateState.innerHTML = '<ap-icon name="history/line"></ap-icon>';

		let isReceiveTooltip = '';
		let isToLateTooltip = '';

		//receptionné
		if (params.data.infos.waitingBill.value) {
			isReceiveTooltip = 'En attente de réception';
			N_receiveState.style.setProperty('--color', 'var(--ap-red-500)');
			N_receiveState.style.setProperty('--bg-color', 'var(--ap-red-100)');
		} else if (params.data.receive.isReceive.value) {
			isReceiveTooltip = 'Réceptionnée';
			N_receiveState.style.setProperty('--color', 'var(--ap-purple-500)');
			N_receiveState.style.setProperty('--bg-color', 'var(--ap-purple-50)');
		} else {
			N_receiveState.classList.add('disabled');
		}

		if (params.data.paid.isToLate.value) {
			isToLateTooltip = 'En retard';
			N_isLateState.style.setProperty('--color', 'var(--ap-indigo-500)');
			N_isLateState.style.setProperty('--bg-color', 'var(--ap-indigo-50)');
		} else {
			N_isLateState.classList.add('disabled');
		}

		if (isReceiveTooltip) {
			tippy(N_receiveState, {
				content: isReceiveTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		if (isToLateTooltip) {
			tippy(N_isLateState, {
				content: isToLateTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		if (paymentStateTooltip) {
			tippy(N_paymentState, {
				content: paymentStateTooltip,
				arrow: false,
				placement: 'right'
			});
		}

		N_div.append(N_paymentState, N_receiveState, N_isLateState);

		return N_div;
	}

	protected _getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			//paiement en retard
			if (params.data.relaunch.active.value) {
				return {
					'background-color': 'var(--ap-blue-50)',
					color: 'var(--ap-blue-900)'
				};
			}

			//litige
			if (params.data.disputes.enabled.value) {
				return {
					'background-color': 'var(--ap-red-50)',
					color: 'var(--ap-red-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected _getContextMenu(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
		if (params.node) {
			const _id = params.node.data._id.value;
			const idOrder = params.node.data.order.id.value;

			return [
				...super._getContextMenu(params),
				'separator',
				{
					name: 'Définir prochaine relance',
					icon: '<ap-icon name="restart/line"></ap-icon>',
					disabled: !params.node.data.relaunch.active.value,
					action: () => {
						new M_SetRelaunchDate('bills-providers', _id).open().then(() => {
							this.getData();
						});
					}
				}, 'separator', {
					name: 'Ajout paiement',
					icon: '<ap-icon name="money-euro-circle/line"></ap-icon>',
					disabled: params.node.data.paid.state.value === '2',
					action: () => {
						new M_AddPayment(_id).open().then(async (payment) => {
							const data = await S_P_Bill.getInstance().getById(_id);

							data.payments = data.payments || [];

							data.payments.push(payment);

							await S_P_Bill.getInstance().save(data);

							toaster.success('Sauvegarde réussi');

							this.getData();
						});
					}
				}, {
					name: 'Créer un avoir',
					icon: '<ap-icon name="bill/line"></ap-icon>',
					disabled: params.node.data.isCredit.value,
					action: () => {
						new M_CreateBillProvider(idOrder, _id).setMode('create-credit').open();
					}
				}, {
					name: !params.node.data.disputes?.enabled.value ? 'Mettre en litige' : 'Clôturer le litige',
					icon: '<ap-icon name="alert" class="text-red"></ap-icon>',
					action: () => {
						if (!params.node.data.disputes?.enabled.value) {
							Alert.prompt('Litige', 'Commentaire', {
								type: 'textarea',
								value: ' ',
								confirmColor: 'validate-modal',
								cancelColor: 'close-modal',
								cancelText: 'Fermer'
							}).then(async (value) => {
								const data = await S_P_Bill.getInstance().getById(_id);

								data.disputes = {
									enabled: true,
									comments: (value as string).trim()
								};

								data.notes = data.notes || [];
								data.notes.push({
									user: Utils.userID,
									date: Date.now().toString(),
									text: 'Mise en litige de la facture.'
								});

								await S_P_Bill.getInstance().save(data);

								toaster.success('Sauvegarde réussi');

								this.getData();
							});
						} else {
							Alert.confirm('Litige', 'Souhaitez-vous clôturer le litige ?').then(async () => {
								const data = await S_P_Bill.getInstance().getById(_id);

								data.disputes.enabled = false;

								data.notes = data.notes || [];
								data.notes.push({
									user: Utils.userID,
									date: Date.now().toString(),
									text: 'Suppression du litige'
								});

								await S_P_Bill.getInstance().save(data);

								toaster.success('Sauvegarde réussi');

								this.getData();
							});
						}
					}
				}
			];
		} else {
			return [];
		}
	}

	protected _onDataUpdate(params: any) {
		let priceTTC = new Decimal(0);
		let priceHT = new Decimal(0);
		let paymentPrice = new Decimal(0);
		let toLatePayment = new Decimal(0);
		let notWaitingPriceTTC = new Decimal(0);
		let waitingPriceTTC = new Decimal(0);

		const lengthSelectedRows = params.api.getSelectedRows().length;

		params.api.forEachNodeAfterFilter((node: any) => {
			if ((lengthSelectedRows && node.isSelected()) || (!lengthSelectedRows)) {
				priceTTC = priceTTC.plus(node.data.priceTTC.value);
				priceHT = priceHT.plus(node.data.priceHT.value);

				paymentPrice = paymentPrice.plus(node.data.paymentPrice.value);

				if (node.data.paid.isToLate.value) {
					toLatePayment = toLatePayment.plus(Decimal.setDisplayNumber(node.data.priceTTC.value).minus(node.data.paymentPrice.value));
				}

				if (node.data.infos.waitingBill.value) {
					waitingPriceTTC = waitingPriceTTC.plus(node.data.priceTTC.value);
				} else {
					notWaitingPriceTTC = notWaitingPriceTTC.plus(node.data.priceTTC.value);
				}
			}
		});

		const notPaid = priceTTC.minus(paymentPrice).toDecimalPlaces(2);
		const tva = priceTTC.minus(priceHT).toDecimalPlaces(2);

		priceHT = priceHT.toDecimalPlaces(2);
		priceTTC = priceTTC.toDecimalPlaces(2);
		paymentPrice = paymentPrice.toDecimalPlaces(2);
		toLatePayment = toLatePayment.toDecimalPlaces(2);

		const values = [{
			label: 'Montant HT',
			value: priceHT.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TVA',
			value: tva.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC',
			value: priceTTC.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC payé',
			value: paymentPrice.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC à payer',
			value: notPaid.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Dont en retard (TTC)',
			value: toLatePayment.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC factures reçues',
			value: notWaitingPriceTTC.setSuffixAndHumanizeNumber('€')
		}, {
			label: 'Montant TTC factures non reçues',
			value: waitingPriceTTC.setSuffixAndHumanizeNumber('€')
		}];

		if (lengthSelectedRows) {
			values.unshift({
				label: `Factures sélectionnées (${lengthSelectedRows})`,
				value: ''
			});
		}

		this.setDataTitle(values);
	}

	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return `${data.infos.internalNumber.formattedValue} / ${data.infos.provider.formattedValue}`;
	}

	protected get configColumns(): DashboardConfigColumns {
		return [
			{
				key: '_id',
				type: 'primaryKey',
				name: 'ID'
			},
			{
				key: 'infos.internalNumber',
				type: 'string',
				name: 'Numéro Interne'
			},
			{
				key: 'infos.number',
				type: 'string',
				name: 'Numéro'
			},
			{
				key: 'infos.type',
				type: 'object',
				name: 'Type',
				object: {
					normal: 'Avancement',
					credit: 'Avoir (Commercial)',
					'credit-error': 'Avoir (Erreur)',
					advance: 'Acompte',
					last: 'Définitive'
				}
			},
			{
				key: 'infos.date',
				type: 'date',
				name: 'Date'
			},
			{
				key: 'infos.receiveDate',
				type: 'date',
				name: 'Date de réception'
			},
			{
				key: 'infos.label',
				type: 'string',
				name: 'Objet'
			},
			{
				key: 'infos.provider',
				type: 'string',
				name: 'Fournisseur'
			},
			{
				key: 'infos.contact',
				type: 'string',
				name: 'Contact'
			},
			{
				key: 'infos.datePayment',
				type: 'date',
				name: 'Date échéance'
			},
			{
				key: 'order.internalNumber',
				type: 'string',
				name: 'N° Commande'
			},
			{
				key: 'relaunch.date',
				type: 'date',
				name: 'Date de relance'
			},
			{
				key: 'relaunch.number',
				type: 'number',
				name: 'Nb. de relance'
			},
			{
				key: 'relaunch.active',
				type: 'boolean',
				name: 'À relancer',
				hide: true
			},
			{
				key: 'day.advance',
				type: 'number',
				name: "Jour d'avance"
			},
			{
				key: 'day.delay',
				type: 'number',
				name: 'Jour de retard'
			},
			{
				key: 'paid.isToLate',
				type: 'boolean',
				name: 'Retard',
				hide: true
			},
			{
				key: 'paid.state',
				type: 'object',
				filter: 'multiple',
				object: {
					0: 'Aucun paiement',
					1: 'Paiement partiel',
					2: 'Paiement total'
				},
				name: 'État paiement',
				hide: true
			},
			{
				key: 'paid.percent',
				type: 'string',
				name: 'Payée'
			},
			{
				key: 'lastPayment',
				type: 'date',
				name: 'Date dernier paiement'
			},
			{
				key: 'priceHT',
				type: 'number',
				name: 'Montant HT (€)'
			},
			{
				key: 'priceTVA',
				type: 'number',
				name: 'Montant TVA (€)'
			},
			{
				key: 'priceTTC',
				type: 'number',
				name: 'Montant TTC (€)'
			},
			{
				key: 'paymentPrice',
				type: 'number',
				name: 'Montant payé TTC (€)'
			},
			{
				key: 'notPaymentPrice',
				type: 'number',
				name: 'Montant à payer TTC (€)'
			},
			{
				key: 'receive.isReceive',
				type: 'boolean',
				name: 'État réception',
				hide: true
			},
			{
				key: 'disputes.enabled',
				type: 'boolean',
				name: 'En litige',
				hide: true
			}
		];
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [{
						value: Utils.userID,
						label: 'Mes factures',
						hideIcon: true
					}]
				},
				{
					column: 'relaunch.active',
					type: 'switch',
					filters: [{
						value: 'true',
						label: 'À relancer',
						hideIcon: true
					}]
				},
				{
					column: 'receive.isReceive',
					type: 'switch',
					filters: [{
						value: 'false',
						label: 'En attente de réception',
						hideIcon: true
					}]
				},
				{
					column: 'disputes.enabled',
					type: 'switch',
					filters: [{
						value: 'true',
						label: 'En litige',
						backgroundColor: 'var(--ap-red-50)',
						hideIcon: true
					}]
				},
				{
					type: 'separator'
				},
				{
					column: 'paid.state',
					type: 'checkbox',
					checkboxListTitle: 'Paiement',
					filters: [{
						value: '0',
						label: 'Aucun',
						hideIcon: true,
						leftIndent: true
					}, {
						value: '1',
						label: 'Partiel',
						hideIcon: true,
						leftIndent: true
					}, {
						value: '2',
						label: 'Total',
						hideIcon: true,
						leftIndent: true
					}]
				}
			]
		];
	}

	protected get optionsColumnsGrid(): DashboardOptions {
		return {
			columns: {
				displayed: {
					icons: true,
					selection: true
				}
			},
			actionButtons: {
				displayed: {
					add: false,
					edit: false,
					view: true,
					duplicate: false,
					delete: true,
					print: false
				}
			}
		};
	}
}

export default Customer;
