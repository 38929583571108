import h from 'hyperscript';

import SettingsApps from '@libs/Settings';

import TextareaEditor from '@libs/agGrid/TextareaEditor';

import CE_Aggrid from '@libs/customElement/AgGrid';
import TextareaCellRenderer from '../agGrid/TextareaCellRenderer';

type CGVLine = {
	category: string,
	subCategory: string,
	text: string,
	_idGrid: string
};

export type CGV = Array<CGVLine>;
class CGVTab extends HTMLElement {
	public static readonly tagName: string = 'ap-cgv-tab';

	private idTab: string;
	private selectorTab: string;
	private mode: 'QUOTE' | 'BILL' | null;
	private settings: SettingsApps;

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private _update = (): void => { };

	private _isLock: boolean = false;

	public static register() {
		customElements.define(this.tagName, CGVTab);
	}

	constructor() {
		super();
		this.settings = SettingsApps.getInstance();
		this.mode = this.getAttribute('mode') as 'QUOTE' | 'BILL' || null;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="list-unordered" text="CGV"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid?.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public async connectedCallback() {
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');

		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				CGV
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
        `;

		this.N_grid = this.N_el.querySelector<CE_Aggrid>('#grid');

		N_container.append(this.N_el);

		this.initGrid();
	}

	private initGrid() {
		this.N_grid?.setGridOptions({
			rowDragManaged: true,
			defaultColDef: {
				suppressMovable: true,
				editable: () => {
					return !this._isLock;
				}
			},
			columnDefs: [
				{
					headerName: '',
					rowDrag: true,
					width: 40,
					suppressSizeToFit: true,
					pinned: 'left'
				}, {
					field: 'category',
					headerName: 'Catégorie',
					width: 180,
					suppressSizeToFit: true
				}, {
					field: 'subCategory',
					headerName: 'Sous-Catégorie',
					width: 140,
					suppressSizeToFit: true
				}, {
					field: 'text',
					headerName: 'Texte',
					cellEditor: TextareaEditor,
					autoHeight: true,
					cellRenderer: TextareaCellRenderer
				}, {
					field: '#',
					headerName: '',
					width: 50,
					editable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					pinned: 'right',
					cellRenderer: (params) => {
						const N_delete = h('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete' } });

						N_delete.addEventListener('click', () => {
							params.api.updateRowData({
								remove: [params.node.data]
							});

							this._update();
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_delete);

						return N_div;
					}
				}
			],
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter ligne',
					disabled: this._isLock,
					action: () => {
						let index = 0;

						params.api?.forEachNode(() => {
							index++;
						});

						params.api?.applyTransaction({
							add: [{
								category: '',
								subCategory: '',
								text: '',
								_idGrid: Date.now().toString(36)
							}]
						});

						params.api?.ensureIndexVisible(index);
					}
				}, {
					name: 'Insérer ligne',
					disabled: !params.node || this._isLock,
					action: () => {
						params.api?.applyTransaction({
							add: [{
								category: '',
								subCategory: '',
								text: '',
								_idGrid: Date.now().toString(36)
							}],
							addIndex: (params.node.rowIndex || 0) + 1
						});
					}
				}];

				if (this.mode) {
					result.push({
						name: 'Insérer les valeurs par defaut',
						disabled: this._isLock,
						action: async () => {
							const CGV = this.settings.get(`${this.mode}.CGV`) || [];

							params.api?.applyTransaction({
								add: CGV as { [key: string]: any }[]
							});
						}
					});
				}

				return result;
			},
			onCellEditingStarted: (params) => {
				if (params.column.getColId() === 'text') {
					params.node.setRowHeight((params.node.rowHeight || 0) + 20);
					params.api.onRowHeightChanged();
				}
			},
			onCellEditingStopped: (params) => {
				setTimeout(() => {
					params.api.resetRowHeights();
				}, 100);
				this._update();
			},
			onRowDataUpdated: () => {
				this._update();
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			}
		});
	}

	public get data(): CGV {
		return this.N_grid!.value as CGV;
	}

	public set data(data: CGV) {
		this.N_grid!.value = data;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}
}

export default CGVTab;
