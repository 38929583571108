import '@css/customElements/uploadPhoto.scss';

class UploadPhoto extends HTMLElement {
	public static readonly tagName: string = 'ap-upload-photo';

	public connectedCallback() {
		const url_name = this.getAttribute('name-url');
		const base64_name = this.getAttribute('name-base64');

		this.classList.add('no-photo', 'no-invalid');

		this.innerHTML = `
			<input type="text" class="ap-input url" name="${url_name}" placeholder="Saisissez une URL : https://example.com">
			<input type="text" name="${base64_name}" class="base64">

			<div class="upload-file">
				<input type="file" id="file-photo" class="file" accept="image/*" />
				<label for="file-photo">
					<ap-icon name="upload/line"></ap-icon>
					<div>Cliquer pour parcourir</div>
					<div>ou glisser et déposer vos fichiers</div>
				</label>
				<div class="photo">
					<ap-icon class="delete" name="close/line"></ap-icon>
					<img src="">
					<ap-icon class="loader-image icon-spin icon-3x" name="loader-4/line"></ap-icon>
				</div>
				<div class="invalid">
					Lien invalide
				</div>
			</div>
		`;

		this.initEvent();
	}

	public getImageFromURL(url_photo: string) {
		if (url_photo.includes('dropbox.com/')) {
			url_photo = url_photo.replace(/(\?dl=0)$/gmi, '?raw=1');
			url_photo = url_photo.replace(/(&dl=0)$/gmi, '&raw=1');
		}

		return url_photo;
	}

	private initEvent() {
		const N_url = this.querySelector<HTMLInputElement>('.url')!;
		const N_base64 = this.querySelector<HTMLInputElement>('.base64')!;
		const N_file = this.querySelector<HTMLInputElement>('.file')!;
		const N_fileLabel = this.querySelector<HTMLLabelElement>('label')!;

		const N_photo = this.querySelector<HTMLImageElement>('.photo img')!;

		N_url.addEventListener('input', async () => {
			this.classList.add('no-invalid');

			if (N_url.value !== '') {
				this.classList.remove('no-photo');

				const url = this.getImageFromURL(N_url.value);

				N_photo.src = '';
				N_base64.value = '';

				try {
					const base64 = await this.getBase64ImageFromURL(url);

					N_base64.value = base64;
					N_photo.src = base64;
				} catch (e) {
					N_base64.value = '';

					const N_img = new Image();

					N_img.addEventListener('load', () => {
						N_photo.src = url;
					});

					N_img.addEventListener('error', () => {
						this.classList.remove('no-invalid');
					});

					N_img.src = url;
				}
			} else {
				N_photo.src = '';
				N_base64.value = '';

				this.classList.add('no-photo');
			}
		});

		N_file.addEventListener('change', async () => {
			N_url.value = '';
			N_photo.src = '';
			N_base64.value = '';

			this.classList.add('no-invalid');

			if (N_file.files![0]) {
				this.classList.remove('no-photo');

				try {
					const base64 = await this.getBase64ImageFromFile(N_file.files![0]);

					N_base64.value = base64;
					N_photo.src = base64;
				} catch (e) {
					this.classList.remove('no-invalid');
				}

				N_file.value = '';
			} else {
				this.classList.add('no-photo');
			}
		});

		N_fileLabel.addEventListener('dragover', (e) => {
			e.stopPropagation();
			e.preventDefault();
			N_fileLabel.classList.add('dragover');
		});
		N_fileLabel.addEventListener('dragleave', (e) => {
			e.stopPropagation();
			e.preventDefault();
			N_fileLabel.classList.remove('dragover');
		});
		N_fileLabel.addEventListener('dragend', (e) => {
			e.stopPropagation();
			e.preventDefault();
			N_fileLabel.classList.remove('dragover');
		});

		N_fileLabel.addEventListener('drop', async (e) => {
			e.preventDefault();
			N_fileLabel.classList.remove('dragover');

			const droppedFiles = e.dataTransfer?.files;

			N_url.value = '';
			N_photo.src = '';
			N_base64.value = '';

			this.classList.add('no-invalid');

			if (droppedFiles && droppedFiles[0]) {
				this.classList.remove('no-photo');

				try {
					const base64 = await this.getBase64ImageFromFile(droppedFiles[0]);

					N_base64.value = base64;
					N_photo.src = base64;
				} catch (e) {
					this.classList.remove('no-invalid');
				}

				N_file.value = '';
			} else {
				this.classList.add('no-photo');
			}
		});

		const N_delete = this.querySelector<HTMLElement>('.delete')!;

		N_delete.addEventListener('click', () => {
			N_photo.src = '';
			N_url.value = '';
			N_base64.value = '';
			this.classList.add('no-photo');
		});
	}

	private getBase64ImageFromURL(url: string): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const data = await fetch(url);
				const blob = await data.blob();
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					const base64data = reader.result as string;
					resolve(base64data);
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	private getBase64ImageFromFile(file: Blob): Promise<string> {
		return new Promise(async (resolve, reject) => {
			try {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					const base64data = reader.result as string;
					resolve(base64data);
				};
			} catch (e) {
				reject(e);
			}
		});
	}

	public get data() {
		const N_url = this.querySelector<HTMLInputElement>('.url')!;
		const N_base64 = this.querySelector<HTMLInputElement>('.base64')!;

		return {
			url: N_url.value,
			base64: N_base64.value
		};
	}

	public set data(data: { url: string, base64: string }) {
		const N_url = this.querySelector<HTMLInputElement>('.url')!;
		const N_base64 = this.querySelector<HTMLInputElement>('.base64')!;

		N_url.value = data.url || '';
		N_base64.value = data.base64 || '';

		const N_photo = this.querySelector<HTMLImageElement>('.photo img')!;

		N_photo.src = data.base64 || this.getImageFromURL(data.url);

		if (data.base64 || data.url) {
			this.classList.remove('no-photo');
		}
	}

	public static register() {
		customElements.define(UploadPhoto.tagName, UploadPhoto);
	}
}

export default UploadPhoto;
