import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import '../../css/brandCellRenderer.scss';

import BrandService from '@services/Product/BrandService';

class BrandCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;
	private value: string;

	private brandService: BrandService;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('brandCellRenderer');
		this.eGui.innerHTML = `
			<div class="text"></div>
		`;

		this.brandService = new BrandService();
		this.value = '';
	}

	public init(params: any) {
		this.value = params.value;

		const N_text = this.eGui.querySelector('.text')!;

		if (this.value) {
			this.brandService.getDisplayRefByID(this.value).then((data) => {
				N_text.innerHTML = data;
			}).catch(() => {
				N_text.innerHTML = 'Aucune Marque';
			});
		} else {
			N_text.innerHTML = '';
		}
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default BrandCellRenderer;
