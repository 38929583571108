import ApIcon from '@autoprog/icons';

class PageTabsMenuItem extends HTMLElement {
	public static readonly tagName: string = 'ap-page-tabs-menu-item';

	private _href = '';

	private icon: string = '';
	private iconActive: string = '';

	public connectedCallback() {
		this.icon = this.getAttribute('icon') as string;
		this.iconActive = this.getAttribute('icon-active') as string;
		let text = this.getAttribute('text') as string;
		this._href = this.getAttribute('href') as string;
		const hasNumber = this.hasAttribute('hasNumber');

		text = text.replace(/'/gmi, '&#39');

		this.innerHTML = `
			<div class="page-tabs-menu-item-icon">
				<ap-icon name="${this.icon}" tooltip='{"title" : "${text}", "placement" : "right"}'></ap-icon>
			</div>
			
			<div class="page-tabs-menu-item-text">${text}</div>

			<div class="page-tabs-menu-item-arrow"><ap-icon name="arrow-right-s/line"></ap-icon></div>
		`;

		if (hasNumber) {
			this.innerHTML += `
				<span class="page-tabs-menu-item-badge" id="number">0</span>
			`;
		}

		this.removeAttribute('icon');
		this.removeAttribute('text');
		this.removeAttribute('href');
		this.removeAttribute('hasNumber');
	}

	public get href() {
		return this._href;
	}

	public open() {
		const N_icon = this.querySelector<ApIcon>('ap-icon')!;
		N_icon.name = this.iconActive || this.icon;

		this.classList.add('active');

		this.dispatchEvent(new CustomEvent('open', { detail: this.href }));
	}

	public close() {
		const N_icon = this.querySelector<ApIcon>('ap-icon')!;
		N_icon.name = this.icon;

		this.classList.remove('active');

		this.dispatchEvent(new CustomEvent('close', { detail: this.href }));
	}

	public static register() {
		customElements.define(PageTabsMenuItem.tagName, PageTabsMenuItem);
	}
}

export default PageTabsMenuItem;
