import axios from 'axios';

/**
 * Permet d'accéder à la configuration du serveur
 */
class ConfigurationService {
	private URI: string = '/api/configuration';
	private static instance: ConfigurationService | null = null;

	public static getInstance(): ConfigurationService {
		if (!ConfigurationService.instance) {
			ConfigurationService.instance = new ConfigurationService();
		}

		return ConfigurationService.instance;
	}

	public async getConfig() {
		return axios.get(this.URI).then((res) => res.data);
	}
}

export default ConfigurationService;
