import { toaster } from '@autoprog/core-client';

import h from 'hyperscript';

import Modal from '@libs/Modal';

//CUSTOM ELEMENT
import TextEditable from '@libs/customElement/TextEditable';
import TrackingProductEventCard from '../customElement/TrackingProductEventCard';

//SERVICE
import TrackingProductService from '@services/TrackingProductService';

//TYPES
import { TrackingProduct, TrackingProductEvent } from '@modules/TrackingProduct/types/trackingProduct';

//TEMPLATE
import T_modal from '../../tpl/modals/historyTrackingProduct.html';

//Style
import '../../css/tracking-product-history.scss';

import _ from 'lodash';

/**
 * Modal pour l'historique des produits suivis
 */
class TrackingProductHistory extends Modal {
	private trackingProduct: TrackingProduct;
	private trackingProductService: TrackingProductService;
	private isChange: boolean;

	constructor(trackingProduct: TrackingProduct) {
		super({
			template: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.trackingProduct = trackingProduct;
		this.trackingProductService = TrackingProductService.getInstance();
		this.isChange = false;

		// A l'ouverture de la modale
		this.on('opened', () => {
			this.init();
		});
	}

	private init() {
		const N_trackingNumber = this.element.querySelector('ap-text-editable') as TextEditable;
		// N_trackingNumber!.innerHTML = this.trackingProduct.trackingNumber || '-';
		N_trackingNumber!.text = this.trackingProduct.trackingNumber;

		N_trackingNumber.addEventListener('textChange', () => {
			if (N_trackingNumber.text && N_trackingNumber.text !== this.trackingProduct.trackingNumber) {
				const newData = _.clone(this.trackingProduct);
				newData.trackingNumber = N_trackingNumber.text;
				this.trackingProductService.save(newData).then((result) => {
					if (result.ok) {
						toaster.success('Numéro de traçabilité modifié');
						this.trackingProduct = newData;
						this.isChange = true;
					} else {
						toaster.error(result.err!);
						N_trackingNumber.text = this.trackingProduct.trackingNumber;
					}
				}).catch(() => {
					toaster.error('Une erreur est survenue lors de la sauvegarde');
				});
			}
		});
		const N_historyContainer = this.element.querySelector('#historyContainer');

		if (this.trackingProduct.history && this.trackingProduct.history.length) {
			const eventCards = h('div.cards');
			const lines = h('div.lines');
			//On trie les événements du plus récent au plus ancien
			this.trackingProduct.history.sort((prev: TrackingProductEvent, current: TrackingProductEvent) => current.date - prev.date);

			//Pour chaque événements on créé un card
			for (const historyEvent of this.trackingProduct.history) {
				const card = new TrackingProductEventCard(historyEvent);

				eventCards.appendChild(card);
				lines.appendChild(h('div.dot'));
				lines.appendChild(h('div.line'));
			}

			N_historyContainer!.appendChild(lines);
			N_historyContainer!.appendChild(eventCards);
		} else {
			N_historyContainer!.appendChild(h('p.text-center.text-muted', 'Aucun événement pour ce produit'));
		}

		this.initCloseBtn();
	}

	private initCloseBtn() {
		const closeBtns = this.element.querySelectorAll('.btn-close') as NodeListOf<HTMLButtonElement>;

		closeBtns.forEach(btn => {
			btn.addEventListener('click', () => {
				if (this.isChange) {
					this.resolve(this.trackingProduct);
				} else {
					this.reject();
				}
			});
		});
	}
}
export default TrackingProductHistory;
