// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import moment, { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/intervention/addLineInterventions.html';

// LIBS
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_User from '@js/services/User/UserService';

class AddLineInterventions extends Modal {
	constructor(data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		data = _.cloneDeep(data);

		data.date = data.date ? moment(data.date, 'YYYY-MM-DD').add(12, 'hours') : moment();

		this.on('opened', async () => {
			const N_users = this.element.querySelector('[name="users"]') as CE_Select2;

			N_users.create(this.element, { multiple: true });

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			data.users = data.users || [Utils.userID];

			for (const i in data.users) {
				data.users[i] = await S_User.getInstance().getDataToSelect2ByID(data.users[i]);
			}

			form.setData(data);

			N_users.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const data = form.getData();

				data.date = (data.date as Moment).format('YYYY-MM-DD');

				data.startTime = this.formatHours(data.startTime as string);
				data.endTime = this.formatHours(data.endTime as string);
				data.tripTime = this.formatHours(data.tripTime as string);

				this.resolve(data);
			});
		});
	}

	private formatHours(value: string) {
		const chunk = value.split(':');

		return ('00' + (chunk[0] || '')).slice(-2) + ':' + ('00' + (chunk[1] || '')).slice(-2);
	}
}

export default AddLineInterventions;
