import { MenuItemDef } from '@ag-grid-enterprise/all-modules';
import h from 'hyperscript';
import { utils } from '@autoprog/core-client';

import Decimal from '@libs/utils/Decimal';

import C_BillsProvider from '../../js/controllers/Bills.Provider';
import M_CreateBill from '../../js/modals/CreateBillProvider';

import CE_Aggrid from '@libs/customElement/AgGrid';
import PriceWithPercentModel from '@libs/model/_app/PriceWithPercent';

import S_P_Bill from '@services/Provider/ProviderBillService';

export type updateFormEvent = {
	priceHT: Decimal
	priceCredit: Decimal,
	notPaymentPrice: string
	paymentPrice: string
};

class BillsProviderTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-bills-providers-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private selectorTab: string = '';

	private idTab: string = '';

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || BillsProviderTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="bill-euro/line" icon-active="bill-euro/fill" text="Factures" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Factures
				<div class="page-tabs-title-right">
					<ap-button type="add" id="add-bill" permission="BILLS._CUSTOMERS.ADD"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButtons();
	}

	private async initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucune Facture' },
			defaultColDef: {
				suppressMenu: true,
				sortable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Index',
					field: 'infos.index',
					width: 100,
					sort: 'asc'
				}, {
					headerName: 'Numéro',
					field: 'infos.internalNumber'
				}, {
					headerName: 'Type',
					field: 'infos.type'
				}, {
					headerName: 'Date',
					field: 'infos.date'
				}, {
					headerName: 'Montant HT (€)',
					field: 'priceHT',
					cellClass: 'text-right  text-monospace'
				}, {
					headerName: 'Montant TTC (€)',
					field: 'priceTTC',
					cellClass: 'text-right text-monospace'
				}, {
					headerName: 'Montant à payer (€)',
					field: 'notPaymentPrice',
					cellClass: 'text-right  text-monospace'
				}, {
					headerName: 'Montant payé (€)',
					field: 'paymentPrice',
					cellClass: 'text-right text-monospace'
				}, {
					headerName: '',
					pinned: 'right',
					width: 80,
					editable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						}

						const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', tooltip: 'Editer', permission: 'BILLS._PROVIDERS.EDIT' } });

						N_edit.addEventListener('click', () => {
							C_BillsProvider.open(params.data._id.value);
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit);

						return N_div;
					}

				}
			],
			getContextMenuItems: (params) => {
				if (params.node) {
					const result: (MenuItemDef | string)[] = [
						{
							name: `N° ${params.node.data.infos.internalNumber.value}`,
							disabled: true,
							cssClasses: ['title-context-menu']
						}, {
							name: 'Éditer',
							icon: '<ap-icon name="edit/line"></ap-icon>',
							disabled: !params.node,
							action: () => {
								C_BillsProvider.open(params.node.data._id.value);
							}
						}
					];

					result.push({
						name: 'Créer un avoir',
						icon: '<ap-icon name="bill/line"></ap-icon>',
						disabled: params.node.data.isCredit.value,
						action: () => {
							new M_CreateBill(this.idOrder, params.node.data._id.value).setMode('create-credit').open();
						}
					});

					return result;
				} else {
					return [];
				}
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});
	}

	private initButtons() {
		const N_create = this.N_el!.querySelector('#add-bill') as HTMLButtonElement;

		N_create.addEventListener('click', () => {
			new M_CreateBill(this.idOrder).setMode('create-bill').open();
		});
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector<HTMLElement>('#number')!;

		if (this.N_grid!.isLoad) {
			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		} else {
			N_number.classList.add('d-none');
		}
	}

	private updatePinnedRowData() {
		const result = {
			priceHT: new Decimal(0),
			priceCredit: new Decimal(0),
			priceTTC: new Decimal(0),
			notPaymentPrice: new Decimal(0),
			paymentPrice: new Decimal(0)
		};

		this.N_grid!.forEachNode((node) => {
			const priceHT = Decimal.setDisplayNumber(node.data.priceHT.value);
			const priceTTC = Decimal.setDisplayNumber(node.data.priceTTC.value);
			const notPaymentPrice = Decimal.setDisplayNumber(node.data.notPaymentPrice.value);
			const paymentPrice = Decimal.setDisplayNumber(node.data.paymentPrice.value);

			if (node.data.infos.type.value === 'credit') {
				result.priceCredit = result.priceCredit.plus(priceHT);
			}

			result.priceHT = result.priceHT.plus(priceHT);
			result.priceTTC = result.priceTTC.plus(priceTTC);
			result.notPaymentPrice = result.notPaymentPrice.plus(notPaymentPrice);
			result.paymentPrice = result.paymentPrice.plus(paymentPrice);
		});

		result.priceHT = result.priceHT.toDecimalPlaces(2);
		result.priceTTC = result.priceTTC.toDecimalPlaces(2);
		result.notPaymentPrice = result.notPaymentPrice.toDecimalPlaces(2);
		result.paymentPrice = result.paymentPrice.toDecimalPlaces(2);

		this.N_grid!.pinnedBottomValue = [{
			priceHT: { value: result.priceHT.toNumber(), formattedValue: result.priceHT.setSuffixAndHumanizeNumber('€') },
			priceTTC: { value: result.priceTTC.toNumber(), formattedValue: result.priceTTC.setSuffixAndHumanizeNumber('€') },
			notPaymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.notPaymentPrice, result.priceTTC).toDashboard(),
			paymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.paymentPrice, result.priceTTC).toDashboard()
		}];

		this.dispatchEvent(new CustomEvent<updateFormEvent>('update.form', {
			detail: {
				priceHT: result.priceHT,
				priceCredit: result.priceCredit,
				notPaymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.notPaymentPrice, result.priceTTC).getText(),
				paymentPrice: PriceWithPercentModel.calculAndConvertToModel(result.paymentPrice, result.priceTTC).getText()
			}
		}));
	}

	public get sum() {
		let sum = new Decimal(0);

		this.N_grid!.forEachNode((node) => {
			const price = Decimal.setDisplayNumber(node.data.price.value);
			sum = price.plus(price);
		});

		return sum;
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public async initData() {
		if (this.idOrder) {
			const data = await S_P_Bill.getInstance().getByOrder(this.idOrder);

			this.dispatchEvent(new CustomEvent('load', { detail: { number: data.length } }));

			this.N_grid!.value = data;
		} else {
			this.N_grid!.value = [];
		}

		this.updatePinnedRowData();
	}

	public static register() {
		customElements.define(BillsProviderTab.tagName, BillsProviderTab);
	}
}

export default BillsProviderTab;
