import { ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { Form } from '@autoprog/core-client';

import _ from 'lodash';
import moment from 'moment';

import M_ExportDashboard from '@libs/modals/ExportDashboard';

import T_Modal from '../../tpl/modals/Export.html';

import Decimal from '@libs/utils/Decimal';

import CE_Select from '@libs/customElement/Select';

import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';
import Utils from '@js/libs/utils/Utils';

class Export extends M_ExportDashboard {
	private form: Form | null = null;

	private stock: string = '';

	constructor(gridOptions: GridOptions) {
		super(gridOptions, 'stock', T_Modal);
	}

	public setStock(stock: string) {
		this.stock = stock;
		return this;
	}

	protected async preInit() {
		const N_stock = this.element.querySelector<CE_Select>('[name="stock"]')!;
		const N_date = this.element.querySelector<HTMLInputElement>('[name="date"]')!;

		N_stock.options = {
			ajax: {
				url: new S_Stock().createSelect2URL(),
				getParams: (search) => {
					return {
						search,
						refData: JSON.stringify({ realStock: true })
					};
				}
			}
		};

		N_date.valueAsDate = new Date();

		N_stock.value = await S_Stock.getInstance().getDataToSelect2ByID(this.stock);
	}

	protected getColumns() {
		const colDef = (this.gridOptions.api?.getColumnDefs() || []) as ColDef[];
		const result: ColDef[] = [];

		for (const col of colDef) {
			if (!col.suppressColumnsToolPanel && !['_createBy_', 'lastOrder'].includes(col.field!)) {
				result.push(col);
			}
		}

		return result;
	}

	protected async getDataExport(header: { [key: string]: string }, separator: string = '') {
		const dataFilter: { [key: string]: string }[] = [];

		const N_stock = this.element.querySelector<CE_Select>('[name="stock"]')!;
		const N_date = this.element.querySelector<HTMLInputElement>('[name="date"]')!;
		const N_products_zero = this.element.querySelector<HTMLInputElement>('[name="products_zero"]')!;
		const N_products_negatives = this.element.querySelector<HTMLInputElement>('[name="products_negatives"]')!;

		const data = await S_StockEvent.getInstance().getCurrentStock({
			user: Utils.userID,
			date: N_date.valueAsDate?.getTime()
		});

		for (const item of data.rowData) {
			if (item.stock.value === N_stock.value && (N_products_zero.checked || item.quantity.value !== 0) && (N_products_negatives.checked || item.quantity.value >= 0)) {
				const obj: { [key: string]: string } = {};

				for (const key in header) {
					if (key === 'lastUpdate.date') {
						obj[key] = moment(_.get(item, key)).format('DD/MM/YYYY HH:mm');
					} else if (key === '_createBy_') {
						obj[key] = _.get(item, key)?.export;
					} else {
						obj[key] = _.get(item, key)?.export || '';

						if (typeof obj[key] === 'string' && separator) {
							if (obj[key].includes(separator)) {
								obj[key] = '"' + obj[key] + '"';
							}
						}
					}
				}

				dataFilter.push(obj);
			}
		}

		return dataFilter;
	}

	//TODO: a remettre avec la maj stokc
	public async exportExcel() {
		const data = this.form!.getData();

		data.date = data.date.valueOf();

		const stockEventsData = await S_StockEvent.getInstance().getDataToExport(data);

		const content: string[] = [];

		const productGroupByBrand = _.groupBy(stockEventsData.arrayProduct, 'brand');

		const keysBrand = Object.keys(productGroupByBrand).sort();

		const styleBorder = '0.5pt solid black;';

		for (const brand of keysBrand) {
			content.push(`
				<tr>
					<td colspan="6" style="background: #a8a8a8; border:${styleBorder}; border-right: 0px">${_.deburr(brand)}</td>
					<td style="background: #a8a8a8; border:${styleBorder};border-left: 0px">${Decimal.setDisplayNumber(stockEventsData.priceByBrand[brand].value).toExcel()}</td>
				</tr>
			`);

			let index = 0;
			for (const item of productGroupByBrand[brand]) {
				const firstCell = [`border-left: ${styleBorder}`];
				const middleCell = [''];
				const lastCell = [`border-right: ${styleBorder}`];

				if (index === 0) {
					firstCell.push(`border-top: ${styleBorder}`);
					middleCell.push(`border-top: ${styleBorder}`);
					lastCell.push(`border-top: ${styleBorder}`);
				}

				if (index === (productGroupByBrand[brand].length - 1)) {
					firstCell.push(`border-bottom: ${styleBorder}`);
					middleCell.push(`border-bottom: ${styleBorder}`);
					lastCell.push(`border-bottom: ${styleBorder}`);
				}

				content.push(`
					<tr>
						<td style="${firstCell.join('')}">${_.deburr(item.reference)}</td>
						<td style="${middleCell.join('')}">${_.deburr(item.brand)}</td>
						<td style="${middleCell.join('')}">${_.deburr(item.name)}</td>
						<td style="${middleCell.join('')}">${_.deburr(item.location)}</td>
						<td style="${middleCell.join('')}">${Decimal.setDisplayNumber(item.quantity).toExcel()}</td>
						<td style="${middleCell.join('')}">${Decimal.setDisplayNumber(item.price).toExcel()}</td>
						<td style="${lastCell.join('')}">${Decimal.setDisplayNumber(item.total).toExcel()}</td>
					</tr>
				`);
				index++;
			}
		}

		const result = `
			<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">'
				<head>
					<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
					<!--[if gte mso 9]>
						<xml>
							<x:ExcelWorkbook>
								<x:ExcelWorksheets>
									<x:ExcelWorksheet>
										<x:Name>Stock</x:Name>
										<x:WorksheetOptions>
											<x:DisplayGridlines/>
										</x:WorksheetOptions>
									</x:ExcelWorksheet>
								</x:ExcelWorksheets>
							</x:ExcelWorkbook>
						</xml>
						<![endif]-->
				</head>
				<body>
					<table>
						<thead>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder} border-left: ${styleBorder}">Référence</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Marque</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Désignation</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Emplacement</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Qte</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Prix</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder} border-right: ${styleBorder}">Total</th>
						</thead>
						<tbody>
							${content.join('')}
						</tbody>
					</table>
				</body>
			</html>
		`;

		const link = document.createElement('a');
		//[0xEF, 0xBB, 0xBF] = encoding UTF8
		const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), result], { type: 'data:application/vnd.ms-excel,' });
		const url = URL.createObjectURL(blob);
		link.href = url;
		link.download = `${moment(data.date as number, 'x').format('YYYY_MM_DD')}_Export_Stock.xls`;
		link.click();
	}
}

export default Export;
