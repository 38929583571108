import { Module, Modules, Router } from '@autoprog/core-client';

import C_Orders from './js/controllers/Orders';
import T_tabs from '@tpl/tabs.html';

import Navigation from '@libs/Navigation';

import '@modules/OrdersCustomer/module';
import '@modules/OrdersProvider/module';

class OrdersModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const navigation = Navigation.getInstance();
		navigation.addCategory({
			name: 'Documents',
			order: 100
		});

		navigation.addLink({
			order: 1,
			category: 'Documents',
			name: 'Commandes',
			type: 'module',
			module: 'orders',
			permissions: ['ORDERS._CUSTOMERS', 'ORDERS._PROVIDERS'],
			icon: 'inbox/line'
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/orders',
			controller: C_Orders as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/orders/:tabs',
			controller: C_Orders as any,
			tpl: T_tabs,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Commandes', OrdersModule);
