import h from 'hyperscript';

import Decimal from '@libs/utils/Decimal';
import PriceWithPercentModel from '@js/libs/model/_app/PriceWithPercent';

class RecapLine extends HTMLElement {
	public static readonly tagName: string = 'ap-billing-request-recap-line';

	private type: string = '';

	private priceLine = {
		sum: new Decimal(0),
		bill: new Decimal(0),
		notBill: new Decimal(0)
	};

	public connectedCallback() {
		this.type = this.getAttribute('type') || '';

		this.innerHTML = '';

		if (this.type === 'title' || this.type === 'sub-title') {
			this.displayTitle();
		} else {
			this.displayLine();
		}

		this.removeAttribute('type');
	}

	private displayTitle() {
		this.innerHTML = `
			<div class="line ${this.type} loading">
				<div class="col-left cell-loading">Je suis un text pour le skeleton loader</div>
				<div class="col-right cell-loading"></div>
			</div>
		`;
	}

	private displayLine() {
		this.innerHTML = `
			<div class="line ${this.type} line-price loading">
				<div class="col-left cell-loading">
					<div>
						<div class="text" id="category"></div>
						<div class="price" id="sum"></div>
					</div>
					<div>
						<div class="text" id="tva"></div>
						<div class="price" id="tva_percent"></div>
					</div>
					<div>
						<div class="text" id="text-bill"></div>
						<div class="price" id="bill"></div>
					</div>
					<div>
						<div class="text" id="text-notBill"></div>
						<div class="price" id="notBill"></div>
					</div>
				</div>

				<div class="col-right cell-loading">
					<div class="text" id="text-col-right"></div>
					<div>
						<ap-input-addon type="number" suffix="€" name="price"></ap-input-addon>
						<div class="d-none" id="readonly-price"></div>
					</div>
					<div>
						<ap-input-addon type="number" suffix="%" name="percent"></ap-input-addon>
						<div class="d-none" id="readonly-percent"></div>
					</div>
					<div>
						<ap-button class="btn-line" type="action" id="last">Restant</ap-button>
					</div>
				</div>
			</div>
		`;

		const N_border = h('div.line.line-border');
		this.append(N_border);

		if (this.type !== 'sum') {
			N_border.innerHTML = '<div class="col-left"></div><div class="col-right"></div>';

			const N_line = this.querySelector('.line.line-price')!;
			N_line.addEventListener('click', () => {
				if (!N_line.classList.contains('selected')) {
					this.dispatchEvent(new CustomEvent('select.group'));
				}
			});
		}

		const N_last = this.querySelector<HTMLButtonElement>('#last')!;

		N_last.addEventListener('click', () => {
			this.setLastPrice();

			if (this.type === 'sum') {
				this.dispatchEvent(new CustomEvent('update.lastPrice'));
			} else {
				this.dispatchEvent(new CustomEvent('update.percent'));
			}

			this.dispatchEvent(new CustomEvent('update.price'));
		});

		this.initEventInput();
	}

	private initEventInput() {
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;

		N_percent?.addEventListener('input', () => {
			this.updatePrice();
			this.dispatchEvent(new CustomEvent('update.percent'));
			this.dispatchEvent(new CustomEvent('update.price'));
		});

		N_price?.addEventListener('input', () => {
			this.updatePercent();
			this.checkError();
			this.dispatchEvent(new CustomEvent('update.price'));
			this.dispatchEvent(new CustomEvent('update.percent'));
		});
	}

	public setDataTitle(data: { textColLeft: string, textColRight: string }) {
		this.removeLoading();

		const N_line = this.querySelector('.line')!;

		const N_colLeft = N_line.querySelector('.col-left')!;
		const N_colRight = N_line.querySelector('.col-right')!;

		N_colLeft.innerHTML = data.textColLeft;
		N_colRight.innerHTML = data.textColRight;
	}

	public setDataTextLine(data: { category: string, textColRight: string, tva: string }) {
		this.removeLoading();

		const N_line = this.querySelector('.line')!;
		const N_category = N_line.querySelector('#category')!;
		const N_tva = N_line.querySelector('#tva')!;
		const N_textColRight = N_line.querySelector('#text-col-right')!;
		const N_textBill = N_line.querySelector('#text-bill')!;

		N_category.innerHTML = data.category;
		N_tva.innerHTML = data.tva;
		N_textColRight.innerHTML = data.textColRight;

		if (this.mode !== 'create-credit') {
			const N_textNotBill = N_line.querySelector('#text-notBill')!;

			const N_notBill = N_line.querySelector<HTMLElement>('#notBill')!;
			const notbillPrice = PriceWithPercentModel.calculAndConvertToModel(this.priceLine.notBill, this.priceLine.sum);

			N_textBill.innerHTML = 'Facturé :';
			N_textNotBill.innerHTML = 'Restant :';

			N_notBill.innerHTML = notbillPrice.toDocument();
		} else {
			N_textBill.innerHTML = 'Montant facture :';
		}
	}

	public setDataPriceLine(data: { sum: number, bill: number, notBill: number, tva: string }) {
		this.priceLine = {
			sum: Decimal.setDisplayNumber(data.sum),
			bill: Decimal.setDisplayNumber(data.bill),
			notBill: Decimal.setDisplayNumber(data.notBill)
		};

		const N_line = this.querySelector('.line.line-price')!;

		const N_sum = N_line.querySelector<HTMLElement>('#sum')!;
		const N_bill = N_line.querySelector<HTMLElement>('#bill')!;
		const N_tva = N_line.querySelector<HTMLElement>('#tva_percent')!;

		const billPrice = PriceWithPercentModel.calculAndConvertToModel(this.priceLine.bill, this.priceLine.sum);

		N_sum.innerHTML = this.priceLine.sum.setSuffixAndHumanizeNumber('€');
		N_bill.innerHTML = billPrice.toDocument();
		N_tva.innerHTML = data.tva;

		if (this.mode !== 'create-credit') {
			const N_notBill = N_line.querySelector<HTMLElement>('#notBill')!;
			const notbillPrice = PriceWithPercentModel.calculAndConvertToModel(this.priceLine.notBill, this.priceLine.sum);

			N_notBill.innerHTML = notbillPrice.toDocument();
		}
	}

	public removeLoading() {
		const N_line = this.querySelector('.line')!;

		N_line.classList.remove('loading');
	}

	public set price(data: number) {
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;
		N_price.valueAsNumber = data;
		this.updatePercent();
		this.dispatchEvent(new CustomEvent('update.price'));
		this.dispatchEvent(new CustomEvent('update.percent'));
	}

	public get price() {
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;
		return N_price.valueAsNumber || 0;
	}

	public get percent() {
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;
		return N_percent.valueAsNumber || 0;
	}

	public set idGroup(value: string) {
		this.setAttribute('id-group', value);
	}

	public get idGroup() {
		return this.getAttribute('id-group') || '';
	}

	public set tva(value: string) {
		this.setAttribute('id-tva', value);
	}

	public get tva() {
		return this.getAttribute('id-tva') || '';
	}

	public set mode(value: string) {
		this.setAttribute('mode', value);
	}

	public get mode() {
		return this.getAttribute('mode')!;
	}

	public get priceNotBill() {
		return this.priceLine.notBill;
	}

	private updatePercent() {
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;

		if (this.mode === 'create-credit') {
			if (this.priceLine.bill.toNumber() !== 0) {
				const percent = Decimal.setDisplayNumber(N_price.valueAsNumber).times(100).dividedBy(this.priceLine.bill);
				N_percent.valueAsNumber = percent.toNumber();
			} else {
				N_percent.valueAsNumber = 100;
			}
		} else {
			if (this.priceLine.sum.toNumber() !== 0) {
				const percent = Decimal.setDisplayNumber(N_price.valueAsNumber).times(100).dividedBy(this.priceLine.sum);
				N_percent.valueAsNumber = percent.toNumber();
			} else {
				N_percent.valueAsNumber = 100;
			}
		}

		this.updateRealonly();

		this.checkError();
	}

	private updatePrice() {
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;

		if (this.mode === 'create-credit') {
			const price = Decimal.setDisplayNumber(N_percent.valueAsNumber).times(this.priceLine.bill).dividedBy(100);
			N_price.valueAsNumber = price.toDecimalPlaces(2).toNumber();
		} else {
			const price = Decimal.setDisplayNumber(N_percent.valueAsNumber).times(this.priceLine.sum).dividedBy(100);
			N_price.valueAsNumber = price.toDecimalPlaces(2).toNumber();
		}

		this.updateRealonly();

		this.checkError();
	}

	public setPrice(value: number) {
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;
		N_price.valueAsNumber = value;

		this.checkError();

		this.updatePercent();

		this.dispatchEvent(new CustomEvent('update'));
	}

	public setPercentFromParent(value: number) {
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;
		N_percent.valueAsNumber = value;

		this.checkError();

		this.updatePrice();
	}

	public setLastPrice() {
		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;
		if (this.mode === 'create-credit') {
			N_price.valueAsNumber = this.priceLine.bill.toNumber();
		} else {
			N_price.valueAsNumber = this.priceLine.notBill.toNumber();
		}

		this.updatePercent();
		this.checkError();
	}

	public toggleLastPrice(isLast: boolean) {
		const N_readonlyPrice = this.querySelector<HTMLElement>('#readonly-price')!;
		const N_readonlyPercent = this.querySelector<HTMLElement>('#readonly-percent')!;

		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;

		const N_last = this.querySelector<HTMLButtonElement>('#last')!;

		N_readonlyPrice.classList.toggle('d-none', !isLast);
		N_readonlyPercent.classList.toggle('d-none', !isLast);
		N_price.parentElement!.classList.toggle('d-none', isLast);
		N_percent.parentElement!.classList.toggle('d-none', isLast);
		N_last.classList.toggle('d-none', isLast);
	}

	private updateRealonly() {
		const N_readonlyPrice = this.querySelector<HTMLElement>('#readonly-price')!;
		const N_readonlyPercent = this.querySelector<HTMLElement>('#readonly-percent')!;

		const N_price = this.querySelector<HTMLInputElement>('[name="price"]')!;
		const N_percent = this.querySelector<HTMLInputElement>('[name="percent"]')!;

		N_readonlyPrice.innerHTML = Decimal.setDisplayNumber(N_price.valueAsNumber).setSuffixAndHumanizeNumber('€');
		N_readonlyPercent.innerHTML = Decimal.setDisplayNumber(N_percent.valueAsNumber).setSuffixAndHumanizeNumber('%');
	}

	public checkError() {
		const N_line = this.querySelector('.line.line-price')!;

		if (this.mode === 'create-credit') {
			if (this.price >= 0) {
				if (Decimal.setDisplayNumber(this.price).greaterThan(this.priceLine.bill)) {
					N_line.classList.add('error');
				} else {
					N_line.classList.remove('error');
				}
			} else {
				if (Decimal.setDisplayNumber(this.price).lessThan(this.priceLine.bill)) {
					N_line.classList.add('error');
				} else {
					N_line.classList.remove('error');
				}
			}
		} else {
			if (this.price >= 0) {
				if (Decimal.setDisplayNumber(this.price).greaterThan(this.priceLine.notBill)) {
					N_line.classList.add('error');
				} else {
					N_line.classList.remove('error');
				}
			} else {
				if (Decimal.setDisplayNumber(this.price).lessThan(this.priceLine.notBill)) {
					N_line.classList.add('error');
				} else {
					N_line.classList.remove('error');
				}
			}
		}

		this.dispatchEvent(new CustomEvent('check.error'));
	}

	public get error() {
		const N_line = this.querySelector('.line.line-price')!;
		return N_line?.classList.contains('error') || false;
	}

	public select() {
		const N_line = this.querySelector('.line.line-price')!;
		N_line.classList.add('selected');
	}

	public unselect() {
		const N_line = this.querySelector('.line.line-price')!;
		N_line.classList.remove('selected');
	}

	public static register() {
		customElements.define(RecapLine.tagName, RecapLine);
	}
}

export default RecapLine;
