// CORE
import CSV from '@autoprog/csv';
import { Controller } from '@autoprog/core-client';

// NODE_MODULE
import moment, { Moment } from 'moment';

// LIBS
import Decimal from '@libs/utils/Decimal';
import ExportUtils from '@libs/utils/ExportUtils';
import drpC from '@libs/utils/daterangepickerConfig';

import CE_Aggrid from '@libs/customElement/AgGrid';

import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

// SERVICE
import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Product from '@services/Product/ProductService';

class RecapProviderController extends Controller {
	private element: HTMLElement;

	private N_provider: CE_Aggrid;
	private N_products: CE_Aggrid;

	private dataByProvider: { [key: string]: any[] } = {};
	private dataProvider: { [key: string]: any }[] = [];

	private currentProviderRow: { [key: string]: any } = {};

	private startDate: Moment;
	private endDate: Moment;

	constructor(el: HTMLElement) {
		super(el);

		this.element = el;

		this.startDate = moment().subtract(1, 'year');
		this.endDate = moment();

		this.N_provider = this.element.querySelector<CE_Aggrid>('#providers .grid')!;
		this.N_products = this.element.querySelector<CE_Aggrid>('#products .grid')!;

		this.init();

		this.getData();
	}

	private init() {
		this.N_provider.setGridOptions({
			localeText: { noRowsToShow: 'Aucun Fournisseur' },
			animateRows: true,
			rowSelection: 'single',
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Fournisseur',
					field: 'provider',
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					}
				},
				{
					headerName: 'Nb CF',
					field: 'number',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				}
			],
			onSelectionChanged: (params) => {
				const selectedRows = params.api.getSelectedRows();

				this.updateProvider(selectedRows[0]);

				const data: { [key: string]: any }[] = [];
				let deliveryPrice: { [key: string]: any } = {};

				for (const item of this.dataByProvider[this.currentProviderRow.idProvider.value]) {
					if (item.product._id.value === 'deliveryPrice') {
						deliveryPrice = item;
					} else {
						data.push(item);
					}
				}

				this.N_products.value = data || [];
				this.N_products.pinnedBottomValue = [deliveryPrice];
			}
		});

		this.N_products.setGridOptions({
			localeText: { noRowsToShow: 'Aucun Produit' },
			rowData: [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product._id',
					filter: 'agTextColumnFilter',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: ProductCellRenderer
				},
				{
					headerName: 'Désignation',
					field: 'product.label',
					filter: 'agTextColumnFilter'
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					filter: 'agSetColumnFilter',
					width: 200,
					suppressSizeToFit: true
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: 'Montant total',
					field: 'price',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						suffix: '€'
					}
				}
			]
		});

		const N_export = this.element.querySelector('#export') as HTMLButtonElement;
		const N_exportByProvider = this.element.querySelector('#exportByProvider') as HTMLButtonElement;
		const N_deliveryPrice = this.element.querySelector('#deliveryPrice') as HTMLButtonElement;
		const N_datePicker = this.element.querySelector('#date-picker') as HTMLInputElement;

		N_export.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			for (const provider in this.dataByProvider) {
				data.push(...this.getDataExportByProvider(provider));
			}

			const title = `${this.startDate.format('YYYY_MM_DD')}_${this.endDate.format('YYYY_MM_DD')}_Export-Commande-Fournisseur`;

			this.exportData(data, title);
		});

		N_deliveryPrice.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			for (const provider in this.dataByProvider) {
				data.push(...this.getDataExportByProvider(provider, true));
			}

			const title = `${this.startDate.format('YYYY_MM_DD')}_${this.endDate.format('YYYY_MM_DD')}_Export-Frais-de-Port`;

			this.exportData(data, title);
		});

		N_exportByProvider.addEventListener('click', () => {
			const data = this.getDataExportByProvider(this.currentProviderRow.idProvider.value);
			const title = `${this.startDate.format('YYYY_MM_DD')}_${this.endDate.format('YYYY_MM_DD')}_Export-Commande-Fournisseur-${this.currentProviderRow.provider.formattedValue}`;

			this.exportData(data, title);
		});

		const N_input = $(N_datePicker);

		N_input.daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate
		}));

		N_input.on('apply.daterangepicker', (ev, picker) => {
			this.startDate = picker.startDate;
			this.endDate = picker.endDate;

			this.getData();
		});
	}

	private getDataExportByProvider(provider: string, onlyDelivery: boolean = false) {
		const tmp: { [key: string]: any }[] = [];
		let deliveryPrice: { [key: string]: any } = {};

		const dataProvider = this.dataProvider.find(l => l.idProvider.value === provider) || {};

		for (const item of this.dataByProvider[provider]) {
			if (item.product._id.value === 'deliveryPrice') {
				deliveryPrice = {
					provider: dataProvider.provider.export,
					_id: item.product._id.export,
					label: item.product.label.export,
					brand: item.product.brand.export,
					quantity: item.quantity.formattedValue,
					price: Decimal.setDisplayNumber(item.price.export).toExcel()
				};
			} else {
				if (!onlyDelivery) {
					tmp.push({
						provider: dataProvider.provider.export,
						reference: item.product.reference.export,
						label: item.product.label.export,
						brand: item.product.brand.export,
						quantity: item.quantity.formattedValue,
						price: Decimal.setDisplayNumber(item.price.export).toExcel()
					});
				}
			}
		}

		return [...tmp, deliveryPrice];
	}

	private exportData(data: { [key: string]: any }[] = [], title: string) {
		const csv = CSV.stringify(data, {
			provider: 'Fournisseur',
			reference: S_Product.getInstance().columnNameReference,
			label: 'Nom',
			brand: 'Marque',
			quantity: 'Quantité',
			price: 'Montant Total'
		}, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private updateProvider(provider: { [key: string]: any } | null = null) {
		const N_providerSelected = this.element.querySelector('#provider-selected') as HTMLElement;
		const N_exportByProvider = this.element.querySelector('#exportByProvider') as HTMLButtonElement;

		if (provider) {
			this.currentProviderRow = provider;

			N_exportByProvider.disabled = false;
			N_providerSelected.innerHTML = `Fournisseur selectionné : ${this.currentProviderRow.provider.formattedValue}`;
		} else {
			this.currentProviderRow = {};

			N_exportByProvider.disabled = true;

			N_providerSelected.innerHTML = 'Fournisseur selectionné : aucun';

			this.N_products.value = [];
		}
	}

	private async getData() {
		const N_spinner = this.element.querySelector('#spinner') as HTMLElement;
		N_spinner.classList.remove('d-none');
		N_spinner.classList.add('d-flex');

		this.updateProvider();

		const { data, providers } = await S_P_Order.getInstance().getDataToRecap(this.startDate.valueOf(), this.endDate.valueOf());

		this.dataByProvider = data;
		this.dataProvider = providers;

		this.N_provider.value = providers;

		N_spinner.classList.add('d-none');
		N_spinner.classList.remove('d-flex');
	}

	public destructor() {
	}
}

export default RecapProviderController;
