import { Module, Modules, Router } from '@autoprog/core-client';

import T_MaterialCustomer from './tpl/material.customer.html';
import T_OrdersCustomer from './tpl/orders.customer.html';
import T_RecapCustomer from './tpl/recap.customer.html';

import C_MaterialCustomer from './js/controllers/Material.customer';
import C_OrdersCustomer from './js/controllers/Orders.Customer';
import C_RecapCustomer from './js/controllers/Recap.customer';

import CE_BillingRequestTab from './js/customElements/BillingRequestTab';
import CE_MaterialsTab from './js/customElements/MaterialsTab';
import CE_OutputStockTab from './js/customElements/OutputStockTab';
import CE_QuotesTab from './js/customElements/QuotesTab';

import CE_RecapLine from './js/customElements/editBillingRequest/RecapLine';
import CE_RecapOrder from './js/customElements/editBillingRequest/RecapOrder';
import CE_RecapQuote from './js/customElements/editBillingRequest/RecapQuote';
import CE_ViewGroupQuote from './js/customElements/editBillingRequest/ViewGroupQuote';

import CE_GroupQuotesTab from './js/customElements/GroupQuotesTab';
import CE_UsersTab from './js/customElements/UsersTab';
import Notifications from '@modules/Apps/js/libs/Notifications';
import SettingsNotificationRegister from '@modules/Notifications/js/libs/SettingsNotificationRegister';

class OrderCustomerModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_MaterialsTab.register();
		CE_OutputStockTab.register();
		CE_QuotesTab.register();
		CE_BillingRequestTab.register();

		CE_RecapOrder.register();
		CE_RecapQuote.register();
		CE_RecapLine.register();
		CE_ViewGroupQuote.register();

		CE_GroupQuotesTab.register();
		CE_UsersTab.register();
	}

	public async preInit() { }

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/ordersPage/customer',
			controller: C_OrdersCustomer as any,
			tpl: T_OrdersCustomer,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands_export/material/customer',
			controller: C_MaterialCustomer as any,
			tpl: T_MaterialCustomer,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands_export/customer',
			controller: C_RecapCustomer as any,
			tpl: T_RecapCustomer,
			requireValidUser: true
		});

		const settingsNotificationRegister = SettingsNotificationRegister.getInstance();

		settingsNotificationRegister.addInput({
			text: 'Commande - client',
			select: [{
				text: 'Responsable planning',
				isSingle: true,
				name: 'command_customers.calendarManager'
			}, {
				text: 'Responsable achat',
				isSingle: true,
				name: 'command_customers.purchaseManager'
			}, {
				text: 'Intervenants',
				name: 'command_customers.users'
			}, {
				text: 'Informations',
				name: 'command_customers.infos'
			}, {
				text: 'Demandes de facturation',
				name: 'command_customers.billingRequest.infos'
			}]
		}, 0);

		Notifications.addCategory(['command-customers'], {
			textFilter: 'Commande Client',
			isFilter: true,
			icon: 'icon-solid-file-alt',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_OrdersCustomer.open(item.data.id, { notification: item._id });
				}
			}
		});
	}

	public async postInit() { }
}

Modules.registerModule('Commandes-Client', OrderCustomerModule);
