import GenericService from './GenericService';

class ProjectsService extends GenericService {
	private static instance: ProjectsService | null = null;

	constructor() {
		super('projects');
	}

	public static getInstance(): ProjectsService {
		if (!ProjectsService.instance) {
			ProjectsService.instance = new ProjectsService();
		}

		return ProjectsService.instance;
	}
}

export default ProjectsService;
