import { Alert, Router, global } from '@autoprog/core-client';

import _ from 'lodash';

import C_CommandCustomer from '@modules/OrdersCustomer/js/controllers/Orders.Customer';
import C_Database from '@js/controllers/Database';
import C_Quote from '../controllers/AddQuotes';

import M_SaveAs from '../modals/AddQuote/SaveAs';
import M_SetRelunchDate from '@libs/modals/SetRelunchDate';

import M_ChangeState from '../modals/AddQuote/ChangeState';

import Utils from '@libs/utils/Utils';

import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';

import Decimal from '@libs/utils/Decimal';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import CE_FilesIcon from '@libs/customElement/FilesIcon';

class Quote extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Devis',
			type: 'database',
			database: 'quotes'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;
		N_add.setAttribute('ap-hidden', 'mobile');

		N_add.addEventListener('click', () => {
			C_Quote.open(null);
		}, {
			signal: this.abortSignal
		});
	}

	protected iconsColumn(params: any) {
		const result: HTMLElement[] = [];

		const N_open = document.createElement('ap-open-document') as HTMLElement;
		N_open.dataset.id = params.data._id;
		N_open.dataset.table = 'quotes';
		result.push(N_open);

		const N_icon = document.createElement('ap-files-icon') as CE_FilesIcon;
		N_icon.data = params.data;
		result.push(N_icon as HTMLElement);

		if (params.data.notFinish) {
			const not_finish = document.createElement('i');
			not_finish.classList.add('icon', 'h5', 'icon-solid-flag', 'mr-2');
			not_finish.setAttribute('tooltip', 'Devis à finir');

			result.push(not_finish);
		}

		return result;
	}

	protected async buttonEdit(params: any) {
		if (!global.IS_MOBILE) {
			const tmp = _.find(global.OPEN_QUOTES, { id: params.data._id }) as any;

			if (tmp) {
				Alert.confirm('Devis déja ouvert', `
                    Ce devis est ouvert par ${tmp.user} <br>
                    Depuis ${tmp.data.date}
                `).then(() => {
					C_Quote.open(params.data._id);
				}).catch(() => {

				});
			} else {
				C_Quote.open(params.data._id);
			}
		}
	}

	protected async buttonDuplicate(params: any) {
		new M_SaveAs(params.value).open().then(() => {
			this.refreshData();
		});
	}

	protected customMobile(item: { [key: string]: any }, N_el: HTMLElement) {
		const router = Router.getInstance();

		const N_view = N_el.querySelector('#view-quote') as HTMLButtonElement;

		N_view.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			router.navigate(`#module/quote/view?id=${item._id}`);
		});
	}

	protected getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		if (params.node) {
			const result: any[] = [{
				name: 'Définir prochaine relance',
				icon: '<i class="icon icon-solid-redo"></i>',
				disabled: params.node.data.infos?.relunch?.disabled || !params.node.data.needRelaunch,
				action: async () => {
					new M_SetRelunchDate('quotes', params.node.data._id).open().then(() => {
						this.refreshData();
					});
				}
			}, 'separator', {
				name: 'Changement état',
				action: () => {
					new M_ChangeState(params.node.data._id).open().then(() => {
						this.refreshData();
					}).catch(() => { });
				}
			}];

			const order = params.node.data.contextMenu?.order;

			if (order) {
				result.push({
					name: `Voir commande (N° ${order.internalNumber})`,
					icon: '<i class="icon icon-solid-file-alt"></i>',
					action: () => {
						C_CommandCustomer.open(order._id);
					}
				});
			}

			return [
				...super.getContextMenu(params),
				'separator',
				...result
			];
		} else {
			return [];
		}
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			//a relancer
			if (params.data.needRelaunch) {
				return {
					'background-color': 'var(--ap-blue-50)',
					color: 'var(--ap-blue-900)'
				};
			}

			//pret a etre envoyé
			if (params.data.infos.state === '5') {
				return {
					'background-color': 'var(--ap-cyan-50)',
					color: 'var(--ap-cyan-900)'
				};
			}

			//à valider
			if (params.data.infos.state === '6') {
				return {
					'background-color': 'var(--ap-grey-50)',
					color: 'var(--ap-grey-900)'
				};
			}

			//en attente commande
			if (params.data.infos.state === '4') {
				return {
					'background-color': 'var(--ap-orange-50)',
					color: 'var(--ap-orange-900)'
				};
			}

			//refusé ou révisé
			if (params.data.infos.state === '7' || params.data.infos.state === '2') {
				return {
					'background-color': 'var(--ap-red-50)',
					color: 'var(--ap-red-900)'
				};
			}

			//accepté
			if (params.data.infos.state === '1') {
				return {
					'background-color': 'var(--ap-green-50)',
					color: 'var(--ap-green-900)'
				};
			}

			//envoyé
			if (params.data.infos.state === 'send') {
				return {
					'background-color': 'var(--ap-purple-50)',
					color: 'var(--ap-purple-900)'
				};
			}

			//a finir
			if (params.data.notFinish) {
				return {
					'background-color': 'var(--ap-blue-grey-50)',
					color: 'var(--ap-blue-grey-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected onDataUpdate(params: any) {
		let price = new Decimal(0);
		let priceWithoutOptions = new Decimal(0);
		let optionsPrice = new Decimal(0);

		params.api.forEachNodeAfterFilter((node: any) => {
			if (node.data.globalPrice) {
				price = price.plus(Decimal.setDisplayNumber(node.data.globalPrice.value));
			}

			if (node.data.globalPriceWithoutOption) {
				priceWithoutOptions = priceWithoutOptions.plus(Decimal.setDisplayNumber(node.data.globalPriceWithoutOption.value));
			}

			if (node.data.globalPriceOption) {
				optionsPrice = optionsPrice.plus(Decimal.setDisplayNumber(node.data.globalPriceOption.value));
			}
		});

		this.setDataTitle(
			[
				{
					label: 'Montant total',
					value: price.setSuffixAndHumanizeNumber('€')
				},
				{
					label: 'Dont options',
					value: optionsPrice.setSuffixAndHumanizeNumber('€')
				},
				{
					label: 'Montant sans options',
					value: priceWithoutOptions.setSuffixAndHumanizeNumber('€')
				}
			]);
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: 'infos.state',
					type: 'checkbox',
					checkboxListTitle: 'États',
					filters: [
						{
							value: '0',
							label: 'En cours',
							hideIcon: true,
							leftIndent: true
						},
						{
							value: '5',
							label: 'Prêt à être envoyé',
							backgroundColor: 'var(--ap-cyan-50)',
							hideIcon: true,
							leftIndent: true
						},
						{
							value: '6',
							label: 'À valider',
							backgroundColor: 'var(--ap-grey-50)',
							hideIcon: true,
							leftIndent: true
						},

						{
							value: 'send',
							label: 'Envoyé',
							backgroundColor: 'var(--ap-purple-50)',
							hideIcon: true,
							leftIndent: true
						},
						{
							value: '1',
							label: 'Accepté',
							backgroundColor: 'var(--ap-green-50)',
							hideIcon: true,
							leftIndent: true
						},
						{
							value: '2',
							label: 'Refusé',
							backgroundColor: 'var(--ap-red-50)',
							hideIcon: true,
							leftIndent: true
						},
						{
							value: '4',
							label: 'Attente commande',
							backgroundColor: 'var(--ap-orange-50)',
							hideIcon: true,
							leftIndent: true
						},
						{
							value: '7',
							label: 'Révisé',
							backgroundColor: 'var(--ap-red-50)',
							hideIcon: true,
							leftIndent: true
						}
					]
				}
			], [
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							label: 'Mes devis',
							hideIcon: true
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'notFinish',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'À finir',
							backgroundColor: 'var(--ap-blue-grey-50)',
							hideIcon: true
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'needRelaunch',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'À relancer',
							backgroundColor: 'var(--ap-blue-50)',
							hideIcon: true
						}
					]
				}, {
					type: 'separator'
				}, {
					checkboxListTitle: 'Notifications : ',
					type: 'title'
				}, {
					column: 'hasNotif.notFinish',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'À finir',
							hideIcon: true,
							leftIndent: true
						}
					]
				}, {
					column: 'hasNotif.validateQuote',
					type: 'switch',
					filters: [
						{
							value: 'true',
							label: 'À Valider',
							hideIcon: true,
							leftIndent: true
						}
					]
				}
			]
		];
	}
}

export default Quote;
