// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/amounts.html';

// LIBS
import Modal from '@libs/EditPageModal';

import CE_PriceBillProvider from '../../customElements/PriceBillProvider';

import S_P_Bill from '@services/Provider/ProviderBillService';

type AmountsBillData = {
	_id?: string,
	order: string,
	infos: {
		type: string
	},
	billOrigin: string
	priceHT: number,
	tva: { [IDTVA: string]: number }
};

class Amounts extends Modal<AmountsBillData> {
	private order: string = '';
	private type: string = '';
	private billOrigin: string = '';
	private billTVA: Object = {};

	constructor(data: AmountsBillData) {
		super(data, T_modal);
	}

	protected async preInit(data: AmountsBillData) {
		this.order = data.order;
		this.type = data.infos.type;
		this.billOrigin = data.billOrigin;
		this.billTVA = data.tva;
	}

	private async initPriceBill() {
		const N_PriceBillProvider = this.element.querySelector<CE_PriceBillProvider>(CE_PriceBillProvider.tagName)!;

		N_PriceBillProvider.addEventListener('update', () => {
			N_PriceBillProvider.checkError();
		});

		let billState = {};

		if (this.type === 'credit' || this.type === 'credit-error') {
			billState = await S_P_Bill.getInstance().getBillStateByID(this.billOrigin!);
		} else {
			billState = await S_P_Bill.getInstance().getBillStateByOrder(this.order, this.id);
		}

		await N_PriceBillProvider.init(billState, this.billTVA);
	}

	protected async setData(data: AmountsBillData) {
		await this.initPriceBill();

		const N_PriceBillProvider = this.element.querySelector<CE_PriceBillProvider>(CE_PriceBillProvider.tagName)!;
		N_PriceBillProvider.data = data;
	}

	protected async getDataForm() {
		const N_PriceBillProvider = this.element.querySelector<CE_PriceBillProvider>(CE_PriceBillProvider.tagName)!;
		return N_PriceBillProvider.data;
	}
}

export default Amounts;
