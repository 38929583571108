import { Form, toaster } from '@autoprog/core-client';
import _ from 'lodash';
import h from 'hyperscript';

import SettingsApps from '@libs/Settings';

import CE_Select2 from '@libs/customElement/Select2';

import S_Users from '@services/User/UserService';

import SettingsNotificationRegister from '../libs/SettingsNotificationRegister';

import SettingsController from '@modules/Settings/js/libs/SettingsController';

import '../../css/settings.notifcation.scss';

class SettingsNotificationTabs extends SettingsController {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private keys: string[] = [];
	private isSingle: { [key: string]: boolean } = {};

	protected init(): void {
		this.generateHTML();

		const settings = SettingsApps.getInstance();

		const form = new Form(this.el.querySelector('form') as HTMLFormElement);

		this.convertData().then((data) => {
			form.setData(data);

			this.postInit();
		});

		const N_save = this.el.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			settings.setGeneral('NOTIFICATION', form.getData());

			toaster.success('Sauvegarde réussi');
		});
	}

	private async convertData() {
		const settings = SettingsApps.getInstance();

		let data: { [key: string]: any } = (settings.get('NOTIFICATION') || {}) as { [key: string]: any };

		data = _.cloneDeep(data);

		for (const key of this.keys) {
			const values = _.get(data, key);

			if (values) {
				if (this.isSingle[key]) {
					const selectData = await S_Users.getInstance().getDataToSelect2ByID(values);

					_.set(data, key, selectData);
				} else {
					const res: { [key: string]: string }[] = [];

					for (const item of values) {
						const selectData = await S_Users.getInstance().getDataToSelect2ByID(item);
						res.push(selectData);
					}

					_.set(data, key, res);
				}
			}
		}

		return data;
	}

	private generateHTML() {
		const N_container = this.el.querySelector('#content_form') as HTMLElement;

		const links = SettingsNotificationRegister.getInstance().links;

		for (const col of links) {
			for (const category of col) {
				const N_category = h('div.block-notification');
				N_category.innerHTML = `<div class="block-title">${category.text || ''}</div>`;

				const N_blockContainerItems = h('div.block-container-items');

				for (const item of category.select) {
					const N_item = h('div.block-item');

					N_item.innerHTML = `
						<div class="label">${item.text || {}}</div>							
						<ap-select2-button name="${item.name}" table="users" hidden-btn-add="true" hidden-btn-edit="true">
						</ap-select2-button>
					`;

					this.isSingle[item.name] = !!item.isSingle;

					this.keys.push(item.name);

					this.selectPostinit[item.name] = N_item.querySelector(`[name="${item.name}"]`) as CE_Select2;

					N_blockContainerItems.appendChild(N_item);
				}

				N_category.appendChild(N_blockContainerItems);

				N_container.appendChild(N_category);
			}
		}

		for (const name in this.selectPostinit) {
			this.selectPostinit[name].create(this.el, { multiple: !this.isSingle[name] });
		}
	}

	private postInit() {
		for (const name in this.selectPostinit) {
			this.selectPostinit[name].postInit();
		}
	}
}

export default SettingsNotificationTabs;
