import moment from 'moment';

import { ElementForm } from '..';

export default (N_input: ElementForm, value: any) => {
	if (N_input instanceof HTMLTextAreaElement) {
		N_input.value = value;
		return;
	}

	if (N_input instanceof HTMLInputElement) {
		switch (N_input.type) {
			case 'checkbox':
				N_input.checked = value;
				return;

			case 'number':
			case 'range':
				N_input.valueAsNumber = value;
				return;

			case 'date':
			case 'month':
			case 'time':
			case 'week':
			case 'datetime-local':
				N_input.valueAsDate = value;
				return;

			default:
				if (value instanceof Date) {
					(N_input as HTMLInputElement).valueAsDate = value;
				} else if (moment.isMoment(value)) {
					(N_input as HTMLInputElement).valueAsDate = value.toDate();
				} else if (typeof value === 'number') {
					(N_input as HTMLInputElement).valueAsNumber = value;
				} else {
					(N_input as HTMLInputElement).value = value as string;
				}
				return;
		}
	}

	if (N_input instanceof HTMLSelectElement) {
		if (N_input.multiple) {
			for (const option of N_input.options) {
				option.selected = value.includes(option.value);
			}
			return;
		}

		N_input.value = value;
		return;
	}

	if ('value' in N_input) {
		(N_input as any).value = value;
	}
};
