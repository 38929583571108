import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-community/core';
import C_database from '@js/controllers/Database';

import TrackingProductHistory from '../modals/TrackingProductHistory';

import h from 'hyperscript';
class TrackingProductsController extends C_database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Suivi de Matériels',
			database: 'tracking-products'
		});
	}

	protected getContextMenu(params: GetContextMenuItemsParams): (MenuItemDef | string)[] {
		const result: (MenuItemDef | string)[] = [];

		result.push({
			name: 'Voir l\'historique',
			icon: '<i class="icon icon-solid-history"></i>',
			disabled: !params.node,
			action: () => {
				this.openHistoryModal(params);
			}
		});

		return result;
	}

	protected addOnButtonGrid(params: any): HTMLElement[] {
		const historyBtn = h('button.btn-transparent.h-100.py-0', { attrs: { tooltip: 'Voir historique' } }, h('i.icon.icon-solid-history.h6')) as HTMLButtonElement;
		historyBtn.addEventListener('click', () => {
			this.openHistoryModal(params);
		});

		return [historyBtn];
	}

	/**
	 * Gère l'ouverture de la modal de l'historique du produit suivi
	 * @param params
	 */
	private openHistoryModal(params: any) {
		new TrackingProductHistory(params.node.data).open()
			.then((res) => {
				params.node!.setData(res);
				params.api!.refreshCells({ force: true, rowNodes: [params.node] });
			})
			.catch(() => { });
	}
}

export default TrackingProductsController;
