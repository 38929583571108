//CORE
//LIBS
import Modal from '@libs/Modal';

//TEMPLATE
import T_modal from '@tpl/modals/mobileSelectMultipleFilter.html';

//STYLE
import '@css/modals/mobile-sort-select-multiple-filter.scss';

class MobileSelectMultipleFilter extends Modal {
	private possibleValues: { [key: string]: string } = {};

	private selectedKeys: string[] = [];

	private N_optionAllCheckbox: HTMLInputElement | null = null;
	private checkboxes: HTMLInputElement[] = [];

	constructor(selectedKeys: string[], possibleValues: { [key: string]: string }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			this.selectedKeys = selectedKeys;
			this.updateTitleCount();

			// On trie la liste par ordre alphabétique
			this.possibleValues = possibleValues;

			this.sortPossibleValues();
			this.renderFilterList();
			this.initSearchbar();

			const N_reset = this.element.querySelector('#reset') as HTMLButtonElement;

			N_reset.addEventListener('click', () => {
				this.selectedKeys = [];
				this.updateTitleCount();
				this.renderFilterList();
				this.updateOptionAllCheckbox();
			});

			const N_apply = this.element.querySelector('#apply') as HTMLButtonElement;

			N_apply.addEventListener('click', () => {
				this.resolve(this.selectedKeys);
			});
		});
	}

	private sortPossibleValues() {
		this.possibleValues = Object.keys(this.possibleValues)
			.sort()
			.reduce((result: { [key: string]: string }, key) => {
				result[key] = this.possibleValues[key];
				return result;
			}, {});
	}

	private renderFilterList() {
		const N_filterListHeader = this.element.querySelector('#filter-list-header') as HTMLDivElement;
		N_filterListHeader.innerHTML = '';

		const N_filterList = this.element.querySelector('#filter-list') as HTMLDivElement;
		N_filterList.innerHTML = '';

		const N_optionAllContainer = document.createElement('div') as HTMLDivElement;
		N_optionAllContainer.classList.add('d-flex', 'w-100');

		const N_optionAllName = document.createElement('label') as HTMLLabelElement;
		N_optionAllName.innerHTML = 'Tout sélectionner';
		N_optionAllName.setAttribute('for', 'all-list-filter-checkbox');

		this.N_optionAllCheckbox = document.createElement('input') as HTMLInputElement;
		this.N_optionAllCheckbox.classList.add('ml-auto');
		this.N_optionAllCheckbox.type = 'checkbox';
		this.N_optionAllCheckbox.id = 'all-list-filter-checkbox';
		this.N_optionAllCheckbox.addEventListener('change', () => {
			this.toggleAllCheckboxes(this.N_optionAllCheckbox!.checked);
		});

		N_optionAllContainer.append(N_optionAllName);
		N_optionAllContainer.append(this.N_optionAllCheckbox);

		N_filterListHeader.append(N_optionAllContainer);

		this.checkboxes = [];
		for (const key in this.possibleValues) {
			const N_optionContainer = document.createElement('div') as HTMLDivElement;
			N_optionContainer.classList.add('d-flex', 'w-100', 'filter-multiple-option-container');

			const id = key + '-checkbox';

			const N_optionName = document.createElement('label') as HTMLLabelElement;
			N_optionName.innerHTML = this.possibleValues[key];
			N_optionName.setAttribute('for', id);

			const N_optionCheckbox = document.createElement('input') as HTMLInputElement;
			N_optionCheckbox.classList.add('ml-auto');
			N_optionCheckbox.type = 'checkbox';
			N_optionCheckbox.id = id;
			N_optionCheckbox.name = this.possibleValues[key];
			N_optionCheckbox.value = key;
			N_optionCheckbox.checked = this.selectedKeys.includes(key);
			N_optionCheckbox.addEventListener('change', () => {
				const checked = N_optionCheckbox.checked;
				if (checked) {
					this.selectedKeys.push(key);
				} else {
					this.selectedKeys = this.selectedKeys.filter((item) => item !== key);
				}
				this.updateTitleCount();
				this.updateOptionAllCheckbox();
			});

			this.checkboxes.push(N_optionCheckbox);

			N_optionContainer.append(N_optionName);
			N_optionContainer.append(N_optionCheckbox);

			N_filterList.append(N_optionContainer);
		}

		this.updateOptionAllCheckbox();
	}

	private toggleAllCheckboxes(checked: boolean) {
		for (const N_checkbox of this.checkboxes) {
			if (!N_checkbox.parentElement!.classList.contains('d-none')) {
				N_checkbox.checked = checked;

				if (checked) {
					if (!this.selectedKeys.includes(N_checkbox.value)) {
						this.selectedKeys.push(N_checkbox.value);
					}
				} else {
					this.selectedKeys = this.selectedKeys.filter((item) => item !== N_checkbox.value);
				}
				this.updateTitleCount();
			}
		}
	}

	private updateOptionAllCheckbox() {
		let checkedCount = 0;
		let lengthOfVisibles = 0;

		for (const N_checkbox of this.checkboxes) {
			if (!N_checkbox.parentElement!.classList.contains('d-none')) {
				lengthOfVisibles++;
				if (N_checkbox.checked) {
					checkedCount++;
				}
			}
		}

		if (checkedCount === 0) {
			this.N_optionAllCheckbox!.checked = false;
			this.N_optionAllCheckbox!.indeterminate = false;
		} else if (checkedCount === lengthOfVisibles) {
			this.N_optionAllCheckbox!.checked = true;
			this.N_optionAllCheckbox!.indeterminate = false;
		} else {
			this.N_optionAllCheckbox!.checked = false;
			this.N_optionAllCheckbox!.indeterminate = true;
		}
	}

	private initSearchbar() {
		const N_searchbar = this.element.querySelector('#search-input') as HTMLInputElement;
		N_searchbar.addEventListener('input', () => {
			this.filterSearch(N_searchbar.value);
		});
	}

	private filterSearch(search: string) {
		for (const item of this.checkboxes) {
			// Normalisation des chaines de caractère pour la comparaison (on les met en minuscule / on retire les accents / on enleve les espaces pour la recherche)
			const itemName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

			search = search.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

			if (itemName.includes(search)) {
				item.parentElement!.classList.replace('d-none', 'd-flex');
			} else {
				item.parentElement!.classList.replace('d-flex', 'd-none');
			}
		}
		this.updateOptionAllCheckbox();
	}

	private updateTitleCount() {
		const N_titleCount = this.element.querySelector('#title-count') as HTMLSpanElement;
		N_titleCount.innerHTML = this.selectedKeys.length.toString();
	}
}

export default MobileSelectMultipleFilter;
