import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/editBillingRequest.html';

import '../../css/editBillingRequest.scss';

import CE_RecapLine from '../customElements/editBillingRequest/RecapLine';
import CE_RecapOrder from '../customElements/editBillingRequest/RecapOrder';
import CE_Select from '@libs/customElement/Select';
import CE_ViewGroupQuote from '../customElements/editBillingRequest/ViewGroupQuote';

import S_C_Bill from '@services/Customer/CustomerBillService';
import S_C_BillingRequest from '@services/Customer/CustomerBillingRequestService';
import S_C_Order from '@services/Customer/CustomerOrderService';

import C_BillsCustomer from '@modules/BillsCustomer/js/controllers/Bills.Customer';

import { dataRecapQuote } from '../customElements/editBillingRequest/RecapQuote';
import moment from 'moment';

import DecimalApps from '@libs/utils/Decimal';

class EditBillingRequest extends Modal {
	private id_order: string;
	private id_edit: string | undefined;

	private mode: string = 'billing-request';
	private billType?: 'advance' | 'normal' | 'last';

	private version: string = '2';

	private hasBill: boolean = false;

	constructor(idOrder: string, id_edit?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id_order = idOrder;
		this.id_edit = id_edit;

		this.on('opened', async () => {
			this.element.classList.add('loading');

			const N_recapOrder = this.element.querySelector<CE_RecapOrder>(CE_RecapOrder.tagName)!;
			const N_viewGroupQuote = this.element.querySelector<CE_ViewGroupQuote>(CE_ViewGroupQuote.tagName)!;
			const N_selectQuotes = this.element.querySelector<CE_Select>('#quotes');

			const N_date = this.element.querySelector<HTMLInputElement>('#date');
			const N_designation = this.element.querySelector<HTMLInputElement>('#designation');
			const N_comment = this.element.querySelector<HTMLTextAreaElement>('#comment');
			const N_type = this.element.querySelector<HTMLSelectElement>('#type');

			N_recapOrder.mode = this.mode;

			const order = await S_C_Order.getInstance().getById(this.id_order);

			const oldBills = await S_C_Bill.getInstance().getByOrder(this.id_order);

			//On vérifie si il y a des factures
			this.hasBill = oldBills.length > 0;

			this.version = oldBills.filter((item: any) => item._rowData_.version === '1').length ? '1.5' : '2';

			this.updateHeader(this.mode);
			this.updateOptionsType(this.mode);

			const N_save = this.element.querySelector<HTMLButtonElement>('#save');

			N_save!.disabled = true;

			N_selectQuotes?.addEventListener('change', () => {
				if (N_selectQuotes.value === 'all') {
					N_recapOrder.scrollIntoView({ behavior: 'smooth' });
				} else {
					const N_quote = this.element.querySelector<HTMLElement>(`[id-quote="${N_selectQuotes.value}"]`)!;
					N_quote.scrollIntoView({ behavior: 'smooth' });
				}
			});

			let priceBygroup = {};

			if (this.mode === 'create-credit') {
				priceBygroup = await S_C_Bill.getInstance().getBillStateByID(this.id_edit!);
			} else if (this.mode === 'rectify-bill-content') {
				// Dans le cas d'une rectification, on donne l'id de la facture à rectifier, pour qu'elle soit ignorée dans le calcul
				priceBygroup = await S_C_Bill.getInstance().getBillStateByOrder(this.id_order, this.id_edit);
			} else {
				priceBygroup = await S_C_Bill.getInstance().getBillStateByOrder(this.id_order);
			}

			N_designation!.value = order.label;

			N_date!.value = moment().format('YYYY-MM-DD');

			this.updateTitle(order);

			N_type?.addEventListener('change', () => {
				if (N_type.value === 'last') {
					N_recapOrder.setLastPrice();
				}

				N_recapOrder.toggleLastPrice(N_type.value === 'last');
			});

			const dataToRecap: dataRecapQuote[] = [];

			for (const item of order.orders) {
				dataToRecap.push({
					quoteID: item.quoteID,
					selectedGroups: item.selectedGroups
				});
			}

			N_recapOrder.addEventListener('register.quotes', ((e: CustomEvent<{ id: string, text: string }[]>) => {
				N_selectQuotes!.options = {
					data: [{
						id: 'all',
						text: `Tous (${e.detail.length})`
					}, ...e.detail]
				};
				N_selectQuotes!.value = 'all';

				if (N_recapOrder.priceNotBill.toNumber() < 0) {
					this.updateOptionsType('create-credit');
					this.updateHeader('create-credit');
				} else {
					this.updateOptionsType(this.mode);
					this.updateHeader(this.mode);
				}
			}) as EventListener);

			N_recapOrder.addEventListener('select.group', ((e: CustomEvent<any>) => {
				const N_lines = this.element.querySelectorAll<CE_RecapLine>('[id-group]');

				for (const N_line of N_lines) {
					N_line.unselect();
				}

				const N_line = this.element.querySelector<CE_RecapLine>(`[id-quote="${e.detail._idQuote}"] [id-group="${e.detail._idGrid}"][id-tva="${e.detail.tva}"]`);
				N_line?.select();

				N_viewGroupQuote.value = e.detail;
			}) as EventListener);

			N_recapOrder.addEventListener('check.error', () => {
				this.updateButtonSave();

				if (this.mode !== 'credit' && this.mode !== 'credit-error') {
					this.checkSum(N_recapOrder.value);
				}
			});

			await N_recapOrder.setData(dataToRecap, priceBygroup);

			if (this.id_edit && this.mode === 'billing-request') {
				const { data } = (await S_C_BillingRequest.getInstance().getDataToModal(this.id_edit)).data;

				N_date!.value = moment(data.billingStartDate).format('YYYY-MM-DD');
				N_designation!.value = data.designation;
				N_comment!.value = data.comment;
				N_type!.value = data.type;
				N_recapOrder!.value = data.details;
			}

			if (this.billType && this.mode === 'rectify-bill-content') {
				N_type!.value = this.billType;
				N_type!.dispatchEvent(new Event('change'));
			}

			this.element.classList.remove('loading');

			N_save?.addEventListener('click', async () => {
				const data: { [key: string]: any } = {
					_id: this.id_edit,
					type: N_type!.value,
					billingStartDate: N_date!.valueAsDate?.getTime(),
					designation: N_designation!.value,
					comment: N_comment!.value,
					details: N_recapOrder.value,
					id_order: idOrder,
					version: this.version
				};

				if (this.validateForm()) {
					if (this.mode === 'billing-request') {
						await S_C_BillingRequest.getInstance().save(data);
					}

					if (this.mode === 'create-bill') {
						this.ignoreChangeRoute = true;
						C_BillsCustomer.open(null, {
							idOrder,
							billingRequest: data
						});
					}

					if (this.mode === 'create-credit') {
						this.ignoreChangeRoute = true;

						data._id = undefined;

						data.id_bill = this.id_edit;

						C_BillsCustomer.open(null, {
							idOrder,
							billingRequest: data
						});
					}

					if (this.mode === 'rectify-bill-content') {
						this.resolve({
							idOrder,
							billingRequest: data
						});
					} else {
						this.resolve();
					}
				}
			});

			//masquage du details des devis quand on est dans l'ancienne version 
			if (this.version === '1.5') {
				const N_quoteList = this.element.querySelectorAll<HTMLElement>('[id-quote]');
				for (const N_el of N_quoteList) {
					N_el.classList.add('d-none');
				}

				N_selectQuotes?.classList.add('disabled');
			}

			this.updateButtonSave();
		});
	}

	private checkSum(value: { [key: string]: any }) {
		let price = new DecimalApps(0);

		for (const quoteID in value) {
			for (const groupID in value[quoteID]) {
				for (const tvaID in value[quoteID][groupID]) {
					price = price.plus(DecimalApps.setDisplayNumber(value[quoteID][groupID][tvaID]));
				}
			}
		}

		if (price.toNumber() < 0) {
			this.updateOptionsType('create-credit');
			this.updateHeader('create-credit');
		} else {
			this.updateOptionsType(this.mode);
			this.updateHeader(this.mode);
		}
	}

	private validateForm() {
		let validity = true;

		const N_labelDate = this.element.querySelector('[for="date"]') as HTMLLabelElement;
		const N_date = this.element.querySelector('#date') as HTMLInputElement;
		const N_labelDesignation = this.element.querySelector('[for="designation"]') as HTMLLabelElement;
		const N_designation = this.element.querySelector('#designation') as HTMLInputElement;

		if (!N_date.value) {
			N_date.classList.add('is-invalid');
			N_labelDate.classList.add('text-red');
			validity = false;
		} else {
			N_date.classList.remove('is-invalid');
			N_labelDate.classList.remove('text-red');
		}

		if (!N_designation.value.trim()) {
			N_designation.classList.add('is-invalid');
			N_labelDesignation.classList.add('text-red');
			validity = false;
		} else {
			N_designation.classList.remove('is-invalid');
			N_labelDesignation.classList.remove('text-red');
		}

		return validity;
	}

	public setMode(value: 'billing-request' | 'create-bill' | 'create-credit' | 'rectify-bill-content') {
		this.mode = value;
		return this;
	}

	public setBillType(value: 'advance' | 'normal' | 'last') {
		this.billType = value;
		return this;
	}

	private updateButtonSave() {
		const N_recapOrder = this.element.querySelector<CE_RecapOrder>(CE_RecapOrder.tagName)!;

		const N_save = this.element.querySelector<HTMLButtonElement>('#save');

		N_save!.disabled = N_recapOrder.error;
	}

	private updateOptionsType(mode: string) {
		const N_select = this.element.querySelector<HTMLSelectElement>('#type')!;
		const value = N_select.value;

		if (mode === 'create-credit') {
			N_select.innerHTML = `
				<option value="credit" selected>Commercial</option>
				<option value="credit-error">Erreur</option>
			`;

			if (['credit', 'credit-error'].includes(value)) {
				N_select.value = value;
			}
		} else {
			//Si on a déjà un paiement, on ne peut pas faire d'acompte
			if (this.hasBill) {
				N_select.innerHTML = `
				<option value="normal">Avancements</option>
				<option value="last">Définitive</option>
			`;

				if (['normal', 'last'].includes(value)) {
					N_select.value = value;
				}
			} else {
				N_select.innerHTML = `
				<option value="advance" selected>Acompte</option>
				<option value="normal">Avancements</option>
				<option value="last">Définitive</option>
			`;

				if (['advance', 'normal', 'last'].includes(value)) {
					N_select.value = value;
				}
			}
		}
	}

	private async updateTitle(order: { [key: string]: any }) {
		const N_addonTitle = this.element.querySelector<HTMLElement>('.modal-title-addon')!;

		if (this.mode === 'create-credit') {
			const bill = await S_C_Bill.getInstance().getById(this.id_edit!);
			N_addonTitle!.innerHTML = bill.infos.number;
		} else {
			N_addonTitle!.innerHTML = order.internalNumber;
		}
	}

	private updateHeader(mode: string) {
		const N_title = this.element.querySelector<HTMLElement>('#title');
		const N_dateContainer = this.element.querySelector<HTMLDivElement>('#date-container');
		const N_labelType = this.element.querySelector<HTMLLabelElement>('[for="type"]');
		const N_labelDesignation = this.element.querySelector<HTMLLabelElement>('[for="designation"]');

		const N_formContainer = this.element.querySelector<HTMLFormElement>('.form-container');

		if (mode === 'billing-request') {
			N_title!.innerHTML = 'Demande de facturation';
		}

		if (mode === 'create-bill') {
			N_title!.innerHTML = 'Création de facture';
			N_dateContainer?.classList.add('d-none');
		}

		if (mode === 'rectify-bill-content') {
			N_title!.innerHTML = 'Rectification du contenu de la facture';
			N_formContainer?.classList.add('d-none');
		}

		if (mode === 'create-credit') {
			if (this.mode === 'create-credit') {
				N_title!.innerHTML = 'Création d’un avoir pour la facture';
			} else {
				N_title!.innerHTML = 'Création d’un avoir pour la commande';
			}

			N_labelType!.innerHTML = 'Type d\'avoir';
			N_labelDesignation!.innerHTML = 'Motif de l’avoir';
			N_dateContainer?.classList.add('d-none');
		}
	}
}

export default EditBillingRequest;
