class AddressDeliveryReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-address-delivery-readonly';

	public selectElement: HTMLSelectElement | null = null;

	public async connectedCallback() {
		const nameType = this.getAttribute('name-type');
		const nameAddressID = this.getAttribute('name-address-id');
		const nameSite = this.getAttribute('name-site');
		const nameGPS = this.getAttribute('name-gps');
		const nameTextarea = this.getAttribute('name-textarea');

		this.classList.add('w-100');

		this.innerHTML = `
			<div class="d-none" name="${nameType}"></div>
			<div class="font-weight-bold d-none" id="addressID" name="${nameAddressID}"></div>
			<div class="font-weight-bold d-none" id="site" name="${nameSite}"></div>
            <div type="textarea" name="${nameTextarea}"></div>
            <div class="page-card-block-value">
				<div class="page-card-label">Coordonnées GPS :</div>
				<div class="page-card-value" name="${nameGPS}"></div>
			</div>
        `;
		/*
			typeAddressCustomer;
			addressIDCustomer;
			siteCustomer;
			fullAddress;
			GPSCoordinates;
		*/
		this.removeAttribute('name-type');
		this.removeAttribute('name-address-id');
		this.removeAttribute('name-site');
		this.removeAttribute('name-gps');
		this.removeAttribute('name-textarea');
	}

	public update(type: string) {
		const N_addressID = this.querySelector<HTMLElement>('#addressID');
		const N_site = this.querySelector<HTMLElement>('#site');

		if (type === 'site') {
			N_addressID?.classList.add('d-none');
			N_site?.classList.remove('d-none');
		}

		if (type === 'customer') {
			N_addressID?.classList.remove('d-none');
			N_site?.classList.add('d-none');
		}
	}

	public static register() {
		customElements.define(AddressDeliveryReadonly.tagName, AddressDeliveryReadonly);
	}
}

export default AddressDeliveryReadonly;
