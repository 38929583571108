import GenericService from '../GenericService';

class ProductCategoryService extends GenericService {
	private static instance: ProductCategoryService | null = null;

	constructor() {
		super('categories');
	}

	public static getInstance(): ProductCategoryService {
		if (!ProductCategoryService.instance) {
			ProductCategoryService.instance = new ProductCategoryService();
		}

		return ProductCategoryService.instance;
	}

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return 'name';
	}
}

export default ProductCategoryService;
