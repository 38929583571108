import { Tab, toaster } from '@autoprog/core-client';

import ConfigManager from '@libs/ConfigManager';
import Loader from '@libs/Loader';
import SelectEditor from '@libs/agGrid/SelectEditor';

import ServiceManager from '@managers/ServiceManager';

import ConfigService from '@services/ConfigService';

//@ts-ignore
import { saveAs } from 'file-saver';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import _ from 'lodash';
import moment from 'moment';

class ExportDataTab extends Tab {
	private gridOptions: GridOptions = {};
	private configService: ConfigService;
	private configManager: ConfigManager;

	private N_exportButton: HTMLButtonElement;
	private N_separator: HTMLSelectElement;
	private N_table: HTMLSelectElement;
	private N_grid: HTMLElement;

	private listTable: { [key: string]: string } = {};
	private table: string | null;

	constructor(el: HTMLElement) {
		super(el);

		this.table = null;

		this.configService = ConfigService.getInstance();
		this.configManager = ConfigManager.getInstance();

		this.N_exportButton = el.querySelector('#btn-export-data') as HTMLButtonElement;
		this.N_separator = el.querySelector('#separator') as HTMLSelectElement;
		this.N_table = el.querySelector('#table') as HTMLSelectElement;
		this.N_grid = el.querySelector('#grid') as HTMLElement;

		this.init();
	}

	private init() {
		// On change le titre de la page
		const title = document.querySelector('#title') as HTMLElement;
		title.innerHTML = 'Exportation de données';

		this.initGrid();
		this.initEvent();
	}

	/**
	 * Initialise la grille du tableau
	 */
	private initGrid() {
		this.gridOptions = agUtils.french({

			localeText: {
				noRowsToShow: 'Aucune table sélectionnée'
			},
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			columnDefs: [{
				headerName: 'Colonne',
				field: 'name',
				headerCheckboxSelection: true,
				checkboxSelection: true
			}, {
				headerName: 'Référence',
				field: 'foreignKey',
				editable: (params) => {
					return params.data.type === 'table';
				},
				cellRenderer: (params) => {
					if (params.data.type !== 'table') {
						return '';
					} else {
						if (params.value) {
							const { columns } = this.configManager.getConfig(params.data.table);

							const col = _.find<any>(columns, { key: params.value });
							return col ? col.name : params.value;
						} else {
							return '<span class="text-muted">Sélectionner la référence</span>';
						}
					}
				},
				cellEditorSelector: (params) => {
					const { columns } = this.configManager.getConfig(params.data.table);

					return {
						component: 'agRichSelect',
						params: {
							values: _.map(columns, 'key'),
							formatValue: (value: any) => {
								const col = _.find<any>(columns, { key: value });
								return col ? col.name : value;
							}
						}
					};
				}
			}, {
				field: 'filter',
				headerName: 'Filtre',
				editable: true,
				cellRenderer: (params) => {
					if (params.data.list) {
						return params.data.list[params.value] || '';
					}

					return params.value;
				},
				cellEditorSelector: (params) => {
					if (params.data.list) {
						return {
							component: 'agRichSelectCellEditor',
							params: {
								values: ['', ...Object.keys(params.data.list)],
								formatValue: (value: any) => {
									return params.data.list[value] || '';
								}
							}
						};
					}

					return {
						component: 'agTextCellEditor'
					};
				}
			}],
			components: {
				SelectEditor
			},
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			rowData: []
		});

		new Grid(this.N_grid, this.gridOptions, { modules: AllModules });
	}

	/**
	 * Initialise les inputs et leurs évènements
	 */
	private initEvent() {
		this.configService.getEditableTable().then((data) => {
			this.listTable = data as { [key: string]: string };

			for (const table in this.listTable) {
				const option = new Option(this.listTable[table], table);
				this.N_table.append(option);
			}
		});

		//Evènement pour remplir le tableau à la sélection d'une table
		this.N_table.addEventListener('change', () => {
			if (this.gridOptions.api) {
				if (this.N_table.value) {
					this.N_exportButton.disabled = false;

					const { columns } = this.configManager.getConfig(this.N_table.value);

					const data: { [key: string]: any }[] = [];

					for (const column of columns) {
						// TODO: à améliorer pour les prendre en compte
						// On exclut les colonnes avec une références multiple
						if (!column.isAgGrid && !column.notExportable && !['file'].includes(column.type)) {
							const obj: { [key: string]: any } = {
								key: column.key,
								type: column.type,
								table: column.table,
								breakExportIfDeleted: column.breakExportIfDeleted,
								name: column.name
							};

							if (column.type === 'object') {
								obj.list = column.object;
							}

							if (column.type === 'array') {
								obj.list = {};

								for (const item of column.array) {
									obj.list[item] = item;
								}
							}

							//On préremplit la colonne référence avec la clé d'export par défaut
							if (column.table) {
								obj.foreignKey = ServiceManager.get(column.table)?.getInstance().getExportKey();
							}

							data.push(obj);
						}
					}

					this.gridOptions.api.setRowData(data);

					this.gridOptions.api.forEachNode((node) => {
						if (node.data.type !== 'primaryKey') {
							node.setSelected(true);
						}
					});

					this.table = this.N_table.value;
				} else {
					this.N_exportButton.disabled = true;
					this.gridOptions.api.setRowData([]);
					this.table = null;
				}

				this.gridOptions.columnApi?.autoSizeAllColumns();
			}
		});

		//Evénement sur le bouton export
		this.N_exportButton.addEventListener('click', async () => {
			Loader.getInstance().open();
			this.exportData().then(() => {
				Loader.getInstance().close();
				toaster.success('Données exportées avec succès.');
			}).catch(e => {
				console.error(e);
				Loader.getInstance().close();
				toaster.error(e.response.data);
			});
		});
	}

	/**
	 * Exporte les données de la base sous forme d'un fichier CSV
	 */
	public async exportData() {
		const cols: any = {};

		const filter: { [key: string]: any } = {};
		const separator = this.N_separator.value;

		//On construit un objet contenant les informations des colonnes du tableau
		if (this.gridOptions.api) {
			const selectedRows = this.gridOptions.api.getSelectedRows();

			for (const data of selectedRows) {
				if (data.type === 'table') {
					cols[data.key] = {
						value: data.foreignKey || '_id',
						table: data.table,
						breakExportIfDeleted: data.breakExportIfDeleted,
						name: data.name
					};
				} else if (data.type === 'date') {
					cols[data.key] = {
						name: data.name,
						date: 'DD/MM/YYYY HH:mm'
					};
				} else if (data.type === 'boolean') {
					cols[data.key] = {
						name: data.name,
						boolean: true,
						date: 'DD/MM/YYYY HH:mm'
					};
				} else if (data.type === 'object') {
					cols[data.key] = {
						name: data.name,
						type: 'object',
						object: data.list
					};
				} else if (data.type === 'number') {
					cols[data.key] = {
						name: data.name,
						type: 'number'
					};
				} else {
					cols[data.key] = {
						name: data.name
					};
				}

				if (data.filter) {
					filter[data.key] = data.filter;
				}
			}
		}

		//On récupère les données au format csv
		const csvData = await ServiceManager.get(this.table!)?.getInstance().exportData(cols, separator, filter);

		const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData]);

		if (separator === '\t') {
			saveAs(blob, `Export du ${moment().format('YYYY_MM_DD')} de ${this.listTable[this.N_table.value]}.tsv`, { autoBom: true });
		} else {
			saveAs(blob, `Export du ${moment().format('YYYY_MM_DD')} de ${this.listTable[this.N_table.value]}.csv`, { autoBom: true });
		}
	}
}

export default ExportDataTab;
