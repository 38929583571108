import { Form } from '@autoprog/core-client';

class Select2Filter {
	private params: any;
	private eGui: HTMLElement;
	private form: Form | null = null;

	private value: any = '';

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('w-100', 'ag-input-text-wrapper');
	}

	public init(params: any) {
		this.params = params;
		this.value = params.value;
		this.eGui.innerHTML = '<select class="form-control" name="value"></select>';

		if (this.eGui) {
			const select = this.eGui.querySelector('select') as HTMLSelectElement;

			params.options = params.options || {};

			if (params.options.select2) {
				const obj: { [key: string]: any } = {

				};

				if (params.options.data) {
					obj.data = params.options.data;
				}

				if (params.options.ajax) {
					obj.ajax = params.options.ajax;
				}

				$(select).select2({
					allowClear: true,
					...obj,
					placeholder: ''
				});

				$(select).on('select2:select', () => {
					this.value = $(select).val() || '';

					this.value = this.value === '#%--%#' ? '' : this.value;

					this.updateFilter();
				});

				$(select).on('select2:unselect', () => {
					this.value = $(select).val() || '';

					if (this.value === '') {
						$(select).val('#%--%#').trigger('change');
					}

					this.updateFilter();
				});
			} else {
				const option = new Option('', '', true, true);
				select.append(option);

				for (const item of params.options.data) {
					const option = new Option(item.text, item.id.toString());
					select.append(option);
				}

				select.addEventListener('change', () => {
					this.value = select.value;

					this.updateFilter();
				});
			}

			this.form = new Form(this.eGui as HTMLFormElement);

			if (params.options.select2 && !this.value) {
				this.form?.setData({
					value: {
						id: '#%--%#',
						text: 'Aucun Filtre'
					}
				});
			}
		}
	}

	private updateFilter() {
		this.params.parentFilterInstance((instance: any) => {
			if (this.value !== '#%--%#') {
				instance.onFloatingFilterChanged('equals', this.value);
			} else {
				instance.onFloatingFilterChanged(null, null);
			}
		});
	}

	public onParentModelChanged(parentModel: any) {
		const value: any = parentModel?.filter || '#%--%#';

		if (this.params.options.select2) {
			this.form?.setData({
				value: {
					id: value,
					text: value
				}
			});
		} else {
			this.form?.setData({
				value
			});
		}

		this.value = value;
	}

	public getGui(): HTMLElement {
		return this.eGui;
	}
}

export default Select2Filter;
