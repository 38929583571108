import DecimalApps from '@libs/utils/Decimal';

class Decimal {
	private value: number | DecimalApps;
	private numberDecimal: number;

	constructor(value: number | DecimalApps, numberDecimal: number = 2) {
		this.value = value;
		this.numberDecimal = numberDecimal;
	}

	public toDecimal() {
		let tmp: DecimalApps;

		if (this.value instanceof DecimalApps) {
			tmp = this.value;
		} else {
			tmp = DecimalApps.setDisplayNumber(this.value);
		}

		return tmp;
	}

	public toNumber() {
		return this.toDecimal().toNumber();
	}

	public toDashboard() {
		const tmp = this.toDecimal();

		return {
			formattedValue: tmp.humanizeNumber(this.numberDecimal),
			value: this.value,
			quickFilter: tmp.humanizeNumber(this.numberDecimal)
		};
	}

	public toSave() {
		return this.toNumber();
	}

	public toDocument() {
		return this.toNumber();
	}

	public toPrint() {
		return this.toDecimal().humanizeNumber(this.numberDecimal);
	}
}

export default Decimal;
