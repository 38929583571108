import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import '../../../css/cellRenderer/notFinishCellRenderer.scss';

class NotFinishCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;

	private colors: string[];

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('notFinishCellRenderer');
		this.eGui.innerHTML = '';

		this.colors = [
			'green', 'red',
			'pink', 'light-green',
			'purple', 'lime',
			'deep-purple', 'yellow',
			'indigo', 'amber',
			'blue', 'orange',
			'light-blue', 'deep-orange',
			'cyan', 'brown',
			'teal', 'grey'
		];
	}

	public init(params: any) {
		if (params.value) {
			this.eGui.innerHTML = '<ap-icon name="flag/line"></ap-icon>';
		} else {
			this.eGui.innerHTML = '';
		}

		this.getColors(params);
	}

	public getColors(params: any) {
		const category = params.data.category || '';

		let backgroundColor = '';
		const data: Set<string> = new Set();
		params.api.forEachNode((node: any) => {
			data.add(node.data.category || '');
		});

		let i = 0;
		for (const item of data.values()) {
			if (item === category) {
				backgroundColor = this.colors[i % this.colors.length];
				break;
			}
			i++;
		}

		this.eGui.style.backgroundColor = `var(--ap-${backgroundColor}-300)`;
		this.eGui.style.color = `var(--ap-${backgroundColor}-300-yiq)`;
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default NotFinishCellRenderer;
