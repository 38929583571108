import removeAccents from 'remove-accents';

import StepContent from '@libs/StepModal/StepContent';

import T_Step from '../../../../tpl/modals/addEditProduct/steps/details.html';

import '../../../../css/modals/steps/details.scss';

import CE_Select from '@libs/customElement/Select';
import CE_UploadPhoto from '@libs/customElement/UploadPhoto';

import CE_EditCategories from '../../../libs/customElement/EditCategories';
import CE_EditTags from '../../../libs/customElement/EditTags';

import M_AddEditBrands from '../../AddEditBrands';

import S_Account from '@modules/Comptabilité/js/services/AccountsService';
import S_Brand from '@services/Product/BrandService';
import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';
import S_Transporter from '@services/TransporterService';

import Settings from '@modules/Settings/js/libs/Settings';

class DetailsStep extends StepContent {
	public async init() {
		const N_brand = this.el.querySelector<CE_Select>('[name="brand"]')!;
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"')!;
		const N_internalCode = this.el.querySelector<HTMLInputElement>('[name="internalCode"]')!;
		const N_reference = this.el.querySelector<HTMLInputElement>('[name="reference"]')!;

		const N_tvaRate = this.el.querySelector<CE_Select>('[name="tvaRate"]')!;
		const N_comptaVente = this.el.querySelector<CE_Select>('[name="comptaVente"]')!;
		const N_transporter = this.el.querySelector<CE_Select>('[name="transporter"]')!;

		const N_categories = this.el.querySelector<CE_EditCategories>(CE_EditCategories.tagName)!;

		const N_generate = this.el.querySelector<HTMLButtonElement>('#generate-internalCode')!;

		N_brand.options = {
			button: {
				add: true
			},
			ajax: {
				url: S_Brand.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search
					};
				}
			}
		};

		N_brand.addEventListener('change', () => {
			this.updateButtonGenerate();
		});

		N_brand.addEventListener('add.click', () => {
			new M_AddEditBrands().open().then(async (id) => {
				const data = await S_Brand.getInstance().getDataToSelect2ByID(id);
				N_brand.value = data;
			});
		});

		N_label.addEventListener('change', () => {
			this.checkValidity();
		});

		N_internalCode.addEventListener('change', () => {
			this.checkValidity();
		});

		N_reference.addEventListener('input', () => {
			this.updateButtonGenerate();
		});

		await N_categories.init();

		N_generate.addEventListener('click', async () => {
			const brand = await S_Brand.getInstance().getDisplayRefByID(N_brand.value as string);
			N_internalCode.value = brand?.slice(0, 3) + '.' + removeAccents(N_reference.value).trim().toUpperCase().replace(/[^A-Z0-9-/]/g, '');
			this.checkValidity();
		});

		N_tvaRate.options = {
			ajax: {
				url: new S_Taxes().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ type: 'sale' })
					};
				}
			}
		};

		N_comptaVente.options = {
			ajax: {
				url: new S_Account().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ forProduct: true, type: 'sale' })
					};
				}
			}
		};

		N_transporter.options = {
			ajax: {
				url: S_Transporter.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search
					};
				}
			}
		};
	}

	private updateButtonGenerate() {
		const N_brand = this.el.querySelector<CE_Select>('[name="brand"]')!;
		const N_reference = this.el.querySelector<CE_Select>('[name="reference"]')!;
		const N_generate = this.el.querySelector<HTMLButtonElement>('#generate-internalCode')!;

		N_generate.disabled = !N_brand.value || !N_reference.value;
	}

	public getTitle() {
		return 'Détails du produit';
	}

	public getContent() {
		return T_Step;
	}

	public getData() {
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;
		const N_reference = this.el.querySelector<HTMLInputElement>('[name="reference"]')!;
		const N_brand = this.el.querySelector<CE_Select>('[name="brand"]')!;
		const N_internalCode = this.el.querySelector<HTMLInputElement>('[name="internalCode"]')!;
		const N_forSaleOnline = this.el.querySelector<HTMLInputElement>('[name="forSaleOnline"]')!;
		const N_detail = this.el.querySelector<HTMLInputElement>('[name="detail"]')!;

		const N_photo = this.el.querySelector<CE_UploadPhoto>(CE_UploadPhoto.tagName)!;
		const N_categories = this.el.querySelector<CE_EditCategories>(CE_EditCategories.tagName)!;
		const N_tags = this.el.querySelector<CE_EditTags>(CE_EditTags.tagName)!;
		const N_tvaRate = this.el.querySelector<CE_Select>('[name="tvaRate"]')!;

		const N_comptaVente = this.el.querySelector<CE_Select>('[name="comptaVente"]')!;
		const N_transporter = this.el.querySelector<CE_Select>('[name="transporter"]')!;

		return {
			label: N_label.value,
			reference: N_reference.value,
			brand: N_brand.value,
			internalCode: N_internalCode.value,
			forSaleOnline: N_forSaleOnline.checked,
			detail: N_detail.value,
			url_photo: N_photo.data.url,
			base64_photo: N_photo.data.base64,
			categories: N_categories.data,
			tags: N_tags.data,
			tvaRate: N_tvaRate.value,
			comptaVente: N_comptaVente.value,
			transporter: N_transporter.value
		};
	}

	public async setData(data: { [key: string]: any }) {
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;
		const N_reference = this.el.querySelector<HTMLInputElement>('[name="reference"]')!;
		const N_brand = this.el.querySelector<CE_Select>('[name="brand"]')!;
		const N_internalCode = this.el.querySelector<HTMLInputElement>('[name="internalCode"]')!;
		const N_forSaleOnline = this.el.querySelector<HTMLInputElement>('[name="forSaleOnline"]')!;
		const N_detail = this.el.querySelector<HTMLInputElement>('[name="detail"]')!;

		const N_photo = this.el.querySelector<CE_UploadPhoto>(CE_UploadPhoto.tagName)!;
		const N_categories = this.el.querySelector<CE_EditCategories>(CE_EditCategories.tagName)!;
		const N_tags = this.el.querySelector<CE_EditTags>(CE_EditTags.tagName)!;

		const N_tvaRate = this.el.querySelector<CE_Select>('[name="tvaRate"]')!;
		const N_comptaVente = this.el.querySelector<CE_Select>('[name="comptaVente"]')!;
		const N_transporter = this.el.querySelector<CE_Select>('[name="transporter"]')!;

		N_label.value = data.label || '';
		N_reference.value = data.reference || '';
		N_brand.value = data.brand || '';
		N_internalCode.value = data.internalCode || '';
		N_forSaleOnline.checked = data.forSaleOnline || false;
		N_detail.value = data.detail || '';

		N_photo.data = {
			url: data.url_photo || '',
			base64: data.base64_photo || ''
		};

		N_tags.data = data.tags || [];

		N_categories.data = data.categories;

		const settingsAccounting = Settings.getInstance().get('accountings');

		const defaultTVA = settingsAccounting?.defaultTaxe?.sale && await new S_Taxes().getDataToSelect2ByID(settingsAccounting?.defaultTaxe?.sale);
		N_tvaRate.value = data.tvaRate || defaultTVA || null;

		const defaultAccount = settingsAccounting?.defaultAccount?.sale.product && await new S_Account().getDataToSelect2ByID(settingsAccounting?.defaultAccount?.sale?.product);
		N_comptaVente.value = data.comptaVente || defaultAccount || null;

		N_transporter.value = data.transporter;

		this.updateButtonGenerate();
	}

	public checkValidity(): boolean {
		const N_label = this.el.querySelector<HTMLInputElement>('[name="label"]')!;
		const N_internalCode = this.el.querySelector<HTMLInputElement>('[name="internalCode"]')!;
		const N_tvaRate = this.el.querySelector<CE_Select>('[name="tvaRate"]')!;

		const N_labelFormItem = this.el.querySelector('#label-form-item');
		const N_internalCodeFormItem = this.el.querySelector('#internalCode-form-item');
		const N_tvaRateFormItem = this.el.querySelector('#tvaRate-form-item');

		N_labelFormItem?.classList.toggle('invalid', !N_label.checkValidity());
		N_internalCodeFormItem?.classList.toggle('invalid', !N_internalCode.checkValidity());
		N_tvaRateFormItem?.classList.toggle('invalid', !N_tvaRate.checkValidity());

		return N_label.checkValidity() && N_internalCode.checkValidity() && N_tvaRate.checkValidity();
	}
}

export default DetailsStep;
