import { global } from '@autoprog/core-client';

import { GridOptions } from '@ag-grid-enterprise/all-modules';

import AggridUtils from '../utils/Aggrid';
import Utils from '@libs/utils/Utils';

import axios from 'axios';

class AgGridStateSaver {
	private gridOptions: GridOptions;
	private name: string;

	private timeoutSaveDatabase: any = null;

	private nextSaveEnabled: boolean = true;

	// eslint-disable-next-line unused-imports/no-unused-vars
	private cb = (value: any) => { };

	constructor(gridOptions: GridOptions, name: string) {
		this.gridOptions = gridOptions;
		this.name = name;

		this.gridOptions.api?.addEventListener('columnEverythingChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnVisible', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnPinned', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnResized', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnRowGroupChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnValueChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnMoved', this.save.bind(this));
		this.gridOptions.api?.addEventListener('columnGroupOpened', this.save.bind(this));
		this.gridOptions.api?.addEventListener('filterChanged', this.save.bind(this));
		this.gridOptions.api?.addEventListener('sortChanged', this.save.bind(this));
	}

	public enableNextSave() {
		this.nextSaveEnabled = true;
	}

	public disableNextSave() {
		this.nextSaveEnabled = false;
	}

	private saveInDatabase(value: { [key: string]: any }) {
		if (this.timeoutSaveDatabase) {
			clearTimeout(this.timeoutSaveDatabase);
		}

		this.timeoutSaveDatabase = setTimeout(async () => {
			if (this.nextSaveEnabled) {
				const idUser = Utils.userID;

				const data: { [key: string]: any } = {
					_id: `${this.name}_${idUser}`
				};

				const params = {
					table: this.name,
					user: idUser
				};

				try {
					const tmp = (await axios.get('/api/gridState', { params })).data.data;
					data._rev = tmp._rev;
					data.columns = tmp.columns || {};
					data.columnsByPC = tmp.columnsByPC || {};
					data.quickFilter = tmp.quickFilter || '';
				} catch (e) {
					data.columns = {};
					data.columnsByPC = {};
					data.quickFilter = '';
				}

				data.columnsByPC[global.ID_PC] = {};

				data.quickFilter = value.quickFilter || '';

				data.filters = value.filters || {};

				value.columnState = value.columnState || [];

				let order = 0;
				for (const item of value.columnState) {
					data.columns[item.colId] = {
						hide: item.hide,
						sort: item.sort,
						pinned: item.pinned,
						order
					};

					data.columnsByPC[global.ID_PC][item.colId] = {
						width: item.width
					};

					order++;
				}

				this.cb(data);

				await axios.put('/api/gridState', { data });
			}

			this.enableNextSave();
		}, 1000);
	}

	private save(): void {
		const columnState = this.gridOptions.columnApi?.getColumnState();

		const value: { [key: string]: any } = {
			columnState,
			quickFilter: AggridUtils.getQuickFilter(this.gridOptions),
			filters: this.gridOptions.api?.getFilterModel()
		};

		this.saveInDatabase(value);
	}

	public setData(data: any) {
		let columnStateWithOrder: { [key: string]: any }[] = [];
		let columnStateWithoutOrder: { [key: string]: any }[] = [];

		const tmp: { [key: string]: any } = {};

		data.columns = data.columns || {};

		data.columnsByPC = data.columnsByPC || {};
		data.columnsByPC[global.ID_PC] = data.columnsByPC[global.ID_PC] || {};

		data.quickFilter = data.quickFilter || '';

		for (const colId in data.columns) {
			const columnDef = this.gridOptions.api?.getColumnDef(colId);

			if (columnDef && !columnDef.suppressColumnsToolPanel) {
				tmp[colId] = tmp[colId] || {};

				if (!columnDef?.suppressMovable) {
					tmp[colId].order = data.columns[colId].order;
					tmp[colId].pinned = data.columns[colId].pinned;
				}

				tmp[colId].hide = data.columns[colId].hide;
				tmp[colId].sort = data.columns[colId].sort;
			}
		}

		for (const colId in data.columnsByPC[global.ID_PC]) {
			const columnDef = this.gridOptions.api?.getColumnDef(colId);

			tmp[colId] = tmp[colId] || {};

			if (columnDef) {
				tmp[colId].width = data.columnsByPC[global.ID_PC][colId].width;
			}
		}

		for (const colId in tmp) {
			if (colId === '_id_1') {
				tmp[colId].pinned = 'right';
				delete tmp[colId].width;
			}

			if (colId === '_icons_') {
				tmp[colId].pinned = 'left';
				delete tmp[colId].width;
			}

			if (tmp[colId].order !== undefined && tmp[colId].order !== null) {
				columnStateWithOrder[tmp[colId].order] = {
					colId,
					...tmp[colId]
				};
			} else {
				columnStateWithoutOrder.push({
					colId,
					...tmp[colId]
				});
			}
		}

		columnStateWithOrder = columnStateWithOrder.filter(item => !!item);
		columnStateWithoutOrder = columnStateWithoutOrder.filter(item => !!item);

		this.setValue({ columnState: [...columnStateWithoutOrder, ...columnStateWithOrder], filters: data.filters, quickFilter: data.quickFilter });
	}

	private setValue(data: { [key: string]: any } = {}) {
		this.gridOptions.columnApi?.applyColumnState({ state: data.columnState, applyOrder: true });

		this.gridOptions.api?.setFilterModel(data.filters);

		this.gridOptions.api?.setQuickFilter(data.quickFilter || '');
	}

	public onUpdate(cb: (value: any) => void) {
		this.cb = cb;
	}

	public static async getValue(name: string) {
		const idUser = Utils.userID;

		const params = {
			table: name,
			user: idUser
		};

		try {
			const data = (await axios.get('/api/gridState', { params })).data.data;
			return data;
		} catch (e) {
			return {
				columns: {},
				columnsByPC: {}
			};
		}
	}
}

export default AgGridStateSaver;
