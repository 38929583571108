import '../../../css/customElement/productImagePreviewer.scss';

class ProductImagePreviewer extends HTMLElement {
	public static readonly tagName: string = 'ap-product-image-previewer';

	private N_image: HTMLImageElement | null = null;

	private _src: string = '';

	constructor() {
		super();

		this._src = this.getAttribute('src') || '';
		this.removeAttribute('src');
	}

	public async connectedCallback() {
		this.innerHTML = `
			<div id="img-default">Aucune photo</div>

			<img id="img-preview" style="max-height: 100px">

			<div id="img-invalid">Lien invalide</div>
			
			<div id="loading-spinner">
				<ap-icon class="icon-spin" name="loader-4/line"></ap-icon>
			</div>
		`;

		this.N_image = this.querySelector('#img-preview') as HTMLImageElement;

		this.initPreviewEvent();

		this.setURL(this._src);
	}

	public setURL(src: string | null) { //base64 || url
		this._src = src || '';
		this.displaySpinner();

		if (this._src === null || this._src.trim() === '' || this._src === 'data:') {
			this.displayDefault();
		} else {
			this.N_image!.src = this._src;
		}
	}

	private displayImage() {
		this.classList.add('displayImage');
		this.classList.remove('displayDefault');
		this.classList.remove('displayInvalid');
		this.classList.remove('displaySpinner');
	}

	public displayDefault() {
		this.classList.remove('displayImage');
		this.classList.add('displayDefault');
		this.classList.remove('displayInvalid');
		this.classList.remove('displaySpinner');
	}

	public displayInvalid() {
		this.classList.remove('displayImage');
		this.classList.remove('displayDefault');
		this.classList.add('displayInvalid');
		this.classList.remove('displaySpinner');
	}

	public displaySpinner() {
		this.classList.remove('displayImage');
		this.classList.remove('displayDefault');
		this.classList.remove('displayInvalid');
		this.classList.add('displaySpinner');
	}

	private async initPreviewEvent() {
		this.N_image!.addEventListener('load', () => {
			this.dispatchEvent(new Event('load'));
			this.displayImage();
		});

		this.N_image!.addEventListener('error', () => {
			this.dispatchEvent(new Event('error'));
			this.displayInvalid();
		});
	}

	public static register() {
		customElements.define(ProductImagePreviewer.tagName, ProductImagePreviewer);
	}
}

export default ProductImagePreviewer;
