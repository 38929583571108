// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/detailsBill.html';

// LIBS
import Modal from '@libs/EditPageModal';

type DetailsBillData = {
	order: string,
	infos: {
		number: string,
		type: string,
		waitingBill: boolean
	}
};

class DetailsBill extends Modal<DetailsBillData> {
	constructor(data: DetailsBillData) {
		super(data, T_modal);
	}

	protected preInit(data: DetailsBillData) {
		const N_typeBill = this.element.querySelector('#type-bill') as HTMLElement;
		const N_selectTypeBill = this.element.querySelector('[name="infos.type"]') as HTMLElement;

		const typesOptions: { [key: string]: string } = {
			normal: 'Avancement',
			advance: 'Acompte',
			last: 'Définitive',
			credit: 'Avoir (Commercial)',
			'credit-error': 'Avoir (Erreur)'
		};

		if (data.infos.type === 'credit' || data.infos.type === 'credit-error') {
			for (const type in typesOptions) {
				if (type === 'credit' || type === 'credit-error') {
					const N_option = new Option(typesOptions[type], type);
					N_selectTypeBill.appendChild(N_option);
				}
			}
		} else if (!data.order) {
			for (const type in typesOptions) {
				const N_option = new Option(typesOptions[type], type);
				N_selectTypeBill.appendChild(N_option);
			}
		} else {
			N_typeBill.remove();
		}
	}

	protected async setData(data: DetailsBillData) {
		const res: { [key: string]: any } = {
			infos: {
				number: data.infos.number,
				type: data.infos.type,
				waitingBill: data.infos.waitingBill
			}
		};

		this.form?.setData(res);
	}
}

export default DetailsBill;
