import { account, accountType } from '../types/account';

import BooleanModel from '@libs/model/_app/Boolean';
import ObjectModel from '@libs/model/_app/Object';
import PrimaryKeyModel from '@libs/model/_app/PrimaryKey';
import StringModel from '@libs/model/_app/String';

class Account {
	private data: account;

	private static type = {
		purchase: 'Achat',
		sale: 'Vente'
	};

	constructor(data: account) {
		this.data = data;
	}

	private convertRowData() {
		return {
			_id: new PrimaryKeyModel(this.data._id),
			number: new StringModel(this.data.number),
			label: new StringModel(this.data.label),
			type: new ObjectModel(this.data.type, Account.type),
			forProduct: new BooleanModel(this.data.forProduct),
			forTaxe: new BooleanModel(this.data.forTaxe),
			forHourlyRate: new BooleanModel(this.data.forHourlyRate),
			_rowData_: this.data
		};
	}

	public toDashboard() {
		const data = this.convertRowData();

		return {
			_id: data._id.toDashboard(),
			number: data.number.toDashboard(),
			label: data.label.toDashboard(),
			type: data.type.toDashboard(),
			forProduct: data.forProduct.toDashboard(),
			forHourlyRate: data.forHourlyRate.toDashboard(),
			forTaxe: data.forTaxe.toDashboard(),
			_rowData_: this.data
		};
	}

	public toSave(): account {
		const data = this.convertRowData();

		return {
			_id: data._id.toSave(),
			number: data.number.toSave(),
			label: data.label.toSave(),
			type: data.type.toSave() as accountType,
			forProduct: data.forProduct.toSave(),
			forHourlyRate: data.forHourlyRate.toSave(),
			forTaxe: data.forTaxe.toSave()
		};
	}

	public toSelect() {
		const data = this.convertRowData();

		return {
			id: data._id.getValue(),
			text: this.displayRef()
		};
	}

	public displayRef() {
		const data = this.convertRowData();
		return `${data.number.getValue()} ${data.label.getValue()}`;
	}

	public displayAggrid() {
		const data = this.convertRowData();
		return data.label.toDocument();
	}
}

export default Account;
