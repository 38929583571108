import { CAttribute, DOM, global } from '@autoprog/core-client';

class Hidden extends CAttribute {
	public static attrName: string = 'ap-hidden';

	constructor(el: HTMLElement | SVGElement) {
		super(el);

		this.create();
	}

	public attributeChanged(name: string): void {
		if (name === Hidden.attrName) {
			this.create();
		}
	}

	private create() {
		const attrContent = this.el.getAttribute(Hidden.attrName);

		if (global.IS_MOBILE && attrContent === 'mobile') {
			this.el.classList.add('d-none');
		}
		if (!global.IS_MOBILE && attrContent === 'desktop') {
			this.el.classList.add('d-none');
		}
	}

	public destructor() {
		this.el.removeAttribute(Hidden.attrName);
	}

	public static registry() {
		DOM.registerAttribute(Hidden as any);
	}
}

export default Hidden;
