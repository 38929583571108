module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pagePrint w-100">\n\n    <table style="min-width: calc(100% - 2px)">\n\n        <tbody>\n\n            <tr>\n                <td colspan="5">\n\n                    <div class="w-100 d-flex">\n                        Inventaire - ' +
((__t = (title)) == null ? '' : __t) +
'\n\n                        <span class="ml-auto">\n                            Page ' +
((__t = (page)) == null ? '' : __t) +
'/' +
((__t = (totalPage)) == null ? '' : __t) +
'\n                        </span>\n                    </div>\n                </td>\n            </tr>\n\n            <tr>\n                <td class="bg-grey-500 border border-dark text-center" style="max-width:144px; width:144px">Référence</td>\n                <td class="bg-grey-500 border border-dark text-center">Libellé</td>\n                <td class="bg-grey-500 border border-dark text-center">' +
((__t = (groupKey == 'product.brand' ? 'Marque' : 'Empl.')) == null ? '' : __t) +
'</td>\n                <td class="bg-grey-500 border border-dark text-center" style="min-width:60px">Qté</td>\n                <td class="bg-grey-500 border border-dark text-center" style="min-width:60px">Nouvelle</td>\n            </tr>\n\n\n            ';
for(let i=0; i < 54; i++){;
__p += '\n\n                ';
 let item=data[i] || {newQuantity : ''};;
__p += '\n\n                ';
if(item.type=='groupKey'){;
__p += '\n                    <tr>\n                        <td class="font-weight-bold border border-dark px-1" colspan="5">\n                            <div class="d-flex">\n                                ' +
((__t = (item.label)) == null ? '' : __t) +
'\n                            </div>\n                        </td>\n                    </tr>\n                ';
}else{;
__p += '\n                    <tr class="' +
((__t = (item.classes)) == null ? '' : __t) +
'">\n                        <td class="border border-dark px-1" style="max-width:144px; width:144px">' +
((__t = (item.reference || '&nbsp;')) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1">' +
((__t = (item.label || '&nbsp;')) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1">' +
((__t = (groupKey == 'product.brand' ? item.brand : item.locationStock)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1 text-right">' +
((__t = (item.oldQuantity)) == null ? '' : __t) +
'</td>\n                        <td class="border border-dark px-1 text-right">' +
((__t = (item.newQuantity == '0' ? '': item.newQuantity)) == null ? '' : __t) +
'</td>\n                    </tr>\n                ';
};
__p += '\n\n            ';
};
__p += '\n\n        </tbody>\n\n    </table>\n\n</div>';

}
return __p
}