import DecimalApps from '@js/libs/utils/Decimal';

import '../../../../css/customElement/viewProduct/detailProduct.scss';

import ProductCellRenderer from '../../ProductCellRenderer';

type dataDetailProduct = {
	_id: string
	label: string
	reference: string
	internalCode: string
	brand: string
	price: string
	defaultProvider: {
		purchasePrice: string
		costPrice: string
		recommendedSellPrice: string
	}
};

class DetailProduct extends HTMLElement {
	public static readonly tagName: string = 'ap-view-product-detail';

	public connectedCallback() {
		this.innerHTML = `
			<div class="header">
				<div class="image cell-loading"></div>
				<div class="label cell-loading"></div>
				<div class="reference cell-loading">
					<div class="text">Référence Fabriquant :&nbsp;</div>
					<div class="value"></div>
				</div>
				<div class="internalCode cell-loading">
					<div class="text">Code produit interne :&nbsp;</div>
					<div class="value"></div>
				</div>
				<div class="brand cell-loading">
					<div class="text">Marque :&nbsp;</div>
					<div class="value"></div>
				</div>
			</div>
			<div class="content cell-loading">
				<div class="price_text">Prix de vente</div>
				<div class="price_value"></div>
				<div class="purchasePrice_text">Prix d'achat</div>
				<div class="purchasePrice_value"></div>
				<div class="costPrice_text">Cout de revient</div>
				<div class="costPrice_value"></div>
				<div class="recommendedSellPrice_text">Prix de vente conseillé</div>
				<div class="recommendedSellPrice_value"></div>
			</div>
		`;
	}

	public set data(data: dataDetailProduct) {
		const N_image = this.querySelector<HTMLElement>('.image')!;

		const N_label = this.querySelector<HTMLElement>('.label')!;
		const N_reference = this.querySelector<HTMLElement>('.reference .value')!;
		const N_internalCode = this.querySelector<HTMLElement>('.internalCode .value')!;
		const N_brand = this.querySelector<HTMLElement>('.brand .value')!;

		const N_price = this.querySelector<HTMLElement>('.price_value')!;
		const N_purchasePrice = this.querySelector<HTMLElement>('.purchasePrice_value')!;
		const N_costPrice = this.querySelector<HTMLElement>('.costPrice_value')!;
		const N_recommendedSellPrice = this.querySelector<HTMLElement>('.recommendedSellPrice_value')!;

		const image = new ProductCellRenderer();
		image.init({ value: data._id, disabledTooltip: true, isOnlyImage: true });

		N_image.innerHTML = '';
		N_image.append(image.getGui());

		N_label.innerHTML = data.label;
		N_reference.innerHTML = data.reference;
		N_internalCode.innerHTML = data.internalCode;
		N_brand.innerHTML = data.brand;
		N_price.innerHTML = DecimalApps.setDisplayNumber(data.price).setSuffixAndHumanizeNumber('€');
		N_purchasePrice.innerHTML = DecimalApps.setDisplayNumber(data.defaultProvider.purchasePrice).setSuffixAndHumanizeNumber('€');
		N_costPrice.innerHTML = DecimalApps.setDisplayNumber(data.defaultProvider.costPrice).setSuffixAndHumanizeNumber('€');
		N_recommendedSellPrice.innerHTML = DecimalApps.setDisplayNumber(data.defaultProvider.recommendedSellPrice).setSuffixAndHumanizeNumber('€');
	}

	public static register() {
		customElements.define(DetailProduct.tagName, DetailProduct);
	}
}

export default DetailProduct;
