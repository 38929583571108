import ApIcon from '@autoprog/icons';

import tippy, { Instance, Props } from 'tippy.js';

import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import S_Quote from '@services/QuoteService';

class QuoteIconCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;
	private value: string;

	private instanceTooltip: Instance<Props> | null = null;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('quoteIconCellRenderer');
		this.eGui.innerHTML = '';

		this.value = '';
	}

	public init(params: any) {
		this.value = params.value;

		if (this.value) {
			const N_icon = document.createElement('ap-icon') as ApIcon;
			N_icon.name = 'file-edit/line';

			this.eGui.append(N_icon);

			this.instanceTooltip?.destroy();

			S_Quote.getInstance().getById(this.value).then((data) => {
				this.instanceTooltip = tippy(N_icon, {
					trigger: 'mouseenter',
					placement: 'right',
					content: data.infos.number
				});
			});
		}
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default QuoteIconCellRenderer;
