// CORE
import { global, toaster } from '@autoprog/core-client';

// NODE_MODULE
import { ColDef } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_ListSelectOrder from '../../tpl/modals/listSelectOrder.html';

// LIBS
import Keyboard from '@libs/Keyboard';
import Modal from '@libs/Modal';

import CE_Aggrid from '@libs/customElement/AgGrid';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';

class ListSelectOrder extends Modal {
	private N_grid: CE_Aggrid | null = null;

	private isElectron = global.IS_ELECTRON;

	/**
	 * Constructeur.
	 * Si un ID de société est donné, les commandes à sélectionner seront filtrées pour n'afficher que celles de cette société.
	 * 
	 * @param companyID (facultatif) l'ID de la société
	 */
	constructor() {
		super({
			tpl: T_ListSelectOrder,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const columnDefs: ColDef[] = [{
				headerName: '',
				pinned: 'left',
				width: 60,
				checkboxSelection: true,
				suppressSizeToFit: true
			}, {
				headerName: 'N° Affaire',
				field: 'internalNumber'
			}, {
				headerName: 'N° Commande Client',
				field: 'infosCustomer.orderNumber'
			}, {
				headerName: 'Client',
				field: 'infosCustomer.customer'
			}, {
				headerName: 'Objet',
				field: 'label'
			}, {
				headerName: 'Date Commande',
				field: 'date'
			}, {
				headerName: 'Site(s)',
				field: 'sites'
			}, {
				headerName: 'Contact',
				field: 'infosCustomer.contact'
			}, {
				headerName: 'Montant',
				cellClass: 'text-right text-monospace',
				field: 'priceHT'
			}];

			let selectData: { [key: string]: any } = {};

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_title = this.element.querySelector('#title') as HTMLButtonElement;

			this.N_grid = this.element.querySelector<CE_Aggrid>('#grid')!;

			this.N_grid.setGridOptions({
				rowSelection: 'single',
				columnDefs,
				floatingFilter: !this.isElectron,
				suppressRowClickSelection: true,
				suppressContextMenu: true,
				defaultColDef: {
					filter: 'agTextColumnFilter',
					filterParams: {
						newRowsAction: 'keep'
					},
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					suppressMenu: true,
					resizable: true
				},
				onRowSelected: (params) => {
					selectData = {};
					N_save.disabled = true;
					N_title.innerHTML = '';
					params.api.forEachNode((node) => {
						if (node.isSelected()) {
							selectData = params.node.data._id.value;
							N_title.innerHTML = `Commande sélectionnée : ${params.node.data.internalNumber.formattedValue}`;
							N_save.disabled = false;
						}
					});
				}

			});

			S_C_Order.getInstance().getDataToAgGrid().then((data) => {
				this.N_grid!.value = data.rowData;
			}).catch(() => {
				toaster.error('Erreur lors de la récupération des données');
			});

			N_save.addEventListener('click', () => {
				this.resolve(selectData);
			});

			const N_search = this.element.querySelector('#search') as HTMLInputElement;
			const N_container_search = this.element.querySelector('#container_search') as HTMLElement;

			if (this.isElectron) {
				N_container_search.classList.add('d-flex');
			} else {
				N_container_search.classList.add('d-none');
			}

			new Keyboard(N_search, {
				eventClick: global.CLICK_EVENT_TACTILE,
				parent: this.element.querySelector('#container-keyboard') as HTMLElement
			});

			N_search.addEventListener('input', () => {
				this.N_grid!.api?.setQuickFilter(N_search.value);
			});
		});
	}
}

export default ListSelectOrder;
