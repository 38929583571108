import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import '@css/cellRenderer/editCellRenderer.scss';

class EditCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('editCellRenderer');
		this.eGui.innerHTML = '';
	}

	public init(params: any) {
		const HTML_button = '<ap-button class="btn-edit btn-icon" icon="edit/line"></ap-button>';

		if (params.CellRenderer) {
			const instance = new params.CellRenderer();
			instance.init(params);
			this.eGui.innerHTML = HTML_button + instance.getGui().outerHTML;
		} else {
			this.eGui.innerHTML = HTML_button + (params.value ?? '');
		}

		const N_button = this.eGui.querySelector('ap-button');

		N_button?.addEventListener('click', () => {
			params.api.startEditingCell({
				rowIndex: params.rowIndex,
				colKey: params.column.getId()
			});
		});
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default EditCellRenderer;
