import _ from 'lodash';
class FilesIcon extends HTMLElement {
	public static readonly tagName: string = 'ap-files-icon';

	private numberAttachements = 0;
	private attachments: { [key: string]: boolean } = {};

	public async connectedCallback() {
		this.innerHTML = `
            <div id="fileIndicator">
				<i class="icon h5 icon-file" tooltip="${this.numberAttachements} pièces jointes"></i>
				<span class="badge custom-badge" id="numberFile">${this.numberAttachements}</span>
            </div>
        `;

		if (!this.numberAttachements) {
			this.classList.add('d-none');
		}
	}

	public set data(data: { [key: string]: any }) {
		this.attachments = data.attachments || {};

		this.numberAttachements = _.compact(_.values(this.attachments)).length;

		const N_icon = this.querySelector('.icon') as HTMLElement;
		const N_badge = this.querySelector('.badge') as HTMLElement;

		if (this.numberAttachements) {
			if (this.numberAttachements > 1) {
				N_icon && N_icon.setAttribute('tooltip', `${this.numberAttachements} pièces jointes`);
			} else {
				N_icon && N_icon.setAttribute('tooltip', `${this.numberAttachements} pièce jointe`);
			}

			N_badge && (N_badge.innerHTML = this.numberAttachements.toString());

			this.classList.remove('d-none');
		} else {
			this.classList.add('d-none');
		}
	}

	public static register() {
		customElements.define(FilesIcon.tagName, FilesIcon);
	}
}

export default FilesIcon;
