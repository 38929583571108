import DecimalApps from '@js/libs/utils/Decimal';

import CalculTaxe from '@modules/Comptabilité/js/libs/CalculTaxe';

import { ContentLineBillPrice, TVARecap, TotalBillResult } from './index';
import { billCustomerFormCalcul, contentLine } from '../../types/billCustomer';

class CalculV1 {
	private bill: billCustomerFormCalcul;

	constructor(bill: billCustomerFormCalcul) {
		this.bill = bill;
	}

	private getAdvanceOrder() {
		return DecimalApps.setDisplayNumber(this.bill.order.advancePriceHT).toDecimalPlaces(2);
	}

	public async calculatePriceContentLine(line: contentLine): Promise<ContentLineBillPrice> {
		const unitPrice = DecimalApps.setDisplayNumber(line.price);
		const priceHT = unitPrice.times(line.quantity);

		const tva = new CalculTaxe(line.tva);
		await tva.init();

		return {
			unitPrice,
			priceHT,
			tva
		};
	}

	public async calculatePrice(): Promise<TotalBillResult> {
		let totalTTC = new DecimalApps(0);
		let totalHT = new DecimalApps(0);
		const advanceHT = this.getAdvanceOrder();

		let tvaKey = null;

		const tvaRecap: TVARecap = new Map();

		for (const item of this.bill.content) {
			const contentLine = item as contentLine;

			if (contentLine.price && contentLine.tva) {
				const priceContentLine = await this.calculatePriceContentLine(contentLine);

				const tvaValue = priceContentLine.tva.getRate();
				const idTVA = priceContentLine.tva.getID();

				if (!tvaKey) {
					tvaKey = priceContentLine.tva;
				}

				const tmpTVA = DecimalApps.setDisplayNumber(tvaValue).dividedBy(100);

				totalHT = totalHT.plus(priceContentLine.priceHT);
				totalTTC = totalTTC.plus(priceContentLine.priceHT.times(new DecimalApps(1).plus(tmpTVA)));

				if (this.bill.infos.type !== 'last') {
					if (tvaRecap.has(idTVA)) {
						tvaRecap.set(idTVA, {
							...tvaRecap.get(idTVA)!,
							priceHT: tvaRecap.get(idTVA)!.priceHT.plus(priceContentLine.priceHT)
						});
					} else {
						tvaRecap.set(idTVA, {
							priceHT: priceContentLine.priceHT,
							tva: priceContentLine.tva
						});
					}
				}
			}
		}

		if (this.bill.infos.type === 'last') {
			const tvaPercent = new DecimalApps(1).plus(new DecimalApps(tvaKey!.getRate()).dividedBy(100));

			totalHT = totalHT.minus(advanceHT);
			totalTTC = totalHT.times(tvaPercent);

			tvaRecap.clear();

			tvaRecap.set(tvaKey!.getID(), {
				priceHT: totalHT,
				tva: tvaKey!
			});
		}

		totalTTC = totalTTC.toDecimalPlaces(2);
		totalHT = totalHT.toDecimalPlaces(2);

		const totalTVA = totalTTC.minus(totalHT).toDecimalPlaces(2);

		return {
			totalTTC,
			totalHT,
			totalTVA,
			advanceHT: advanceHT.toDecimalPlaces(2),
			tvaRecap
		};
	}
}

export default CalculV1;
