module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card-mobile flex-column">\n\n    <div class="w-100 text-sm txt-bold text-blue-grey-900">\n        <div>\n            ' +
((__t = (data.name || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n    </div>\n\n</div>';

}
return __p
}