// CORE
import agUtils from '@libs/agGrid/french';
import { global } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, ColDef, ColGroupDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// LIBS
import Decimal from '@libs/utils/Decimal';
import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';

// MODAL
import M_AddEditProduct from '../../modals/AddEditProduct';
import M_Group from '@modules/Products/js/modals/groupsProducts/GroupsProducts';

// CUSTOM_ELEMENT
import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_SelectCategorie from './SelectCategorie';

// SERVICE
import S_Groups from '@services/Product/GroupsService';
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';
import S_Stocks from '@services/StockService';

class SelectProductsTabs extends HTMLElement {
	public static readonly tagName: string = 'ap-select-products-tabs';

	private mods: string[] | undefined;

	private N_productsNumber: HTMLElement | null = null;
	private N_productsTitle: HTMLElement | null = null;

	private N_groupsNumber: HTMLElement | null = null;
	private N_groupsTitle: HTMLElement | null = null;

	private gridOptionsGroup: GridOptions = {};

	private _selectProducts: { [key: string]: any } = {};
	private _selectGroups: { [key: string]: any } = {};

	private stocksLocations: any[] = [];

	private isFilterEnabled: boolean = true;

	private isElectron = global.IS_ELECTRON;

	private N_Product: CE_Aggrid | null = null;

	public async connectedCallback() {
		// Valeurs prises en comptes dans l'attribut 'mod' :
		// - "no-stock" (ne charge pas et n'affiche les informations concernant le stock)
		this.mods = this.getAttribute('mod')?.split(/\s+/);

		if (this.isElectron) {
			this.isFilterEnabled = false;
		}

		this.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="d-flex mb-3">

					<ul class="nav nav-pills d-flex" role="tablist">
						<li class="nav-item border-right">
							<a class="nav-link px-3 active" data-toggle="tab" href="#product" role="tab">Produits</a>
						</li>
						<li class="nav-item border-right">
							<a class="nav-link px-3" data-toggle="tab" href="#group" role="tab">Groupes</a>
						</li>
						<ap-select-categories class="ml-2"></ap-select-categories>
					</ul>

					<div class="flex-grow-1 px-3 d-none" id="search_container">
						<input class="form-control" placeholder="Recherche" id="search">
					</div>

					<div class="d-flex ml-auto">
						<div class="d-flex align-items-center pr-2">
							<span class="mr-1" id="products-title">Produit selectionné :</span><span id="products-number"> 0</span>
						</div>
						<div class="d-flex align-items-center px-2 border-left">
							<span class="mr-1" id="groups-title">Groupe selectionné :</span><span id="groups-number"> 0</span>
						</div>
						<div class="pl-2 border-left" id="add_buttons">
							<button class="btn btn-info" id="add_product">
								Créer Produit
							</button>

							<button class="btn btn-info mr-2" id="add_group">
								Créer Groupe
							</button>
						</div>
					</div>
				
				</div>

				<div class="tab-content flex-grow-1">

					<div class="tab-pane  h-100 fade show active" id="product" role="tabpanel">
						<ap-aggrid id="grid_product" class="dashboard-grid"></ap-aggrid>
					</div>

					<div class="tab-pane h-100 fade" id="group" role="tabpanel">

						<div class="w-100 h-100" id="content">
							<div id="grid_group" class="h-100 ag-theme-alpine"></div>
						</div>

					</div>
				</div>

			</div>
			
        `;

		const N_search = this.querySelector('#search') as HTMLInputElement;
		const N_search_container = this.querySelector('#search_container') as HTMLInputElement;

		if (this.isElectron) {
			this.removeAddButtons();
			N_search_container.classList.remove('d-none');
		} else {
			this.initAddButtons();
		}

		this.N_productsNumber = this.querySelector('#products-number') as HTMLElement;
		this.N_productsTitle = this.querySelector('#products-title') as HTMLElement;

		this.N_groupsNumber = this.querySelector('#groups-number') as HTMLElement;
		this.N_groupsTitle = this.querySelector('#groups-title') as HTMLElement;

		let timeout: any = null;
		N_search.addEventListener('input', () => {
			timeout && clearTimeout(timeout);
			timeout = setTimeout(() => {
				this.N_Product!.api?.setQuickFilter(N_search.value);
				this.gridOptionsGroup.api?.setQuickFilter(N_search.value);
			}, 200);
		});

		await this.initProduct();
		await this.initGroup();
	}

	private removeAddButtons() {
		const N_addButtons = this.querySelector('#add_buttons') as HTMLDivElement;
		N_addButtons.remove();
	}

	private initAddButtons() {
		const N_addProduct = this.querySelector('#add_product') as HTMLButtonElement;

		N_addProduct.addEventListener('click', async () => {
			const { data } = await new M_AddEditProduct().open();

			this._selectProducts[data.data._id] = true;

			this.getDataProduct();
		});

		const N_addGroup = this.querySelector('#add_group') as HTMLButtonElement;

		N_addGroup.addEventListener('click', () => {
			new M_Group().open().then((data) => {
				this._selectGroups[data.name] = data;

				this.getDataGroup();
			});
		});
	}

	public static register() {
		customElements.define(SelectProductsTabs.tagName, SelectProductsTabs);
	}

	private async initProduct() {
		let stocksColumns: ColGroupDef = { children: [] };

		if (!this.mods?.includes('no-stock')) {
			const tmp = await this.getStockColumn();
			this.stocksLocations = tmp.locations;
			stocksColumns = tmp.colDefs;
		}

		this.N_Product = this.querySelector<CE_Aggrid>('#grid_product');

		this.N_Product?.setGridOptions({
			rowSelection: 'multiple',
			suppressDragLeaveHidesColumns: true,
			onRowClicked: (e) => {
				e.node.setSelected(!e.node.isSelected());
			},
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: S_Product.getInstance().referenceKey,
					checkboxSelection: true
				},
				{
					headerName: 'Désignation',
					field: 'label'
				},
				{
					headerName: 'Marque',
					field: 'brand',
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					}
				},
				{
					headerName: 'Mots clés',
					field: 'tags'
				},
				{
					headerName: 'Catégories IDs',
					field: 'categoryIDs',
					hide: true
				},
				{
					headerName: 'Prix',
					field: 'price',
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 2,
						suffix: '€'
					}
				},
				stocksColumns
			],
			floatingFilter: this.isFilterEnabled,
			suppressRowClickSelection: true,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				suppressMenu: true,
				resizable: true
			},
			onRowSelected: (params) => {
				if (params.node.isSelected()) {
					this._selectProducts[params.node.data._id.value] = {
						product: this.convertDataProduct(params.node.data),
						quantity: 1
					};
				} else {
					delete this._selectProducts[params.node.data._id.value];
				}

				const numberProduct = Object.keys(this._selectProducts).length;

				this.N_productsNumber!.innerHTML = numberProduct.toString();

				if (numberProduct > 1) {
					this.N_productsTitle!.innerHTML = 'Produits selectionnés : ';
				} else {
					this.N_productsTitle!.innerHTML = 'Produit selectionné : ';
				}
			}

		});

		const N_SelectCategorie = this.querySelector<CE_SelectCategorie>(CE_SelectCategorie.tagName);
		N_SelectCategorie?.setGridOptions(this.N_Product!.gridOptions);

		await this.getDataProduct();

		$('[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			if (e.target.getAttribute('href') === '#product') {
				this.N_Product!.forEachNode((node) => {
					node.setSelected(!!this._selectProducts[node.data._id.value]);
				});
			}
		});
	}

	private async getDataProduct() {
		this.N_Product!.resetValue();

		const data = await S_Product.getInstance().getDataToAgGrid();

		data.rowData = data.rowData.filter((item: any) => !item.deprecated.value);

		this.N_Product!.value = data.rowData;

		if (!this.mods?.includes('no-stock')) {
			this.initStock();
		}

		this.N_Product!.forEachNode((node) => {
			if (this._selectProducts[node.data._id.value]) {
				this._selectProducts[node.data._id.value] = {
					product: this.convertDataProduct(node.data),
					quantity: 1
				};
				node.setSelected(true);
			} else {
				node.setSelected(false);
			}
		});
	}

	private convertDataProduct(product: { [key: string]: any }) {
		return {
			_id: product._id.value,
			brand: product.brand.value,
			price: product.price.value,
			defaultProvider: {
				costPrice: product.defaultProvider?.costPrice.value || 0
			},
			label: product.label.value,
			time: 0,
			comptaVente: product.comptaVente.value,
			tvaRate: product.tvaRate.value,
			unit: product.unit.value
		};
	}

	private async getStockColumn(): Promise<{ colDefs: ColGroupDef, locations: any[] }> {
		const childrenStock: ColDef[] = [];

		const locations = await S_Stocks.getInstance().getStockToCommandCustomer();

		for (const item of locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: ['text-monospace', 'text-right'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});
			})(item);
		}

		return {
			colDefs: { headerName: 'Stock', children: childrenStock },
			locations
		};
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			const productID = item.product._id.value;
			const stockID = item.stock.value;

			stock[productID] = stock[productID] || {};
			stock[productID][stockID] = stock[productID][stockID] || { quantity: 0 };
			stock[productID][stockID].quantity = item.quantity.formattedValue;
		}

		this.N_Product!.forEachNode((node) => {
			for (const item of this.stocksLocations) {
				const productID = node.data._id.value;
				const stockID = item._id;

				stock[productID] = stock[productID] || {};
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[stockID] = stock[productID][stockID] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	private async initGroup() {
		this.gridOptionsGroup = agUtils.french<GridOptions>({
			rowData: [],
			animateRows: true,
			floatingFilter: this.isFilterEnabled,
			suppressDragLeaveHidesColumns: true,
			suppressContextMenu: true,
			groupMultiAutoColumn: true,
			groupSelectsChildren: true,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			onRowClicked: (e) => {
				if (e.node.group) {
					e.node.setSelected(!e.node.isSelected());
				}
			},
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				resizable: true,
				filterParams: {
					newRowsAction: 'keep'
				}
			},
			columnDefs: [
				{
					field: 'name',
					headerName: 'Nom',
					rowGroup: true,
					hide: true
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: `product.${S_Product.getInstance().referenceKey}`
				},
				{
					headerName: 'Désignation',
					field: 'product.label'
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					}
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: ['text-monospace', 'text-right']
				}, {
					headerName: 'Prix',
					headerTooltip: 'Prix',
					field: 'product.price',
					filter: 'agNumberColumnFilter',
					cellClass: ['text-monospace', 'text-right'],
					comparator: (valueA, valueB) => {
						valueA = valueA?.value || '';
						valueB = valueB?.value || '';
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						if (params.data) {
							return params.data.product.price.value;
						}
					},
					cellRenderer: (params) => {
						if (params.node.group) {
							let price = new Decimal(0);

							for (const node of params.node.childrenAfterGroup) {
								const tmpPrice = Decimal.setDisplayNumber(node.data.product.price.value).times(node.data.quantity || 0);
								price = price.plus(tmpPrice);
							}

							return `<span class="font-weight-bold">${price.setSuffixAndHumanizeNumber('€')}</span>`;
						} else {
							return params.data.product.price.formattedValue;
						}
					}
				}
			],
			autoGroupColumnDef: {
				headerName: 'Nom',
				cellRendererParams: {
					suppressCount: true,
					checkbox: true
				},
				filter: 'agTextColumnFilter',
				filterValueGetter: params => params.data.name
			},
			onRowSelected: (params) => {
				if (params.node.group) {
					const key = params.node.key || '';
					if (params.node.isSelected()) {
						this._selectGroups[key] = params.node.childrenAfterGroup?.map(item => {
							return {
								quantity: item.data.quantity,
								product: this.convertDataProductGroup(item.data.product)
							};
						});
					} else {
						delete this._selectGroups[key];
					}
				}

				this.N_groupsNumber!.innerHTML = Object.keys(this._selectGroups).length.toString();

				if (Object.keys(this._selectGroups).length > 1) {
					this.N_groupsTitle!.innerHTML = 'Groupes selectionnés : ';
				} else {
					this.N_groupsTitle!.innerHTML = 'Groupe selectionné : ';
				}
			}
		});

		const N_grid = this.querySelector('#grid_group') as HTMLElement;

		new Grid(N_grid, this.gridOptionsGroup, { modules: AllModules });

		await this.getDataGroup();

		$('[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			if (e.target.getAttribute('href') === '#group') {
				this.gridOptionsGroup.api?.sizeColumnsToFit();
			}
		});
	}

	private async getDataGroup() {
		const data = await S_Groups.getInstance().getDataToAgGrid();

		this.gridOptionsGroup.api?.setRowData(data.rowData);

		this.gridOptionsGroup.api?.forEachNode((node) => {
			if (node.group) {
				node.setSelected(!!this._selectGroups[node.key || '']);
			}
		});
	}

	/**
	 * Convertit les données de produits des groupes au format attendu pour le groupe de devis
	 * @param product le produit du groupe
	 * @returns 
	 */
	private convertDataProductGroup(product: { [key: string]: any }) {
		return {
			_id: product._id,
			brand: product.brand,
			price: product.price.value,
			defaultProvider: {
				costPrice: product.defaultProvider?.costPrice.value || 0
			},
			label: product.label,
			comptaVente: product.comptaVente,
			tvaRate: product.tvaRate,
			unit: product.unit
		};
	}

	public get data() {
		return {
			products: this._selectProducts,
			groups: this._selectGroups
		};
	}

	public get onlyProducts() {
		const results: { [key: string]: any } = {};

		for (const id in this._selectProducts) {
			const product = this._selectProducts[id].product;
			const quantity = this._selectProducts[id].quantity;

			results[product._id] = results[product._id] || {};

			results[product._id].product = product;

			results[product._id].quantity = results[product._id].quantity || 0;
			results[product._id].quantity += Number(quantity);
		}

		for (const key in this._selectGroups) {
			for (const item of this._selectGroups[key]) {
				const product = item.product;
				const quantity = item.quantity;

				if (product._id) {
					results[product._id] = results[product._id] || {};

					results[product._id].product = product;

					results[product._id].quantity = results[product._id].quantity || 0;
					results[product._id].quantity = Number(quantity);
				}
			}
		}

		return results;
	}

	public resetSelection() {
		this.N_Product!.forEachNode((node) => {
			node.setSelected(false);
		});
		this.gridOptionsGroup.api?.forEachNode((node) => {
			node.setSelected(false);
		});
		this._selectProducts = {};
		this._selectGroups = {};
	}
}

export default SelectProductsTabs;
