// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../tpl/modals/Edit.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

class Edit extends Modal {
	constructor(product: string, quantity: number, stock: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			const N_select = form.getElementByName('product') as HTMLSelectElement;

			const tmp = await S_Product.getInstance().getDataToSelect2ByID(product);

			const N_option = new Option(tmp.text, tmp.id, true, true);

			N_select.appendChild(N_option);

			form.setData({
				product,
				quantity
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				const data = form.getData();

				const newQuantity = data.quantity;

				data.type = 'update';

				data.stock = stock;

				data.quantity = (data.quantity as number) - quantity;

				await S_StockEvent.getInstance().save(data);

				this.resolve(newQuantity);
			});
		});
	}
}

export default Edit;
