import { DOM, toaster } from '@autoprog/core-client';

import SelectEditor from '@libs/agGrid/new/SelectEditor';
import TextareaEditor from '@libs/agGrid/TextareaEditor';

import { CellClassParams, ColDef } from '@ag-grid-enterprise/all-modules';

import M_AddFavorite from '../modals/AddQuote/AddFavorite';
import M_DuplicateMultiple from '../modals/AddQuote/DuplicateMultiple';
import M_SelectProduct from '@modules/Products/js/modals/SelectProduct';

import _ from 'lodash';
import h from 'hyperscript';

import AggridUtils from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import Utils from '@libs/utils/Utils';

import Clipboard from './Clipboard';

import CE_AddLine from '@libs/customElement/button-edit-aggrid/AddLine';
import CE_Button from '@libs/customElement/Button';
import CE_SelectProduct from '@modules/Products/js/libs/customElement/button-edit-aggrid/SelectProduct';
import CE_UpdateGroup from '../libs/customElement/button-edit-aggrid/UpdateGroup';

import S_Products from '@services/Product/ProductService';
import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import M_AddEditProduct from '@modules/Products/js/modals/AddEditProduct';

import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

import Calcul from './Calcul';
import QuoteData from './QuoteData';
import QuoteOption from './QuoteOptions';

import AccountCellRenderer from '@modules/Comptabilité/js/libs/AccountCellrenderer';
import NotFinishCellRenderer from './cellRenderer/NotFinishCellRenderer';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import PUGroupCellRenderer from './cellRenderer/PUGroupCellRenderer';
import SumGroupCellRenderer from './cellRenderer/SumGroupCellRenderer';
import TaxeCellRenderer from '@modules/Comptabilité/js/libs/TaxeCellRenderer';
import TextareaCellRenderer from '@libs/agGrid/TextareaCellRenderer';

import BrandCellRenderer from '@modules/Products/js/libs/BrandCellRenderer';
import Settings from '@modules/Settings/js/libs/Settings';
import UnitCellRenderer from '@modules/Units/js/libs/UnitCellrenderer';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};
class Options {
	private _isLock: boolean;
	private _calcul: Calcul | null;

	private quoteOption: QuoteOption | null;

	private settingsAccounting: { [key: string]: any };

	public constructor() {
		this._isLock = false;
		this._calcul = null;
		this.quoteOption = null;

		this.settingsAccounting = Settings.getInstance().get('accountings');
	}

	public init() {
		this.quoteOption = QuoteOption.getInstance();
		this._calcul = new Calcul();
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	public getType(params: any) {
		return params.data.type || this.quoteOption!.pref.type || QuoteData.NO_MARGIN;
	}

	public getTypeMat(params: any) {
		return params.data.typeMat || this.quoteOption!.pref.typeMat || QuoteData.NO_MO;
	}

	public getTVA(params: any) {
		return params.data.tva || this.settingsAccounting?.defaultTaxe?.sale || '';
	}

	public getUnit(params: any) {
		return params.data.unit || QuoteData.DEFAULT_UNIT;
	}

	public getComptaVente(params: any) {
		if (params.data.reference && !params.data.comptaVente) {
			return this.settingsAccounting?.defaultAccount?.sale?.product || '';
		} else {
			return params.data.comptaVente || '';
		}
	}

	private getMarginType() {
		return this.quoteOption!.getMarginType();
	}

	private getHourlyPriceType() {
		return this.quoteOption!.getHourlyPriceType();
	}

	private convertProduct = async (id: string, oldData: { [key: string]: any }) => {
		const { data } = await S_Products.getInstance().getDataToModal(id);

		return {
			_idGrid: Utils.generateId(),
			...oldData,
			reference: id,
			label: data.data.label,
			price: data.data.price,
			unit: data.data.unit.id,
			brand: data.data.brand.id,
			tva: data.data.tvaRate.id,
			time: oldData.time ?? '0',
			comptaVente: data.data.comptaVente.id,
			costPrice: data.data.defaultProvider?.costPrice || 0
		};
	};

	public gridMaster(mode: string) {
		const columnDefs: ColDef[] = [
			{
				headerName: '',
				field: '_idGrid',
				cellRenderer: 'agGroupCellRenderer',
				editable: false,
				resizable: false,
				pinned: 'left',
				hide: mode === 'modal',
				rowDrag: mode !== 'favorite',
				width: 60,
				valueGetter: () => {
					return '';
				}
			}, {
				headerName: '',
				field: 'notFinish',
				editable: false,
				resizable: false,
				pinned: 'left',
				width: 10,
				hide: mode === 'modal' || mode === 'favorite',
				cellRenderer: NotFinishCellRenderer,
				cellClass: 'ag-cell-renderer-not-finish'
			},
			{
				headerName: 'Catégorie',
				field: 'category',
				width: 150,
				cellClassRules: {
					'ag-cell-error': (params: CellClassParams) => !params.value
				}
			}, {
				headerName: 'Sous-Catégorie',
				field: 'subCategory',
				width: 130
			}, {
				headerName: 'Description',
				field: 'name',
				autoHeight: true,
				wrapText: true,
				suppressSizeToFit: false,
				cellEditor: TextareaEditor,
				cellClass: mode === 'modal' ? 'ag-cell-scrollable' : '',
				cellRenderer: TextareaCellRenderer
			}, {
				headerName: 'Unité',
				field: 'unit',
				width: 70,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						table: 'units'
					}
				},
				cellRenderer: UnitCellRenderer
			}, {
				headerName: 'Qté',
				field: 'quantity',
				width: 70,
				cellEditor: NumericCellEditor,
				cellStyle: (params) => {
					return {
						'background-color': (!params.value || params.value === '0') ? 'var(--ap-red-200)' : ''
					};
				},
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: 0
				}
			}, {
				headerName: 'P.U.',
				width: 150,
				field: 'price',
				editable: false,
				cellClass: 'text-right text-monospace',
				cellRenderer: PUGroupCellRenderer
			}, {
				headerName: 'Total HT',
				field: 'sum',
				editable: false,
				width: 150,
				cellClass: 'text-right text-monospace',
				cellRenderer: SumGroupCellRenderer
			}, {
				headerName: 'Action',
				editable: false,
				pinned: 'right',
				cellClass: 'p-0',
				hide: mode === 'modal',
				width: 110,
				cellRenderer: (params) => {
					let icon = 'star/line';

					if (params.data._idFav) {
						icon = 'star/fill';
					}

					const N_favorite = h<CE_Button>('ap-button.btn-action-aggrid.btn-default', { attrs: { icon } });

					const updateFavorite = (value: string) => {
						if (value) {
							N_favorite.icon = 'star/fill';
							N_favorite.tooltip = 'Modifier le favori ?';
							N_favorite.confirmation = true;
						} else {
							N_favorite.icon = 'star/line';
							N_favorite.tooltip = 'Créer un favori';
						}
					};

					N_favorite.addEventListener('click', async () => {
						if (!params.data._idFav) {
							new M_AddFavorite(params.data).open().then((id) => {
								params.data._idFav = id;

								updateFavorite(params.data._idFav);
							});
						} else {
							const newData = _.cloneDeep(params.data);
							newData._id = params.data._idFav;

							const data = await S_Q_Favorite.getInstance().save(newData);

							if (!data.err) {
								toaster.success('Sauvegarde réussi');
							}
						}
					});

					const N_duplicate = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'duplicate' } });

					N_duplicate.addEventListener('click', () => {
						const item = _.cloneDeep(params.data);

						item._idGrid = Utils.generateId();

						AggridUtils.addLine(params, item);
					});

					const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete' } });

					N_delete.addEventListener('click', () => {
						params.api.applyTransaction({
							remove: [params.data]
						});
					});

					DOM.nextTick().then(() => {
						N_delete.disabled = this._isLock;
						N_duplicate.disabled = this._isLock;

						updateFavorite(params.data._idFav);
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_favorite, N_duplicate, N_delete);

					return N_div;
				}
			}
		];

		return {
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			//stopEditingWhenCellsLoseFocus: true, //ne marche pas avec les select2/selectCustom
			suppressScrollOnNewData: true,
			columnDefs,
			rowHeight: 250, //il faut une valeur differente de 25 (par defaut pour pas que le scroll ce reset)
			defaultColDef: {
				editable: () => {
					return !this._isLock;
				},
				cellStyle: (params: any) => {
					const colId = params.column.getColId();

					params.data.style = params.data.style || {};

					if (params.data.style[colId]) {
						return params.data.style[colId];
					} else {
						return {};
					}
				},
				resizable: true,
				suppressMovable: true,
				suppressMenu: true,
				suppressSizeToFit: true
			}
		};
	}

	public gridDetails(mode: string, updateGrid?: any) {
		const clipboard = Clipboard.getInstance();

		const columnDefs: ColDef[] = [
			{
				headerName: '',
				rowDrag: true,
				field: 'drag',
				pinned: 'left',
				resizable: false,
				width: 40,
				hide: mode === 'favorite',
				suppressSizeToFit: true,
				valueGetter: () => {
					return '';
				}
			},
			{
				headerName: S_Products.getInstance().columnNameReference,
				field: 'reference',
				width: 170,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						table: 'products'
					}
				},
				cellRenderer: ProductCellRenderer
			}, {
				headerName: 'Marque',
				field: 'brand',
				width: 120,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						table: 'brands'
					}
				},
				cellRenderer: BrandCellRenderer
			}, {
				headerName: 'Libellé',
				field: 'label',
				cellClassRules: {
					'ag-cell-error': (params: CellClassParams) => !params.value
				},
				valueSetter: (params) => {
					if (params.newValue) {
						const value = params.newValue.trim();
						params.data.label = value;
					}

					return true;
				},
				suppressSizeToFit: false
			}, {
				headerName: 'Compte comptable',
				field: 'comptaVente',
				width: 120,
				hide: !this.settingsAccounting?.enabled,
				editable: mode !== 'favorite',
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						table: 'accounts',
						refData: {
							forProduct: true,
							type: 'sale'
						}
					}
				},
				valueGetter: (params) => {
					return this.getComptaVente(params);
				},
				cellRenderer: AccountCellRenderer
			}, {
				headerName: 'Qté',
				field: 'quantity',
				width: 80,
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: 0
				}
			}, {
				headerName: 'U',
				field: 'unit',
				width: 60,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						table: 'units'
					}
				},
				valueGetter: (params) => {
					return this.getUnit(params);
				},
				cellRenderer: UnitCellRenderer
			}, {
				headerName: 'Type Mat.',
				field: 'typeMat',
				width: 110,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						dataFunction: this.getMarginType.bind(this)
					}
				},
				cellStyle: (params) => {
					if (params.data.notApplyCoefOnMat && params.data.typeMat !== QuoteData.DISCOUNT) {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					} else {
						return {
							'background-color': '',
							color: ''
						};
					}
				},
				valueGetter: (params) => {
					return this.getTypeMat(params);
				},
				cellRenderer: (params) => {
					const tmp = this.quoteOption!.quoteOptions.marginType;
					return tmp[params.value] || params.value;
				}
			}, {
				headerName: 'P.U. Mat.',
				field: 'price',
				width: 110,
				valueGetter: (params) => {
					return params.data.price || '0';
				},
				cellRenderer: (params) => {
					const numericCellRenderer = new NumericCellRenderer();
					numericCellRenderer.init(params);

					const N_div = numericCellRenderer.getGui();

					if (params.data.reference) {
						if (parseFloat(params.data.costPrice) > parseFloat(params.data.price)) {
							N_div.title = `Prix inférieur au prix d'achat ( ${Decimal.setDisplayNumber(params.data.costPrice).humanizeNumber()} € )`;
							N_div.style.backgroundColor = '#ff8282';
						}
					}

					return N_div;
				},
				cellRendererParams: {
					decimalNumber: -1,
					suffix: '€'
				}
			}, {
				headerName: 'Type MO',
				field: 'type',
				width: 100,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor',
				cellEditorParams: {
					options: {
						dataFunction: this.getHourlyPriceType.bind(this)
					}
				},
				valueGetter: (params) => {
					return this.getType(params);
				},
				cellRenderer: (params) => {
					const tmp = this.quoteOption!.quoteOptions.hourlyPriceType;
					return tmp[params.value] || params.value;
				},
				cellStyle: (params) => {
					if (params.data.notApplyCoefOnMO) {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					} else {
						return { 'background-color': '', color: '' };
					}
				}
			}, {
				headerName: 'Tps MO',
				field: 'time',
				width: 90,
				cellClass: 'text-right text-monospace',
				valueGetter: (params) => {
					return params.data.time || '0';
				},
				valueSetter: (params) => {
					const value = params.newValue.replace(/\s/gmi, '');

					const number = parseFloat(value);

					const key: string = value[value.length - 1] || '';

					const authorizeKeys: { [key: string]: number } = {
						m: Math.floor(35 * 52 / 12), // 35×52/12 = 151,67, 35h X 52 semaine / 12 mois
						s: 5 * 7,
						w: 5 * 7,
						j: 7
					};

					if (authorizeKeys[key]) {
						params.data.time = number * authorizeKeys[key];
					} else {
						params.data.time = params.newValue;
					}

					return true;
				},
				cellRenderer: (params) => {
					const suffix = this.getType(params) === QuoteData.KILOMETRAGE_COST ? 'km' : 'h&nbsp;';
					return Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumber(suffix, -1);
				}
			}, {
				headerName: 'Total Mat.',
				field: 'MAT_Total',
				width: 110,
				editable: false,
				valueGetter: (params) => {
					return this._calcul!.calculatePrice(params.data).sumMat;
				},
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: -1,
					suffix: '€'
				}
			}, {
				headerName: 'Total MO',
				field: 'MO_Total',
				width: 120,
				editable: false,
				valueGetter: (params) => {
					return this._calcul!.calculatePrice(params.data).sumMO;
				},
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: -1,
					suffix: '€'
				}
			}, {
				headerName: 'PU Total',
				field: 'PU_Total',
				width: 120,
				editable: false,
				hide: true, //!this.quoteOption!.pref.displayPUDetails, FIXME:
				valueGetter: (params) => {
					return this._calcul!.calculatePrice(params.data).price;
				},
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: -1,
					suffix: '€'
				}
			}, {
				headerName: 'Total HT',
				field: 'total',
				width: 120,
				editable: false,
				valueGetter: (params) => {
					return this._calcul!.calculatePrice(params.data).sum;
				},
				cellRenderer: NumericCellRenderer,
				cellRendererParams: {
					decimalNumber: -1,
					suffix: '€'
				}
			}, {
				headerName: 'TVA',
				field: 'tva',
				width: 80,
				hide: mode === 'favorite',
				suppressSizeToFit: true,
				cellEditor: SelectEditor,
				cellClass: 'ag-select-editor text-right text-monospace',
				cellClassRules: {
					'ag-cell-error': (params: CellClassParams) => !params.value
				},
				cellEditorParams: {
					options: {
						table: 'taxes',
						refData: {
							type: 'sale'
						}
					}
				},
				valueGetter: (params) => {
					return this.getTVA(params);
				},
				cellRenderer: TaxeCellRenderer
			}, {
				headerName: 'Action',
				field: 'button',
				width: 110,
				pinned: 'right',
				resizable: false,
				editable: false,
				cellClass: 'p-0',
				cellRenderer: (params) => {
					const N_displayDetails = h<CE_Button>('ap-button.btn-action-aggrid.btn-switch', { attrs: { icon: 'file-list/line' } });

					const updateDescriptionButton = (reference: string, value: boolean) => {
						if (reference) {
							if (value) {
								N_displayDetails.tooltip = 'Masquer la description';
								N_displayDetails.icon = 'file-list/fill';
								N_displayDetails.switchOn();
							} else {
								N_displayDetails.tooltip = 'Afficher la description';
								N_displayDetails.icon = 'file-list/line';
								N_displayDetails.switchOff();
							}
						} else {
							N_displayDetails.disabled = true;
							N_displayDetails.icon = 'file-list/line';
							N_displayDetails.switchOff();
						}
					};

					N_displayDetails.addEventListener('click', () => {
						params.data.displayDetails = !params.data.displayDetails;

						updateDescriptionButton(params.data.reference, params.data.displayDetails);

						params.api.applyTransaction({ update: [params.data] });

						updateGrid && updateGrid();
					});

					const N_disabledPrint = h<CE_Button>('ap-button.btn-action-aggrid.btn-switch', { attrs: { icon: 'printer/line' } });

					const updatePrintButton = (disabledPrint: boolean) => {
						if (disabledPrint) {
							N_disabledPrint.tooltip = 'Afficher à l\'impression';
							N_disabledPrint.icon = 'printer/line';
							N_disabledPrint.switchOff();
						} else {
							N_disabledPrint.tooltip = 'Masquer à l\'impression';
							N_disabledPrint.icon = 'printer/fill';
							N_disabledPrint.switchOn();
						}
					};

					N_disabledPrint.addEventListener('click', () => {
						params.data.disabledPrint = !params.data.disabledPrint;

						updatePrintButton(params.data.disabledPrint);

						params.api.applyTransaction({ update: [params.data] });

						updateGrid && updateGrid();
					});

					const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete' } });

					N_delete.addEventListener('click', () => {
						params.api.applyTransaction({
							remove: [params.data]
						});

						params.api.setPinnedBottomRowData([{}]);

						updateGrid && updateGrid();
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_displayDetails, N_disabledPrint, N_delete);

					DOM.nextTick().then(() => {
						N_displayDetails.disabled = this._isLock;
						N_disabledPrint.disabled = this._isLock;
						N_delete.disabled = this._isLock;

						updatePrintButton(params.data.disabledPrint);
						updateDescriptionButton(params.data.reference, params.data.displayDetails);
					});

					return N_div;
				}
			}
		];

		const onCellEditingStopped = (params: any) => {
			if (params.column.getColId() === 'reference' && params.oldValue !== params.newValue) {
				this.convertProduct(params.data.reference, { ...params.data, displayDetails: false }).then((product) => {
					params.node.setData(product);

					params.api.refreshCells({ force: true, columns: ['brand', 'label', 'comptaVente', 'unit', 'quantity', 'typeMat', 'price', 'type', 'time', 'MAT_Total', 'MO_Total', 'PU_Total', 'total', 'button', 'tva'] });

					params.api.setPinnedBottomRowData([{}]);

					updateGrid && updateGrid();
				});
			}

			if (['brand', 'label', 'unit', 'reference'].indexOf(params.column.getColId()) === -1) {
				params.api.refreshCells({ force: true, columns: ['MAT_Total', 'MO_Total', 'PU_Total', 'total'] });
			}

			if (params.column.getColId() === 'type') {
				params.api.refreshCells({ force: true, columns: ['time'] });
			}

			params.api.setPinnedBottomRowData([{}]);

			updateGrid && updateGrid();
		};

		const getContextMenuItems = (params: any) => {
			if (params.node?.rowPinned) {
				return [];
			} else {
				const addLine = AggridUtils.contextMenuAddLines(params, (i: number) => {
					return {
						_idGrid: Utils.generateId() + '_' + i,
						quantity: 1,
						unit: QuoteData.DEFAULT_UNIT
					};
				}, this._isLock);

				const coef = {
					name: 'Coef. Total',
					icon: '<ap-icon name="close/line"></ap-icon>',
					disabled: this._isLock,
					subMenu: [{
						name: 'Desactiver pour MO',
						action: () => {
							params.node.data.notApplyCoefOnMO = !params.node.data.notApplyCoefOnMO;

							params.node.setData(params.node.data);
							params.api.setPinnedBottomRowData([{}]);
							params.api.refreshCells({ force: true });

							updateGrid && updateGrid();
						}
					}, {
						name: 'Desactiver pour Mat.',
						action: () => {
							params.node.data.notApplyCoefOnMat = !params.node.data.notApplyCoefOnMat;

							params.node.setData(params.node.data);
							params.api.setPinnedBottomRowData([{}]);
							params.api.refreshCells({ force: true });

							updateGrid && updateGrid();
						}
					}]
				};

				const duplicate = {
					name: 'Dupliquer',
					disabled: this._isLock,
					icon: '<ap-icon name="file-copy/line"></ap-icon>',
					subMenu: [{
						name: 'Ici',
						disabled: !params.node,
						action: () => {
							const item = _.cloneDeep(params.node.data);

							item._idGrid = Utils.generateId();

							AggridUtils.insertLine(params, item);

							params.api?.refreshCells({ force: true });
							params.api?.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}, {
						name: 'À la fin',
						disabled: !params.node,
						action: () => {
							const item = _.cloneDeep(params.node.data);

							item._idGrid = Utils.generateId();

							AggridUtils.addLine(params, item);

							params.api?.refreshCells({ force: true });
							params.api?.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}, {
						name: 'Multiple',
						action: () => {
							const data: { [key: string]: any }[] = [];

							params.api.forEachNode((node: any) => {
								data.push(node.data);
							});

							new M_DuplicateMultiple(data).open().then(({ data, end }) => {
								if (end) {
									AggridUtils.addLines(params, data);
								} else {
									AggridUtils.insertLines(params, data);
								}

								params.api?.refreshCells({ force: true });
								params.api?.setPinnedBottomRowData([{}]);

								updateGrid && updateGrid();
							});
						}
					}]
				};

				const product = {
					name: 'Produit',
					disabled: this._isLock,
					icon: '<ap-icon name="price-tag/line"></ap-icon>',
					subMenu: [{
						name: 'Actualiser',
						icon: '<ap-icon name="loop-left"></ap-icon>',
						disabled: !(params.node && params.node.data.reference),
						subMenu: [{
							name: 'Prix',
							action: async () => {
								const product = await this.convertProduct(params.node.data.reference, params.node.data);

								params.node.data.price = product.price;
								params.node.data.costPrice = product.costPrice;

								params.node.setData(params.node.data);

								params.api.refreshCells({ force: true, node: [params.node] });
								params.api.setPinnedBottomRowData([{}]);

								updateGrid && updateGrid();
							}
						}, {
							name: 'Libellé',
							action: async () => {
								const product = await this.convertProduct(params.node.data.reference, params.node.data);

								params.node.data.label = product.label;

								params.node.setDataValue('label', product.label);

								params.api.refreshCells({ force: true, node: [params.node] });

								updateGrid && updateGrid();
							}
						}]

					}, {
						name: 'Éditer',
						icon: '<ap-icon name="edit/line"></ap-icon>',
						disabled: !params.node?.data.reference,
						action: async () => {
							await new M_AddEditProduct(params.node.data.reference).open();

							const product = await this.convertProduct(params.node.data.reference, params.node.data);

							params.node.data.price = product.price;
							params.node.data.brand = product.brand;
							params.node.data.comptaVente = product.comptaVente;
							params.node.data.costPrice = product.costPrice || 0;

							params.node.setData(params.node.data);

							params.api.refreshCells({ force: true, node: [params.node] });
							params.api.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					},
					{
						name: 'Nouveau',
						icon: '<ap-icon name="add/line"></ap-icon>',
						action: async () => {
							const res = await new M_AddEditProduct().open();

							params.api.applyTransaction({
								add: [{
									_idGrid: Utils.generateId(),
									quantity: 1,
									reference: res.data.data._id,
									brand: res.data.data.brand,
									price: res.data.data.price,
									costPrice: res.data.data.defaultProvider?.costPrice || 0,
									displayDetails: false,
									label: res.data.data.label,
									time: res.data.data.time,
									comptaVente: res.data.data.comptaVente,
									unit: res.data.data.unit || 'U'
								}]
							});

							let index = -1;

							params.api.forEachNode(() => {
								index++;
							});

							params.api.ensureIndexVisible(index);
							params.api.refreshCells({ force: true });
							params.api.setPinnedBottomRowData([{}]);

							updateGrid && updateGrid();
						}
					}]
				};

				const refresh = {
					name: 'Actualiser le groupe',
					disabled: this._isLock,
					icon: '<ap-icon name="loop-left/line"></ap-icon>',
					subMenu: [{
						name: 'Prix',
						action: async () => {
							if (params.api) {
								const nodes: any[] = [];

								params.api.forEachNode((node: any) => {
									nodes.push(node);
								});

								for (const node of nodes) {
									if (node.data.reference) {
										const data = await S_Products.getInstance().getById(node.data.reference);

										node.data.price = data.price;
										node.data.costPrice = data.defaultProvider?.costPrice || 0;

										node.setData(node.data);
									}
								}

								params.api.refreshCells({ force: true });
								params.api.setPinnedBottomRowData([{}]);

								updateGrid && updateGrid();
							}
						}
					}, {
						name: 'Libellé',
						action: async () => {
							const nodes: any[] = [];

							params.api.forEachNode((node: any) => {
								nodes.push(node);
							});

							await Promise.all(nodes.map(async (node: any) => {
								const data = await S_Products.getInstance().getById(node.data.reference);
								node.data.label = data.label;
								node.setDataValue('label', data.label);
							}));

							params.api.refreshCells({ force: true });

							updateGrid && updateGrid();
						}

					}]
				};

				const select = {
					name: 'Sélectionner',
					disabled: this._isLock,
					icon: '<ap-icon name="list-check-3"></ap-icon>',
					action: () => {
						new M_SelectProduct().open().then(({ products, groups }) => {
							const data = [];

							for (const id in products) {
								const product = products[id].product;
								const quantity = products[id].quantity;

								data.push({
									_idGrid: Utils.generateId(),
									quantity,
									reference: product._id,
									brand: product.brand,
									price: product.price,
									costPrice: product.defaultProvider?.costPrice || 0,
									displayDetails: false,
									label: product.label,
									time: product.time,
									comptaVente: product.comptaVente,
									tva: product.tvaRate,
									unit: product.unit || QuoteData.DEFAULT_UNIT
								});
							}

							for (const key in groups) {
								for (const item of groups[key]) {
									const product = item.product;
									const quantity = item.quantity;

									data.push({
										_idGrid: Utils.generateId(),
										quantity,
										reference: product._id,
										brand: product.brand,
										price: product.price,
										costPrice: product.defaultProvider?.costPrice.value || 0,
										displayDetails: false,
										label: product.label,
										time: product.time,
										comptaVente: product.comptaVente,
										tva: product.tvaRate,
										unit: product.unit || QuoteData.DEFAULT_UNIT,
										disabledPrint: false
									});
								}
							}

							params.api.applyTransaction({
								add: data
							});

							params.api.setPinnedBottomRowData([{}]);
							params.api.refreshCells({ force: true });

							updateGrid && updateGrid();
						}).catch(() => { });
					}
				};

				const copy = {
					name: 'Copier',
					disabled: !params.node,
					icon: '<ap-icon name="file-copy/line"></ap-icon>',
					action: () => {
						clipboard.setData(_.cloneDeep(params.node.data), 'details');
					}
				};

				const paste = {
					name: 'Coller',
					disabled: this._isLock || !clipboard.getData('details'),
					icon: '<ap-icon name="clipboard/line"></ap-icon>',
					action: () => {
						const data = clipboard.getData('details');

						params.api.applyTransaction({
							add: [data]
						});

						params.api.refreshCells({ force: true });
						params.api.setPinnedBottomRowData([{}]);

						updateGrid && updateGrid();
					}
				};

				const copyReference = {
					name: 'Copier référence',
					disabled: !(params.node && params.node.data.reference),
					icon: '<ap-icon name="file-copy/line"></ap-icon>',
					action: async () => {
						const data = await S_Products.getInstance().getById(params.node.data.reference);

						try {
							navigator.clipboard.writeText(data.reference);
							toaster.success('Copie réussie');
						} catch (err) { //En cas d'erreur
							toaster.error('Erreur lors de la copie');
						}
					}
				};

				const style = this.styleContextMenu(params, () => {
					updateGrid && updateGrid();
				});

				if (params.node) {
					if (params.node.data.notApplyCoefOnMO) {
						coef.subMenu[0].name = 'Activer pour MO';
					}

					if (params.node.data.notApplyCoefOnMat) {
						coef.subMenu[1].name = 'Activer pour Mat.';
					}

					if (params.node.data.reference) {
						return [
							...addLine,
							select,
							product,
							'separator',
							coef,
							duplicate,
							style,
							'separator',
							copy,
							paste,
							copyReference
						];
					} else {
						return [
							...addLine,
							select,
							product,
							'separator',
							coef,
							duplicate,
							style,
							'separator',
							copy,
							paste,
							copyReference
						];
					}
				} else {
					return [
						...addLine,
						select,
						product,
						'separator',
						refresh,
						'separator',
						duplicate,
						'separator',
						copy,
						paste,
						copyReference
					];
				}
			}
		};

		const getRowStyle = (params: any) => {
			if (params.node.rowPinned) {
				return {
					'font-weight': 'bold',
					'font-size': '15px'
				};
			} else {
				if (params.data.disabledPrint) {
					return {
						'background-color': 'var(--ap-blue-50)',
						color: 'var(--ap-blue-900)'
					};
				}

				return { 'background-color': '', color: '' };
			}
		};

		return {
			columnDefs,
			onCellEditingStopped,
			getRowStyle,
			getContextMenuItems,
			suppressDragLeaveHidesColumns: true,
			//stopEditingWhenCellsLoseFocus: true, //ne marche pas avec les select2
			suppressScrollOnNewData: true,
			animateRows: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressSizeToFit: true,
				resizable: true,
				suppressMovable: true,
				cellStyle: (params: any) => {
					const colId = params.column.getColId();

					params.data.style = params.data.style || {};

					if (params.data.style[colId]) {
						return params.data.style[colId];
					} else {
						return {};
					}
				},
				editable: () => {
					return !this._isLock;
				}
			},
			onGridReady: (params: any) => {
				params.api.sizeColumnsToFit();
			},
			onFirstDataRendered: (params: any) => {
				params.api.setPinnedBottomRowData([{}]);
			},
			isFullWidthCell: (params: any) => {
				return !!params.rowPinned || params.data.loading;
			},
			fullWidthCellRenderer: (params: any) => {
				const data = this._calcul!.calculateFooterDetails(params);

				const N_div = document.createElement('div');

				N_div.classList.add('h-100', 'font-bold', 'h5', 'd-flex', 'align-content-center', 'align-items-center', 'pl-2');

				N_div.innerHTML = `
					<ap-button-aggrid-select-product></ap-button-aggrid-select-product>
					<ap-button-aggrid-add-line></ap-button-aggrid-add-line>
					<ap-button-aggrid-update-group></ap-button-aggrid-update-group>

					<div class="row d-flex align-content-center border-left ml-auto" style="width: 70%">

						<div class="col text-center">
							Prix Unitaire : 
							${data.price.setSuffixAndHumanizeNumber('€', this.quoteOption!.pref.decimalNumber)}
						</div>
						<div class="col text-center">
							Tps MO : 
							${data.time.setSuffixAndHumanizeNumber('h', -1)}
						</div>
						<div class="col text-center">
							Km : 
							${data.km.setSuffixAndHumanizeNumber('km', -1)}
						</div>
						<div class="col text-center">
							Total Matériel :
							${data.sumMaterials.setSuffixAndHumanizeNumber('€', this.quoteOption!.pref.decimalNumber)}
						</div>
						<div class="col text-center">
							Marges : 
							${data.marges.setSuffixAndHumanizeNumber('€', this.quoteOption!.pref.decimalNumber)}
						</div>
					</div>
				`;

				const N_addLines = N_div.querySelector<CE_AddLine>(CE_AddLine.tagName)!;
				const N_updateGroup = N_div.querySelector<CE_UpdateGroup>(CE_UpdateGroup.tagName)!;
				const N_selectProduct = N_div.querySelector<CE_SelectProduct>(CE_SelectProduct.tagName)!;

				this._isLock ? N_addLines.setAttribute('disabled', '') : N_addLines.removeAttribute('disabled');
				this._isLock ? N_updateGroup.setAttribute('disabled', '') : N_updateGroup.removeAttribute('disabled');
				this._isLock ? N_selectProduct.setAttribute('disabled', '') : N_selectProduct.removeAttribute('disabled');

				N_addLines.generateData = (index: number) => {
					return {
						_idGrid: Utils.generateId() + '_' + index,
						quantity: 1
					};
				};

				N_addLines.gridOptions = params;
				N_updateGroup.gridOptions = params;
				N_selectProduct.gridOptions = params;

				N_selectProduct.generateGroup = (label: string) => {
					return {
						_idGrid: Utils.generateId(),
						label
					};
				};

				N_selectProduct.generateProduct = (product: any, quantity: number, inGroup: boolean) => {
					return {
						_idGrid: Utils.generateId(),
						reference: product._id,
						label: product.label,
						price: product.price,
						unit: product.unit.id,
						brand: product.brand.id,
						tva: product.tvaRate.id,
						time: '0',
						quantity,
						comptaVente: product.comptaVente.id,
						costPrice: product.defaultProvider?.costPrice || 0,
						disabledPrint: inGroup
					};
				};

				N_addLines.addEventListener('update', () => {
					updateGrid && updateGrid();
				});

				N_updateGroup.addEventListener('update', () => {
					updateGrid && updateGrid();
				});

				N_selectProduct.addEventListener('update', () => {
					updateGrid && updateGrid();
				});

				return N_div;
			},
			pinnedBottomRowData: [{}]
		};
	}

	public styleContextMenu(params: { [key: string]: any }, update?: any) {
		const style = {
			name: 'Style',
			icon: '<ap-icon name="brush-2/line"></ap-icon>',
			subMenu: [{
				name: 'Gras',
				icon: '<ap-icon name="bold"></ap-icon>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					if (params.node.data.style[colId]['font-weight']) {
						params.node.data.style[colId]['font-weight'] = '';
					} else {
						params.node.data.style[colId]['font-weight'] = 'bold';
					}

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}, {
				name: 'Italique',
				icon: '<ap-icon name="italic"></ap-icon>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					if (params.node.data.style[colId]['font-style']) {
						params.node.data.style[colId]['font-style'] = '';
					} else {
						params.node.data.style[colId]['font-style'] = 'italic';
					}

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}, {
				name: 'Barrer',
				icon: '<ap-icon name="strikethrough"></ap-icon>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					let value = params.node.data.style[colId]['text-decoration'] || '';

					if (value.indexOf('line-through') !== -1) {
						value = value.replace('line-through', '');
					} else {
						value += ' line-through';
					}

					params.node.data.style[colId]['text-decoration'] = value.trim();

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}, {
				name: 'Souligner',
				icon: '<ap-icon name="underline"></ap-icon>',
				action: () => {
					const colId = params.column.getColId();

					params.node.data.style = params.node.data.style || {};
					params.node.data.style[colId] = params.node.data.style[colId] || {};

					let value = params.node.data.style[colId]['text-decoration'] || '';

					if (value.indexOf('underline') !== -1) {
						value = value.replace('underline', '');
					} else {
						value += ' underline';
					}

					params.node.data.style[colId]['text-decoration'] = value.trim();

					params.node.setData(params.node.data);

					params.api?.refreshCells({ columns: [colId], force: true });

					update && update();
				}
			}]
		};

		return style;
	}
}

export default Options;
