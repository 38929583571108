// CORE
import { DOM, Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../../../tpl/modals/AddQuote/editPage/details.html';

// LIBS
import moment, { Moment } from 'moment';
import Modal from '@libs/Modal';

// PRINTER
// UTILS

// MODAL
// CUSTOM_ELEMENT

// SERVICE
import S_Quote from '@services/QuoteService';

type quoteDetailsData = {
	infos: {
		number: string,
		numberCommand: string,
		state: number,
		date: Moment,
		sendDate: Moment
	},
	order: string,
	states: { [key: string]: string }
};

class quoteDetails extends Modal {
	private previousCallback: null | (() => void) = null;
	private nextCallback: null | ((data: any) => void) = null;

	private form: Form | null;
	private number: string;

	constructor(data: quoteDetailsData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;
		this.number = data.infos.number;

		this.on('opened', async () => {
			this.init(data);

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);

			this.postInit();

			const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			if (this.nextCallback) {
				N_save.innerHTML = 'Suivant';
			}

			if (this.previousCallback) {
				N_previous && N_previous.classList.remove('d-none');
			}

			N_previous.addEventListener('click', () => {
				if (this.previousCallback) {
					this.previousCallback();
				}

				DOM.nextTick(() => {
					this.reject(false);
				});
			});

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					const newData = {
						infos: {
							date: data.infos.date,
							sendDate: data.infos.sendDate,
							number: S_Quote.getInstance().updateNumberByDate(this.number, moment(data.infos.date))
						}
					};

					if (this.nextCallback) {
						this.nextCallback(newData);
					}

					this.resolve(newData);
				}));
			});

			this.updateButtonSave();
		});
	}

	public setPreviousCallback(cb: () => void) {
		this.previousCallback = cb;
		return this;
	}

	public setNextCallback(cb: (data: any) => void) {
		this.nextCallback = cb;
		return this;
	}

	private init(data: quoteDetailsData) {
		const N_date = this.element.querySelector('#date') as HTMLInputElement;

		if (['1', '2', '7'].includes(data.infos.state.toString())) {
			N_date.disabled = true;
		}
	}

	private async setData(data: quoteDetailsData) {
		const res: { [key: string]: any } = {
			infos: {
				numberCommand: data.order || 'Aucune commande',
				state: data.states[data.infos.state],
				date: data.infos.date,
				sendDate: data.infos.sendDate
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		this.element.querySelector('[name="infos.date"]')?.addEventListener('change', () => {
			this.updateButtonSave();
		});
	}

	private updateButtonSave() {
		const date = this.form?.getDataByName('infos.date') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !date;
	}
}

export default quoteDetails;
