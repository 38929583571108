import T_template from '../../../tpl/blocks/template_materials.ejs';

import _ from 'lodash';

import Utils from '@libs/utils/Utils';

//services
import S_Customer from '@services/Customer/CustomerService';
import S_Product from '@services/Product/ProductService';
import S_Quote from '@services/QuoteService';
import S_Site from '@js/services/Site/SiteService';
import S_Stock from '@services/StockService';

class MaterialsByCategory {
	constructor(id: string, content: { [key: string]: any }[] = [], displayStock: boolean) {
		content = _.cloneDeep(content);
		this.init(id, content, displayStock);
	}

	private async init(id: string, content: { [key: string]: any }[] = [], displayStock: boolean) {
		const productData: { [productID: string]: any } = {};
		const stockData: { [productID: string]: any } = {};

		for (const item of content) {
			item.product.brand = item.product.brand || 'MARQUE NON RENSEIGNEE';
			productData[item.product._id] = item.product;
			stockData[item.product._id] = item.currentStock;
		}

		const data = await S_Quote.getInstance().getById(id);

		const stockLocation = await this.getStockLocation();

		if (data.infos.customer) {
			data.infos.customer = await S_Customer.getInstance().getById(data.infos.customer);
		}

		if (data.infos.sites) {
			for (let index = 0; index < data.infos.sites.length; index++) {
				try {
					data.infos.sites[index] = (await S_Site.getInstance().getById(data.infos.sites[index])).name;
				} catch (e) {
				}
			}
		}

		const materials = [] as any[];

		for (const line of data.data) {
			for (const details of line.details) {
				if (details.reference && productData[details.reference]) {
					materials.push({
						category: line.category + (line.subCategory ? ' / ' + line.subCategory : ''),
						reference: details.reference,
						brand: details.brand,
						unit: details.unit,
						quantity: details.quantity * line.quantity
					});
				}
			}
		}

		const groupMaterials = _.groupBy(materials, 'category');

		let materialsData: any[] = [];
		let index = 0;

		for (const key in groupMaterials) {
			materialsData.push({
				type: 'brand',
				label: groupMaterials[key][0].category
			});

			const data = _.groupBy(groupMaterials[key], (item) => {
				return item.reference;
			});

			let tmp: { [key: string]: any }[] = [];

			for (const key in data) {
				const group = data[key] || [];

				const productID = group[0].reference;

				tmp.push({
					_id: group[0].reference,
					product: productData[productID],
					quantity: _.sumBy(group, (item) => {
						return Number(item.quantity);
					}),
					currentStock: stockData[productID]
				});
			}

			tmp = _.sortBy(tmp, ['product.brand', 'product.reference']) as any[];

			let brand = '';
			for (let i = 0; i < tmp.length; i++) {
				if (tmp[i].product) {
					tmp[i].product = this.convertProductToPrint(tmp[i].product);
				}

				if (brand !== tmp[i].product.brand) {
					tmp.splice(i, 0, {
						type: 'brand',
						label: '&nbsp;&nbsp;&nbsp;&nbsp;&rArr;&nbsp;' + tmp[i].product.brand
					});

					i++;
					index++;

					brand = tmp[i].product.brand;
				}

				if (index % 53 === 0) {
					tmp.splice(i, 0, {
						type: 'brand',
						label: '&nbsp;&nbsp;&nbsp;&nbsp;&rArr;&nbsp;' + brand
					});

					i++;
					index++;
				}

				index++;
			}

			materialsData = materialsData.concat(tmp);
		}

		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		const chunkMaterials = _.chunk(materialsData, 54);

		for (const chunk of chunkMaterials) {
			N_container.innerHTML += T_template({
				column: {
					reference: S_Product.getInstance().columnNameReference
				},
				infos: data.infos,
				stockLocation,
				displayStock,
				data: chunk
			});
		}

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);

			Utils.removeTooltip();
		}, 1000);
	}

	private convertProductToPrint(product: { [key: string]: any }) {
		return {
			reference: product[S_Product.getInstance().referenceKey],
			label: product.label,
			unit: product.unit,
			brand: product.brand
		};
	}

	private async getStockLocation() {
		const location = await S_Stock.getInstance().getStockToCommandCustomer();

		return location;
	}
}

export default MaterialsByCategory;
