import GenericService from './GenericService';

class ContactsService extends GenericService {
	private static instance: ContactsService | null = null;

	constructor() {
		super('contacts');
	}

	public static getInstance(): ContactsService {
		if (!ContactsService.instance) {
			ContactsService.instance = new ContactsService();
		}

		return ContactsService.instance;
	}
}

export default ContactsService;
