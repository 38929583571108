// SERVICES
import S_Companies from '@services/CompaniesService';

// STYLE
import '../../css/settings.companies.scss';

// MODALES
import M_AddEditCompanies from '../modals/AddEditCompanies';

// LIBS
import FormControllerPageID from '@libs/FormControllerPageID';
import SettingsController from '../libs/SettingsController';

// CUSTOM ELEMENTS
import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';

// TYPES
import { Company } from '../types/company';

import CompanyAddressModel from '../model/CompanyAddress';
import CompanyBankModel from '../model/CompanyBank';

class SettingsTabs extends SettingsController {
	private selectedCompany: Company;
	private form: FormControllerPageID;

	constructor(el: HTMLElement, data: Company) {
		super(el);

		const N_form = this.el.querySelector<HTMLElement>('.page-settings-companies')!;
		this.form = new FormControllerPageID(N_form);

		this.selectedCompany = data;
	}

	protected async init() {
		this.initGridAddressList();
		this.initGridBankList();
		this.initCompanyContent();
		this.initButtons();

		if (!this.selectedCompany._id) {
			this.updateButtonSave();
		}
	}

	private initButtons() {
		const N_save = this.el.querySelector<CE_Button>('#save')!;

		N_save.addEventListener('click', async () => {
			const result = await S_Companies.getInstance().save(this.selectedCompany);

			if (result.ok) {
				this.selectedCompany._id = result.data.data._id;
				this.resetButtonSave();
				this.resolve();
			}
		});

		const N_edit = this.el.querySelector<CE_Button>('#edit')!;
		N_edit.addEventListener('click', () => {
			new M_AddEditCompanies(this.selectedCompany).open().then((data) => {
				this.selectedCompany = data;
				this.updateButtonSave();
				this.initCompanyContent();
			});
		});

		const N_delete = this.el.querySelector<CE_Button>('#delete')!;
		N_delete.addEventListener('click', async () => {
			await S_Companies.getInstance().delete(this.selectedCompany._id);
			this.resolve();
		});
	}

	private updateButtonSave() {
		const N_save = this.el.querySelector<CE_Button>('#save')!;
		N_save.disabled = false;
	}

	private resetButtonSave() {
		const N_save = this.el.querySelector<CE_Button>('#save')!;
		N_save.disabled = true;
	}

	private initCompanyContent() {
		this.form.setData(this.selectedCompany);

		const N_CompanyLogo = this.el.querySelector('#company-logo') as HTMLDivElement;

		if (!this.selectedCompany.logo) {
			N_CompanyLogo.innerHTML = '(non défini)';
		} else {
			N_CompanyLogo.innerHTML = `<img src="${this.selectedCompany.logo}">`;
		}

		this.initSelectedCompanyAddressList();
	}

	private initSelectedCompanyAddressList() {
		const N_grid_address = this.el.querySelector<CE_AgGrid>('#company-address-list')!;
		const N_grid_bank = this.el.querySelector<CE_AgGrid>('#company-bank-list')!;

		const addressListRowData = this.selectedCompany?.addressList || [];
		const bankListRowData = this.selectedCompany?.bankList || [];

		const addressList = [];

		for (const address of addressListRowData) {
			addressList.push(new CompanyAddressModel(address).toDashboard());
		}

		const bankList = [];

		for (const bank of bankListRowData) {
			bankList.push(new CompanyBankModel(bank).toDashboard());
		}

		N_grid_address.value = addressList;
		N_grid_bank.value = bankList;
	}

	private initGridAddressList() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#company-address-list')!;

		N_grid.setGridOptions({
			suppressContextMenu: true,
			defaultColDef: {
				resizable: true
			},
			columnDefs: [{
				headerName: 'Nom',
				field: 'name'
			}, {
				headerName: 'Adresse',
				field: 'address'
			}, {
				headerName: 'Code Postal',
				field: 'zipCode'
			}, {
				headerName: 'Ville',
				field: 'city'
			}, {
				headerName: 'Pays',
				field: 'country'
			}, {
				headerName: 'Téléphone',
				field: 'phone'
			}, {
				headerName: 'Fax',
				field: 'fax'
			}, {
				headerName: 'Email',
				field: 'email'
			}, {
				headerName: 'Par Défaut',
				field: 'default',
				cellRenderer: (params) => {
					const N_BooleanIcon = document.createElement('div');

					if (params.value) {
						N_BooleanIcon.innerHTML = '<ap-icon name="check" style="color: green"></ap-icon>';
					}

					return N_BooleanIcon;
				}
			}]
		});
	}

	private initGridBankList() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#company-bank-list')!;

		N_grid.setGridOptions({
			columnDefs: [{
				headerName: 'Nom',
				field: 'name'
			}, {
				headerName: 'IBAN',
				field: 'IBAN'
			}, {
				headerName: 'RIB',
				field: 'RIB'
			}, {
				headerName: 'Code SWIFT',
				field: 'SWIFT'
			}, {
				headerName: 'Commande Fournisseur',
				field: 'useCommandProvider'
			}, {
				headerName: 'Facture',
				field: 'useBill'
			}]
		});
	}

	public destructor() {
		this.abortController.abort('destroyed');
	}
}

export default SettingsTabs;
