import { toaster } from '@autoprog/core-client';

import _ from 'lodash';

import T_Modal from '../../../tpl/modals/AddQuote/EditGroup.html';

import M_AddFavorite from './AddFavorite';

import Clipboard from '../../libs/Clipboard';
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';

import Calcul from '../../libs/Calcul';
import GridOptions from '../../libs/GridOptions';
import QuoteOptions from '../../libs/QuoteOptions';

import StateSaver from '@libs/agGrid/StateSaver';

import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import CE_AgGrid from '@libs/customElement/AgGrid';

import '../../../css/modals/editGroup.scss';

class EditGroup extends Modal {
	private N_gridMaster: CE_AgGrid | null = null;
	private N_gridDetails: CE_AgGrid | null = null;

	private optionsGrid: GridOptions;
	private quoteOptions: QuoteOptions;
	private calcul: Calcul;

	private allData: any[] = [];

	private _idGrid: string = '';

	private idFav: string = '';

	constructor(allData: any[], idGrid: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.allData = _.cloneDeep(allData);
		this._idGrid = idGrid;
		this.optionsGrid = new GridOptions();
		this.quoteOptions = QuoteOptions.getInstance();
		this.calcul = new Calcul();

		this.optionsGrid.init();

		const clipboard = Clipboard.getInstance();

		const data = _.find(this.allData, { _idGrid: idGrid } as any) as any;

		this.on('opened', () => {
			this.N_gridMaster = this.element.querySelector<CE_AgGrid>('#grid')!;
			this.N_gridDetails = this.element.querySelector<CE_AgGrid>('#grid-group')!;

			const N_notFinish = this.element.querySelector<HTMLInputElement>('[name="notFinish"]')!;
			const N_options = this.element.querySelector<HTMLInputElement>('[name="options"]')!;

			const N_favorite = this.element.querySelector<HTMLElement>('#favorite')!;
			const N_infos_quote = this.element.querySelector<HTMLElement>('#infos_quote')!;

			//FIXME:
			N_infos_quote.innerHTML = (document.querySelector('#page-title .title') as HTMLElement).textContent || '';

			const N_close = this.element.querySelector<HTMLButtonElement>('.close')!;
			const N_copy = this.element.querySelector<HTMLButtonElement>('#copy')!;

			N_close.addEventListener('click', () => {
				this.resolve({ _idFav: this.idFav });
			});

			N_copy.addEventListener('click', () => {
				clipboard.setData(_.cloneDeep(this.getData()), 'master');
			});

			N_notFinish.addEventListener('change', () => {
				this.N_gridMaster?.forEachNode((node) => {
					node.data.notFinish = !node.data.notFinish;

					node.setData(node.data);
				});
			});

			N_options.addEventListener('change', () => {
				this.N_gridMaster?.forEachNode((node) => {
					node.data.isOption = !node.data.isOption;

					node.setData(node.data);
				});
			});

			if (data._idFav) {
				this.idFav = data._idFav;
				N_favorite.innerHTML = '<i class="icon icon-solid-star"></i>';
				N_favorite.setAttribute('confirmation', '');
				N_favorite.title = 'Modifier le favori ?';
			} else {
				N_favorite.innerHTML = '<i class="icon icon-star"></i>';
			}

			N_favorite.addEventListener('click', async () => {
				const newData = _.cloneDeep(this.getData());

				if (!newData._idFav) {
					new M_AddFavorite(newData).open().then((id) => {
						this.idFav = id;

						N_favorite.innerHTML = '<i class="text-dark h5 icon icon-solid-star"></i>';
						N_favorite.setAttribute('confirmation', '');
						N_favorite.title = 'Modifier le favori ?';
					}).catch((e) => {
						console.error(e);
					});
				} else {
					newData._id = newData._idFav;

					const data = await S_Q_Favorite.getInstance().save(newData);

					if (!data.err) {
						toaster.success('Sauvegarde réussi');
					}
				}
			});

			N_notFinish.checked = data.notFinish;
			N_options.checked = data.isOption;

			this.N_gridDetails!.setGridOptions({
				...this.optionsGrid.gridDetails('modal', () => {
					const data: any[] = this.N_gridDetails?.value || [];

					//peut y avoir qu'une ligne
					this.N_gridMaster?.forEachNode((node) => {
						node.data.details = data;
					});
					this.N_gridMaster?.refreshCells();

					this.updateNewPrice();
				}),
				onRowDataUpdated: () => {
					this.updateNewPrice();
				},
				onGridReady: async (params) => {
					const settings = await StateSaver.getValue('edit-group-details-quote');
					const stateSaver = new StateSaver(params, 'edit-group-details-quote');
					stateSaver.setData(settings);
				}
			});

			this.N_gridMaster!.setGridOptions({
				singleClickEdit: true,
				...this.optionsGrid.gridMaster('modal'),
				getRowHeight: () => {
					return 130;
				},
				onGridReady: async (params) => {
					const settings = await StateSaver.getValue('edit-group-master-quote');
					const stateSaver = new StateSaver(params, 'edit-group-master-quote');
					stateSaver.setData(settings);
				},
				onCellEditingStopped: (params) => {
					params.api.resetRowHeights();
					params.api.refreshCells({ force: true });
					this.updateNewPrice();
				},
				getContextMenuItems: (params) => {
					return [this.optionsGrid.styleContextMenu(params)];
				}
			});

			this.N_gridDetails!.value = data.details;
			this.N_gridMaster!.value = [data];

			const N_oldPrice = this.element.querySelector('#oldPrice') as HTMLElement;

			const oldPrice = this.calcul.calculateGlobalPrice(this.allData).all;

			N_oldPrice.innerHTML = this.calcul.humanizeNumber(oldPrice);

			this.save();

			this.updateNewPrice();
		});
	}

	private updateNewPrice() {
		const index = _.findIndex(this.allData, { _idGrid: this._idGrid } as any);

		const newData = this.getData();
		newData._idGrid = this._idGrid;

		this.allData[index] = newData;

		const price = this.calcul.calculateGlobalPrice(this.allData).all;

		const N_newPrice = this.element.querySelector('#newPrice') as HTMLElement;
		N_newPrice.innerHTML = Utils.setSuffixAndHumanizeNumber(price, '€', this.quoteOptions.pref.decimalNumber);
	}

	private getData() {
		const result: { [key: string]: any } = this.N_gridMaster?.value?.[0] || {};

		result.details = this.N_gridDetails?.value || [];

		if (this.idFav) {
			result._idFav = this.idFav;
		}

		return result;
	}

	private save() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			this.resolve(this.getData());
		});
	}
}

export default EditGroup;
