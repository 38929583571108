import { Navigation } from '@autoprog/core-client';

import Permissions from '@libs/Permissions';

export type LinkItem = {
	category?: string
	order: number
	icon: string
	name: string
	type: string
	permission?: string
	permissions?: string[]
	module?: string
	database?: string
	id?: string
	tabs?: any[]
};

type CategoryItem = {
	name: string
	order: number
	permissions?: string
};

class NavigationApps {
	private static instance: NavigationApps;

	private navigation: Navigation;

	private categoriesMap: Map<string, CategoryItem>;
	private linkMap: Map<string, LinkItem[]>;

	private static CATEGORY_NULL = '_null';

	constructor() {
		this.navigation = Navigation.getInstance();

		this.categoriesMap = new Map();
		this.linkMap = new Map();

		this.addCategory({
			name: NavigationApps.CATEGORY_NULL,
			order: -10000
		});
	}

	public static getInstance() {
		if (!NavigationApps.instance) {
			NavigationApps.instance = new NavigationApps();
		}

		return NavigationApps.instance;
	}

	public addCategory(item: CategoryItem) {
		item.name = item.name || NavigationApps.CATEGORY_NULL;

		const category = this.categoriesMap.get(item.name);

		if (!category) {
			this.categoriesMap.set(item.name, item);
		}
	}

	public addLink(item: LinkItem) {
		item.category = item.category || NavigationApps.CATEGORY_NULL;

		const category = this.categoriesMap.get(item.category);

		if (!category) {
			console.error('Categorie non enregistré');
		}

		let link = this.linkMap.get(item.category);

		if (!link) {
			link = [];
		}

		link.push(item);

		this.linkMap.set(item.category, link);
	}

	private initPermissions() {
		for (const [key, value] of this.categoriesMap.entries()) {
			const pages = this.linkMap.get(key);

			if (pages) {
				const permissions: string[] = [];

				for (const page of pages) {
					if (page.type === 'database') {
						const keyPermission = Permissions[page.database!];
						permissions.push(`${keyPermission}.SHOW`);
					}

					if (page.type === 'tabs') {
						for (const item of page.tabs!) {
							if (item.type === 'database') {
								const keyPermission = Permissions[item.database!];
								permissions.push(`${keyPermission}.SHOW`);
							}
						}
					}

					if (page.type === 'settings') {
						permissions.push('MAIN_SETTINGS.SHOW');
					}

					if (page.type === 'module') {
						const permissions: string[] = [];

						page.permissions = page.permissions || [];

						for (const item of page.permissions) {
							permissions.push(`${item}.SHOW`);
						}
					}
				}

				if (value.name !== NavigationApps.CATEGORY_NULL) {
					value.permissions = permissions.join(' || ');
					this.categoriesMap.set(key, value);
				}
			}
		}
	}

	public render() {
		this.initPermissions();

		const mapCategories = new Map([...this.categoriesMap.entries()].sort((a, b) => a[1].order - b[1].order));

		let index = 0;

		for (const [key, value] of mapCategories.entries()) {
			if (this.linkMap.get(key)) {
				if (value.name !== NavigationApps.CATEGORY_NULL) {
					this.navigation.addSeparator(value.name || '', value.permissions || '');
				}

				let pages = Array.from(this.linkMap.get(key)!.values());

				pages = pages.sort((a, b) => a.order - b.order);

				for (const page of pages) {
					const id = 'ID' + Date.now();

					if (page.type === 'database') {
						const keyPermission = Permissions[page.database!];
						this.navigation.addLink(id, page.name, page.icon || 'bug/line', `database/${page.database}`, `${keyPermission}.SHOW`);
					}

					if (page.type === 'tabs') {
						const permissions: string[] = [];

						for (const item of page.tabs!) {
							if (item.type === 'database') {
								const keyPermission = Permissions[item.database!];
								permissions.push(`${keyPermission}.SHOW`);
							}
						}

						this.navigation.addLink(id, page.name, page.icon || 'bug/line', `tabs/${page.id || index}`, permissions.join(' || '));
					}

					if (page.type === 'settings') {
						this.navigation.addLink(id, page.name, page.icon || 'bug/line', 'settings/MAIN', 'MAIN_SETTINGS.SHOW');
					}

					if (page.type === 'module') {
						const permissions: string[] = [];

						page.permissions = page.permissions || [];

						for (const item of page.permissions) {
							permissions.push(`${item}.SHOW`);
						}

						this.navigation.addLink(id, page.name, page.icon || 'bug/line', `module/${page.module}`, permissions.join(' || '));
					}

					index++;
				}
			}
		}
	}
}

export default NavigationApps;
