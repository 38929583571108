// CORE
// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../../tpl/modals/users/editPage/generalInformations.html';

// LIBS
import Modal from '@libs/EditPageModal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

type GeneralInformationsData = {
	lastname: string,
	firstname: string,
	birthday: Moment,
	email: string,
	phone: string,
	job: string
};

class GeneralInformations extends Modal<GeneralInformationsData> {
	constructor(data: GeneralInformationsData) {
		super(data, T_modal);
	}
}

export default GeneralInformations;
