// CORE

// NODE_MODULE

// TEMPLATE
import T_modal from '../../../../tpl/modals/users/editPage/identifiers.html';

// LIBS
import Modal from '@libs/EditPageModal';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import GroupsService from '@services/User/GroupService';

type IdentifiersData = {
	login: string,
	password: string
	groups: string[]
};

class Identifiers extends Modal<IdentifiersData> {
	constructor(data: IdentifiersData) {
		super(data, T_modal);
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.groups = await this.convertGroup(data.groups);
		return data;
	}

	protected preInit(): void {
		const N_groups = this.element.querySelector('[name="groups"]') as CE_Select2;

		N_groups.create(this.element, { multiple: true });

		this.selectPostinit.groups = N_groups;
	}

	protected async setData(data: IdentifiersData) {
		const res: { [key: string]: any } = {
			login: data.login,
			password: data.password,
			groups: data.groups ? await this.convertGroup(data.groups) : null
		};

		this.form?.setData(res);
	}

	private async convertGroup(groups: string[]) {
		const res: any[] = [];

		for (const item of groups) {
			res.push(await GroupsService.getInstance().getDataToSelect2ByID(item));
		}

		return res;
	}
}

export default Identifiers;
