import { Modal, Router, global } from '@autoprog/core-client';

//@ts-ignore
import { Converter } from 'showdown';

import Utils from '@libs/utils/Utils';

import semver from 'semver';

import T_modal from '../../tpl/modals/changelog.html';

import UtilsVersion from '../utils/ConvertVersion';

class Changelog extends Modal {
	constructor(lastVersion = '0.0.0-0') {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			lastVersion = UtilsVersion.convert(lastVersion);

			const N_container = this.element.querySelector('#changelog-container') as HTMLElement;

			const idUser = Utils.userID;

			const converter = new Converter();

			const changelog = converter.makeHtml(global.CHANGELOG).replace(/\((MR-[0-9]{1,})?( ){0,}(#[0-9]{1,})?\)/gmi, (value: string) => {
				return `<span class="${idUser !== '1' ? 'd-none' : ''}">${value}</span>`;
			});

			N_container.innerHTML = changelog;

			const N_changelog = this.element.querySelector('#changelog') as HTMLElement;
			N_changelog.remove();

			let lastVersionFind = '';

			for (let i = 0; i < N_container.children.length; i++) {
				const N_el = N_container.children[i] as HTMLElement;

				if (N_el.tagName === 'H2') {
					lastVersionFind = N_el.innerHTML;
					lastVersionFind = lastVersionFind.split(/([0-9]{1,}.[0-9]{1,}.[0-9]{1,}(-[0-9]{0,1})?)/gmi)[1];
				}

				lastVersionFind = UtilsVersion.convert(lastVersionFind);

				if (semver.lte(lastVersionFind, lastVersion)) {
					N_el.style.display = 'none';
				} else {
					const N_list = N_el.querySelectorAll(':scope > li') as NodeListOf<HTMLElement>;

					//On masque les sections avec comme titre incluant ['CODE']
					N_list.forEach((N_el) => {
						if (N_el.innerText.includes('[CODE]') && idUser !== '1') {
							N_el.classList.add('d-none');
						}
					});
				}
			}

			const N_viewAll = this.element.querySelector('#viewAll') as HTMLButtonElement;

			N_viewAll.addEventListener('click', () => {
				Router.getInstance().navigate('/#module/changelog/home');

				this.resolve();
			});
		});
	}
}

export default Changelog;
