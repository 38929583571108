import GenericService from '../GenericService';

class CustomerService extends GenericService {
	private static instance: CustomerService | null = null;

	constructor() {
		super('customers');
	}

	public static getInstance(): CustomerService {
		if (!CustomerService.instance) {
			CustomerService.instance = new CustomerService();
		}

		return CustomerService.instance;
	}

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return 'number';
	}
}

export default CustomerService;
