import T_modal from '../../../tpl/modals/editPage/generalInformation.html';

import Modal from '@libs/EditPageModal';

import CE_Select2 from '@libs/customElement/Select2';

import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Customer from '@services/Customer/CustomerService';
import S_User from '@services/User/UserService';

type GeneralInformationData = {
	infosCustomer: {
		orderNumber: string
		addonNumber: string
		customer: string
		contact: string
	}
	manager: string
};

class GeneralInformation extends Modal<GeneralInformationData> {
	constructor(data: GeneralInformationData) {
		super(data, T_modal);
	}

	protected preInit() {
		const N_customer = this.element.querySelector('[name="infosCustomer.customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infosCustomer.contact"]') as CE_Select2;

		const N_manager = this.element.querySelector('[name="manager"]') as CE_Select2;

		N_manager.create(this.element);

		N_customer.create(this.element, { disabled: true });

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infosCustomer.customer'] = N_customer;
		this.selectPostinit['infosCustomer.contact'] = N_contact;
		this.selectPostinit.manager = N_manager;
	}

	protected async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infosCustomer: {
				orderNumber: data.infosCustomer.orderNumber,
				addonNumber: data.infosCustomer.addonNumber,
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer),
				contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infosCustomer.contact)
			},
			manager: await S_User.getInstance().getDataToSelect2ByID(data.manager)
		};

		this.form?.setData(res);
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.manager = await S_User.getInstance().getDataToSelect2ByID(data.manager);

		data.infosCustomer.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer);
		data.infosCustomer.contact = await S_C_Contact.getInstance().getDataToSelect2ByID(data.infosCustomer.contact);

		return data;
	}

	protected postInit() {
		super.postInit();

		const N_orderNumber = this.element.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;

		N_orderNumber.addEventListener('input', () => {
			this.updateButtonSave();
		});

		this.selectPostinit['infosCustomer.customer'].on('change', async (customer) => {
			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				this.form?.setDataByName('infosCustomer.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infosCustomer.contact', { id: '', text: '' });
			}

			this.updateButtonSave();
		});

		this.selectPostinit.manager.on('change', async () => {
			this.updateButtonSave();
		});

		this.updateButtonSave();
	}

	private updateButtonSave() {
		const customer = this.form?.getDataByName('infosCustomer.customer') as string;
		const orderNumber = this.form?.getDataByName('infosCustomer.orderNumber') as string;
		const manager = this.form?.getDataByName('manager') as string;

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !customer || !orderNumber || !manager;
	}
}

export default GeneralInformation;
