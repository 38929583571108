import C_Database from '@js/controllers/Database';

import Notifications from '../libs/Notifications';

import S_Notifications from '@services/NotificationService';

class NotificationsController extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Notification',
			database: 'notifications',
			permission: 'APPS/NOTIFICATION'
		});
	}

	protected valueGetter(params: any) {
		if (params.colDef.field === 'icon') {
			return `<i class="icon ${Notifications.getIconByType(params.data.type)}"></i>`;
		} else {
			return super.valueGetter(params);
		}
	}

	protected addOnButtonGrid(params: any) {
		const N_button = document.createElement('button');

		N_button.classList.add('h-100', 'py-0', 'btn-transparent');
		N_button.innerHTML = '<i class="text-info h5 icon icon-eye"></i>';

		const N_button_reactivate = document.createElement('button');
		N_button_reactivate.classList.add('h-100', 'py-0', 'btn-transparent');

		if (params.data.state === '0') {
			N_button_reactivate.disabled = true;
			N_button_reactivate.style.opacity = '0.5';
		}

		N_button_reactivate.setAttribute('tooltip', 'Réactiver la notification');
		N_button_reactivate.innerHTML = '<i class="h5 icon icon-solid-bell"></i>';

		N_button_reactivate.setAttribute('confirmation', '');

		N_button_reactivate.addEventListener('click', () => {
			S_Notifications.getInstance().save({
				_id: params.data._id,
				state: '0'
			}).then(() => {
				this.refreshData();
			});
		});

		if (Notifications.hasAction(params.data)) {
			N_button.addEventListener('click', () => {
				const N_div = document.createElement('div');
				Notifications.getAction(params.data, N_div);
			});
		} else {
			N_button.classList.add('d-none');
		}

		return [N_button, N_button_reactivate];
	}

	public destructor() {

	}
}

export default NotificationsController;
