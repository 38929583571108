module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div class="d-flex">\n            <div class="font-weight-bold text-blue-grey-900">\n                ' +
((__t = (data.infos.number || '&nbsp;')) == null ? '' : __t) +
'\n            </div>\n            <div class="ml-auto">\n                ';
 let state= { "0": "En cours de préparation", "1": "Prêt à expédier", "2": "Expédié" };
__p += '\n                ' +
((__t = (state[data.state])) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="d-flex mt-2">\n            <div>\n                Le ' +
((__t = ((data.infos.deliveryDate || '').split('-').reverse().join('/'))) == null ? '' : __t) +
'\n            </div>\n            <div class="ml-auto">\n                ' +
((__t = (data.infos.delivered || '&nbsp;')) == null ? '' : __t) +
'\n            </div>\n        </div>\n        <div class="mt-2">\n            ';
if(data.infos.isCustomer){;
__p += '\n                ' +
((__t = (data.infos.customer || '&nbsp;')) == null ? '' : __t) +
'\n            ';
};
__p += '\n\n            ';
if(data.infos.isProvider){;
__p += '\n                ' +
((__t = (data.infos.provider || '&nbsp;')) == null ? '' : __t) +
'\n            ';
};
__p += '\n\n            ';
if(data.infos.isOther){;
__p += '\n                ' +
((__t = (data.infos.other || '&nbsp;')) == null ? '' : __t) +
'\n            ';
};
__p += '\n\n            &nbsp;\n        </div>\n    </div>\n\n</div>';

}
return __p
}