class PageTabsMenuContent extends HTMLElement {
	public static readonly tagName: string = 'ap-page-tabs-menu-content';

	public connectedCallback() {
	}

	public setActive(href: string) {
		const N_currentActive = this.querySelector('.page-tabs-container.active') as HTMLElement;
		N_currentActive?.classList.remove('active');

		const N_el = this.querySelector(href) as HTMLElement;
		N_el?.classList.add('active');
	}

	public static register() {
		customElements.define(PageTabsMenuContent.tagName, PageTabsMenuContent);
	}
}

export default PageTabsMenuContent;
