import { Controller } from '@autoprog/core-client';
import History from '@libs/History';

import '@css/controllers/maintenance.scss';

class MaintenanceController extends Controller {
	constructor(el: HTMLElement, data: any) {
		super(el, data);

		const N_title = el.querySelector<HTMLElement>('.title');
		const N_text = el.querySelector<HTMLElement>('.text');
		const N_back = el.querySelector<HTMLButtonElement>('.link-button');

		N_title && (N_title.innerHTML = data.title);
		N_text && (N_text.innerHTML = data.text);

		const historyApps = History.getInstance();

		N_back!.addEventListener('click', () => {
			historyApps.back();
		});
	}

	public destructor() {

	}
}

export default MaintenanceController;
