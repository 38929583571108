import GenericService from './GenericService';

class TransporteurService extends GenericService {
	private static instance: TransporteurService | null = null;

	constructor() {
		super('transporters');
	}

	public static getInstance(): TransporteurService {
		if (!TransporteurService.instance) {
			TransporteurService.instance = new TransporteurService();
		}

		return TransporteurService.instance;
	}

	/**
	* Retourne la clé a exporter pour les données avec une référence
	* @returns le nom de la clé
	*/
	public getExportKey(): string {
		return 'name';
	}
}

export default TransporteurService;
