import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/EditTrackingProduct.html';

import S_Product from '@services/Product/ProductService';
import S_Trackingproduct from '@services/TrackingProductService';

import CE_Select2 from '@libs/customElement/Select2';

class EditTrackingProduct extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private item: { [key: string]: any } = {};
	private allData: { [key: string]: any }[] = [];
	private mode: string = '';

	constructor(item: { [key: string]: any }, allData: { [key: string]: any }[], mode: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.item = item;
		this.allData = allData;
		this.mode = mode;

		this.on('opened', async () => {
			this.init();

			const form = new Form(this.element.querySelector('form') as HTMLFormElement);

			form.setData({
				product: await S_Product.getInstance().getDataToSelect2ByID(item.product._id),
				trackingProducts: await S_Trackingproduct.getInstance().getDataToSelect2ByID(item.id),
				comment: item.comment
			});

			this.postInit();

			const N_save = this.element.querySelector('#save');

			N_save?.addEventListener('click', () => {
				this.resolve(item);
			});
		});
	}

	private init() {
		const N_product = this.element.querySelector('[name="product"]') as CE_Select2;
		const N_trackingProducts = this.element.querySelector('[name="trackingProducts"]') as CE_Select2;

		N_product.create(this.element, { disabled: true });

		const select2Tp = N_trackingProducts.create(this.element);

		//On set une référence sur le produit correspondant
		select2Tp.setRefValue('product', this.item.product.id);

		const idUsed: { id: string, trackingNumber: string }[] = [];

		for (const item of this.allData) {
			idUsed.push({
				id: item.id,
				trackingNumber: item.trackingNumber
			});
		}

		//On renseigne les ids déjà utilisés
		select2Tp.setRefValue('idUsed', idUsed);

		//Si on est dans un bon de réception
		if (this.mode === 'reception') {
			select2Tp.setRefValue('reception', true);
		}

		this.selectPostinit.product = N_product;
		this.selectPostinit.trackingProduct = N_trackingProducts;
	}

	private postInit() {
		this.selectPostinit.product.postInit();
		this.selectPostinit.trackingProduct.postInit();
	}
}

export default EditTrackingProduct;
