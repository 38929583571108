import C_Database from '@js/controllers/Database';

// MODAL
import AddEdit from '../modals/receipt/Infos';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';
import { GetContextMenuItemsParams } from '@ag-grid-community/core';

class ReceiptsTab extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Réceptions',
			database: 'receipts'
		});
	}

	protected buttonEdit(params?: any) {
		new AddEdit({
			id: params?.data._id
		}).open().then(() => {
			this.refreshData();
		}).catch(() => {
		});
	}

	protected getContextMenu(params: GetContextMenuItemsParams) {
		return [
			{
				name: `N° ${params.node.data.infos.number} / ${params.node.data.infos.provider}`,
				disabled: true,
				cssClasses: ['title-context-menu']
			},
			...super.getContextMenu(params)
		];
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			if (params.data.infos.state === 'waiting') {
				return {
					'background-color': 'var(--ap-red-50)',
					color: 'var(--ap-red-900)'
				};
			}

			if (params.data.infos.state === 'partial') {
				return {
					'background-color': 'var(--ap-orange-50)',
					color: 'var(--ap-orange-900)'
				};
			}

			if (params.data.infos.state === 'total') {
				return {
					'background-color': 'var(--ap-green-50)',
					color: 'var(--ap-green-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: 'infos.state',
					checkboxListTitle: 'État',
					type: 'checkbox',
					filters: [{
						value: 'waiting',
						label: 'En attente',
						backgroundColor: 'var(--ap-red-50)',
						hideIcon: true,
						leftIndent: true
					}, {
						value: 'partial',
						label: 'Réception partielle',
						backgroundColor: 'var(--ap-orange-50)',
						hideIcon: true,
						leftIndent: true
					}, {
						value: 'total',
						label: 'Réception totale',
						backgroundColor: 'var(--ap-green-50)',
						hideIcon: true,
						leftIndent: true
					}]
				}
			]
		];
	}
}

export default ReceiptsTab;
