import axios from 'axios';

import Utils from '@libs/utils/Utils';

import GenericService from '@services/GenericService';

type getAllData = {
	rowData: any,
	settings: { [key: string]: any }
};

class TaxesService extends GenericService {
	constructor() {
		super('units');
	}

	public async getDataToAgGridByCompanyID(companyID: string, abortController?: AbortController): Promise<getAllData> {
		return axios.get(`${this.uri}/all`, {
			params: {
				companyID,
				user: Utils.userID
			},
			signal: abortController?.signal
		}).then(response => {
			if (response.data.ok) {
				return response.data.data;
			}

			return {};
		});
	}
}

export default TaxesService;
