import '@css/customElements/alert.scss';

export type AlertType = 'danger' | 'warning' | 'success' | 'info' | 'primary' | 'secondary' | 'light' | 'dark';

class Alert extends HTMLElement {
	public static readonly tagName: string = 'ap-alert';

	public connectedCallback() {
		const content = this.querySelector('.content')?.innerHTML ?? this.innerHTML;

		this.innerHTML = `<div class="content">${content}</div>`;

		const size = this.firstElementChild?.clientHeight;

		this.style.setProperty('--calc-height', `${size}px`);

		const type = this.getAttribute('type');

		if (!Alert.isAlertType(type)) {
			this.setType('danger'); // 'danger' par défaut
		}
	}

	public setType(type: AlertType) {
		this.setAttribute('type', type);
	}

	public toggleVisibility(value?: boolean) {
		this.classList.toggle('visible', value);
	}

	public static register() {
		customElements.define(Alert.tagName, Alert);
	}

	private static isAlertType(type: string | null) {
		return type === 'danger' || type === 'warning' || type === 'success' || type === 'info' || type === 'primary' || type === 'secondary' || type === 'light' || type === 'dark';
	}
}

export default Alert;
