import '../../../css/customElement/editCategoriesReadonly.scss';

type category = {
	id: string,
	text: string
};

type dataEditCategoriesReadonly = {
	category1: category
	category2?: category
	category3?: category
}[];

class EditCategoriesReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-edit-categories-readonly';

	public connectedCallback() {
		this.innerHTML = `
			<div class="categories-title">Catégories</div>
			<div class="categories-content no-data-category">Aucune catégorie</div>
		`;
	}

	public set data(data: dataEditCategoriesReadonly) {
		const N_content = this.querySelector<HTMLElement>('.categories-content')!;
		N_content.innerHTML = '';

		if (data.length && Object.keys(data[0]).length) {
			N_content.classList.remove('no-data-category');

			data = data.sort((itemA, itemB) => {
				if (itemA.category1.text > itemB.category1.text) {
					return 1;
				}
				if (itemA.category1.text < itemB.category1.text) {
					return -1;
				}
				return 0;
			});

			for (const item of data) {
				if (item.category1) {
					const N_category1 = document.createElement('div');
					N_category1.classList.add('category1');
					N_category1.innerHTML = `<ap-checkbox label="${item.category1.text}" disabled checked></ap-checkbox>`;
					N_content.append(N_category1);
				}

				if (item.category2) {
					const N_category2 = document.createElement('div');
					N_category2.classList.add('category2');
					N_category2.innerHTML = `<ap-checkbox label="${item.category2.text}" disabled checked></ap-checkbox>`;
					N_content.append(N_category2);
				}

				if (item.category3) {
					const N_category3 = document.createElement('div');
					N_category3.classList.add('category3');
					N_category3.innerHTML = `<ap-checkbox label="${item.category3.text}" disabled checked></ap-checkbox>`;
					N_content.append(N_category3);
				}
			}
		} else {
			N_content.classList.add('no-data-category');
			N_content.innerHTML = 'Aucune catégorie';
		}
	}

	public static register() {
		customElements.define(EditCategoriesReadonly.tagName, EditCategoriesReadonly);
	}
}

export default EditCategoriesReadonly;
