import SignaturePad from 'signature_pad';

class Signature extends HTMLElement {
	public static readonly tagName: string = 'ap-signature';

	private signaturePad: SignaturePad | null = null;
	private _update = (): void => { };

	public async connectedCallback() {
		const height = this.getAttribute('height');
		const width = this.getAttribute('width');

		this.innerHTML = `
            <canvas style="box-shadow: 0px 0px 0px 1px #000000;" id="signature" height="${height}" width="${width}"></canvas>
        `;

		this.removeAttribute('height');
		this.removeAttribute('width');
	}

	public init() {
		const N_canvas = this.querySelector('#signature') as HTMLCanvasElement;
		const N_clearSignature = document.querySelector('#clear_signature') as HTMLButtonElement;

		this.signaturePad = new SignaturePad(N_canvas, {
			backgroundColor: 'rgb(255, 255, 255)'
		});

		N_clearSignature?.addEventListener('click', () => {
			this.signaturePad?.clear();
			this._update();
		});

		this.signaturePad.addEventListener('endStroke', () => {
			this._update();
		});
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public set data(data: any) {
		if (data.data && data.width && data.height) {
			const N_canvas = this.querySelector('#signature') as HTMLCanvasElement;
			const ratioX = N_canvas.width / data.width;
			const ratioY = N_canvas.height / data.height;

			for (const item of data.data) {
				for (const point of item.points) {
					point.x *= ratioX;
					point.y *= ratioY;
				}
			}
			this.signaturePad?.fromData(data.data);
		}
	}

	public get data(): any {
		const N_canvas = this.querySelector('#signature') as HTMLCanvasElement;

		const signature = {
			svg: this.signaturePad!.toDataURL('image/svg+xml'),
			data: this.signaturePad!.toData(),
			height: N_canvas.height,
			width: N_canvas.width
		};

		signature.svg = atob(signature.svg.split(',')[1]);

		return signature;
	}

	public static register() {
		customElements.define(Signature.tagName, Signature);
	}
}

export default Signature;
