// CORE
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/Alerte.html';

// LIBS
import Modal from '@libs/Modal';
import SelectFilter from '@libs/agGrid/SelectFilter';

import CreateOrderContextMenu from '@modules/OrdersProvider/js/libs/ProductContextMenu';
import M_CreateOrder from '@modules/OrdersProvider/js/modals/CreateOrderByProduct';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

class Alerte extends Modal {
	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

			const gridOptions: GridOptions = agUtils.french<GridOptions>({
				animateRows: true,
				suppressDragLeaveHidesColumns: true,
				suppressRowClickSelection: true,
				rowSelection: 'multiple',
				columnDefs: [
					{
						headerCheckboxSelection: true,
						headerCheckboxSelectionFilteredOnly: true,
						checkboxSelection: true,
						width: 50,
						suppressSizeToFit: true
					},
					{
						headerName: S_Product.getInstance().columnNameReference,
						suppressSizeToFit: true,
						width: 150,
						field: `product.${S_Product.getInstance().referenceKey}.formattedValue`,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						}
					},
					{
						headerName: 'Désignation',
						field: 'product.label.formattedValue',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						}
					},
					{
						headerName: 'Fournisseur par défaut',
						field: 'product.defaultProvider.id.formattedValue',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null || result === undefined) return null;
								return _.deburr(result?.toLowerCase());
							},
							debounceMS: 200
						}
					},
					{
						headerName: 'Type',
						suppressSizeToFit: true,
						width: 200,
						field: 'typeAlert.value',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponent: SelectFilter,
						floatingFilterComponentParams: {
							suppressFilterButton: true,
							options: {
								data: [{
									text: 'Alerte',
									id: 'alert'
								}, {
									text: 'A réapprovisionner',
									id: 'warning'
								}, {
									text: 'Négative',
									id: 'negative'
								}]
							}
						},
						cellRenderer: (params) => {
							return ({
								alert: 'Alerte',
								warning: 'A réapprovisionner',
								negative: 'Négative'
							} as { [key: string]: any })[params.value] || '';
						}
					},
					{
						headerName: 'Qté',
						suppressSizeToFit: true,
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						filterParams: {
							filterOptions: ['equals']
						},
						width: 100,
						field: 'quantity.formattedValue',
						cellClass: 'text-monospace text-right'
					},
					{
						headerName: 'Seuil Alarme',
						suppressSizeToFit: true,
						width: 100,
						field: 'product.alarmStock.formattedValue',
						cellClass: 'text-monospace text-right'
					},
					{
						headerName: 'Qté commandée',
						suppressSizeToFit: true,
						width: 200,
						field: 'lastOrder',
						cellClass: 'text-monospace text-right',
						cellRenderer: (params) => {
							let total = 0;
							let numberOrder = 0;

							const tooltip: string[] = [];

							params.value = params.value || {};

							if (Object.keys(params.value.customer || {}).length) {
								tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison direct client</div>');

								for (const id in params.value.customer) {
									total += params.value.customer[id].quantity;
									numberOrder++;

									tooltip.push(`<div class="d-flex">${params.value.customer[id].text} <div class="ml-auto pl-2">(Qté: ${params.value.customer[id].quantity})</div></div>`);
								}
							}

							if (Object.keys(params.value.internal || {}).length) {
								tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison interne</div>');

								for (const id in params.value.internal) {
									total += params.value.internal[id].quantity;
									numberOrder++;

									tooltip.push(`<div class="d-flex">${params.value.internal[id].text} <div class="ml-auto pl-2">(Qté: ${params.value.internal[id].quantity})</div></div>`);
								}
							}

							const N_div = document.createElement('div');

							N_div.classList.add('w-100');
							N_div.setAttribute('popover', JSON.stringify({ title: 'Détail commande:', content: tooltip.join('') || 'Aucune commande', trigger: 'hover', placement: 'right', sanitize: false }));

							N_div.innerHTML = `${total} (${numberOrder} Commande${numberOrder > 1 ? 's' : ''})`;

							return N_div;
						}
					}
				],
				defaultColDef: {
					suppressMenu: true,
					resizable: true,
					sortable: true
				},
				getContextMenuItems: (params) => {
					const products: { [key: string]: any }[] = gridOptions.api?.getSelectedRows() || [];

					if (products.length === 0) {
						products.push(params.node.data);
					}

					return [
						CreateOrderContextMenu.orderProduct(this.convertProductToOrderProvider(products), !params.node, () => {
							this.resolve();
						})
					];
				},
				onRowSelected: (params) => {
					const number = params.api?.getSelectedRows().length;
					N_save.disabled = number === 0;
					N_save.innerHTML = `Commander ${number > 1 ? 'les produits (' + number + ')' : 'le produit'}`;
				},
				onGridReady: async (params) => {
					const data = await this.getData();
					params.api.setRowData(data);
					params.api.sizeColumnsToFit();
				}
			});

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, gridOptions, { modules: AllModules });

			N_save.addEventListener('click', async () => {
				const products: { [key: string]: any }[] = gridOptions.api?.getSelectedRows() || [];

				this.ignoreChangeRoute = true;

				await new M_CreateOrder(this.convertProductToOrderProvider(products)).open();

				this.resolve();
			});
		});
	}

	private convertProductToOrderProvider(products: { [key: string]: any }[]) {
		const res: { productID: string, quantity: number }[] = [];

		for (const product of products) {
			if (product.product.alarmStock === null) {
				res.push({
					productID: product.product._id.value,
					quantity: Math.abs(product.quantity.value)
				});
			} else {
				res.push({
					productID: product.product._id.value,
					quantity: product.typeAlert.value === 'alert' || product.typeAlert.value === 'negative' ? product.product.alarmStock.value - product.quantity.value : 1
				});
			}
		}

		return res;
	}

	private async getData() {
		const data = await S_StockEvent.getInstance().getCurrentStock();

		const alert = _.filter(data.rowData, { displayDashboard: true, typeAlert: { value: 'alert' } });
		const warning = _.filter(data.rowData, { displayDashboard: true, typeAlert: { value: 'warning' } });
		const negative = _.filter(data.rowData, { displayDashboard: true, typeAlert: { value: 'negative' } });

		return [...negative, ...alert, ...warning];
	}
}

export default Alerte;
