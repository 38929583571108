import GenericService from '../GenericService';

class GroupsService extends GenericService {
	private static instance: GroupsService | null = null;

	constructor() {
		super('products-groups');
	}

	public static getInstance(): GroupsService {
		if (!GroupsService.instance) {
			GroupsService.instance = new GroupsService();
		}

		return GroupsService.instance;
	}

	/**
	 * Supprime tous les élements avec le nom correspondant dans la base Groupe produit
	 * @param name le nom du groupe
	 * @returns
	 */
	public async deleteByName(name: string) {
		return this.get_function('deleteByName', { name });
	}
}

export default GroupsService;
