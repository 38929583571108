import agUtils from '@libs/agGrid/french';

import T_modal from '../../../tpl/modals/groupsProducts/groupsProducts.html';

import M_addLine from './AddLineGroupsProducts';

import Decimal from '@libs/utils/Decimal';
import Modal from '@libs/Modal';

import BrandCellRenderer from '../../libs/BrandCellRenderer';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import S_Pdt_Groups from '@services/Product/GroupsService';
import S_Product from '@services/Product/ProductService';

class GroupsProducts extends Modal {
	private name: string = '';

	private gridOptions: GridOptions = {};

	constructor(name?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.name = name || '';

		this.on('opened', async () => {
			this.gridOptions = agUtils.french<GridOptions>({
				rowData: [],
				animateRows: true,
				floatingFilter: true,
				suppressDragLeaveHidesColumns: true,
				groupMultiAutoColumn: true,
				rowDragManaged: true,
				defaultColDef: {
					suppressMenu: true,
					suppressMovable: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					resizable: true,
					editable: (params) => {
						return !params.data.id_products;
					},
					filterParams: {
						newRowsAction: 'keep'
					}
				},
				columnDefs: [
					{
						headerName: S_Product.getInstance().columnNameReference,
						field: `product.${S_Product.getInstance().referenceKey}`,
						width: 180,
						suppressSizeToFit: true,
						rowDrag: true,
						editable: false
					},
					{
						headerName: 'Désignation',
						field: 'product.label',
						cellRenderer: (params) => {
							if (params.data.id_products) {
								return params.value;
							} else {
								return '<i class="icon icon-edit ml-2 text-light-blue-600"></i>' + (params.value || '');
							}
						}
					},
					{
						headerName: 'Marque',
						field: 'product.brand',
						editable: false,
						cellRenderer: (params) => {
							if (params.data.id_products) {
								const brandCellRenderer = new BrandCellRenderer();
								brandCellRenderer.init(params);
								return brandCellRenderer.getGui();
							} else {
								return '';
							}
						}
					},
					{
						headerName: 'Quantité',
						field: 'quantity',
						cellClass: ['text-monospace', 'text-right'],
						width: 100,
						suppressSizeToFit: true,
						cellRenderer: (params) => {
							if (params.data.id_products) {
								return params.value;
							} else {
								return (params.value || '') + '<i class="icon icon-edit ml-2 text-light-blue-600"></i>';
							}
						}
					},
					{
						headerName: 'Prix de vente',
						field: 'product.price',
						cellClass: ['text-monospace', 'text-right'],
						cellRenderer: (params) => {
							const price = Decimal.setDisplayNumber(params.value);
							if (params.data.id_products) {
								return price.setSuffixAndHumanizeNumber('€');
							} else {
								return price.setSuffixAndHumanizeNumber('€') + '<i class="icon icon-edit ml-2 text-light-blue-600"></i>';
							}
						}
					},
					{
						headerName: 'Action',
						field: '_id',
						width: 80,
						suppressSizeToFit: true,
						filter: false,
						cellRenderer: (params) => {
							const N_edit = document.createElement('button');

							N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

							N_edit.setAttribute('tooltip', 'Éditer');

							N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';

							N_edit.disabled = !params.data.id_products;

							N_edit.addEventListener('click', () => {
								new M_addLine({
									id_products: params.data.id_products,
									quantity: params.data.quantity
								}).open().then(async (data) => {
									const product = await S_Product.getInstance().getById(data.id_products);

									params.node.data.id_products = data.id_products;
									params.node.data.quantity = data.quantity;
									params.node.data.product = product;

									params.api.updateRowData({
										update: [params.node]
									});
								});
							});

							const N_delete = document.createElement('button');

							N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

							N_delete.setAttribute('tooltip', 'Éditer');

							N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';

							N_delete.setAttribute('confirmation', '');

							N_delete.addEventListener('click', async () => {
								if (params.value) {
									await S_Pdt_Groups.getInstance().delete(params.value);
								}

								params.api?.applyTransaction({
									remove: [params.node.data]
								});
							});

							const N_div = document.createElement('div');

							N_div.appendChild(N_edit);
							N_div.appendChild(N_delete);

							return N_div;
						}
					}
				],
				getContextMenuItems: (params) => {
					return [
						{
							name: 'Ajouter Produit',
							action: () => {
								new M_addLine().open().then(async (data) => {
									const product = await S_Product.getInstance().getById(data.id_products);

									params.api?.applyTransaction({
										add: [{
											id_products: data.id_products,
											quantity: data.quantity,
											product
										}]
									});
								});
							}
						}, {
							name: 'Ajouter Ligne',
							action: () => {
								params.api?.applyTransaction({
									add: [{
										quantity: 1,
										product: {
											price: 0
										}
									}]
								});
							}
						}
					];
				},
				onRowDataUpdated: () => {
					this.updatePrice();
				},
				onRowDataChanged: () => {
					this.updatePrice();
				},
				onCellEditingStopped: () => {
					this.updatePrice();
				},
				onGridReady: (params) => {
					params.api?.sizeColumnsToFit();
				}
			});

			const N_name = this.element.querySelector('[name="name"]') as HTMLInputElement;

			N_name.value = this.name;

			const N_grid = this.element.querySelector('#grid') as HTMLElement;

			new Grid(N_grid, this.gridOptions, { modules: AllModules });

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				const data: { [key: string]: any }[] = [];

				let index = 0;
				this.gridOptions.api?.forEachNode((node) => {
					node.data.$order = index;
					data.push(node.data);
					index++;
				});

				for (const item of data) {
					if (item.product._id) {
						await S_Pdt_Groups.getInstance().save({
							_id: item._id,
							name: N_name.value,
							quantity: item.quantity,
							$order: item.$order,
							id_products: item.product._id
						});
					} else {
						await S_Pdt_Groups.getInstance().save({
							_id: item._id,
							name: N_name.value,
							quantity: item.quantity,
							$order: item.$order,
							product: {
								price: item.product.price,
								label: item.product.label,
								brand: item.product.brand
							}
						});
					}
				}

				this.resolve(data);
			});

			if (this.name) {
				const data = await this.getData();

				this.gridOptions.api?.setRowData(data);
			}
		});
	}

	private async getData() {
		const { data } = await S_Pdt_Groups.getInstance().getDataToModal('', { byName: true, name: this.name });
		return data.data;
	}

	private updatePrice() {
		let price = new Decimal(0);

		const N_price = this.element.querySelector('#price') as HTMLInputElement;

		this.gridOptions.api?.forEachNode((node) => {
			const tmpPrice = Decimal.setDisplayNumber(node.data.product.price).times(node.data.quantity || 0);
			price = price.plus(tmpPrice);
		});

		N_price.value = price.setSuffixAndHumanizeNumber('€');
	}
}

export default GroupsProducts;
