import '../../../css/customElement/editCategoriesReadonly.scss';

import FormControllerPageID from '@libs/FormControllerPageID';

import S_ProductStock from '@services/Product/ProductStockService';

import '../../../css/customElement/editProductStockReadonly.scss';

class EditProductStockReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-edit-product-stock-readonly';

	private form: FormControllerPageID | null = null;

	public connectedCallback() {
		this.innerHTML = `
			<div class="stock-title"></div>
			<div class="stock-content">
				<div class="page-card">

					<div class="page-card-body">

						<div class="page-card-block-value">
							<div class="page-card-label">Emplacement du produit :</div>
							<div class="page-card-value" name="stockLocation"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Seuil d’alarme personnalisé :</div>

							<div class="custom-alarm">
								<div class="page-card-value" name="alarmStock"></div><div id="enableCustomAlarm"></div>
							</div>
						</div>

					</div>

				</div>
			
			</div>
		`;

		this.form = new FormControllerPageID(this.querySelector('.stock-content')!);

		const N_title = this.querySelector<HTMLElement>('.stock-title')!;

		N_title.addEventListener('click', () => {
			this.classList.toggle('active');
		});
	}

	public async init(id: string) {
		const { data } = await S_ProductStock.getInstance().getDataToModal(id);

		this.form?.setData(data.data);

		const N_enableCustomAlarm = this.querySelector<HTMLDivElement>('#enableCustomAlarm')!;
		N_enableCustomAlarm.setAttribute('data-enabled', !!data.data.enableCustomAlarm + ''); // data-enabled="true" ou data-enabled="false" -> pour le CSS
		N_enableCustomAlarm.innerHTML = data.data.enableCustomAlarm ? '(activé)' : '(désactivé)';

		const N_title = this.querySelector<HTMLElement>('.stock-title')!;
		N_title.innerHTML = `${data.data.isDefault ? '<ap-icon name="star"></ap-icon>' : ''}${data.title} <ap-icon id="icon-active" name="arrow-down-s/line"></ap-icon>`;

		this.classList.add('active');
	}

	public static register() {
		customElements.define(EditProductStockReadonly.tagName, EditProductStockReadonly);
	}
}

export default EditProductStockReadonly;
