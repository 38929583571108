import { Controller, Tabs, global } from '@autoprog/core-client';

import C_Tab from '@js/controllers/TabDatabase';

import C_Favorite from '../tabs/Favorite';
import C_Quote from '../tabs/Quote';
import C_QuoteToDo from '../tabs/QuoteToDo';

import History from '@libs/History';

import T_blockTabs from '@tpl/blocks/tabs.html';

export enum QuoteState {
	KEEP_STATE = '-1',
	IN_PROGRESS = '0',
	ACCEPTED = '1',
	REFUSED = '2',
	WAITING_FOR_ORDER = '4',
	READY_TO_BE_SENT = '5',
	TO_VALIDATE = '6',
	REVISED = '7',
	SEND = 'send'
}

class QuotesCtrl extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'quotes';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		this.tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		this.tabs.add({
			name: 'Devis à faire',
			id: 'quotes_to_do',
			default: defaultTab === 'quotes_to_do',
			controller: C_Tab,
			data: {
				instance: C_QuoteToDo
			},
			template: T_blockTabs,
			permission: 'QUOTES._QUOTES_TO_DO.SHOW'
		});

		this.tabs.add({
			name: 'Devis',
			id: 'quotes',
			default: defaultTab === 'quotes',
			controller: C_Tab,
			data: {
				instance: C_Quote
			},
			template: T_blockTabs,
			permission: 'QUOTES.SHOW'
		});

		if (!global.IS_MOBILE) {
			this.tabs.add({
				name: 'Favoris',
				id: 'favorite',
				default: defaultTab === 'favorite',
				controller: C_Tab,
				data: {
					instance: C_Favorite
				},
				template: T_blockTabs,
				permission: 'QUOTES._FAVORITE.SHOW'
			});
		}

		let firstLoad = true;
		this.tabs.on('change', (data: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/quotes/${data.id}`);
			}
		});
	}

	public destructor() {
		this.tabs.destructor();
	}
}

export default QuotesCtrl;
