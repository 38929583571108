import _ from 'lodash';

class Switch3 extends HTMLElement {
	public static readonly tagName: string = 'ap-switch-3';

	public _value: -1 | 0 | 1 = -1;

	public async connectedCallback() {
		const name = _.uniqueId(Date.now().toString(36) + '_');

		this.innerHTML = `
            <div class="theme-toggle">
                <label class="custom-radio-button">
                    <input id="first" name="${name}" type="radio"/>
                    <span class="checkmark"></span>
                </label>

                <label class="custom-radio-button">
                    <input id="second" name="${name}" type="radio" checked/>
                    <span class="checkmark"></span>
                </label>

                <label class="custom-radio-button">
                    <input id="third" name="${name}" type="radio"/>
                    <span class="checkmark"></span>
                </label>
            </div>
        `;

		const N_first = this.querySelector('#first') as HTMLInputElement;
		const N_second = this.querySelector('#second') as HTMLInputElement;
		const N_third = this.querySelector('#third') as HTMLInputElement;

		N_first.addEventListener('change', () => {
			this._value = 0;
			this.dispatchEvent(new Event('change', { bubbles: true }));
		});

		N_second.addEventListener('change', () => {
			this._value = -1;
			this.dispatchEvent(new Event('change', { bubbles: true }));
		});

		N_third.addEventListener('change', () => {
			this._value = 1;
			this.dispatchEvent(new Event('change', { bubbles: true }));
		});
	}

	public set value(value: number) {
		if ([-1, 0, 1].includes(value)) {
			this._value = value as any;

			const N_first = this.querySelector('#first') as HTMLInputElement;
			const N_second = this.querySelector('#second') as HTMLInputElement;
			const N_third = this.querySelector('#third') as HTMLInputElement;

			N_first.checked = (this.value === 0);
			N_second.checked = (this.value === -1);
			N_third.checked = (this.value === 1);
		}
	}

	public get value() {
		return this._value;
	}

	public static register() {
		customElements.define(Switch3.tagName, Switch3);
	}
}

export default Switch3;
