type select = {
	name: string
	isSingle?: boolean
	text?: string
};

export type Input = {
	text?: string
	select: select[]
};

class SettingsNotificationRegister {
	private static instance: SettingsNotificationRegister;

	private _links: Input[][];

	private constructor() {
		this._links = [];
	}

	public get links() {
		return this._links;
	}

	public static getInstance() {
		if (!SettingsNotificationRegister.instance) {
			SettingsNotificationRegister.instance = new SettingsNotificationRegister();
		}

		return SettingsNotificationRegister.instance;
	}

	public addInput(item: Input, column: number) {
		this._links[column] = this._links[column] || [];
		this._links[column].push(item);
	}
}

export default SettingsNotificationRegister;
