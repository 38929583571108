import T_modal from '../../../tpl/modals/AddQuote/SelectFavorite.html';

import { GridOptions } from '@ag-grid-enterprise/all-modules';

import Modal from '@libs/Modal';

import Options from '../../libs/GridOptions';

import CE_Aggrid from '@libs/customElement/AgGrid';

import S_Product from '@services/Product/ProductService';
import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

class SelectFavorite extends Modal {
	private optionsGrid: Options;

	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.optionsGrid = new Options();
		this.optionsGrid.init();
		this.optionsGrid.isLock = true;

		this.on('opened', async () => {
			const N_grid = this.element.querySelector<CE_Aggrid>('#grid')!;

			const N_numberSelected = this.element.querySelector('#numberSelected') as HTMLElement;
			let number = 0;

			const gridMaster = this.optionsGrid.gridMaster('favorite');

			gridMaster.columnDefs.pop();

			gridMaster.columnDefs[0].checkboxSelection = true;

			gridMaster.columnDefs.splice(1, 0, {
				field: 'title',
				headerName: 'Titre',
				suppressSizeToFit: true,
				width: 300
			});

			gridMaster.columnDefs.push({
				field: 'user',
				headerName: 'Utilisateur',
				width: 150,
				suppressSizeToFit: true
			});

			for (const item of gridMaster.columnDefs) {
				if (['quantity', 'price', 'sum', '_idGrid', 'unit'].includes(item.field || '')) {
					item.filter = false;
				}
			}

			const gridDetails = this.optionsGrid.gridDetails('favorite', () => { });

			gridDetails.columnDefs.pop();

			N_grid.setGridOptions({
				...gridMaster,
				floatingFilter: true,
				suppressContextMenu: true,
				suppressRowClickSelection: true,
				rowSelection: 'multiple',
				defaultColDef: {
					suppressMenu: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					resizable: true,
					filterParams: {
						newRowsAction: 'keep'
					}
				},
				masterDetail: true,
				detailRowHeight: 400,
				detailCellRendererParams: {
					detailGridOptions: <GridOptions>{
						...gridDetails,
						suppressDragLeaveHidesColumns: true
					},
					template: () => {
						return '<div class="h-100"><div ref="eDetailGrid" class="h-100" style="padding: 3px;background: black;"></div></div>';
					},
					getDetailRowData: (params: any) => {
						params.successCallback(params.data.details);
					}
				},
				onRowSelected: (params) => {
					if (params.node.isSelected()) {
						number++;
					} else {
						number--;
					}
					N_numberSelected.innerHTML = number.toString();
				},
				onColumnResized: (params) => {
					if (params.finished) {
						params.api?.resetRowHeights();
					}
				}
			});

			const { rowData } = await S_Q_Favorite.getInstance().getDataToAgGrid();

			N_grid.value = rowData;

			const N_save = this.element.querySelector('#save') as HTMLElement;
			const N_update_label = this.element.querySelector('#update_label') as HTMLInputElement;

			N_save.addEventListener('click', async () => {
				const data = N_grid.api?.getSelectedRows() || [];

				let index = 0;

				for (const item of data) {
					item._idGrid = Date.now().toString(36) + '_' + index;
					item._idFav = item._id;

					delete item._id;
					delete item._rev;
					delete item.title;

					for (const detail of item.details) {
						if (detail.reference) {
							try {
								const product = await S_Product.getInstance().getByIdWithProvider(detail.reference);

								if (product.price) {
									detail.price = product.price;
								}

								if (product.comptaVente) {
									detail.comptaVente = product.comptaVente;
								}

								if (product.defaultProvider) {
									detail.costPrice = product.defaultProvider.costPrice.value.value;
								}

								if (N_update_label.checked && product.label) {
									detail.label = product.label;
								}
							} catch (e) {

							}
						}
					}

					index++;
				}

				this.resolve(data);
			});
		});
	}
}

export default SelectFavorite;
