module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div>\n            ' +
((__t = (data.user || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n        <div class="mt-2">\n            ' +
((__t = (data.date || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n        <div class="mt-2" style="white-space: pre-line;">' +
((__t = (data.text)) == null ? '' : __t) +
'</div>\n    </div>\n\n</div>';

}
return __p
}