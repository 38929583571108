import AddEdit from '@libs/AddEdit';
import ConfigManager from '@libs/ConfigManager';
import ServiceManager from './ServiceManager';

class ModalManager {
	private static instance: ModalManager;

	private static cache: Map<string, any> = new Map<string, any>();

	private configManager: ConfigManager;

	private constructor() {
		this.configManager = ConfigManager.getInstance();
	}

	public static getInstance() {
		if (!ModalManager.instance) {
			ModalManager.instance = new ModalManager();
		}

		return ModalManager.instance;
	}

	public static register(table: string, cls: any) {
		ModalManager.cache.set(table, cls);
	}

	public async open(table: string, id: string = '', defaultValue: { [key: string]: any } = {}, mode: string = '') {
		const config = this.configManager.getConfig(table);

		let modal = null;

		const Cls = ModalManager.cache.get(table);

		if (Cls) {
			modal = new Cls(id, defaultValue);
		} else {
			modal = new AddEdit(config, table, id, defaultValue);
		}

		if (mode) {
			return modal.setMode(mode).open();
		} else {
			return modal.open();
		}
	}

	public async openDuplicate(table: string, id: string = '', defaultValue: { [key: string]: any } = {}) {
		const config = this.configManager.getConfig(table);

		let modal = null;

		const Cls = ModalManager.cache.get(table);

		if (Cls) {
			modal = new Cls(id, defaultValue);
		} else {
			modal = new AddEdit(config, table, id, defaultValue);
		}

		return modal.setMode('duplicate').open();
	}

	public async openDuplicateWithReturnData(table: string, id: string = '', defaultValue: { [key: string]: any } = {}) {
		const newId = await this.openDuplicate(table, id, defaultValue);

		return await ServiceManager.get(table)?.getInstance().getById(newId);
	}

	public async openWithReturnData(table: string, id: string = '', defaultValue: { [key: string]: any } = {}) {
		const newId = await this.open(table, id, defaultValue);

		return await ServiceManager.get(table)?.getInstance().getById(newId);
	}

	public async openWithReturnSelect2Data(table: string, id: string = '', defaultValue: { [key: string]: any } = {}) {
		const newId = await this.open(table, id, defaultValue);

		return await ServiceManager.get(table)?.getInstance().getDataToSelect2ByID(newId);
	}
}

export default ModalManager;
