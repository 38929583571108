import '../../../css/customElement/multiInput.scss';

class MultiInput extends HTMLElement {
	public static readonly tagName: string = 'ap-multi-input';

	private N_inputs: HTMLDivElement[] = [];

	public connectedCallback() {
		const pattern = this.getAttribute('pattern') || '';

		this.innerHTML = '';

		const match = pattern.split(':');
		match.shift();

		const N_div = document.createElement('div');
		N_div.classList.add('content');

		let index = 0;
		for (let i = 0; i < match.length; i = i + 2) {
			const name = match[i];
			const separator = match[i + 1];

			const { N_input, N_separator } = this.renderInput(name, separator, index);

			N_div.append(N_input, N_separator);

			index++;
		}

		this.append(N_div);
	}

	private focusInput(N_el: HTMLDivElement) {
		if (N_el) {
			const selection = window.getSelection()!;

			const range = document.createRange();
			selection.removeAllRanges();
			range.selectNodeContents(N_el);
			range.collapse(false);
			selection.addRange(range);

			N_el?.focus();
		}
	}

	private renderInput(name: string, separator: string, index: number) {
		const N_input = document.createElement('div');
		N_input.classList.add('input');
		N_input.setAttribute('contenteditable', 'true');
		N_input.setAttribute('name', name);

		let oldSelection: number = -1;

		N_input.addEventListener('keydown', (e) => {
			if (e.key === 'Enter') {
				e.stopPropagation();
				e.stopImmediatePropagation();
				e.preventDefault();
			}

			if (separator.toUpperCase().includes(e.key.toUpperCase())) {
				e.stopPropagation();
				e.stopImmediatePropagation();
				e.preventDefault();
				this.N_inputs[index + 1]?.focus();
			}

			oldSelection = window.getSelection()!.anchorOffset;
		});

		N_input.addEventListener('keyup', (e) => {
			if (oldSelection === 0) {
				if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
					this.focusInput(this.N_inputs[index - 1]);
				}
			}

			if (oldSelection === this.N_inputs[index].innerHTML.length) {
				if (e.key === 'ArrowRight') {
					this.focusInput(this.N_inputs[index + 1]);
				}
			}
		});

		this.N_inputs.push(N_input);

		const N_separator = document.createElement('div');
		N_separator.classList.add('separator');
		N_separator.innerHTML = separator;

		return { N_input, N_separator };
	}

	public get value() {
		const result: { [key: string]: string } = {};

		for (const N_el of this.N_inputs) {
			const name = N_el.getAttribute('name') || '';

			result[name] = N_el.innerHTML;
		}

		return result;
	}

	public set value(data: { [key: string]: any }) {
		for (const N_el of this.N_inputs) {
			const name = N_el.getAttribute('name') || '';
			N_el.innerHTML = data[name] || '';
		}
	}

	public static register() {
		customElements.define(MultiInput.tagName, MultiInput);
	}
}

export default MultiInput;
