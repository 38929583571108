import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import CE_Select2 from '@libs/customElement/Select2';

import T_modal from '../../../../tpl/modals/deliveries/editPage/selectTransporter.html';

import S_Transporter from '@services/TransporterService';

type TransporterData = {
	infos: {
		transporter: string,
		trackingNumber: string,
		commentsTransporter: string
	}
};

// ENUM pour les modes d'édition
export enum EditMode {
	Default = 0,
	Dashboard = 1
}

class SelectTransporter extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};
	private form: Form | null;
	private editMode: EditMode;

	constructor(transporterData?: TransporterData, editMode?: EditMode) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.editMode = editMode || EditMode.Default;

		this.form = null;

		this.on('opened', async () => {
			this.init();

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			this.form = new Form(N_form);

			if (transporterData) {
				await this.setData(transporterData);
			}

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const formData = this.form?.getData() as { [key: string]: any };

					if (this.editMode === EditMode.Default) {
						formData.infos.transporter = await S_Transporter.getInstance().getDataToSelect2ByID(formData.infos.transporter);
					}

					this.resolve(formData);
				}));
			});
		});
	}

	private init() {
		const N_transporter = this.element.querySelector('[name="infos.transporter"]') as CE_Select2;

		N_transporter.create(this.element);

		N_transporter.postInit();

		this.selectPostinit['infos.transporter'] = N_transporter;
	}

	private async setData(data: TransporterData) {
		const res: { [key: string]: any } = {
			infos: {
				transporter: await S_Transporter.getInstance().getDataToSelect2ByID(data.infos.transporter),
				trackingNumber: data.infos.trackingNumber,
				commentsTransporter: data.infos.commentsTransporter
			}
		};

		this.form?.setData(res);
	}
}

export default SelectTransporter;
