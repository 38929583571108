import { GridOptions } from '@ag-grid-enterprise/all-modules';

import '@css/customElements/button-edit-aggrid.scss';

import M_SelectProduct from '../../../modals/SelectProduct';

import S_Products from '@services/Product/ProductService';

class SelectProduct extends HTMLElement {
	public static readonly tagName: string = 'ap-button-aggrid-select-product';

	private _gridOptions: GridOptions = {};

	private _generateProduct: (product: any, quantity: number, inGroup: boolean) => any = () => { };
	private _generateGroup: (label: string) => any = () => { };

	public connectedCallback() {
		this.classList.add('button-edit-aggrid');

		if (this.hasAttribute('disabled')) {
			this.innerHTML = `
			<ap-button class="disabled" id="select-product" tooltip="Sélectionner" icon="list-check-3"></ap-button>
		`;
		} else {
			this.innerHTML = `
			<ap-button  id="select-product" tooltip="Sélectionner" icon="list-check-3"></ap-button>
		`;
		}

		const N_selectProduct = this.querySelector<HTMLElement>('#select-product')!;

		N_selectProduct.addEventListener('click', async () => {
			const { products, groups } = await new M_SelectProduct().open();

			const result = [];

			for (const id in products) {
				const { data } = await S_Products.getInstance().getDataToModal(id);

				result.push(this._generateProduct(data.data, products[id].quantity, false));
			}

			for (const key in groups) {
				result.push(this._generateGroup(groups[key][0].name));

				for (const item of groups[key]) {
					const { data } = await S_Products.getInstance().getDataToModal(item.product._id);

					result.push(this._generateProduct(data.data, item.quantity, true));
				}
			}

			this._gridOptions.api?.applyTransaction({
				add: result
			});

			this._gridOptions.api?.setPinnedBottomRowData([{}]);
			this._gridOptions.api?.refreshCells({ force: true });

			this.dispatchEvent(new CustomEvent('update'));
		});
	}

	public set gridOptions(gridOptions: GridOptions) {
		this._gridOptions = gridOptions;
	}

	public set generateProduct(generateProduct: (product: any, quantity: number, inGroup: boolean) => any) {
		this._generateProduct = generateProduct;
	}

	public set generateGroup(generateGroup: (label: string) => any) {
		this._generateGroup = generateGroup;
	}

	public static register() {
		customElements.define(SelectProduct.tagName, SelectProduct);
	}
}

export default SelectProduct;
