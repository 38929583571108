import _ from 'lodash';

import { GridOptions } from '@ag-grid-enterprise/all-modules';

import CE_Button from './Button';
import CE_Switch from './Switch';
import CE_Switch3 from './Switch3';

type FilterItem = {
	label: string // Le label du filtre
	id?: string // Identifiant du filtre
	backgroundColor?: string // Pour mettre un arrière plan au conteneur de l'icone et du label (sous-forme de badge arrondi)
	iconName?: string // Pour choisir un icone personnalisé
	iconColor?: string // La couleur de l'icone donné
	value?: string // La valeur du filtre
	values?: string[] // La liste des valeurs du filtre
	hideIcon?: boolean, // Pour masquer l'icone
	leftIndent?: boolean // Pour indenter à gauche (ou marquer un alinéa)
};

type ConfigItem = {
	id?: string // Identifiant de la configuration
	column?: string // Pour indiquer le nom de la colonne ag grid concernée
	type: 'checkbox' | 'switch' | 'switch-3' | 'title' | 'separator' // Le type de la configuration
	checkboxListTitle?: string // Pour donner un titre à une configuration de type 'checkbox'
	filters?: FilterItem[] // La liste des filtres de la configuration
	hide?: boolean // Pour masquer la configuration
};

export type Config = ConfigItem[][];

class FilterButton extends HTMLElement {
	public static readonly tagName: string = 'ap-filter-button';

	public static readonly defaultIcon = 'icon-solid-circle';
	private config: Config = [];

	private filter: { [key: string]: boolean } = {};
	private values: { [key: string]: any } = {};

	protected _gridOptions: GridOptions = {};
	private _ignoreCols: string[] = [];
	private waitingSetConfig = false;

	public async connectedCallback() {
		this.innerHTML = `
            <div class="dropdown">
                <ap-button data-toggle="dropdown" class="btn-filter">
                    <ap-icon name="filter/line"></ap-icon>
                    Filtres
					<span class="d-none" id="number">0</span>
                </ap-button>
                <div class="dropdown-menu">
                </div>
            </div>
            <ap-button class="btn-icon btn-clear-filter d-none" id="clear-filter">
                <ap-icon name="filter-off/line"></i>
            </ap-button>
        `;

		if (this.waitingSetConfig) {
			this._setConfig();
		}
	}

	public setConfig(config: Config, gridOptions: GridOptions, ignoreCols: string[] = []) {
		this.config = config;
		this._gridOptions = gridOptions;
		this._ignoreCols = ignoreCols;

		this.waitingSetConfig = true;

		if (this.isConnected) {
			this._setConfig();
		}
	}

	private _setConfig() {
		this.waitingSetConfig = false;

		const N_dropdown = this.querySelector('.dropdown-menu') as HTMLElement;
		const N_clearFilter = this.querySelector('#clear-filter') as HTMLButtonElement;
		const N_content = document.createElement('div');

		N_content.classList.add('dropdown-content');

		N_dropdown.addEventListener('click', (e) => {
			e.stopPropagation();
		});

		N_clearFilter.addEventListener('click', () => {
			const filters = this._gridOptions.api?.getFilterModel() || {};

			for (const key in filters) {
				if (!this._ignoreCols.includes(key)) {
					delete filters[key];
				}
			}

			this._gridOptions.api?.setFilterModel(filters);
		});

		if (this.config.length) {
			for (const colConfig of this.config) {
				const N_col = document.createElement('div');

				for (let i = 0; i < colConfig.length; i++) {
					const item = colConfig[i];

					if (!item.hide) {
						item.id = item.id || _.uniqueId(Date.now().toString(36) + '_');

						item.filters = item.filters || [];

						if (item.type === 'title') {
							const N_div = document.createElement('div');
							N_div.innerHTML = `<div class="title">${_.upperFirst(item.checkboxListTitle)}</div>`;
							N_col.appendChild(N_div);
						}

						if (item.type === 'checkbox') {
							N_col.appendChild(this.initCheckBox(item));
						}

						if (item.type === 'switch') {
							N_col.appendChild(this.initSwitch(item));
						}

						if (item.type === 'switch-3') {
							N_col.appendChild(this.initSwitch3(item));
						}

						if (colConfig[i].type === 'separator') {
							const N_hr = document.createElement('hr');
							N_col.appendChild(N_hr);
						}
					}
				}

				N_content.appendChild(N_col);
			}

			N_dropdown.appendChild(N_content);
		} else {
			const N_dropdown = this.querySelector('.dropdown') as HTMLElement;
			N_dropdown.classList.add('d-none');
		}

		this._gridOptions.api?.addEventListener('filterChanged', () => {
			const filters = this._gridOptions.api?.getFilterModel() || {};

			for (const column in this.values) {
				const col = this.findFilter(column);

				if (col) {
					col.filters = col.filters || [];

					if (col.type === 'switch') {
						const N_input = this.querySelector<CE_Switch>(`#${col.filters[0]?.id}`);
						N_input && (N_input.checked = false);

						this.values[column] = '';
					} else if (col.type === 'switch-3') {
						const N_input = this.querySelector(`#${col.filters[0]?.id}`) as CE_Switch3;
						N_input && (N_input.value = -1);

						this.values[column] = '';
					} else if (col.type === 'checkbox') {
						const N_ClearFilter = this.querySelector('#clear-filter-' + col.id!) as CE_Button;
						N_ClearFilter && N_ClearFilter.classList.add('disabled');

						for (const value of this.values[column]) {
							const filter = _.find(col?.filters, { value });

							const N_input = this.querySelector(`#${filter?.id}`) as HTMLInputElement;
							N_input && (N_input.checked = false);

							this.values[column] = [];

							const N_ClearFilter = this.querySelector('#clear-filter-' + col.id!) as CE_Button;
							N_ClearFilter && N_ClearFilter.classList.add('disabled');
						}
					}
				}
			}

			for (const column in filters) {
				const col = this.findFilter(column);

				if (col) {
					col.filters = col.filters || [];

					if (col.type === 'switch') {
						const filter = _.find(col?.filters, { value: filters[column].filter });
						const N_input = this.querySelector<CE_Switch>(`#${col.filters[0]?.id}`)!;

						if (filter) {
							this.values[column] = filters[column].filter;
							N_input && (N_input.checked = true);
						}
					} else if (col.type === 'switch-3') {
						const N_input = this.querySelector(`#${col.filters[0]?.id}`) as CE_Switch3;

						this.values[column] = filters[column].filter;

						if (N_input) {
							N_input.value = (col.filters[0]?.values || []).indexOf(filters[column].filter);
						}
					} else if (col.type === 'checkbox') {
						for (const value of filters[column].values) {
							const filter = _.find(col.filters, { value });

							const N_input = this.querySelector(`#${filter?.id}`) as HTMLInputElement;
							N_input && (N_input.checked = true);

							const N_ClearFilter = this.querySelector('#clear-filter-' + col.id!) as CE_Button;
							N_ClearFilter && N_ClearFilter.classList.remove('disabled');
						}

						this.values[column] = filters[column].values;
					}

					this.updateNumber(column);
				}
			}

			for (const column in this.values) {
				const col = this.findFilter(column);

				if (!filters[column] && col) {
					if (col.type === 'switch' || col.type === 'switch-3') {
						this.values[column] = '';
					} else {
						this.values[column] = [];
					}

					this.updateNumber(column);
				}
			}

			for (const key of this._ignoreCols) {
				delete filters[key];
			}

			if (_.isEmpty(filters)) {
				N_clearFilter.classList.add('d-none');
			} else {
				N_clearFilter.classList.remove('d-none');
			}
		});
	}

	private findFilter(column: string) {
		for (const colConfig of this.config) {
			for (const item of colConfig) {
				if (item.column === column) {
					return item;
				}
			}
		}

		return null;
	}

	private setFilters(column: string) {
		const instance = this._gridOptions.api?.getFilterInstance(column);

		const col = this.findFilter(column);

		if (instance && col) {
			if (col.type === 'switch' || col.type === 'switch-3') {
				instance.setModel(this.values[column]
					? {
						filter: this.values[column],
						filterType: 'text',
						type: 'equals'
					}
					: null);
			} else {
				instance.setModel({
					values: this.values[column].length ? this.values[column] : null
				});
			}

			this.updateNumber(column);

			this._gridOptions.api?.onFilterChanged();
		}
	}

	private updateNumber(column: string) {
		if (this.values[column].length) {
			this.filter[column] = true;
		} else {
			delete this.filter[column];
		}

		const N_number = this.querySelector('#number') as HTMLElement;

		const value = Object.keys(this.filter).length;

		N_number.innerHTML = `(${value.toString()})`;

		if (value) {
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	private initCheckBox(item: ConfigItem) {
		this.values[item.column!] = [];

		const N_div = document.createElement('div');
		N_div.id = item.id!;

		const N_all = document.createElement('div');
		N_all.classList.add('header-item');
		N_all.innerHTML = `
            <div class="header-item-title">${item.checkboxListTitle ?? 'Tout'}</div>
			<ap-button id="clear-filter-${item.id!}" class="btn-icon btn-clear-filter disabled" tooltip="Retirer le filtre"><ap-icon name="filter-off/fill"></ap-icon></ap-button>
        `;

		N_div.appendChild(N_all);

		const N_ClearFilter = N_all.querySelector('.btn-clear-filter') as CE_Button;
		N_ClearFilter.addEventListener('click', () => {
			if (this.values[item.column!].length) {
				this.values[item.column!] = [];

				for (const filter of item.filters!) {
					const N_input = N_div.querySelector(`#${filter.id}`) as HTMLInputElement;
					N_input.checked = false;
				}

				this.setFilters(item.column!);
			}
		});

		for (const filter of item.filters!) {
			filter.id = filter.id || _.uniqueId(Date.now().toString(36) + '_');

			const N_containerInput = document.createElement('div');
			N_containerInput.classList.add('item');

			filter.iconName = filter.iconName || FilterButton.defaultIcon;

			let iconStyle = '';

			// On met une légère ombre pour distinguer les pastilles blanches sur fond blanc
			if (filter.iconName === 'icon-solid-circle' && (filter.iconColor === 'white' || filter.iconColor?.toLowerCase() === '#ffffff')) {
				iconStyle += 'filter: drop-shadow(0px 0px 1px #050505);';
			}

			N_containerInput.innerHTML = `
				<div class="item-icon-label">
					<i class="mr-2 icon ${filter.iconName} text-${filter.iconColor} text-md ${filter.iconColor ? '' : 'invisible'} ${filter.hideIcon ? 'd-none' : ''}" ${iconStyle}></i>
					<label class="item-label" for="${filter.id}">${filter.label}</label>
				</div>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" id="${filter.id}" class="custom-control-input">
                    <label class="custom-control-label" for="${filter.id}"></label>
                </div>
            `;

			if (filter.leftIndent) {
				N_containerInput.classList.add('ml-4');
			}

			const N_ItemIconLabel = N_containerInput.querySelector('.item-icon-label') as HTMLDivElement;

			if (filter.backgroundColor) {
				N_ItemIconLabel.style.backgroundColor = filter.backgroundColor;
			}

			const N_input = N_containerInput.querySelector(`#${filter.id}`) as HTMLInputElement;

			N_input.addEventListener('change', () => {
				const index = this.values[item.column!].indexOf(filter.value);

				if (index === -1) {
					this.values[item.column!].push(filter.value);
				} else {
					this.values[item.column!].splice(index, 1);
				}

				this.setFilters(item.column!);
			});

			N_div.appendChild(N_containerInput);
		}

		return N_div;
	}

	private initSwitch(item: ConfigItem) {
		this.values[item.column!] = '';

		const N_div = document.createElement('div');
		N_div.id = item.id!;

		const filter = item.filters![0];

		filter.id = filter.id || _.uniqueId(Date.now().toString(36) + '_');

		filter.iconName = filter.iconName || FilterButton.defaultIcon;

		let iconStyle = '';

		// On met une légère ombre pour distinguer les pastilles blanches sur fond blanc
		if (filter.iconName === 'icon-solid-circle' && (filter.iconColor === 'white' || filter.iconColor?.toLowerCase() === '#ffffff')) {
			iconStyle += 'filter: drop-shadow(0px 0px 1px #050505);';
		}

		const N_containerInput = document.createElement('div');
		N_containerInput.classList.add('item');
		N_containerInput.innerHTML = `
			<div class="item-icon-label">
				<i class="mr-2 icon ${filter.iconName} text-${filter.iconColor} text-md ${filter.iconColor ? '' : 'invisible'} ${filter.hideIcon ? 'd-none' : ''}" ${iconStyle}></i>
				<label class="item-label" for="${filter.id}">${filter.label}</label>
			</div>
			
			<div class="custom-control custom-checkbox ml-auto">
				<input type="checkbox" id="${filter.id}" class="custom-control-input">
				<label class="custom-control-label" for="${filter.id}"></label>
			</div>
        `;

		if (filter.leftIndent) {
			N_containerInput.classList.add('ml-4');
		}

		const N_ItemIconLabel = N_containerInput.querySelector('.item-icon-label') as HTMLDivElement;

		if (filter.backgroundColor) {
			N_ItemIconLabel.style.backgroundColor = filter.backgroundColor;
		}

		N_div.appendChild(N_containerInput);

		const N_input = N_containerInput.querySelector<HTMLInputElement>('input')!;

		N_input.addEventListener('change', () => {
			this.values[item.column!] = N_input.checked ? filter.value : '';
			this.setFilters(item.column!);
		});

		return N_div;
	}

	private initSwitch3(item: ConfigItem) {
		this.values[item.column!] = '';

		const N_div = document.createElement('div');
		N_div.id = item.id!;

		const filter = item.filters![0];

		filter.id = filter.id || _.uniqueId(Date.now().toString(36) + '_');

		filter.iconName = filter.iconName || FilterButton.defaultIcon;

		let iconStyle = '';

		// On met une légère ombre pour distinguer les pastilles blanches sur fond blanc
		if (filter.iconName === 'icon-solid-circle' && (filter.iconColor === 'white' || filter.iconColor?.toLowerCase() === '#ffffff')) {
			iconStyle += 'filter: drop-shadow(0px 0px 1px #050505);';
		}

		const N_containerInput = document.createElement('div');
		N_containerInput.classList.add('item');
		N_containerInput.innerHTML = `
			<div class="item-icon-label">
				<i class="mr-2 icon ${filter.iconName} text-${filter.iconColor} text-md ${filter.iconColor ? '' : 'invisible'} ${filter.hideIcon ? 'd-none' : ''}" ${iconStyle}></i>
				<label class="item-label" for="${filter.id}">${filter.label}</label>
			</div>
            <ap-switch-3 id="${filter.id}"></ap-switch-3>
        `;

		if (filter.leftIndent) {
			N_containerInput.classList.add('ml-4');
		}

		const N_ItemIconLabel = N_containerInput.querySelector('.item-icon-label') as HTMLDivElement;

		if (filter.backgroundColor) {
			N_ItemIconLabel.style.backgroundColor = filter.backgroundColor;
		}

		const N_input = N_containerInput.querySelector(`#${filter.id}`) as CE_Switch3;

		N_input.addEventListener('change', () => {
			if (N_input.value === -1) {
				this.values[item.column!] = '';
			} else {
				this.values[item.column!] = (filter.values || [])[N_input.value];
			}

			this.setFilters(item.column!);
		});

		N_div.appendChild(N_containerInput);

		return N_div;
	}

	public static register() {
		customElements.define(FilterButton.tagName, FilterButton);
	}
}

export default FilterButton;
