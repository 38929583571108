import GenericService from '../GenericService';

class GroupsService extends GenericService {
	private static instance: GroupsService | null = null;

	constructor() {
		super('groups');
	}

	public static getInstance(): GroupsService {
		if (!GroupsService.instance) {
			GroupsService.instance = new GroupsService();
		}

		return GroupsService.instance;
	}

	public displayRefByData(item: { [key: string]: any }) {
		return item.name;
	}
}

export default GroupsService;
