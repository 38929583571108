import _ from 'lodash';

import GenericService, { getAllData } from '../GenericService';

import Utils from '@js/libs/utils/Utils';

class UserService extends GenericService {
	private static instance: UserService | null = null;

	constructor() {
		super('users');
	}

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}

		return UserService.instance;
	}

	public async getAllWithDelete() {
		return await this.get_function('getAllWithDelete');
	}

	public async getDataToAgGridWithDelete(abortController?: AbortController): Promise<getAllData> {
		return await this.get_function('gridWithDelete', {
			user: Utils.userID
		}, abortController?.signal);
	}

	public async getQuotesManagerToSelect2() {
		return await this.get_function('quotesManagerToSelect2');
	}

	public displayRefByData(item: { [key: string]: any }) {
		return `${item.lastname.toUpperCase()} ${_.upperFirst(item.firstname)}`;
	}

	public async restore(id: string) {
		return await this.post_function('restore', { id });
	}
}

export default UserService;
