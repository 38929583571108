import axios from 'axios';

class ChangelogService {
	private static instance: ChangelogService | null = null;

	protected baseURI: string = '/api/changelog';

	public static getInstance(): ChangelogService {
		if (!ChangelogService.instance) {
			ChangelogService.instance = new ChangelogService();
		}

		return ChangelogService.instance;
	}

	public async getAll(): Promise<string> {
		const result = await axios.get(this.baseURI);
		return result.data;
	}
}

export default ChangelogService;
