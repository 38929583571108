import '@css/customElements/alert.scss';

import h from 'hyperscript';
import moment from 'moment';

import CE_Aggrid from '@libs/customElement/AgGrid';

import '@css/customElements/files-edit.scss';
import Utils from '../utils/Utils';

import S_User from '@services/User/UserService';

class FilesEdit extends HTMLElement {
	public static readonly tagName: string = 'ap-files-edit';
	private static index = 0;

	private users: { [key: string]: any } = {};

	private N_grid: CE_Aggrid | null = null;

	public connectedCallback() {
		this.innerHTML = `
			<div class="title-files-edit">Fichiers</div>
			<div class="upload-file">
				<input type="file" id="file-edit_${FilesEdit.index}" class="file"/>
				<label for="file-edit_${FilesEdit.index}">
					<ap-icon name="upload/line"></ap-icon>
					<div>Cliquer pour parcourir ou glisser et déposer vos fichiers</div>
				</label>
			</div>
			<ap-aggrid id="grid" mode="edit"></ap-aggrid>
		`;

		FilesEdit.index++;

		this.initEvent();
		this.initGriOptions();
	}

	private initEvent() {
		const N_File = this.querySelector<HTMLInputElement>('.file')!;
		const N_FileLabel = this.querySelector<HTMLLabelElement>('label')!;

		N_File.addEventListener('change', async () => {
			const reader = new FileReader();

			reader.addEventListener('load', async (e) => {
				const fileReader = e.target as FileReader;

				if (N_File.files) {
					const name = N_File.files[0].name;

					const newData = {
						name,
						user: Utils.userID,
						date: Date.now(),
						_attachments: {
							[name]: {
								content_type: N_File.files[0].name,
								data: btoa((fileReader.result) as string)
							}
						}
					};

					this.N_grid?.addValues([newData]);

					N_File.value = '';
				}
			});

			if (N_File.files) {
				reader.readAsBinaryString(N_File.files[0]);
			}
		});

		N_FileLabel.addEventListener('dragover', (e) => {
			e.stopPropagation();
			e.preventDefault();
			N_FileLabel.classList.add('dragover');
		});
		N_FileLabel.addEventListener('dragleave', (e) => {
			e.stopPropagation();
			e.preventDefault();
			N_FileLabel.classList.remove('dragover');
		});
		N_FileLabel.addEventListener('dragend', (e) => {
			e.stopPropagation();
			e.preventDefault();
			N_FileLabel.classList.remove('dragover');
		});

		N_FileLabel.addEventListener('drop', (e) => {
			e.preventDefault();
			N_FileLabel.classList.remove('dragover');

			const droppedFiles = e.dataTransfer?.files;

			const fileReader = e.target as FileReader;

			if (droppedFiles && droppedFiles[0]) {
				const name = droppedFiles[0].name;

				const newData = {
					name,
					user: Utils.userID,
					date: Date.now(),
					_attachments: {
						[name]: {
							content_type: droppedFiles[0].name,
							data: btoa((fileReader.result) as string)
						}
					}
				};

				this.N_grid?.addValues([newData]);

				N_File.value = '';
			}
		});
	}

	private initGriOptions() {
		this.N_grid = this.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			columnDefs: [{
				headerName: 'Nom du fichier',
				field: 'name',
				minWidth: 250
			}, {
				headerName: 'Personne',
				field: 'user',
				suppressSizeToFit: true,
				width: 250,
				cellRenderer: (params) => {
					if (this.users[params.value]) {
						return S_User.getInstance().displayRefByData(this.users[params.value]);
					} else {
						return 'Utilisateur introuvable';
					}
				}
			}, {
				headerName: 'Date',
				field: 'date',
				suppressSizeToFit: true,
				width: 150,
				cellRenderer: (params) => {
					return moment(params.value).format('DD/MM/YYYY HH:mm');
				}
			}, {
				headerName: '',
				width: 70,
				suppressSizeToFit: true,
				suppressMovable: true,
				pinned: 'right',
				cellRenderer: (params) => {
					const N_delete = h('ap-button.btn-action-aggrid', { attrs: { confirmation: 'true', type: 'delete' } });

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_delete);

					return N_div;
				}
			}]
		});
	}

	public async init() {
		const data = await S_User.getInstance().getAllWithDelete();

		for (const item of data) {
			this.users[item._id] = item;
		}
	}

	public set value(data: { [key: string]: any }[]) {
		this.N_grid!.value = data;
	}

	public set disabled(value: boolean) {
		const N_file = this.querySelector<HTMLInputElement>('.file')!;
		N_file.disabled = value;
	}

	public get value() {
		return this.N_grid!.value;
	}

	public static register() {
		customElements.define(FilesEdit.tagName, FilesEdit);
	}
}

export default FilesEdit;
