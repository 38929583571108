import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_modal from '../../../tpl/modals/groupsProducts/addLineGroupsProducts.html';

import S_Product from '@services/Product/ProductService';

import CE_Select from '@libs/customElement/Select';

class AddLine extends Modal {
	private form: Form | null = null;

	private N_product: CE_Select | null = null;

	constructor(data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.N_product = this.element.querySelector('[name="id_products"]') as CE_Select;

			this.init();

			const N_form = this.element.querySelector('form') as HTMLFormElement;

			this.form = new Form(N_form);

			data.quantity = data.quantity || 1;

			if (data.id_products) {
				data.id_products = await S_Product.getInstance().getDataToSelect2ByID(data.id_products);
			}

			this.N_product.value = data.id_products;

			this.form.setData(data);

			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				const dataToResolve = {
					...this.form!.getData(),
					id_products: this.N_product?.value
				};
				this.resolve(dataToResolve);
			});

			this.updateButtonSave();
		});
	}

	private init() {
		this.N_product!.options = {
			button: {
				add: false
			},
			ajax: {
				url: S_Product.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search
					};
				}
			}
		};
	}

	private postInit() {
		const N_quantity = this.element.querySelector('[name="quantity"]') as HTMLInputElement;

		this.N_product?.addEventListener('change', () => {
			this.updateButtonSave();
		});

		N_quantity.addEventListener('change', () => {
			this.updateButtonSave();
		});
	}

	private updateButtonSave() {
		const data = this.form!.getData();

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !this.N_product?.value || data.quantity === 0;
	}
}

export default AddLine;
