class PageTabsMenuTitle extends HTMLElement {
	public static readonly tagName: string = 'ap-page-tabs-menu-title';

	private icon: string = '';
	private iconActive: string = '';

	public connectedCallback() {
		this.icon = this.getAttribute('icon') as string;
		this.iconActive = this.getAttribute('icon-active') as string;

		const text = this.getAttribute('text') || 'Menu';

		this.innerHTML = `<ap-icon name="${this.icon}" class="menu-icon"></ap-icon><ap-icon name="${this.iconActive}" class="menu-active-icon"></ap-icon>${text}`;

		this.removeAttribute('icon');
		this.removeAttribute('text');
	}

	public static register() {
		customElements.define(PageTabsMenuTitle.tagName, PageTabsMenuTitle);
	}
}

export default PageTabsMenuTitle;
