import GenericService from '../GenericService';

class BillingRequestService extends GenericService {
	private static instance: BillingRequestService | null = null;

	constructor() {
		super('billing-requests');
	}

	public static getInstance(): BillingRequestService {
		if (!BillingRequestService.instance) {
			BillingRequestService.instance = new BillingRequestService();
		}

		return BillingRequestService.instance;
	}

	public getByOrder(idOrder: string) {
		return this.get_function('getByOrder', { idOrder });
	}

	public getByBill(idBill: string) {
		return this.get_function('getByBill', { idBill });
	}
}

export default BillingRequestService;
