import { Moment } from 'moment';
import _ from 'lodash';

import Form from '@libs/FormControllerPageID';

import M_AdditionalInformations from '../../modals/AddQuote/editPage/AdditionalInformations';
import M_BusinessQuote from '../../modals/AddQuote/editPage/BusinessQuote';
import M_Details from '../../modals/AddQuote/editPage/Details';
import M_GeneralInformations from '../../modals/AddQuote/editPage/GeneralInformations';

import T_QuoteInformations from '../../../tpl/customElement/quoteInformations.html';

import S_C_Address from '@services/Customer/CustomerAddressService';

import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';
import QuoteData from '../QuoteData';

class QuoteInformations extends HTMLElement {
	public static readonly tagName: string = 'ap-quote-informations';

	private _isLock: boolean = false;
	private form: Form | null = null;
	private _order: { [key: string]: string } = {};

	public async connectedCallback() {
		this.innerHTML = T_QuoteInformations;

		this.form = new Form(this);

		this.init();
	}

	public set data(data: { [key: string]: any }) {
		this._order = data.order;

		const N_numberCommand = this.querySelector('#numberCommand') as HTMLDivElement;
		N_numberCommand!.innerHTML = this._order?.value || 'Aucune commande';

		this.setDataForm(data.data);
		this.updateRelunchEnable();
	}

	public get data() {
		return this.form!.getData();
	}

	public set isLock(value: boolean) {
		const N_edit_GeneralInformation = this.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_BusinessQuote = this.querySelector('[data-edit="businessQuote"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = value;
		N_edit_BusinessQuote.disabled = value;
		N_edit_AdditionalInformation.disabled = value;

		this._isLock = value;
	}

	private init() {
		const N_edit_GeneralInformation = this.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_BusinessQuote = this.querySelector('[data-edit="businessQuote"]') as HTMLButtonElement;
		const N_edit_Details = this.querySelector('[data-edit="details"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			if (!this._isLock) {
				this.openGeneralInformation();
			}
		});

		N_edit_BusinessQuote.addEventListener('click', () => {
			if (!this._isLock) {
				this.openBusinessQuote();
			}
		});

		N_edit_Details.addEventListener('click', () => {
			const res = {
				infos: {
					number: this.form?.getDataByName('infos.number') as string,
					numberCommand: this.form?.getDataByName('infos.numberCommand') as string,
					state: this.form?.getDataByName('infos.state') as number,
					date: this.form?.getDataByName('infos.date') as Moment,
					sendDate: this.form?.getDataByName('infos.sendDate') as Moment
				},
				states: QuoteData.LIST_STATE,
				order: this._order.value
			};

			new M_Details(res).open().then((data) => {
				this.setDataForm(data);
				this.dispatchEvent(new CustomEvent('update.saveButton'));
				this.dispatchEvent(new CustomEvent('update.title'));
			});
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			if (!this._isLock) {
				const res = {
					infos: {
						customer: this.form?.getDataByName('infos.customer') as string,
						addressID: this.form?.getDataByName('infos.addressID') as string,
						fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
						comments: this.form?.getDataByName('infos.comments') as string,
						validityType: this.form?.getDataByName('infos.validityType') as string,
						validityDate: this.form?.getDataByName('infos.validityDate') as Moment,
						commentFinish: this.form?.getDataByName('infos.commentFinish') as string,
						relunch: {
							type: this.form?.getDataByName('infos.relunch.type') as string,
							date: this.form?.getDataByName('infos.relunch.date') as Moment,
							disabled: this.form?.getDataByName('infos.relunch.disabled') as boolean
						},
						sendDate: this.form?.getDataByName('infos.sendDate') as Moment

					}
				};

				new M_AdditionalInformations(res).open().then((data) => {
					this.setDataForm(data);
					this.updateRelunchEnable();
					this.dispatchEvent(new CustomEvent('update.saveButton'));
				});
			}
		});
	}

	public openGeneralInformation(isFirstOpen: boolean = false) {
		const res = {
			infos: {
				number: this.form?.getDataByName('infos.number') as string,
				customer: this.form?.getDataByName('infos.customer') as string,
				contact: this.form?.getDataByName('infos.contact') as string,
				email: this.form?.getDataByName('infos.email') as string,
				manager: this.form?.getDataByName('infos.manager') as string,
				date: this.form?.getDataByName('infos.date') as Moment
			}
		};

		let modal: M_GeneralInformations;

		if (isFirstOpen) {
			modal = new M_GeneralInformations(res).setNextCallback((data) => {
				this.setDataForm(data);
				this.openBusinessQuote(isFirstOpen);
			});
		} else {
			modal = new M_GeneralInformations(res);
		}

		modal.open().then(async (data) => {
			this.setDataForm(data);

			if (res.infos.customer !== data.infos.customer.id) {
				await this.updateCustomerAddress();
			}

			this.dispatchEvent(new CustomEvent('update.title'));

			if (!isFirstOpen) {
				this.dispatchEvent(new CustomEvent('update.saveButton'));
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.dispatchEvent(new CustomEvent('return'));
			}
		});
	}

	private openBusinessQuote(isFirstOpen: boolean = false) {
		const res = {
			infos: {
				customer: this.form?.getDataByName('infos.customer') as string,
				label: this.form?.getDataByName('infos.label') as string,
				hasFinalCustomer: this.form?.getDataByName('infos.hasFinalCustomer') as boolean,
				finalCustomer: this.form?.getDataByName('infos.finalCustomer') as string,
				sites: this.form?.getDataByName('infos.sites') as string[],
				siteCustom: this.form?.getDataByName('infos.siteCustom') as string,
				description: this.form?.getDataByName('infos.description') as string
			}
		};

		let modal: M_BusinessQuote;

		if (isFirstOpen) {
			modal = new M_BusinessQuote(res).setPreviousCallback(() => {
				this.openGeneralInformation(isFirstOpen);
			});
		} else {
			modal = new M_BusinessQuote(res);
		}

		modal.open().then((data) => {
			const N_sites = this.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
			N_sites.update(data.infos.hasFinalCustomer);

			this.setDataForm(data);
			this.dispatchEvent(new CustomEvent('update.saveButton'));
			this.dispatchEvent(new CustomEvent('update.title'));
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.dispatchEvent(new CustomEvent('return'));
			}
		});
	}

	private async updateCustomerAddress() {
		const customer = this.form?.getDataByName('infos.customer');
		const addresses = await S_C_Address.getInstance().getByCustomerToSelect2(customer as string);

		if (addresses.length && customer) {
			const fullAddress = await S_C_Address.getInstance().getFullAddress(addresses[0]?.id);
			this.form?.setDataByName('infos.addressID', addresses[0]);
			this.form?.setDataByName('infos.fullAddress', fullAddress);
		} else {
			this.form?.setDataByName('infos.addressID', { id: '', text: '' });
			this.form?.setDataByName('infos.fullAddress', '');
		}
	}

	private updateRelunchEnable() {
		const data = this.form?.getDataByName('infos.relunch.disabled') as boolean;
		const N_container_relunch = this.querySelector('#container_relunch') as HTMLElement;

		N_container_relunch.classList.toggle('disabled', data);
	}

	protected convertData(data: { [key: string]: any; }) {
		if (data.infos?.state) {
			data.infos.state = {
				id: data.infos.state,
				text: QuoteData.LIST_STATE[data.infos.state]
			};
		}

		if (data.infos?.validityType) {
			data.infos.validityType = {
				id: data.infos.validityType,
				text: QuoteData.DURATION_TYPES[data.infos.validityType]
			};
		}

		if (data.infos?.relunch?.type) {
			data.infos.relunch.type = {
				id: data.infos.relunch.type,
				text: QuoteData.DURATION_TYPES[data.infos.relunch.type]
			};
		}

		return data;
	}

	protected setDataForm(data: { [key: string]: any }) {
		data = _.cloneDeep(data);
		data = this.convertData(data);
		this.form?.setData(data);
	}

	public static register() {
		customElements.define(QuoteInformations.tagName, QuoteInformations);
	}
}

export default QuoteInformations;
