import { Module, Modules } from '@autoprog/core-client';

import C_SettingsNotification from './js/controllers/Settings.Notification';
import T_SettingsNotification from './tpl/settings.Notification.html';

import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

class SettingsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addCategory({
			id: 'general',
			order: 0
		});

		settingsRegister.addLink({
			Controller: C_SettingsNotification,
			tpl: T_SettingsNotification,
			icon: 'notification-3/line',
			order: 1,
			category: 'general',
			name: 'Notifications'
		});
	}

	public async init() {
	}

	public async postInit() {
	}
}

Modules.registerModule('Notifications', SettingsModule);
