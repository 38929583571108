import Decimal from '@libs/utils/Decimal';

type tvaItem = {
	id: string
	value: number
	label: string
	default?: boolean
};

class TVA extends HTMLElement {
	public static readonly tagName: string = 'ap-tva';

	public static data: tvaItem[] = [{
		id: '20',
		label: '20%',
		default: true,
		value: 20
	}, {
		id: '10',
		label: '10%',
		value: 10
	}, {
		id: '5.5',
		label: '5,5%',
		value: 5.5
	}, {
		id: '2.1',
		label: '2,1%',
		value: 2.1
	}, {
		id: '-1',
		label: 'Non soumis à la TVA',
		value: 0
	}];

	public async connectedCallback() {
		const name = this.getAttribute('name');
		const required = this.getAttribute('required');

		this.classList.add('w-100');

		this.innerHTML = `
            <div class="input-group">
                <select name="${name}" class="form-control text-right" ${required ? 'required' : ''}>
                    ${this.getOptions()}
                </select>
                <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div>
            </div>
        `;

		this.setAttribute('name', '');
	}

	private getOptions() {
		let html = '';

		for (const item of TVA.data) {
			html += `<option value="${item.id}">${item.label}</option>`;
		}

		return html;
	}

	public static getDefaultID() {
		for (const item of TVA.data) {
			if (item.default) {
				return item.id;
			}
		}

		return '20';
	}

	public static convertValueToCalc(id: string) {
		const item = TVA.data.find(item => item.id === id);

		if (item) {
			const value = Decimal.setDisplayNumber(item.value).dividedBy(100);
			return new Decimal(1).plus(value);
		}

		return Decimal.setDisplayNumber(0);
	}

	public static getDataToSelect2(id: string) {
		const item = TVA.data.find(item => item.id === id);

		return {
			id: item?.id,
			text: item?.label
		};
	}

	public static register() {
		customElements.define(TVA.tagName, TVA);
	}
}

export default TVA;
