module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-mobile">\n\n	<div style="min-width: 70px;">\n		';
 if(data.url_photo){;
__p += '\n			<div class="h-100 w-100"\n				style="background-image: url(\'' +
((__t = (data.base64_photo || data.url_photo)) == null ? '' : __t) +
'\'); background-size: contain; background-repeat: no-repeat; background-position: center;">\n			</div>\n		';
}else{;
__p += '\n			<div\n				class="align-items-center bg-light d-flex h-100 h4 justify-content-center text-grey-300">\n				N/A\n			</div>\n		';
};
__p += '\n	</div>\n\n	<div class="flex-grow-1 pl-2">\n\n		<div class="d-flex font-weight-bold text-blue-grey-900">\n			' +
((__t = (data.internalCode || '&nbsp;')) == null ? '' : __t) +
'\n		</div>\n		<div class="text-xs mt-2">\n			Référence : ' +
((__t = (data.reference || '&nbsp;')) == null ? '' : __t) +
'\n		</div>\n		<div class="text-md mt-2">\n			' +
((__t = (data.brand || '&nbsp;')) == null ? '' : __t) +
'\n		</div>\n		\n		<div class="mt-2">\n			' +
((__t = (data.name || '&nbsp;')) == null ? '' : __t) +
'\n		</div>\n\n	</div>\n</div>';

}
return __p
}