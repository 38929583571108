import ConfigManager from '@libs/ConfigManager';

import _ from 'lodash';

import T_C_address from '@tpl/modals-blocks/customers-address.ejs';
import T_C_contacts from '@tpl/modals-blocks/customers-contacts.ejs';
import T_Customers from '@tpl/modals-blocks/customers.ejs';
import T_P_address from '@tpl/modals-blocks/providers-address.ejs';
import T_P_contacts from '@tpl/modals-blocks/providers-contacts.ejs';
import T_Providers from '@tpl/modals-blocks/providers.ejs';
import T_S_contacts from '@tpl/modals-blocks/sites-contacts.ejs';
import T_Sites from '@tpl/modals-blocks/sites.ejs';
import T_Stock from '@tpl/modals-blocks/stock.ejs';

type ejs = (variables: { [key: string]: any; }) => string;
class TemplateModalManager {
	private static cache: Map<string, ejs> = new Map<string, ejs>();

	public static init() {
		this.cache.set('customers-address', T_C_address);
		this.cache.set('customers-contacts', T_C_contacts);
		this.cache.set('customers', T_Customers);
		this.cache.set('providers-address', T_P_address);
		this.cache.set('providers-contacts', T_P_contacts);
		this.cache.set('providers', T_Providers);
		this.cache.set('sites-contacts', T_S_contacts);
		this.cache.set('sites', T_Sites);
		this.cache.set('stock', T_Stock);
	}

	public static get(table: string, mode: string = '') {
		let res = '';

		const config = ConfigManager.getInstance().getConfig(table);

		const template = this.cache.get(table);

		if (template) {
			res = template({
				config,
				mode
			});
		} else {
			res = TemplateModalManager.getDefaultTemplate(config);
		}

		return `<form class="d-flex flex-column h-100">${res}</form>`;
	}

	private static getDefaultTemplate(config: { [key: string]: any }) {
		let html = '';

		for (const item of (config.columns || [])) {
			const id = _.uniqueId(Date.now().toString(36));

			if (item.type === 'string' || (item.type === 'primaryKey' && (config.columns || []).length === 1)) {
				if (item.isTextarea) {
					html += `  
                        <div class="form-group">
                            <label for="${id}">${item.name}</label>
                            <textarea  class="form-control" name="${item.key}" id="${id}" ${item.disabled ? 'disabled' : ''}></textarea>
                        </div>
                    `;
				} else {
					html += `  
                        <div class="form-group">
                            <label for="${id}">${item.name}</label>
                            <input type="text" name="${item.key}" class="form-control" id="${id}" ${item.disabled ? 'disabled' : ''}>
                        </div>
                    `;
				}
			}

			if (item.type === 'date') {
				html += `  
                    <div class="form-group">
                        <label for="${id}">${item.name}</label>
                        <input type="date" name="${item.key}" class="form-control" id="${id}" ${item.disabled ? 'disabled' : ''}>
                    </div>
                `;
			}

			if (item.type === 'file') {
				if (item.multiple) {
					html += `
                        <div class="file">
                            <ap-files-tab data-display-tab="false"></ap-files-tab>
                        </div>
                    `;
				}
			}

			if (item.type === 'boolean') {
				html += `
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" name="${item.key}" class="custom-control-input" id="${id}" ${item.disabled ? 'disabled' : ''}>
                        <label class="custom-control-label" for="${id}">${item.name}</label>
                    </div>
                `;
			}

			if (item.type === 'table') {
				if (item.isAgGrid) {
					html += `
                        <div>
                            ${item.name}
                            <div style="height : 500px">
                                <div class="h-100 ag-theme-alpine grid" data-table="${item.table}" data-key-ref="${item.keyRef}" name="${item.key}"></div>
                            </div>
                        </div>
                    `;
				} else {
					html += `
                        <div class="form-group">
                            <label for="${id}">${item.name}</label>
                            <select class="form-control select2" data-table="${item.table}" name="${item.key}" id="${id}" ${item.disabled ? 'disabled' : ''}  ${item.isMultiple ? 'multiple' : ''}></select>
                        </div>
                    `;
				}
			}

			if (item.type === 'array') {
				html += `
                    <div class="form-group">
                        <label for="${id}">${item.name}</label>
                        <select name="${item.key}" class="form-control" id="${id}" ${item.disabled ? 'disabled' : ''}>
                `;

				item.array = item.array || [];

				for (const value of item.array) {
					html += `<option value="${value}">${value}</option>`;
				}

				html += `
                        </select>
                    </div>
                `;
			}

			if (item.type === 'object') {
				html += `
                    <div class="form-group">
                        <label for="${id}">${item.name}</label>
                        <select name="${item.key}" class="form-control" id="${id}" ${item.disabled ? 'disabled' : ''}>
                `;

				item.object = item.object || {};

				for (const key in item.object) {
					html += `<option value="${key}">${item.object[key]}</option>`;
				}

				html += `
                        </select>
                    </div>
                `;
			}
		}

		return html;
	}

	public static getTitle(data: { [key: string]: any }, table: string) {
		const config = ConfigManager.getInstance().getConfig(table);

		const listTable: { [key: string]: any } = {
			products: 'Produit',
			'products-provider': 'Produit fournisseur',
			'products-stocks': 'Produit stock',
			'products-groups': 'Groupe produits',
			customers: 'Client',
			'customers-address': 'Adresse client',
			'customers-contacts': 'Contact client',
			providers: 'Fournisseur',
			'providers-address': 'Adresse fournisseur',
			'providers-contacts': 'Contact fournisseur',
			'commands-customer': 'Commande client',
			'commands-provider': 'Commande fournisseur',
			bills: 'Facture client',
			'bills-providers': 'Facture fournisseur',
			sites: 'Site',
			stock: 'Stock',
			projects: 'Projet',
			materials: 'Suivi matérial',
			'sites-projects': 'Projet',
			'sites-contacts': 'Contact site',
			receipts: 'Bon de réception',
			transporters: 'Transporteur',
			brands: 'Marque',
			categories: 'Catégorie'
		};

		const cloneData = _.cloneDeep(data);

		for (const col of config.columns) {
			if (col.autoIncrement) {
				delete cloneData[col.key];
			}
		}

		let title = _.isEmpty(cloneData) ? 'Création' : 'Edition';

		title += ' ' + (listTable[table]?.toLowerCase() || table?.toLowerCase());

		if (config.modal && config.modal.addonTitle) {
			const valAddon = _.get(data, config.modal.addonTitle);

			if (valAddon) {
				title += ` ( ${valAddon} ) `;
			}
		}

		return title;
	}
}

export default TemplateModalManager;
