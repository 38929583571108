import GenericService from '../GenericService';

class FavoriteQuoteService extends GenericService {
	private static instance: FavoriteQuoteService | null = null;

	constructor() {
		super('quotes-favorite');
	}

	public static getInstance(): FavoriteQuoteService {
		if (!FavoriteQuoteService.instance) {
			FavoriteQuoteService.instance = new FavoriteQuoteService();
		}

		return FavoriteQuoteService.instance;
	}
}

export default FavoriteQuoteService;
