// CORE

// NODE_MODULE
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
import T_Modal from '../../../tpl/modals/AddQuote/SelectAddQuote.html';

// LIBS
import AgGridStateSaver from '@libs/agGrid/StateSaver';
import Modal from '@libs/Modal';
import UnitCellRenderer from '@modules/Units/js/libs/UnitCellrenderer';

// CUSTOM_ELEMENT
import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_GridSidebar from '@libs/customElement/GridSidebar';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Quote from '@services/QuoteService';

class SelectQuote extends Modal {
	private id: string = '';

	private N_gridGroup: CE_Aggrid | null = null;
	private N_gridQuotes: CE_Aggrid | null = null;

	private selectedQuotes: { [key: string]: boolean } = {};
	private selectedGroups: any[] = [];

	constructor(id?: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id = id || '';

		this.on('opened', () => {
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_selectAll = this.element.querySelector('#selectAll') as HTMLButtonElement;
			const N_unselectAll = this.element.querySelector('#unselectAll') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				let index = 0;

				for (const item of this.selectedGroups) {
					if (item.type !== 'fullLine') {
						item._idGrid = Date.now().toString(36) + '_' + index;

						for (const detail of item.details) {
							if (detail.reference) {
								try {
									const product = await S_Product.getInstance().getByIdWithProvider(detail.reference);

									if (product.price) {
										detail.price = product.price;
									}

									if (product.comptaVente) {
										detail.comptaVente = product.comptaVente;
									}

									if (product.defaultProvider) {
										detail.costPrice = product.defaultProvider.costPrice.value.value;
									}

									if (product.label) {
										detail.label = product.label;
									}
								} catch (e) {

								}
							}
						}

						index++;
					}
				}

				this.resolve(this.selectedGroups);
			});

			N_selectAll.addEventListener('click', () => {
				this.N_gridGroup?.api?.forEachNode((node) => {
					if (node.data.type !== 'fullLine') {
						node.setSelected(true);
					}
				});
			});

			N_unselectAll.addEventListener('click', () => {
				this.N_gridGroup?.api?.forEachNode((node) => {
					node.setSelected(false);
				});
			});

			this.initGroup();
			this.initQuote();

			this.initSwapGrid();

			this.initSearch();

			this.setData();
		});
	}

	private initSearch() {
		const N_search = this.element.querySelector('#search') as HTMLInputElement;

		N_search.addEventListener('input', () => {
			this.N_gridQuotes?.api?.setQuickFilter(N_search.value);
		});
	}

	private initGroup() {
		this.N_gridGroup = this.element.querySelector<CE_Aggrid>('#grid-group')!;

		this.N_gridGroup.setGridOptions({
			suppressContextMenu: true,
			rowMultiSelectWithClick: true,
			rowSelection: 'multiple',
			columnDefs: [
				{
					checkboxSelection: (params) => {
						return params.data.type !== 'fullLine';
					},
					suppressSizeToFit: true,
					pinned: 'left',
					toolPanelClass: 'd-none',
					width: 40
				},
				{
					headerName: 'Catégorie',
					suppressSizeToFit: true,
					width: 200,
					field: 'category',
					colSpan: (params) => {
						return params.data.type === 'fullLine' ? 5 : 1;
					},
					cellClass: (params) => {
						return params.data.type === 'fullLine' ? ['text-center', 'h5'] : [];
					},
					cellRenderer: (params) => {
						if (params.data.type === 'fullLine') {
							return params.data.data;
						}

						return params.value;
					}
				},
				{
					headerName: 'Sous-Catégorie',
					suppressSizeToFit: true,
					width: 130,
					field: 'subCategory'
				},
				{
					headerName: 'Nom',
					cellClass: 'cell-wrap-text',
					autoHeight: true,
					field: 'name',
					cellRenderer: (params) => {
						const value = (params.value || '').split('\n');
						let result = '';

						for (const item of value) {
							result += `<div style="line-height: 17px">${item || '&nbsp;'}</div>`;
						}

						return `<div style="margin-top:5px; margin-bottom:5px;" >${result}</div>`;
					}
				},
				{
					headerName: 'Qte',
					suppressSizeToFit: true,
					width: 70,
					field: 'quantity'
				},
				{
					headerName: 'U',
					suppressSizeToFit: true,
					width: 50,
					field: 'unit',
					cellRenderer: UnitCellRenderer
				}
			],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			onRowSelected: (params) => {
				this.selectedGroups = params.api?.getSelectedRows();

				this.updateSaveButton();
			},
			onGridReady: async (params) => {
				const data = await AgGridStateSaver.getValue('select-quote-group');

				delete data.filters;

				const stateSaver = new AgGridStateSaver(params, 'select-quote-group');
				stateSaver.setData(data);
			}
		});
	}

	private initQuote() {
		this.N_gridQuotes = this.element.querySelector<CE_Aggrid>('#grid-quote')!;

		this.N_gridQuotes.setGridOptions({
			rowSelection: 'multiple',
			columnDefs: [
				{
					checkboxSelection: true,
					suppressSizeToFit: true,
					pinned: 'left',
					toolPanelClass: 'd-none',
					width: 40
				},
				{
					headerName: 'Numéro',
					suppressSizeToFit: true,
					field: 'infos.number'
				},
				{
					headerName: 'Client',
					field: 'infos.customer',
					suppressSizeToFit: true
				},
				{
					headerName: 'Objet',
					field: 'infos.label'
				},
				{
					headerName: 'Date',
					suppressSizeToFit: true,
					field: 'infos.date',
					cellRenderer: (params) => {
						return moment(params.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
					}
				},
				{
					headerName: 'Site',
					suppressSizeToFit: true,
					field: 'infos.sites'
				},
				{
					headerName: 'Contact',
					suppressSizeToFit: true,
					field: 'infos.contact'
				},
				{
					headerName: 'Montant (€)',
					suppressSizeToFit: true,
					cellClass: 'text-right text-monospace',
					field: 'globalPrice',
					valueGetter: (params) => {
						if (params.data.globalPrice) {
							return params.data.globalPrice.value;
						}
					},
					cellRenderer: (params) => {
						return params.data.globalPrice.formattedValue;
					}
				}
			],
			rowMultiSelectWithClick: true,
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				suppressMenu: true,
				resizable: true
			},
			onModelUpdated: (params) => {
				params.api.forEachNode((node) => {
					if (node.data) {
						node.setSelected(!!this.selectedQuotes[node.data._id]);
					}
				});
			},
			onRowSelected: (params) => {
				if (params.node.isSelected()) {
					this.selectedQuotes[params.node.data._id] = true;
				} else {
					delete this.selectedQuotes[params.node.data._id];
				}

				this.updateSaveButton();
			},
			onGridReady: async (params) => {
				const data = await AgGridStateSaver.getValue('select-quote-quote');

				delete data.filters;

				const stateSaver = new AgGridStateSaver(params, 'select-quote-quote');
				stateSaver.setData(data);
			}

		});

		const N_GridSidebar = this.element.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.N_gridQuotes.gridOptions);
	}

	private updateSaveButton() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;
		const N_next = this.element.querySelector('#next') as HTMLButtonElement;

		N_save.disabled = this.selectedGroups.length === 0;
		N_next.disabled = Object.keys(this.selectedQuotes).length === 0;
	}

	private async setData() {
		const { rowData } = await S_Quote.getInstance().getDataToAgGrid();
		if (this.id) {
			const index = _.findIndex(rowData, { _id: this.id });
			rowData.splice(index, 1);
		}

		this.N_gridQuotes!.value = rowData;
	}

	private initSwapGrid() {
		const N_gridQuote = this.element.querySelector('#container-grid-quote') as HTMLElement;
		const N_gridGroup = this.element.querySelector('#container-grid-group') as HTMLElement;

		const N_next = this.element.querySelector('#next') as HTMLButtonElement;
		const N_back = this.element.querySelector('#back') as HTMLButtonElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;
		const N_close = this.element.querySelector('#close') as HTMLElement;

		N_next.addEventListener('click', () => {
			N_gridQuote.classList.add('d-none');
			N_gridQuote.classList.remove('d-flex');
			N_gridGroup.classList.add('d-flex');
			N_gridGroup.classList.remove('d-none');

			N_next.classList.add('d-none');
			N_close.classList.add('d-none');
			N_back.classList.remove('d-none');
			N_save.classList.remove('d-none');

			this.updateGroup();
		});

		N_back.addEventListener('click', () => {
			N_gridQuote.classList.add('d-flex');
			N_gridQuote.classList.remove('d-none');
			N_gridGroup.classList.add('d-none');
			N_gridGroup.classList.remove('d-flex');

			N_next.classList.remove('d-none');
			N_close.classList.remove('d-none');
			N_back.classList.add('d-none');
			N_save.classList.add('d-none');
		});
	}

	private async updateGroup() {
		this.N_gridGroup!.resetValue();

		const rows: { [key: string]: any }[] = [];

		for (const id in this.selectedQuotes) {
			const data = (await S_Quote.getInstance().getDataToModal(id, {
				_typeLoad: 'grid'
			})).data;

			rows.push({
				type: 'fullLine',
				data: data.title
			});

			rows.push(...data.data.data);
		}

		this.N_gridGroup!.value = rows;
		this.N_gridGroup!.api?.resetRowHeights();
	}
}

export default SelectQuote;
