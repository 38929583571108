import GenericService from '../GenericService';

class ToDoQuoteService extends GenericService {
	private static instance: ToDoQuoteService | null = null;

	constructor() {
		super('quotes-to-do');
	}

	public static getInstance(): ToDoQuoteService {
		if (!ToDoQuoteService.instance) {
			ToDoQuoteService.instance = new ToDoQuoteService();
		}

		return ToDoQuoteService.instance;
	}
}

export default ToDoQuoteService;
