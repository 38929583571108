import { LoggedUser, Module, Modules, Router, global } from '@autoprog/core-client';

import C_User from './js/controllers/User';
import T_User from './tpl/user.html';

import Utils from '@libs/utils/Utils';

import C_SettingsUserGroup from './js/controllers/Settings.UserGroup';
import T_SettingsUserGroup from './tpl/settings.UserGroup.html';

import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

import CE_NavbarSeparator from '@js/libs/customElement/navbar/Navbar-separator';
import CE_NavbarUser from './js/customElements/NavbarUser';

import './css/companies.scss';
import './css/user.scss';
import './css/additionalInformations.scss';
import './css/profilePicture.scss';

class ProfileModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/profile/user',
			controller: C_User as any,
			tpl: T_User,
			requireValidUser: true
		});

		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addLink({
			id: 'users',
			Controller: C_SettingsUserGroup,
			tpl: T_SettingsUserGroup,
			icon: 'group/line',
			order: 0,
			category: 'general',
			name: 'Utilisateurs / Groupes'
		});

		CE_NavbarUser.register();
	}

	public async postInit() {
		if (!global.IS_MOBILE) {
			const loggedUser = LoggedUser.getInstance();

			const CE_NavbarSeparator = document.createElement('ap-navbar-separator') as CE_NavbarSeparator;
			const CE_NavbarUser = document.createElement('ap-navbar-user') as CE_NavbarUser;

			const N_NavbarRight = document.querySelector('#navbar-right-content') as HTMLDivElement;
			N_NavbarRight.append(CE_NavbarSeparator, CE_NavbarUser);

			this.refreshAdminMode();

			loggedUser.on('login', () => {
				this.refreshAdminMode();
			});
		}
	}

	private refreshAdminMode() {
		if (this.isModeAdmin()) {
			document.body.classList.add('is-admin');
		} else {
			document.body.classList.remove('is-admin');
		}
	}

	private isModeAdmin() {
		return Utils.userID === '1' && !window.location.host.includes('localhost') && !window.location.host.includes('127.0.0.1');
	}
}

Modules.registerModule('Profil', ProfileModule);
