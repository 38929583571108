import h from 'hyperscript';
import moment from 'moment';

//STYLE
import '../../css/tracking-product-event-card.scss';

//TYPE
import { TrackingProductEvent } from '@modules/TrackingProduct/types/trackingProduct';

class TrackingProductEventCard extends HTMLElement {
	public static readonly tagName: string = 'ap-tracking-product-history-item';
	private event: TrackingProductEvent;

	public constructor(historyItem: TrackingProductEvent) {
		super();
		this.event = historyItem;
	}

	public async connectedCallback() {
		const cardItem = h('div.tracking-product-event-card.mb-2',
			h('div.header',
				h('span.date', `${moment(this.event.date).format('DD/MM/YYYY - HH:mm')}`),
				h('span.user', `par ${this.event.user.fullName}`)
			)
		);

		const content = this.getContent();
		cardItem.appendChild(content);

		this.appendChild(cardItem);
	}

	private getContent(): HTMLDivElement {
		if (this.event.traceability.receipt) {
			const receipt = this.event.traceability.receipt;
			return h('div.content',
				h('p', `Réception ${receipt.infos.number} pour la commande ${receipt.infos.order.infos.number}`),
				h('p', `Fournisseur : ${receipt.infos.order.infos.provider.number} - ${receipt.infos.order.infos.provider.name}`)
			);
		} else if (this.event.traceability.customerOrder) {
			const customerOrder = this.event.traceability.customerOrder;
			return h('div.content',
				h('p', `Commande ${customerOrder.infos.internalNumber}`),
				h('p', `Client : ${customerOrder.infos.customer.number} - ${customerOrder.infos.customer.name}`)
			);
		} else {
			return h('div.content',
				h('p.text-muted', 'Aucune information disponible')
			);
		}
	}

	public static register() {
		customElements.define(TrackingProductEventCard.tagName, TrackingProductEventCard);
	}
}
export default TrackingProductEventCard;
