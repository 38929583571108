class Boolean {
	private value: boolean;

	constructor(value: boolean) {
		this.value = value;
	}

	public toDashboard() {
		return {
			formattedValue: this.value ? '<ap-icon class="text-green" name="check/line"></ap-icon>' : '<ap-icon class="text-red" name="close/line"></ap-icon>',
			value: this.value,
			quickFilter: ''
		};
	}

	public toDocument() {
		return this.value;
	}

	public toSave() {
		return this.value;
	}

	public toPrint() {
		return this.value ? 'OUI' : 'NON';
	}
}

export default Boolean;
