import _ from 'lodash';

export default function (numberStr: string | number, pref: { [key: string]: any }): number {
	pref.decimalNumber = _.isUndefined(pref.decimalNumber) ? 2 : pref.decimalNumber;

	const pow = Math.pow(10, pref.decimalNumber);

	let number = parseFloat(numberStr.toString());
	number = Math.round(number * pow);
	number = parseFloat((number / pow).toString());

	return number;
}
