import { GridOptions } from '@ag-grid-enterprise/all-modules';

import '@css/customElements/button-edit-aggrid.scss';

class AddLine extends HTMLElement {
	public static readonly tagName: string = 'ap-button-aggrid-add-line';

	private _gridOptions: GridOptions = {};
	private _generateData: (index: number) => any = () => { return {}; };

	public connectedCallback() {
		this.classList.add('button-edit-aggrid');

		this.style.setProperty('--max-width', '165px');
		if (this.hasAttribute('disabled')) {
			this.innerHTML = `
			<ap-button class="disabled" id="add-line" tooltip="Ajouter ligne(s)" icon="add/line"></ap-button>

			<div class="container-buttons">
				<ap-button class="disabled" id="line-1" tooltip="Ajouter 1 ligne">1</ap-button>
				<ap-button class="disabled" id="line-5" tooltip="Ajouter 5 lignes">5</ap-button>
				<ap-button class="disabled" id="line-10" tooltip="Ajouter 10 lignes">10</ap-button>
				<ap-button class="disabled" id="line-20" tooltip="Ajouter 20 lignes">20</ap-button>
			</div>
		`;
		} else {
			this.innerHTML = `
			<ap-button id="add-line" tooltip="Ajouter ligne(s)" icon="add/line"></ap-button>

			<div class="container-buttons">
				<ap-button id="line-1" tooltip="Ajouter 1 ligne">1</ap-button>
				<ap-button id="line-5" tooltip="Ajouter 5 lignes">5</ap-button>
				<ap-button id="line-10" tooltip="Ajouter 10 lignes">10</ap-button>
				<ap-button id="line-20" tooltip="Ajouter 20 lignes">20</ap-button>
			</div>
		`;
		}

		const N_line1 = this.querySelector<HTMLElement>('#line-1')!;
		const N_line5 = this.querySelector<HTMLElement>('#line-5')!;
		const N_line10 = this.querySelector<HTMLElement>('#line-10')!;
		const N_line20 = this.querySelector<HTMLElement>('#line-20')!;

		N_line1.addEventListener('click', () => {
			this._gridOptions.api?.applyTransaction({
				add: this.getLines(1)
			});

			this.dispatchEvent(new CustomEvent('update'));
		});

		N_line5.addEventListener('click', () => {
			this._gridOptions.api?.applyTransaction({
				add: this.getLines(5)
			});

			this.dispatchEvent(new CustomEvent('update'));
		});

		N_line10.addEventListener('click', () => {
			this._gridOptions.api?.applyTransaction({
				add: this.getLines(10)
			});

			this.dispatchEvent(new CustomEvent('update'));
		});

		N_line20.addEventListener('click', () => {
			this._gridOptions.api?.applyTransaction({
				add: this.getLines(20)
			});

			this.dispatchEvent(new CustomEvent('update'));
		});
	}

	private getLines(number: number) {
		const result = [];

		for (let i = 0; i < number; i++) {
			result.push(this._generateData(i));
		}

		return result;
	}

	public set gridOptions(gridOptions: GridOptions) {
		this._gridOptions = gridOptions;
	}

	public set generateData(generateData: (index: number) => any) {
		this._generateData = generateData;
	}

	public static register() {
		customElements.define(AddLine.tagName, AddLine);
	}
}

export default AddLine;
