// CORE
// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS

// MODAL
import M_Alerte from '../../../../../Stock/js/modals/Alerte';

// CUSTOM_ELEMENT
// SERVICE

//STYLE
import '../../../../css/quickAccess.scss';

class Stock extends HTMLElement {
	public static readonly tagName: string = 'ap-stock';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `

			
			<div class="d-flex align-items-center text-nowrap quickaccess-card">
				<div class="p-3 w-100">
					<div class="d-flex w-100">
						<div class="d-flex align-items-center">
							<i class="quickaccess-icon stock-icon d-flex align-items-center justify-content-center icon icon-solid-database h2 px-3 py-2"
							style="height: 56px; width: 56px"></i>
						</div>
						<div class="pl-3 pt-3 w-100">
							<div class="d-flex font-weight-bold text-grey-700 h6">Alertes stock</div>
							<div style="font-size: 12px">
								<div class="d-flex w-100 text-red">
									<div>En alerte</div>
									<div class="ml-auto font-weight-bold" data-key="stock.alert"></div>
								</div>
								<div class="d-flex w-100">
									<div>Négatif</div>
									<div class="ml-auto font-weight-bold" data-key="stock.negative"></div>
								</div>
								<div class="d-flex w-100">
									<div class="mr-2">À réapprovisionner</div>
									<div class="ml-auto font-weight-bold" data-key="stock.warning"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="quickaccess-link-icon">
					<i class="icon icon-solid-external-link-alt"></i>
				<div>
			</div>
        `;
	}

	private linkData() {
		const N_stock_negative = this.querySelector('[data-key="stock.negative"]');
		const N_stock_alert = this.querySelector('[data-key="stock.alert"]');
		const N_stock_warning = this.querySelector('[data-key="stock.warning"]');

		N_stock_negative!.innerHTML = this._data.stock.negative;
		N_stock_alert!.innerHTML = this._data.stock.alert;
		N_stock_warning!.innerHTML = this._data.stock.warning;
	}

	private initButton() {
		const N_stock = this.querySelector('.quickaccess-card') as HTMLElement;

		N_stock?.addEventListener('click', () => {
			new M_Alerte().open();
		});
	}

	public set data(data: any) {
		this._data = data;
		this.linkData();
		this.initButton();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(Stock.tagName, Stock);
	}
}

export default Stock;
