import '@css/customElements/switch.scss';

class Switch extends HTMLElement {
	public static readonly tagName: string = 'ap-switch';

	public connectedCallback() {
		const name = this.getAttribute('name') as string;
		const disabled = this.hasAttribute('disabled');

		this.innerHTML = `
			<label class="ap-switch">
				<input type="checkbox" name=${name} ${disabled ? 'disabled' : ''}>
				<span class="slider round"></span>
			</label>
        `;

		const N_input = this.querySelector<HTMLInputElement>('input');
		N_input?.addEventListener('change', () => {
			this.dispatchEvent(new Event('change'));
		});

		this.removeAttribute('name');
	}

	public get checked() {
		const N_input = this.querySelector<HTMLInputElement>('input');
		return N_input!.checked;
	}

	public set checked(value: boolean) {
		const N_input = this.querySelector<HTMLInputElement>('input');
		N_input!.checked = value;
	}

	public set disabled(value: boolean) {
		if (value) {
			this.setAttribute('disabled', '');
		} else {
			this.removeAttribute('disabled');
		}

		const input = this.querySelector<HTMLInputElement>('input');
		if (input) {
			input.disabled = value;
		}
	}

	public static register() {
		customElements.define(Switch.tagName, Switch);
	}
}

export default Switch;
