import EditPageModal from '@js/libs/EditPageModal';

import { taxe } from '../types/taxe';

import T_modal from '../../tpl/modals/addTaxe.html';

import CE_Select from '@libs/customElement/Select';

import TaxeModel from '../model/Taxe';

import AccountsService from '../services/AccountsService';
import TaxesService from '../services/TaxesService';

import '../../css/addTaxe.scss';

class AddTVA extends EditPageModal<Partial<taxe>> {
	private taxe: Partial<taxe> | null;

	constructor(data?: Partial<taxe>) {
		super(data || {}, T_modal);
		this.taxe = data || null;
	}

	protected async setData(data: Partial<taxe>): Promise<void> {
		if (data.accountingAccount) {
			data.accountingAccount = await new AccountsService().getDataToSelect2ByID(data.accountingAccount);
		}

		super.setData(data);
	}

	protected preInit() {
		const N_type = this.element.querySelector<CE_Select>('[name="type"]');
		const N_accountingAccount = this.element.querySelector<CE_Select>('[name="accountingAccount"]');

		N_type!.options = {
			data: [{
				id: 'purchase',
				text: 'Achat'
			}, {
				id: 'sale',
				text: 'Vente'
			}]
		};

		N_type!.addEventListener('change', async () => {
			if (N_accountingAccount!.value) {
				const account = await new AccountsService().getById(N_accountingAccount!.value as string);
				if (account.type !== N_type!.value) {
					N_accountingAccount!.value = null;
				}
			}
		});

		const title = this.element.querySelector<HTMLDivElement>('.modal-title');

		if (this.taxe) {
			title!.innerText = 'EDITION CODE TVA';
		} else {
			title!.innerText = 'NOUVEAU CODE TVA';
		}

		N_accountingAccount!.options = {
			ajax: {
				url: new AccountsService().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ forTaxe: true, type: N_type!.value })
					};
				}
			}
		};
	}

	protected async closeModal(data: { [key: string]: any }) {
		await new TaxesService().save(data);
		this.resolve(data);
	}

	protected async getDataForm(data: taxe) {
		return new TaxeModel(data).toSave();
	}

	protected checkValidityForm() {
		return this.form?.checkValidity() || false;
	}
}

export default AddTVA;
