import { SocketIO } from '@autoprog/core-client';

class OpenDocument extends HTMLElement {
	public static readonly tagName: string = 'ap-open-document';

	public static elements: OpenDocument[] = [];

	private abortController: AbortController | null = null;

	public async connectedCallback() {
		OpenDocument.elements.push(this);

		this.abortController = new AbortController();

		this.innerHTML = `
			<ap-button class="btn-navbar" icon="folder-open/line"></ap-button>
        `;

		this.classList.add('d-none');

		const id = this.dataset.id || '';
		const table = this.dataset.table || '';

		SocketIO.getInstance().on('openDocuments.update', (data: any[]) => {
			let find = false;

			for (const item of data) {
				if (item.id === id && item.table === table) {
					find = true;
					this.setData(item);
					break;
				}
			}

			if (!find) {
				this.hide();
			}
		}, {
			signal: this.abortController!.signal
		});
	}

	private hide() {
		this.classList.add('d-none');
	}

	private setData(item: { [key: string]: any }) {
		this.classList.remove('d-none');
		this.setAttribute('tooltip', `Ouvert par ${item.user}`);
	}

	public destructor() {
		this.abortController!.abort('destroyed');
	}

	public static destructor() {
		for (const item of OpenDocument.elements) {
			item.destructor();
		}
	}

	public static register() {
		customElements.define(OpenDocument.tagName, OpenDocument);
	}
}

export default OpenDocument;
