import { Form, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import '../../../css/customElements/tabPrintOption.scss';

import axios from 'axios';

import Utils from '../utils/Utils';
import _ from 'lodash';

class PrintOptionTab extends HTMLElement {
	public static readonly tagName: string = 'ap-print-options-tab';

	protected _dataId = (): string | null => { return null; };
	protected _data = (): any => { return {}; };
	protected idTab: string;
	protected selectorTab: string;
	protected isDefault: boolean;
	protected form: Form | null;
	public table: string;
	protected N_tabPanel: Element | null;

	private _update = (): void => { };

	protected eventChangeForm = '**.change';

	private N_printContainer: HTMLIFrameElement | null;
	private N_spinner: HTMLElement | null;

	protected KEY_SETTINGS = '';

	constructor() {
		super();

		this.idTab = this.getAttribute('tabID') || 'options';
		this.selectorTab = this.dataset.tabContainer || '.tab-content';
		this.form = null;
		this.N_tabPanel = null;
		this.isDefault = false;

		this.N_printContainer = null;
		this.N_spinner = null;

		this.table = '';

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="printer/line" icon-active="printer/fill" text="Options d'impression"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.fakePrint();
		});
	}

	public async connectedCallback() { }

	protected getFormTemplate() {
		return '';
	}

	public setParentElement(parent: HTMLElement) {
		this.isDefault = !!this.getAttribute('default');

		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_tabPanel = document.createElement('div');
		this.N_tabPanel.classList.add('page-tabs-container', 'tab-pane');
		this.N_tabPanel.id = this.idTab;

		const formTemplate = this.getFormTemplate();

		this.N_tabPanel.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Options d'impression
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content page-tabs-container-print">
				<div class="col-form" id="optionsForm">
					<div class="container-apply-button">
						<ap-button class="btn-apply" icon="check/line">Actualiser</ap-button>
						<ap-button class="btn-defaultValue" icon="refresh/line" confirmation tooltip="Valeurs par défaut"></ap-button>
					</div>
					<div class="container-form">${formTemplate}</div>
				</div>
				<div class="col-fake-print" id="containerFakePrint">
					<iframe id="fakePrint"></iframe>
					<div class="loader-fake-print" id="spinner">
						<div class="container-spinner">
							<ap-icon class="icon-9x icon-spin" name="loader-4/line"></ap-icon>
						</div>
					</div>
				</div>
			</div>
		`;

		const N_containerPrint = this.N_tabPanel.querySelector<HTMLElement>('.page-tabs-container-print')!;

		if (this.KEY_SETTINGS === '') {
			N_containerPrint.classList.add('no-reset-button');
		}

		if (this.isDefault) {
			N_containerPrint.classList.add('is-default');
		}

		if (formTemplate === '') {
			N_containerPrint.classList.add('no-form-template');
		}

		this.N_printContainer = this.N_tabPanel!.querySelector('#fakePrint') as HTMLIFrameElement;
		this.N_spinner = this.N_tabPanel!.querySelector('#spinner') as HTMLElement;

		const N_form = this.N_tabPanel!.querySelector('#optionsForm') as HTMLFormElement;

		this.form = new Form(N_form);

		const N_apply = this.N_tabPanel!.querySelector('.btn-apply') as HTMLButtonElement;

		this.form.on(this.eventChangeForm, () => {
			this._update();
		});

		if (this.isDefault) {
			if (this.form) {
				let timeoutDebounce: any = null;
				this.form.on(this.eventChangeForm, () => {
					timeoutDebounce && clearTimeout(timeoutDebounce);
					timeoutDebounce = setTimeout(() => {
						this.fakePrint();
					}, 300);
				});
			}
		} else {
			N_apply?.addEventListener('click', () => {
				N_apply.loading(this.fakePrint());
			});

			const N_applyDefault = this.N_tabPanel!.querySelector('.btn-defaultValue') as HTMLButtonElement;

			N_applyDefault?.addEventListener('click', () => {
				this.applyDefaultValue();
			});
		}

		N_container.append(this.N_tabPanel);
	}

	public get dataId() {
		return this._dataId();
	}

	public setID(cb: () => string | null) {
		this._dataId = cb;
	}

	public setGetData(cb: () => any) {
		this._data = cb;
	}

	public getData() {
		const result = _.merge(this._data(), this.data);
		return result;
	}

	protected applyDefaultValue() {
		const N_apply = this.N_tabPanel!.querySelector('.btn-apply') as HTMLButtonElement;
		const defaultOption = SettingsApps.getInstance().get(this.KEY_SETTINGS) as unknown as { [key: string]: any };

		if (defaultOption) {
			this.setDefaultValue(defaultOption);
			N_apply.loading(this.fakePrint());
			this._update();
		} else {
			toaster.info('Aucun paramètres par défaut');
		}
	}

	protected setDefaultValue(value: { [key: string]: any }) {
		this.data = value;
	}

	public set data(data: { [key: string]: any }) {
		this.form!.setData(data);
	}

	public get data() {
		return (this.form!.getData() as { [key: string]: any });
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public async postInit() {
		try {
			await this.fakePrint();
		} catch (e) {
		}
	}

	protected async fakePrint() {
		const url = await this.getUrlPreviewPrint();

		const abortController = new AbortController();
		window.addEventListener('message', (event) => {
			if (event.data === 'DOMContentLoadedIframePreview') {
				this.N_printContainer!.style.visibility = 'visible';
				this.N_spinner!.style.opacity = '0';
				this.N_spinner!.style.pointerEvents = 'none';
				abortController.abort('');
			}
		}, {
			signal: abortController.signal
		});

		this.N_spinner!.style.opacity = '1';
		this.N_spinner!.style.pointerEvents = 'all';

		this.N_printContainer!.style.visibility = 'hidden';
		this.N_printContainer!.src = url;
	}

	protected async getUrlPreviewPrint(): Promise<string> {
		try {
			const token = (await axios.post(`/api/${this.table}/previewToken`, {
				defaultvalue: this.getData()
			})).data;

			return `/api/${this.table}/preview?id=${this.dataId}&token=${token}&companyID=${Utils.companyID}`;
		} catch (e) {
			return '';
		}
	}

	public static register() {
		customElements.define(this.tagName, PrintOptionTab);
	}
}

export default PrintOptionTab;
