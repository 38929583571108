import T_C_address from '@tpl/mobile-blocks/customers-address.ejs';
import T_C_contacts from '@tpl/mobile-blocks/customers-contacts.ejs';
import T_Customers from '@tpl/mobile-blocks/customers.ejs';
import T_Deliveries from '@tpl/mobile-blocks/deliveries.ejs';
import T_Interventions from '@tpl/mobile-blocks/interventions.ejs';
import T_Notes from '@tpl/mobile-blocks/notes.ejs';
import T_P_address from '@tpl/mobile-blocks/providers-address.ejs';
import T_P_contacts from '@tpl/mobile-blocks/providers-contacts.ejs';
import T_Pdt_groups from '@tpl/mobile-blocks/products-groups.ejs';
import T_Pdt_provider from '@tpl/mobile-blocks/products-provider.ejs';
import T_Pdt_stocks from '@tpl/mobile-blocks/products-stocks.ejs';
import T_Products from '@tpl/mobile-blocks/products.ejs';
import T_Providers from '@tpl/mobile-blocks/providers.ejs';
import T_QuoteToDo from '@tpl/mobile-blocks/quotes-to-do.ejs';
import T_Quotes from '@tpl/mobile-blocks/quotes.ejs';
import T_Receipts from '@tpl/mobile-blocks/receipts.ejs';
import T_S_contacts from '@tpl/mobile-blocks/sites-contacts.ejs';
import T_Sites from '@tpl/mobile-blocks/sites.ejs';
import T_Transporters from '@tpl/mobile-blocks/transporters.ejs';

type ejs = (variables: { [key: string]: any; }) => string;

class TemplateMobileManager {
	private static cache: Map<string, ejs> = new Map<string, ejs>();

	public static init() {
		this.cache.set('customers.address', T_C_address);
		this.cache.set('customers-contacts', T_C_contacts);
		this.cache.set('customers', T_Customers);
		this.cache.set('deliveries', T_Deliveries);
		this.cache.set('interventions', T_Interventions);
		this.cache.set('notes', T_Notes);
		this.cache.set('products', T_Products);
		this.cache.set('products-provider', T_Pdt_provider);
		this.cache.set('products-stocks', T_Pdt_stocks);
		this.cache.set('products-groups', T_Pdt_groups);
		this.cache.set('providers-address', T_P_address);
		this.cache.set('providers-contacts', T_P_contacts);
		this.cache.set('providers', T_Providers);
		this.cache.set('quotes-to-do', T_QuoteToDo);
		this.cache.set('quotes', T_Quotes);
		this.cache.set('receipts', T_Receipts);
		this.cache.set('sites-contacts', T_S_contacts);
		this.cache.set('sites', T_Sites);
		this.cache.set('transporters', T_Transporters);
	}

	public static get(table: string, data: { [key: string]: any }) {
		const template = this.cache.get(table);

		if (template) {
			return template({
				data
			});
		} else {
			return '';
		}
	}
}

export default TemplateMobileManager;
