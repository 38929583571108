import { App, ConnectionLostType, LoggedUser, LoginModule, Modal, Router, SocketIO, Tabs, Window, global, toaster } from '@autoprog/core-client';

import 'moment/locale/fr';

import h from 'hyperscript';
import moment from 'moment';

import ConfigManager from '@libs/ConfigManager';
import Keyboard from '@libs/Keyboard';
import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';
import SettingsApps from '@libs/Settings';
import Title from '@libs/Title';

import C_ControllerDatabase from './controllers/ControllerDatabase';
import C_Database from './controllers/Database';

import T_Database from '@tpl/database.html';

import T_appTitle from '@tpl/blocks/appTitle.html';
import T_loginPageHeader from '@tpl/blocks/loginPageHeader.html';

import favicon from '../img/favicon.ico';

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';

import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css';

import '../modules/Settings/module';
import '../modules/Notifications/module';

import '../modules/ThirdParty/module';
import '../modules/Changelog/module';
import '../modules/Apps/module';
import '../modules/Quotes/module';
import '../modules/Stock/module';
import '../modules/Bills/module';
import '../modules/Orders/module';
import '../modules/Profile/module';
import '../modules/ImportExportData/module';
import '../modules/TrackingProduct/module';
import '../modules/Deliveries_Receipts/module';
//FIXME: a migrer dans derliverie_receipt
import '../modules/Deliveries/module';
import '../modules/Products/module';
import '../modules/Comptabilité/module';
import '../modules/Units/module';

import '@autoprog/core-client/dist/core.css';

import 'select2/dist/css/select2.min.css';
import 'select2';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/themes/light.css';

import '@css/app.scss';
import '@css/aggrid.scss';
import '@css/mobile.scss';
import '@css/modal.scss';
import '@css/custom-switch-size.scss';
import '@css/new/input.scss';
import '@css/new/modal.scss';
import '@css/new/PageControllerID/index.scss';
import '@css/new/form.scss';
import '@css/maintenance.scss';

import 'animate.css';

import ModalManager from './managers/ModalManager';
import ServiceManager from './managers/ServiceManager';
import TemplateMobileManager from './managers/TemplateMobileManager';
import TemplateModalManager from './managers/TemplateModalManager';

//services
import S_Customer from './services/Customer/CustomerService';
import S_CustomerAddress from './services/Customer/CustomerAddressService';
import S_CustomerBill from './services/Customer/CustomerBillService';
import S_CustomerContact from './services/Customer/CustomerContactService';
import S_CustomerOrder from './services/Customer/CustomerOrderService';

import S_Product from './services/Product/ProductService';
import S_ProductBrand from './services/Product/BrandService';
import S_ProductGroups from './services/Product/GroupsService';
import S_ProductProvider from './services/Product/ProductProviderService';
import S_ProductStock from './services/Product/ProductStockService';
import S_ProductTags from './services/Product/ProductTagsService';
import S_Transporter from './services/TransporterService';

import S_Provider from './services/Provider/ProviderService';
import S_ProviderAddress from './services/Provider/ProviderAddressService';
import S_ProviderBill from './services/Provider/ProviderBillService';
import S_ProviderContact from './services/Provider/ProviderContactService';
import S_ProviderOrder from './services/Provider/ProviderOrderService';

import S_Site from './services/Site/SiteService';
import S_SiteContact from './services/Site/SiteContactService';

import S_Category from './services/Product/ProductCategoryService';
import S_Companies from './services/CompaniesService';
import S_Delivery from './services/DeliveryService';
import S_Group from './services/User/GroupService';
import S_Intervention from './services/Interventions';
import S_Notifications from './services/NotificationService';
import S_Project from './services/ProjectService';
import S_Quote from './services/QuoteService';
import S_Receipt from './services/ReceiptService';
import S_Stock from './services/StockService';
import S_StockEvent from './services/StockEventService';
import S_ToDoQuote from './services/Quotes/ToDoQuoteService';
import S_TrackingProduct from './services/TrackingProductService';
import S_User from './services/User/UserService';

import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';

import S_Contacts from './services/ContactsService';

import M_Product from '@modules/Products/js/modals/AddEditProduct';

import ConfigurationService from './services/ConfigurationService';

import CE_Address from '@libs/customElement/Address';
import CE_AddressReadonly from '@libs/customElement/AddressReadonly';
import CE_CGVTab from '@libs/customElement/CGVTab';
import CE_CompanySelect from '@libs/customElement/CompanySelect';
import CE_DeadlinePayment from '@libs/customElement/DeadlinePayment';
import CE_DeadlinePaymentReadonly from '@libs/customElement/DeadlinePaymentReadonly';
import CE_FilesIcon from '@libs/customElement/FilesIcon';
import CE_FilesTab from '@libs/customElement/FilesTab';
import CE_FilterButton from '@libs/customElement/FilterButton';
import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_HeaderDashboard from '@libs/customElement/HeaderDashboard';
import CE_HistoryTab from '@libs/customElement/HistoryTab';
import CE_InputTime from '@libs/customElement/InputTime';
import CE_MobileFilter from '@libs/customElement/MobileFilter';
import CE_MobileSort from '@libs/customElement/MobileSort';
import CE_NotesTab from '@libs/customElement/NotesTab';
import CE_OpenDocument from '@libs/customElement/OpenDocument';
import CE_OpenDocuments from '@libs/customElement/OpenDocuments';
import CE_PhoneNumber from '@libs/customElement/PhoneNumber';
import CE_PrintOptionTab from '@libs/customElement/PrintOptionTab';
import CE_Progress from '@libs/customElement/Progress';
import CE_Quantity from '@libs/customElement/Quantity';
import CE_Reminders from '@libs/customElement/RemindersTab';
import CE_SaveButton from '@libs/customElement/SaveButton';
import CE_SearchBar from '@libs/customElement/searchBar';
import CE_Select2 from '@libs/customElement/Select2';
import CE_Select2Mobile from '@libs/customElement/Select2Mobile';
import CE_SelectPaymentType from '@libs/customElement/SelectPaymentType';
import CE_Signature from '@libs/customElement/Signature';
import CE_SignatureTab from '@libs/customElement/SignatureTab';
import CE_SitesFinalCustomer from '@js/libs/customElement/Sites-FinalCustomer';
import CE_SitesFinalCustomerReadonly from '@js/libs/customElement/Sites-FinalCustomer-Readonly';
import CE_Switch3 from '@libs/customElement/Switch3';
import CE_TVA from '@libs/customElement/TVA';
import CE_TextEditable from '@libs/customElement/TextEditable';
import CE_Traceability from '@libs/customElement/Traceability';
import CE_VerticalAccordion from '@libs/customElement/VerticalAccordion';

import CE_NavbarClock from '@libs/customElement/navbar/Navbar-clock';
import CE_NavbarSeparator from '@libs/customElement/navbar/Navbar-separator';

import CE_AddressDelivery from '@libs/customElement/AddressDelivery';
import CE_AddressDeliveryReadonly from '@libs/customElement/AddressDeliveryReadonly';
import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_AgGridButtonEditAddLine from '@js/libs/customElement/button-edit-aggrid/AddLine';
import CE_Alert from '@libs/customElement/Alert';
import CE_Button from '@libs/customElement/Button';
import CE_Checkbox from '@libs/customElement/Checkbox';
import CE_DashboardUser from '@libs/customElement/dashboard/DashboardUser';
import CE_FilesEdit from '@libs/customElement/FilesEdit';
import CE_FilesReadonly from '@libs/customElement/FilesReadonly';
import CE_InputWithAddon from '@libs/customElement/InputWithAddon';
import CE_LostConnexion from '@libs/customElement/LostConnexion';
import CE_Messages from '@libs/customElement/Messages';
import CE_Select from '@libs/customElement/Select';
import CE_StepModal from '@js/libs/customElement/StepModalNav';
import CE_Switch from '@libs/customElement/Switch';
import CE_UploadPhoto from '@libs/customElement/UploadPhoto';

import CE_PageTabsMenu from '@libs/customElement/PageControllerID/PageTabsMenu';
import CE_PageTabsMenuContent from '@libs/customElement/PageControllerID/PageTabsMenuContent';
import CE_PageTabsMenuItem from '@libs/customElement/PageControllerID/PageTabsMenuItem';
import CE_PageTabsMenuTitle from '@libs/customElement/PageControllerID/PageTabsMenuTitle';

import CA_Device from '@libs/customAttribute/Display';
import CA_EllipsisTooltip from '@libs/customAttribute/EllipsisTooltip';
import CA_Hidden from '@libs/customAttribute/Hidden';
import CA_Readonly from '@libs/customAttribute/Readonly';

/* Mobile */
import CE_NavbarMobile from '@libs/customElement/mobile/NavbarMobile';

Tabs.activeClass = 'tab-active';
//eslint-disable-next-line no-template-curly-in-string
Tabs.navTemplate = '<button permission="${tab.permission}" tab-link="${tab.id}" class="btn btn-tab"> ${tab.name} </button>';

const userAgent = navigator.userAgent.toLowerCase();
global.IS_TABLET = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

if (global.IS_TABLET) {
	global.IS_MOBILE = false;
}

const ce = console.error;
console.error = function (message?: any, ...optionalParams: any[]): void {
	if (message && message.toString()[0] !== '*') {
		ce(message, ...optionalParams);
	}
};

moment().locale('fr');

(async () => {
	const apLostConnexion = h<CE_LostConnexion>('ap-lost-connexion');

	const app = await App.create({
		theme: {
			disableAsideHover: true,
			asideToggler: true,
			asideOnTop: false,
			noAside: global.IS_MOBILE,
			noNavbar: global.IS_MOBILE,
			noFooter: !global.IS_MOBILE
		},
		connectionLostType: global.IS_MOBILE ? ConnectionLostType.MODAL : apLostConnexion
	});

	global.COUCHDB_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/db/`;
	global.FILEDB_URL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/file/`;

	//On récupère l'id des colonnes ag-grid sauvegardé dans le localStorage avant d'initialiser le core.
	//Cet id permet de récupérer la taille des colonnes ag-grid sauvegardé pour l'utilisateur pour le pc.
	const lsUid = localStorage.getItem('apps_uid_col') || '';

	//Si l'id n'existe pas, on le créée.
	if (lsUid) {
		global.ID_PC = lsUid;
	} else {
		const ID = Date.now().toString(36);
		localStorage.setItem('apps_uid_col', ID);
		global.ID_PC = ID;
	}

	const fileConfig = await ConfigurationService.getInstance().getConfig();

	global.CONFIG = fileConfig.data;
	global.COUCHDB_PREFIX = global.CONFIG.couchDB.prefix;

	global.DECIMAL_SEPARATOR = ',';
	global.FAVICON = favicon;

	ServiceManager.register('customers', S_Customer);
	ServiceManager.register('customers-address', S_CustomerAddress);
	ServiceManager.register('bills', S_CustomerBill);
	ServiceManager.register('customers-contacts', S_CustomerContact);
	ServiceManager.register('commands-customer', S_CustomerOrder);

	ServiceManager.register('products', S_Product);
	ServiceManager.register('products-provider', S_ProductProvider);
	ServiceManager.register('products-stocks', S_ProductStock);
	ServiceManager.register('products-tags', S_ProductTags);
	ServiceManager.register('brands', S_ProductBrand);
	ServiceManager.register('products-groups', S_ProductGroups);
	ServiceManager.register('transporters', S_Transporter);

	ServiceManager.register('providers', S_Provider);
	ServiceManager.register('providers-address', S_ProviderAddress);
	ServiceManager.register('bills-providers', S_ProviderBill);
	ServiceManager.register('commands-provider', S_ProviderOrder);
	ServiceManager.register('providers-contacts', S_ProviderContact);

	ServiceManager.register('sites', S_Site);
	ServiceManager.register('sites-contacts', S_SiteContact);

	ServiceManager.register('deliveries', S_Delivery);
	ServiceManager.register('projects', S_Project);
	ServiceManager.register('quotes', S_Quote);
	ServiceManager.register('quotes-to-do', S_ToDoQuote);
	ServiceManager.register('receipts', S_Receipt);
	ServiceManager.register('stock', S_Stock);
	ServiceManager.register('stock_events', S_StockEvent);
	ServiceManager.register('users', S_User);
	ServiceManager.register('groups', S_Group);
	ServiceManager.register('categories', S_Category);
	ServiceManager.register('notifications', S_Notifications);

	ServiceManager.register('interventions', S_Intervention);
	ServiceManager.register('tracking-products', S_TrackingProduct);

	ServiceManager.register('interventions', S_Intervention);
	ServiceManager.register('tracking-products', S_TrackingProduct);

	ServiceManager.register('contacts', S_Contacts);

	ServiceManager.register('taxes', S_Taxes as any);

	ServiceManager.register('companies', S_Companies);

	ModalManager.register('products', M_Product);

	TemplateMobileManager.init();
	TemplateModalManager.init();

	CE_DashboardUser.register();
	CE_Alert.register();
	CE_Button.register();
	CE_Checkbox.register();
	CE_InputWithAddon.register();
	CE_LostConnexion.register();
	CE_Messages.register();
	CE_Select.register();
	CE_Switch.register();
	CE_AgGrid.register();
	CE_AddressDelivery.register();
	CE_AddressDeliveryReadonly.register();
	CE_UploadPhoto.register();
	CE_StepModal.register();
	CE_FilesEdit.register();
	CE_FilesReadonly.register();
	CE_AgGridButtonEditAddLine.register();

	CE_PageTabsMenuContent.register();
	CE_PageTabsMenuItem.register();
	CE_PageTabsMenuTitle.register();
	CE_PageTabsMenu.register();

	CE_Reminders.register();
	CE_MobileFilter.register();
	CE_MobileSort.register();
	CE_OpenDocument.register();
	CE_OpenDocuments.register();
	CE_GridSidebar.register();
	CE_VerticalAccordion.register();
	CE_Signature.register();
	CE_NotesTab.register();
	CE_SignatureTab.register();
	CE_HistoryTab.register();
	CE_CGVTab.register();
	CE_FilesIcon.register();
	CE_FilesTab.register();
	CE_FilterButton.register();
	CE_InputTime.register();
	CE_HeaderDashboard.register();
	CE_PrintOptionTab.register();
	CE_SitesFinalCustomer.register();
	CE_SitesFinalCustomerReadonly.register();
	CE_Switch3.register();
	CE_TVA.register();
	CE_Traceability.register();
	CE_Quantity.register();
	CE_Select2.register();
	CE_Address.register();
	CE_AddressReadonly.register();
	CE_TextEditable.register();
	CE_Select2Mobile.register();
	CE_PhoneNumber.register();
	CE_DeadlinePayment.register();
	CE_DeadlinePaymentReadonly.register();
	CE_SaveButton.register();
	CE_SelectPaymentType.register();
	CE_SearchBar.register();
	CE_CompanySelect.register();
	CE_Progress.register();

	CE_NavbarSeparator.register();
	CE_NavbarClock.register();

	CA_Device.registry();
	CA_Hidden.registry();
	CA_Readonly.registry();
	CA_EllipsisTooltip.registry();

	await app.preInit();

	/**
	 * TODO: declarer page 404
	 */

	await app.init();

	const configManager = ConfigManager.getInstance();

	await configManager.init();

	LoginModule.loginPageHeader = T_loginPageHeader;

	//###########################################################
	//Configurations diverses
	//###########################################################

	const io = SocketIO.getInstance();
	io.connect();

	//###########################################################
	//Déclaration des routes
	//###########################################################
	const router = Router.getInstance();
	const navigation = Navigation.getInstance();

	const page_sites = {
		category: 'Données',
		order: 1,
		name: 'Sites',
		type: 'database',
		database: 'sites',
		icon: 'road-map/line'
	};

	navigation.addCategory({
		name: 'Données',
		order: 0
	});

	navigation.addCategory({
		name: 'Paramétrage',
		order: 1000
	});

	navigation.addLink(page_sites);

	router.addRoute({
		url: '/database/sites',
		controller: C_ControllerDatabase,
		tpl: T_Database,
		data: {
			...page_sites,
			instance: C_Database
		},
		requireValidUser: true,
		default: false
	});

	navigation.render();

	NavigationMobile.getInstance().addLink({
		icon: 'icon-methanisation-2',
		name: 'Sites',
		order: 2,
		dataset: {
			link: 'sites_projects'
		}
	});

	NavigationMobile.getInstance().addLink({
		icon: 'icon-solid-list-ul',
		name: 'Client / Fournisseur',
		order: 3,
		dataset: {
			link: 'customers_providers'
		}
	});

	const N_app = document.querySelector('#app');

	const N_modal_right = document.createElement('div');
	N_modal_right.id = 'floating-modals-container';
	N_app?.appendChild(N_modal_right);

	if (!global.IS_MOBILE || global.IS_TABLET) {
		const N_router = document.querySelectorAll('a[router-href]') as NodeListOf<HTMLElement>;

		N_router.forEach((N_el) => {
			const N_icon = N_el.querySelector('ap-icon') as HTMLElement;
			const text = N_el.innerText;
			N_icon.setAttribute('tooltip', `{"title" : "${text}", "delay" : 500, "placement" : "right"}`);
		});

		const N_navbarRight = document.querySelector('#navbar-right-content') as HTMLElement;

		N_navbarRight.innerHTML = `
			<ap-company-select></ap-company-select>

			<ap-navbar-separator></ap-navbar-separator>

			<ap-buttons-history></ap-buttons-history>

			<ap-navbar-separator></ap-navbar-separator>

			<ap-button-fullscreen></ap-button-fullscreen>
			
			<ap-open-documents></ap-open-documents>
		`;

		N_navbarRight.prepend(apLostConnexion);

		global.APP_NAME = T_appTitle;

		const N_navbarContent = document.querySelector('#navbar-content') as HTMLElement;
		N_navbarContent.appendChild(h('ap-messages'));

		document.body.classList.add('desktop');
	} else {
		CE_NavbarMobile.register();

		const N_footer = document.createElement('ap-navbar-mobile');
		N_app?.appendChild(N_footer);

		document.body.classList.add('mobile');
	}

	Title.getInstance();

	await app.postInit();

	toaster.options.positionClass = 'toast-bottom-left';

	if (global.IS_ELECTRON) {
		global.CLICK_EVENT_TACTILE = 'touchstart';

		document.body.style.userSelect = 'none';
		Keyboard.init();
	} else {
		global.CLICK_EVENT_TACTILE = 'click';
	}

	LoggedUser.getInstance().on('logout', () => {
		Modal.closeAll();
		Window.closeAll();
		Title.getInstance().updateNotif(0);
	});

	//reload automatique des settings
	let timeout: any = null;

	SocketIO.getInstance().on('settings.reload', () => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			SettingsApps.getInstance().load();
		}, 1000);
	});
})();
