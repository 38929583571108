import { Router } from '@autoprog/core-client';

class HistoryApps {
	private static instance: HistoryApps;

	private list: string[] = [];
	private currentIndex: number = -1;

	private router: Router;

	private constructor() {
		this.router = Router.getInstance();
		this.router.on('route.change', () => {
			if (window.location.hash !== this.list[this.currentIndex]) {
				this.currentIndex++;

				this.list[this.currentIndex] = window.location.hash;

				this.list.splice(this.currentIndex + 1, this.list.length - this.currentIndex);
			}
		});
	}

	public back() {
		if (this.currentIndex > 0) {
			this.currentIndex--;

			this.router.navigate(this.list[this.currentIndex]);
			return true;
		} else {
			return false;
		}
	}

	public forward() {
		if (this.list[this.currentIndex + 1]) {
			this.currentIndex++;
			this.router.navigate(this.list[this.currentIndex]);
			return true;
		}

		return false;
	}

	public pushState(href: string) {
		this.currentIndex++;

		history.pushState(null, '', href);

		this.list[this.currentIndex] = window.location.hash;

		this.list.splice(this.currentIndex + 1, this.list.length - this.currentIndex);
	}

	public deleteCurrentState() {
		this.list.splice(this.currentIndex, 1);
		this.currentIndex--;
	}

	public replaceState(href: string) {
		history.replaceState(null, '', href);
		this.list[this.currentIndex] = window.location.hash;
	}

	public static getInstance() {
		if (!HistoryApps.instance) {
			HistoryApps.instance = new HistoryApps();
		}

		return HistoryApps.instance;
	}
}

export default HistoryApps;
