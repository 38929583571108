// CORE
import { Alert, Router, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import OpenDocuments from '@libs/customElement/OpenDocuments';

// MODAL
import M_AdditionalInformation from '../modals/editPage/AdditionalInformation';
import M_Amounts from '../modals/editPage/Amounts';
import M_DetailsBill from '../modals/editPage/DetailsBill';
import M_GeneralInformation from '../modals/editPage/GeneralInformation';

// CUSTOM_ELEMENT
import CE_PaymentTab from '@modules/BillsProvider/js/customElements/Payment';

// SERVICE
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_Taxe from '@modules/Comptabilité/js/services/TaxesService';

import C_OrdersProvider from '@modules/OrdersProvider/js/controllers/Orders.Provider';

import '../../css/pageControllerID.scss';
import DecimalApps from '@js/libs/utils/Decimal';
import PriceWithPercentModel from '@js/libs/model/_app/PriceWithPercent';

class BillsProviderCtrl extends ControllerPageID {
	private N_PaymentTab: CE_PaymentTab | null = null;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = BillsProviderCtrl.options || {};

		BillsProviderCtrl.options = {};

		this.routeReturn = 'module/bills/providers';

		this.init('bills-providers', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		BillsProviderCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'bills-providers');
			Router.getInstance().navigate(`/module/billsPage/provider?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/billsPage/provider');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();
		this.initEvent();

		const data = await this.getData();

		await this.renderTVA(data.data);

		this.setData(data);

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		if (!this.options.orderID && !this.id) {
			this.openGeneralInformation(true);
		}

		if (this.options.orderID) {
			this.enableSaveButton();
		}

		this.updateDisplayWithoutOrder();
	}

	private openGeneralInformation(isFirstOpen = false) {
		const res = {
			infos: {
				provider: this.form?.getDataByName('infos.provider') as string,
				contact: this.form?.getDataByName('infos.contact') as string,
				paymentType: this.form?.getDataByName('infos.paymentType') as string,
				label: this.form?.getDataByName('infos.label') as string
			}
		};

		let modal: M_GeneralInformation;

		if (isFirstOpen) {
			modal = new M_GeneralInformation(res, !this.options.idCommand).setNextCallback(() => {
				this.openDetailBill(true);
			});
		} else {
			modal = new M_GeneralInformation(res, !this.options.idCommand);
		}

		modal.open().then(async (data) => {
			this.setDataForm(data);
			await this.updateProvider();
			this.updatePrice();
			this.updateTitle();

			if (!isFirstOpen) {
				this.enableSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openDetailBill(isFirstOpen = false) {
		const res = {
			order: this.form?.getDataByName('order') as string,
			infos: {
				number: this.form?.getDataByName('infos.number') as string,
				type: this.form?.getDataByName('infos.type') as string,
				waitingBill: this.form?.getDataByName('infos.waitingBill') as boolean
			}
		};

		let modal: M_DetailsBill;

		if (isFirstOpen) {
			modal = new M_DetailsBill(res).setPreviousCallback(() => {
				this.openGeneralInformation(true);
			});
		} else {
			modal = new M_DetailsBill(res);
		}

		modal.open().then((data) => {
			this.setDataForm(data);
			this.updateTitle();

			this.enableSaveButton();
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openAmount(isFirstOpen: boolean = false) {
		const formData = this.form?.getData();

		const res = {
			_id: this.id,
			infos: {
				type: formData.infos.type as string
			},
			billOrigin: formData.billOrigin as string,
			order: formData.order.internalNumber as string,
			priceHT: formData.priceHT as number,
			tva: formData.tva as { [IDTVA: string]: number }
		};

		const modal = new M_Amounts(res);

		if (isFirstOpen) {
			modal.setPreviousCallback(() => {
				this.openDetailBill(true);
			});
		}

		modal.open().then(async (data) => {
			//fix temporaire pour fusionner les nouvelles valeurs de la tva
			this.form?.setDataByName('tva', null);
			await this.renderTVA(data);
			this.setDataForm(data);
			this.updatePrice();
			this.enableSaveButton();
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;
		const N_edit_Amounts = this.el.querySelector('[data-edit="amounts"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsBill.addEventListener('click', () => {
			this.openDetailBill();
		});

		N_edit_Amounts.addEventListener('click', () => {
			this.openAmount();
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					provider: this.form?.getDataByName('infos.provider') as string,
					date: this.form?.getDataByName('infos.date') as Moment,
					datePayment: this.form?.getDataByName('infos.datePayment') as Moment,
					receiveDate: this.form?.getDataByName('infos.receiveDate') as Moment,
					comment: this.form?.getDataByName('infos.comment') as string
				},
				address: {
					id: this.form?.getDataByName('address.id') as string,
					text: this.form?.getDataByName('address.text') as string
				},
				disputes: {
					enabled: this.form?.getDataByName('disputes.enabled') as boolean,
					comment: this.form?.getDataByName('disputes.comment') as string
				},
				relaunch: {
					date: this.form?.getDataByName('relaunch.date') as Moment
				}
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.enableSaveButton();
				this.updatePrice();
			});
		});
	}

	private initEvent() {
		const N_openOrderButton = this.el.querySelector('#open_order') as HTMLButtonElement;

		N_openOrderButton.addEventListener('click', () => {
			const idOrder = this.form?.getDataOnAllDataByName('order.internalNumber');
			C_OrdersProvider.open(idOrder);
		});

		const N_disputes = this.el.querySelector('#disputes') as HTMLButtonElement;

		N_disputes.addEventListener('click', () => {
			const isDispute = this.form?.getDataByName('disputes.enabled') as boolean;

			if (!isDispute) {
				Alert.prompt('Litige', 'Commentaire', {
					type: 'textarea',
					value: ' ',
					confirmColor: 'validate-modal',
					cancelColor: 'close-modal',
					cancelText: 'Fermer'
				}).then((value) => {
					this.form?.setDataByName('disputes.enabled', true);
					this.form?.setDataByName('disputes.comment', (value as string).trim());

					N_disputes!.lastElementChild!.innerHTML = 'Clôturer le litige';

					this.N_NotesTab!.addLine('Mise en litige de la facture.');

					this.updateDisputesButton();
					this.enableSaveButton();
				});
			} else {
				Alert.confirm('Litige', 'Retirer le litige ?', {
					yesColor: 'validate-modal',
					yesText: 'Valider',
					noColor: 'close-modal',
					noText: 'Fermer'
				}).then(() => {
					this.form?.setDataByName('disputes.enabled', false);
					this.form?.setDataByName('disputes.comment', '');

					this.N_NotesTab!.addLine('Suppression du litige');

					this.updateDisputesButton();
					this.enableSaveButton();
				});
			}
		});
	}

	private initTabs() {
		this.initPayment();
	}

	private async initPayment() {
		this.N_PaymentTab = this.el.querySelector(CE_PaymentTab.tagName) as CE_PaymentTab;

		this.N_PaymentTab.setParentElement(this.el);

		this.N_PaymentTab.addEventListener('load', () => {
			this.updatePrice();
		});

		this.N_PaymentTab.addEventListener('update', async () => {
			await this.save();
			this.updatePrice();
		});

		this.N_PaymentTab.setSave(async () => {
			await this.save();
		});

		this.N_PaymentTab.setGetPriceTTC(() => {
			return this.form?.getDataOnAllDataByName('priceTTC') as string;
		});
	}

	protected convertData(data: { [key: string]: any }) {
		const types: { [key: string]: string } = {
			'credit-error': 'Avoir (Erreur)',
			credit: 'Avoir (Commercial)',
			normal: 'Avancement',
			advance: 'Acompte',
			last: 'Définitive'
		};

		if (data.infos?.type) {
			data.infos.type = {
				id: data.infos.type,
				text: types[data.infos.type]
			};
		}

		return data;
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.updateTTC();

		this.N_PaymentTab!.iniData(data.data.payments || []);

		const N_billOrigin = this.el.querySelector<HTMLElement>('#billOrigin')!;

		if (data.data.infos.type === 'credit' || data.data.infos.type === 'credit-error') {
			N_billOrigin.classList.remove('d-none');
		}

		this.updateDisputesButton();
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.infos.date = newData.infos.date.format('x');

		if (newData.infos.datePayment) {
			newData.infos.datePayment = newData.infos.datePayment.format('x');
		}

		if (newData.infos.receiveDate) {
			newData.infos.receiveDate = newData.infos.receiveDate.format('x');
		}

		if (newData.relaunch && newData.relaunch.date) {
			newData.relaunch.date = newData.relaunch.date.format('x');
		}

		newData.payments = this.N_PaymentTab!.data;

		newData.order = newData.order.internalNumber;

		return newData;
	}

	private updateDisplayWithoutOrder() {
		const order = this.form?.getDataByName('order') as string;

		if (!order) {
			const N_openOrderButton = this.el.querySelector('#open_order') as HTMLButtonElement;
			const N_containerFormOrder = this.el.querySelector('#container-form-order') as HTMLElement;
			const N_containerFormIndex = this.el.querySelector('#container-form-index') as HTMLElement;

			N_openOrderButton.classList.add('disabled');

			N_containerFormOrder.classList.add('d-none');
			N_containerFormOrder.classList.remove('d-flex');
			N_containerFormIndex.classList.add('d-none');
			N_containerFormIndex.classList.remove('d-flex');
		}
	}

	private updateDisputesButton() {
		const N_disputes = this.el.querySelector('#disputes') as HTMLButtonElement;
		const isDispute = this.form?.getDataByName('disputes.enabled') as boolean;

		N_disputes!.lastElementChild!.innerHTML = isDispute ? 'Clôturer le litige' : 'Mettre en litige';
	}

	private updatePrice() {
		const priceTTC = DecimalApps.setDisplayNumber(this.form?.getDataOnAllDataByName('priceTTC'));
		const paid = this.N_PaymentTab!.getPaidPrice();

		const paidPriceModel = PriceWithPercentModel.calculAndConvertToModel(paid, priceTTC);
		const notPaymentPriceModel = PriceWithPercentModel.calculAndConvertToModel(priceTTC.minus(paid), priceTTC);

		this.form?.setDataByName('paymentPrice', paidPriceModel.toDocument());
		this.form?.setDataByName('notPaymentPrice', notPaymentPriceModel.toDocument());
	}

	private async updateProvider() {
		const providerID = this.form?.getDataByName('infos.provider');

		const addresses = await S_P_Address.getInstance().getByProviderToSelect2(providerID as string);

		if (addresses.length && providerID) {
			const fullAddress = await S_P_Address.getInstance().getFullAddress(addresses[0]?.id);
			this.form?.setDataByName('address.id', addresses[0]);
			this.form?.setDataByName('address.text', fullAddress);
		} else {
			this.form?.setDataByName('address.id', { id: '', text: '' });
			this.form?.setDataByName('address.text', '');
		}
	}

	private async renderTVA(data: { [key: string]: any }) {
		const N_tvaContainer = this.el.querySelector<HTMLElement>('.tva-container')!;

		N_tvaContainer.innerHTML = '';
		let sum = DecimalApps.setDisplayNumber(data.priceHT);

		for (const IDTVA in data.tva) {
			const N_div = document.createElement('div');
			N_div.classList.add('page-card-block-value');

			N_div.innerHTML = `
				<div class= "page-card-label">TVA ${(await new S_Taxe().getById(IDTVA)).rate}% : </div>
				<div class="page-card-value" name="tva.${IDTVA}" type="price"> </div>
			`;

			N_tvaContainer.append(N_div);

			sum = sum.plus(DecimalApps.setDisplayNumber(data.tva[IDTVA]));
		}

		this.form?.setDataByName('priceTTC', sum.toNumber());

		this.form?.updateInputs();
	}

	private updateTTC() {
		const data = this.form?.getData();

		let sum = DecimalApps.setDisplayNumber(data.priceHT);

		for (const IDTVA in data.tva) {
			sum = sum.plus(DecimalApps.setDisplayNumber(data.tva[IDTVA]));
		}

		this.form?.setDataByName('priceTTC', sum.toNumber());
	}

	/**
	 * On refresh le numéro interne de la facture
	 * @param newData 
	 */
	protected async postSave(newData: { [key: string]: any }) {
		if (!this.form?.getDataByName('infos.internalNumber')) {
			this.form?.setDataByName('infos.internalNumber', newData.infos.internalNumber);
			this.updateTitle();
		}
	}
}

export default BillsProviderCtrl;
