import DecimalApps from '@js/libs/utils/Decimal';

import '../../../../css/customElement/viewProduct/providerProduct.scss';

type dataProviderProduct = {
	isDefault: boolean,
	provider: string
	reference: string
	label: string
	public_price: string
	discount: string
	purchasePrice: string
	shipPrice: string
	costPrice: string
	marginRate: string
	recommendedSellPrice: string
};

class ProviderProduct extends HTMLElement {
	public static readonly tagName: string = 'ap-view-product-provider';

	public connectedCallback() {
		this.innerHTML = `
			<div class="header">
				<div class="isDefault cell-loading">
					<div class="text">Par défaut :&nbsp;</div>
					<div class="value"></div>
				</div>
				<div class="provider cell-loading">
					<div class="text">Fournisseur :&nbsp;</div>
					<div class="value"></div>
				</div>
				<div class="reference cell-loading">
					<div class="text">Référence :&nbsp;</div>
					<div class="value"></div>
				</div>
				<div class="label cell-loading">
					<div class="text">Désignation :&nbsp;</div>
					<div class="value"></div>
				</div>
			</div>
			<div class="content cell-loading">
				<div class="public_price_text">Prix public</div>
				<div class="public_price_value"></div>
				
				<div class="discount_text">Remise</div>
				<div class="discount_value"></div>
				
				<div class="purchase_price_text">Prix d'achat</div>
				<div class="purchase_price_value"></div>
				
				<div class="shipPrice_text">Prix de transport</div>
				<div class="shipPrice_value"></div>

				<div class="costPrice_text">Cout de revient</div>
				<div class="costPrice_value"></div>

				<div class="marginRate_text">Taux marges</div>
				<div class="marginRate_value"></div>

				<div class="recommendedSellPrice_text">Prix de vente conseillé</div>
				<div class="recommendedSellPrice_value"></div>
			</div>
		`;
	}

	public set data(data: dataProviderProduct) {
		const N_provider = this.querySelector<HTMLElement>('.provider .value')!;
		const N_reference = this.querySelector<HTMLElement>('.reference .value')!;
		const N_label = this.querySelector<HTMLElement>('.label .value')!;
		const N_isDefault = this.querySelector<HTMLElement>('.isDefault .value')!;

		const N_public_price = this.querySelector<HTMLElement>('.public_price_value')!;
		const N_discount = this.querySelector<HTMLElement>('.discount_value')!;
		const N_purchase_price = this.querySelector<HTMLElement>('.purchase_price_value')!;
		const N_shipPrice = this.querySelector<HTMLElement>('.shipPrice_value')!;
		const N_costPrice = this.querySelector<HTMLElement>('.costPrice_value')!;
		const N_marginRate = this.querySelector<HTMLElement>('.marginRate_value')!;
		const N_recommendedSellPrice = this.querySelector<HTMLElement>('.recommendedSellPrice_value')!;

		N_isDefault.innerHTML = data.isDefault ? 'OUI' : 'NON';

		N_isDefault.classList.toggle('yes', data.isDefault);
		N_isDefault.classList.toggle('no', !data.isDefault);

		N_provider.innerHTML = data.provider;
		N_reference.innerHTML = data.reference;
		N_label.innerHTML = data.label;

		N_public_price.innerHTML = DecimalApps.setDisplayNumber(data.public_price).setSuffixAndHumanizeNumber('€');
		N_discount.innerHTML = DecimalApps.setDisplayNumber(data.discount).setSuffixAndHumanizeNumber('%');
		N_purchase_price.innerHTML = DecimalApps.setDisplayNumber(data.purchasePrice).setSuffixAndHumanizeNumber('€');
		N_shipPrice.innerHTML = DecimalApps.setDisplayNumber(data.shipPrice).setSuffixAndHumanizeNumber('€');
		N_costPrice.innerHTML = DecimalApps.setDisplayNumber(data.costPrice).setSuffixAndHumanizeNumber('€');
		N_marginRate.innerHTML = DecimalApps.setDisplayNumber(data.marginRate).setSuffixAndHumanizeNumber('%');
		N_recommendedSellPrice.innerHTML = DecimalApps.setDisplayNumber(data.recommendedSellPrice).setSuffixAndHumanizeNumber('€');
	}

	public static register() {
		customElements.define(ProviderProduct.tagName, ProviderProduct);
	}
}

export default ProviderProduct;
