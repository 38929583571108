import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_Modal from '../../../tpl/modals/AddQuote/AddBrochure.html';

import S_Q_Brochures from '@services/Quotes/BrochureQuoteService';
class AddBrochure extends Modal {
	constructor(id: string = '') {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			let data: { [key: string]: any } = {};

			if (id) {
				data = await S_Q_Brochures.getInstance().getById(id);

				form.setData(data);
			}

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				const newData = form.getData() as { [key: string]: any };

				newData._id = id || Date.now().toString(36);

				await S_Q_Brochures.getInstance().save(newData);

				this.resolve(newData);
			});
		});
	}
}

export default AddBrochure;
