import { global } from '@autoprog/core-client';

import agUtils from '@libs/agGrid/french';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import h from 'hyperscript';

import M_mobile from '../modals/EditTrackingProduct';
import M_scan from '../modals/ScanTrackingNumber';

import Select2Utils from '@libs/utils/select2Utils';
import SelectEditor from '@libs/agGrid/SelectEditor';
import SettingsApps from '@libs/Settings';

import S_Product from '@services/Product/ProductService';
import S_TrackingProduct from '@services/TrackingProductService';

class TrackingProductTab extends HTMLElement {
	public static readonly tagName: string = 'ap-tracking-product-tab';

	public static readonly receptionMode: string = 'reception';
	public static readonly customerOrderMode: string = 'customerOrder';

	private tabId: string;
	private selectorTab: string;
	private context: string | null;

	private _gridOptions: GridOptions = {};

	private trackingProductService: S_TrackingProduct;

	constructor() {
		super();
		this.context = this.getAttribute('mode');
		this.tabId = this.getAttribute('tabID') || 'trackingProduct';
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.trackingProductService = S_TrackingProduct.getInstance();
	}

	public async connectedCallback() {
		const type = this.getAttribute('type');

		if (type === 'page') {
			this.innerHTML = `
				<li>
					<a data-toggle="tab" href="#${this.tabId}" role="tab">
						<div class="icon-container" tooltip="Produits suivis">
							<i class="icon icon-solid-sitemap"></i>
						</div>
						<span>Produits suivis</span>
					</a>
				</li>
			`;
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3" data-toggle="tab" href="#${this.tabId}" role="tab">
						<i class="icon icon-solid-sitemap"></i>
						Produits suivis
					</a>
				</li>
			`;
		}
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.tabId}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public setParentElement(parent: HTMLElement, context?: string) {
		if (context) {
			this.context = context;
		}
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		const N_div = document.createElement('div');

		N_div.classList.add('tab-pane', 'h-100', 'fade');
		N_div.setAttribute('role', 'tabpanel');
		N_div.id = this.tabId;
		N_div.innerHTML = `
            <div class="d-flex flex-column h-100">
				<div class="flex-grow-1">
					<button class="ml-auto btn btn-info d-none" id="barcode">
						Code barre
					</button>
				</div>
                <div class="h-100 ag-theme-alpine grid"></div>
            </div>
        `;

		const N_barcode = N_div.querySelector('#barcode') as HTMLButtonElement;

		const appSettings = ((SettingsApps.getInstance().get('APPLICATION') || {}) as { [key: string]: any });

		if (appSettings.trackingProducts?.barcode?.visible) {
			N_barcode.classList.remove('d-none');
		}

		N_barcode.addEventListener('click', async () => {
			const data = this.data;

			const tmp = await new M_scan(data).open();

			this.data = tmp;
		});

		N_container.append(N_div);

		if (!global.IS_MOBILE) {
			this.initGrid();
		}
	}

	private getSelect2() {
		const select2Tp = new Select2Utils('tracking-products');

		//On set une référence sur le produit correspondant
		select2Tp.setRefCallback('product', () => {
			const cells = this._gridOptions.api?.getEditingCells()!;

			let product = '';
			this._gridOptions.api?.forEachNode((node) => {
				if (cells[0].rowIndex === node.rowIndex) {
					product = node.data.product._id;
				}
			});

			return product;
		});

		//On renseigne les ids déjà utilisés
		select2Tp.setRefCallback('idUsed', () => {
			const res: any[] = [];
			this._gridOptions.api?.forEachNode((node) => {
				res.push({
					id: node.data.id,
					trackingNumber: node.data.trackingNumber
				});
			});
			return res;
		});

		//Si on est dans un bon de réception
		if (this.context === TrackingProductTab.receptionMode) {
			select2Tp.setRefValue('reception', true);
		}

		return select2Tp;
	}

	private initGrid() {
		this._gridOptions = agUtils.french({
			localeText: { noRowsToShow: 'Aucun produit suivi' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			suppressContextMenu: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Numéro de traçabilité',
					field: 'trackingNumber',
					pinned: 'left',
					width: 250,
					suppressSizeToFit: true,
					editable: (params) => {
						if (this.context === TrackingProductTab.receptionMode) {
							return !params.data.id;
						} else {
							return true;
						}
					},
					cellEditorParams: {
						options: {
							select2Utils: this.getSelect2(),
							overrideOption: {
								allowClear: true,
								escapeMarkup: function (markup: string) { return markup; },
								minimumInputLength: 1,
								language: {
									noResults: function () {
										return '<span class="text-danger">Numéro de traçabilité existant</span>';
									},
									inputTooShort: function () {
										return '<span class="text-muted">Aucun numéro renseigné</span>';
									}
								}
							},
							rootElement: this
						}
					},
					cellEditor: SelectEditor,
					cellRenderer: (params) => {
						if (!params.colDef.editable(params)) {
							return params.value;
						} else {
							if (params.value) {
								const element = h('div.d-flex',
									h('span.flex-grow-1', params.value),
									h('button.btn.btn-no-background', h('i.icon.icon-solid-times'))
								);
								element.querySelector('button')!.addEventListener('click', () => {
									params.data.trackingNumber = null;
									params.data.id = null;
									params.node!.setData(params.data);
									params.api.refreshCells({ force: true, rowNodes: [params.node] });
								});
								return element;
							} else {
								return null;
							}
						}
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: `product.${S_Product.getInstance().referenceKey}`,
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							return S_Product.getInstance().cellRendererByData(params.data.product);
						}
					}
				},
				{
					headerName: 'Produit',
					field: 'product.name'
				},
				{
					headerName: 'Commentaire',
					field: 'comment',
					editable: true
				}
			],
			onCellEditingStopped: async (params) => {
				if (params.colDef.field === 'trackingNumber') {
					if (params.data.trackingNumber) {
						const trackingProduct = await this.trackingProductService.getById(params.data.trackingNumber);
						if (trackingProduct) {
							params.data = trackingProduct;
						} else {
							params.data.id = null;
						}
					} else {
						params.data.id = null;
					}
					params.node!.setData(params.data);
					params.api.refreshCells({ force: true, rowNodes: [params.node] });
				}
			}
		} as GridOptions);

		new Grid(document.querySelector(`#${this.tabId} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
		this.gridOptions.api?.setRowData([]);
	}

	private initMobile(trackingProducts: any[]) {
		const container = document.querySelector(`#${this.tabId} .grid`) as HTMLDivElement;

		for (const item of trackingProducts) {
			container.appendChild(this.templateInitMobile(item, trackingProducts));
		}
	}

	private templateInitMobile(item: { [key: string]: any }, trackingProducts: any[]) {
		const N_div = document.createElement('div');
		N_div.classList.add('card-mobile', 'flex-column');

		N_div.innerHTML = `
			<div class="font-weight-bold flex-grow-1 w-100 text-truncate">
					${item.product[S_Product.getInstance().referenceKey]} ${item.product.name}
			</div>
			<div>
				Numéro de traçabilité : ${item.trackingNumber || '<span class="text-muted">aucun</span>'}
			</div>
			<div>
				Commentaire :
			</div>
			<div>
				${item.comment || '<span class="text-muted">aucun</span>'}
			</div>
		`;

		N_div.addEventListener('click', () => {
			new M_mobile(item, trackingProducts, this.context || '').open().then(() => {

			});
		});

		return N_div;
	}

	public set data(trackingProducts: any) {
		this.gridOptions.rowData = trackingProducts;
		this.gridOptions.api?.setRowData(trackingProducts);

		if (global.IS_MOBILE) {
			this.initMobile(trackingProducts);
		}
	}

	public get data(): any {
		const results: any[] = [];

		this._gridOptions.api?.forEachNode((node) => {
			results.push(node.data);
		});

		return results;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(TrackingProductTab.tagName, TrackingProductTab);
	}
}

export default TrackingProductTab;
