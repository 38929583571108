import EditPageModal from '@libs/EditPageModal';

import { unit } from '../types/unit';

import T_modal from '../../tpl/modals/addUnit.html';

import Unit from '../model/Unit';

import UnitsService from '../services/UnitsService';

import '../../css/addUnit.scss';

class AddCompte extends EditPageModal<Partial<unit>> {
	private unit: Partial<unit> | null;

	constructor(data?: Partial<unit>) {
		super(data || {}, T_modal);
		this.unit = data || null;
	}

	protected preInit() {
		const title = this.element.querySelector<HTMLDivElement>('.modal-title');

		if (this.unit) {
			title!.innerText = 'EDITION UNITÉ';
		} else {
			title!.innerText = 'NOUVELLE UNITÉ';
		}
	}

	protected async closeModal(data: { [key: string]: any }) {
		await new UnitsService().save(data);
		this.resolve(data);
	}

	protected async getDataForm(data: unit) {
		return new Unit(data).toSave();
	}

	protected checkValidityForm() {
		return this.form?.checkValidity() || false;
	}
}

export default AddCompte;
