import ModalApps, { ModalOptions } from '../Modal';

import StepContent, { IStepContent } from './StepContent';

import CE_StepModal from '@js/libs/customElement/StepModalNav';

import '@css/modals/stepModal.scss';

class StepModal extends ModalApps {
	protected stepsInstances: StepContent[] = [];
	private currentIndex = 0;

	constructor(options: ModalOptions, index: number = 0) {
		super(options);

		this.currentIndex = index;

		this.on('opened', async () => {
			this.enabledLoading();

			this.setTheme();
			this.setTitle();

			await this.initStep();
			this.initEvent();

			this.renderStep();
			this.renderButtons();

			await this.setData();

			this.disabledLoading();
		});
	}

	private enabledLoading() {
		const N_prev = this.element.querySelector<HTMLButtonElement>('#btn-prev')!;
		const N_next = this.element.querySelector<HTMLButtonElement>('#btn-next')!;
		const N_save = this.element.querySelector<HTMLButtonElement>('#btn-save')!;

		N_prev.disabled = true;
		N_next.disabled = true;
		N_save.disabled = true;

		this.element.classList.add('loading');
	}

	private disabledLoading() {
		this.element.classList.remove('loading');
	}

	protected get steps(): IStepContent[] {
		return [];
	}

	private async initStep() {
		const N_body = this.element.querySelector<HTMLElement>('.modal-body')!;

		const N_StepModal = this.element.querySelector<CE_StepModal>(CE_StepModal.tagName);

		const dataStepModal: { text: string }[] = [];

		let index = 0;
		for (const Item of this.steps) {
			const N_div = document.createElement('div');
			N_div.classList.add('step');

			if (index < this.currentIndex) {
				N_div.classList.add('step-prev');
			}

			if (index === this.currentIndex) {
				N_div.classList.add('step-current');
			}

			if (index > this.currentIndex) {
				N_div.classList.add('step-next');
			}

			N_div.dataset.index = index.toString();

			N_div.innerHTML = `
				<div class="step-title"></div>
				<div class="step-content"></div>
			`;

			const N_content = N_div.querySelector<HTMLElement>('.step-content')!;
			const N_title = N_div.querySelector<HTMLElement>('.step-title')!;

			const step = new Item(N_content);

			N_content.innerHTML = step.getContent();
			N_title.innerHTML = step.getTitle();

			dataStepModal.push({ text: step.getTitle() });

			N_body.append(N_div);

			await step.init();

			this.stepsInstances.push(step);

			index++;
		}

		for (const instance of this.stepsInstances) {
			instance.setInstanceOtherStep(this.stepsInstances);
		}

		N_StepModal && (N_StepModal.data = dataStepModal);
	}

	private initEvent() {
		const N_prev = this.element.querySelector<HTMLButtonElement>('#btn-prev')!;
		const N_next = this.element.querySelector<HTMLButtonElement>('#btn-next')!;
		const N_save = this.element.querySelector<HTMLButtonElement>('#btn-save')!;
		const N_StepModal = this.element.querySelector<CE_StepModal>(CE_StepModal.tagName);

		N_prev.addEventListener('click', () => {
			this.prev();
		});

		N_next.addEventListener('click', () => {
			this.next();
		});

		N_save.addEventListener('click', () => {
			this.save();
		});

		N_StepModal?.addEventListener('step.change', ((e: CustomEvent<{ index: number }>) => {
			if (this.isFreeSave() || e.detail.index < this.currentIndex || (e.detail.index > this.currentIndex && this.stepsInstances[this.currentIndex].checkValidity())) {
				this.setActive(e.detail.index);
			}
		}) as EventListener);
	}

	protected async setData() {

	}

	private renderStep() {
		const N_item = this.element.querySelectorAll<HTMLElement>('[data-index]');

		for (const N_el of N_item) {
			const index = Number(N_el.dataset.index);

			if (index < this.currentIndex) {
				N_el?.classList.remove('step-current');
				N_el?.classList.remove('step-next');
				N_el?.classList.add('step-prev');
			}

			if (index === this.currentIndex) {
				N_el?.classList.remove('step-prev');
				N_el?.classList.remove('step-next');
				N_el?.classList.add('step-current');
			}

			if (index > this.currentIndex) {
				N_el?.classList.remove('step-prev');
				N_el?.classList.remove('step-current');
				N_el?.classList.add('step-next');
			}
		}

		const N_StepModal = this.element.querySelector<CE_StepModal>(CE_StepModal.tagName);
		N_StepModal?.setActive(this.currentIndex);

		this.stepsInstances[this.currentIndex].openStep();
	}

	private renderButtons() {
		const N_btnPrev = this.element.querySelector<HTMLButtonElement>('#btn-prev')!;
		const N_btnNext = this.element.querySelector<HTMLButtonElement>('#btn-next')!;
		const N_btnSave = this.element.querySelector<HTMLButtonElement>('#btn-save')!;

		N_btnPrev.disabled = true;
		N_btnNext.disabled = true;
		N_btnSave.disabled = true;

		if (this.currentIndex > 0) {
			N_btnPrev.disabled = false;
		}

		if (this.currentIndex < this.steps.length - 1) {
			N_btnNext.disabled = false;
		}

		if (this.currentIndex === this.steps.length - 1 || this.isEdit() || this.isFreeSave()) {
			N_btnSave.disabled = false;
		}
	}

	protected isEdit() {
		return false;
	}

	protected isFreeSave() {
		return false;
	}

	private setActive(index: number) {
		this.currentIndex = index;
		this.stepsInstances[this.currentIndex].setActive();
		this.renderStep();
		this.renderButtons();

		this.emit('step.changed', { index: this.currentIndex, instance: this.stepsInstances[this.currentIndex] });
	}

	private next() {
		if (this.isFreeSave() || this.stepsInstances[this.currentIndex].checkValidity()) {
			if (this.stepsInstances[this.currentIndex + 1]) {
				this.currentIndex++;
				this.stepsInstances[this.currentIndex].setActive();
				this.renderStep();
				this.renderButtons();

				this.emit('step.changed', { index: this.currentIndex, instance: this.stepsInstances[this.currentIndex] });
			}
		}
	}

	private prev() {
		if (this.stepsInstances[this.currentIndex - 1]) {
			this.currentIndex--;
			this.stepsInstances[this.currentIndex].setActive();
			this.renderStep();
			this.renderButtons();

			this.emit('step.changed', { index: this.currentIndex, instance: this.stepsInstances[this.currentIndex] });
		}
	}

	private setTitle() {
		const N_title = this.element.querySelector<HTMLElement>('.modal-title #title')!;

		N_title.innerHTML = this.getTitle();
	}

	private setTheme() {
		this.element.setAttribute('theme', this.getTheme());
	}

	protected save() {

	}

	protected checkValidity() {
		const N_StepModal = this.element.querySelector<CE_StepModal>(CE_StepModal.tagName);

		return this.stepsInstances.reduce((validity, step, stepIndex) => {
			const stepValidity = step.checkValidity();

			N_StepModal?.setValidity(stepValidity, stepIndex);

			return validity && stepValidity;
		}, true);
	}

	protected getTheme() {
		return 'add';
	}

	protected getTitle() {
		return '';
	}

	protected getData() {
		let result: { [key: string]: any } = {};

		for (const item of this.stepsInstances) {
			result = {
				...result,
				...item.getData()
			};
		}

		return result;
	}
}

export default StepModal;
