import { Module, Modules } from '@autoprog/core-client';

import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

import C_SettingsListUnit from './js/controllers/Settings.ListUnit';
import T_SettingsListUnit from './tpl/Settings.ListUnit.html';

class UnitsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async preInit() {
		const settingsRegister = SettingsRegister.getInstance();

		settingsRegister.addLink({
			Controller: C_SettingsListUnit,
			tpl: T_SettingsListUnit,
			order: 102,
			category: 'general',
			icon: 'menu/line',
			name: 'Unité'
		});
	}

	public async init() {

	}

	public async postInit() {
	}
}

Modules.registerModule('Unité', UnitsModule);
