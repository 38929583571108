import Decimal from '@libs/utils/Decimal';
import QuoteData from '../QuoteData';
import QuoteOption from '../QuoteOptions';

class CalculV3 {
	private constructor() { }

	public static calculatePrice(item: { [key: string]: any }, pref: { [key: string]: any }) {
		pref.price = pref.price || {};
		pref.marges = pref.marges || {};

		const coefTotal = Decimal.setDisplayNumber(pref.coefTotal || '1');

		const result = {
			price: new Decimal(0),
			sumMat: new Decimal(0),
			sumMO: new Decimal(0),
			sum: new Decimal(0)
		};

		const quantity = Decimal.setDisplayNumber(item.quantity);

		if (item.quantity && !item.disabled) {
			const type = item.type || pref.type || '';

			if (item.time && pref.price[type]) {
				const time = Decimal.setDisplayNumber(item.time);
				const prefPrice = Decimal.setDisplayNumber(pref.price[type]);

				let tmp = time.times(prefPrice);

				if (!item.notApplyCoefOnMO) {
					tmp = tmp.times(coefTotal);
				}

				result.sumMO = tmp.times(quantity);
			}

			if (item.price) {
				let tmp = new Decimal(0);
				let marge = new Decimal(0);

				const typeMat = item.typeMat || pref.typeMat || '';

				const price = Decimal.setDisplayNumber(item.price);
				const quantity = Decimal.setDisplayNumber(item.quantity);

				// Si on applique une marge commerciale
				if (pref.calculMarkUp) {
					// Marge commerciale = 1 - (taux marge / 100)
					marge = new Decimal(1).minus(Decimal.setDisplayNumber(pref.marges[typeMat]).dividedBy(100));

					// Caclul prix avec marge commerciale = Prix / Marge commerciale = prix / ( 1 - (taux marge / 100))
					tmp = price.dividedBy(marge);
				} else { // sinon on applique un coef simple
					marge = Decimal.setDisplayNumber(pref.marges[typeMat]).dividedBy(100).plus(new Decimal(1));

					tmp = price.times(marge);
				}

				if (typeMat === QuoteData.DISCOUNT) {
					result.sumMat = tmp.times(quantity.times(new Decimal(-1)));
				} else {
					if (!item.notApplyCoefOnMat) {
						tmp = tmp.times(coefTotal);
					}

					result.sumMat = tmp.times(quantity);
				}
			}
		}

		const decimalNumber = Decimal.setDisplayNumber(pref.decimalNumber).toNumber();

		result.sum = result.sumMO.plus(result.sumMat).toDecimalPlaces(decimalNumber, Decimal.ROUND_UP);

		return {
			sumMat: result.sumMat.toDecimalPlaces(2).toNumber(),
			sumMO: result.sumMO.toDecimalPlaces(2).toNumber(),
			sum: result.sum.toDecimalPlaces(decimalNumber, Decimal.ROUND_UP).toNumber(),
			price: result.sum.dividedBy(quantity).toDecimalPlaces(2).toNumber()
		};
	}

	public static calculateGlobalPrice(grid: any[], pref: { [key: string]: any } = {}) {
		const data = {
			all: new Decimal(0),
			option: new Decimal(0),
			notOption: new Decimal(0)
		};

		for (const group of grid) {
			let price = new Decimal(0);

			if (!group.disabled) {
				for (const item of group.details) {
					price = price.plus(this.calculatePrice(item, pref).sum);
				}
			}

			const quantity = Decimal.setDisplayNumber(group.quantity);

			const tmp = price.times(quantity);

			data.all = data.all.plus(tmp);

			if (group.isOption) {
				data.option = data.option.plus(tmp);
			} else {
				data.notOption = data.notOption.plus(tmp);
			}
		}

		const decimalNumber = Decimal.setDisplayNumber(pref.decimalNumber).toNumber();

		return {
			all: data.all.toDecimalPlaces(decimalNumber).toNumber(),
			option: data.option.toDecimalPlaces(decimalNumber).toNumber(),
			notOption: data.notOption.toDecimalPlaces(decimalNumber).toNumber()
		};
	}

	public static humanizeNumber(number: string, pref: { [key: string]: any }) {
		return Decimal.setDisplayNumber(number).humanizeNumber(pref.decimalNumber);
	}

	public static calculatePUGroup(data: any): number {
		let res = new Decimal(0);

		const pref = QuoteOption.getInstance().pref;

		if (!data.disabled) {
			for (const item of data.details) {
				if (item.quantity) {
					const tmp = CalculV3.calculatePrice(item, pref);
					res = res.plus(Decimal.setDisplayNumber(tmp.sum));
				}
			}
		}

		return res.toDecimalPlaces(pref.decimalNumber).toNumber();
	}

	public static calculateSumGroup(data: any): number {
		let res = new Decimal(0);

		const pref = QuoteOption.getInstance().pref;

		if (!data.disabled) {
			for (const item of data.details) {
				if (item.quantity) {
					const tmp = CalculV3.calculatePrice(item, pref);
					res = res.plus(Decimal.setDisplayNumber(tmp.sum));
				}
			}

			const quantity = Decimal.setDisplayNumber(data.quantity);
			res = res.times(quantity);
		}

		return res.toDecimalPlaces(pref.decimalNumber).toNumber();
	}
}

export default CalculV3;
