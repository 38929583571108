import { CompanyBank } from '../types/companyBank';

import BooleanModel from '@libs/model/_app/Boolean';
import StringModel from '@libs/model/_app/String';

class CompanyBankModel {
	private data: CompanyBank;

	constructor(data: CompanyBank) {
		this.data = data;
	}

	private convertRowData() {
		return {
			name: new StringModel(this.data.name),
			IBAN: new StringModel(this.data.IBAN),
			RIB: new StringModel(this.data.RIB),
			SWIFT: new StringModel(this.data.SWIFT),
			useCommandProvider: new BooleanModel(this.data.useCommandProvider),
			useBill: new BooleanModel(this.data.useBill),
			_rowData_: this.data
		};
	}

	public toDashboard() {
		const data = this.convertRowData();

		return {
			name: data.name.toDashboard(),
			IBAN: data.IBAN.toDashboard(),
			RIB: data.RIB.toDashboard(),
			SWIFT: data.SWIFT.toDashboard(),
			useCommandProvider: data.useCommandProvider.toDashboard(),
			useBill: data.useBill.toDashboard(),
			_rowData_: this.data
		};
	}

	public toSave(): CompanyBank {
		const data = this.convertRowData();

		return {
			name: data.name.toSave(),
			IBAN: data.IBAN.toSave(),
			RIB: data.RIB.toSave(),
			SWIFT: data.SWIFT.toSave(),
			useCommandProvider: data.useCommandProvider.toSave(),
			useBill: data.useBill.toSave()
		};
	}
}

export default CompanyBankModel;
