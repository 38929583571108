import DecimalApps from '@libs/utils/Decimal';

class PriceWithPercentModel {
	private value: number | DecimalApps;
	private percent: number | DecimalApps;

	public static calculAndConvertToModel(price: number | DecimalApps, sumPrice: number | DecimalApps) {
		if (!(price instanceof DecimalApps)) {
			price = DecimalApps.setDisplayNumber(price);
		}

		let percent = price.times(100).dividedBy(sumPrice);
		percent = percent.toDecimalPlaces(2);

		price = price.toDecimalPlaces(2);

		return new PriceWithPercentModel(price, percent);
	}

	constructor(value: number | DecimalApps, percent: number | DecimalApps) {
		this.value = value;
		this.percent = percent;
	}

	public toDecimal() {
		let value: DecimalApps;

		if (this.value instanceof DecimalApps) {
			value = this.value;
		} else {
			value = DecimalApps.setDisplayNumber(this.value);
		}

		return value;
	}

	public toNumber() {
		return this.toDecimal().toNumber();
	}

	public toDocument() {
		const value = this.toDecimal();
		let percent: DecimalApps;

		if (this.percent instanceof DecimalApps) {
			percent = this.percent;
		} else {
			percent = DecimalApps.setDisplayNumber(this.percent);
		}

		return `${value.setSuffixAndHumanizeNumber('€')} | ${percent.humanizePercent()}%`;
	}

	public toDashboard() {
		const value = this.toDecimal();

		let percent: DecimalApps;

		if (this.percent instanceof DecimalApps) {
			percent = this.percent;
		} else {
			percent = DecimalApps.setDisplayNumber(this.percent);
		}

		return {
			formattedValue: `${value.setSuffixAndHumanizeNumber('€')} | ${percent.humanizePercent()}%`,
			value: value.toNumber(),
			quickFilter: `${value.setSuffixAndHumanizeNumber('€')} | ${percent.humanizePercent()}%`
		};
	}

	public getText() {
		const value = this.toDecimal();

		let percent: DecimalApps;

		if (this.percent instanceof DecimalApps) {
			percent = this.percent;
		} else {
			percent = DecimalApps.setDisplayNumber(this.percent);
		}

		return `${value.setSuffixAndHumanizeNumber('€')} | ${percent.humanizePercent()}%`;
	}

	public toPrint() {
		const value = this.toDecimal();

		let percent: DecimalApps;

		if (this.percent instanceof DecimalApps) {
			percent = this.percent;
		} else {
			percent = DecimalApps.setDisplayNumber(this.percent);
		}

		return `${value.setSuffixAndHumanizeNumber('€')} | ${percent.humanizePercent()}%`;
	}
}

export default PriceWithPercentModel;
