// TEMPLATE
import T_SelectProduct from '../../tpl/modals/selectProduct.html';

// LIBS
import Loader from '@libs/Loader';
import Modal from '@libs/Modal';

// CUSTOM_ELEMENT
import CE_SelectProductTabs from '../libs/customElement/SelectProductTabs';

//SERVICES
import S_Products from '@services/Product/ProductService';

class SelectProductModal extends Modal {
	constructor() {
		super({
			tpl: T_SelectProduct,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			Loader.getInstance().open();

			const N_tabs = this.element.querySelector('ap-select-products-tabs') as CE_SelectProductTabs;

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				const { products } = N_tabs.data;

				//FIXME: il faudrait que les données renvoyés  par N_tabs.data soit correctement formattées
				//Revoir les données d'un produit renvoyées par le backend
				for (const id in products) {
					const product = products[id].product;

					const productInfos = await S_Products.getInstance().getById(product._id);
					productInfos.defaultProvider = product.defaultProvider;
					products[id].product = productInfos;
				}

				this.resolve(N_tabs.data);
			});

			Loader.getInstance().close();
		});
	}
}

export default SelectProductModal;
