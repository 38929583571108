import { Moment } from 'moment';

class QuoteRelance extends HTMLElement {
	public static readonly tagName: string = 'ap-quote-relance';

	private sendDate: Moment | null = null;

	private N_checkbox: HTMLInputElement | null = null;
	private N_type: HTMLSelectElement | null = null;
	private N_date: HTMLInputElement | null = null;

	public async connectedCallback() {
		const nameRelunchDisabled = this.getAttribute('name-relunch-disabled') as string;
		const nameRelunchType = this.getAttribute('name-relunch-type') as string;
		const nameRelunchDate = this.getAttribute('name-relunch-date') as string;

		this.innerHTML = `
            <div class="mt-2">

				<div class="row align-items-center">
					<div class="col-6">
						<label class="col-form-label">Désactiver la relance ? *</label>
					</div>
					<div class="col-6">
						<label class="switch cursor-pointer">
							<input type="checkbox" name="${nameRelunchDisabled}">
							<span class="slider round"></span>
						</label>
					</div>
				</div>

				<div class="row align-items-center">
					<div class="col-6">
						<label class="col-form-label">Durée avant relance * :</label>
					</div>
					<div class="col-6">
						<select name="${nameRelunchType}" class="mt-1 form-control">
							<option value="1_week">1 semaine</option>
							<option value="2_week">2 semaines</option>
							<option value="3_week">3 semaines</option>
							<option value="1_month">1 mois</option>
							<option value="2_month">2 mois</option>
						</select>
					</div>
				</div>

				<div class="row mt-2 align-items-center">
					<div class="col-6">
						<label class="col-form-label">Date relance * :</label>
					</div>
					<div class="col-6">
						<input name="${nameRelunchDate}" type="date" class="mt-1 form-control">
					</div>
				</div>
			</div>
        `;

		this.N_checkbox = this.querySelector(`[name="${nameRelunchDisabled}"]`) as HTMLInputElement;
		this.N_type = this.querySelector(`[name="${nameRelunchType}"]`) as HTMLSelectElement;
		this.N_date = this.querySelector(`[name="${nameRelunchDate}"]`) as HTMLInputElement;

		this.initEvent();
	}

	private initEvent() {
		this.N_checkbox!.addEventListener('change', () => {
			this.updateForm();
		});

		this.N_type!.addEventListener('change', () => {
			this.updateDate();
		});
	}

	public setSendDate(value: Moment) {
		this.sendDate = value;
		this.updateDate();
	}

	private updateDate() {
		if (this.sendDate) {
			const relunchType = this.N_type!.value;

			const [number, type] = relunchType.split('_');

			const date = this.sendDate.clone();

			date.add(number, type as any);

			this.N_date!.valueAsDate = date.toDate();
		}
	}

	private updateForm() {
		const nameRelunchDisabled = this.getAttribute('name-relunch-disabled') as string;
		const nameRelunchType = this.getAttribute('name-relunch-type') as string;
		const nameRelunchDate = this.getAttribute('name-relunch-date') as string;

		const N_checkbox = this.querySelector(`[name="${nameRelunchDisabled}"]`) as HTMLInputElement;
		const N_type = this.querySelector(`[name="${nameRelunchType}"]`) as HTMLSelectElement;
		const N_date = this.querySelector(`[name="${nameRelunchDate}"]`) as HTMLInputElement;

		N_type.disabled = N_checkbox.checked;
		N_date.disabled = N_checkbox.checked;
	}

	public postInit() {
		this.updateForm();
	}

	public static register() {
		customElements.define(QuoteRelance.tagName, QuoteRelance);
	}
}

export default QuoteRelance;
