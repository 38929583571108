import { Alert, Form, Modal, Permissions, services, toaster } from '@autoprog/core-client';

import T_addGroup from '../../../tpl/modals/groups/AddEditGroup.html';

export interface Permission {
	name: string;
	description?: string;
	children?: RegistredPermissions;
	required?: string[];
	fullKey: string;
}
export interface RegistredPermissions {
	[key: string]: Permission;
}
class AddEditGroup extends Modal {
	private permissions: RegistredPermissions;
	private form?: Form;

	private s_groups: any;

	constructor(id?: string | number) {
		super({
			tpl: T_addGroup,
			backdrop: 'static',
			keyboard: false
		});

		this.permissions = {};

		this.s_groups = services.GroupsService.getInstance();

		this.on('opened', async () => {
			const N_saveButton = this.element.querySelector<HTMLButtonElement>('#save-group-button')!;
			const N_Permissions = this.element.querySelector<any>('permissions-selector')!;

			this.form = new Form(this.element.querySelector<HTMLFormElement>('#add-group-form')!);

			this.permissions = Permissions.get() as RegistredPermissions;
			N_Permissions.datasource = this.permissions;

			if (id) {
				(this.element.querySelector('.modal-title') as HTMLElement).innerHTML = 'Modification Groupe';

				try {
					const group = await this.s_groups.getById(id);

					if (this.form) {
						this.form.setData(group as any);
						N_Permissions.values = group.permissions;
					}

					N_saveButton.addEventListener('click', async () => {
						if (this.form && this.form.checkValidity()) {
							const data = this.form.getData();

							try {
								await this.s_groups.update(id, {
									name: data.name,
									permissions: N_Permissions.values as any
								});
								toaster.success('Groupe enregistré');
								this.resolve();
							} catch (e) {
								Alert.error('Erreur lors de l\'enregistrement', '', (e as any));
							}
						}
					});
				} catch (e) {
					Alert.error('Erreur de chargement', '', (e as any));
				}
			} else {
				N_saveButton.addEventListener('click', async () => {
					if (this.form && this.form.checkValidity()) {
						try {
							const data = this.form.getData();
							data.permissions = N_Permissions.values as any;
							await this.s_groups.add(data);
							toaster.success('Groupe enregistré');
							this.resolve();
						} catch (e) {
							Alert.error('Erreur lors de l\'enregistrement', '', (e as any));
						}
					}
				});
			}
		});
	}
}

export default AddEditGroup;
