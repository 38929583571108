// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import moment, { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '@tpl/modals/addNote.html';

// LIBS
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';

class AddNote extends Modal {
	constructor(data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_form = this.element.querySelector('form') as HTMLFormElement;

			const form = new Form(N_form);

			if (_.isEmpty(data)) {
				form.setData({
					date: moment()
				});
			} else {
				data.date = moment(data.date, 'x');

				form.setData(data);
			}

			const N_text = this.element.querySelector('[name="text"]') as HTMLTextAreaElement;

			N_text.focus();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				data = {
					...data,
					...form.getData(),
					user: data.user || Utils.userID
				};

				data.date = (data.date as Moment).format('x');

				this.resolve(data);
			});
		});
	}
}

export default AddNote;
