import SettingsController from '@modules/Settings/js/libs/SettingsController';

import '../../css/settings.userGroup.scss';

import SettingsGroup from './Settings.Group';
import SettingsUser from './Settings.User';

class SettingsUserGroup extends SettingsController {
	private instance: SettingsUser | SettingsGroup | null;

	constructor(el: HTMLElement) {
		super(el);

		this.instance = null;

		this.render('users');

		const N_tabs = this.el.querySelectorAll<HTMLElement>('[data-tab]');

		for (const N_item of N_tabs) {
			N_item.addEventListener('click', () => {
				this.render(N_item.dataset.tab!);
			});
		}
	}

	private render(id: string) {
		this.instance?.destructor();

		const N_tab = this.el.querySelector<HTMLElement>('[data-tab].active');
		N_tab?.classList.remove('active');

		const N_newTab = this.el.querySelector<HTMLElement>(`[data-tab="${id}"]`)!;
		N_newTab.classList.add('active');

		if (id === 'users') {
			this.instance = new SettingsUser(this.el);
		}

		if (id === 'groups') {
			this.instance = new SettingsGroup(this.el);
		}
	}

	public destructor() {

	}
}

export default SettingsUserGroup;
