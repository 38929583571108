// CORE
import { Form } from '@autoprog/core-client';

// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/generalInformation.html';

// LIBS
import Modal from '@libs/Modal';

// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Customer from '@services/Customer/CustomerService';

type GeneralInformationData = {
	infos: {
		customer: string
		contact: string
		label: string
	}
};

class GeneralInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	constructor(data: GeneralInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const formData = this.form?.getData() as GeneralInformationData;

					const res: { [key: string]: any } = formData;

					res.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(formData.infos.customer);
					res.infos.contact = await S_C_Contact.getInstance().getDataToSelect2ByID(formData.infos.contact);

					this.resolve(res);
				}));
			});
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;

		N_customer.create(this.element, { disabled: true });

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.contact'] = N_contact;
	}

	private async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infos.contact),
				label: data.infos.label
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default GeneralInformation;
