import { DOM } from '@autoprog/core-client';
import h from 'hyperscript';

import SettingsController from '@modules/Settings/js/libs/SettingsController';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';

import UnitsService from '../services/UnitsService';

import M_AddUnit from '../modals/AddUnit';

class ListUnitTab extends SettingsController {
	private unitsService: UnitsService;

	constructor(el: HTMLElement) {
		super(el);

		this.unitsService = new UnitsService();
	}

	protected async init() {
		this.initTaxe();
		this.initButtons();

		this.getData();
	}

	private initTaxe() {
		const N_grid = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_grid.setGridOptions({
			columnDefs: [{
				headerName: 'Unité',
				field: 'unit',
				width: 100,
				suppressSizeToFit: true
			}, {
				headerName: 'Libellé',
				field: 'label'
			}, {
				headerName: 'Longueur',
				field: 'forLength',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: 'Poids',
				field: 'forWeight',
				width: 160,
				suppressSizeToFit: true
			}, {
				headerName: '',
				width: 80,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					const id = params.node.data._id.value;

					const N_edit = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { type: 'edit' } });

					N_edit.addEventListener('click', () => {
						new M_AddUnit(params.node.data._rowData_).open().then(() => {
							this.getData();
						});
					});

					const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { type: 'delete', confirmation: 'true' } });

					N_delete.addEventListener('click', async () => {
						await this.unitsService.delete(params.node.data._id.value);
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit, N_delete);

					DOM.nextTick().then(() => {
						N_edit.disabled = (id === 'kilogram' || id === 'default_unit');
						N_delete.disabled = (id === 'kilogram' || id === 'default_unit');
					});

					return N_div;
				}
			}],
			suppressContextMenu: true,
			defaultColDef: {
				resizable: true,
				suppressMovable: true,
				suppressMenu: true
			}
		});
	}

	private initButtons() {
		const N_add_unit = this.el.querySelector<HTMLButtonElement>('#add-unit')!;

		N_add_unit.addEventListener('click', () => {
			new M_AddUnit().open().then(() => {
				this.getData();
			});
		});
	}

	private async getData() {
		const units = await this.unitsService.getDataToAgGrid();

		const N_grid_units = this.el.querySelector<CE_AgGrid>('#grid')!;

		N_grid_units.resetValue();

		N_grid_units.value = units?.rowData || [];
	}
}

export default ListUnitTab;
