import h from 'hyperscript';

import '../../css/customElements/settingsCompanyListItem.scss';

import { LinkItem } from '../libs/SettingsRegister';

class SettingsCompanyListItem extends HTMLElement {
	public static readonly tagName: string = 'ap-setting-company-list-item';

	private _id: string = '';

	private static index = 0;

	private N_title: HTMLElement | null = null;
	private N_content: HTMLElement | null = null;

	public connectedCallback() {
		this.innerHTML = '';

		this.append(this.N_title!, this.N_content!);

		this._id = Date.now().toString(36) + '_' + SettingsCompanyListItem.index;
		SettingsCompanyListItem.index++;

		const N_tile = this.querySelector<HTMLElement>('.company-title')!;

		N_tile.addEventListener('click', () => {
			this.dispatchEvent(new CustomEvent('click-title'));
		});
	}

	public get id() {
		return this._id;
	}

	public close() {
		this.classList.remove('open');
	}

	public toggle() {
		this.classList.toggle('open');
	}

	public setTitle(value: string) {
		this.N_title = this.querySelector<HTMLElement>('.company-title') || document.createElement('div');
		this.N_title.classList.add('company-title');
		this.N_title.innerHTML = value;
	}

	public setContent(values: LinkItem[]) {
		this.N_content = this.querySelector<HTMLElement>('.company-container') || document.createElement('div');
		this.N_content.classList.add('company-container');

		for (const page of values) {
			const icon = page.icon ? `<ap-icon name="${page.icon}"></ap-icon>` : '';

			const N_item = h('div.company-link#' + page.id, { innerHTML: `${icon}${page.name}` });

			N_item.addEventListener('click', () => {
				this.dispatchEvent(new CustomEvent('resetActive', { detail: page }));
				N_item.classList.add('active');
				this.dispatchEvent(new CustomEvent('selectTabs', { detail: page }));
			});

			this.N_content.append(N_item);
		}

		this.N_content.style.setProperty('--number-line', values.length.toString());
	}

	public static register() {
		customElements.define(SettingsCompanyListItem.tagName, SettingsCompanyListItem);
	}
}

export default SettingsCompanyListItem;
