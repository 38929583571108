class SitesFinalCustomer extends HTMLElement {
	public static readonly tagName: string = 'ap-sites-final-customer';

	public connectedCallback() {
		const nameSitesSelect = this.getAttribute('name-sites-select') as string;
		const nameSiteCustom = this.getAttribute('name-site-custom') as string;
		const nameFinalCustomer = this.getAttribute('name-final-customer') as string;
		const nameHasFinalCustomer = this.getAttribute('name-has-final-customer') as string;

		this.classList.add('mb-2');

		this.innerHTML = `
			<div class="d-flex align-items-center ${this.className}">
				<span class="font-weight-bold">Sous-Traitance</span>
				<label class="switch ml-2 cursor-pointer">
					<input type="checkbox" name="${nameHasFinalCustomer}" id="switch">
					<span class="slider round"></span>
				</label>
			</div>
			<div class="form-group" id="containerFinalCustomers">
				<label>Client final</label>
				<div class="w-100">
					<input type="text" class="form-control" placeholder="Seulement en cas de sous-traitance" name="${nameFinalCustomer}">
				</div>
			</div>
			<div class="form-group">
				<label>Site(s)</label>
				<ap-select2-button name="${nameSitesSelect}" table="sites"></ap-select2-button>
				<input class="form-control" name="${nameSiteCustom}" id="custom" placeholder="Site du client final"/>
			</div>
        `;

		this.update(false);

		this.classList.add('w-100');
	}

	private update(value: boolean) {
		const N_select = this.querySelector('ap-select2-button') as HTMLElement;
		const N_inputCustom = this.querySelector('#custom') as HTMLElement;

		const N_containerFinalCustomers = this.querySelector('#containerFinalCustomers') as HTMLElement;

		if (value) {
			N_select.classList.add('d-none');
			N_inputCustom.classList.remove('d-none');
			N_containerFinalCustomers.classList.remove('d-none');
		} else {
			N_select.classList.remove('d-none');
			N_inputCustom.classList.add('d-none');

			N_containerFinalCustomers.classList.add('d-none');
		}
	}

	public setFinalCustomer() {
		const N_checkbox = this.querySelector('#switch') as HTMLInputElement;

		N_checkbox.addEventListener('change', () => {
			this.update(N_checkbox.checked);
		});

		this.update(N_checkbox.checked);
	}

	public static register() {
		customElements.define(SitesFinalCustomer.tagName, SitesFinalCustomer);
	}
}

export default SitesFinalCustomer;
