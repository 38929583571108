import axios from 'axios';

import Utils from '@libs/utils/Utils';

type saveData = {
	ok: boolean,
	err?: string,
	data: {
		data: any,
		history?: any
	}
};

class SettingsService {
	protected baseURI: string = '/api/';
	protected urlService: string;

	constructor(urlService: string) {
		this.urlService = urlService;
	}

	public get uri(): string {
		return this.baseURI.concat(this.urlService);
	}

	public async getByCompany(companyID: string) {
		const { data } = await axios.get(`${this.uri}/getByCompany`, {
			params: {
				companyID
			}
		});
		return data.data;
	}

	public async getDataByCompany(companyID: string) {
		const { data } = await axios.get(`${this.uri}/getDataByCompany`, {
			params: {
				companyID
			}
		});
		return data.data;
	}

	public async save(data: { [key: string]: any }): Promise<saveData> {
		const result = await axios.post<saveData>(`${this.uri}/save`, {
			user: Utils.userID,
			data
		});

		return result.data;
	}
}

export default SettingsService;
