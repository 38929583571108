import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import Unit from '../model/Unit';
import UnitsService from '../services/UnitsService';

class UnitCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;
	private value: string;

	private UnitsService: UnitsService;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.innerHTML = '';

		this.UnitsService = new UnitsService();
		this.value = '';
	}

	public init(params: any) {
		this.value = params.value;

		if (this.value) {
			this.UnitsService.getById(this.value).then((data) => {
				if (data) {
					this.eGui.innerHTML = new Unit(data).displayAggrid();
				} else {
					this.eGui.innerHTML = '-';
				}
			}).catch(() => {
				this.eGui.innerHTML = '-';
			});
		} else {
			this.eGui.innerHTML = '-';
		}
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default UnitCellRenderer;
