import Form from '@libs/FormControllerPageID';

import _ from 'lodash';

import M_Edit from '../../modals/QuoteOptions';

import QuoteData from '../QuoteData';
import QuoteOption from '../QuoteOptions';

import S_Account from '@modules/Comptabilité/js/services/AccountsService';
import Settings from '@modules/Settings/js/libs/Settings';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};

class InputDeleteBtnReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-input-readonly';

	public async connectedCallback() {
		const text = this.getAttribute('text');
		const name = this.getAttribute('name') || '';
		const type = this.getAttribute('type') || '';

		const settingsAccounting = Settings.getInstance().get('accountings');

		const nameCompta = name.replace('price', 'comptaVente');

		this.innerHTML = `			
			<div class="page-card-block-value">
				<div class="page-card-label">${text} :</div>
				<div class="page-card-value" type="${type === 'hourlyPrice' ? 'price' : 'percentage'}" name="${name}"></div>
				<div id="compta">
					(<div name="${nameCompta}" defaultvalue="Aucun compte comptable"></div>)
				</div>
			</div>
		`;

		const N_compta = this.querySelector('#compta') as HTMLElement;

		if (name.split('.')[1] !== 'price') {
			N_compta.remove();
		}

		if (!settingsAccounting?.enabled) {
			this.classList.add('no-compta');
		}

		this.removeAttribute('name');
	}

	public static register() {
		customElements.define(InputDeleteBtnReadonly.tagName, InputDeleteBtnReadonly);
	}
}

class QuoteOptionTabReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-tab-readonly';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_tab: HTMLElement | null = null;

	private quoteOptions: QuoteOption | null = null;

	private form: Form | null = null;

	private _isLock: boolean = false;

	private N_div: HTMLDivElement | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || '';

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-settings/line" icon-active="file-settings/fill" text="Paramètres devis"></ap-page-tabs-menu-item>`;

		this.id = '';
	}

	public async setParentElement(parent: HTMLElement) {
		this.N_div = document.createElement('div');

		this.N_div.classList.add('tab-pane', 'page-tabs-container');
		this.N_div.id = this.idTab;
		this.N_div.innerHTML = `
			<div class="page-tabs-title">
				Paramètres devis
				<div class="page-tabs-title-right">
					<ap-button class="btn-icon" type="edit" id="edit" permission="QUOTES.EDIT"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-card-body page-tabs-content page-tabs-quote-options">
				<div>
					<div class="page-tabs-content-subtitle">
						TAUX HORAIRE (€)
					</div>
					<div class="quote-options-list" id="price-list"></div>
				</div>

				<div>
					<div class="page-tabs-content-subtitle">
						MARGES (%)
					</div>
					<div class="quote-options-list" id="marges-list"></div>
				</div>

				<div>
					<div class="page-tabs-content-subtitle">
						INFORMATIONS
					</div>

					<div class="quote-options-list">
						<div class="page-card-block-value">
							<div class="page-card-label">Nombre de décimales :</div>
							<div class="page-card-value" name="pref.decimalNumber"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Coef total :</div>
							<div class="page-card-value" name="pref.coefTotal"></div>
						</div>
					</div>

					<div class="line"></div>

					<div class="page-tabs-content-subtitle">
						TYPE PAR DEFAUT
					</div>

					<div class="quote-options-list">
						<div class="page-card-block-value">
							<div class="page-card-label">Marge :</div>
							<div class="page-card-value" name="pref.typeMat"></div>
						</div>

						<div class="page-card-block-value">
							<div class="page-card-label">Taux horaire :</div>
							<div class="page-card-value" name="pref.type"></div>
						</div>
					</div>

					<div class="line"></div>

					<div class="page-tabs-content-subtitle">
						CALCUL DE LA MARGE
					</div>
	
					<div class="page-card-block-value">
						<div class="page-card-label">Appliquer une marge commerciale :</div>
						<div class="page-card-value" name="pref.calculMarkUp"></div>
					</div>

					<div class="line"></div>

					<div class="page-tabs-content-subtitle">
						OPTION D'AFFICHAGE DU GROUPE
					</div>

					<div class="page-card-block-value">
						<div class="page-card-label">Afficher la colonne <b>PU total</b>:</div>
						<div class="page-card-value" name="pref.displayPUDetails"></div>
						<i class="icon icon-solid-info-circle ml-2" tooltip="Prix unitaire avec marge"></i>
					</div>
				</div>
			</div>
        `;

		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		N_container.appendChild(this.N_div);

		this.N_tab = this.N_div;

		this.form = new Form(this.N_tab as HTMLFormElement);

		const N_edit = this.N_div.querySelector('#edit') as HTMLButtonElement;

		N_edit.addEventListener('click', () => {
			if (!this._isLock) {
				new M_Edit().open().then(async (data) => {
					this.quoteOptions!.pref = data;
					await this.updateFormData();
					this.dispatchEvent(new CustomEvent('update'));
				});
			}
		});
	}

	public set isLock(isLock: boolean) {
		if (this.N_div) {
			const N_edit = this.N_div.querySelector('#edit') as HTMLButtonElement;
			N_edit.disabled = isLock;
		}

		this._isLock = isLock;
	}

	public updateSettingQuoteOptions() {
		this.quoteOptions = this.quoteOptions || QuoteOption.getInstance();

		const settingQuoteOptions = this.quoteOptions!.quoteOptions;

		const N_priceHoursList = this.N_tab!.querySelector('#price-list') as HTMLElement;
		N_priceHoursList.innerHTML = '';

		for (const key in settingQuoteOptions!.hourlyPriceType) {
			const value = settingQuoteOptions!.hourlyPriceType[key];

			if (![QuoteData.NO_MO].includes(key)) {
				const N_div = this.createTemplateType(`pref.price.${key}`, value, 'hourlyPrice');

				N_priceHoursList.appendChild(N_div);
			}
		}

		const N_marginList = this.N_tab!.querySelector('#marges-list') as HTMLElement;
		N_marginList.innerHTML = '';

		for (const key in settingQuoteOptions!.marginType) {
			const value = settingQuoteOptions!.marginType[key];

			if (![QuoteData.NO_MARGIN, QuoteData.LICENCE, QuoteData.DISCOUNT].includes(key)) {
				const N_div = this.createTemplateType(`pref.marges.${key}`, value, 'margin');

				N_marginList.appendChild(N_div);
			}
		}

		this.form?.updateInputs();
	}

	public get settingQuoteOptions(): SettingQuoteOption {
		return this.quoteOptions!.quoteOptions!;
	}

	private async convertValue(data: { [key: string]: any }) {
		data.typeMat = {
			id: data.typeMat,
			text: this.quoteOptions!.quoteOptions!.marginType[data.typeMat]
		};

		data.type = {
			id: data.type,
			text: this.quoteOptions!.quoteOptions!.hourlyPriceType[data.type]
		};

		for (const key in data.marges) {
			data.marges[key] = data.marges[key] || 0;
		}

		for (const key in data.price) {
			data.price[key] = data.price[key] || 0;
		}

		for (const key in data.comptaVente) {
			data.comptaVente[key] = await new S_Account().getDataToSelect2ByID(data.comptaVente[key]);
		}

		data.decimalNumber = data.decimalNumber || 0;

		return data;
	}

	public async updateFormData() {
		this.quoteOptions = this.quoteOptions || QuoteOption.getInstance();

		let data = _.cloneDeep(this.quoteOptions!.pref);
		data = await this.convertValue(data);
		this.form!.setData({ pref: data });
	}

	public get data(): { [key: string]: any } {
		const data = this.form!.getData() as { [key: string]: any };
		return data.pref;
	}

	private createTemplateType(key: string, value: string, type: 'margin' | 'hourlyPrice'): InputDeleteBtnReadonly {
		const N_div = document.createElement('ap-quotes-options-input-readonly') as InputDeleteBtnReadonly;

		N_div.setAttribute('name', key);
		N_div.setAttribute('text', value);
		N_div.setAttribute('type', type);

		return N_div;
	}

	public static register() {
		customElements.define(QuoteOptionTabReadonly.tagName, QuoteOptionTabReadonly);
		InputDeleteBtnReadonly.register();
	}
}

export default QuoteOptionTabReadonly;
