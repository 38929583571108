import '@css/customElements/aggrid.scss';

import agUtils from '@libs/agGrid/french';

import { AllModules, Grid, GridOptions, RowNode } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import _merge from 'lodash/merge';

import Utils from '@libs/utils/Utils';

type itemData = {
	formattedValue: string
	value: string | number | boolean
	filterValue?: string
	quickFilter: string
};

type mode = 'dashboard' | 'edit';

class Aggrid extends HTMLElement {
	public static readonly tagName: string = 'ap-aggrid';

	private _gridOptions: GridOptions = {};

	private _isLoad: boolean = false;

	private _mode: mode = 'dashboard';

	private abortController: AbortController | null = null;
	private get abortSignal(): AbortSignal {
		return this.abortController!.signal;
	}

	public connectedCallback() {
		this.abortController = new AbortController();

		this._mode = (this.getAttribute('mode') as mode) || 'dashboard';

		this.innerHTML = `
			<div class="ag-theme-alpine" id="grid"></div>
		`;

		this.isLoad = false;
	}

	public disconnectedCallback() {
		this.abortController!.abort('destroyed');
	}

	private set isLoad(value: boolean) {
		if (value) {
			this.classList.remove('loading');
		} else {
			this.classList.add('loading');
		}

		this._isLoad = value;
	}

	public get isLoad() {
		return this._isLoad;
	}

	private getLoadingData() {
		const loadingData: any[] = [];
		for (let i = 0; i < 20; i++) {
			loadingData.push({});
		}

		return loadingData;
	}

	private get defaultOptions(): GridOptions {
		const result: GridOptions = {
			animateRows: true,
			rowData: this.getLoadingData(),
			suppressDragLeaveHidesColumns: true,
			isFullWidthCell: () => {
				return !this.isLoad;
			},
			fullWidthCellRenderer: () => {
				return '<div class="cell-loading"></div>';
			}
		};

		if (this._mode === 'dashboard') {
			result.defaultColDef = {
				getQuickFilterText: (params) => {
					const value = _.get(params.data, params.colDef.field || '') as itemData;
					return value?.quickFilter || '';
				},
				valueGetter: (params) => {
					const value = _.get(params.data, params.colDef.field || '') as itemData;
					return value?.filterValue ?? value?.value ?? '';
				},
				cellRenderer: (params) => {
					const value = _.get(params.data, params.colDef.field || '') as itemData;
					return value?.formattedValue || '';
				},
				suppressMenu: true,
				resizable: true
			};
		} else {
			result.defaultColDef = {
				suppressMenu: true,
				resizable: true
			};
		}

		return result;
	}

	public setGridOptions(options: GridOptions) {
		this._gridOptions?.api?.destroy();

		const gridOptions = _merge(this.defaultOptions, options);

		if (options.isFullWidthCell) {
			gridOptions.isFullWidthCell = (params) => {
				return this._isLoad ? options.isFullWidthCell!(params) : this.defaultOptions.isFullWidthCell!(params);
			};
		}

		if (this.defaultOptions.fullWidthCellRenderer) {
			gridOptions.fullWidthCellRenderer = (params) => {
				if (this._isLoad) {
					return (options.fullWidthCellRenderer as (params: RowNode) => string)(params);
				} else {
					return (this.defaultOptions.fullWidthCellRenderer as (params: RowNode) => string)(params);
				}
			};
		}

		this._gridOptions = agUtils.french<GridOptions>(gridOptions);

		const N_grid = this.querySelector('#grid') as HTMLElement;
		new Grid(N_grid, this._gridOptions, { modules: AllModules });

		Utils.cancelEditingAggrid(this._gridOptions, this.abortSignal);
	}

	public addValues(data: any[]) {
		this._gridOptions.api?.applyTransaction({
			add: data
		});
	}

	public sizeColumnsToFit() {
		this._gridOptions.api?.sizeColumnsToFit();
	}

	public forEachNode(cb: (node: RowNode) => void) {
		this._gridOptions.api?.forEachNode(cb);
	}

	public refreshCells() {
		this._gridOptions.api?.refreshCells({ force: true });
	}

	public get gridOptions() {
		return this._gridOptions;
	}

	public get api() {
		return this._gridOptions.api;
	}

	public resetValue() {
		this.isLoad = false;

		this._gridOptions.api?.setRowData(this.getLoadingData());
	}

	public set value(data: any[]) {
		this.isLoad = true;
		this._gridOptions.rowData = data;
		this._gridOptions.api?.setRowData(data);
		this._gridOptions.api?.sizeColumnsToFit();
	}

	public set pinnedBottomValue(data: any[]) {
		this._gridOptions.api?.setPinnedBottomRowData(data);
	}

	public get value() {
		this._gridOptions.api?.stopEditing();

		if (this.isLoad) {
			const res: any[] = [];

			this._gridOptions.api?.forEachNode((node) => {
				if (this._mode === 'dashboard') {
					if (node.data._rowData_) {
						res.push(node.data._rowData_);
					}
				} else {
					if (node.data) {
						res.push(node.data);
					}
				}
			});

			return res;
		} else {
			return [];
		}
	}

	public static register() {
		customElements.define(Aggrid.tagName, Aggrid);
	}
}

export default Aggrid;
