// CORE LIBRARY
import { Controller, Tabs } from '@autoprog/core-client';

// TAB CONTROLLER
import C_Tab from '@js/controllers/TabDatabase';

import C_Deliveries from '../tabs/DeliveriesTab';
import C_Receipts from '../tabs/ReceiptsTab';

// TEMPLATE
import T_blockTabs from '@tpl/blocks/tabs.html';

// AUTRES
import History from '@libs/History';

class DeliveriesCtrl extends Controller {
	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'deliveries';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		const tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		tabs.add({
			name: 'Livraisons',
			id: 'deliveries',
			default: defaultTab === 'deliveries',
			controller: C_Tab,
			data: {
				instance: C_Deliveries
			},
			permission: 'DELIVERIES.SHOW',
			template: T_blockTabs
		});

		tabs.add({
			name: 'Réceptions',
			id: 'receipts',
			default: defaultTab === 'receipts',
			controller: C_Tab,
			data: {
				instance: C_Receipts
			},
			permission: 'RECEIPTS.SHOW',
			template: T_blockTabs
		});

		let firstLoad = true;

		tabs.on('change', (data: { [key: string]: string }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`/#module/apps/deliveries/${data.id}`);
			}
		});
	}

	public destructor() {

	}
}

export default DeliveriesCtrl;
