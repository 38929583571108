import { CompanyAddress } from '../types/companyAddress';

import BooleanModel from '@libs/model/_app/Boolean';
import StringModel from '@libs/model/_app/String';

class CompanyAddressModel {
	private data: CompanyAddress;

	constructor(data: CompanyAddress) {
		this.data = data;
	}

	private convertRowData() {
		return {
			name: new StringModel(this.data.name),
			address: new StringModel(this.data.address),
			zipCode: new StringModel(this.data.zipCode),
			city: new StringModel(this.data.city),
			phone: new StringModel(this.data.phone),
			fax: new StringModel(this.data.fax),
			email: new StringModel(this.data.email),
			default: new BooleanModel(this.data.default),
			_rowData_: this.data
		};
	}

	public toDashboard() {
		const data = this.convertRowData();

		return {
			name: data.name.toDashboard(),
			address: data.address.toDashboard(),
			zipCode: data.zipCode.toDashboard(),
			city: data.city.toDashboard(),
			phone: data.phone.toDashboard(),
			fax: data.fax.toDashboard(),
			email: data.email.toDashboard(),
			default: data.default.toDashboard(),
			_rowData_: this.data
		};
	}

	public toSave(): CompanyAddress {
		const data = this.convertRowData();

		return {
			name: data.name.toSave(),
			address: data.address.toSave(),
			zipCode: data.zipCode.toSave(),
			city: data.city.toSave(),
			phone: data.phone.toSave(),
			fax: data.fax.toSave(),
			email: data.email.toSave(),
			default: data.default.toSave()
		};
	}
}

export default CompanyAddressModel;
