import { Alert, Controller, Router, toaster } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter';
import Decimal from '@libs/utils/Decimal';

import Utils from '@js/libs/utils/Utils';

import _ from 'lodash';

import S_Product from '@services/Product/ProductService';
import moment from 'moment';

class UpdatePrice extends Controller {
	private el: HTMLElement;
	private gridOptions: GridOptions = {};

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		const N_save = this.el.querySelector('#save') as HTMLButtonElement;
		const N_addEuro = this.el.querySelector('#addEuro') as HTMLButtonElement;
		const N_addPercent = this.el.querySelector('#addPercent') as HTMLButtonElement;

		N_save.addEventListener('click', async () => {
			const docs: { [key: string]: any }[] = [];

			this.gridOptions.api?.stopEditing();

			this.gridOptions.api?.forEachNode((node) => {
				docs.push({
					_id: node.data._id,
					price: Decimal.setDisplayNumber(node.data.newPrice).toNumber()
				});
			});

			//TODO: save multiple
			for (const i in docs) {
				await S_Product.getInstance().save({
					_id: docs[i]._id,
					price: docs[i].price
				});
			}

			toaster.success('Sauvegarde Réussi');

			Router.getInstance().navigate('#module/apps/products');
		});

		N_addEuro.addEventListener('click', () => {
			Alert.prompt('Mise à jour des prix de vente affichés avec une valeur fixe (€)', 'Met à jour les prix de vente affichés dans le tableau (filtré) avec une valeur (€) positive ou négative.').then((n) => {
				this.updateAllNewPricesInEuro(n as string);
			});
		});

		N_addPercent.addEventListener('click', () => {
			Alert.prompt('Mise à jour des prix de vente affichés avec un pourcentage (%)', 'Met à jour les prix de vente affichés dans le tableau (filtré) avec un pourcentage (%) négatif ou positif des anciens prix de vente.').then((n) => {
				this.updateAllNewPricesInPercent(n as string);
			});
		});

		this.initGrid();
		this.initEvents();
	}

	private initEvents() {
		const N_resetNewPrices = this.el.querySelector('#resetNewPrices') as HTMLButtonElement;
		N_resetNewPrices.addEventListener('click', () => {
			this.resetAllNewPrices();
			Utils.removeTooltip();
		});
	}

	private updateAllNewPricesInEuro(amountInEuro: string) {
		const number = Decimal.setDisplayNumber(amountInEuro as string);

		const newData: any[] = [];

		this.gridOptions.api?.forEachNodeAfterFilter((node) => {
			const oldPrice = new Decimal(node.data.newPrice);

			const newPrice = oldPrice.plus(number);

			node.data.newPrice = newPrice.toNumber();

			newData.push(node.data);
		});

		this.gridOptions.api?.setRowData(newData);
	}

	private updateAllNewPricesInPercent(percent: string) {
		const number = Decimal.setDisplayNumber(percent);

		const newData: any[] = [];

		this.gridOptions.api?.forEachNodeAfterFilter((node) => {
			const initialPrice = Decimal.setDisplayNumber(node.data.price.value || '0');

			const difference = initialPrice.times(number.dividedBy(100));

			const oldPrice = new Decimal(node.data.newPrice);

			const newPrice = oldPrice.plus(difference);

			node.data.newPrice = newPrice.toNumber();

			newData.push(node.data);
		});

		this.gridOptions.api?.setRowData(newData);
	}

	private updateNewPriceInEuro(rowId: string, amountInEuro: string) {
		const rowNode = this.gridOptions.api?.getRowNode(rowId);

		if (rowNode) {
			const number = Decimal.setDisplayNumber(amountInEuro);

			const newData: any[] = [];

			const oldPrice = new Decimal(rowNode.data.newPrice);

			const newPrice = oldPrice.plus(number);

			rowNode.data.newPrice = newPrice.toNumber();

			newData.push(rowNode.data);

			this.gridOptions.api?.applyTransaction({
				update: newData
			});
		}
	}

	private updateNewPriceInPercent(rowId: string, percent: string) {
		const rowNode = this.gridOptions.api?.getRowNode(rowId);

		if (rowNode) {
			const number = Decimal.setDisplayNumber(percent);

			const newData: any[] = [];

			const initialPrice = Decimal.setDisplayNumber(rowNode.data.price.value || '0');

			const difference = initialPrice.times(number.dividedBy(100));

			const oldPrice = new Decimal(rowNode.data.newPrice);

			const newPrice = oldPrice.plus(difference);

			rowNode.data.newPrice = newPrice.toNumber();

			newData.push(rowNode.data);

			this.gridOptions.api?.applyTransaction({
				update: newData
			});
		}
	}

	private resetAllNewPrices() {
		const newData: any[] = [];

		this.gridOptions.api?.forEachNodeAfterFilter((node) => {
			node.data.newPrice = node.data.price.value;

			newData.push(node.data);
		});

		this.gridOptions.api?.setRowData(newData);
	}

	private resetNewPrice(rowId: string) {
		const rowNode = this.gridOptions.api?.getRowNode(rowId);

		if (rowNode) {
			const newData: any[] = [];

			rowNode.data.newPrice = rowNode.data.price.value;

			newData.push(rowNode.data);

			this.gridOptions.api?.applyTransaction({
				update: newData
			});
		}
	}

	private async initGrid() {
		const { rowData } = await S_Product.getInstance().getDataToAgGrid();

		const res: any[] = [];

		for (const item of rowData) {
			item.newPrice = Decimal.setDisplayNumber(item.price.value).toNumber();
			res.push(item);
		}

		this.gridOptions = agUtils.french<GridOptions>({
			rowData: res,
			suppressContextMenu: true,
			columnDefs: [
				{
					headerName: 'Dernière mise à jour',
					field: 'lastUpdatePrice',
					width: 150,
					suppressSizeToFit: true,
					floatingFilter: true,
					filter: 'agNumberColumnFilter',
					floatingFilterComponent: DatePickerRangeFilter,
					filterParams: {
						newRowsAction: 'keep'
					},
					valueGetter: (params) => {
						return parseInt(moment(params.data.lastUpdatePrice).format('x'));
					},
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							return moment(params.value).format('DD/MM/YYYY');
						}
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: S_Product.getInstance().referenceKey,
					width: 150,
					suppressSizeToFit: true,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						newRowsAction: 'keep',
						textFormatter: (result: string) => {
							if (result === null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				}, {
					headerName: 'Désignation',
					field: 'name',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						newRowsAction: 'keep',
						textFormatter: (result: string) => {
							if (result === null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				}, {
					headerName: 'Marque',
					field: 'brand',
					width: 150,
					suppressSizeToFit: true,
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						newRowsAction: 'keep',
						applyMiniFilterWhileTyping: true
					}
				}, {
					headerName: 'Coût de revient',
					field: 'defaultProvider.costPrice',
					cellClass: ['text-monospace', 'text-right'],
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return Decimal.setDisplayNumber(params.value.value).setSuffixAndHumanizeNumberWithPrecision('€');
					}
				}, {
					headerName: 'Ancien prix de vente',
					cellClass: ['text-monospace', 'text-right'],
					field: 'price',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return Decimal.setDisplayNumber(params.value.value).setSuffixAndHumanizeNumberWithPrecision('€');
					}
				}, {
					headerName: 'Nouveau prix de vente',
					field: 'newPrice',
					cellClass: ['text-monospace', 'text-right', 'font-weight-bold'],
					editable: true,
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return Decimal.setDisplayNumber(params.value).setSuffixAndHumanizeNumberWithPrecision('€');
					}
				}, {
					headerName: 'Action',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_resetNewPrice = document.createElement('button');

						N_resetNewPrice.classList.add('h-100', 'py-0', 'btn-transparent');

						N_resetNewPrice.innerHTML = '<i class="h5 icon icon-solid-redo-alt"></i>';
						N_resetNewPrice.setAttribute('confirmation', '');
						N_resetNewPrice.setAttribute('tooltip', 'Réinitialiser le nouveau prix de vente');

						N_resetNewPrice.type = 'button';

						N_resetNewPrice.addEventListener('click', async () => {
							this.resetNewPrice(params.node.id);
						});

						const N_addEuro = document.createElement('button');
						N_addEuro.classList.add('h-100', 'py-0', 'btn-transparent');

						N_addEuro.innerHTML = '<i class="h5 icon icon-solid-euro-sign"></i>';
						N_addEuro.setAttribute('tooltip', 'Mise à jour du prix de vente avec une valeur fixe (€)');

						N_addEuro.type = 'button';

						N_addEuro.addEventListener('click', async () => {
							Alert.prompt('Mise à jour du prix de vente avec une valeur fixe (€)', 'Met à jour le prix de vente avec une valeur (€) positive ou négative.').then((n) => {
								this.updateNewPriceInEuro(params.node.id, n as string);
							});
						});

						const N_addPercent = document.createElement('button');
						N_addPercent.classList.add('h-100', 'py-0', 'btn-transparent');
						N_addPercent.innerHTML = '<i class="h5 icon icon-solid-percent"></i>';
						N_addPercent.setAttribute('tooltip', 'Mise à jour du prix de vente avec un pourcentage (%)');

						N_addPercent.type = 'button';

						N_addPercent.addEventListener('click', async () => {
							Alert.prompt('Mise à jour du prix de vente avec un pourcentage (%)', 'Met à jour le prix de vente avec un pourcentage (%) négatif ou positif de l\'ancien prix de vente.').then((n) => {
								this.updateNewPriceInPercent(params.node.id, n as string);
							});
						});

						N_div.appendChild(N_resetNewPrice);
						N_div.appendChild(N_addEuro);
						N_div.appendChild(N_addPercent);

						return N_div;
					}
				}

			],
			getRowStyle: (params: any) => {
				const costPrice = Decimal.setDisplayNumber(params.node.data.defaultProvider.costPrice.value);
				const newPrice = Decimal.setDisplayNumber(params.node.data.newPrice);

				if (costPrice.greaterThan(newPrice)) {
					return {
						'background-color': 'var(--ap-red-50)',
						color: 'var(--ap-red-900)'
					};
				}
			},
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			onCellEditingStopped: (params) => {
				params.api?.redrawRows({ rowNodes: [params.node] });
			},
			onGridReady: (params) => {
				params.api?.sizeColumnsToFit();
			}
		});

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });
	}

	public destructor() {
	}
}

export default UpdatePrice;
