class ObjectModel {
	private value: string;
	private translate: { [key: string]: string };

	constructor(value: string, translate: { [key: string]: string }) {
		this.value = value;
		this.translate = translate;
	}

	public getValue() {
		return this.value;
	}

	public getText() {
		return this.translate[this.value] || this.value;
	}

	public toDashboard() {
		return {
			formattedValue: this.getText(),
			value: this.value,
			quickFilter: this.getText()
		};
	}

	public toDocument() {
		return this.value;
	}

	public toSave() {
		return this.value;
	}

	public toPrint() {
		return this.getText();
	}
}

export default ObjectModel;
