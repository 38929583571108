import { ISettingsController } from './SettingsController';

export type LinkItem = {
	id?: string
	category?: string
	order: number
	icon?: string
	name: string
	tpl: string
	permission?: string
	Controller: ISettingsController
};

type CategoryItem = {
	id: string
	name?: string
	order: number
};

class SettingsRegister {
	private static instance: SettingsRegister;
	private static CATEGORY_NULL = '_null';

	private _categoriesMap: Map<string, CategoryItem>;
	private _linkMap: Map<string, LinkItem[]>;

	public static defaultCategories = ['general', 'company'];

	private constructor() {
		this._categoriesMap = new Map();
		this._linkMap = new Map();
	}

	public get categoriesMap() {
		return this._categoriesMap;
	}

	public get linkMap() {
		return this._linkMap;
	}

	public static getInstance() {
		if (!SettingsRegister.instance) {
			SettingsRegister.instance = new SettingsRegister();
		}

		return SettingsRegister.instance;
	}

	public addCategory(item: CategoryItem) {
		item.id = item.id || SettingsRegister.CATEGORY_NULL;

		const category = this._categoriesMap.get(item.id);

		if (!category) {
			this._categoriesMap.set(item.id, item);
		}
	}

	public addLink(item: LinkItem) {
		item.category = item.category || SettingsRegister.CATEGORY_NULL;

		const category = this._categoriesMap.get(item.category);

		if (!category) {
			console.error('Categorie non enregistré');
		}

		const link = this._linkMap.get(item.category) || [];

		link.push(item);

		this._linkMap.set(item.category, link);
	}

	public getLinksByID(id: string) {
		for (const itemList of this._linkMap.values()) {
			for (const item of itemList) {
				if (item.id === id) {
					return item;
				}
			}
		}
	}
}

export default SettingsRegister;
