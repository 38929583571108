import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import '@css/textareaCellRendered.scss';

class TextareaCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('textareaCellRenderer');
		this.eGui.innerHTML = '';
	}

	public init(params: any) {
		this.eGui.innerHTML = params.value || '';
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default TextareaCellRenderer;
