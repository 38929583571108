import GenericService from '../GenericService';

class UserService extends GenericService {
	private static instance: UserService | null = null;

	constructor() {
		super('users_addon');
	}

	public static getInstance(): UserService {
		if (!UserService.instance) {
			UserService.instance = new UserService();
		}

		return UserService.instance;
	}
}

export default UserService;
