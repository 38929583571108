import removeAccent from 'remove-accents';

import S_Categories from '@services/Product/ProductCategoryService';

import '../../../css/customElement/editCategories.scss';

import CE_Button from '@libs/customElement/Button';
import CE_Checkbox from '@libs/customElement/Checkbox';

class EditCategories extends HTMLElement {
	public static readonly tagName: string = 'ap-edit-categories';

	private groupByParent: { [idParent: string]: any[] } = {};
	private _parent: { [id: string]: string } = {};

	public connectedCallback() {
		this.innerHTML = `
			<div class="header">
				<div class="title-categories">Catégories</div>
				<div class="search">
					<input class="ap-input" id="search" placeholder="Rechercher"/>
				</div>
			</div>
			<div class="content"></div>
		`;

		this.initSearch();
	}

	private initSearch() {
		const N_input = this.querySelector<HTMLInputElement>('#search')!;

		N_input?.addEventListener('input', () => {
			const value = removeAccent(N_input.value).toUpperCase();

			if (value.length) {
				const idSearch: { [id: string]: boolean } = {};
				//filtre
				for (const id in this.groupByParent) {
					for (const item of this.groupByParent[id]) {
						const label = removeAccent(item.label).toUpperCase();
						if (label.includes(value)) {
							idSearch[item._id] = true;
						}
					}
				}

				//récupération de tous les ids a afficher
				const allDisplayIds: { [id: string]: boolean } = {};

				for (const id in idSearch) {
					const path = this.getPath(id);
					const children = this.getChildren(id);

					for (const idPath of path) {
						allDisplayIds[idPath] = true;
					}

					for (const idChildren of children) {
						allDisplayIds[idChildren] = true;
					}
				}

				//affichage
				const N_checkboxList = this.querySelectorAll<CE_Checkbox>('ap-checkbox');

				for (const N_checkbox of N_checkboxList) {
					const id = N_checkbox.dataset.id || '';

					if (allDisplayIds[id]) {
						N_checkbox.parentElement!.classList.remove('d-none');
						N_checkbox.parentElement!.classList.add('active');

						if (idSearch[id]) {
							N_checkbox.parentElement!.classList.add('highligth');
						} else {
							N_checkbox.parentElement!.classList.remove('highligth');
						}
					} else {
						N_checkbox.parentElement!.classList.add('d-none');
						N_checkbox.parentElement!.classList.remove('highligth', 'active');
					}
				}
			} else {
				const N_checkboxList = this.querySelectorAll<CE_Checkbox>('ap-checkbox');
				for (const N_checkbox of N_checkboxList) {
					N_checkbox.parentElement!.classList.remove('d-none', 'highligth');
					N_checkbox.parentElement!.classList.add('active');
				}
			}
		});
	}

	private checkedParent(idParent: string) {
		if (idParent) {
			const N_checkbox = this.querySelector<CE_Checkbox>(`ap-checkbox[data-id="${idParent}"]`);
			N_checkbox && (N_checkbox.checked = true);

			this.checkedParent(this._parent[idParent]);
		}
	}

	private getChildren(id: string, result: string[] = []): string[] {
		result.unshift(id);

		if (this.groupByParent[id]) {
			for (const item of this.groupByParent[id]) {
				this.getChildren(item._id, result);
			}
		}

		return result;
	}

	private getPath(id: string, result: string[] = []): string[] {
		result.unshift(id);

		if (this._parent[id]) {
			return this.getPath(this._parent[id], result);
		}

		return result;
	}

	private getFirstParent(idParent: string): string {
		if (this._parent[idParent]) {
			return this.getFirstParent(this._parent[idParent]);
		}

		return idParent;
	}

	private resetSelection(id: string) {
		if (id && this.groupByParent[id]) {
			for (const item of this.groupByParent[id]) {
				const N_checkbox = this.querySelector<CE_Checkbox>(`ap-checkbox[data-id="${item._id}"]`);
				N_checkbox && (N_checkbox.checked = false);
				this.resetSelection(item._id);
			}
		}
	}

	private render(idParent: string, N_div: HTMLElement, level: number = 1) {
		this.groupByParent[idParent] = this.groupByParent[idParent].sort((itemA, itemB) => {
			if (itemA.label > itemB.label) {
				return 1;
			}
			if (itemA.label < itemB.label) {
				return -1;
			}
			return 0;
		});

		for (const item of this.groupByParent[idParent]) {
			const N_category = document.createElement('div');

			N_category.innerHTML = `
				<ap-checkbox data-level="${level}" label="${item.label}" data-id="${item._id}"></ap-checkbox>
				<ap-button class="btn-icon" icon="arrow-down-s/line"></ap-button>
			`;

			N_category.style.setProperty('--level', (level - 1).toString());
			N_category.classList.add('active');

			const N_checkbox = N_category.querySelector<CE_Checkbox>('ap-checkbox')!;
			const N_button = N_category.querySelector<CE_Button>('ap-button')!;

			N_checkbox.addEventListener('change', () => {
				if (N_checkbox.checked) {
					if (N_checkbox.dataset.level !== '1') {
						const firstParent = this.getFirstParent(item._id);
						this.resetSelection(firstParent);
					}

					this.checkedParent(item._id);
				} else {
					this.resetSelection(item._id);
				}
			});

			N_button.addEventListener('click', () => {
				N_category.classList.toggle('active');

				const children = this.getChildren(item._id);

				const active = N_category.classList.contains('active');

				for (const idChildren of children) {
					if (idChildren !== item._id) {
						const N_checkbox = this.querySelector<CE_Checkbox>(`[data-id="${idChildren}"]`)!;

						N_checkbox.parentElement!.classList.toggle('d-none', !active);

						if (active) {
							N_checkbox.parentElement?.classList.add('active');
						}
					}
				}
			});

			N_div.append(N_category);

			if (this.groupByParent[item._id]) {
				//N_div.append(this.render(item._id, document.createElement('div'), level + 1));
				this.render(item._id, N_div, level + 1);
			} else {
				N_button.remove();
			}
		}

		return N_div;
	}

	public async init() {
		const N_content = this.querySelector<HTMLElement>('.content')!;

		const data = await S_Categories.getInstance().getAll();

		this.groupByParent = {};

		for (const item of data) {
			this.groupByParent[item.parent] = this.groupByParent[item.parent] || [];
			this.groupByParent[item.parent].push(item);

			this._parent[item._id] = item.parent;
		}

		this.render('', N_content);
	}

	public set data(data: { [key: string]: any }[]) {
		for (const item of data) {
			if (item.category1) {
				const checkbox = this.querySelector<HTMLInputElement>(`[data-id="${item.category1.id}"]`);
				checkbox!.checked = true;
			}
			if (item.category2) {
				const checkbox = this.querySelector<HTMLInputElement>(`[data-id="${item.category2.id}"]`);
				checkbox!.checked = true;
			}
			if (item.category3) {
				const checkbox = this.querySelector<HTMLInputElement>(`[data-id="${item.category3.id}"]`);
				checkbox!.checked = true;
			}
		}
	}

	public get data() {
		const N_checkboxLevel3 = this.querySelectorAll<HTMLInputElement>('[data-level="3"] input[type="checkbox"]:checked');
		const N_checkboxLevel2 = this.querySelectorAll<HTMLInputElement>('[data-level="2"] input[type="checkbox"]:checked');
		const N_checkboxLevel1 = this.querySelectorAll<HTMLInputElement>('[data-level="1"] input[type="checkbox"]:checked');

		const N_checkbox = [...N_checkboxLevel3, ...N_checkboxLevel2, ...N_checkboxLevel1];

		const result: { [key: string]: any }[] = [];
		const ignoreId: { [key: string]: boolean } = {};

		for (const N_el of N_checkbox) {
			const level = N_el.parentElement!.dataset.level || '';
			const id = N_el.parentElement!.dataset.id || '';

			if (level === '3') {
				const category3 = id;

				if (!ignoreId[category3]) {
					const category2 = this._parent[category3];
					const category1 = this._parent[category2];

					ignoreId[category1] = true;
					ignoreId[category2] = true;
					ignoreId[category3] = true;

					result.push({
						category1,
						category2,
						category3
					});
				}
			}

			if (level === '2') {
				const category2 = id;

				if (!ignoreId[category2]) {
					const category1 = this._parent[category2];

					ignoreId[category1] = true;
					ignoreId[category2] = true;

					result.push({
						category1,
						category2
					});
				}
			}

			if (level === '1') {
				const category1 = id;

				if (!ignoreId[category1]) {
					ignoreId[category1] = true;

					result.push({
						category1
					});
				}
			}
		}

		return result;
	}

	public checkValidity() {
		const result = this.data.length > 0;

		this.classList.toggle('invalid', !result);

		return result;
	}

	public static register() {
		customElements.define(EditCategories.tagName, EditCategories);
	}
}

export default EditCategories;
