import { LoggedUser } from '@autoprog/core-client';

import S_Users from '@services/User/UserService';

import M_AddPayment from '../modals/AddLinePayment';

import Decimal from '@libs/utils/Decimal';

import CE_Aggrid from '@libs/customElement/AgGrid';

import _ from 'lodash';
import h from 'hyperscript';
import moment from 'moment';

class PaymentsBillCustomerTab extends HTMLElement {
	public static readonly tagName: string = 'ap-bill-customer-payment-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private users: any[] = [];

	private getGlobalPriceTTC: () => string = () => { return '0'; };
	private getID: () => string = () => { return ''; };
	private _updateInfos: () => void = () => { };

	private callback: () => Promise<void> = async () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || PaymentsBillCustomerTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="bank/line" icon-active="bank/fill" text="Paiements" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Paiements
				<div class="page-tabs-title-right">
					<ap-button id="add" type="add" permission="BILLS._CUSTOMERS._PAYMENTS.ADD"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButton();
	}

	public setCallback(cb: () => Promise<void>) {
		this.callback = cb;
	}

	public setUpdateInfos(cb: () => void) {
		this._updateInfos = cb;
	}

	private initButton() {
		const N_btn = this.N_el!.querySelector('#add') as HTMLButtonElement;

		N_btn.addEventListener('click', async () => {
			N_btn.disabled = true;
			try {
				await this.callback();
				new M_AddPayment(this.getID()).open().then((data) => {
					this.N_grid!.addValues([data]);

					this.callback();
					N_btn.disabled = false;
				}).catch(() => {
					N_btn.disabled = false;
				});
			} catch (e) {
				N_btn.disabled = false;
			}
		});
	}

	public update() {
		this.N_grid!.refreshCells();
	}

	public setGetglobalPriceTTC(cb: () => string) {
		this.getGlobalPriceTTC = cb;
	}

	public setGetID(cb: () => string) {
		this.getID = cb;
	}

	private initGrid() {
		this.N_grid = this.N_el!.querySelector<CE_Aggrid>('#grid');

		this.N_grid!.setGridOptions({
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Personne',
					field: 'user',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							const user = _.find(this.users, { _id: params.value }) as any;
							return `${user ? user.lastname + ' ' + user.firstname : 'Utilisateur introuvable'}`;
						}
					}
				}, {
					headerName: 'Date',
					field: 'date',
					sort: 'asc',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							return moment(params.value, 'x').format('DD/MM/YYYY');
						}
					}
				}, {
					headerName: 'Montant TTC (€)',
					field: 'price',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params) => {
						const globalPriceTTC = Decimal.setDisplayNumber(this.getGlobalPriceTTC());

						if (params.node.rowPinned) {
							let price = new Decimal(0);

							params.api.forEachNode((node: any) => {
								price = price.plus(Decimal.setDisplayNumber(node.data.price));
							});

							const percent = new Decimal(100).times(price).dividedBy(globalPriceTTC).toDecimalPlaces(2);

							return price.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€') + ' | ' + percent.humanizePercent() + '%';
						} else {
							const price = Decimal.setDisplayNumber(params.value);
							const percent = new Decimal(100).times(price).dividedBy(globalPriceTTC).toDecimalPlaces(2);

							return price.setSuffixAndHumanizeNumber('€') + ' | ' + percent.humanizePercent() + '%';
						}
					}
				}, {
					headerName: 'Type',
					field: 'type',
					cellRenderer: (params) => {
						if (params.data.type === 'Chèque') {
							return `${params.data.type}${params.data.bank ? ' - ' + params.data.bank : ''}${params.data.numberCheque ? ' - N° ' + params.data.numberCheque : ''}`;
						} else if (params.data.type === 'Virement') {
							return `${params.data.type}${params.data.bank ? ' - ' + params.data.bank : ''}`;
						}

						return params.data.type;
					}
				}, {
					headerName: 'Commentaire',
					field: 'comment'
				}, {
					headerName: '',
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', permission: 'BILLS._CUSTOMERS._PAYMENTS.EDIT' } });

							N_edit.addEventListener('click', () => {
								new M_AddPayment(this.getID(), params.data).open().then((data) => {
									params.data = data;

									params.node.setData(data);

									params.api?.refreshCells({ force: true });

									this.callback();
								});
							});

							const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit);

							return N_div;
						}
					}
				}
			],
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter un paiement',
					disabled: !LoggedUser.getInstance().hasPermission('BILLS._CUSTOMERS._PAYMENTS.ADD'),
					action: async () => {
						let index = 0;

						params.api?.forEachNode(() => {
							index++;
						});

						await this.callback();
						new M_AddPayment(this.getID()).open().then((data) => {
							params.api?.applyTransaction({
								add: [data]
							});

							params.api?.ensureIndexVisible(index);

							params.api?.refreshCells({ force: true });

							this.callback();
						});
					}
				}];

				return result;
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);

				if (number !== 0) {
					params.api?.setPinnedBottomRowData([{}]);
				}
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);

				params.api?.setPinnedBottomRowData([{}]);
			},
			onGridReady: () => {
				this._updateInfos();
			}
		});
	}

	private updateNumber(number: number) {
		if (this.N_grid!.isLoad) {
			const N_number = this.querySelector('#number') as HTMLElement;

			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		}
	}

	public async iniData(data: any[]) {
		this.users = await S_Users.getInstance().getAllWithDelete();
		this.N_grid!.value = data;
		this._updateInfos();
	}

	public get data(): any[] {
		return this.N_grid!.value;
	}

	public getPaidPrice() {
		let paidPrice = new Decimal(0);

		this.N_grid!.forEachNode((node) => {
			const item = node.data;

			if (item.price) {
				paidPrice = paidPrice.plus(Decimal.setDisplayNumber(item.price));
			}
		});

		return paidPrice;
	}

	public static register() {
		customElements.define(PaymentsBillCustomerTab.tagName, PaymentsBillCustomerTab);
	}
}

export default PaymentsBillCustomerTab;
