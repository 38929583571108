import C_Database from '@js/controllers/Database';

import moment from 'moment';

import Export from '@libs/export/Export';
import ExportUtils from '@libs/utils/ExportUtils';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';
import ModalManager from '@js/managers/ModalManager';

class Contacts extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Contacts',
			database: 'contacts',
			permission: 'CONTACTS'
		});
	}

	protected getPermissionKey(data: any) {
		const permissionsKey: { [key: string]: string } = {
			customer: 'CUSTOMERS',
			provider: 'PROVIDERS',
			site: 'SITES'
		};
		return permissionsKey[data.type];
	}

	protected async buttonEdit(params: any) {
		await ModalManager.getInstance().open(params.data.type + 's-contacts', params.data._id);
		this.refreshData();
	}

	private async addContact(type: string) {
		await ModalManager.getInstance().open(type + 's-contacts', undefined, {}, 'free');
		this.refreshData();
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: 'type',
					type: 'checkbox',
					checkboxListTitle: 'Type de contact',
					filters: [{
						value: 'customer',
						label: 'Client'
					}, {
						value: 'provider',
						label: 'Fournisseur'
					}, {
						value: 'site',
						label: 'Site'
					}]
				}
			]
		];
	}

	protected header() {
		const N_div = document.createElement('div') as HTMLDivElement;

		N_div.classList.add('d-flex', 'flex-grow-1');

		const N_div_buttons = document.createElement('div') as HTMLDivElement;
		N_div_buttons.classList.add('ml-auto', 'd-flex');

		const N_dropdown = document.createElement('div') as HTMLDivElement;
		N_dropdown.classList.add('dropdown');

		const N_dropdown_button = document.createElement('button') as HTMLButtonElement;
		N_dropdown_button.classList.add('dropdown-toggle', 'btn', 'btn-add', 'mr-1');
		N_dropdown_button.setAttribute('type', 'button');
		N_dropdown_button.setAttribute('id', 'dropdownMenuButton');
		N_dropdown_button.setAttribute('data-toggle', 'dropdown');
		N_dropdown_button.setAttribute('aria-expanded', 'false');
		N_dropdown_button.innerHTML = '<i class="icon icon-solid-plus"></i> <span class="mr-2">Ajouter</span>';

		const N_dropdown_menu = document.createElement('button') as HTMLButtonElement;
		N_dropdown_menu.classList.add('dropdown-menu');

		const N_Export = document.createElement('button') as HTMLButtonElement;
		N_Export.classList.add('btn', 'mr-1', 'btn-export-csv', 'd-inline-flex');
		N_Export.innerHTML = '<i class="icon icon-solid-file-csv"></i>';
		N_Export.setAttribute('tooltip', 'Exporter les données en CSV');
		N_Export.setAttribute('permission', 'CONTACTS.EXPORT');

		N_Export.addEventListener('click', () => {
			const csvString = new Export()
				.included(['_id', 'lastname', 'firstname', 'status', 'tel1', 'tel2', 'fax', 'mail', 'provider', 'customer', 'site', 'type'])
				.fromTableName(this.tableName)
				.fromGridOptions(this.gridOptions)
				.createCSV();

			const a = ExportUtils.createFileLink(csvString);
			a.download = `Export-Contacts-${moment().format('DD-MM-YYYY')}.csv`;
			a.click();
			a.remove();
		});

		const N_AddContactCustomer = document.createElement('button') as HTMLButtonElement;
		N_AddContactCustomer.title = 'Ajouter le contact d\'un client';
		N_AddContactCustomer.classList.add('dropdown-item', 'mr-1');
		N_AddContactCustomer.setAttribute('permission', 'CUSTOMERS._CONTACTS.ADD');
		N_AddContactCustomer.innerHTML = 'Client';

		N_AddContactCustomer.addEventListener('click', () => {
			this.addContact('customer');
		});

		const N_AddContactProvider = document.createElement('button') as HTMLButtonElement;
		N_AddContactProvider.title = 'Ajouter le contact d\'un fournisseur';
		N_AddContactProvider.classList.add('dropdown-item', 'mr-1');
		N_AddContactProvider.setAttribute('permission', 'PROVIDERS._CONTACTS.ADD');
		N_AddContactProvider.innerHTML = 'Fournisseur';

		N_AddContactProvider.addEventListener('click', () => {
			this.addContact('provider');
		});

		const N_AddContactSite = document.createElement('button') as HTMLButtonElement;
		N_AddContactSite.title = 'Ajouter le contact d\'un site';
		N_AddContactSite.classList.add('dropdown-item', 'mr-1');
		N_AddContactSite.setAttribute('permission', 'SITES._CONTACTS.ADD');
		N_AddContactSite.innerHTML = 'Site';

		N_AddContactSite.addEventListener('click', () => {
			this.addContact('site');
		});

		N_dropdown_menu.appendChild(N_AddContactCustomer);
		N_dropdown_menu.appendChild(N_AddContactProvider);
		N_dropdown_menu.appendChild(N_AddContactSite);

		N_dropdown.appendChild(N_dropdown_button);
		N_dropdown.appendChild(N_dropdown_menu);

		N_div_buttons.appendChild(N_Export);
		N_div_buttons.appendChild(N_dropdown);

		N_div.appendChild(N_div_buttons);

		return N_div;
	}
}

export default Contacts;
