// TEMPLATE

// STYLE
import '@css/customElements/navbar-clock.scss';

// NODE
import moment from 'moment';

/**
 * CustomElement fait pour être intégré dans la barre de navigation du haut.
 * 
 * Affiche la date et l'heure.
 * 
 * Attributs :
 * 
 * - displaySeconds (sans valeur) : pour afficher les secondes sur l'horloge
 */
class NavbarClock extends HTMLElement {
	/**
	 * L'élement contenant la date
	 */
	private N_Date: HTMLDivElement | null = null;

	/**
	 * L'élement contenant l'heure
	 */
	private N_Time: HTMLDivElement | null = null;

	/**
	 * Nom de tag du Custom Element
	 */
	public static readonly tagName: string = 'ap-navbar-clock';

	/**
	 * Affiche les secondes
	 */
	public displaySeconds: boolean = false;

	/**
	 * Méthode appelée lorsque l'élément est connecté pour la première fois au DOM
	 */
	public async connectedCallback() {
		this.innerHTML = `
			<div id="date-time">
				<div id="date">DD/MM/YYYY</div>
				<div id="time">HH:mm</div>
			</div>
		`;

		this.classList.add('text-not-selectable');

		this.init();
	}

	/**
	 * Fonction d'initialisation principale
	 */
	private init() {
		this.retrieveAttributes();

		this.initElements();
		this.startClock();
	}

	/**
	 * Récupère les attributs
	 */
	private retrieveAttributes() {
		this.displaySeconds = this.getAttribute('displaySeconds') !== null;
	}

	/**
	 * Stocke les élements à utiliser
	 */
	private initElements() {
		this.N_Date = this.querySelector('#date') as HTMLDivElement;
		this.N_Time = this.querySelector('#time') as HTMLDivElement;
	}

	/**
	 * Démarre l'horloge
	 */
	private startClock() {
		this.clockRefresh();
		setInterval(() => this.clockRefresh(), 1000);
	}

	/**
	 * Rafraichit l'horloge
	 */
	private clockRefresh() {
		const now = moment();

		const date = now.format('DD/MM/YYYY');
		const time = now.format('HH:mm' + (this.displaySeconds ? ':ss' : ''));

		this.N_Date!.innerHTML = date;
		this.N_Time!.innerHTML = time;
	}

	/**
	 * Méthode d'enregistrement du Custom Element
	 */
	public static register() {
		customElements.define(NavbarClock.tagName, NavbarClock);
	}
}

export default NavbarClock;
