import roundUp from './roundUp';

export default function (number: string, pref: { [key: string]: any }, ignoreRound = false) {
	if (ignoreRound) {
		number = parseFloat(number).toString();
	} else {
		number = roundUp(number, pref).toString();
	}

	if (isNaN(parseFloat(number))) {
		return '';
	} else {
		let result = '';

		const chunkNumber = number.split('.');

		const tmp = chunkNumber[0].split('').reverse();

		for (let i = tmp.length - 1; i >= 0; i--) {
			result += tmp[i];

			if (i % 3 === 0 && i !== 0) {
				result += ' ';
			}
		}

		chunkNumber[1] = chunkNumber[1] || '';

		if (ignoreRound) {
			if (chunkNumber[1].length) {
				result = result + '.' + chunkNumber[1];
			}
		} else {
			if (pref.decimalNumber && pref.decimalNumber !== '0') {
				result = result + '.' + (chunkNumber[1] + '00').slice(0, pref.decimalNumber);
			}
		}

		return result;
	}
}
