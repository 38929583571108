import { IStepContent } from '@libs/StepModal/StepContent';

import StepModal from '@libs/StepModal';

import OrderStep from './steps/Order';
import QuoteStep from './steps/Quote';

import T_modal from '../../../tpl/modals/orderFromQuote/modal.html';

class OrderFromQuote extends StepModal {
	private data: any;

	constructor(data: any) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.data = data;
	}

	public getTitle() {
		return 'Enregistrement du devis dans une commande';
	}

	public getTheme() {
		return 'add';
	}

	protected isFreeSave() {
		return false;
	}

	protected get steps(): IStepContent[] {
		return [OrderStep, QuoteStep];
	}

	protected async setData() {
		const [stepOrder, stepQuote] = this.stepsInstances;

		await stepOrder.setData({
			quoteID: this.data.quoteID,
			quoteNumber: this.data.quoteNumber,
			infosCustomer: {
				orderNumber: this.data.infosCustomer.orderNumber,
				addonNumber: this.data.infosCustomer.addonNumber,
				customer: this.data.infosCustomer.customer,
				contact: this.data.infosCustomer.contact
			},
			manager: this.data.manager,
			orderLabel: this.data.label,
			orderDescription: this.data.description
		});

		await stepQuote.setData({
			quoteID: this.data.quoteID,
			customer: this.data.infosCustomer.customer,
			contact: this.data.infosCustomer.contact,
			label: this.data.label,
			description: this.data.description,
			deliveryAddress: this.data.deliveryAddress,
			entryDate: this.data.entryDate,
			date: this.data.date,
			deliveryDate: this.data.deliveryDate,
			finalCustomer: this.data.finalCustomer,
			sites: this.data.sites,
			constributors: this.data.constributors,
			informedPeople: this.data.informedPeople,
			purchaseManager: this.data.purchaseManager,
			planningManager: this.data.planningManager,
			price: this.data.price,
			selectedGroups: this.data.selectedGroups
		});
	}

	protected async save() {
		if (this.checkValidity()) {
			this.resolve(this.getData());
		}
	}
}

export default OrderFromQuote;
