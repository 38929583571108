import { Modal, Router } from '@autoprog/core-client';

import FilesTab from '@libs/customElement/FilesTab';
import NotesTab from '@libs/customElement/NotesTab';

import CE_Address from '@libs/customElement/Address';
import CE_SaveButton from '@libs/customElement/SaveButton';
import CE_Select2 from '@libs/customElement/Select2';

type ModalOptions = {
	modalID?: string,
	data?: { [key: string]: any },
	tpl?: string | ((data: object) => string),
	template?: string | ((data: object) => string),
	keyboard?: boolean
	backdrop?: boolean | 'static'
};

class ModalDocument extends Modal {
	protected N_NotesTab: NotesTab | null = null;
	protected N_FilesTab: FilesTab | null = null;

	protected N_unlock: HTMLElement | null = null;
	protected N_form: HTMLFormElement | null = null;

	protected selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	protected closeAfterSave = true;

	private _lock: boolean = false;

	constructor(options: ModalOptions) {
		super(options);

		Router.getInstance().on('route.change', (route: any, params: any, query: any, event: any) => {
			return new Promise<void>((resolve, reject) => {
				//@ts-ignore
				if (Modal.modals[Modal.modals.length - 1].modalID === this.modalID) {
					event.stopPropagation();
					reject(new Error('close modal'));
					this.reject();
				}
			});
		}, {
			signal: this.abortSignal
		});
	}

	private initNotes() {
		this.N_NotesTab = this.element.querySelector(NotesTab.tagName) as NotesTab;

		this.N_NotesTab.setParentElement(this.element);
	}

	private initFiles() {
		this.N_FilesTab = this.element.querySelector(FilesTab.tagName) as FilesTab;

		this.N_FilesTab.setParentElement(this.element);

		this.N_FilesTab.setCallback(() => {
			this.save();
		});
	}

	private initSaveButton() {
		const N_save = this.element.querySelector('#save') as CE_SaveButton;

		N_save.onClick(async () => {
			await this._save();
			if (this.closeAfterSave) {
				this.resolve();
			}
		});

		N_save.on('save.change', (state: string) => {
			const N_closeList = this.element.querySelectorAll('[data-reject],[data-type="close"]') as NodeListOf<HTMLButtonElement>;

			N_closeList.forEach((N_close) => {
				const disabled = state === 'start';
				N_close.disabled = disabled;
				if (disabled) {
					N_close.classList.add('cursor-not-allowed');
				} else {
					N_close.classList.remove('cursor-not-allowed');
				}
			});
		});
	}

	protected init() {
		this.N_unlock = this.element.querySelector('#unlock');
		this.N_form = this.element.querySelector('.modal-body');

		this.N_unlock?.addEventListener('click', () => {
			this.lock = false;
		});

		this.initNotes();
		this.initFiles();
		this.initSaveButton();
	}

	protected postInit() {
		this.N_NotesTab!.postInit();
		this.N_FilesTab!.postInit();

		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}

	protected async save(): Promise<any> {
		throw new Error('get override');
	}

	protected async _save(): Promise<any> {
		await this.save();
		this.lock = true;
	}

	protected get lock() {
		return this._lock;
	}

	protected set lock(value: boolean) {
		if (value) {
			this.N_unlock?.classList.remove('d-none');
			this.N_form?.classList.add('disable');
		} else {
			this.N_unlock?.classList.add('d-none');
			this.N_form?.classList.remove('disable');
		}
		this._lock = value;
	}
}

export default ModalDocument;
