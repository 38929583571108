// CORE

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/detailsCommand.html';

// LIBS
import EditPageModal from '@libs/EditPageModal';

// CUSTOM_ELEMENT
import CE_OrderCustomer from '../../customElements/OrderCustomer';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Sites from '@services/Site/SiteService';

type DetailsCommandData = {
	infos: {
		label: string | string[],
		orderCustomerIDs: string[],
		sites: string[],
	}
};

class DetailsCommand extends EditPageModal<DetailsCommandData> {
	private newMaterials: any[] = [];

	private newOrders: any[] = [];

	private orderProvider: string;

	constructor(data: DetailsCommandData, provider: string) {
		super(data, T_modal);

		this.orderProvider = provider;
	}

	protected firstUpdateSaveButton() {
		this.updateButtonSave();
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.infos.sites = await this.getSites(data.infos.sites);
		data.infos.orderCustomerIDs = await this.getOrderCustomer(data.infos.orderCustomerIDs);

		const additionalData = {
			newMaterials: this.newMaterials,
			newOrders: this.newOrders
		};

		return _.merge(additionalData, data);
	}

	protected preInit() {
		const N_sites = this.element.querySelector('[name="infos.sites"]') as CE_Select2;

		N_sites.create(this.element, { multiple: true });

		this.selectPostinit['infos.sites'] = N_sites;
	}

	protected async setData(data: DetailsCommandData) {
		let labelInfos;
		if (typeof data.infos.label === 'string') {
			labelInfos = data.infos.label;
		} else {
			const orderCustomer = await this.getOrderCustomer(data.infos.label);

			if (orderCustomer.length) {
				labelInfos = orderCustomer[0].text;
			} else {
				labelInfos = '';
			}
		}
		const res: { [key: string]: any } = {
			infos: {
				label: labelInfos,
				orderCustomerIDs: await this.getOrderCustomer(data.infos.orderCustomerIDs),
				sites: await this.getSites(data.infos.sites)
			}
		};

		this.form?.setData(res);
	}

	private async getOrderCustomer(orders: string[]): Promise<{ id: string, text: string }[]> {
		const result: { id: string, text: string }[] = [];

		for (const order of orders) {
			result.push(await S_C_Order.getInstance().getDataToSelect2ByID(order));
		}

		return result;
	}

	private async getSites(sites: string[]): Promise<{ id: string, text: string }[]> {
		const result: { id: string, text: string }[] = [];

		for (const site of sites) {
			result.push(await S_Sites.getInstance().getDataToSelect2ByID(site));
		}

		return result;
	}

	protected postInit() {
		this.initEvents();
		this.postInitOrderCustomer();
		super.postInit();
	}

	private initEvents() {
		const N_label = this.element.querySelector('[name="infos.label"]') as HTMLInputElement;

		N_label.addEventListener('input', () => {
			this.updateButtonSave();
		});
	}

	private postInitOrderCustomer() {
		const N_ordersCustomer = this.element.querySelector('ap-order-customer') as CE_OrderCustomer;

		N_ordersCustomer.postInit(async (products, idOrder) => {
			const { data } = await S_P_Order.getInstance().getDataToModal(undefined, {
				products,
				orderCustomer: idOrder,
				provider: this.orderProvider
			});

			this.newMaterials.push(...data.data.content);

			const orderCustomer = data.data.infos.orderCustomerIDs;

			this.newOrders.push(...orderCustomer);

			N_ordersCustomer.addValues(orderCustomer);

			this.form?.setDataByName('infos.sites', data.data.infos.sites);
		});
	}

	private updateButtonSave() {
		const label = this.form?.getDataByName('infos.label') as string;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save.disabled = !label;
	}
}

export default DetailsCommand;
