import PrintOptionTab from '@libs/customElement/PrintOptionTab';

import T_FormPrintOptions from '../../tpl/blocks/billFormPrintOption.html';

class BillPrintOption extends PrintOptionTab {
	public static readonly tagName: string = 'ap-bill-customer-print-options-tab';

	protected readonly KEY_SETTINGS = 'BILL.OPTIONS';

	protected eventChangeForm = 'options.print.**.change';

	constructor() {
		super();
		this.table = 'bills';
	}

	protected getFormTemplate() {
		return T_FormPrintOptions;
	}

	protected setDefaultValue(value: { [key: string]: any }) {
		this.data = value;
	}

	public set data(data: { [key: string]: any }) {
		this.form!.setData({ options: data });
	}

	public get data() {
		return (this.form!.getData() as { [key: string]: any }).options;
	}

	public static register() {
		customElements.define(this.tagName, BillPrintOption);
	}

	public displayDetailsGroupWarning() {
		const N_DetailsGroupWarning = this.N_tabPanel?.querySelector('#detailsGroupWarning') as HTMLDivElement;
		N_DetailsGroupWarning.classList.remove('d-none');
	}

	/**
	 * Désactive l'option d'affichage des groupes de devis pour les factures en V1
	 */
	public disableDisplayDetailGroupOption() {
		const N_displayDetailgroupOption = this.N_tabPanel!.querySelector("input[name='options.print.display.detailsGroup']") as HTMLInputElement;
		this.form?.setDataByName('options.print.display.detailsGroup', false);

		N_displayDetailgroupOption!.disabled = true;
		const N_DetailsGroupInfo = this.N_tabPanel?.querySelector('#detailsGroupInfo') as HTMLDivElement;
		N_DetailsGroupInfo.classList.remove('d-none');
	}

	/**
	 * Enlève l'option d'affichage des groupes de devis et le récap des factures pour les acompte
	 */
	public removeDisplayDetailGroupOption() {
		const N_displayDetailgroupOption = this.N_tabPanel!.querySelector("input[name='options.print.display.detailsGroup']") as HTMLInputElement;
		const N_recapBillsOption = this.N_tabPanel!.querySelector("input[name='options.print.addon.recapBills']") as HTMLInputElement;
		N_displayDetailgroupOption.parentElement?.remove();
		N_recapBillsOption.parentElement?.remove();
	}
}

export default BillPrintOption;
