import Decimal from '@libs/utils/Decimal';

import QuoteOption from '../../QuoteOptions';
import roundUp from './roundUp';

export default function (group: any, calcul: any) {
	let data = 0;

	const pref = QuoteOption.getInstance().pref;

	if (!group.disabled) {
		for (const item of group.details) {
			if (item.quantity) {
				const tmp = calcul!.calculatePrice(item, pref);
				data += tmp.sum;
			}
		}
	}

	data = roundUp(data, pref);
	return Decimal.setDisplayNumber(data).toDecimalPlaces(pref.decimalNumber).toNumber();
}
