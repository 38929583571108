import { CAttribute, DOM, global } from '@autoprog/core-client';

class Display extends CAttribute {
	public static attrName: string = 'display';

	constructor(el: HTMLElement | SVGElement) {
		super(el);

		this.create();
	}

	public attributeChanged(name: string): void {
		if (name === Display.attrName) {
			this.create();
		}
	}

	private create() {
		const attrContent = this.el.getAttribute(Display.attrName);

		if (global.IS_MOBILE && attrContent === 'desktop') {
			this.el.remove();
		}
		if (!global.IS_MOBILE && attrContent === 'mobile') {
			this.el.remove();
		}
	}

	public destructor() {
		this.el.removeAttribute(Display.attrName);
	}

	public static registry() {
		DOM.registerAttribute(Display as any);
	}
}

export default Display;
