//CORE
//LIBS
import Modal from '@libs/Modal';

//TEMPLATE
import T_modal from '@tpl/modals/mobileSort.html';

// STYLE
import '@css/modals/mobile-sort-filter.scss';

class MobileSort extends Modal {
	private sortList: { key: string, type: string, name: string }[] = [];

	private selectedSort: { selectedKey: string, selectedType: string, selectedSortOrder: string } = { selectedKey: '', selectedType: '', selectedSortOrder: '' };

	constructor(sortList: { key: string, type: string, name: string }[], selectedSort: { selectedKey: string, selectedType: string, selectedSortOrder: string }) {
		super({
			tpl: T_modal
		});

		this.on('opened', () => {
			this.sortList = sortList;
			this.selectedSort = selectedSort;

			this.renderSortOptions();

			const N_reset = this.element.querySelector('#reset') as HTMLButtonElement;

			N_reset.addEventListener('click', () => {
				this.selectedSort = { selectedKey: '', selectedType: '', selectedSortOrder: '' };

				const N_sortDefault = this.element.querySelector('#sort-default') as HTMLInputElement;
				N_sortDefault.checked = true;
			});

			const N_apply = this.element.querySelector('#apply') as HTMLButtonElement;

			N_apply.addEventListener('click', () => {
				this.resolve({
					selectedKey: this.selectedSort.selectedKey,
					selectedType: this.selectedSort.selectedType,
					selectedSortOrder: this.selectedSort.selectedSortOrder
				});
			});
		});
	}

	private renderSortOptions() {
		const N_sortOptions = this.element.querySelector('#sort-options') as HTMLDivElement;

		const N_sortDefaultContainer = document.createElement('div');
		N_sortDefaultContainer.classList.add('mobile-sort-option');

		const N_sortDefaultLabel = document.createElement('label');
		N_sortDefaultLabel.innerHTML = 'Aucun tri';
		N_sortDefaultLabel.setAttribute('for', 'sort-default');
		N_sortDefaultLabel.classList.add('mr-2');

		const N_sortDefault = document.createElement('input');
		N_sortDefault.type = 'radio';
		N_sortDefault.name = 'sort-option';
		N_sortDefault.id = 'sort-default';
		N_sortDefault.setAttribute('checked', '');

		N_sortDefault.addEventListener('change', () => {
			this.selectedSort = { selectedKey: '', selectedType: '', selectedSortOrder: '' };
		});

		N_sortDefaultContainer.append(N_sortDefaultLabel);
		N_sortDefaultContainer.append(N_sortDefault);

		N_sortOptions.append(N_sortDefaultContainer);

		for (const item of this.sortList) {
			const N_sortOptionAscContainer = document.createElement('div');
			const N_sortOptionDescContainer = document.createElement('div');

			N_sortOptionAscContainer.classList.add('mobile-sort-option');
			N_sortOptionDescContainer.classList.add('mobile-sort-option');

			const N_sortOptionAscLabel = document.createElement('label');
			const N_sortOptionDescLabel = document.createElement('label');

			N_sortOptionAscLabel.innerHTML = item.name + ': ordre croissant';
			N_sortOptionDescLabel.innerHTML = item.name + ': ordre décroissant';

			const ascID = 'sort-' + item.key + '-asc';
			const descID = 'sort-' + item.key + '-desc';

			N_sortOptionAscLabel.setAttribute('for', ascID);
			N_sortOptionDescLabel.setAttribute('for', descID);

			N_sortOptionAscLabel.classList.add('mr-2');
			N_sortOptionDescLabel.classList.add('mr-2');

			const N_sortOptionAsc = document.createElement('input');
			const N_sortOptionDesc = document.createElement('input');

			if (item.key === this.selectedSort.selectedKey) {
				N_sortDefault.removeAttribute('checked');

				if (this.selectedSort.selectedSortOrder === 'asc') {
					N_sortOptionAsc.setAttribute('checked', '');
				}

				if (this.selectedSort.selectedSortOrder === 'desc') {
					N_sortOptionDesc.setAttribute('checked', '');
				}
			}

			N_sortOptionAsc.type = 'radio';
			N_sortOptionDesc.type = 'radio';

			N_sortOptionAsc.name = 'sort-option';
			N_sortOptionDesc.name = 'sort-option';

			N_sortOptionAsc.id = ascID;
			N_sortOptionDesc.id = descID;

			N_sortOptionAsc.addEventListener('change', () => {
				this.selectedSort = {
					selectedKey: item.key,
					selectedType: item.type,
					selectedSortOrder: 'asc'
				};
			});

			N_sortOptionDesc.addEventListener('change', () => {
				this.selectedSort = {
					selectedKey: item.key,
					selectedType: item.type,
					selectedSortOrder: 'desc'
				};
			});

			N_sortOptionAscContainer.append(N_sortOptionAscLabel);
			N_sortOptionAscContainer.append(N_sortOptionAsc);

			N_sortOptionDescContainer.append(N_sortOptionDescLabel);
			N_sortOptionDescContainer.append(N_sortOptionDesc);

			N_sortOptions.append(N_sortOptionAscContainer);
			N_sortOptions.append(N_sortOptionDescContainer);
		}
	}
}

export default MobileSort;
