import { AxiosError } from 'axios';
import { toaster } from '@autoprog/core-client';

import { IStepContent } from '@libs/StepModal/StepContent';

import StepModal from '@libs/StepModal';

import StepCaract from './steps/Caract';
import StepDetails from './steps/Details';
import StepProvider from './steps/Provider';
import StepStock from './steps/Stock';

import T_modal from '../../../tpl/modals/addEditProduct/modal.html';

import S_Product from '@services/Product/ProductService';
import S_Unit from '@modules/Units/js/services/UnitsService';

class AddEditProduct extends StepModal {
	private id: string;
	private readonly defaultUnit = 'default_unit';
	private readonly unitKilogram = 'kilogram';

	constructor(id?: string, index: number = 0) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		}, index);

		this.id = id || '';
	}

	public getTitle() {
		if (this.id) {
			return 'Modification d\'un produit';
		} else {
			return 'Ajout d\'un nouveau produit';
		}
	}

	public getTheme() {
		if (this.id) {
			return 'edit';
		} else {
			return 'add';
		}
	}

	protected isEdit() {
		return this.id !== '';
	}

	protected isFreeSave() {
		return true;
	}

	protected get steps(): IStepContent[] {
		return [StepDetails, StepProvider, StepCaract, StepStock];
	}

	protected async setData() {
		const [stepDetails, stepProvider, stepCaract, stepStock] = this.stepsInstances;

		stepDetails.addListener('check-validity', () => {
			this.checkValidity();
		});

		if (this.id) {
			stepDetails.isEdit = true;
			stepProvider.isEdit = true;
			stepCaract.isEdit = true;
			stepStock.isEdit = true;

			let data: { data: { [key: string]: any }, title: string } = { data: {}, title: '' };

			const tmp = await S_Product.getInstance().getDataToModal(this.id);
			data = tmp.data;

			await stepDetails.setData({
				label: data.data.label,
				reference: data.data.reference,
				brand: data.data.brand,
				internalCode: data.data.internalCode,
				forSaleOnline: data.data.forSaleOnline,
				detail: data.data.detail,
				url_photo: data.data.url_photo,
				base64_photo: data.data.base64_photo,
				categories: data.data.categories,
				tags: data.data.tags,
				tvaRate: data.data.tvaRate,
				comptaVente: data.data.comptaVente,
				transporter: data.data.transporter
			});

			await stepProvider.setData({
				providers: data.data.providers || [],
				price: data.data.price
			});

			await stepCaract.setData({
				compatibility: data.data.compatibility,
				model: data.data.model,
				variation: data.data.variation,
				subVariation: data.data.subVariation,
				position: data.data.position,
				unit: data.data.unit,
				unitLength: data.data.unitLength,
				weight: data.data.weight,
				length: data.data.length,
				width: data.data.width,
				height: data.data.height,
				unitWeight: data.data.unitWeight,
				isDangerous: data.data.isDangerous,
				comment: data.data.comment,
				files: data.data.files
			});

			await stepStock.setData({
				stocks: data.data.stocks || [],
				stock: {
					defaultAlarm: data.data.stock?.defaultAlarm,
					hidden: data.data.stock?.hidden,
					calculatePrice: data.data.stock?.calculatePrice,
					enableAlarm: data.data.stock?.enableAlarm
				}
			});
		} else {
			await stepDetails.setData({
				categories: []
			});

			await stepProvider.setData({
				providers: []
			});

			await stepCaract.setData({
				files: [],
				unit: await new S_Unit().getDataToSelect2ByID(this.defaultUnit),
				unitWeight: await new S_Unit().getDataToSelect2ByID(this.unitKilogram)
			});

			await stepStock.setData({
				stocks: [],
				stock: {
					calculatePrice: true,
					defaultAlarm: null,
					enableAlarm: true
				}
			});
		}
	}

	protected async save() {
		if (this.checkValidity()) {
			const data = this.getData();

			data._id = this.id;

			try {
				const res = await S_Product.getInstance().save(data);

				this.resolve(res);
			} catch (e) {
				toaster.error((e as AxiosError).response?.data || (e as Error).message);
			}
		}
	}
}

export default AddEditProduct;
