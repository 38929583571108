import GenericService from '../GenericService';

class TagsService extends GenericService {
	private static instance: TagsService | null = null;

	constructor() {
		super('products-tags');
	}

	public static getInstance(): TagsService {
		if (!TagsService.instance) {
			TagsService.instance = new TagsService();
		}

		return TagsService.instance;
	}
}

export default TagsService;
