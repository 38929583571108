module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="card-mobile">\n\n    <div class="w-100 text-sm">\n        <div class="font-weight-bold text-blue-grey-900">\n            ' +
((__t = (data.number || '&nbsp;')) == null ? '' : __t) +
' - ' +
((__t = (data.name || '&nbsp;')) == null ? '' : __t) +
'\n        </div>\n        <div class="mt-2">\n            ';
 if(data.phoneNumber){;
__p += '\n                <a href="tel:' +
((__t = (data.phoneNumber)) == null ? '' : __t) +
';" data-cancel-modal="true">\n                    <i class="icon icon-phone mr-2"></i> ' +
((__t = (data.phoneNumber || '&nbsp;')) == null ? '' : __t) +
'\n                </a>\n            ';
}else{;
__p += '\n                <span class="text-muted">\n                    <i class="icon icon-phone mr-2"></i> Aucun Téléphone\n                </span>\n            ';
};
__p += '\n        </div>\n        <div class="mt-2">\n            ';
 if(data.email){;
__p += '\n                <a href="mailto:' +
((__t = (data.email)) == null ? '' : __t) +
';" data-cancel-modal="true"> \n                    <i class="icon icon-envelope mr-2"></i> ' +
((__t = (data.email || '&nbsp;')) == null ? '' : __t) +
'\n                </a>\n            ';
}else{;
__p += '\n                <span class="text-muted">\n                    <i class="icon icon-envelope mr-2"></i> Aucun Email\n                </span>\n            ';
};
__p += '\n        </div>\n    </div>\n\n</div>';

}
return __p
}