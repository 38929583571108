import '../../../css/customElement/editTags.scss';

import CE_Button from '@libs/customElement/Button';
import CE_Select from '@libs/customElement/Select';

import S_Tags from '@services/Product/ProductTagsService';

class EditTags extends HTMLElement {
	public static readonly tagName: string = 'ap-edit-tags';

	private _data: { [key: string]: boolean } = {};

	public connectedCallback() {
		this.innerHTML = `
			<div class="content">
				<div class="item add">+ Ajouter</div>
			</div>
			<div class="select">
				<ap-select></ap-select>
			</div>
		`;

		const N_add = this.querySelector<HTMLButtonElement>('.add')!;
		const N_select = this.querySelector<CE_Select>('ap-select')!;

		N_select.options = {
			createItem: true,
			ajax: {
				url: S_Tags.getInstance().createSelect2URL(),
				getParams(search) {
					return {
						search
					};
				}
			}
		};

		N_select.addEventListener('change', () => {
			this.renderItem(N_select.value as string);
			N_select.value = '';
			this.classList.remove('select-value');
		});

		N_select.addEventListener('close', () => {
			N_select.value = '';
			this.classList.remove('select-value');
		});

		N_add?.addEventListener('click', async () => {
			this.classList.add('select-value');
			N_select.open();
		});
	}

	private renderItem(id: string) {
		if (!this._data[id]) {
			this._data[id] = true;

			const N_content = this.querySelector<HTMLElement>('.content');

			const N_div = document.createElement('div');

			N_div.classList.add('item');

			S_Tags.getInstance().getDisplayRefByID(id).then((text) => {
				N_div.innerHTML = `${text} <ap-button class="btn-icon" icon="close" confirmation></ap-button>`;

				const N_delete = N_div.querySelector<CE_Button>('ap-button');

				N_delete?.addEventListener('click', () => {
					delete this._data[id];
					N_div.remove();
				});
			});

			N_content?.prepend(N_div);
		}
	}

	public set data(ids: string[]) {
		for (const id of ids) {
			this.renderItem(id);
		}
	}

	public get data() {
		return Object.keys(this._data);
	}

	public static register() {
		customElements.define(EditTags.tagName, EditTags);
	}
}

export default EditTags;
