import { Form } from '@autoprog/core-client';
import h from 'hyperscript';

// TEMPLATE
import T_modal from '../../tpl/modals/addEditCompanies.html';

// LIBS
import Modal from '@libs/Modal';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';

// STYLE
import '../../css/addEditCompanies.scss';

// TYPES
import { Company } from '@modules/Settings/js/types/company';
import { CompanyAddress } from '@modules/Settings/js/types/companyAddress';
import { CompanyBank } from '../types/companyBank';

class AddEditCompanies extends Modal {
	private form: Form | null = null;

	private company: Company | undefined = undefined;

	private isEditMode: boolean = false;

	constructor(company?: Company) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			this.company = company;
			this.isEditMode = this.company !== undefined;

			this.init();
		});
	}

	private init() {
		if (this.isEditMode) {
			this.setTitleForEdit();
		}

		this.initAddressListGrid();
		this.initBankListGrid();

		this.initForm();
		this.initLogoForm();

		this.updateLogoPreview();

		this.initLogoResetButton();
		this.initLogoDeleteButton();

		this.initSaveButton();
	}

	/**
	 * Définit le titre dans le contexte d'une modale en mode édition
	 */
	private setTitleForEdit() {
		const N_Title = this.element.querySelector('#title') as HTMLDivElement;
		N_Title.innerHTML = 'Édition de la société';
	}

	/**
	 * Initialise le formulaire
	 */
	private initForm() {
		const N_form = this.element.querySelector('form') as HTMLFormElement;
		const N_gridAddress = this.element.querySelector<CE_AgGrid>('#company-address-list')!;
		const N_gridBank = this.element.querySelector<CE_AgGrid>('#company-bank-list')!;
		this.form = new Form(N_form);

		this.form.setData(this.company as { [key: string]: any });

		N_gridAddress.value = this.company?.addressList || [];
		N_gridBank.value = this.company?.bankList || [];
	}

	/**
	 * Initialise la partie du formulaire conçernant l'icone
	 */
	private initLogoForm() {
		const N_CompanyLogoFile = this.element.querySelector('#company-logo-file') as HTMLInputElement;

		N_CompanyLogoFile.addEventListener('change', () => {
			const files = N_CompanyLogoFile.files;

			if (files && files.length > 0) {
				const file = files[0];

				const reader = new FileReader();

				reader.onloadend = () => {
					const base64 = reader.result as string;
					this.form?.setDataByName('logo', base64);
					this.updateLogoPreview();
				};

				reader.readAsDataURL(file);
			}
		});
	}

	/**
	 * Met à jour la prévisualisation du logo
	 */
	private updateLogoPreview() {
		const N_CompanyLogoPreview = this.element.querySelector('#company-logo-preview') as HTMLDivElement;

		const logo = this.form?.getDataByName<string>('logo');

		if (logo) {
			N_CompanyLogoPreview.innerHTML = `<img src="${logo}">`;
		} else {
			N_CompanyLogoPreview.innerHTML = 'Aucun logo';
		}
	}

	/**
	 * Initialise l'ag-grid de la liste des adresses
	 */
	private initAddressListGrid() {
		const N_grid = this.element.querySelector<CE_AgGrid>('#company-address-list')!;

		N_grid?.setGridOptions({
			columnDefs: [{
				headerName: 'Nom *',
				field: 'name',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.name
				}
			}, {
				headerName: 'Adresse *',
				field: 'address',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.address
				}
			}, {
				headerName: 'Code Postal *',
				field: 'zipCode',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.zipCode
				}
			}, {
				headerName: 'Ville *',
				field: 'city',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.city
				}
			}, {
				headerName: 'Pays *',
				field: 'country',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.country
				}
			}, {
				headerName: 'Téléphone *',
				field: 'phone',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.phone
				}
			}, {
				headerName: 'Fax',
				field: 'fax'
			}, {
				headerName: 'Email *',
				field: 'email',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.email
				}
			}, {
				headerName: 'Défaut',
				field: 'default',
				editable: false,
				cellRenderer: (params) => {
					const N_checkbox = document.createElement('div');
					N_checkbox.classList.add('text-center');

					if (params.value) {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
					} else {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
					}

					N_checkbox.addEventListener('click', () => {
						const value = !params.value;

						params.api?.forEachNode((node: any) => {
							node.setDataValue('default', false);
						});

						params.node.setDataValue('default', value);

						if (value) {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
						} else {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
						}
					});

					return N_checkbox;
				}
			}, {
				headerName: 'Action',
				width: 80,
				pinned: 'right',
				resizable: false,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params) => {
					const N_delete = h('ap-button.btn-action-aggrid.btn-delete', { attrs: { confirmation: 'true', tooltip: 'Supprimer', icon: 'delete-bin/line' } });

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_delete);

					return N_div;
				}
			}],
			defaultColDef: {
				editable: true
			},
			getContextMenuItems: (params) => {
				return [{
					name: 'Ajouter une adresse',
					action: () => {
						params.api?.applyTransaction({ add: [{}] });
					}
				}];
			},
			localeText: {
				noRowsToShow: 'Aucune adresse'
			}
		});
	}

	/**
	 * Initialise l'ag-grid de la liste des adresses
	 */
	private initBankListGrid() {
		const N_grid = this.element.querySelector<CE_AgGrid>('#company-bank-list')!;

		N_grid?.setGridOptions({
			columnDefs: [{
				headerName: 'Nom *',
				field: 'name',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.name
				}
			}, {
				headerName: 'IBAN *',
				field: 'IBAN',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.IBAN
				}
			}, {
				headerName: 'RIB *',
				field: 'RIB',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.RIB
				}
			}, {
				headerName: 'Code SWIFT *',
				field: 'SWIFT',
				cellClassRules: {
					'cell-invalid': (params: any) => !params.data.SWIFT
				}
			}, {
				headerName: 'Commande Fournisseur',
				field: 'useCommandProvider',
				editable: false,
				cellRenderer: (params) => {
					const N_checkbox = document.createElement('div');
					N_checkbox.classList.add('text-center');

					if (params.value) {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
					} else {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
					}

					N_checkbox.addEventListener('click', () => {
						const value = !params.value;

						params.node.setDataValue('useCommandProvider', value);

						if (value) {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
						} else {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
						}
					});

					return N_checkbox;
				}
			}, {
				headerName: 'Facture',
				field: 'useBill',
				editable: false,
				cellRenderer: (params) => {
					const N_checkbox = document.createElement('div');
					N_checkbox.classList.add('text-center');

					if (params.value) {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
					} else {
						N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
					}

					N_checkbox.addEventListener('click', () => {
						const value = !params.value;

						params.node.setDataValue('useBill', value);

						if (value) {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
						} else {
							N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
						}
					});

					return N_checkbox;
				}
			}, {
				headerName: 'Action',
				width: 80,
				pinned: 'right',
				resizable: false,
				suppressSizeToFit: true,
				editable: false,
				cellRenderer: (params) => {
					const N_delete = h('ap-button.btn-action-aggrid.btn-delete', { attrs: { confirmation: 'true', tooltip: 'Supprimer', icon: 'delete-bin/line' } });

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					const N_div = h<HTMLElement>('div.container-action-aggrid', N_delete);

					return N_div;
				}
			}],
			defaultColDef: {
				editable: true
			},
			getContextMenuItems: (params) => {
				return [{
					name: 'Ajouter une banque',
					action: () => {
						params.api?.applyTransaction({ add: [{}] });
					}
				}];
			},
			localeText: {
				noRowsToShow: 'Aucune banque'
			}
		});
	}

	/**
	 * Initialise le bouton de réinitialisation du logo par défaut
	 */
	private initLogoResetButton() {
		const N_CompanyLogoReset = this.element.querySelector<CE_Button>('#company-logo-reset')!;
		N_CompanyLogoReset.addEventListener('click', () => {
			this.form?.setDataByName('logo', this.company?.logo || '');
			this.updateLogoPreview();
		});
	}

	/**
	 * Initialise le bouton de suppression du logo
	 */
	private initLogoDeleteButton() {
		const N_CompanyLogoDelete = this.element.querySelector<CE_Button>('#company-logo-delete')!;
		N_CompanyLogoDelete.addEventListener('click', () => {
			this.form?.setDataByName('logo', '');
			this.updateLogoPreview();
		});
	}

	/**
	 * Initialise les bouton de sauvegarde de la modale
	 */

	private getSaveData() {
		const N_CompanyAddressList = this.element.querySelector<HTMLDivElement>('#company-address-list')!;
		const N_AddressListInvalidMessage = this.element.querySelector<HTMLDivElement>('#address-list-invalid-message')!;
		const N_DefaultAddressInvalidMessage = this.element.querySelector<HTMLDivElement>('#default-address-invalid-message')!;
		const N_gridAddress = this.element.querySelector<CE_AgGrid>('#company-address-list')!;
		const N_gridBank = this.element.querySelector<CE_AgGrid>('#company-bank-list')!;

		const addressList: CompanyAddress[] = N_gridAddress.value;
		const bankList: CompanyBank[] = N_gridBank.value;

		//On vérifie s'il y a des adresses invalides
		const hasInvalidAddress = N_gridBank.querySelectorAll('.cell-invalid').length > 0;
		const isAddressListValid = addressList.length > 0 && !hasInvalidAddress;

		N_CompanyAddressList.classList.toggle('invalid', !isAddressListValid);
		//On affiche le message d'erreur sur le formulaire si besoin
		N_AddressListInvalidMessage.classList.toggle('d-none', isAddressListValid);

		//On vérifie si il y a une adresse par défaut
		const hasDefaultAddress = isAddressListValid ? addressList.some(address => address.default === true) : true;
		//On affiche le message d'erreur sur le formulaire si besoin
		N_DefaultAddressInvalidMessage.classList.toggle('d-none', hasDefaultAddress);

		if (this.form?.checkValidity() && isAddressListValid && hasDefaultAddress) {
			const data = this.form?.getData() as Company;

			if (data) {
				if (this.company?._id) {
					data._id = this.company._id;
				}

				data.addressList = addressList;
				data.bankList = bankList;
			}

			return data;
		}
	}

	private initSaveButton() {
		const N_validate = this.element.querySelector<CE_Button>('#validate')!;

		N_validate.addEventListener('click', () => {
			const data = this.getSaveData();

			if (data) {
				this.resolve(data);
			}
		});
	}
}

export default AddEditCompanies;
