// CORE
import { Router } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

//STYLE
import '../../../../css/quickAccess.scss';

class Interventions extends HTMLElement {
	public static readonly tagName: string = 'ap-interventions';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `
			<div class="d-flex align-items-center text-nowrap quickaccess-card">
				<div class="p-3 w-100">
                    <div class="d-flex w-100">
                        <div class="d-flex align-items-center">
							<i class="quickaccess-icon interventions-icon d-flex align-items-center justify-content-center icon icon-location h2 px-3 py-2"
							style="height: 56px; width: 56px"></i>
						</div>
                        <div class="pl-3 w-100">
                            <div class="d-flex font-weight-bold text-grey-700 h6">Interventions</div>
                            <div style="font-size: 12px">
                                <div class="d-flex w-100">
                                    <div>À facturer</div>
                                    <div class="ml-auto font-weight-bold" data-key="interventions.invoiced"></div>
                                </div>
                                <div class="d-flex w-100">
                                    <div>Futures</div>
                                    <div class="ml-auto font-weight-bold" data-key="interventions.future"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<div class="quickaccess-link-icon">
					<i class="icon icon-solid-external-link-alt"></i>
				<div>
            </div>
        `;
	}

	private linkData() {
		const N_interventions_invoiced = this.querySelector('[data-key="interventions.invoiced"]');
		const N_interventions_future = this.querySelector('[data-key="interventions.future"]');

		N_interventions_invoiced!.innerHTML = this._data.interventions.invoiced;
		N_interventions_future!.innerHTML = this._data.interventions.future;
	}

	private initButton() {
		const router = Router.getInstance();

		const N_interventions = this.querySelector('.quickaccess-card') as HTMLElement;

		N_interventions?.addEventListener('click', () => {
			router.navigate('#module/apps/interventions');
		});
	}

	public set data(data: any) {
		this._data = data;
		this.linkData();
		this.initButton();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(Interventions.tagName, Interventions);
	}
}

export default Interventions;
