import Calcul from '@modules/Quotes/js/libs/Calcul';
import GridOptions from '@modules/Quotes/js/libs/GridOptions';
import Options from '@modules/Quotes/js/libs/QuoteOptions';

import EditCellRenderer from '@libs/agGrid/cellRenderer/EditCellRenderer';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';

import CE_Aggrid from '@libs/customElement/AgGrid';

import DecimalApps from '@libs/utils/Decimal';

import S_Quotes from '@services/QuoteService';

class GroupQuotesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-group-quotes-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_el: HTMLElement | null = null;

	private _selectedGroups: { [key: string]: number } = {};

	private options: Options | null = null;
	private gridOptions: GridOptions | null = null;

	private N_grid: CE_Aggrid | null = null;

	public connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || GroupQuotesTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-list/line" icon-active="file-list/fill" text="Sélection groupes devis"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid?.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Liste du matériels par devis
						
				<div class="page-tabs-title-right">
					<div class="px-4 font-weight-bold text-green border-right align-items-center d-flex text-md">
						Montant sélection : <span id="price" class="text-monospace pl-2"></span>
					</div>

					<div class="px-4 d-flex align-items-center text-md">
						<div class="badge pl-2 text-dark">
							Légende :
						</div>
						<small class="d-flex align-items-center text-purple-900 mr-2">
							<div class="badge-legend bg-purple-100">
							</div>
							Catégorie en option
						</small>
					</div>

					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
					
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
	}

	private async initGrid() {
		this.N_grid = this.N_el!.querySelector<CE_Aggrid>('#grid');

		this.options = Options.newInstance();

		this.gridOptions = new GridOptions();

		const columnsOptions = this.gridOptions.gridMaster('quote');

		columnsOptions.defaultColDef.editable = () => {
			return false;
		};

		columnsOptions.columnDefs[0].hide = true;

		const indexQuantity = columnsOptions.columnDefs.findIndex(item => item.field === 'quantity');

		columnsOptions.columnDefs[indexQuantity] = {
			...columnsOptions.columnDefs[indexQuantity],
			editable: true,
			suppressSizeToFit: true,
			width: 120,
			cellEditor: NumericCellEditor,
			cellRenderer: EditCellRenderer,
			cellRendererParams: {
				CellRenderer: NumericCellRenderer,
				decimalNumber: 0
			}
		};

		columnsOptions.columnDefs.splice(indexQuantity, 0, {
			headerName: 'Qté devis',
			field: 'quantityQuote',
			suppressSizeToFit: true,
			width: 120,
			cellRenderer: NumericCellRenderer,
			cellRendererParams: {
				decimalNumber: 0
			}
		});

		columnsOptions.columnDefs.pop();

		this.N_grid!.setGridOptions({
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			suppressClickEdit: true,
			suppressContextMenu: true,
			...columnsOptions,
			onCellEditingStopped: (params) => {
				params.api.refreshCells({ force: true });
				this.update();
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			getRowStyle: (params: any) => {
				if (params.data.isOption) {
					return {
						'background-color': 'var(--ap-purple-50)',
						color: 'var(--ap-purple-900)'
					};
				} else {
					return {
						background: '',
						color: ''
					};
				}
			}
		});
	}

	private update() {
		this._selectedGroups = {};

		this.N_grid!.forEachNode((node) => {
			this._selectedGroups[node.data._idGrid] = node.data.quantity;
		});

		const N_price = this.N_el!.querySelector('#price') as HTMLElement;
		N_price.innerHTML = DecimalApps.setDisplayNumber(this.price).setSuffixAndHumanizeNumber('€', -1);

		this.dispatchEvent(new CustomEvent('update'));
	}

	public get selectedGroups() {
		return this._selectedGroups;
	}

	public get price() {
		const data: any[] = [];

		this.N_grid!.forEachNode((node) => {
			this._selectedGroups[node.data._idGrid] = Number(node.data.quantity);
			data.push(node.data);
		});

		return new Calcul().calculateGlobalPrice(data).all;
	}

	public async initData(id: string, selectingRows: { [key: string]: number }) {
		this._selectedGroups = selectingRows;
		const data = await S_Quotes.getInstance().getById(id);

		for (const item of data.data) {
			item.quantityQuote = item.quantity;
			item.quantity = this._selectedGroups[item._idGrid];
		}

		this.options!.infos = data.infos;
		this.options!.pref = data.pref;
		this.options!.quoteOptions = data.quoteOptions;
		this.options!.version = data.version;

		this.gridOptions!.init();

		this.N_grid!.value = data.data;

		this.update();
	}

	public static register() {
		customElements.define(GroupQuotesTab.tagName, GroupQuotesTab);
	}
}

export default GroupQuotesTab;
