import generateID from '@libs/utils/generateID';

class PrimaryKey {
	private value: string;

	constructor(value?: string) {
		this.value = value || generateID();
	}

	public toDashboard() {
		return {
			formattedValue: this.value,
			value: this.value,
			quickFilter: ''
		};
	}

	public toDocument() {
		return this.value;
	}

	public toSave() {
		return this.value;
	}

	public toPrint() {
		return this.value;
	}

	public getValue() {
		return this.value;
	}
}

export default PrimaryKey;
