class Loader {
	private N_div: HTMLElement | null = null;

	private static instance: Loader;

	private _active = false;

	public static getInstance() {
		if (!Loader.instance) {
			Loader.instance = new Loader();
		}

		return Loader.instance;
	}

	public open() {
		if (!this._active) {
			this.N_div = document.createElement('div');

			this._active = true;

			this.N_div.classList.add('loader');

			this.N_div.innerHTML = `
				<div class="double-bounce1"></div>
				<div class="double-bounce2"></div>
			`;

			document.body.classList.add('positon-relative');

			document.body.appendChild(this.N_div);
		}
	}

	public close() {
		document.body.classList.remove('positon-relative');

		this._active = false;

		this.N_div?.remove();
	}

	public get active() {
		return this._active;
	}
}

export default Loader;
