import { Component } from '@ag-grid-enterprise/all-modules';

class TextareaEditor extends Component {
	constructor() {
		super('<div class="h-100 w-100"></div>');
	}

	public getValue() {
		const textarea = this.getGui().querySelector('textarea') as HTMLTextAreaElement;
		return textarea.value.trim();
	}

	public afterGuiAttached() {
		const textarea = this.getGui().querySelector('textarea') as HTMLTextAreaElement;

		(this.getGui().parentNode as HTMLElement).classList.add('h-100');

		textarea.focus();
	}

	public isPopup() {
		return false;
	}

	public init(params: any) {
		const textarea = document.createElement('textarea');
		textarea.classList.add('h-100', 'w-100');
		textarea.style.lineHeight = '18px';

		textarea.value = (params.value || '') + (params.charPress || '');

		this.getGui().appendChild(textarea);

		this.addGuiEventListener('keydown', (e) => {
			//enter
			if (e.keyCode === 13) {
				e.stopPropagation();
			}

			//arrow
			if ([37, 38, 39, 40].indexOf(e.keyCode) !== -1) {
				e.stopPropagation();
			}
		});

		this.addGuiEventListener('contextmenu', (e) => {
			e.stopPropagation();
		});
	}

	public destroy() {
		(this.getGui().parentNode as HTMLElement).classList.remove('h-100');
	}
}

export default TextareaEditor;
