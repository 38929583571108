// CORE
import { Controller, Router, global } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';
import moment from 'moment';
import tippy from 'tippy.js';

// TEMPLATE
// LIBS
import Keyboard from '@libs/Keyboard';
import Loader from '@libs/Loader';
import StateSaver from '@libs/agGrid/StateSaver';
import Utils from '@libs/utils/Utils';

import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_Edit from '../modals/Edit';
import M_Input from '../modals/Input';
import M_M_Input from '../modals/multiple/Input';
import M_M_Output from '../modals/multiple/Output';
import M_Output from '../modals/Output';

// CUSTOM_ELEMENT
import CE_AgGrid from '@libs/customElement/AgGrid';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class StockTactileController extends Controller {
	private N_grid: CE_AgGrid;

	private currentStock: string = '';

	private el: HTMLElement;

	private stateSaver: StateSaver | null = null;

	private keyboard: Keyboard | null = null;

	private viewAllProductsChecked = false;

	constructor(el: HTMLElement) {
		super(el);

		global.NO_ASIDE = true;
		global.NO_NAVBAR = true;

		this.el = el;

		$(document).on(global.CLICK_EVENT_TACTILE, ':not(.popover)', function () {
			//@ts-ignore
			$('.popover').popover('hide');
		});

		this.N_grid = el.querySelector<CE_AgGrid>('#grid')!;

		this.N_grid.setGridOptions({
			columnDefs: [
				{
					headerName: '',
					width: 100,
					suppressSizeToFit: true,
					cellClass: 'p-0',

					cellRenderer: (params) => {
						const N_div = document.createElement('div');
						N_div.classList.add('container-icons-aggrid');

						const cellRenderer = new ProductCellRenderer();
						cellRenderer.init({ params, value: params.data.product._id.value, isOnlyImage: true });

						N_div.append(cellRenderer.getGui());

						return N_div;
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product.' + S_Product.getInstance().referenceKey,
					width: 220,
					suppressSizeToFit: true
				},
				{
					headerName: 'Désignation',
					field: 'product.label',
					filterParams: {
						textFormatter: (result: string) => {
							if (result == null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				},
				{
					field: 'stock',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					field: 'displayStock',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Empl.',
					field: 'product.locationStock',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Dernière Vérification',
					field: 'lastUpdate.date',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.data.lastUpdate && params.data.lastUpdate.user) {
							return `
								<div class="text-center w-100" style="line-height: 25px">${moment(params.data.lastUpdate.date).format('DD/MM/YYYY HH:mm')}</div>
                                <div class="text-xs text-muted text-center w-100" style="line-height: 25px">Par ${params.data.lastUpdate.user}</div>        
                            `;
						} else {
							return `
                                <span class="text-muted">Aucune vérification</span>
                            `;
						}
					}
				},
				{
					headerName: 'Qté',
					field: 'quantity',
					width: 80,
					filter: 'agNumberColumnFilter',
					suppressSizeToFit: true,
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: '#',
					width: 80,
					suppressSizeToFit: true,
					suppressColumnsToolPanel: true,
					filter: false,
					pinned: 'right',
					cellRenderer: (params) => {
						const productID = params.data.product._id.value;
						const stockID = params.data.stock.value;
						const quantity = params.data.quantity.value;

						const N_div = document.createElement('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('btn', 'btn-transparent');
						N_edit.innerHTML = '<i class="icon icon-solid-ellipsis-v"></i>';

						N_edit.setAttribute('permission', 'STOCK.INPUT || STOCK.OUTPUT || STOCK.UPDATE');

						const N_tooltip = document.createElement('div');
						N_tooltip.classList.add('d-flex', 'flex-column');
						N_tooltip.innerHTML = `
							<button class="btn btn-blue-50 mb-2" id="input" permission="STOCK.INPUT"><i class="icon icon-solid-sign-in-alt mr-2 text-indigo"></i>Entrée</button>
							<button class="btn btn-blue-50 mb-2" id="output" permission="STOCK.OUTPUT"><i class="icon icon-solid-sign-out-alt mr-2 text-red"></i>Sortie</button>
							<button class="btn btn-blue-50" id="edit" permission="STOCK.UPDATE"><i class="icon icon-solid-sync-alt mr-2"></i>Mettre à jour</button>
						`;

						const N_input = N_tooltip.querySelector<HTMLButtonElement>('#input')!;
						const N_output = N_tooltip.querySelector<HTMLButtonElement>('#output')!;
						const N_update = N_tooltip.querySelector<HTMLButtonElement>('#edit')!;

						N_input.addEventListener('click', () => {
							this.keyboard?.close();

							new M_Input(productID, quantity, stockID).open().then(() => {
								this.getData();
							}).catch(() => { });
						});

						N_output.addEventListener('click', () => {
							this.keyboard?.close();

							new M_Output(productID, quantity, stockID).open().then(() => {
								this.getData();
							}).catch(() => { });
						});

						N_update.addEventListener('click', () => {
							this.keyboard?.close();

							new M_Edit(productID, quantity, stockID).open().then(() => {
								this.getData();
							}).catch(() => { });
						});

						tippy(N_edit, {
							trigger: 'click',
							allowHTML: true,
							interactive: true,
							theme: 'material',
							appendTo: document.body,
							content: N_tooltip
						});

						N_div.appendChild(N_edit);

						return N_div;
					}
				}
			],
			defaultColDef: {
				resizable: false,
				sortable: false,
				suppressMovable: true,
				suppressMenu: true,
				headerClass: ['bg-blue-50', 'text-lg'],
				cellClass: ['d-flex', 'align-items-center', 'text-lg']
			},
			suppressRowClickSelection: true,
			suppressCellSelection: true,
			headerHeight: 40,
			suppressContextMenu: true,
			getRowHeight: () => {
				return 60;
			},
			onGridReady: async (params) => {
				await this.getData();
				params.api.sizeColumnsToFit();
			},
			onFilterChanged: (params: any) => {
				const filter = params.api.getFilterModel();

				delete filter.stock;
				delete filter.displayStock;
				delete filter.quantity;

				params.columnApi.setColumnsVisible(['displayStock', 'stock'], false);
				params.api.sizeColumnsToFit();

				this.updatePrice();
			},
			rowData: []
		});

		this.initList(el);
		this.initMultipleOutput();
		this.initMultipleInput();

		const N_search = this.el.querySelector('#search') as HTMLInputElement;

		this.keyboard = new Keyboard(N_search, {
			eventClick: global.CLICK_EVENT_TACTILE,
			parent: this.el.querySelector('#container-keyboard') as HTMLElement
		});

		let timeout: any = null;
		N_search.addEventListener('input', () => {
			timeout && clearTimeout(timeout);
			timeout = setTimeout(() => {
				this.N_grid.api?.setQuickFilter(N_search.value);
			}, 200);
		});

		const N_viewAllProducts = el.querySelector('#viewAllProducts') as HTMLInputElement;

		N_viewAllProducts.addEventListener('change', () => {
			this.viewAllProductsChecked = !this.viewAllProductsChecked;

			if (this.N_grid.api) {
				this.viewAllProductsChecked = N_viewAllProducts.checked;

				const filterInstance = this.N_grid.api?.getFilterInstance('displayStock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.viewAllProductsChecked ? '' : 'true'
				});

				this.N_grid.api?.onFilterChanged();
			}
		});

		const N_home = el.querySelector('#home') as HTMLButtonElement;

		N_home.addEventListener('click', () => {
			Router.getInstance().navigate('');
		});
	}

	private initMultipleOutput() {
		const N_multiple_output = this.el.querySelector('#multiple_output') as HTMLButtonElement;

		N_multiple_output.addEventListener('click', () => {
			new M_M_Output(this.currentStock).open().then(() => {
				this.getData();
			});
		});
	}

	private initMultipleInput() {
		const N_multiple_input = this.el.querySelector('#multiple_input') as HTMLButtonElement;

		N_multiple_input.addEventListener('click', () => {
			new M_M_Input(this.currentStock).open().then(() => {
				this.getData();
			});
		});
	}

	private initList(el: HTMLElement) {
		const N_list = el.querySelector('#list') as HTMLSelectElement;

		S_Stock.getInstance().getRealStock().then((data) => {
			for (const item of data) {
				const option = new Option(item.name, item._id, item.default, item.default);

				N_list.append(option);

				if (item.default) {
					this.currentStock = item._id;
				}
			}
		});

		N_list.addEventListener('change', () => {
			if (this.N_grid.api) {
				this.currentStock = N_list.value;

				const filterInstance = this.N_grid.api.getFilterInstance('stock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.currentStock
				});

				this.N_grid.api.onFilterChanged();
			}
		});
	}

	private async getData() {
		Loader.getInstance().open();

		const data = await S_StockEvent.getInstance().getCurrentStock({
			user: Utils.userID
		});

		this.stateSaver?.setData(data.settings);

		this.N_grid.value = data.rowData;

		Loader.getInstance().close();

		const filterInstanceStock = this.N_grid.api?.getFilterInstance('stock');

		filterInstanceStock?.setModel({
			type: 'equals',
			filter: this.currentStock
		});

		const filterInstanceDisplay = this.N_grid.api?.getFilterInstance('displayStock');

		filterInstanceDisplay?.setModel({
			type: 'equals',
			filter: this.viewAllProductsChecked ? '' : 'true'
		});

		this.N_grid.api?.onFilterChanged();
	}

	private updatePrice() {
		let res = new Decimal(0);
		let index = 0;

		this.N_grid.api?.forEachNodeAfterFilter((node) => {
			if (node.data.quantity.value >= 0) {
				res = res.plus(Decimal.setDisplayNumber(node.data.totalPurchasePrice?.value || '0'));
			}
			index++;
		});

		const N_price = this.el.querySelector('#price') as HTMLElement;
		const N_numberProducts = this.el.querySelector('#numberProducts') as HTMLElement;

		N_price.innerHTML = res.setSuffixAndHumanizeNumber('€');
		N_numberProducts.innerHTML = index.toString();
	}

	public destructor() {

	}
}

export default StockTactileController;
