import moment from 'moment';

import Export from '@libs/export/Export';
import ExportUtils from '@libs/utils/ExportUtils';

// CORE
// NODE_MODULE
// TEMPLATE
// LIBS
import C_Database from '@js/controllers/Database';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

class History extends C_Database {
	protected header() {
		const N_div = document.createElement('div') as HTMLDivElement;

		N_div.classList.add('d-flex', 'flex-grow-1');

		const N_Export = document.createElement('button');
		N_Export.classList.add('btn', 'mr-1', 'btn-export-csv', 'ml-auto');
		N_Export.innerHTML = '<i class="icon icon-solid-file-csv"></i>';
		N_Export.setAttribute('tooltip', 'Exporter les données en CSV');
		N_Export.setAttribute('permission', 'STOCK._STOCK_EVENTS.EXPORT');

		N_Export.addEventListener('click', () => {
			const csvString = new Export()
				.included(['stock', 'type', 'product', 'user', 'date', 'quantity', 'traceability.provider', 'traceability.command_provider', 'traceability.receipt', 'traceability.customer', 'traceability.command_customer', 'traceability.stock'])
				.fromTableName(this.tableName)
				.fromGridOptions(this.gridOptions)
				.createCSV();

			const a = ExportUtils.createFileLink(csvString);
			a.download = `Export-Historique-Stock-${moment().format('DD-MM-YYYY')}.csv`;
			a.click();
			a.remove();
		});

		N_div.appendChild(N_Export);
		return N_div;
	}
}

export default History;
