// CORE

// NODE_MODULE
import { ColDef } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_modal from '../../tpl/modals/editStockOutput.html';

// LIBS
import Modal from '@libs/Modal';

import NumericCellEditor from '@libs/agGrid/NumericCellEditor';

import EditCellRenderer from '@libs/agGrid/cellRenderer/EditCellRenderer';
import LoadingCellRenderer from '@libs/agGrid/cellRenderer/LoadingCellRenderer';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

import CE_Aggrid from '@libs/customElement/AgGrid';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

class EditStockOutput extends Modal {
	private N_grid: CE_Aggrid | null = null;

	private locations: { [key: string]: any }[] = [];

	private products: {
		[id: string]: { unit: string, brand: string, price: string, label: string, reference: string }
	} = {};

	private idOrder: string = '';

	constructor(idOrder: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.idOrder = idOrder;

		this.on('opened', () => {
			this.N_grid = this.element.querySelector<CE_Aggrid>('#grid')!;
			this.N_grid?.setGridOptions({
				columnDefs: [{
					headerName: ''
				}]
			});

			this.initData();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => {
				N_save.loading(new Promise<void>(async (resolve) => {
					const data = this.N_grid!.value;

					const stockData: { [key: string]: any }[] = [];
					const dataToRefresh: { [key: string]: any }[] = [];

					for (const item of data) {
						stockData.push({
							deliveryToCustomer: item.deliveryToCustomer,
							quantity: item.quantity,
							productID: item.productID,
							stocks: item.stocks
						});

						dataToRefresh.push({
							deliveryToCustomer: item.deliveryToCustomer,
							product: item.product,
							quantity: item.quantity,
							productID: item.productID,
							stocks: item.stocks,
							unit: item.unit
						});
					}

					await S_C_Order.getInstance().saveStockEvent(this.idOrder, stockData);

					resolve();

					this.resolve(dataToRefresh);
				}));
			});
		});
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];
		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity || 0;
					},
					cellRenderer: LoadingCellRenderer,
					cellRendererParams: {
						CellRenderer: NumericCellRenderer,
						decimalNumber: 0
					}
				});

				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id + '.quantity',
					width: 120,
					cellEditor: NumericCellEditor,
					editable: true,
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						params.data.stocks[item._id] = params.data.stocks[item._id] || { quantity: 0 };
						return params.data.stocks[item._id].quantity || 0;
					},
					cellRenderer: EditCellRenderer,
					cellRendererParams: {
						CellRenderer: NumericCellRenderer,
						decimalNumber: 0
					}
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer.quantity',
			width: 120,
			cellEditor: NumericCellEditor,
			editable: true,
			suppressSizeToFit: true,
			valueGetter: (params) => {
				return params.data.deliveryToCustomer?.quantity || 0;
			},
			cellRenderer: EditCellRenderer,
			cellRendererParams: {
				CellRenderer: NumericCellRenderer,
				decimalNumber: 0
			}
		});

		this.N_grid?.setGridOptions({
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerName: '',
					filter: false,
					width: 50,
					pinned: 'left',
					field: 'productID',
					sortable: false,
					resizable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					cellRenderer: ProductCellRenderer,
					cellRendererParams: {
						isOnlyImage: true
					}
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID].reference;
					}
				},
				{
					headerName: 'Libellé',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID].label;
					}
				},
				{
					headerName: 'Marque',
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						return this.products[params.data.productID].brand;
					}
				},
				{
					headerName: 'Quantité Cmd',
					field: 'quantity',
					width: 100,
					cellClass: 'text-center',
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: 'Unité',
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'text-center',
					valueGetter: (params) => {
						return this.products[params.data.productID].unit;
					}
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Sortie stock',
					children: childrenLocation
				}
			],
			getRowStyle: (params: any) => {
				const quantity = params.data.quantity;
				let outputQuantity = (params.data.deliveryToCustomer?.quantity || 0);

				for (const idStock in params.data.stocks) {
					outputQuantity += (params.data.stocks[idStock]?.quantity || 0);
				}

				//stock sortie
				if (outputQuantity >= quantity) {
					return {
						'background-color': 'var(--ap-green-50)',
						color: 'var(--ap-green-900)'
					};
				}

				//aucun stock de sortie
				if (outputQuantity === 0) {
					return {
						'background-color': 'var(--ap-red-50)',
						color: 'var(--ap-red-900)'
					};
				}

				//en cours de sortie
				if (outputQuantity < quantity) {
					return {
						'background-color': 'var(--ap-orange-50)',
						color: 'var(--ap-orange-900)'
					};
				}
			},
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			suppressContextMenu: true,
			suppressClickEdit: true
		});
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.reference] = stock[item.reference] || {};
			stock[item.reference][item.stock] = stock[item.reference][item.stock] || { quantity: 0 };
			stock[item.reference][item.stock].quantity = item.quantity.formattedValue;
		}

		this.N_grid!.forEachNode((node) => {
			for (const item of this.locations) {
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.productID]?.[item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	private async getAdditionalData() {
		const products = await S_Product.getInstance().getDataToAgGrid();

		this.products = {};

		for (const item of products.rowData) {
			this.products[item._id.value] = {
				reference: item[S_Product.getInstance().referenceKey].formattedValue,
				unit: item.unit.formattedValue,
				brand: item.brand.formattedValue,
				label: item.label.formattedValue,
				price: item.price.formattedValue
			};
		}
	}

	public async initData() {
		const { locations, content } = await S_C_Order.getInstance().getStockById(this.idOrder);

		this.locations = locations;

		this.initGrid();

		await this.getAdditionalData();

		this.N_grid!.value = content;

		this.initStock();
	}
}

export default EditStockOutput;
