import GenericService from './GenericService';

class RemindersService extends GenericService {
	private static instance: RemindersService | null = null;

	constructor() {
		super('reminders');
	}

	public static getInstance(): RemindersService {
		if (!RemindersService.instance) {
			RemindersService.instance = new RemindersService();
		}

		return RemindersService.instance;
	}

	public getByDocument(table: string, documentId: string) {
		return this.get_function('getByDocument', { table, documentId });
	}
}

export default RemindersService;
