import ConfigManager from '../libs/ConfigManager';

import agUtils from '@libs/agGrid/french';

import _ from 'lodash';
import moment from 'moment';

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter';
import SelectFilter from '@libs/agGrid/SelectFilter';

import { ColDef, GetQuickFilterTextParams, GridOptions } from '@ag-grid-enterprise/all-modules';

import Decimal from '@libs/utils/Decimal';
import Utils from '@js/libs/utils/Utils';

class GridOptionsManager {
	private static instance: GridOptionsManager;

	private configManager: ConfigManager;

	constructor() {
		this.configManager = ConfigManager.getInstance();
	}

	public static getInstance() {
		if (!GridOptionsManager.instance) {
			GridOptionsManager.instance = new GridOptionsManager();
		}

		return GridOptionsManager.instance;
	}

	public get(table: string, gridOptions: GridOptions = {}): GridOptions {
		const columnDefs = this.getDefaultColumns(table);

		const result: GridOptions = _.merge({
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}]
			},
			columnDefs,
			defaultColDef: {
				suppressMenu: true,
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				resizable: true,
				sortable: true,
				cellRenderer: (params) => {
					return params.value;
				}
			},
			suppressDragLeaveHidesColumns: true,
			suppressScrollOnNewData: true
		} as GridOptions, gridOptions);

		return agUtils.french<GridOptions>(result);
	}

	public getDefaultColumns(table: string) {
		const config = this.configManager.getConfig(table);

		const columns: ColDef[] = [];

		for (const item of config.columns) {
			if (item.type !== 'file' && !item.notDisplay) {
				const obj: ColDef = {
					headerName: item.name,
					headerTooltip: item.name,
					field: item.key,
					hide: item.type === 'primaryKey' && config.columns.length > 1,
					cellClass: item.classes || '',
					resizable: true
				};

				// Permet d'ignorer les accents
				if (item.type === 'string') {
					obj.filterParams = {
						textFormatter: (result: string) => {
							if (result === null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					};
				}

				if (item.type === 'date') {
					obj.getQuickFilterText = (params: GetQuickFilterTextParams) => {
						return moment(params.value, 'x').format(item.formatTo || 'x');
					};

					obj.filter = 'agNumberColumnFilter';

					obj.floatingFilterComponent = DatePickerRangeFilter;

					obj.comparator = (valueA, valueB) => {
						valueA = Number(valueA || '0');
						valueB = Number(valueB || '0');
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					};

					obj.valueGetter = (params) => {
						const val = _.get(params.data, item.key);
						return parseInt(moment(val, item.formatFrom || 'x').format('x'));
					};

					obj.cellRenderer = (params) => {
						let value = '';

						if (item.formatTo) {
							const date = moment(params.value, 'x');
							value = date.isValid() ? date.format(item.formatTo || 'x') : '';
						} else {
							value = _.get(params.data, params.colDef.field || '');
						}

						return value;
					};
				}

				if (item.type === 'boolean') {
					obj.getQuickFilterText = () => {
						return '';
					};
					obj.floatingFilterComponent = SelectFilter;

					obj.floatingFilterComponentParams = {
						suppressFilterButton: true,
						options: {
							data: [{
								text: 'oui',
								id: 'true'
							}, {
								text: 'non',
								id: 'false'
							}]
						}
					};

					obj.cellRenderer = (params) => {
						let value = '<i class="text-danger icon icon-solid-times"></i>';

						if (params.value === true) {
							value = '<i class="text-success icon icon-solid-check"></i>';
						}

						return value;
					};
				}

				if (item.type === 'array') {
					if (item.filter === 'multiple') {
						obj.filter = 'agSetColumnFilter';

						obj.floatingFilterComponentParams = {
							suppressFilterButton: false
						};

						obj.filterParams = {
							values: item.array
						};
					} else {
						obj.floatingFilterComponent = SelectFilter;
						const data: { [key: string]: string }[] = [];

						for (const d of item.array) {
							data.push({
								id: d,
								text: d
							});
						}

						obj.floatingFilterComponentParams = {
							suppressFilterButton: true,
							options: {
								data
							}
						};
					}
				}

				if (item.type === 'object') {
					obj.getQuickFilterText = (params: GetQuickFilterTextParams) => {
						return item.object[params.value] || params.value;
					};

					if (item.filter === 'multiple') {
						obj.filter = 'agSetColumnFilter';

						obj.valueFormatter = (params: any) => {
							return item.object[params.value] || params.value;
						};

						obj.floatingFilterComponentParams = {
							suppressFilterButton: false
						};

						obj.filterParams = {
							values: Object.keys(item.object),
							valueFormatter: (params: any) => {
								return item.object[params.value] || params.value;
							}
						};
					} else {
						obj.floatingFilterComponent = SelectFilter;

						const data: { [key: string]: string }[] = [];

						if (item.order) {
							for (const key of item.order) {
								data.push({
									id: key,
									text: item.object[key]
								});
							}
						} else {
							for (const key in item.object) {
								data.push({
									id: key,
									text: item.object[key]
								});
							}
						}

						obj.floatingFilterComponentParams = {
							suppressFilterButton: true,
							options: {
								data
							}
						};
					}

					obj.cellRenderer = (params) => {
						const value = item.object[params.value] || params.value;

						return value;
					};
				}

				if (item.type === 'number') {
					obj.filter = 'agNumberColumnFilter';

					obj.comparator = (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					};

					obj.floatingFilterComponentParams = {
						suppressFilterButton: false
					};

					//On filtre sur le début du nombre
					obj.filterParams = Utils.getPriceFilterParams();

					obj.valueGetter = (params) => {
						return Decimal.setDisplayNumber(_.get(params.data, params.column.getColDef()?.field || '')).toNumber();
					};

					obj.cellRenderer = (params) => {
						return _.get(params.data, params.column.colDef.field);
					};
				}

				if (item.type === 'Decimal') {
					obj.filter = 'agNumberColumnFilter';

					obj.comparator = (valueA, valueB) => {
						valueA = valueA || '';
						valueB = valueB || '';
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					};

					obj.floatingFilterComponentParams = {
						suppressFilterButton: false
					};

					obj.valueGetter = (params) => {
						return (_.get(params.data, params.column.getColDef()?.field || '') || {}).value;
					};

					obj.cellRenderer = (params) => {
						return (_.get(params.data, params.column.colDef.field) || {}).formattedValue;
					};
				}

				if (item.type === 'table' && item.listFilter) {
					obj.filter = 'agSetColumnFilter';

					obj.floatingFilterComponentParams = {
						suppressFilterButton: false
					};

					obj.filterParams = {
						applyMiniFilterWhileTyping: true
					};
				}

				columns.push(obj);
			}
		}

		return columns;
	}
}

export default GridOptionsManager;
