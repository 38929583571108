// CORE
import { Router } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
// LIBS
// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
// SERVICE

//STYLE
import '../../../../css/quickAccess.scss';

class QuotesToDo extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-to-do';

	private _data: any;

	public async connectedCallback() {
		this.innerHTML = `
			<div class="d-flex align-items-center text-nowrap quickaccess-card">
				<div class="p-3 w-100">
					<div class="d-flex w-100">
						<div class="d-flex align-items-center">
							<i class="quickaccess-icon quotes-to-do-icon d-flex align-items-center justify-content-center icon icon-solid-file h2 px-3 py-2"
							style="height: 56px; width: 56px"></i>
						</div>
						<div class="pl-3 w-100">
							<div class="d-flex font-weight-bold text-grey-700 h6">Devis à faire</div>
							<div style="font-size: 12px">
								<div class="d-flex w-100">
									<div>En retard</div>
									<div class="ml-auto font-weight-bold" data-key="quotesToDo.toLate"></div>
								</div>
								<div class="d-flex w-100">
									<div>En attente</div>
									<div class="ml-auto font-weight-bold" data-key="quotesToDo.waiting"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="quickaccess-link-icon">
					<i class="icon icon-solid-external-link-alt"></i>
				<div>
			</div>
        `;
	}

	private linkData() {
		const N_quotesToDo_toLate = this.querySelector('[data-key="quotesToDo.toLate"]');
		const N_quotesToDo_waiting = this.querySelector('[data-key="quotesToDo.waiting"]');

		N_quotesToDo_toLate!.innerHTML = this._data.quotesToDo.toLate;
		N_quotesToDo_waiting!.innerHTML = this._data.quotesToDo.waiting;
	}

	private initButton() {
		const router = Router.getInstance();

		const N_quotesToDo = this.querySelector('.quickaccess-card') as HTMLElement;

		N_quotesToDo?.addEventListener('click', () => {
			router.navigate('#module/quotes/quotes_to_do');
		});
	}

	public set data(data: any) {
		this._data = data;
		this.linkData();
		this.initButton();
	}

	public get data() {
		return this._data;
	}

	public static register() {
		customElements.define(QuotesToDo.tagName, QuotesToDo);
	}
}

export default QuotesToDo;
