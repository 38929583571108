import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import Calcul from '../Calcul';

import Decimal from '@libs/utils/Decimal';

class SumGroupCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.innerHTML = '';
	}

	public init(params: any) {
		const calcul = new Calcul();
		this.eGui.innerHTML = Decimal.setDisplayNumber(calcul.calculateSumGroup(params.data)).setSuffixAndHumanizeNumber('€', -1);
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default SumGroupCellRenderer;
