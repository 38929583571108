import Decimal from '@libs/utils/Decimal';
import QuoteOption from '../../QuoteOptions';

import roundUp from './roundUp';

export default function (data: any, calcul: any) {
	let res = 0;

	const pref = QuoteOption.getInstance().pref;

	if (!data.disabled) {
		for (const item of data.details) {
			if (item.quantity) {
				const tmp = calcul!.calculatePrice(item, pref);
				res += tmp.sum;
			}
		}

		res *= (data.quantity || 1);
	}

	res = roundUp(res, pref);
	return Decimal.setDisplayNumber(res).toDecimalPlaces(pref.decimalNumber).toNumber();
}
