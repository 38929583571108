import { Form, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import SettingsController from '../libs/SettingsController';

class SettingsTabs extends SettingsController {
	constructor(el: HTMLElement) {
		super(el);

		const N_form = el.querySelector('form') as HTMLFormElement;

		const form = new Form(N_form);

		form.setData(SettingsApps.getInstance().get('APPLICATION') as { [key: string]: any });

		const N_save = el.querySelector('#save') as HTMLButtonElement;

		N_save.addEventListener('click', () => {
			SettingsApps.getInstance().setGeneral('APPLICATION', form.getData());
			toaster.success('Sauvegarde réussi');
		});
	}

	public destructor() {

	}
}

export default SettingsTabs;
