import moment, { Moment } from 'moment';

class DeadlinePaymentUtils {
	public static calculateDate(startedDate: Moment, deadlinePayment: number, fixedDateDeadline: number, typeDeadlinePayment: string) {
		const estimatedDate = moment(startedDate);

		//fin de mois
		if (typeDeadlinePayment === '1') {
			estimatedDate.endOf('month').add(deadlinePayment, 'day');
			if (fixedDateDeadline) {
				estimatedDate.set('date', fixedDateDeadline || 0);
			}
		} else {
			estimatedDate.add(deadlinePayment, 'day');
		}

		return estimatedDate;
	}
}

export default DeadlinePaymentUtils;
