import CE_Button from '@libs/customElement/Button';

class ButtonFullScreen extends HTMLElement {
	public static readonly tagName: string = 'ap-button-fullscreen';

	public connectedCallback() {
		this.innerHTML = `
			<ap-button class="btn-navbar" icon="fullscreen/line" tooltip="Plein écran"></ap-button>
		`;

		const N_fullscreen = this.querySelector<CE_Button>('ap-button')!;

		let fullscreen = false;

		N_fullscreen.addEventListener('click', () => {
			if (fullscreen) {
				document.exitFullscreen();
			} else {
				document.body.requestFullscreen();
			}
		});

		const updateFulscreenIcon = () => {
			if (fullscreen) {
				N_fullscreen.icon = 'fullscreen/line';
			} else {
				N_fullscreen.icon = 'fullscreen-exit/line';
			}

			fullscreen = !fullscreen;
		};

		document.addEventListener('fullscreenchange', () => {
			updateFulscreenIcon();
		});

		document.addEventListener('keydown', (e) => {
			if (e.code === 'F11') {
				if (fullscreen) {
					document.exitFullscreen();
				} else {
					document.body.requestFullscreen();
				}

				e.preventDefault();
				e.stopPropagation();
				e.stopImmediatePropagation();
			}
		});
	}

	public static register() {
		customElements.define(ButtonFullScreen.tagName, ButtonFullScreen);
	}
}

export default ButtonFullScreen;
