import { Tab } from '@autoprog/core-client';

import _cloneDeep from 'lodash/cloneDeep';

import Desktop from './Database.Desktop';

class TabDatabase extends Tab {
	protected instance: Desktop;

	constructor(el: HTMLElement, data: any) {
		super(el);

		const cloneData = _cloneDeep(data);

		if (!cloneData.instance) {
			throw new Error('instance not find');
		}

		const Instance = cloneData.instance;

		delete cloneData.instance;

		this.instance = new Instance(el, cloneData);
	}

	public destructor() {
		this.instance.destructor();
	}
}

export default TabDatabase;
