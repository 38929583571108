import EventEmitter from '@autoprog/eventemitter';

import CE_Select2 from '@libs/customElement/Select2';

import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Site from '@services/Site/SiteService';

type ValueRef = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | string | boolean;

class AddressDelivery extends HTMLElement {
	public static readonly tagName: string = 'ap-address-delivery';

	public selectElement: HTMLSelectElement | null = null;

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private eventEmitter = new EventEmitter();

	public async connectedCallback() {
		const nameType = this.getAttribute('name-type');
		const nameAddressID = this.getAttribute('name-address-id');
		const nameSite = this.getAttribute('name-site');
		const nameGPS = this.getAttribute('name-gps');
		const nameTextarea = this.getAttribute('name-textarea');

		this.innerHTML = `
			<div class="d-flex">
				<div class="font-weight-bold mr-2">
					Type d'adresse :
				</div>
				<div class="form-group">
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="${nameType}" id="customerAddressType1" value="customer" checked>
						<label class="form-check-label" for="customerAddressType1">Client</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="${nameType}" id="customerAddressType2" value="site">
						<label class="form-check-label" for="customerAddressType2">Site</label>
					</div>
				</div>
			</div>

			<label>Adresse :</label>

			<div class="form-group d-none" id="addressID">
				<ap-select2-button name="${nameAddressID}" table="customers-address"></ap-select2-button>
			</div>

			<div class="form-group d-none" id="site">
				<ap-select2-button name="${nameSite}" table="sites"></ap-select2-button>
			</div>

			<textarea class="form-control mt-2" id="fullAddress" rows="5" name="${nameTextarea}"></textarea>
			
			<div class="form-group mt-2">
				<label>Coordonnées GPS</label>
				<input type="text" class="form-control"  id="gps" name="${nameGPS}">
			</div>
        `;
		/*
			typeAddressCustomer;
			addressIDCustomer;
			siteCustomer;
			fullAddress;
			GPSCoordinates;
		*/

		const N_customerAddressType1 = this.querySelector<HTMLInputElement>('#customerAddressType1')!;
		const N_customerAddressType2 = this.querySelector<HTMLInputElement>('#customerAddressType2')!;

		N_customerAddressType1.addEventListener('change', () => {
			this.update(N_customerAddressType1.value);
		});

		N_customerAddressType2.addEventListener('change', () => {
			this.update(N_customerAddressType2.value);
		});

		this.removeAttribute('name-type');
		this.removeAttribute('name-address-id');
		this.removeAttribute('name-site');
		this.removeAttribute('name-gps');
		this.removeAttribute('name-textarea');
	}

	public create(customer: ValueRef, parent: HTMLElement) {
		const N_siteCustomer = this.querySelector<CE_Select2>('#site ap-select2-button')!;
		const N_addressID = this.querySelector<CE_Select2>('#addressID ap-select2-button')!;

		N_addressID.setRef({ id_customer: customer, delivery: true });
		N_addressID.create(parent);

		N_siteCustomer.setRef({ customer });
		N_siteCustomer.create(parent);

		this.selectPostinit.site = N_siteCustomer;
		this.selectPostinit.address = N_addressID;
	}

	public async update(type: string) {
		const N_addressID = this.querySelector<HTMLElement>('#addressID');
		const N_site = this.querySelector<HTMLElement>('#site');
		const N_fullAddress = this.querySelector<HTMLTextAreaElement>('#fullAddress')!;
		const N_GPSCoordinates = this.querySelector<HTMLInputElement>('#gps')!;

		if (type === 'site') {
			N_addressID?.classList.add('d-none');
			N_site?.classList.remove('d-none');

			if (!this.selectPostinit.site.value) {
				this.clearAddress();
			} else {
				const siteInfos = await S_Site.getInstance().getById(this.selectPostinit.site.value as string);
				N_fullAddress.value = siteInfos?.address || '';
				N_GPSCoordinates.value = siteInfos?.latitude && siteInfos?.longitude ? siteInfos?.latitude + ', ' + siteInfos?.longitude : '';
			}
		}

		if (type === 'customer') {
			N_addressID?.classList.remove('d-none');
			N_site?.classList.add('d-none');

			if (!this.selectPostinit.address.value) {
				this.clearAddress();
			} else {
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(this.selectPostinit.address.value as string);
				N_fullAddress.value = addressInfos?.fullAddress || '';
				N_GPSCoordinates.value = addressInfos?.GPSCoordinates || '';
			}
		}
	}

	private clearAddress() {
		const N_fullAddress = this.querySelector<HTMLTextAreaElement>('#fullAddress')!;
		const N_GPSCoordinates = this.querySelector<HTMLInputElement>('#gps')!;

		N_fullAddress.value = '';
		N_GPSCoordinates.value = '';
	}

	public postInit() {
		const N_fullAddress = this.querySelector<HTMLTextAreaElement>('#fullAddress')!;
		const N_GPSCoordinates = this.querySelector<HTMLInputElement>('#gps')!;

		this.selectPostinit.address.on('change', async (value) => {
			const addressInfos = await S_C_Address.getInstance().getAddressInfos(value as string);
			N_fullAddress.value = addressInfos.fullAddress || '';
			N_GPSCoordinates.value = addressInfos.GPSCoordinates || '';
		});

		this.selectPostinit.site.on('change', async (value) => {
			const siteInfos = await S_Site.getInstance().getById(value as string);
			N_fullAddress.value = siteInfos.address || '';
			N_GPSCoordinates.value = siteInfos.latitude && siteInfos.longitude ? siteInfos.latitude + ', ' + siteInfos.longitude : '';
		});

		this.selectPostinit.site.postInit();
		this.selectPostinit.address.postInit();
	}

	public on(key: string, cb: (value: string) => void) {
		this.eventEmitter.on(key, cb);
	}

	public static register() {
		customElements.define(AddressDelivery.tagName, AddressDelivery);
	}
}

export default AddressDelivery;
