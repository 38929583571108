import axios from 'axios';

class CsvService {
	private static instance: CsvService | null = null;

	protected baseURI: string = '/api/csv';

	public static getInstance(): CsvService {
		if (!CsvService.instance) {
			CsvService.instance = new CsvService();
		}

		return CsvService.instance;
	}

	public async parseCsv(formData: FormData): Promise<any> {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		};
		return await axios.post(
			`${this.baseURI}/parsing`,
			formData,
			config
		).then(result => result.data);
	}
} export default CsvService;
