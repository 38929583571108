import { Settings } from '@autoprog/core-client';

class SettingsApps {
	private static instance: SettingsApps;

	private constructor() { }

	public static getInstance() {
		if (!SettingsApps.instance) {
			SettingsApps.instance = new SettingsApps();
		}

		return SettingsApps.instance;
	}

	public load() {
		return Settings.getInstance().load();
	}

	public setGeneral(key: string, data: any) {
		return Settings.getInstance().setGeneral(key, JSON.stringify(data));
	}

	public setForUser(user: string, key: string, data: any) {
		return Settings.getInstance().setForUser(Number(user), key, JSON.stringify(data));
	}

	public get(key: string) {
		const data = Settings.getInstance().get(key) as string;
		if (data) {
			return JSON.parse(data);
		} else {
			return null;
		}
	}
}

export default SettingsApps;
