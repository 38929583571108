import '../../../css/customElements/brandLine.scss';

class BrandLine extends HTMLElement {
	public static readonly tagName: string = 'ap-create-order-brand-line';

	private _number: number = 0;

	public connectedCallback() {
		this.innerHTML = '<span class="brand"></span>&nbsp;(<span class="number"></span>)';
		this.classList.add('cell-loading');
	}

	public setData(data: { brand?: string, number?: number }) {
		const N_brand = this.querySelector<HTMLElement>('.brand')!;
		const N_number = this.querySelector<HTMLElement>('.number')!;

		if (data.brand !== undefined) {
			N_brand.innerHTML = data.brand;
		}

		if (data.number !== undefined) {
			this._number = data.number;
			N_number.innerHTML = data.number.toString();

			if (data.number === 0) {
				this.remove();
			}
		}
	}

	public get number() {
		return this._number;
	}

	public static register() {
		customElements.define(BrandLine.tagName, BrandLine);
	}
}

export default BrandLine;
