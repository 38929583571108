// CORE
import { Form, global, toaster } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../tpl/modals/Input.html';

// LIBS
import Modal from '@libs/Modal';

// PRINTER
// UTILS
// MODAL
import M_OutputCustomer from './OutputCustomer';

// CUSTOM_ELEMENT
import CE_InputTracaForm from '../libs/customElement/InputTracaForm';
import CE_Quantity from '@libs/customElement/Quantity';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class Input extends Modal {
	private form: null | Form = null;

	private filters: { [key: string]: any } = {};

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	constructor(idProduct?: string, quantity?: number, stock?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.filters.stock = stock;

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			if (global.IS_MOBILE) {
				const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLSelectElement;
				N_modalDialog.classList.remove('modal-70');
			}

			await this.initStock();
			this.init();

			const N_form = this.element.querySelector('.modal-content') as HTMLFormElement;

			this.form = new Form(N_form);

			this.form.setData({
				quantity: 0
			});

			if (idProduct) {
				const tmpProduct = await S_Product.getInstance().getDataToSelect2ByID(idProduct);

				this.form?.setDataByName('product', tmpProduct);

				const N_oldQuantity = this.element.querySelector('#oldQuantity') as HTMLElement;

				N_oldQuantity.innerHTML = `Ancienne quantité : ${quantity || 0}`;
			}

			if (stock) {
				this.form?.setDataByName('stock', stock);
			}

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				if (this.form) {
					const data = this.form.getData() as { [key: string]: any };

					if (data.product && data.quantity) {
						data.type = 'input';

						for (const key in data.traceability) {
							if (data.traceability[key] === '') {
								delete data.traceability[key];
							}
						}

						let saveEvent = true;

						if (data.stock === 'receiptToCustomer') {
							try {
								await new M_OutputCustomer(idProduct!, data.quantity as number).open();
							} catch (e) {
								saveEvent = false;
							}
						}

						if (saveEvent) {
							await S_StockEvent.getInstance().save(data);

							this.resolve();
						}
					} else {
						toaster.error('Veuillez remplir tous les champs');
					}
				}
			});

			this.postInit();
		});
	}

	private async initStock() {
		const N_list = this.element.querySelector('[name="stock"]') as HTMLSelectElement;
		const data = await S_Stock.getInstance().getRealStock();

		for (const item of data) {
			const option = new Option(item.name, item._id);

			N_list.append(option);
		}
	}

	private init() {
		const N_product = this.element.querySelector('[name="product"]') as CE_Select2;
		const N_stock = this.element.querySelector('[name="stock"]') as HTMLSelectElement;

		N_product.create(this.element, { disabled: true });

		const N_inputTracaForm = this.element.querySelector(CE_InputTracaForm.tagName) as CE_InputTracaForm;
		N_inputTracaForm.init(this.element, N_stock);

		const N_quantity = this.element.querySelector(CE_Quantity.tagName) as CE_Quantity;

		N_quantity.addEventListener('change', () => {
			this.updateButtonSave();
		});

		this.selectPostinit.product = N_product;
	}

	private postInit() {
		const N_inputTracaForm = this.element.querySelector(CE_InputTracaForm.tagName) as CE_InputTracaForm;
		N_inputTracaForm.postInit();
		this.selectPostinit.product.postInit();

		this.updateButtonSave();
	}

	private updateButtonSave() {
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		const quantity = this.form?.getDataByName('quantity');

		N_save.disabled = quantity === 0 || isNaN(quantity as number);
	}
}

export default Input;
