// CORE
import { Form, global } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// TEMPLATE
import T_modal from '../../../tpl/modals/multiple/OutputCustomer.html';

// LIBS
import Modal from '@libs/Modal';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

// PRINTER
// UTILS
// MODAL
// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';

class OutputCustomer extends Modal {
	private form: Form | null = null;

	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private gridOptionsProducts: GridOptions = {};

	private rowData: any[];

	constructor(rowData: any[]) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.rowData = rowData;

		this.on('opened', async () => {
			const N_close = this.element.querySelector('.close') as HTMLButtonElement;

			N_close.addEventListener('click', (e: MouseEvent) => {
				this.reject();

				e.stopImmediatePropagation();
				e.stopPropagation();
			});

			if (global.IS_MOBILE) {
				const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLSelectElement;
				N_modalDialog.classList.remove('modal-70');
			}

			await this.initStock();
			this.init();

			const N_form = this.element.querySelector('.modal-content') as HTMLFormElement;

			this.form = new Form(N_form);

			this.form?.setDataByName('stock', 'deliveryToCustomer');

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				this.save();
			});

			this.postInit();
		});
	}

	private async initStock() {
		const N_list = this.element.querySelector('[name="stock"]') as HTMLSelectElement;
		const data = await S_Stock.getInstance().getRealStock();

		for (const item of data) {
			const option = new Option(item.name, item._id);

			N_list.append(option);
		}
	}

	private init() {
		const N_T_site = this.element.querySelector('[name="traceability.site"]') as CE_Select2;
		const N_T_customer = this.element.querySelector('[name="traceability.customer"]') as CE_Select2;
		const N_T_C_command = this.element.querySelector('[name="traceability.command_customer"]') as CE_Select2;

		N_T_customer.create(this.element);

		N_T_site.setRef({ customer: N_T_customer.selectElement! });
		N_T_site.create(this.element);

		N_T_C_command.create(this.element);

		this.selectPostinit['traceability.site'] = N_T_site;
		this.selectPostinit['traceability.customer'] = N_T_customer;
		this.selectPostinit['traceability.command_customer'] = N_T_C_command;

		this.initQuantitesEditorGrid();
	}

	private initQuantitesEditorGrid() {
		this.gridOptionsProducts = agUtils.french<GridOptions>({
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product._id',
					cellRenderer: ProductCellRenderer
				},
				{
					headerName: 'Désignation',
					field: 'product.label'
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					type: 'numberColumn',
					cellClass: ['text-right', 'text-monospace', 'cursor-pointer'],
					editable: true,
					singleClickEdit: true,
					cellEditor: NumericCellEditor,
					cellRenderer: (params) => {
						const eDiv = document.createElement('div');
						eDiv.innerHTML = params.value + '<i class="icon icon-edit ml-2 text-light-blue-600"></i>';
						return eDiv;
					}
				}
			],
			columnTypes: {
				valueColumn: {
					valueParser: 'Number(newValue)',
					filter: 'agNumberColumnFilter'
				}
			},
			suppressContextMenu: true,
			floatingFilter: true,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				suppressMenu: true,
				resizable: true
			},
			onGridReady: async () => {
				this.getSelectedData();
			}
		});

		const N_grid_products_list = this.element.querySelector('#grid_products_list');

		if (N_grid_products_list) {
			new Grid(N_grid_products_list as HTMLElement, this.gridOptionsProducts, { modules: AllModules });
		}
	}

	private postInit() {
		this.selectPostinit['traceability.command_customer'].on('change', async (value) => {
			if (value) {
				this.selectPostinit['traceability.customer'].disable();

				const data = await S_C_Order.getInstance().getById(value as string);

				const customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer);

				this.form?.setDataByName('traceability.customer', customer);
			} else {
				this.selectPostinit['traceability.customer'].enable();
			}
		});

		this.selectPostinit['traceability.site'].postInit();
		this.selectPostinit['traceability.customer'].postInit();
		this.selectPostinit['traceability.command_customer'].postInit();
	}

	private async getSelectedData() {
		this.gridOptionsProducts.api?.setRowData(this.rowData);
		this.gridOptionsProducts.api?.sizeColumnsToFit();
	}

	private async save() {
		const formData = this.form?.getData() as { [key: string]: any };

		for (const key in formData.traceability) {
			if (formData.traceability[key] === '') {
				delete formData.traceability[key];
			}
		}

		this.gridOptionsProducts.api?.stopEditing();

		this.gridOptionsProducts.api?.forEachNode(async (row) => {
			const data = {
				product: row.data.product._id,
				quantity: row.data.quantity,
				stock: formData.stock,
				traceability: formData.traceability,
				type: 'output'
			};

			await S_StockEvent.getInstance().save(data);
		});

		this.resolve();
	}
}

export default OutputCustomer;
