import { ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import csv from '@autoprog/csv';
import h from 'hyperscript';
import moment from 'moment';

import Modal from '@libs/Modal';

import '@css/modals/exportDashboard.scss';
import T_modal from '@tpl/modals/exportDashboard.html';

import CE_Checbox from '@libs/customElement/Checkbox';
import ExportUtils from '../utils/ExportUtils';
import { toaster } from '@autoprog/core-client';

class ExportDashboard extends Modal {
	protected gridOptions: GridOptions;
	protected table: string;

	constructor(gridOptions: GridOptions, table: string, tpl: string = T_modal) {
		super({
			tpl,
			keyboard: false,
			backdrop: 'static'
		});

		this.gridOptions = gridOptions;
		this.table = table;

		this.on('opened', () => {
			this.preInit();

			const N_printColumns = this.element.querySelector<HTMLElement>('#columnsToPrint')!;

			const N_title = this.element.querySelector<HTMLElement>('.modal-title')!;

			const N_selectAll = this.element.querySelector<HTMLButtonElement>('#selectAll')!;
			const N_unselectAll = this.element.querySelector<HTMLButtonElement>('#unselectAll')!;
			const N_export = this.element.querySelector<HTMLButtonElement>('#export')!;

			const N_CSV = this.element.querySelector<HTMLInputElement>('[name="csv"]')!;
			const N_separator = this.element.querySelector<HTMLInputElement>('[name="separator"]')!;

			N_title.innerHTML = `Paramètres de l'export des ${table}`;

			const colDefs = this.getColumns();

			for (const col of colDefs) {
				const N_checkbox = h<CE_Checbox>('ap-checkbox', { attrs: { name: col.field, label: col.headerName } });
				N_checkbox.checked = !col.hide;
				N_printColumns?.appendChild(N_checkbox);
			}

			N_selectAll.addEventListener('click', () => {
				const N_checkbox = N_printColumns.querySelectorAll<CE_Checbox>(CE_Checbox.tagName)!;

				for (const N_el of N_checkbox) {
					N_el.checked = true;
				}
			});

			N_unselectAll.addEventListener('click', () => {
				const N_checkbox = N_printColumns.querySelectorAll<CE_Checbox>(CE_Checbox.tagName)!;

				for (const N_el of N_checkbox) {
					N_el.checked = false;
				}
			});

			N_export.addEventListener('click', async () => {
				N_export.disabled = true;

				const header = this.getHeaderExport();

				let separator = '';

				if (N_CSV.checked) {
					if (N_separator.value === 'semi-colon') {
						separator = ';';
					}

					if (N_separator.value === 'tab') {
						separator = '\t';
					}
				}

				this.getDataExport(header, separator).then((dataFilter) => {
					const fileName = this.getFilenameExport();

					let dataFile = '';

					if (N_CSV.checked) {
						dataFile = this.convertFileExport(header, dataFilter, separator);
					}

					ExportUtils.exportFile(dataFile, fileName);
				}).catch(() => {
					toaster.error('Erreur lors de l\'export');
				}).finally(() => {
					N_export.disabled = false;
				});
			});

			this.postInit();
		});
	}

	protected preInit() {

	}

	protected postInit() {

	}

	protected convertFileExport(header: { [key: string]: string }, dataFilter: { [key: string]: string }[], separator: string) {
		let dataFile = '';
		dataFile = csv.stringify(dataFilter, header, '\r\n', separator);
		return dataFile;
	}

	protected getFilenameExport() {
		const N_CSV = this.element.querySelector<HTMLInputElement>('[name="csv"]')!;
		const N_separator = this.element.querySelector<HTMLInputElement>('[name="separator"]')!;

		let fileName = `Export_${moment().format('YYYY_MM_DD')}_${this.table}`;

		if (N_CSV.checked) {
			if (N_separator.value === 'semi-colon') {
				fileName += '.csv';
			}

			if (N_separator.value === 'tab') {
				fileName += '.tsv';
			}
		}

		return fileName;
	}

	protected getHeaderExport() {
		const header: { [key: string]: string } = {};
		const colDefs = this.getColumns();

		for (const col of colDefs) {
			const N_checkbox = this.element.querySelector<CE_Checbox>(`ap-checkbox [name="${col.field}"]`);

			if (N_checkbox?.checked) {
				header[col.field!] = col.headerName!;
			}
		}

		return header;
	}

	protected async getDataExport(header: { [key: string]: string }, separator: string = '') {
		const dataFilter: { [key: string]: string }[] = [];

		this.gridOptions.api?.forEachNodeAfterFilterAndSort((node) => {
			const obj: { [key: string]: string } = {};

			for (const key in header) {
				if (key !== '_createBy_') {
					obj[key] = _.get(node.data, key)?.export || '';

					if (separator) {
						if (obj[key].includes(separator)) {
							obj[key] = '"' + obj[key] + '"';
						}
					}
				}
			}

			dataFilter.push(obj);
		});

		return dataFilter;
	}

	protected getColumns() {
		const colDef = (this.gridOptions.api?.getColumnDefs() || []) as ColDef[];
		const result: ColDef[] = [];

		for (const col of colDef) {
			if (!col.suppressColumnsToolPanel && col.field !== '_createBy_') {
				result.push(col);
			}
		}

		return result;
	}
}

export default ExportDashboard;
