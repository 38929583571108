import h from 'hyperscript';

import '../../../css/ap-text-editable.scss';

class TextEditable extends HTMLElement {
	private static readonly tagName: string = 'ap-text-editable';
	private _text: string | null;
	private viewContent: HTMLDivElement;
	private editContent: HTMLDivElement;

	constructor() {
		super();

		this._text = null;
		this.viewContent = h('div.container');
		this.editContent = h('div.container.d-none');
		this.initViewContent();
		this.initEditContent();
	}

	public connectedCallback() {
		this.appendChild(this.viewContent);
		this.appendChild(this.editContent);
	}

	private initViewContent() {
		const N_text = h('p', this.text || '-');
		const N_editBtn = h('button.btn.btn-blue', 'Éditer') as HTMLButtonElement;

		N_editBtn.addEventListener('click', () => {
			this.viewContent.classList.add('d-none');
			this.editContent.classList.remove('d-none');
		});

		this.viewContent.appendChild(N_text);
		this.viewContent.appendChild(N_editBtn);
	}

	private initEditContent() {
		const input = h('input.form-control', { value: this.text || '-' }) as HTMLInputElement;
		const confirmBtn = h('button.btn.btn-green-600#confirm', 'Valider') as HTMLButtonElement;
		confirmBtn.addEventListener('click', () => {
			this.text = input.value;
			this.dispatchEvent(new Event('textChange', { bubbles: true }));

			this.editContent.classList.add('d-none');
			this.viewContent.classList.remove('d-none');
		});
		const cancelBtn = h('button.btn.btn-red#cancel', 'Annuler') as HTMLButtonElement;
		cancelBtn.addEventListener('click', () => {
			this.editContent.classList.add('d-none');
			this.viewContent.classList.remove('d-none');
		});

		this.editContent.appendChild(input);
		this.editContent.appendChild(confirmBtn);
		this.editContent.appendChild(cancelBtn);

		this.appendChild(this.editContent);
		input.focus();
	}

	public set text(value: string | null) {
		this._text = value;
		const N_input = this.editContent.querySelector('input') as HTMLInputElement;
		const N_p = this.viewContent.querySelector('p') as HTMLElement;
		N_input && (N_input.value = value || '-');
		N_p && (N_p.innerHTML = value || '-');
	}

	public get text(): string | null {
		return this._text;
	}

	public static register() {
		customElements.define(TextEditable.tagName, TextEditable);
	}
}
export default TextEditable;
