import tippy from 'tippy.js';

import '@css/customElements/progress.scss';

class Progress extends HTMLElement {
	public static readonly tagName: string = 'ap-progress';

	public connectedCallback() {
		const percent = this.getAttribute('percent');

		const tooltip = this.getAttribute('tooltip');

		this.innerHTML = `
			<div class="progress">
				<div class="progress-value"></div>
			</div>
			<div class="value">${percent}%</div>
		`;

		if (tooltip) {
			const N_value = this.querySelector('.value')!;
			const widthValue = N_value.clientWidth;
			tippy(this, {
				content: tooltip,
				placement: 'right',
				offset: [0, -1 * widthValue],
				arrow: false
			});
		}

		this.removeAttribute('tooltip');

		this.style.setProperty('--percent', percent + '%');
	}

	public static register() {
		customElements.define(Progress.tagName, Progress);
	}
}

export default Progress;
