import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _set from 'lodash/set';

import moment from 'moment';

import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/FormControllerPageID';

class FormControllerPageID {
	private el: HTMLElement;

	private data: any = {};
	private resData: any = {};
	private resAllData: any = {};

	private divByName: { [key: string]: HTMLElement } = {};

	constructor(el: HTMLElement) {
		this.el = el;

		this.updateInputs();
	}

	public updateInputs() {
		const N_list = this.el.querySelectorAll('[name]') as NodeListOf<HTMLElement>;

		this.divByName = {};

		for (const N_div of N_list) {
			const name = N_div.getAttribute('name') || '';
			const classes = N_div.className || '';

			//si input non enregistré et n'est pas une div pour aggrid
			if (!this.divByName[name] && !classes.match(/ag-(.*)/gmi)) {
				this.divByName[name] = N_div;
			}
		}
	}

	private setResData(name: string, value: any, N_div: HTMLElement) {
		if (N_div.getAttribute('save') !== 'false') {
			_set(this.resData, name, value);
		}

		_set(this.resAllData, name, value);
	}

	public setData(data: { [key: string]: any }) {
		this.data = Utils.merge(this.data, data);
		for (const name in this.divByName) {
			const value = _get(this.data, name);
			this.setDataByName(name, value);
		}
	}

	private password(name: string, value: string, N_div: HTMLElement) {
		N_div.innerHTML = '******';
		this.setResData(name, value, N_div);
	}

	private percentage(name: string, value: string | number, N_div: HTMLElement, defaultValue: string) {
		N_div.innerHTML = value ? value + ' %' : defaultValue;
		this.setResData(name, value, N_div);
	}

	private price(name: string, value: string | number, N_div: HTMLElement, defaultValue: string) {
		if (value !== undefined) {
			N_div.innerHTML = Decimal.setDisplayNumber(value).toDecimalPlaces(2).setSuffixAndHumanizeNumber('€');

			this.setResData(name, value, N_div);
		} else {
			N_div.innerHTML = defaultValue;
		}
	}

	private percent(name: string, value: string | number, N_div: HTMLElement, defaultValue: string) {
		if (value !== undefined) {
			N_div.innerHTML = Decimal.setDisplayNumber(value).toDecimalPlaces(2).setSuffixAndHumanizeNumber('%');

			this.setResData(name, value, N_div);
		} else {
			N_div.innerHTML = defaultValue;
		}
	}

	private date(name: string, value: string | number, N_div: HTMLElement, defaultValue: string) {
		if (value) {
			const date = moment(value);
			if (date.isValid()) {
				const format = N_div.getAttribute('format') || 'DD/MM/YYYY';
				N_div.innerHTML = date.format(format);
			} else {
				N_div.innerHTML = '';
			}

			this.setResData(name, date, N_div);
		} else {
			N_div.innerHTML = defaultValue;
		}
	}

	private stringOrNumber(name: string, value: string | number, N_div: HTMLElement, defaultValue: string) {
		let displayValue = value;

		if (typeof value === 'string') {
			if (!value) {
				displayValue = defaultValue;
			}
		}

		if (typeof value === 'number') {
			if (value === undefined || value === null) {
				displayValue = defaultValue;
			}
		}

		N_div.innerHTML = displayValue + '';

		if (N_div.getAttribute('type') === 'textarea') {
			N_div.classList.toggle('textarea-default-value', !value);
			N_div.style.whiteSpace = 'pre-line';
		}

		this.setResData(name, value, N_div);
	}

	private boolean(name: string, value: boolean, N_div: HTMLElement) {
		N_div.innerHTML = value ? '<span class="text-success font-weight-bold">OUI</span>' : '<span class="text-danger font-weight-bold">NON</span>';

		this.setResData(name, value, N_div);
	}

	private object(name: string, value: { [key: string]: string }, N_div: HTMLElement, defaultValue: string) {
		let saveValue = '';

		if (value.id !== undefined) {
			N_div.innerHTML = value.text || value.id || defaultValue;
			saveValue = value.id;
		} else {
			N_div.innerHTML = defaultValue;
		}

		this.setResData(name, saveValue, N_div);
	}

	private array(name: string, value: { [key: string]: string }[], N_div: HTMLElement, defaultValue: string) {
		const html: string[] = [];
		const values: string[] = [];

		for (const item of value) {
			html.push(item.text || item.id);
			values.push(item.id);
		}

		if (html.length) {
			N_div.innerHTML = html.join(', ');
		} else {
			N_div.innerHTML = defaultValue;
		}

		this.setResData(name, values, N_div);
	}

	public setDataByName(name: string, value: any) {
		_set(this.data, name, value);

		if (this.divByName[name]) {
			const N_div = this.divByName[name];

			const defaultValue = N_div.getAttribute('defaultvalue') || '&nbsp';

			N_div.setAttribute('ellipsis-tooltip', '');

			const type = N_div.getAttribute('type');

			if (value !== null) {
				if (type === 'password') {
					this.password(name, value, N_div);
				} else if (type === 'percentage') {
					this.percentage(name, value, N_div, defaultValue);
				} else if (type === 'price') {
					this.price(name, value, N_div, defaultValue);
				} else if (type === 'percent') {
					this.percent(name, value, N_div, defaultValue);
				} else if (type === 'date') {
					this.date(name, value, N_div, defaultValue);
				} else if (typeof value === 'string' || typeof value === 'number' || type === 'price_percent') {
					this.stringOrNumber(name, value, N_div, defaultValue);
				} else if (typeof value === 'boolean') {
					this.boolean(name, value, N_div);
				} else if (value instanceof Array) {
					this.array(name, value, N_div, defaultValue);
				} else if (typeof value === 'object') {
					this.object(name, value, N_div, defaultValue);
				} else {
					N_div.innerHTML = defaultValue;
				}
			} else {
				N_div.innerHTML = defaultValue;

				if (N_div.getAttribute('save') !== 'false') {
					_set(this.resData, name, '');
				}
			}
		} else {
			_set(this.resData, name, value);
		}
	}

	public getData() {
		return _cloneDeep(this.resData);
	}

	public getDataByName(name: string) {
		return _get(this.getData(), name);
	}

	public getDataOnAllDataByName(name: string) {
		return _get(this.resAllData, name);
	}
}

export default FormControllerPageID;
