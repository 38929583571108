import { Form } from '@autoprog/core-client';

import T_modal from '../../tpl/modals/exportCompta.html';

import CE_Select2 from '@libs/customElement/Select2';
import Modal from '@libs/Modal';
import UtilsBills from '../libs/Utilsbills';
import drpC from '@libs/utils/daterangepickerConfig';

import S_C_Bill from '@services/Customer/CustomerBillService';
import S_Customer from '@services/Customer/CustomerService';

import moment from 'moment';

class ExportCompta extends Modal {
	private form: Form | null = null;

	private billByCustomers: { [customer: string]: any[] } = {};

	private exportIds: string[] = [];

	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const data = await S_C_Bill.getInstance().getAll();

			for (const item of data) {
				this.billByCustomers[item.infos.customer] = this.billByCustomers[item.infos.customer] || [];
				this.billByCustomers[item.infos.customer].push(item);
			}

			const N_date = this.element.querySelector('[name="date"]') as HTMLInputElement;
			const N_input = $(N_date);

			N_date.disabled = true;

			//@ts-ignore
			N_input.daterangepicker(drpC({
				autoUpdateInput: true,
				startDate: new Date(),
				parentEl: this.element
			}));

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			this.form.setData({
				value: 'week'
			});

			const N_customer = this.element.querySelector('[name="customer"]') as CE_Select2;

			N_customer.create(this.element);

			N_customer.on('change', () => {
				this.getNumberBill();
			});

			N_customer.postInit();

			this.form.on('value.change', () => {
				const value = this.form?.getDataByName('value');
				N_date.disabled = value !== 'other';
				this.getNumberBill();
			});

			N_input.on('apply.daterangepicker', () => {
				this.getNumberBill();
			});

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				const res = await S_C_Bill.getInstance().getComptaByIds(this.exportIds);

				const data = this.form?.getData() as { [key: string]: any };

				let customer = '';
				if (data.customer) {
					customer = (await S_Customer.getInstance().getById(data.customer)).name;
				}

				UtilsBills.exportCompta(res, customer ? `${customer}-${moment().format('DD/MM/YYYY')}` : moment().format('DD/MM/YYYY'));
			});

			this.getNumberBill();
		});
	}

	private getNumberBill() {
		const data = this.form?.getData() as { [key: string]: any };

		const number_bills = this.element.querySelector('#number_bills') as HTMLDivElement;
		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		let number = 0;

		let startDate = 0;
		let endDate = moment('01/01/9999', 'DD/MM/YYYY').valueOf();

		if (data.value === 'all') {
			//rien
		}

		if (data.value === 'month') {
			startDate = moment().startOf('month').valueOf();
			endDate = moment().endOf('month').valueOf();
		}

		if (data.value === 'week') {
			startDate = moment().startOf('week').valueOf();
			endDate = moment().endOf('week').valueOf();
		}

		if (data.value === 'other') {
			startDate = data.date.startDate.valueOf();
			endDate = data.date.endDate.valueOf();
		}

		let bills: { [customer: string]: any[] } = {};

		if (data.customer) {
			bills = {
				[data.customer]: this.billByCustomers[data.customer] || []
			};
		} else {
			bills = this.billByCustomers;
		}

		this.exportIds = [];

		for (const customer in bills) {
			for (const bill of bills[customer]) {
				if (endDate > Number(bill.infos.date) && Number(bill.infos.date) > startDate) {
					this.exportIds.push(bill._id);
					number++;
				}
			}
		}

		if (number > 1) {
			number_bills.innerHTML = `${number} factures à exporter`;
		} else {
			number_bills.innerHTML = `${number} facture à exporter`;
		}

		N_save.disabled = number === 0;
	}
}

export default ExportCompta;
