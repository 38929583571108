import { SocketIO, toaster } from '@autoprog/core-client';

import Utils from '@libs/utils/Utils';

class ClipBoard {
	private static _instance: ClipBoard;

	private data: any;

	private io: SocketIO;
	private loggedUser: string;

	private constructor() {
		this.io = SocketIO.getInstance();
		this.loggedUser = Utils.userID;

		this.io.connect();

		this.io.whenConnected().then(() => {
			this.io.on('reconnect', () => {
				this.io.emit('quote.clipboard.init', true);
			});

			this.io.on('connect', () => {
				this.io.emit('quote.clipboard.init', true);
			});

			this.io.on('quote.clipboard.paste', (data: { [key: string]: any }) => {
				this.data = data[this.loggedUser] || {};
			});

			this.io.emit('quote.clipboard.init', true);
		});
	}

	public setData(data: any, type: string) {
		data._idGrid = Date.now().toString(36);

		toaster.success('Copie réussi');

		this.io.emit('quote.clipboard.copy', { data, type, user: this.loggedUser });
	}

	public getData(type: string) {
		this.data = this.data || {};
		return this.data[type] || '';
	}

	public static getInstance() {
		if (!ClipBoard._instance) {
			ClipBoard._instance = new ClipBoard();
		}

		return ClipBoard._instance;
	}
}

export default ClipBoard;
