//CORE
import { Controller, global } from '@autoprog/core-client';

import Utils from '@libs/utils/Utils';

//@ts-ignore
import { Converter } from 'showdown';

class Changelog extends Controller {
	constructor(el: HTMLElement) {
		super(el);

		const N_container = el.querySelector('#changelog-container') as HTMLElement;

		const converter = new Converter();

		const idUser = Utils.userID;

		const changelog = converter.makeHtml(global.CHANGELOG).replace(/\((MR-[0-9]{1,})?( ){0,}(#[0-9]{1,})?\)/gmi, (value: string) => {
			return `<span class="${idUser !== '1' ? 'd-none' : ''}">${value}</span>`;
		});

		N_container.innerHTML = changelog;

		const N_changelog = el.querySelector('#changelog') as HTMLElement;
		N_changelog.remove();

		for (let i = 0; i < N_container.children.length; i++) {
			const N_el = N_container.children[i] as HTMLElement;

			const N_list = N_el.querySelectorAll(':scope > li') as NodeListOf<HTMLElement>;

			N_list.forEach((N_el) => {
				if (N_el.innerText.includes('[CODE]') && idUser !== '1') {
					N_el.classList.add('d-none');
				}
			});
		}
	}

	public destructor() {

	}
}

export default Changelog;
