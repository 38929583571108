import CustomerOrderService from './Customer/CustomerOrderService';
import GenericService from './GenericService';
import ProductService from './Product/ProductService';

import { Moment } from 'moment';

class DeliveryService extends GenericService {
	private static instance: DeliveryService | null = null;

	private prefix = 'BL';

	constructor() {
		super('deliveries');
	}

	public static getInstance(): DeliveryService {
		if (!DeliveryService.instance) {
			DeliveryService.instance = new DeliveryService();
		}

		return DeliveryService.instance;
	}

	public async getByCustomerOrder(id: string) {
		return this.get_function('getByCustomerOrder', {
			id
		});
	}

	public async getNotOrderProduct(id: string): Promise<any[]> {
		const data = await this.getByCustomerOrder(id);
		const orderProducts = await CustomerOrderService.getInstance().getById(id).then((response) => response.materials);
		const notOrder: any[] = [];

		for (const delivery of data) {
			for (const product of delivery.products) {
				const index = orderProducts.find((material: any) => material.reference === product.reference);
				if (typeof (index) === 'undefined') {
					notOrder.push({
						reference: product.reference,
						deliveredQuantity: product.deliveredQuantity,
						label: product.label + '- BL: ' + delivery.infos.number,
						quantity: product.quantity,
						remainingQuantity: product.remainingQuantity
					});
				}
			}
		}
		return notOrder;
	}

	public async getRemainingOrderProduct(id: string): Promise<any[]> {
		const data = await this.getByCustomerOrder(id);
		const orderProducts = await CustomerOrderService.getInstance().getById(id).then((response) => response.materials);
		const remain: any[] = [];

		const order_Products_Map = new Map<string, number>();

		orderProducts.forEach((product: any) => {
			order_Products_Map.set(product.reference, product.quantity);
		});

		for (const delivery of data) {
			delivery.products.forEach((product: any) => {
				if (typeof (order_Products_Map.get(product.reference)) === 'number') {
					const remain_Qtt = order_Products_Map.get(product.reference)! - product.deliveredQuantity;
					if (remain_Qtt > 0) {
						order_Products_Map.set(product.reference, remain_Qtt);
					} else {
						order_Products_Map.delete(product.reference);
					}
				}
			});
		}

		for (let i = 0; i < orderProducts.length; i++) {
			const product = orderProducts[i];
			if (typeof (order_Products_Map.get(product.reference)) === 'number') {
				const remainProduct = await ProductService.getInstance().getById(product.reference);
				remain.push({
					reference: product.reference,
					biogazCode: remainProduct.biogazCode,
					name: remainProduct.name,
					quantity: order_Products_Map.get(product.reference)!
				});
			}
		}

		return remain;
	}

	public updateNumberByDate(number: string, date: Moment) {
		const chunkNumber = number.replace(`${this.prefix}-`, '').split('-');

		return `${this.prefix}-${date.format('YYYY-MM')}-${chunkNumber.slice(2).join('-')}`;
	}
}

export default DeliveryService;
